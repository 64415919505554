import {Injectable} from '@angular/core';
import {NotificationService} from './notification.service';
import {NotificationEnum, NotificationTimes} from '../enums';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {utils, verifyServerResponse} from '../functions';
import {serverMessages} from '../static';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {
  constructor(private readonly notificationService: NotificationService) {}

  private getErrorText(
    error: HttpErrorResponse,
    messages: Array<{ code: number; message: string }> = []
  ): string {
    try {
      const statusCode = error.status;
      if (messages && messages.length) {
        const error = messages.find((message) => +message.code === statusCode);
        if (error) {
          const hasError = verifyServerResponse(error);
          if (!!hasError) {
            return '';
          }
          return error.message;
        }
      }
      const hasError = error.error instanceof Blob ? true : verifyServerResponse(error.error);
      if (!!hasError) {
        return '';
      }

      const messageError = error?.error?.message ?? (utils.isString(error.error) ? error.error : null);
      if (statusCode === HttpStatusCode.UnprocessableEntity && messageError === 'ValidationError') {
        return error.error.errors.reduce((elem, sum) => `${sum} ${elem}`, '');
      } else if (
        statusCode === HttpStatusCode.Unauthorized &&
        (messageError === serverMessages.expiredRefreshToken ||
        messageError === serverMessages.expiredAuthToken)
      ) {
        return '';
      }
      if (messageError) {
        return messageError;
      }
      if (typeof  error?.error !== 'object') {
        return error.message;
      }
      return '';
    } catch (err) {
      return 'LBL_ERROR.GENERAL';
    }
  }

  handleError(error: HttpErrorResponse, notification = true): Promise<any> {
    let text = this.getErrorText(error);
    let notificationType = NotificationEnum.ERROR;
    let notificationDuration = NotificationTimes.DEFAULT;
    if (error.status === 0) {
      text = 'LBL_ERROR.API_NOT_FOUND';
    } else if (text === 'Time out' && +error.status === HttpStatusCode.RequestTimeout) {
      text = 'LBL_ERROR.TIME_OUT';
    } else if (+error.status === HttpStatusCode.PayloadTooLarge) {
      text = 'LBL_ERROR.RETRY_FILE';
      notificationType = NotificationEnum.WARNING;
      notificationDuration = NotificationTimes.INFINITE;
    }
    if (notification && !!text) {
      this.notificationService.notify(text, notificationType, notificationDuration);
    }
    return Promise.reject(error);
  }

  handleErrorByMessages(
    error: any,
    messages: Array<{ code: number; message: string }>
  ): Promise<any> {
    const text = this.getErrorText(error, messages);
    if (!!text) {
      this.notificationService.notify(text, NotificationEnum.ERROR);
    }
    return Promise.reject(error);
  }
}
