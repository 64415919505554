import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'slm-last-update',
  templateUrl: './last-update.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LastUpdateComponent {

  @Input() date: Date | string;
  @Input() marginBottom: string;
  @Input() fullWidth = false;
}
