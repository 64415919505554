import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconColors } from '../../../../../../global';

@Component({
  selector: 'slm-table-stepper',
  template: `
    <div *ngIf="!!statuses.length" class="flex">
      <div
        *ngFor="let status of statuses; let index = index; let last = last"
        class="flex flex-items-center"
      >
        <h5
          [style.color]="status.color"
          class="border radius-infinite border-2 pl-1_5 pr-1_5 m-0 line-h-1_6"
          [class.border-dashes]="!status.type"
          [style.borderColor]="status.color"
          [matTooltip]="'EMAIL_STEPPER.' + index | translate"
        >
          {{ index + 1 }}
        </h5>
        <slm-svg-icon
          *ngIf="!last"
          [color]="iconColor.GREY"
          icon="arrow_right"
        ></slm-svg-icon>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableStepperComponent {
  public iconColor = IconColors;
  public statuses: Array<{ type: boolean; color: IconColors }> = [];

  @Input('data')
  public set setData(number: number) {
    if (number <= 0 || number > 6) {
      this.statuses = [];
    } else if (number <= 2) {
      this.statuses = [
        {
          type: !(number % 2),
          color: IconColors.BLUE
        }
      ];
    } else if (number <= 4) {
      this.statuses = [
        {
          type: true,
          color: IconColors.BLUE
        },
        {
          type: !(number % 2),
          color: IconColors.ORANGE
        }
      ];
    } else {
      this.statuses = [
        {
          type: true,
          color: IconColors.BLUE
        },
        {
          type: true,
          color: IconColors.ORANGE
        },
        {
          type: !(number % 2),
          color: IconColors.ERROR
        }
      ];
    }
  }
}
