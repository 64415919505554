import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {MenuItem, SubMenuItem, dropdownFadeAnimation, Icon} from '../../../global';

@Component({
  selector: 'slm-side-nav-item',
  templateUrl: './side-nav-item.component.html',
  styleUrls: ['./side-nav-item.component.scss'],
  animations: [dropdownFadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideNavItemComponent {
  @Input() public menuItem: MenuItem = null;
  @Input() public desktopClosed = false;
  @Input() public openedMenu: string = null;

  @Input() public selectedRoute: string = null;

  @Output() public toggle = new EventEmitter<string>();
  @Output() public route = new EventEmitter<string>();

  public readonly upgradeIcon = Icon.GRADE;

  openMenu() {
    if (this.menuItem) {
      if (this.menuItem.submenu) {
        if (this.openedMenu === this.menuItem?.name) {
          this.toggle.next(null);
        } else {
          this.toggle.next(this.menuItem?.name);
        }
      } else {
        this.clickedRoute(this.menuItem.route);
      }
    }
  }

  clickedRoute(route) {
    this.route.emit(route);
  }

  trackByLabel(item: SubMenuItem) {
    return item.name;
  }
}
