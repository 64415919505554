import { Injectable } from '@angular/core';
import { FinanceService } from '../services';
import { CashFlowInvoiceType } from '../entities';
import {InvoiceTypeEnum, YearQuarter} from '../enums';

@Injectable({
  providedIn: 'root'
})
export class FinanceSandbox {
  constructor(private readonly financeService: FinanceService) {}

  getVatBalance = (queryParams: {year: number; month?: number; quarter?: YearQuarter; navVat: boolean}) =>
    this.financeService.getVatBalance(queryParams);

  getCashFlowBalance = () => this.financeService.getCashFlowBalance();

  getInvoicesStatus = (from: Date, to: Date, type: InvoiceTypeEnum.INCOMING | InvoiceTypeEnum.OUTGOING) =>
    this.financeService.getInvoicesStatus(from, to, type);

  getCashFlow = (period: string, verified: boolean) =>
    this.financeService.getCashFlow(period, verified);

  getInvoicesCashFlow = (
    interval: 'daily' | 'weekly' | 'monthly',
    verified: boolean,
    type: CashFlowInvoiceType,
    orderBy: 'creationDate' | 'completeDate' | 'paymentDeadline',
    from: Date,
    to: Date
  ) =>
    this.financeService.getInvoicesCashFlow(
      interval,
      verified,
      type,
      orderBy,
      from,
      to
    );

  getInvoiceBalances = (type: CashFlowInvoiceType, amount: 'net' | 'gross') =>
    this.financeService.getInvoiceBalances(type, amount);
}
