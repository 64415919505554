<slm-external-page-container *ngIf="!showSuccessScreen; else registrationSuccess" width="600px" layoutDirection="start">
  <h4 translate>AUTHENTICATION.REGISTRATION.WELCOME</h4>
  <h5 [innerHTML]="'AUTHENTICATION.REGISTRATION.REGISTRATION_WITH_DATA' | translate"></h5>
  <slm-registration-form
    (form)="setForm($event)"
    class="w-100"
    disableTaxNumberAutocomplete="true"
    [subscriptions]="subscriptionOptions$ | async"
  ></slm-registration-form>

  <re-captcha
    class="block m-auto w-fit-content"
    [formControl]="recaptchaControl"
    required
    [siteKey]="recaptchaSiteKey"
    lang="hu"
  ></re-captcha>
  <slm-button
    class="w-50 block m-auto mt-4 w-min-250px"
    type="submit"
    (click)="submitForm()"
    [loading]="isLoading$ | async"
    text="LBL_BTN.REGISTRATION"
  ></slm-button>
  <div class="mt-4 mb-2 flex w-100 flex-center">
    <span class="h-1px bg-color-grey-light block w-50 mt-1_5"></span>
    <h5 class="w-auto-i w-min-50" translate>LBL_OR</h5>
    <span class="h-1px bg-color-grey-light block w-50 mt-1_5"></span>
  </div>
  <slm-button
    type="button"
    class="w-50 block m-auto w-min-250px"
    buttonStyle="transparent-blue"
    text="LBL_BTN.LOGIN"
    (click)="goToLogin()"
  ></slm-button>

</slm-external-page-container>

<ng-template #registrationSuccess>
  <slm-external-page-container
    width="600px">
    <h3 translate>LBL_MESSAGES.REGISTRATION_SUCCESS</h3>
    <h4 translate [translateParams]="{email: email}">LBL_MESSAGES.REGISTRATION_SUCCESS_EMAIL</h4>
    <h5 class="txt-size-medium-i" translate>LBL_MESSAGES.REGISTRATION_SUCCESS_EMAIL_HELP</h5>
    <slm-button
      class="w-fit-content mt-4 m-auto"
      [url]="routes.LOGIN"
      text="LBL_BTN.LOGIN"
    ></slm-button>
  </slm-external-page-container>
</ng-template>
