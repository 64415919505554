<article *ngIf="view === 'card'; else listView" class="card card--no-padding">
  <div class="card__header">
    <h4 class="card__header__title">{{ title | translate }}</h4>
    <div *ngIf="showHeaderButtons" class="card__header__buttons">
      <slm-button
        *ngFor="let button of buttons"
        [buttonStyle]="button.buttonStyle"
        [config]="button"
        [disabled]="!imageUrls"
        (click)="fireEvent.emit({ event: button.action })"
      ></slm-button>
    </div>
  </div>
  <ng-container *ngIf="imageFromUrl; then urlImageContent; else base64ImageContent"></ng-container>
</article>


<ng-template #urlImageContent>
  <div
    cdk-scrollable
    *ngIf="!!imageUrls; else loading"
    class="image-list overflow-y-auto"
  >
    <ng-container *ngTemplateOutlet="imageContent"></ng-container>
  </div>
</ng-template>

<ng-template #base64ImageContent>
  <div
    *ngIf="!!imagePreviewData || imagePreviewData  === ''; else loading"
    class="image-list overflow-y-auto"
  >
    <div class="image-list__container">
      <ng-container *ngIf="imagePreviewData.length; else noImage">
        <slm-image-zoom
          class="image-list__container__image__img pt-4 pb-4"
          [thumbImage]="imagePreviewData"
          zoomMode="hover-freeze"
          enableScrollZoom="true"
          scrollStepSize="0.1"
          minZoomRatio="0.1"
          maxZoomRatio="2"
          magnification="1.5"
        ></slm-image-zoom>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #listView>
  <ng-container *ngTemplateOutlet="imageContent"></ng-container>
</ng-template>

<ng-template #imageContent>
  <div class="image-list__container">
    <ng-container *ngIf="imageUrls.length; else noImage">
      <div
        *ngFor="
            let img of imageUrls;
            let index = index;
            let first = first;
            let last = last;
            trackBy: trackByValue
          "
        class="image-list__container__image"
      >
        <div class="image-list__container__image__header">
          <h5 class="image-list__container__image__header__title color-accent w-max-fit-content">
            {{
            "LBL_IMAGES.PAGE_NUM"
              | translate
              : {
                count: imageUrls.length,
                page: index + 1
              }
            }}
          </h5>
          <h5
            *ngIf="img.fileName"
            class="image-list__container__image__header__title color-accent txt-no-wrap ml-3-i"
            [matTooltip]="img.fileName">
            {{img.fileName}}
          </h5>
          <div
            class="image-list__container__image__header__btn"
            *ngIf="!hideAllButton"
          >
            <slm-button-icon
              *ngIf="img.fileName"
              [icon]="icons.CONTENT_COPY"
              testId="fileNameCopy"
              class="fill-accent mr-2"
              elevation="true"
              [matTooltip]="'COPY_TO_CLIPBOARD' | translate"
              (click)="copyFileNameToClipboard(img.fileName)"
            ></slm-button-icon>

            <slm-button-icon
              [icon]="icons.ROTATE_LEFT"
              testId="rotateImageLeft"
              class="fill-accent mr-2"
              elevation="true"
              [loading]="isImageRotating(img.value)"
              [matTooltip]="'ROTATE_RIGHT' | translate"
              (click)="rotate(img.value, true)"
            ></slm-button-icon>
            <slm-button-icon
              [icon]="icons.ROTATE_RIGHT"
              testId="rotateImageRight"
              class="fill-accent mr-2"
              [loading]="isImageRotating(img.value)"
              elevation="true"
              [matTooltip]="'ROTATE_LEFT' | translate"
              (click)="rotate(img.value, false)"
            ></slm-button-icon>
            <ng-container *ngIf="ocrBtnEnabled && img.key === -1">
              <slm-button-icon
                [icon]="icons.DOCUMENT_SEARCH"
                testId="start_image_ocr"
                class="fill-accent mr-2"
                elevation="true"
                [loading]="isOcrRunning(img.value)"
                [matTooltip]="'LBL_UPLOAD_FILL.DOCUMENT_OCR' | translate"
                (click)="fireEvent.emit({ event: 'ocr', data: img })"
              ></slm-button-icon>
            </ng-container>
            <ng-container *ngIf="!isEditMode">
              <slm-button-icon
                [icon]="icons.PRINT"
                class="fill-accent mr-2"
                elevation="true"
                testId="print_image"
                [matTooltip]="'LBL_BTN.PRINT_ITEM' | translate"
                (click)="fireEvent.emit({ event: 'print_image', data: img })"
              ></slm-button-icon>
              <slm-button-icon
                [icon]="icons.DOWNLOAD"
                testId="download_image"
                class="fill-accent"
                elevation="true"
                [matTooltip]="'LBL_BTN.DOWNLOAD_ITEM' | translate"
                (click)="fireEvent.emit({ event: 'download_image', data: img })"
              ></slm-button-icon>
            </ng-container>
            <ng-container *ngIf="isEditMode">

              <slm-button-icon
                [icon]="icons.DELETE"
                class="fill-accent mr-2"
                elevation="true"
                testId="delete_image"
                [matTooltip]="'LBL_BTN.DELETE' | translate"
                (click)="fireEvent.emit({ event: 'remove', data: index })"
              ></slm-button-icon>
              <slm-button-icon
                *ngIf="!first"
                [icon]="icons.ARROW_UP"
                class="fill-accent mr-2"
                elevation="true"
                testId="move_up_image"
                [matTooltip]="'LBL_BTN.MOVE_UP' | translate"
                (click)="fireEvent.emit({ event: 'move_up', data: index })"
              ></slm-button-icon>
              <slm-button-icon
                *ngIf="!last"
                [icon]="icons.ARROW_DOWN"
                class="fill-accent"
                elevation="true"
                testId="move_down_image"
                [matTooltip]="'LBL_BTN.MOVE_DOWN' | translate"
                (click)="fireEvent.emit({ event: 'move_down', data: index })"
              ></slm-button-icon>
            </ng-container>
          </div>
        </div>
        <slm-image-zoom
          *ngIf="(img.data || (img.value | pictureAsync)| async) as image"
          class="image-list__container__image__img"
          [thumbImage]="image"
          zoomMode="hover-freeze"
          enableScrollZoom="true"
          scrollStepSize="0.1"
          minZoomRatio="0.1"
          maxZoomRatio="2"
          magnification="1.5"
        ></slm-image-zoom>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #loading>
  <slm-loader class="image-list__loader"></slm-loader>
</ng-template>
<ng-template #noImage>
  <slm-no-image
    [placeholderTitle]="placeholderTitle"
    [placeholderDescription]="placeholderDescription"
  ></slm-no-image>
</ng-template>

<ng-template #ocrRunning>
  <slm-button-icon
    class="fill-white mr-2"
    elevation="true"
    loading="true"
    [matTooltip]="'LBL_UPLOAD_FILL.DOCUMENT_OCR' | translate"
  ></slm-button-icon>
</ng-template>
