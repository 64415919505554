import { createFeatureSelector, createSelector } from '@ngrx/store';
import {partnerReducer, PartnerState} from './partner.reducer';

const partnerState = createFeatureSelector<PartnerState>(partnerReducer.name);

const getSelectedId = () => createSelector(
  partnerState,
  (state) => state.selectedPartnerId
);

const getSelectedPartner = () => createSelector(
  partnerState,
  (state) => state.selectedPartner
);

export const partnerSelectors = {
  getSelectedId,
  getSelectedPartner
};
