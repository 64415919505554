import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {DragAndDropError, Icon, Icons} from '../../../global';

@Component({
  selector: 'slm-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss']
})
export class DragAndDropComponent {
  @Input() public icon: Icons = Icon.BACKUP;
  @Input() public text = 'LBL_DND.TITLE';
  @Input() public browseButton = 'LBL_DND.BTN';
  @Input() public label = '';
  @Input() public accept = '';
  @Input() public showValue = true;
  @Input() public supportedFiles:
    | 'images'
    | 'documents'
    | 'images-documents' = null;
  @Input() public type: 'single-file' | 'multi-file' = 'single-file';
  @Input() public isVertical = true;
  @Input() public testId = '';
  @Input() public maxFileSizeMB: number = null;

  @Output() public file = new EventEmitter<any>();
  @Output() public fileError = new EventEmitter<DragAndDropError>();

  @Input('value')
  public set setValue(value) {
    if (value) {
      this.value = value;
    }
  }

  public value = null;

  @Input() iconSize = '25%';

  @Input('config')
  public set setWidth(config: {
    width: string;
    height?: string;
    isCube?: boolean;
    position?: 'absolute' | 'relative';
  }) {
    this.width = config.width;
    if (config.isCube) {
      this.height = config.width || config.height || '200px';
    } else {
      this.height = config.height || '200px';
    }

    this.position = config?.position || 'absolute';
  }

  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;

  public width = '100px';
  public height = '200px';
  public position = 'absolute';

  public fileBrowseHandler(event) {
    this.submitData(event.target.files);
  }

  public onFileDropped(files) {
    this.submitData(files);
  }

  private submitData(selectedFiles: FileList) {
    const files = [];
    for (let index = 0; index < (selectedFiles || []).length; ++index) {
      files.push(selectedFiles.item(index));
    }
    if (files.length > 0) {
      if (this.type !== 'multi-file') {
        const isValid = this.verifyType(files[0]);
        if (!isValid) {
          return;
        }
        if (this.type === 'single-file' && this.supportedFiles === 'images') {
          if (this.showValue) {
            const fr = new FileReader();
            fr.onload = () => (this.value = fr.result);
            fr.readAsDataURL(files[0]);
          }
        } else {
          const isExist = (files || []).find((file) => !this.verifyType(file));
          if (!isExist) {
            if (this.showValue) {
              this.value = files[0];
            }
          }
        }
      }
      const allowedFiles =
        this.type !== 'multi-file'
          ? files[0]
          : files.filter((file) => this.verifyType(file) && this.verifySize(file));
      if (
        (Array.isArray(allowedFiles) && !!allowedFiles.length) ||
        !Array.isArray(allowedFiles)
      ) {
        this.file.emit(allowedFiles);
      }
      if (!this.showValue) {
        this.clear();
      }
    }
  }

  public clear(): void {
    this.fileDropEl.nativeElement.value = '';
    this.value = null;
    this.file.emit(null);
  }

  private verifyType(file: any): boolean {
    const mimeType = file.type;
    if (!mimeType) {
      this.fileError.emit({error: 'type', message: 'LBL_ERROR.UNSUPPORTED_FILE_FORMAT'});
      return false;
    }
    if (this.accept && !this.accept.includes(mimeType)) {
      this.fileError.emit({error: 'type', message: 'LBL_ERROR.UNSUPPORTED_FILE_FORMAT'});
      return false;
    }
    if (
      this.supportedFiles === 'images' &&
      !this.accept &&
      mimeType.match(/image\/(jpg|jpeg|png)/) === null
    ) {
      this.fileError.emit({error: 'type', message: 'LBL_ERROR.ONLY_IMAGES_ARE_SUPPORTED'});
      return false;
    } else if (
      this.supportedFiles === 'images-documents' &&
      !this.accept &&
      mimeType.match(/(image\/(jpg|jpeg|png))|(application\/pdf)/) === null
    ) {
      this.fileError.emit({error: 'type', message: 'LBL_ERROR.ONLY_IMAGES_AND_DOCUMENTS_ARE_SUPPORTED'});
      return false;
    } else if (
      this.supportedFiles === 'documents' &&
      !this.accept &&
      mimeType.match(/(application\/pdf)/) === null
    ) {
      this.fileError.emit({error: 'type', message: 'LBL_ERROR.ONLY_DOCUMENTS_ARE_SUPPORTED'});
      return false;
    }
    return true;
  }

  private verifySize(file: any): boolean {
    if(this.maxFileSizeMB){
      const size = file.size;
      if(size > this.maxFileSizeMB * Math.pow(1024, 2)){
        this.fileError.emit({error: 'size', message: 'LBL_ERROR.MAX_FILE_SIZE'});
        return false;
      }
    }
    return true;
  }
}
