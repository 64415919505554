import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, ValidatorFn} from '@angular/forms';
import {InputTypeEnum} from '../../../../global';
import {MatChipInputEvent} from '@angular/material/chips';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {InputAbstract} from '../base/input.abstract';

@Component({
  selector: 'slm-chip-input',
  templateUrl: './chip-input.component.html',
  styles: [`
    :host {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
    }
  `]
})
export class ChipInputComponent extends InputAbstract implements OnInit, OnDestroy {
  @Input() public type: InputTypeEnum = 'text';
  @Input() public validators: Array<ValidatorFn> = [];
  public chips$ = new BehaviorSubject<Array<string>>([]);
  private destroy$ = new Subject();

  public readonly chipLimiters = [ENTER, COMMA];
  private readonly _error$ = new BehaviorSubject<string>('email');
  public errorMessage$: Observable<string>;

  ngOnInit() {
    super.ngOnInit();
    if (this.control) {
      this.control.valueChanges.pipe(
        takeUntil(this.destroy$)
      ).subscribe((value: Array<string>) => this.chips$.next((value || []).filter((value) => !!value)));
      this.control.setValue(this.control.value);
    }
    this.errorMessage$ = this._error$.pipe(
      map((attribute) => (attribute ? this.getError(attribute) : null))
    );
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.destroy$ = null;
  }

  public addItem(event: MatChipInputEvent): void {
    const value = (event.value || '')?.trim();
    const input = event.input;
    if (!!value && !!this.control) {
      if (!!this.validators?.length) {
        const control = new UntypedFormControl(value, this.validators);
        control.markAllAsTouched();
        if (control.invalid) {
          this._error$.next(Object.keys(control.errors)[0]);
          return;
        }
      }
      const controlValues: Array<string> = this.control.value || [];
      if (controlValues.includes(value)) {
        return;
      }
      controlValues.push(value?.trim());
      this.control.setValue(controlValues.filter((value) => !!value));
      this.control.markAsDirty();
      input.value = '';
    } else {
      this._error$.next(null);
    }
  }

  public removeItem(item: string): void {
    if (this.control) {
      const controlValues: Array<string> = this.control.value || [];
      if (controlValues.length) {
        const itemIndex = controlValues.indexOf(item);
        if (itemIndex >= 0) {
          controlValues.splice(itemIndex, 1);
          this.control.setValue(controlValues);
        }
      }
    }
  }
}
