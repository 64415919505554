import {NativeDateAdapter} from '@angular/material/core';
import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {getDefaultLanguage} from '../../global';

export interface DateDisplay {
  year: string;
  month: string;
  day: string;
}

export const customDateFormat = {
  parse: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
  },
  display: {
    // dateInput: { month: 'numeric', year: 'numeric', day: 'numeric'},
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

@Injectable()
export class DatePickerAdapter extends NativeDateAdapter {
  private datePipe = new DatePipe('en');

  parse(value: string | number): Date | null {
    if (!value) {
      return null;
    }

    const regex = /^[\.\/0-9]*$/;
    if(value && !regex.test(String(value))){
      return new Date(value);
    }

    const currentDate = new Date();
    const dayCount = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

    const currentLocale = getDefaultLanguage();
    if (this.locale !== currentLocale) {
      this.setLocale(currentLocale);
    }
    if (
      typeof value === 'string' &&
      (value.indexOf('.') > -1 || value.indexOf('/') > -1)
    ) {
      const parts: string[] = value
        .split(value.indexOf('.') > -1 ? '.' : '/')
        .filter((value) => value !== '');
      const isNotDate = parts.find((part) => isNaN(+part));
      if (isNotDate) {
        return null;
      }

      if (parts.length > 2) {
        return this.getFullFormatDate(parts);
      } else if (parts.length === 2) {
        return this.getMonthFormatDate(parts);
      } else {
        if (parts.length === 1 && +parts[0] <= dayCount) {
          const date = new Date();
          date.setDate(+parts[0]);
          return date;
        }
        if(parts?.length) {
          return DatePickerAdapter.buildDateFromString(parts[0]);
        }
        return new Date(value);
      }
    }
    const day = +value;
    if (day === 0) {
      return new Date(value);
    }
    const typedValue = `${value}`;
    if (isNaN(day)) {
      return new Date(value);
    }
    if (typedValue.length > 2) {
      return DatePickerAdapter.buildDateFromString(typedValue);
    }
    const date = new Date();
    if (day <= dayCount) {
      date.setDate(day);
      return date;
    }
    return DatePickerAdapter.buildDateFromString(typedValue);
  }

  format(date: Date, display: string | DateDisplay): string {
    if (!date) {
      return null;
    }
    const currentLocale = getDefaultLanguage();
    if (this.locale !== currentLocale) {
      this.setLocale(currentLocale);
    }
    return this.formatDate(this.locale, date);
  }

  getFirstDayOfWeek(): number {
    return this.locale === 'hu' ? 1 : 0;
  }

  private formatDate = (locale, date): string => this.datePipe.transform(date, locale === 'hu' ? 'yyyy.MM.dd' : 'dd.MM.yyyy');

  private static buildDateFromString(value: string): Date {
    const invalidDate = 'Invalid date';
    const valueLength = value.length;

    if (!value) {
      return new Date(invalidDate);
    }
    const date = new Date();
    if ((valueLength === 3 && value.startsWith('0')) || +value < 100) {
      const day = +value % 10;
      const month = (+value - day) / 10;
      date.setMonth(month - 1, day);
      return date;
    }

    const testDate = new Date();
    if (valueLength === 3 && +value > 100) {
      let day = +value % 100;
      let month = Math.floor((+value - day) / 100);
      testDate.setMonth(month, 0);
      if (day <= testDate.getDate()) {
        date.setMonth(month - 1, day);
        return date;
      }
      day = +value % 10;
      month = +value - day;
      if (month <= 12) {
        date.setMonth(month - 1, day);
        return date;
      }
      return new Date(invalidDate);
    }
    if (valueLength === 4) {
      let month = +value.slice(0, 2);
      let day = +value.slice(2, 4);

      if (month > 12) {
        let year = +value.slice(0, 2);
        month = +value.slice(2, 3);
        day = +value.slice(3, 4);

        year = this.getCustomYear(year);

        testDate.setFullYear(year);
        testDate.setMonth(month, 0);
        if (day > testDate.getDate() || year > new Date().getFullYear()+10) {
          return new Date(invalidDate);
        }
        date.setFullYear(year, month - 1, day);
        return date;
      }
      testDate.setMonth(month, 0);
      if (day <= testDate.getDate()) {
        date.setMonth(month - 1, day);
        return date;
      }
      return new Date(invalidDate);
    }
    if (value.length <= 6) {
      const day = +value.slice(valueLength - 2, valueLength);
      const month = +value.slice(valueLength - 4, valueLength - 2);
      const year = +value.slice(0, valueLength - 4);
      if (month > 12) {
        return new Date(invalidDate);
      }

      const selectedYear = this.getCustomYear(year);

      testDate.setFullYear(selectedYear);
      testDate.setMonth(month, 0);
      if (day > testDate.getDate()) {
        return new Date(invalidDate);
      }
      date.setFullYear(selectedYear, month - 1, day);
      return date;
    }
    return new Date(invalidDate);
  }

  private getFullFormatDate(parts: Array<string>): Date {
    const month = +parts[1] - 1;
    if (month > 11) {
      return null;
    }
    let year;
    let day;
    if (+parts[0] > 31) {
      year = DatePickerAdapter.getDateYear(+parts[0]);
      day = +parts[2];
    } else if (+parts[2] > 31) {
      year = DatePickerAdapter.getDateYear(+parts[2]);
      day = +parts[0];
    } else {
      const isHungarian = this.locale === 'hu';
      year = DatePickerAdapter.getDateYear(+parts[isHungarian ? 0 : 2]);
      day = +parts[isHungarian ? 2 : 1];
    }
    if (day > DatePickerAdapter.daysInMonth(year, month)) {
      return null;
    }
    return new Date(year, month, day);
  }

  private getMonthFormatDate(parts: Array<string>): Date {
    const isHungarian = this.locale === 'hu';
    const date = new Date();
    const month = +parts[isHungarian ? 0 : 1] - 1;
    const day = +parts[isHungarian ? 1 : 0];
    if (month > 11) {
      return null;
    }
    date.setMonth(month, day);
    if (day > DatePickerAdapter.daysInMonth(date.getFullYear(), month)) {
      return null;
    }
    return date;
  }

  private static getDateYear(year: number): number {
    const currentYear = new Date().getFullYear();
    if (year < 1000 && year > (currentYear % 100) + 50) {
      return 1000 + year;
    } else if (year <= (currentYear % 100) + 50) {
      return 2000 + year;
    } else {
      return year;
    }
  }

  private static daysInMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
  }

  private static getCustomYear(year: number){
    const currentYear = new Date().getFullYear() % 100;

    if (year < 10) {
      return Math.floor(new Date().getFullYear() / 100) * 100 + (currentYear - currentYear % 10) + year;
    } else {
      return Math.floor(new Date().getFullYear() / 100) * 100 + year;
    }
  }
}
