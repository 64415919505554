import {InvoicePayments} from '../entities';
import {replaceUrlParameter} from '../functions';

export const serverMessages = {
  expiredAuthToken: 'Invalid token: Auth token expired',
  expiredRefreshToken: 'Invalid token: Refresh token expired'
};

export const getPaymentLabel = (
  item: InvoicePayments, linkOrDiscount: string
): { label: string; link?: string; linkText?: string } => ({
  label: item.type === 'cash'
    ? 'INVOICE.PAYMENT.BANK-TRANSFER'
    : item.type === 'money'
      ? 'INVOICE.PAYMENT_TYPE.MONEY'
      : item.type === 'compensation'
        ? 'INVOICE.PAYMENT_TYPE.COMPENSATION'
        : item.type === 'cashDiscount'
          ? linkOrDiscount
          : item.type === 'other'
            ? 'INVOICE.PAYMENT_TYPE.OTHER'
            : item.type === 'prepayment'
              || item.type === 'proforma'
              ? 'INVOICE.PROFORMA'
              :item.type === 'bank-card'
                ? 'INVOICE.PAYMENT_TYPE.BANK_CARD'
                : 'INVOICE.INVOICE',
  ...item.type === 'prepayment' ? {
    link: replaceUrlParameter(linkOrDiscount, {id: item.prepayment.id}),
    linkText: item.prepayment.documentNumber
  } : {}
});
