import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import {
  ButtonConfig,
  buttonsStaticConfig,
  inputErrorTypes,
  Icon,
  CustomValidators
} from '../../../modules/global';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'slm-password-change-dialog',
  templateUrl: './password-change-dialog.component.html'
})
export class PasswordChangeDialogComponent implements OnInit {
  public readonly buttons: Array<ButtonConfig> = [
    buttonsStaticConfig.cancel,
    buttonsStaticConfig.save
  ];
  public readonly errors = [
    inputErrorTypes.required,
    inputErrorTypes.minLength,
    inputErrorTypes.strongPassword
  ];

  public passwordForm: UntypedFormGroup;

  public passwordMatch$: Observable<boolean>;
  public passwordNotMatch$: Observable<boolean>;
  private isValidPassword = false;
  public inputConfig = {
    currentPassword: {
      type: 'password',
      icon: Icon.VISIBILITY
    },
    newPassword: {
      type: 'password',
      icon: Icon.VISIBILITY
    },
    newPasswordConfirmation: {
      type: 'password',
      icon: Icon.VISIBILITY
    }
  };

  constructor(
    public readonly dialogRef: MatDialogRef<PasswordChangeDialogComponent>,
    private readonly fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.passwordForm = this.fb.group({
      currentPassword: ['', [Validators.required]],
      newPassword: [
        '',
        [
          Validators.required,
          CustomValidators.strongPassword,
          Validators.minLength(8)
        ]
      ],
      newPasswordConfirmation: [
        '',
        [
          Validators.required,
          CustomValidators.strongPassword,
          Validators.minLength(8)
        ]
      ]
    });
    this.passwordMatch$ = combineLatest([
      this.passwordForm.get('currentPassword').valueChanges,
      this.passwordForm.get('newPassword').valueChanges
    ]).pipe(
      map((values) => !!values[0] && !!values[1] && values[0] === values[1])
    );
    this.passwordNotMatch$ = combineLatest([
      this.passwordForm.get('newPasswordConfirmation').valueChanges,
      this.passwordForm.get('newPassword').valueChanges
    ]).pipe(
      map((values) => !!values[0] && !!values[1] && values[0] !== values[1]),
      tap((isNotValid) => (this.isValidPassword = !isNotValid))
    );
  }

  public buttonEvent(event: string) {
    if (event === 'save') {
      if (this.passwordForm.invalid) {
        this.passwordForm.markAllAsTouched();
        return;
      }
      this.dialogRef.close({
        currentPassword: this.passwordForm.value.currentPassword,
        newPassword: this.passwordForm.value.newPassword
      });
    } else {
      this.dialogRef.close();
    }
  }

  public passwordTypeChanged(type: string): void {
    const config = this.inputConfig[type];
    if (config) {
      if ('password' === config.type) {
        this.inputConfig[type] = {
          type: 'text',
          icon: Icon.VISIBILITY_OFF
        };
      } else {
        this.inputConfig[type] = {
          type: 'password',
          icon: Icon.VISIBILITY
        };
      }
    }
  }
}
