import {Component, Input, OnInit} from '@angular/core';
import {
  RoutesEnum,
  Currency,
  FinanceBalanceOverviewReport,
  defaultCurrency,
  RightsEnum,
  utils
} from '../../../global';

@Component({
  selector: 'slm-cash-flow-balance-overview',
  template: `
    <div class="grid-col-2-50 grid-responsive pr-3 pl-3 pb-4">
      <div
        class="card card--no-shadow card--no-padding"
        *ngIf="income as income; else loading"
      >
        <div class="card__header">
          <h4 class="card__header__title" translate>REVENUES</h4>
        </div>
        <div class="card__content pt-2 pl-1 pr-1">
          <div class="grid-col-2-50">
            <div>
              <h5 class="w-100 flex txt-start-i" translate>INVOICED_REVENUE</h5>
              <h3 class="w-100 block txt-start-i color-accent">
                {{ income.amountIncome | currencyTransform: currency }}
              </h3>
              <a
                class="block mb-2"
                *slmAccess="rights.DOCUMENT_LISTING_READ"
                [routerLink]="'/' + routes.INVOICES_OUTGOINGS_TABLE"
                [queryParams]="params"
                data-test-id="invoicesOutgoings"
                >{{
                  'COUNT_OUTGOING_INVOICE'
                    | translate: { count: outgoing.invoiceCount }
                }}</a
              >
            </div>
            <div>
              <h5 class="w-100 flex txt-start-i" translate>REVENUE_RECEIVED</h5>
              <h3 class="w-100 block txt-start-i">
                {{ income.amountOutgoing | currencyTransform: currency }}
              </h3>
              <span class="block mb-2"
                >{{ income.needToPay | currencyTransform: currency }}
                {{ 'NEED_TO_PAY' | translate }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="card card--no-shadow card--no-padding"
        *ngIf="outgoing as outgoings; else loading"
      >
        <div class="card__header">
          <h4 class="card__header__title" translate>
            EXPENDITURES_SUBTITLE
          </h4>
        </div>
        <div class="card__content pt-2 pl-1 pr-1">
          <div class="grid-col-2-50">
            <div>
              <h5 class="w-100 flex txt-start-i" translate>VENDOR_INVOICES</h5>
              <h3 class="w-100 block txt-start-i color-accent">
                {{
                  outgoings.amountIncome | currencyTransform: currency
                }}
              </h3>
              <a
                class="block mb-2"
                *slmAccess="rights.DOCUMENT_LISTING_READ"
                [routerLink]="'/' + routes.INVOICES_INCOMES_TABLE"
                [queryParams]="params"
                data-test-id="invoicesIncomes"
                >{{
                  'COUNT_INCOMING_INVOICE'
                    | translate: { count: income.invoiceCount }
                }}</a
              >
            </div>
            <div>
              <h5 class="w-100 flex txt-start-i" translate>EXPENDITURE_PAID</h5>
              <h3 class="w-100 block txt-start-i">
                {{
                  outgoings.amountOutgoing | currencyTransform: currency
                }}
              </h3>
              <span class="block mb-2"
                >{{ outgoings.needToPay | currencyTransform: currency }}
                {{ 'PAY_OUT_DUE' | translate }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #loading>
      <slm-loader class="block m-auto mt-5 mb-5"></slm-loader>
    </ng-template>
  `
})
export class CashFlowBalanceOverviewComponent implements OnInit {
  public readonly routes = RoutesEnum;
  public readonly rights = RightsEnum;
  public readonly params = {completionDateFrom: null};
  @Input() public income: FinanceBalanceOverviewReport = null;
  @Input() public outgoing: FinanceBalanceOverviewReport = null;
  @Input() public currency: Currency = defaultCurrency;

  public ngOnInit() {
    const date = new Date();
    date.setMonth(0, 1);
    this.params.completionDateFrom = utils.dateToServerDate(date);
  }
}
