import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import {GlobalErrorHandler, utils} from '../../global';

@Pipe({
  name: 'dateTransform'
})
export class DateTransformPipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService,
    private readonly globalErrorHandler: GlobalErrorHandler,
    private readonly datePipe: DatePipe
  ) {}

  transform(date: string | number | Date, format: string = ''): any {
    if (!date || ((utils.isString(date) ||  date instanceof Date) && (date?.toString() ?? '').includes('Invalid Date'))) {
      return '';
    }
    const dateFormat: string =
      format.startsWith('LBL_') || format.startsWith('FORMAT.')
        ? this.translateService.instant(format)
        : format;
    const calculatedFormat =
      dateFormat.startsWith('FORMAT') || dateFormat.startsWith('LBL_')
        ? dateFormat.endsWith('_FULL')
          ? 'yyyy.MM.dd HH:mm'
          : 'yyyy.MM.dd'
        : dateFormat;

    if (typeof  date !== 'string') {
      return this.datePipe.transform(date, calculatedFormat);
    }
    let dateString = date;
    if (/\d\d\d-\d\d-\d\d[\sT]\d\d:\d\d(:\d\d)?([-.:\s])\d{1,4}[Z]?/.test(date)) {
      dateString = date.replace(/([-.:\s])\d{1,4}[Z]?$/, '');
    }
    try {
      if (/\d\d\d-\d\d-\d\d \d\d:\d\d/.test(dateString)) {
        return this.datePipe.transform(dateString.replace(' ', 'T'), calculatedFormat);
      }
      const convertedDate = dateString
        .replace(/[./]/g, '-')
        .replace('T', ' ')
        .replace('Z', '');
      return this.datePipe.transform(convertedDate, calculatedFormat);
    } catch (e) {
      this.globalErrorHandler.reportError(`Invalid date format: ${date}`, 'MESSAGE.DATE_PARSE_ERROR');
      return this.translateService.instant('INVALID_DATE');
    }
  }
}
