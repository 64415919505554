import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output, ViewChild
} from '@angular/core';
import {MenuItem} from '../../../../../../global';
import {MatMenuTrigger} from "@angular/material/menu";

@Component({
  selector: 'slm-table-menu',
  template: `
      <ng-container *ngIf="!!menuItems?.length">
          <slm-button-icon
                  [icon]="icon"
                  class="size-25 block"
                  [ngClass]="icon==='settings'? 'fill-accent': ''"
                  data-test-id="tableDocumentsMenu"
                  [matMenuTriggerFor]="tableMenu"
                  [matTooltip]="'LBL_TABLE.OPTIONS' | translate"
          ></slm-button-icon>
          <ng-template #invalid
          ><span class="w-100 block text-center">--</span></ng-template
          >
          <mat-menu #tableMenu="matMenu">
            <span (mouseleave)="trigger.closeMenu()">
              <div [ngClass]="maxHeight? maxHeight: ''">
                  <ng-container *ngFor="let item of menuItems">
                      <div *slmAccess="item.rights">
                          <div
                                  *ngIf="!item.route"
                                  mat-menu-item
                                  [attr.data-test-id]="item.testId"
                                  class="flex-i flex-items-center flex-start"
                                  (click)="item.action ? fire($event, item.action) : null"
                                  (keyup.enter)="item.action ? fire($event, item.action) : null"
                          >
                              <slm-svg-icon
                                      *ngIf="item.icon"
                                      [icon]="item.icon"
                                      class="fill-accent d-inline-block"
                              ></slm-svg-icon>
                              <h5 class="pl-2 mb-0 txt-medium txt-no-wrap txt-ellipsis d-inline-block w-max-200"
                              >
                                {{ item.name | translate }}
                              </h5>
                          </div>
                          <a
                                  *ngIf="item.route"
                                  [attr.data-test-id]="item.testId"
                                  mat-menu-item
                                  class="flex place-content-center flex-start d-inline-block"
                                  [routerLink]="item.route.toString()"
                          >
                              <slm-svg-icon
                                      *ngIf="item.icon"
                                      [icon]="item.icon"
                                      class="fill-accent d-inline-block vertical-align-middle"
                              ></slm-svg-icon>
                              <h5 class="pl-2 mb-0 txt-medium txt-no-wrap d-inline-block w-max-200">{{ item.name | translate }}</h5>
                          </a>
                      </div>
                  </ng-container>
              </div>
            </span>
          </mat-menu>
      </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableMenuComponent {
  public menuItems: Array<MenuItem> = [];
  private menu: Array<MenuItem> = [];

  @Input('menu')
  public set setMenuItems(items: Array<MenuItem>) {
    this.menu = items;
    this.menuItems = this.getEnabledMenuItems();
  }

  private data: any = null;

  @Input('data')
  public set setData(data: any) {
    this.data = data;
    this.menuItems = this.getEnabledMenuItems();
  }
  private getEnabledMenuItems = () => (this.menu || []).filter(
    (item) => !item.isEnabled || item.isEnabled(this.data)
  ).map(value =>
      ({
        ...value,
        route: typeof value.route === 'function' ? value.route(this.data) : value.route
      })
  );

  @Input() icon = 'more';
  @Input() maxHeight: string;
  @Output() fireEvent = new EventEmitter<string>();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  fire(event: Event, name: string) {
    this.fireEvent.next(name);
  }
}
