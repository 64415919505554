import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
  Currency,
  CustomValidators,
  GeneralSandbox,
  inputErrorTypes,
  InvoicePaymentTypeEnum,
  PaymentFormConfig,
  RightsConfig,
  RightsEnum,
  SelectOption,
  UserSandbox
} from '../../../global';
import {BehaviorSubject, combineLatest, concat, Observable, of, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, switchMap, takeUntil, tap} from 'rxjs/operators';
import {CurrencyTransformPipe} from '../../pipes';

@Component({
  selector: 'slm-payout-template',
  templateUrl: './payout-template.container.html',
  providers: [CurrencyTransformPipe],
  //Need to review after next angular update
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class PayoutTemplateContainer implements OnInit, OnDestroy {
  public readonly rightsToMakePayout: RightsConfig = {
    orRights: [RightsEnum.DOCUMENT_FINANCIAL_APPROVAL_READ, RightsEnum.DOCUMENT_FINANCIAL_SETTINGS_WRITE]
  };

  public readonly errors = [inputErrorTypes.required, inputErrorTypes.amount, inputErrorTypes.max, inputErrorTypes.min];

  @Input() public title = 'INVOICE.PAYOUT_TITLE';
  @Input() public addDefaultElement = false;

  @Input('configData')
  public set setConfigData(data: PaymentFormConfig) {
    this.config$.next(data);
    this.cd.detectChanges();
  }

  @Output()
  public initialized = new EventEmitter<boolean>();
  @Output() formArray = new EventEmitter<UntypedFormArray>();

  public config$ = new BehaviorSubject<PaymentFormConfig>(null);
  public noProformas$: Observable<boolean>;
  public currency$: Observable<Currency>;
  private addedValue = 0;
  public readonly array: UntypedFormArray = this.fb.array([]);
  public arrayItems: UntypedFormGroup[];
  public showDateAndValueFields$: Array<Observable<boolean>> = [];
  public showPaymentNoteField$: Array<Observable<boolean>> = [];
  public isPercentSelected = false;
  private arrayIndex = 0;
  public savedInTransaction$: Observable<string>;

  public readonly typeOptions: Array<SelectOption> = [
    {
      value: InvoicePaymentTypeEnum.CASH,
      label: 'INVOICE.PAYMENT.BANK-TRANSFER'
    },
    {
      value: InvoicePaymentTypeEnum.BANK_CARD,
      label: 'INVOICE.PAYMENT_TYPE.BANK_CARD'
    },
    {
      label: 'INVOICE_TYPE.REQUEST',
      value: InvoicePaymentTypeEnum.PROFORMA
    },
    {
      value: InvoicePaymentTypeEnum.MONEY,
      label: 'INVOICE.PAYMENT_TYPE.MONEY'
    },
    {
      value: InvoicePaymentTypeEnum.COMPENSATION,
      label: 'INVOICE.PAYMENT_TYPE.COMPENSATION'
    },
    {
      value: InvoicePaymentTypeEnum.DISCOUNT,
      label: 'INVOICE.PAYMENT_TYPE.DISCOUNT'
    },
    {
      value: InvoicePaymentTypeEnum.OTHER,
      label: 'INVOICE.PAYMENT_TYPE.OTHER'
    }
  ];
  public prepayment$: Observable<Array<SelectOption>>;

  private destroys: Array<Subject<any>> = [];

  private readonly destroy$ = new Subject();

  constructor(
    private readonly userSandbox: UserSandbox,
    private readonly fb: UntypedFormBuilder,
    private readonly generalSandbox: GeneralSandbox,
    private readonly pricePipe: CurrencyTransformPipe,
    private readonly cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {

    this.currency$ = this.config$.asObservable().pipe(
      tap(config => {
        if(config?.paymentType === 'credit-card'){
          this.arrayItems.forEach(group => {
            const type = group.get('type');
            if(type){
              type.setValue(InvoicePaymentTypeEnum.BANK_CARD);
            }
          });
        }
      }),
      map(config => config?.currency)
    );
    this.formArray.emit(this.array);
    this.prepayment$ = this.config$.pipe(
      filter((value) => !!value),
      tap(() => this.initialized.emit(false)),
      switchMap((data) =>
        this.generalSandbox.getProformas(
          new Date(data.date),
          data.partnerId,
          data.type
        )
      ),
      tap(() => this.initialized.emit(true)),
      map((response) =>
        (response.items || []).map((item) => ({
          label: item.name,
          value: item,
          subLabel: this.pricePipe.transform(item.gross, item.currency, true)
        }))
      ),
      shareReplay({bufferSize: 1, refCount: true})
    );
    this.noProformas$ = combineLatest([this.prepayment$, this.config$]).pipe(
      map(([value, data]) => value && !value.length && !!data)
    );

    this.savedInTransaction$ = this.config$.pipe(
      map(value => value?.savedInTransaction ? this.pricePipe.transform(value?.savedInTransaction, value?.currency) : null)
    );

    if (this.addDefaultElement) {
      this.newAccountingItem();
    }
  }

  ngOnDestroy() {
    this.initialized.emit(true);
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private getValueForm(): UntypedFormGroup {
    const {grossValue, paidValue, currency} = this.config$.getValue() ?? {};
    const maxOutPay = grossValue - ((+paidValue || 0) + this.addedValue);
    const maxOutPayGoodDecimals = ((Math.ceil((maxOutPay - Math.trunc(maxOutPay)) * 1000) / 1000) + Math.trunc(maxOutPay)).toFixed(currency?.precision || 0);
    return this.fb.group({
      date: [new Date(), [Validators.required]],
      value: [
        !!grossValue
          ? +maxOutPayGoodDecimals
          : '',
        [Validators.required, CustomValidators.amount, CustomValidators.absMax(+maxOutPayGoodDecimals), CustomValidators.notZero]
      ]
    });
  }

  private getPrepayment(): UntypedFormGroup {
    return this.fb.group({
      prepayment: ['', [Validators.required]]
    });
  }

  private getValueNoteForm(): UntypedFormGroup {
    const {grossValue, paidValue, currency} = this.config$.getValue() ?? {};
    const maxOutPay = grossValue - ((+paidValue || 0) + this.addedValue);
    const maxOutPayGoodDecimals = ((Math.ceil((maxOutPay - Math.trunc(maxOutPay)) * 1000) / 1000) + Math.trunc(maxOutPay)).toFixed(currency?.precision || 0);
    return this.fb.group({
      date: [new Date(), [Validators.required]],
      value: [
        !!grossValue
          ? +maxOutPayGoodDecimals
          : '',
        [Validators.required, CustomValidators.amount, CustomValidators.absMax(+maxOutPayGoodDecimals), CustomValidators.notZero]
      ],
      note: ['', [Validators.required]]
    });
  }

  private getDiscountForm(): UntypedFormGroup {
    const {grossValue, paidValue} = this.config$.getValue() ?? {};
    const maxOutPay = grossValue - ((+paidValue || 0) + this.addedValue);
    return this.fb.group({
      date: [new Date(), [Validators.required]],
      value: ['', [Validators.required, CustomValidators.amount, CustomValidators.absMax(maxOutPay), CustomValidators.notZero]
      ],
      percent: ['', [Validators.required]]
    });
  }

  public newAccountingItem(): void {
    this.addedValue = this.recalcAddedValue(-1);
    const form: UntypedFormGroup = this.fb.group({
      type: ['cash', [Validators.required]],
      form: this.getValueForm(),
      index: [this.arrayIndex]
    });

    const destroy = new Subject();
    this.destroys.push(destroy);
    const type = form.get('type');

    this.arrayIndex++;
    // watching the type change
    type.valueChanges
      .pipe(takeUntil(destroy))
      .subscribe((value: string) => {
          this.addedValue = this.recalcAddedValue(form.value.index);
          form.setControl(
            'form',
            [InvoicePaymentTypeEnum.CASH, InvoicePaymentTypeEnum.MONEY, InvoicePaymentTypeEnum.BANK_CARD].includes(value as InvoicePaymentTypeEnum)
              ? this.getValueForm()
              : [InvoicePaymentTypeEnum.COMPENSATION, InvoicePaymentTypeEnum.OTHER].includes(value as InvoicePaymentTypeEnum)
                ? this.getValueNoteForm()
                : value === InvoicePaymentTypeEnum.DISCOUNT
                  ? this.getDiscountForm()
                  : this.getPrepayment()
          );
        }
      );

    // watching the value change
    form.valueChanges
      .pipe(
        filter((formValues) => formValues.type !== 'prepayment'),
        map((formValues) =>
          formValues.form.value
        ),
        distinctUntilChanged(),
        takeUntil(destroy))
      .subscribe(() => this.addedValue = this.recalcAddedValue(form.value.index));

    //in case of cash, money, compensation, other, we need date and value field to appear
    this.showDateAndValueFields$.push(concat(of(type.value), type.valueChanges).pipe(
      map((paymentType) => ['cash', 'money', 'compensation', 'other', 'bank-card'].includes(paymentType)),
      distinctUntilChanged()
    ));

    // in case of compensation and other, we need a note field to appear
    this.showPaymentNoteField$.push(concat(of(type.value), type.valueChanges).pipe(
      map((paymentType) => ['compensation', 'other'].includes(paymentType)),
      distinctUntilChanged()
    ));

    this.array.push(form);

    const {grossValue} = this.config$.getValue() ?? {};
    // in case of discount watching the change of the percent and change the value accordingly
    form.valueChanges.pipe(
      filter((value) => value.type === InvoicePaymentTypeEnum.DISCOUNT),
      map((value) =>
        this.isPercentSelected ? value.form?.percent : null
      ),
      distinctUntilChanged(),
      map((percent) => percent ? grossValue * percent / 100 : null),
      takeUntil(destroy)
    ).subscribe(
      amount => {
        form.get('form').get('value').setValue((Math.round(amount * 1000)) / 1000);
      }
    );

    // in case of discount watching the change of the value and change the percent accordingly
    form.valueChanges.pipe(
      filter((value) => value.type === InvoicePaymentTypeEnum.DISCOUNT),
      map((value) =>
        !this.isPercentSelected ? value.form?.value : null
      ),
      distinctUntilChanged(),
      map((value) => value ? (value / grossValue) * 100 : null),
      takeUntil(destroy)
    ).subscribe(
      percentage => {
        if (percentage) {
          form.get('form').get('percent').setValue(Math.round(percentage));
        }
      }
    );
    this.arrayItems = this.array.controls as UntypedFormGroup[];
  }

  // calculate the added values, by summarizing the value fields - except for the typechanged one
  private recalcAddedValue(index: number): number {
    return this.array.value
      .filter((value) => value.index !== index)
      .reduce((value, form) => (+form.form.value || 0) + value, 0);
  }

  public onFocus(isSelected) {
    this.isPercentSelected = isSelected;
  }

  public deleteAccountingItem(index: number): void {
    if (!this.addDefaultElement || this.array.length > 1) {
      this.destroys[index].next(null);
      this.destroys.splice(index, 1);
      this.array.removeAt(index);
      this.showDateAndValueFields$.splice(index, 1);
      this.showPaymentNoteField$.splice(index, 1);
      this.recalcAddedValue(-1);
    }
  }

  public markAllAsTouched() {
    this.array.markAllAsTouched();
    this.cd.detectChanges();
  }
}
