import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  buttonsStaticConfig,
  fileUploadModeOptions, FileUploadModes,
  GeneralSandbox,
  InvoiceDocument,
  NavigationService,
  NotificationEnum,
  NotificationService,
  RoutesEnum
} from '../../../global';
import {catchError, takeUntil, tap} from 'rxjs/operators';
import {Observable, Subject, throwError} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {HttpStatusCode} from '@angular/common/http';

interface DocumentUploadDialogConfig {
  title?: string;
  save?: (documentIds: Array<number>) => Observable<any>;
  fileUploading: (file: File, uploadMode: FileUploadModes, filename: string, userDivisionId?: number) => Observable<any>;
  documentDelete: (documentIds: Array<number | string>) => Observable<any>;
  disabledClose?: boolean;
  attachToExists?: boolean;
  hideUploadOptions?: boolean;
  acceptedFileMimeTypes?: string;
  hideAllOpenIcon?: boolean;
}

@Component({
  selector: 'slm-dialog-upload-dialog',
  templateUrl: './document-upload-dialog.component.html'
})
export class DocumentUploadDialogComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  private multiUpload = false;
  private multiUploadIndex = -1;
  private multiUploadFileIndexes: Array<number> = [];

  public dialogButtons = [buttonsStaticConfig.close];
  public documents: Array<InvoiceDocument> = [];
  public fileUploadModeOptions = fileUploadModeOptions;
  public uploadModeControl: UntypedFormControl = new UntypedFormControl('');
  public userDivisionControl: UntypedFormControl = new UntypedFormControl({value: '', disabled: true});
  public maxFileSizeMB = 8;
  public readonly defaultMimeTypes = 'application/pdf,image/png,image/jpeg,image/jpg';

  public useDivisions$: Observable<boolean>;

  constructor(
    public readonly dialogRef: MatDialogRef<DocumentUploadDialogComponent>,
    public readonly notificationService: NotificationService,
    private readonly router: NavigationService,
    @Inject(MAT_DIALOG_DATA)
    public data: DocumentUploadDialogConfig,
    private readonly generalSandbox: GeneralSandbox,
    private readonly translationService: TranslateService
  ) {
  }

  ngOnInit() {
    this.dialogRef.disableClose = this.data.disabledClose;
    if (this.data.save && !this.data.attachToExists) {
      this.dialogButtons = [...this.dialogButtons, buttonsStaticConfig.save];
    }

    if(!this.data.acceptedFileMimeTypes){
      this.data.acceptedFileMimeTypes = this.defaultMimeTypes;
    }

    this.generalSandbox.fileUploadMode$.pipe(
      tap(value => this.uploadModeControl.setValue(value)),
      takeUntil(this.destroy$)
    ).subscribe();

    this.useDivisions$ = this.generalSandbox.useDivision$;
  }

  unauthorizedFileFormat(event) {
    this.notificationService.notify(
      this.translationService.instant(event.message, event.error === 'size' ? {maxSize: this.maxFileSizeMB} : {}),
      NotificationEnum.ERROR
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public documentEvent(index: number, event: string): void {
    if (this.multiUpload) {
      this.notificationService.notify('MESSAGE.PLEASE_WAIT_FOR_THE_UPLOAD', NotificationEnum.WARNING);
      return;
    }
    switch (event) {
      case 'refresh':
        this.reUploadFile(index);
        break;
      case 'cancel':
        this.cancelUpload(index);
        break;
      case 'remove':
        this.deleteDocument(index);
        break;
      case 'open':
        this.documents[index].hideOpen = true;
        this.router.openInNewTab([RoutesEnum.INVOICES, RoutesEnum.DOCUMENTS_FILLING_FORM], {
          id: this.documents[index].ids,
          selectedId: this.documents[index].ids
        });
    }
  }

  selectedFile(selectedFiles: Array<File>): void {
    if (!selectedFiles || !selectedFiles?.length) {
      return;
    }
    if (this.multiUploadIndex !== -1) {
      this.notificationService.notify('MESSAGE.PLEASE_WAIT_FOR_THE_UPLOAD', NotificationEnum.WARNING);
      return;
    }
    const files = (selectedFiles ?? []).filter(file => !!file);
    this.multiUpload = !!files?.length ?? false;
    this.dialogRef.disableClose = true;
    this.multiUploadFileIndexes.length = 0;
    (files || []).forEach((file) => {
      const index = this.documents.push({
        ids: [],
        name: file.name,
        file,
        progress: 0,
        status: 'uploading',
        subscription: null
      });
      if (this.multiUpload) {
        this.multiUploadFileIndexes.push(index - 1);
      } else {
        this.uploadFile(index - 1, file);
      }
    });
    if (this.multiUpload && this.multiUploadFileIndexes.length) {
      this.multiUploadIndex = 0;
      const index = this.multiUploadFileIndexes[this.multiUploadIndex];
      this.uploadFile(index, this.documents[index].file);
    }
  }

  private goToNextMultiFile() {
    if (this.multiUploadIndex === -1) {
      return;
    }
    if (this.multiUploadIndex < this.multiUploadFileIndexes.length - 1) {
      ++this.multiUploadIndex;
      const index = this.multiUploadFileIndexes[this.multiUploadIndex];
      this.uploadFile(index, this.documents[index].file);
    } else {
      this.multiUploadIndex = -1;
      this.multiUpload = false;
      this.enableClose();
    }
  }

  private uploadFile(index: number, file: File): void {
    this.documents[index].subscription = this.data
      .fileUploading(file, this.uploadModeControl.value, this.documents[index]?.name, this.userDivisionControl.value)
      .pipe(
        catchError((err) => {
          if (+err?.status === HttpStatusCode.PayloadTooLarge) {
            const name = err?.error?.name || '';
            this.documents[index].status = 'timeout';
            this.dialogRef.disableClose = false;
            if (name) {
              this.documents[index].name = name;
            }
          }
          this.unsuccessfulUpload(index, null);
          this.goToNextMultiFile();
          return throwError(err);
        })
      )
      .subscribe((response: any) => {
        switch (response.type) {
          case 'progress':
            this.setProgress(index, response.result);
            break;
          case 'response':
            this.finishUpload(index, response.result);
            if (this.multiUpload) {
              this.goToNextMultiFile();
            }
            break;
        }
      });
  }

  enableClose() {
    const hasUpload = this.documents.find(document => document.status === 'uploading');
    if (!hasUpload && !this.data.save) {
      this.dialogRef.disableClose = false;
    }
  }

  private cancelUpload(index: number): void {
    if (this.isValid(index)) {
      if (!!this.documents[index].subscription) {
        this.documents[index].subscription.unsubscribe();
      }
      this.documents[index].status = 'canceled';
      if (!this.multiUpload) {
        this.enableClose();
      }
    }
  }

  private reUploadFile(index: number): void {
    if (this.isValid(index, 'canceled') || this.isValid(index, 'error') || this.isValid(index, 'timeout')) {
      this.documents[index].progress = 0;
      this.documents[index].status = 'uploading';
      this.dialogRef.disableClose = true;
      this.uploadFile(index, this.documents[index].file);
    }
  }

  private deleteDocument(index: number): void {
    if (this.isValid(index, 'done')) {
      const ids = this.documents[index]?.ids.length > 0 ? this.documents[index]?.ids : [this.documents[index]?.data?.id];
      this.data.documentDelete(ids || []).subscribe();
      this.documents.splice(index, 1);
    }
  }

  private isValid(index: number, status: string = 'uploading'): boolean {
    return (
      index < this.documents.length &&
      index >= 0 &&
      this.documents[index].status === status
    );
  }

  private setProgress(index: number, progress: number): void {
    if (this.isValid(index)) {
      this.documents[index].progress = progress;
    }
  }

  private finishUpload(
    index: number,
    response: { items: Array<number>; name: string }
  ): void {
    if (this.isValid(index)) {
      this.documents[index].ids = response.items || [];
      this.documents[index].name = response.name;
      this.documents[index].progress = 100;
      this.documents[index].status = 'done';
      this.documents[index].data = response;
      if (this.data.save) {
        this.documents[index].hideOpen = true;
        this.documents[index].hideClose = true;

        if(this.data.attachToExists){
          this.saveFileUpload();
        }
      }
      if (!this.multiUpload) {
        this.enableClose();
      }
    }
  }

  private unsuccessfulUpload(index: number, name: string): void {
    if (this.isValid(index)) {
      this.documents[index].status = 'error';
      if (!this.multiUpload) {
        this.enableClose();
      }
      if (!!name) {
        this.documents[index].name = name;
      }
    }
  }

  public closeDialog(event): void {
    if (this.dialogRef.disableClose && !this.data.save) {
      this.notificationService.notify('MESSAGE.FILE_UPLOAD_IN_PROGRESS', NotificationEnum.WARNING);
      return;
    }

    switch (event) {
      case 'save':
        this.saveFileUpload();
        break;
      case 'close':
        const documents = this.createDocumentsObj();
        if (!!documents.length && !this.data.save) {
          this.dialogRef.close(documents);
        } else if(this.data.save && this.data.attachToExists){
          this.dialogRef.close(documents);
        }else{
          this.dialogRef.close();
        }
        break;
    }
  }

  private changeSaveButtonLoadingState(isLoading: boolean){
    if(!this.data.attachToExists){
      this.dialogButtons[1].isLoading = isLoading;
    }
  }

  private createDocumentsObj(){
    return this.documents
      .filter((document) => document.status === 'done')
      .map((document) => ({
        name: document.file.name,
        size: document.file.size,
        ids: document.ids,
        data: document?.data
      }));
  }

  private saveFileUpload(){
    if (this.dialogButtons[1]?.isLoading) {
      return;
    }

    const documents = this.createDocumentsObj();

    if (!documents.length) {
      this.notificationService.notify('INVOICE.NO_SELECTED_FILE', NotificationEnum.WARNING);
      return;
    }

    this.changeSaveButtonLoadingState(true);
    if(!this.data.attachToExists){
      this.dialogButtons[1].isLoading = true;
    }
    this.data.save(
      documents.reduce((previousValue, currentValue) => [...previousValue, ...currentValue.ids], [])
    ).subscribe({
        next: () => {
          if(!this.data.attachToExists){
            this.changeSaveButtonLoadingState(false);
            this.dialogRef.close(documents);
          }
        },
        error: () => {
          this.changeSaveButtonLoadingState(false);
        }
      }
    );
  }
}
