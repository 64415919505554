import {User} from '../entities';
import {RightsEnum} from '../enums';

export const getUserRights = (user: User): User => {
  const userRights = user.rights || [];
  const rights = [];
  userRights.forEach((right: string) => {
    if (right.endsWith('_ADMIN')) {
      const newRightRead = right.replace('_ADMIN', '_READ') as RightsEnum;
      if (!userRights.includes(newRightRead) && !rights.includes(right)) {
        rights.push(newRightRead);
      }
      const newRightWrite = right.replace('_ADMIN', '_WRITE') as RightsEnum;
      if (!userRights.includes(newRightWrite) && !rights.includes(right)) {
        rights.push(newRightWrite);
      }
    } else if (right.endsWith('_WRITE')) {
      const newRight = right.replace('_WRITE', '_READ') as RightsEnum;
      if (!userRights.includes(newRight) && !rights.includes(right)) {
        rights.push(newRight);
      }
    }
    if (!rights.includes(right)) {
      rights.push(right);
    }
  });
  return {
    ...user,
    rights
  };
};
