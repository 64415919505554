import { Paginator } from './paginator.entity';
import { InvoiceTypeEnum } from '../enums';
import {Base, Code} from './general.entity';
import {AccountingItem} from '../../accounting-settings/entities/accounting-template.entity';

export interface AccountingTemplateItem {
  id: number;
  invoicesNumber: number | string;
  name: string | any;
  place: InvoiceTypeEnum | string;
  autoRule?: boolean;
  accountingPeriod: AccountingPeriod;
  accountingItems: Array<AccountingItem>;
}

export interface AccountingPeriod {
  date: {
    begin?: Date | string;
    end?: Date | string;
  };
  id: number;
  name: string;
  verification?: string;
  description?: string;
  selectedYear?: number;
  openYear?: boolean;
}

export interface AccountingMirrorResponse {
  chartOfAccounts: Array<AccountingMirror>;
  paginator: Paginator;
}

export interface AccountingMirror extends Base {
  accountingPeriod: AccountingPeriod;
  ledgerNumber: number;
  accounting1: Code;
  accounting2: Code;
  accounting3: Code;
  accounting4: Code;
}

export interface AccountingMirrorCostLocationItem {
  id: number;
  code: string;
  description: string;
}

export interface CheckResponse {
  error: boolean;
  message: string;
}

export interface DefaultLedgerNumberValue {
  id: number | string;
  name: string;
  ledgerNumber: string;
}
