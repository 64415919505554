<mat-form-field
  class="flex-grow flex"
  [class.text-input--error]="
    control && !control.valid && control.touched && control.dirty
  "
  [class.text-input--required]="isRequiredField  && (control.valid || !control.dirty)"
  [class.text-input--small]="smallInput"
>
  <mat-label *ngIf="label || (!isRequiredField && !hideOptionalLabel)"
    >{{ label | translate
    }} <span *ngIf="secondaryLabel">{{ secondaryLabel | translate }}</span>
    <span *ngIf="!isRequiredField && !hideOptionalLabel" translate="LBL_INPUT.LABEL.NOT_REQUIRED"></span>
  </mat-label>
  <h5 *ngIf="!!prefixLabel && !prefixIcon" class="prefix-label" matPrefix>
    {{ prefixLabel | translate }}
  </h5>
  <slm-svg-icon
    matPrefix
    *ngIf="!!prefixIcon"
    [icon]="prefixIcon"
  ></slm-svg-icon>
  <input
    (keydown.,)="setComma($event)"
    matInput
    [attr.data-test-id]="testId"
    type="text"
    [required]="isRequiredField"
    [class.color-accent-i]="coloredValue"
    decimalMarker="."
    (focusout)="focusOut()"
    [placeholder]="placeholder | translate"
    [formControl]="control"
    [allowNegativeNumbers]="allowNegativeNumber"
    [mask]="decimals"
    (keyup.enter)="enterEnabled ? onClick('enter') : null"
  />
  <slm-svg-icon
    *ngIf="!!suffixIcon"
    matSuffix
    matRipple
    class="fill-grey svg-icon"
    [class.cursor-pointer]="buttonIcon"
    [matTooltip]="suffixIconLabel | translate"
    [matRippleDisabled]="!buttonIcon"
    (click)="buttonIcon ? onClick('suffix') : null"
    [icon]="suffixIcon"
  ></slm-svg-icon>
  <h5 *ngIf="suffixLabel" class="suffix-label" matSuffix>
    {{ suffixLabel | translate }}
  </h5>
  <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
  <mat-error
    *ngIf="control && (control.dirty || control.touched) && control.invalid"
    >{{ getError() | translate: errorBody }}</mat-error
  >
</mat-form-field>
