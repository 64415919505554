import { Injectable } from '@angular/core';
import {MessageService, MessageType} from '../services';

@Injectable({
  providedIn: 'root'
})
export class MessageSandbox {
  constructor(private readonly messageService: MessageService) {}

  public sendMessage = (id: string | number, message: string, messageType: MessageType) =>
    this.messageService.sendMessage(id, message, messageType);

  public updateMessage = (id: number | string, message: string, messageType: MessageType) =>
    this.messageService.updateMessage(id, message, messageType);

  public deleteMessage = (id: number | string, messageType: MessageType) => this.messageService.deleteMessage(id, messageType);
}
