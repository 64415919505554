import {Component, OnInit} from '@angular/core';
import {
  AuthSandbox,
  RoutesEnum,
  NavigationService, NotificationService
} from '../../../modules/global';
import {first} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormControl, Validators} from '@angular/forms';

@Component({
  selector: 'slm-activation',
  templateUrl: './activation.container.html'
})
export class ActivationContainer implements OnInit {

  public readonly acceptControl = new UntypedFormControl('', [Validators.requiredTrue]);

  private token: string = null;
  public loading = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: NavigationService,
    private readonly authSandbox: AuthSandbox,
    private readonly notification: NotificationService
  ) {
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(first())
      .subscribe((params) => {
      this.token = params.get('token');
      if (!this.token) {
        this.router.navigate([RoutesEnum.LOGIN]);
      }
    });
  }

  activateUser() {
    if (this.loading) {
      return;
    }
    if (this.acceptControl.invalid) {
      this.acceptControl.markAllAsTouched();
      return;
    }
    this.loading = true;
    this.authSandbox.activate(this.token)
      .subscribe(() => {
        this.notification.notify('AUTHENTICATION.ACTIVATION_COMPLETED');
        this.router.navigate([RoutesEnum.LOGIN]);
      }, () => this.loading = false);
  }
}
