import { Component, Input } from '@angular/core';
import {
  FinanceReportsProcessing,
  Icon,
  IconColors,
  InvoiceTypeEnum
} from '../../../global';

@Component({
  selector: 'slm-invoice-overview',
  template: `
    <div
      class="grid grid-col-3-33-1 grid-responsive grid-responsive-no-gap"
      *ngIf="data; else loading"
    >
      <div>
        <h5
          class="w-100 txt-start-i border-bottom border-color-light-gray"
          translate
        >
          BY_FILLING
        </h5>
        <div
          class="w-100 p-1 border-bottom border-color-light-gray flex flex-space-btwn"
        >
          <div class="flex">
            <slm-svg-icon
              [icon]="icons.VISIBILITY"
              [color]="colors.BLUE"
              class="mr-2"
            ></slm-svg-icon>
            <div class="line-h-2 txt-medium" translate>
              LBL_TABLE_ATTR.WAIT-CHECKING
            </div>
          </div>

<!--          <a-->
<!--            *slmAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            [routerLink]="link"-->
<!--            [queryParams]="paramsFillingFirst"-->
<!--            class="line-h-2"-->
<!--          >{{ data.fillingStatus.waitChecking }}-->
<!--            {{ 'INVOICE.COUNT_NUM' | translate }}</a-->
<!--          >-->
<!--          <div-->
<!--            *slmRevertHasAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            class="line-h-2"-->
<!--          >-->
          <div class="line-h-2">{{ data.fillingStatus.waitChecking }} {{ 'INVOICE.COUNT_NUM' | translate }}</div>
        </div>
        <div
          class="w-100 p-1 border-bottom border-color-light-gray flex flex-space-btwn"
        >
          <div class="flex">
            <slm-svg-icon
              [icon]="icons.REFRESH"
              [color]="colors.RED"
              class="mr-2"
            ></slm-svg-icon>
            <div class="line-h-2 txt-medium" translate>
              LBL_TABLE_ATTR.BE-REPAIRED
            </div>
          </div>

<!--          <a-->
<!--            *slmAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            [routerLink]="link"-->
<!--            [queryParams]="paramsFillingWaitSecond"-->
<!--            class="line-h-2"-->
<!--          >{{ data.fillingStatus.beRepaired }}-->
<!--            {{ 'INVOICE.COUNT_NUM' | translate }}</a-->
<!--          >-->
<!--          <div-->
<!--            *slmRevertHasAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            class="line-h-2"-->
<!--          >-->
          <div class="line-h-2">{{ data.fillingStatus.beRepaired }} {{ 'INVOICE.COUNT_NUM' | translate }}</div>
        </div>
        <div
          class="w-100 p-1 border-bottom border-color-light-gray flex flex-space-btwn"
        >
          <div class="flex">
            <slm-svg-icon
              [icon]="icons.DESCRIPTION"
              [color]="colors.GREEN"
              class="mr-2"
            ></slm-svg-icon>
            <div class="line-h-2 txt-medium" translate>
              LBL_TABLE_ATTR.FILED
            </div>
          </div>

<!--          <a-->
<!--            *slmAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            [routerLink]="link"-->
<!--            [queryParams]="paramsFillingThird"-->
<!--            class="line-h-2"-->
<!--          >{{ data.fillingStatus.accounted }}-->
<!--            {{ 'INVOICE.COUNT_NUM' | translate }}</a-->
<!--          >-->
<!--          <div-->
<!--            *slmRevertHasAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            class="line-h-2"-->
<!--          >-->
          <div class="line-h-2">{{ data.fillingStatus.accounted }} {{ 'INVOICE.COUNT_NUM' | translate }}</div>
        </div>
      </div>
      <div>
        <h5
          class="w-100 txt-start-i border-bottom border-color-light-gray"
          translate
        >
          BY_ACCOUNTING
        </h5>
        <div
          class="w-100 p-1 border-bottom border-color-light-gray flex flex-space-btwn"
        >
          <div class="flex">
            <slm-svg-icon
              [icon]="icons.TIME"
              [color]="colors.ORANGE"
              class="mr-2"
            ></slm-svg-icon>
            <div class="line-h-2 txt-medium" translate>
              LBL_TABLE_ATTR.WAIT-ACCOUNTING
            </div>
          </div>

<!--          <a-->
<!--            *slmAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            [routerLink]="link"-->
<!--            [queryParams]="paramsAccountingFirst"-->
<!--            class="line-h-2"-->
<!--          >{{ data.accountingStatus.waitAccounting }}-->
<!--            {{ 'INVOICE.COUNT_NUM' | translate }}</a-->
<!--          >-->
<!--          <div-->
<!--            *slmRevertHasAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            class="line-h-2"-->
<!--          >-->
          <div class="line-h-2">{{ data.accountingStatus.waitAccounting }} {{ 'INVOICE.COUNT_NUM' | translate }}</div>
        </div>
        <div
          class="w-100 p-1 border-bottom border-color-light-gray flex flex-space-btwn"
        >
          <div class="flex">
            <slm-svg-icon
              [icon]="icons.VISIBILITY"
              [color]="colors.BLUE"
              class="mr-2"
            ></slm-svg-icon>
            <div class="line-h-2 txt-medium" translate>
              LBL_TABLE_ATTR.WAIT-CHECKING
            </div>
          </div>

<!--          <a-->
<!--            [routerLink]="link"-->
<!--            *slmAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            [queryParams]="paramsAccountingSecond"-->
<!--            class="line-h-2"-->
<!--          >{{ data.accountingStatus.waitChecking }}-->
<!--            {{ 'INVOICE.COUNT_NUM' | translate }}</a-->
<!--          >-->
<!--          <div-->
<!--            *slmRevertHasAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            class="line-h-2"-->
<!--          >-->
          <div class="line-h-2">{{ data.accountingStatus.waitChecking }} {{ 'INVOICE.COUNT_NUM' | translate }}</div>
        </div>
        <div
          class="w-100 p-1 border-bottom border-color-light-gray flex flex-space-btwn"
        >
          <div class="flex">
            <slm-svg-icon
              [icon]="icons.DESCRIPTION"
              [color]="colors.GREEN"
              class="mr-2"
            ></slm-svg-icon>
            <div class="line-h-2 txt-medium" translate>
              LBL_TABLE_ATTR.ACCOUNTED
            </div>
          </div>

<!--          <a-->
<!--            *slmAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            [routerLink]="link"-->
<!--            [queryParams]="paramsAccountingThird"-->
<!--            class="line-h-2"-->
<!--          >{{ data.accountingStatus.accounted }}-->
<!--            {{ 'INVOICE.COUNT_NUM' | translate }}</a-->
<!--          >-->
<!--          <div-->
<!--            *slmRevertHasAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            class="line-h-2"-->
<!--          >-->
          <div class="line-h-2">{{ data.accountingStatus.accounted }} {{ 'INVOICE.COUNT_NUM' | translate }}</div>
        </div>
      </div>
      <div>
        <h5
          class="w-100 txt-start-i border-bottom border-color-light-gray"
          translate
        >
          BY_PAYMENT
        </h5>
        <div
          class="w-100 p-1 border-bottom border-color-light-gray flex flex-space-btwn"
        >
          <div class="flex">
            <slm-svg-icon
              [icon]="icons.BANKING"
              [color]="colors.ORANGE"
              class="mr-2"
            ></slm-svg-icon>
            <div class="line-h-2 txt-medium" translate>LBL_TABLE_ATTR.PAYABLE</div>
          </div>

<!--          <a-->
<!--            *slmAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            [routerLink]="link"-->
<!--            [queryParams]="paramsPaymentFirst"-->
<!--            class="line-h-2"-->
<!--          >{{ data.paymentStatus.payable }}-->
<!--            {{ 'INVOICE.COUNT_NUM' | translate }}</a-->
<!--          >-->
<!--          <div-->
<!--            *slmRevertHasAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            class="line-h-2"-->
<!--          >-->
          <div class="line-h-2">{{ data.paymentStatus.payable }} {{ 'INVOICE.COUNT_NUM' | translate }}</div>
        </div>


        <div
          *ngIf="showSentToBank"
          class="w-100 p-1 border-bottom border-color-light-gray flex flex-space-btwn"
        >
          <div class="flex">
            <slm-svg-icon
              [icon]="icons.SYNC_ALT"
              [color]="colors.ORANGE"
              class="mr-2"
            ></slm-svg-icon>
            <div class="line-h-2 txt-medium" translate>LBL_TABLE_ATTR.SENT-TO-BANK</div>
          </div>
          <div class="line-h-2">{{ data.paymentStatus?.sentToBank || 0 }} {{ 'INVOICE.COUNT_NUM' | translate }}</div>
        </div>

        <div
          class="w-100 p-1 border-bottom border-color-light-gray flex flex-space-btwn"
        >
          <div class="flex">
            <slm-svg-icon
              [icon]="icons.VISIBILITY"
              [color]="colors.BLUE"
              class="mr-2"
            ></slm-svg-icon>
            <div class="line-h-2 txt-medium" translate>LBL_TABLE_ATTR.WAIT-APPROVE</div>
          </div>

<!--          <a-->
<!--            *slmAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            [routerLink]="link"-->
<!--            [queryParams]="paramsPaymentWaitSecond"-->
<!--            class="line-h-2"-->
<!--          >{{ data.paymentStatus.waitApprove || 0 }}-->
<!--            {{ 'INVOICE.COUNT_NUM' | translate }}</a-->
<!--          >-->
<!--          <div-->
<!--            *slmRevertHasAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            class="line-h-2"-->
<!--          >-->
          <div class="line-h-2">{{ data.paymentStatus.waitApprove || 0 }} {{ 'INVOICE.COUNT_NUM' | translate }}</div>
        </div>
        <div
          class="w-100 p-1 border-bottom border-color-light-gray flex flex-space-btwn"
        >
          <div class="flex">
            <slm-svg-icon
              [icon]="icons.CHECK_CIRCLE"
              [color]="colors.GREEN"
              class="mr-2"
            ></slm-svg-icon>
            <div class="line-h-2 txt-medium" translate>LBL_TABLE_ATTR.PAID</div>
          </div>

<!--          <a-->
<!--            *slmAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            [routerLink]="link"-->
<!--            [queryParams]="paramsPaymentThird"-->
<!--            class="line-h-2"-->
<!--          >{{ data.paymentStatus.paid }}-->
<!--            {{ 'INVOICE.COUNT_NUM' | translate }}</a-->
<!--          >-->
<!--          <div-->
<!--            *slmRevertHasAccess="rights.DOCUMENT_LISTING_READ"-->
<!--            class="line-h-2"-->
<!--          >-->
          <div class="line-h-2">{{ data.paymentStatus.paid }} {{ 'INVOICE.COUNT_NUM' | translate }}</div>
        </div>
      </div>
    </div>

    <ng-template #loading>
      <slm-loader class="block m-auto mt-5 mb-5"></slm-loader>
    </ng-template>
  `
})
export class InvoiceOverviewComponent {
  public readonly icons = Icon;
  public readonly colors = IconColors;
 // public readonly rights = RightsEnum;
 //  public paramsPaymentFirst = { page: 1, size: 10, paymentStatus: 'payable' };
 //  public paramsPaymentWaitSecond = { page: 1, size: 10, paymentStatus: 'wait-approve' };
 //  public paramsPaymentThird = { page: 1, size: 10, paymentStatus: 'paid' };
 //
 //  public paramsAccountingFirst = { page: 1, size: 10, accountingStatus: 'wait-accounting' };
 //  public paramsAccountingSecond = { page: 1, size: 10, accountingStatus: 'wait-checking' };
 //  public paramsAccountingThird = { page: 1, size: 10, accountingStatus: 'accounted' };
 //
 //  public paramsFillingFirst = { page: 1, size: 10, fillingStatus: 'wait-checking' };
 //  public paramsFillingWaitSecond = { page: 1, size: 10, fillingStatus: 'be-repaired' };
 //  public paramsFillingThird = { page: 1, size: 10, fillingStatus: 'accounted' };
  @Input('params')
  public set setParams(params: any) {
    // if (!!params) {
    //   this.paramsPaymentThird = { page: 1, size: 10, paymentStatus: 'paid', ...params};
    //   this.paramsPaymentWaitSecond = { page: 1, size: 10, paymentStatus: 'wait-approve', ...params};
    //   this.paramsPaymentFirst = { page: 1, size: 10, paymentStatus: 'payable', ...params};
    //   this.paramsFillingFirst = { page: 1, size: 10, fillingStatus: 'wait-checking', ...params};
    //   this.paramsFillingWaitSecond = { page: 1, size: 10, fillingStatus: 'be-repaired', ...params };
    //   this.paramsFillingThird = { page: 1, size: 10, fillingStatus: 'accounted', ...params };
    //   this.paramsAccountingFirst = { page: 1, size: 10, accountingStatus: 'wait-accounting', ...params };
    //   this.paramsAccountingSecond = { page: 1, size: 10, accountingStatus: 'wait-checking', ...params };
    //   this.paramsAccountingThird = { page: 1, size: 10, accountingStatus: 'accounted', ...params };
    // } else {
    //   this.paramsPaymentThird = { page: 1, size: 10, paymentStatus: 'paid' };
    //   this.paramsPaymentWaitSecond = { page: 1, size: 10, paymentStatus: 'wait-approve', ...params};
    //   this.paramsPaymentFirst = { page: 1, size: 10, paymentStatus: 'payable', ...params};
    //   this.paramsFillingFirst = { page: 1, size: 10, fillingStatus: 'wait-checking' };
    //   this.paramsFillingWaitSecond = { page: 1, size: 10, fillingStatus: 'be-repaired' };
    //   this.paramsFillingThird = { page: 1, size: 10, fillingStatus: 'accounted' };
    //   this.paramsAccountingFirst = { page: 1, size: 10, accountingStatus: 'wait-accounting' };
    //   this.paramsAccountingSecond = { page: 1, size: 10, accountingStatus: 'wait-checking', ...params };
    //   this.paramsAccountingThird = { page: 1, size: 10, accountingStatus: 'accounted' };
    // }
  }
  @Input() public type: InvoiceTypeEnum | string = InvoiceTypeEnum.OUTGOING;
  @Input() public data: FinanceReportsProcessing = null;
  @Input() public link = '';
  @Input() public showSentToBank = false;
}
