import { Directive, OnInit } from '@angular/core';
import {
  AmountListResponseNew,
  defaultPage,
  ListResponse,
  ListResponseNew,
  PageResponse,
  PaginatorNew,
  SelectionButton,
  TableColumn,
  TableFilter,
  TableOrder
} from '../../../global';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

@Directive()
export abstract class PageTableContainerDirective implements OnInit {
  private _filters: any = null;
  private _filterIsOpen = false;
  private _search = '';
  private _order: TableOrder = null;
  private _page: PaginatorNew = defaultPage;
  private _selection: Array<any> = [];
  public filterConfig: Array<TableFilter> = null;
  public selectionAttributes: Array<string> = ['id'];
  public tableConfig: Array<TableColumn> = null;
  public selectionButtons: Array<SelectionButton> = null;
  public amountSummary$ = new BehaviorSubject(null);

  public data$: Observable<Array<any>>;

  private readonly refresh$ = new BehaviorSubject<boolean>(true);
  private readonly _isLoading$ = new BehaviorSubject<boolean>(true);
  private readonly _filters$ = new BehaviorSubject<any>(null);
  private readonly _filterIsOpen$ = new BehaviorSubject<boolean>(false);
  private readonly _search$ = new BehaviorSubject<string>(null);
  private readonly _order$ = new BehaviorSubject<TableOrder>(null);
  private readonly _page$ = new BehaviorSubject<PaginatorNew>(this._page);

  public readonly isLoading$ = this._isLoading$.asObservable();
  public readonly filters$ = this._filters$
    .asObservable()
    .pipe(debounceTime(250));
  public readonly search$ = this._search$.asObservable();
  public readonly filterIsOpen$ = this._filterIsOpen$.asObservable();
  public readonly order$ = this._order$.asObservable();
  public readonly page$ = this._page$.asObservable();

  protected constructor() {}

  ngOnInit() {
    this.data$ = this.refresh$.pipe(
      debounceTime(250),
      tap(() => this._isLoading$.next(true)),
      switchMap(() =>
        this.loadData(this.search, this.page, this.order, this.filters)
      ),
      filter((value) => !!value),
      map(
        (
          data: AmountListResponseNew<any> | PageResponse<any> | ListResponse<any> | ListResponseNew<any>
        ) => {
          const keys = (Object.keys(data || {}) || []).filter(
            (key) => key !== 'paginator'
          );
          const paginator: any = data?.paginator;
          if (paginator) {
            const receivedPaginator: PaginatorNew = {
              page: +(paginator?.page || paginator?.actualPage || 1),
              size: +(paginator?.pageSize || paginator?.size || 10),
              fullLength: +(paginator?.fullLength || 0)
            };
            const items = !!keys.length ? data[keys[0]] : [];
            if (!!data['amounts'] && (items?.length ?? 0) > 0) {
              this.amountSummary$.next({
                amounts: data['amounts'],
                allAmount: data['allAmount']
              });
            } else {
              this.amountSummary$.next(null);
            }
            this.setPage(receivedPaginator);
          }
          this._isLoading$.next(false);
          return this.formatItems(!!keys.length ? data[keys[0]] : []);
        }
      )
    );
  }

  protected refresh(): void {
    this.refreshData(this.search, this.page, this.order, this.filters);
  }

  public formatItems(items): Array<any> {
    return items;
  }

  public get filterIsOpen(): boolean {
    return this._filterIsOpen;
  }

  public openFilter() {
    this._filterIsOpen = !this._filterIsOpen;
    this._filterIsOpen$.next(this._filterIsOpen);
  }

  public get filters(): any {
    return this._filters || {};
  }

  public set filters(value: any) {
    this._page = { ...this._page, page: 1 };
    this.setFilters(value);
    this.refresh();
  }

  protected setFilters(value) {
    this._filters = value || {};
    this._filters$.next(value);
  }

  public get search(): string {
    return this._search || null;
  }

  public set search(value: string) {
    if (typeof value === 'string') {
      this._page = { ...this._page, page: 1 };
      this.setSearch(value);
      this.refresh();
    }
  }

  public setSearch(value: string) {
    this._search = value || null;
    this._search$.next(this._search);
  }

  public get order(): TableOrder {
    return this._order;
  }

  public set order(value: TableOrder) {
    const order: TableOrder =
      !!value && !!value.attribute && !!value.order
        ? { attribute: value.attribute, order: value.order }
        : {
            attribute: null,
            order: null
          };
    this.setOrder(order);
    this.refresh();
  }

  public setOrder(value: TableOrder) {
    this._order = !!value && !!value.attribute && !!value.order ? value : null;
    this._order$.next(this._order);
  }

  public get page(): PaginatorNew {
    return this._page;
  }

  public set page(value: PaginatorNew) {
    this.setPage(value);
    this.refresh();
  }

  private setPage(page: PaginatorNew) {
    this._page = page;
    this._page$.next(page);
  }

  public get isSelected(): boolean {
    return !!(this._selection || []).length;
  }

  public get selection(): Array<any> {
    return this._selection || [];
  }

  public set selection(value: Array<any>) {
    this._selection = value || [];
  }

  protected resetFilters(): void {
    this._filters$.next((this._filters = {}));
    this._search$.next((this._search = ''));
    this._page$.next(
      (this._page = { page: 1, size: this._page?.size || 10, fullLength: 0 })
    );
    this.order = null;
  }

  public refreshData(
    search: string,
    page: PaginatorNew,
    order: TableOrder,
    filters: any
  ): boolean {
    return true;
  }

  abstract loadData(
    search: string,
    page: PaginatorNew,
    order: TableOrder,
    filters: any
  ): any;
}
