import {Base, FileManagerRightType, InternalMessage, InvoiceTypeEnum} from '../index';
import {FileType, ItemType} from '../enums';

export const mainFolderId = 'root';

export interface UserRightsData {
  id: number;
  right: FileManagerRightType;
}

export interface Folder {
  id: string;
	name: string;
  uploadDate?: string;
  uploader?: Base;
  rights?: Array<UserRightsData>;
  isPrivate?: boolean;
}

export interface File {
  id: string;
  name: string;
  fileType?: FileType;
  size?: number;
  uploadDate?: string;
  uploader?: Base;
}

export interface AttachedInvoices {
  id: number;
  invoiceNumber: string;
  partnerName: string;
  type: InvoiceTypeEnum.INCOMING | InvoiceTypeEnum.OUTGOING
}

export interface FileDetailed extends File {
  internalMessages: Array<InternalMessage>;
  attachedInvoices: Array<AttachedInvoices>
  previewData?: string;
}

export interface FoldersAndFiles{
  currentFolder: Folder;
  parents: Array<Folder>;
  files: Array<File>;
  folders: Array<Folder>;
}

export interface FileManagerTableItems {
  id: string;
  name: string;
  type: ItemType;
  uploadDate: string;
  uploaderName: string;
  fileType?: FileType;
  size?: number;
  isEnabledToEdit?: boolean;
}

