import {createAction, props} from '@ngrx/store';
import {
  AuthorizationObject,
  Company,
  Currency,
  General,
  ListResponseNew,
  SystemNotification,
  User, VatCode
} from '../entities';
import {DefaultLedgerNumber} from '../../accounting-settings/entities/default-invoice-entity';
import {DefaultLedgerNumberTypes, InvoiceType} from '../enums';

export enum GlobalActionTypes {
  SetLanguage = '[Global] set language',
  SetToken = '[Global] set token',
  SetAuthorizationData = '[Global] set authorization data',
  LoadUser = '[Global User] load User',
  FetchCompanyCurrency = '[Global currency] fetch currency',
  LoadCompanyCurrency = '[Global currency] load currency',
  ClearCompanyCurrency = '[Global currency] clear currency',
  FetchCompanyCurrencies = '[Global currency] fetch currencies',
  LoadCompanyCurrencies = '[Global currency] load currencies',
  ClearCompanyCurrencies = '[Global currency] clear currencies',
  ChangeCompany = '[Global User] change company',
  LoadCompany = '[Global user] load company',
  ReloadUser = '[Global User] Reload user data',
  ClearUserState = '[Global User] clear user',
  ClearGlobalState = '[Global] clear global',
  SetCompanyIdAndToken = '[Global] set token and companyId',
  SetAuthentication = '[Global] set authentication data',
  RefreshGeneral = '[Global] refresh general data',
  SetGeneral = '[Global] set general data',
  RefreshSystemNotifications = '[Global] refresh system notifications',
  SetSystemNotifications = '[Global] set system notifications',
  LoadNotifications = '[Global] refresh notifications',
  SetNotifications = '[Global] set notifications',
  LoadVatCodes = '[Global] load vat coded',
  SetVatCodes = '[Global] set vat codes',
  ClearVatCodes = '[Global] clear vat codes',

  LoadDefaultLedgerNumber = '[Global] load default ledger number',
  SetDefaultLedgerNumber = '[Global] set default ledger number',
  ClearDefaultLedgerNumber = '[Global] clear default ledger number',
  ClearAllDefaultLedgerNumber = '[Global] clear all default ledger number',
  ClearUseOnTransactionStatus = '[Global] clear useOnTransactionStatus value',

  //Invoice List Actions
  GetInvoiceListSettings = '[InvoiceListSettings] get invoice table column settings',
  SetInvoiceListSettings = '[InvoiceListSettings] set invoice table column settings',
}

const setLanguage = createAction(GlobalActionTypes.SetLanguage, props<{ language: string }>());

const setToken = createAction(GlobalActionTypes.SetToken, props<{ token: string }>());

const setAuthorizationData = createAction(GlobalActionTypes.SetAuthorizationData,
  props<{ token: string; refreshToken: string }>());

const changeCompany = createAction(GlobalActionTypes.ChangeCompany,
  props<{ companyId: number }>());

const setAuthentication = createAction(GlobalActionTypes.SetAuthentication,
  props<{ authorization: AuthorizationObject }>());

const setTokenAndCompanyId = createAction(GlobalActionTypes.SetCompanyIdAndToken,
  props<{ token: string; companyId: number }>());

const loadUser = createAction(GlobalActionTypes.LoadUser,
  props<{ user: User }>());

const clearGlobalState = createAction(GlobalActionTypes.ClearGlobalState);

const clearUserState = createAction(GlobalActionTypes.ClearUserState);

const reloadUser = createAction(GlobalActionTypes.ReloadUser);

const fetchCompanyCurrency = createAction(GlobalActionTypes.FetchCompanyCurrency);

const loadCompanyCurrency = createAction(GlobalActionTypes.LoadCompanyCurrency, props<{ currency: Currency }>());

const clearCompanyCurrency = createAction(GlobalActionTypes.ClearCompanyCurrency);

const fetchCompanyCurrencies = createAction(GlobalActionTypes.FetchCompanyCurrencies);

const loadCompanyCurrencies = createAction(GlobalActionTypes.LoadCompanyCurrencies,
  props<{ currencies: Array<Currency> }>());

const clearCompanyCurrencies = createAction(GlobalActionTypes.ClearCompanyCurrencies);

const loadCompany = createAction(GlobalActionTypes.LoadCompany, props<{ partner: Company }>());

const refreshGeneral = createAction(GlobalActionTypes.RefreshGeneral);

const setGeneral = createAction(GlobalActionTypes.SetGeneral, props<{ general: General }>());

const refreshSystemNotifications = createAction(GlobalActionTypes.RefreshSystemNotifications);

const setSystemNotifications = createAction(GlobalActionTypes.SetSystemNotifications,
  props<{ systemNotifications: Array<SystemNotification> }>());

const loadNotifications = createAction(GlobalActionTypes.LoadNotifications, props<{ page: number }>());

const setNotifications = createAction(GlobalActionTypes.SetNotifications,
  props<{ response: ListResponseNew<SystemNotification> }>());

const loadVatCodes = createAction(GlobalActionTypes.LoadVatCodes);

const setVatCodes = createAction(GlobalActionTypes.SetVatCodes,
  props<{ vatCodes: Array<VatCode> }>());

const clearVatCodes = createAction(GlobalActionTypes.ClearVatCodes);

const setDefaultLedgerNumber = createAction(GlobalActionTypes.SetDefaultLedgerNumber, props<{
  ledgerNumber: DefaultLedgerNumber;
  ledgerNumberType: DefaultLedgerNumberTypes;
}>());

const loadDefaultLedgerNumber = createAction(GlobalActionTypes.LoadDefaultLedgerNumber, props<{ ledgerNumberType: DefaultLedgerNumberTypes }>());

const clearDefaultLedgerNumber = createAction(GlobalActionTypes.ClearDefaultLedgerNumber, props<{ ledgerNumberType: DefaultLedgerNumberTypes }>());
const clearAllDefaultLedgerNumber = createAction(GlobalActionTypes.ClearAllDefaultLedgerNumber);

const clearUseOnTransactionStatus = createAction(GlobalActionTypes.ClearUseOnTransactionStatus);

const getInvoiceListSettings = createAction(GlobalActionTypes.GetInvoiceListSettings, props<{ target: InvoiceType }>());

const setInvoiceListSettings = createAction(GlobalActionTypes.SetInvoiceListSettings, props<{
  target: InvoiceType;
  settings: Array<string>;
}>());

export const globalActions = {
  setLanguage,
  setToken,
  setAuthorizationData,
  changeCompany,
  setAuthentication,
  setTokenAndCompanyId,
  loadUser,
  clearGlobalState,
  clearUserState,
  reloadUser,
  fetchCompanyCurrency,
  loadCompanyCurrency,
  clearCompanyCurrency,
  fetchCompanyCurrencies,
  loadCompanyCurrencies,
  clearCompanyCurrencies,
  loadCompany,
  refreshGeneral,
  setGeneral,
  refreshSystemNotifications,
  setSystemNotifications,
  loadNotifications,
  setNotifications,
  loadVatCodes,
  setVatCodes,
  clearVatCodes,
  setDefaultLedgerNumber,
  loadDefaultLedgerNumber,
  clearDefaultLedgerNumber,
  clearAllDefaultLedgerNumber,
  clearUseOnTransactionStatus,
  getInvoiceListSettings,
  setInvoiceListSettings
};
