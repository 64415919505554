<mat-form-field
  [class.text-input--required]="isRequiredField  && (control.valid || !control.dirty)"
  class="flex-grow"
  [class.text-input--error]="
    !control?.valid && control?.touched && control?.dirty
  "
  [class.warning-input-style]="!!customWarningMsg"
  (focusout)="focusOut()"
>
  <mat-label *ngIf="label || (!isRequiredField && !hideOptionalLabel)"
  >{{ label | translate }}
    <span *ngIf="secondaryLabel"> {{ secondaryLabel | translate }}</span>
    <span *ngIf="!isRequiredField && !hideOptionalLabel" translate="LBL_INPUT.LABEL.NOT_REQUIRED"></span>
  </mat-label>
  <slm-svg-icon matPrefix *ngIf="prefixIcon" [icon]="prefixIcon"></slm-svg-icon>
  <input
    matInput
    [required]="isRequiredField"
    [attr.data-test-id]="testId"
    uppercaseFormat
    #autocompleteInputField
    [enableUppercase]="upperCase"
    [type]="type"
    [placeholder]="placeholder | translate"
    [formControl]="control"
    [matAutocomplete]="auto"
    [maxlength]="maxLength"
    (keyup.enter)="enterEnabled ? onClick('enter') : null"
  />

  <div *ngIf="!loading && !suffixIcon && clearButton && control?.value"
       matSuffix
       class="radius-infinite pos-relative top-4"
       matRipple
       (click)="clear()">
    <slm-svg-icon class="fill-accent" icon="close"></slm-svg-icon>
  </div>
  <slm-svg-icon
    *ngIf="!loading && suffixIcon && type !== 'search'"
    matSuffix
    matRipple
    class="fill-grey"
    [matTooltip]="suffixIconLabel | translate"
    [matRippleDisabled]="!buttonIcon"
    (click)="buttonIcon ? onClick('suffix') : null"
    [icon]="suffixIcon"
  ></slm-svg-icon>

  <slm-svg-icon
    *ngIf="!loading && type === 'search' && control?.value"
    matSuffix
    class="fill-grey"
    matRipple
    [matRippleDisabled]="!buttonIcon"
    (click)="clear()"
    icon="close"
  ></slm-svg-icon>
  <slm-loader *ngIf="loading" diameter="24" class="block top-4" matSuffix></slm-loader>
  <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
  <mat-hint *ngIf="maxLength" align="end"
  >{{ control?.value?.length || 0 }} / {{ maxLength }}</mat-hint
  >
  <mat-hint *ngIf="customWarningMsg">{{ customWarningMsg | translate }}</mat-hint>
  <mat-error *ngIf="(control?.dirty || control?.touched) && control?.invalid">{{
    getError() | translate: errorBody
    }}</mat-error>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayValue()">
    <ng-container *ngIf="options">
      <mat-option *ngFor="let option of options; let index = index"
                  [value]="option"
                  [attr.data-test-id]="testId + index"
                  class="h-min-3r h-fit-content-i pt-1-i pb-1-i"
                  (click)="disableLoading()">
        <div class="txt-medium">{{ option.label | translate }}</div>
        <span *ngIf="option.subLabel" class="mt-1 block txt-normal-wrap">{{ option.subLabel | translate }}</span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
