import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ErrorHandlingService} from './error-handling.service';
import {
  Paginator,
  TableOrder,
  AccountingMirrorResponse,
  VatResponse,
  ListResponseNew,
  PaginatorNew,
  AccountingTemplateItem,
  Code
} from '../entities';
import {DefaultLedgerNumberTypes, InvoiceType, InvoiceTypeEnum} from '../enums';
import {Observable} from 'rxjs';
import {capitalizeFirstLetter, utils} from '../functions';
import {catchError, retry} from 'rxjs/operators';
import {DefaultLedgerNumber} from '../../accounting-settings/entities/default-invoice-entity';

@Injectable({providedIn: 'root'})
export class GlobalAccountingService {
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandler: ErrorHandlingService
  ) {
  }

  public getAccountingTemplates(
    page: PaginatorNew = null,
    order: TableOrder = null,
    search: string = null,
    periodId: number = null,
    date: string = null,
    type: InvoiceTypeEnum = null,
    partnerId: number
  ): Observable<ListResponseNew<AccountingTemplateItem>> {
    return this.http
      .get('/accounting-templates', {
        params: {
          ...utils.paginatorToQueryParams(page),
          ...(!!date ? {date} : {}),
          ...(!!type ? {type} : {}),
          ...utils.tableOrderToQueryParams(order),
          ...(!!search ? {search: encodeURIComponent(search)} : {}),
          ...(!!periodId ? {periodId: `${periodId}`} : {}),
          ...(!!partnerId ? {partnerId: `${partnerId}`} : {})
        }
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getLedgerNumbers(
    page: Paginator = null,
    order: TableOrder,
    search: string = null,
    periodId: number = null,
    date: Date = null
  ): Observable<AccountingMirrorResponse> {
    return this.http
      .get('/chart-of-accounts/list', {
        params: {
          ...!!page ? {
            itemsPerPage: `${page?.pageSize || 10}`,
            pageNumber: `${page?.actualPage || 1}`
          } : {},
          ...(!!order
            ? {
              [`order${capitalizeFirstLetter(order.attribute)}`]: order.order
            }
            : {}),
          ...(!!search ? {filterSearch: encodeURIComponent(search)} : {}),
          ...(!!periodId ? {periodId: `${periodId}`} : {}),
          ...(!!date ? {date: `${utils.dateToServerDate(date)}`} : {})
        }
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getAccountingVatCodes(
    page: Paginator,
    order: TableOrder,
    search: string = null,
    active: boolean = true,
    inactive: boolean = true
  ): Observable<VatResponse> {
    return this.http
      .get('/vat-code/list', {
        params: {
          itemsPerPage: `${page?.pageSize || 10}`,
          pageNumber: `${page?.actualPage || 1}`,
          ...(order
            ? {
              [`order${capitalizeFirstLetter(order.attribute)}`]: order.order
            }
            : {}),
          ...(search ? {filterSearch: encodeURIComponent(search)} : {}),
          active: `${active}`,
          inactive: `${inactive}`
        }
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getDeductibleVatCodes(
    page: PaginatorNew,
    order: TableOrder
  ): Observable<ListResponseNew<Code>> {
    return this.http
      .get('/vatcodes/deductible', {
        params: {
          itemsPerPage: `${page?.size || 10}`,
          pageNumber: `${page?.page || 1}`,
          ...(order
            ? {
              [`order${capitalizeFirstLetter(order.attribute)}`]: order.order
            }
            : {})
        }
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getDefaultInvoice(type: DefaultLedgerNumberTypes): Observable<DefaultLedgerNumber> {
    return this.http
      .get('/ledger/accounts', { params: { type } })
      .pipe(
        retry(1),
        catchError((err) => this.errorHandler.handleError(err)));
  }

  public checkRuleForDateAndCompany(date: Date | string, companyId: number, type: InvoiceType): Observable<{ ruleForCompanyExists: boolean }> {
    return this.http
      .get(`/auto-accounting-rule/${date}/${companyId}/${type}`, {})
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }
}
