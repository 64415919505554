import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormControl} from '@angular/forms';
import {concat, Observable, of} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {Icon} from '../../../global';

@Component({
  selector: 'slm-password-strength-presenter',
  template: `

    <div *ngIf="passwordStrength$ | async as strength">
      <div class="flex flex-items-center m-2">
        <div *ngIf="!strength.length else fulfilled"
             class="block p-1 ml-1 mr-1 h-0 radius-infinite bg-color-grey"></div>
        <h5 class="pl-2 txt-start-i mb-0" translate>AUTHENTICATION.LEAST_LENGTH</h5>
      </div>
      <div class="flex m-2 flex-items-center">
        <div *ngIf="!strength.uppercase else fulfilled"
             class="block p-1 ml-1 mr-1 h-0 radius-infinite bg-color-grey"></div>
        <h5 class="pl-2 txt-start-i mb-0" translate>AUTHENTICATION.LEAST_UPPERCASE</h5>
      </div>
      <div class="flex m-2 flex-items-center">
        <div *ngIf="!strength.lowercase else fulfilled"
             class="block p-1 ml-1 mr-1 h-0 radius-infinite bg-color-grey"></div>
        <h5 class="pl-2 txt-start-i mb-0" translate>AUTHENTICATION.LEAST_LOWERCASE</h5>
      </div>
      <div class="flex m-2 flex-items-center">
        <div *ngIf="!strength.number else fulfilled"
             class="block p-1 ml-1 mr-1 h-0 radius-infinite bg-color-grey"></div>
        <h5 class="pl-2 txt-start-i mb-0" translate>AUTHENTICATION.LEAST_NUMBER</h5>
      </div>
    </div>
    <ng-template #fulfilled>
      <slm-svg-icon [icon]="icons.CHECK_CIRCLE"
                    class="fill-green w-10px size-16px" size="16px"></slm-svg-icon>
    </ng-template>
  `
})
export class PasswordStrengthPresenterComponent implements OnInit {
  public readonly icons = Icon;
  @Input() control: UntypedFormControl | AbstractControl;

  public passwordStrength$: Observable<{
    length: boolean;
    uppercase: boolean;
    lowercase: boolean;
    number: boolean;
  }>;

  ngOnInit() {
    if (this.control) {
      this.passwordStrength$ = concat(of(''), this.control.valueChanges).pipe(
        debounceTime(150),
        distinctUntilChanged(),
        map((value: string) => {
          const strength = {
            length: false,
            uppercase: false,
            lowercase: false,
            number: false
          };
          if (!value) {
            return strength;
          }
          if (value.length >= 8) {
            strength.length = true;
          }
          if (value.toLowerCase() !== value) {
            strength.uppercase = true;
          }
          if (value.toUpperCase() !== value) {
            strength.lowercase = true;
          }

          if (/[0-9]/.test(value)) {
            strength.number = true;
          }

          return strength;
        })
      );
    }
}

}
