import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CompanySandbox} from '../../../global';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'slm-aggreg8-package',
  templateUrl: './aggreg8-package.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Aggreg8PackageContainer implements OnInit {

  public companyName$: Observable<string>;

  constructor(private companySandbox: CompanySandbox) {
  }

  ngOnInit(): void {
    this.companyName$ = this.companySandbox.activeCompany$.pipe(
      map(company => company?.partnerName ? company.partnerName : '')
    );
  }
}
