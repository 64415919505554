import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { ButtonConfig, FireEvent, KeyValue } from '../../../global';

@Component({
  selector: 'slm-dialog-container',
  templateUrl: './dialog-container.component.html'
})
export class DialogContainerComponent {
  @Input() public title = '';
  @Input() public buttons: Array<ButtonConfig> = [];
  @Input() public disabledButtons: Array<KeyValue> = [];
  @Input() public disableContainerPadding = false;
  @Input() public overflow: 'auto' | 'hidden' = 'auto';

  @Output() public fireEvent = new EventEmitter<FireEvent>();

  @HostListener('window:keyup.esc', ['$event']) onKeyUp(event) {
    event.stopPropagation();
    this.fireEvent.next({ event: 'cancel' });
  }

  public isDisabled(action: string): boolean {
    return !!(this.disabledButtons || []).find(
      (state) => state.key === action && state.value
    );
  }
}
