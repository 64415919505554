export enum NotificationEnum {
  ERROR = 'error', // red notification
  INFO = 'info', // blue notification
  WARNING = 'warning', // orange notification
  SUCCESS = 'success' // green notification
}

export enum NotificationTimes {
  DEFAULT = 5_000, // 5 sec
  LONG = 15_000, // 15 sec
  INFINITE = -1,
}
