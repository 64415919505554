import {Pipe, PipeTransform} from '@angular/core';
import {Currency, defaultCurrency, utils} from '../../global';

/**
 * This is the currency transform pipe.
 * This formats the currency value, and add currency symbol if it necessary.
 */
@Pipe({
  name: 'currencyTransform'
})
export class CurrencyTransformPipe implements PipeTransform {

  /**
   * This is the currency pipe transform method.
   *
   * @param value The value what we want to transform.
   * @param currency The currency symbol, what we want to display.
   * @param showSymbol Set false if you don't want to show the symbol.
   * @param language The language used to decide the position of the currency.
   * @param forceShowFraction if the fraction is required
   */
  transform(
    value: any,
    currency: string | Currency = 'HUF',
    showSymbol: boolean = true,
    language?: string,
    forceShowFraction: boolean = false
  ): string {
    // Remove the white space from the value.
    const transformedValue =
      typeof value === 'number'
        ? `${value}`
        : `${value ?? 0}`.toString().replace(/\s/g, '');
    if (!transformedValue) {
      return transformedValue;
    }
    const precision = !currency || typeof currency === 'string' ? defaultCurrency.precision : currency?.precision ?? 2;
    const fixedInteger = (!isNaN(+transformedValue) ? +value : 0).toFixed(precision);
    const nearestInteger = Math.floor(+fixedInteger);
    let verifiedValue = forceShowFraction ? fixedInteger : `${nearestInteger === +fixedInteger ? nearestInteger : fixedInteger}`;

    // Checks what rounding method what we need.
    // If we haven't symbol or we use HUF it rounds to an integer,
    // else it rounds to two decimal point.
    const symbol = !currency
      ? defaultCurrency.prefix
      : utils.isString(currency)
        ? currency
        : utils.isObject(currency)
          ? (currency as Currency).prefix
          : defaultCurrency.prefix;
    if (!symbol) {
      verifiedValue = (
        Math.round((+verifiedValue + Number.EPSILON) * 100) / 100
      ).toString();
    }

    // If the verifiedValue is less than 1000, we don't need formatting.
    if (Math.abs(+verifiedValue) >= 1000) {
      verifiedValue = utils.convertValueToAmount(verifiedValue);
    }

    // Checks where the place of the currencySymbol is based on language.
    if (!language || language === 'hu-HU' || language === 'hu') {
      return showSymbol ? `${verifiedValue} ${symbol}` : verifiedValue;
    } else {
      return showSymbol ? `${symbol} ${verifiedValue}` : verifiedValue;
    }
  }
}
