export interface FinanceReportsVatBalance {
  vatBalanceAmount: number;
  invoicedVatAmount: number;
  deductibleVatAmount: number;
}

export interface FinanceCashFlowBalanceReport {
  incomes: FinanceBalanceOverviewReport;
  outgoings: FinanceBalanceOverviewReport;
}

export interface FinanceBalanceOverviewReport {
  invoiceCount: number;
  amountIncome: number;
  amountOutgoing: number;
  needToPay: number;
}

export interface FinanceReportsProcessing {
  fillingStatus: {
    waitChecking: number;
    beRepaired: number;
    accounted: number;
  };
  accountingStatus: {
    waitAccounting: number;
    waitChecking: number;
    accounted: number;
  };
  paymentStatus: {
    payable: number;
    waitApprove: number;
    paid: number;
    sentToBank?: number;
  };
}

export interface FinanceReportsCashFlowItem {
  date: string;
  balance: number;
  incomes: number;
  outgoings: number;
}

export interface FinanceReportsCashFlow {
  A8Integrating: boolean;
  items: Array<FinanceReportsCashFlowItem>;
}

export interface FinanceInvoicesReportsCashFlowItem {
  date: string;
  invoices: number;
}

export type CashFlowInvoiceType = 'incomes' | 'outgoings';

export interface FinanceCashFlowInvoiceOverview {
  expiredAmount: number;
  expiredAmountCount: number;
  notExpiredAmount: number;
  notExpiredAmountCount: number;
  sumAmount: number;
  sumAmountCount: number;
}
