export enum IconColors {
  GREEN = '#08a100',
  LIGHT_BLUE = '#66ccff',
  LIGHT_BLUE_DARKER = '#52c5ff',
  BLUE = '#0099ff',
  DARK_BLUE = '#3366ff',
  RED = '#FF0000',
  ERROR = '#B71C1C',
  RED_MAT = '#D23246',
  ORANGE = '#ffa600',
  GREY = '#666666',
  DARK_GREY = '#222222',
  PINK = '#FF5252',
  WHITE = '#ffffff'
}
