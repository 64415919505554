import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { InputError, KeyValue } from '../../../global';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'slm-editor',
  templateUrl: './editor.component.html',
  styles: [
    `
           .ql-custom-button {
             width: 28px;
             height: 21px;
             display: inline-block;
           }
           .ql-custom {
             width: 135px;
             padding-right: 0.4em;
           }
           ::ng-deep mat-select.ql-custom .mat-mdc-select-trigger .mat-select-value .mat-select-placeholder {
             color: #29b6f6 !important;
             font-weight: 500;
           }
         `
  ]
})
export class EditorComponent implements OnDestroy, OnInit {
  public readonly modules = { toolbar: '#toolbar' };

  @Input() public control: UntypedFormControl | AbstractControl;
  @Input() public maxLength = 2500;
  @Input() public label = '';
  @Input() public height = 'calc(100vh - 29.9rem)';
  @Input() public errors: Array<InputError> = [];
  @Input() public placeholder = '';
  @Input() public testId = '';
  @Input() public options: Array<KeyValue> = [];
  public readonly selectControl: UntypedFormControl = new UntypedFormControl();
  public readonly destroy$ = new Subject();
  private cursorPosition = 0;

  @ViewChild('quillEditor', { static: true })
  public editor: QuillEditorComponent;

  ngOnInit() {
    this.selectControl.valueChanges
      .pipe(
        filter((value) => !!value),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        this.editor.quillEditor.insertText(this.cursorPosition, value, 'user');
        this.selectControl.setValue('');
      });
  }

  public editorChanged(event) {
    if (!!event?.range || event?.oldRange) {
      this.cursorPosition = event?.range?.index ?? event?.oldRange?.index ?? 0;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public getError() {
    if (this.control) {
      const errorType = Object.keys(this.control.errors)[0];
      const error = (this.errors || []).find(
        (error: InputError) => error.name === errorType
      );
      return error?.message;
    }
  }
}
