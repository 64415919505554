<section class="page-container" [title]="(rights$ | async) || ''" [ngClass]="rightsClasses$ | async">
  <div class="page-container__bg"></div>
  <div *ngIf="title" class="page-container__header w-100"
       [class.page-container__header--flex-width]="layoutWidth === 'normal'"
       [class.page-container__header--flex-small-width]="layoutWidth === 'small'">
    <h2 class="page-container__header__title">{{ title | translate }}</h2>
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'button'">
        <ng-container *ngTemplateOutlet="button"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'select'">
        <ng-container *ngTemplateOutlet="select"></ng-container>
      </ng-container>

      <div
        *ngSwitchCase="'stepper'"
        class="page-container__header__actions--stepper"
      >
        <slm-stepper
          [currentStep]="+selectedStepperPage"
          [steps]="stepperPages"
        ></slm-stepper>
      </div>
      <ng-container
        *ngSwitchCase="'counter'">
        <div class="page-container__header__actions--counter">
          <slm-counter
            *ngIf="counter"
            [counter]="counter"
            (fireEvent)="fireEvent.emit($event)"
            class="m-1"
          ></slm-counter>
        </div>
        <div class="page-container__header__actions">
          <ng-container *ngTemplateOutlet="button"></ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <ng-content></ng-content>
</section>

<ng-template #button>
  <div class="page-container__header__actions">
    <ng-container *ngIf="headerSelect">
      <slm-select-input
        [control]="headerSelect.control"
        [options]="headerSelect.options"
        [headerSelectStyle]="true"
        [hideOptionalLabel]="true"
        class="w-max-150 w-200px"
      ></slm-select-input>
    </ng-container>
    <ng-container *ngIf="secondaryButtonConfig">
      <slm-button
        (click)="fireEvent.emit(secondaryButtonConfig.action)"
        [config]="secondaryButtonConfig"
        *slmAccess="secondaryButtonConfig.rights"
        noWrap="true"
        buttonStyle="light"
        class="page-container__header__actions__btn mr-1"
      ></slm-button>
    </ng-container>
    <ng-container *ngIf="primaryButtonConfig">
      <slm-button
        (click)="fireEvent.emit(primaryButtonConfig.action)"
        [config]="primaryButtonConfig"
        *slmAccess="primaryButtonConfig.rights"
        class="page-container__header__actions__btn"
      ></slm-button>
    </ng-container>
    <ng-container *ngIf="thirdRowButtonConfig">
      <slm-button
        (click)="fireEvent.emit(thirdRowButtonConfig.action)"
        [config]="thirdRowButtonConfig"
        *slmAccess="thirdRowButtonConfig.rights"
        noWrap="true"
        buttonStyle="light"
        class="page-container__header__actions__btn mr-1"
      ></slm-button>
    </ng-container>
    <ng-container *ngIf="fourthRowButtonConfig">
      <slm-button
        (click)="fireEvent.emit(fourthRowButtonConfig.action)"
        [config]="fourthRowButtonConfig"
        *slmAccess="fourthRowButtonConfig.rights"
        noWrap="true"
        buttonStyle="light"
        class="page-container__header__actions__btn mr-1"
      ></slm-button>
    </ng-container>
  </div>
</ng-template>

<ng-template #select>
  <div *slmAccess="selectRights">
    <div
      *ngIf="selectValues && !!selectConfigs.length"
      class="page-container__header__actions page-container__header__actions--select"
      [class.page-container__header__actions--select--single]="
        selectConfigs.length === 1
      "
    >
      <slm-select-input
        *ngIf="selectConfigs.length > 0"
        [control]="selectForm.get(selectConfigs[0].name)"
        [options]="selectConfigs[0].options || []"
        [label]="selectConfigs[0].label"
        [testId]="selectConfigs[0].testId"
      ></slm-select-input>
      <slm-select-input
        *ngIf="selectConfigs.length === 2"
        [control]="selectForm.get(selectConfigs[1].name)"
        [options]="selectConfigs[1].options || []"
        [label]="selectConfigs[1].label"
        [testId]="selectConfigs[1].testId"
      ></slm-select-input>
    </div>
  </div>
</ng-template>
