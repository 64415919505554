import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  globalActions,
  GlobalActionTypes
} from './global.actions';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap, tap
} from 'rxjs/operators';
import {UserService, CompanyService, GeneralService} from '../services';
import {UserCompanyChange} from '../entities';
import {forkJoin, of} from 'rxjs';
import {GlobalAccountingSandbox} from '../sandboxes';
import {rootStoreActions} from '../../../app/+state';
import {Store} from '@ngrx/store';

@Injectable()
export class GlobalEffects {
  constructor(
    private readonly userService: UserService,
    private readonly companyService: CompanyService,
    private readonly generalService: GeneralService,
    private readonly globalAccountingSandbox: GlobalAccountingSandbox,
    private readonly actions$: Actions,
    private readonly store: Store
  ) {
  }

  reloadUser$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalActionTypes.ReloadUser),
    switchMap(() => this.userService.loadUser()),
    map((user) => (user ? globalActions.loadUser({user}) : globalActions.clearUserState()))
  ));

  changeCompany$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalActionTypes.ChangeCompany),
    switchMap((company: any) =>
      forkJoin([
        this.userService.changeCompany(company.companyId),
        of(company.companyId)
      ])
    ),
    tap(() => this.store.dispatch(rootStoreActions.companyChangeAction())),
    map(([authentication, companyId]: [UserCompanyChange, number]) =>
      authentication
        ? globalActions.setTokenAndCompanyId({ token: authentication.token, companyId})
        : globalActions.clearUserState()
    )
  ));

  refreshCompany$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalActionTypes.SetCompanyIdAndToken),
    switchMap(() => this.companyService.getCurrentCompany()),
    map((partner) => globalActions.loadCompany({partner}))
  ));

  refreshCurrencies$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalActionTypes.SetCompanyIdAndToken),
    switchMap(() => this.companyService.getCompanyCurrencies()),
    map((currencies: any) =>
      !currencies?.currencies
        ? currencies
        : Array.isArray(currencies.currencies)
        ? currencies.currencies
        : [currencies.currencies]
    ),
    map((currencies) => globalActions.loadCompanyCurrencies({currencies}))
  ));

  loadCompanyAfterLogin$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalActionTypes.SetAuthentication),
    debounceTime(250),
    switchMap(() => this.companyService.getCurrentCompany()),
    filter((partner) => !!partner),
    map((partner) => globalActions.loadCompany({partner}))
  ));

  loadCompany$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalActionTypes.LoadUser),
    switchMap(() => this.companyService.getCurrentCompany()),
    filter((partner) => !!partner),
    map((partner) => globalActions.loadCompany({partner}))
  ));

  fetchCompanyCurrency$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalActionTypes.FetchCompanyCurrency),
    switchMap(() => this.companyService.getCompanyCurrency()),
    map((currency) => globalActions.loadCompanyCurrency({currency}))
  ));

  fetchCompanyCurrencies$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalActionTypes.FetchCompanyCurrencies),
    switchMap(() => this.companyService.getCompanyCurrencies()),
    map((currencies: any) =>
      !currencies?.currencies
        ? currencies
        : Array.isArray(currencies.currencies)
        ? currencies.currencies
        : [currencies.currencies]
    ),
    map((currencies) => globalActions.loadCompanyCurrencies({currencies}))
  ));

  refreshGeneral$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalActionTypes.RefreshGeneral),
    debounceTime(250),
    switchMap(() => this.generalService.getSystemInformation()),
    map((general) => globalActions.setGeneral({general}))
  ));

  detectGeneralSystemNotificationChanges$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalActionTypes.SetGeneral),
    distinctUntilChanged(
      (prev: any, curr: any) =>
        prev.general?.notificationCount === curr.general?.notificationCount
    ),
    filter((action) => action.general !== null),
    map(() => globalActions.refreshSystemNotifications())
  ));

  detectGeneralNotificationChanges$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalActionTypes.SetGeneral),
    distinctUntilChanged(
      (prev: any, curr: any) =>
        prev.general?.notificationCount === curr.general?.notificationCount
    ),
    filter((action) => action.general !== null),
    map(() => globalActions.loadNotifications({page: 1}))
  ));

  refreshSystemNotification$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalActionTypes.RefreshSystemNotifications),
    switchMap(() => this.generalService.getNotifications({page: 1, size: -1}, false)),
    map((response) => globalActions.setSystemNotifications({systemNotifications: response.items}))
  ));

  setNextPageNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalActionTypes.LoadNotifications),
    switchMap((action: any) =>
      this.generalService.getNotifications({page: action.page, size: 5}, true)
    ),
    map((response) => globalActions.setNotifications({response}))
  ));

  setVatCodes$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalActionTypes.LoadVatCodes),
    switchMap(() => this.globalAccountingSandbox.getAccountingVatCodes(
      {
        actualPage: 1,
        pageSize: 100
      },
      null
    )),
    map(response => globalActions.setVatCodes({vatCodes: response.vatCodes}))
  ));

  loadVendorLedgerNumber = createEffect(() => this.actions$.pipe(
    ofType(GlobalActionTypes.LoadDefaultLedgerNumber),
    switchMap((action: any) => this.globalAccountingSandbox.getDefaultLedgerNumber(action.ledgerNumberType)),
    map(response => globalActions.setDefaultLedgerNumber(response))
  ));

  getViewDetails$ = createEffect(() => this.actions$.pipe(
    ofType(GlobalActionTypes.GetInvoiceListSettings),
    switchMap((action: any) => forkJoin([this.userService.getViewDetails(action.target), of(action.target)])),
    map(([settings, target]) => globalActions.setInvoiceListSettings({settings, target}))
  ));
}
