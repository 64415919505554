export type FileManagerMode = 'page' | 'windowed';
export type ItemType = 'file' | 'folder';

export type FileManagerRightType = FileManagerRightTypeEnum.READ | FileManagerRightTypeEnum.WRITE;

export type FileType =
  FileTypeEnum.XLS |
  FileTypeEnum.XLSX |
  FileTypeEnum.DOC |
  FileTypeEnum.DOCX |
  FileTypeEnum.PPT |
  FileTypeEnum.PPTX |
  FileTypeEnum.PDF |
  FileTypeEnum.JPG |
  FileTypeEnum.JPEG |
  FileTypeEnum.PNG |
  FileTypeEnum.GIF |
  FileTypeEnum.BMP |
  FileTypeEnum.TXT |
  FileTypeEnum.FOLDER |
  FileTypeEnum.BACK_FOLDER;

export enum FileTypeEnum {
  XLS = 'xls',
  XLSX = 'xlsx',
  DOC = 'doc',
  DOCX = 'docx',
  PPT = 'ppt',
  PPTX = 'pptx',
  PDF = 'pdf',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  GIF = 'gif',
  BMP = 'bmp',
  TXT = 'txt',

  FOLDER = 'folder',
  BACK_FOLDER = 'back_folder',
}

export enum FileManagerRightTypeEnum {
  READ = 'read',
  WRITE = 'write'
}
