import {ColumnTableItem, defaultCurrency, Icon, IconColors, InvoiceTypeEnum, RightsEnum, RoutesEnum, TableAlign} from '../../global';

export const invoiceDataTableConfig = (addDefault: boolean, useDivision: boolean): Array<ColumnTableItem> => [
  {
    value: 'INPUTS.VENDOR_NAME',
    key: 'partnerName',
    align: TableAlign.START,
    thirdColumn: (invoice) => invoice?.type === InvoiceTypeEnum.INCOMING ? {
      type: 'link',
      text: 'INVOICE.DETAILED_SHEET',
      rights: [RightsEnum.CUSTOMERS_READ],
      route: `/${RoutesEnum.SETTINGS}/${RoutesEnum.SETTINGS_PARTNERS_DATA}`
    } : null
  },
  {
    value: 'INPUTS.CUSTOMER_NAME',
    key: 'customerName',
    hideIfEmpty: true,
    align: TableAlign.START,
    thirdColumn: (invoice) => invoice?.type !== InvoiceTypeEnum.INCOMING ? {
      type: 'link',
      text: 'INVOICE.DETAILED_SHEET',
      rights: [RightsEnum.CUSTOMERS_READ],
      route: `/${RoutesEnum.SETTINGS}/${RoutesEnum.SETTINGS_PARTNERS_DATA}`
    } : null
  },
  {
    key: 'negativeInfo',
    value: 'LBL_PARTNERS.NEGATIVE_INFO',
    valueColor: IconColors.ORANGE,
    boldValue: true,
    hideIfEmpty: true,
    type: 'translate'
  },
  {
    key: 'euNegativeInfo',
    value: 'LBL_PARTNERS.NEGATIVE_EU_VAT_NUMBER_SIMPLE',
    valueColor: IconColors.ORANGE,
    boldValue: true,
    hideIfEmpty: true,
    type: 'translate'
  },
  {
    key: 'successUpload',
    value: 'INVOICE.UPLOAD_STATUS',
    valueColor: IconColors.ORANGE,
    boldValue: true,
    hideIfEmpty: true,
    type: 'translate',
    rights: {
      orRights: [RightsEnum.DOCUMENT_RECORDING_WRITE, RightsEnum.FILLING_ACCOUNTS_EDIT_WRITE]
    },
    thirdColumn: {
      action: 'sync',
      type: 'button',
      icon: Icon.SYNC_ALT,
      iconStyle: 'size-25 fill-accent',
      text: 'INVOICE.RE_UPLOAD'
    }
  },
  {
    key: 'successUploadNoRights',
    value: 'INVOICE.UPLOAD_STATUS',
    valueColor: IconColors.ORANGE,
    boldValue: true,
    hideIfEmpty: true,
    type: 'translate',
    rights: {
      orRights: [RightsEnum.DOCUMENT_RECORDING_WRITE, RightsEnum.FILLING_ACCOUNTS_EDIT_WRITE],
      reverseRights: true
    }
  },
  {
    key: 'verificationDate',
    value: 'LBL_PARTNERS.LAST_VERIFICATION',
    boldValue: true,
    hideIfEmpty: true,
    type: 'date',
    dateFormat: 'FORMAT.DATE_FULL'
  },
  {
    key: 'navStatus',
    value: 'INVOICE_GENERALS.NAV_STATUS',
    hideIfEmpty: true
  },
  {
    value: 'INVOICE_GENERALS.INVOICE_NUMBER',
    key: 'invoiceNumber',
    replacement: '-',
    align: TableAlign.START
  },
  {
    value: 'INVOICE.INVOICE',
    key: 'prepaymentData',
    hideIfEmpty: true,
    type: 'text-link',
    align: TableAlign.START
  },
  {
    value: 'INVOICE_GENERALS.REGISTRATION_NUMBER',
    key: 'invoiceRegistrationNumber',
    replacement: '-',
    align: TableAlign.START,
    hideIfEmpty: true
  },
  {
    value: 'ACCOUNTING_TEMPLATE_NAME',
    key: 'titleName',
    align: TableAlign.START,
    replacement: '-'
  },
  {
    value: 'INVOICE_GENERALS.CREATION_DATE',
    key: 'invoiceCreationDate',
    type: 'date',
    replacement: '',
    align: TableAlign.START,
    dateFormat: 'FORMAT.DATE'
  },
  {
    value: 'INVOICE_GENERALS.COMPLETION_DATE',
    key: 'invoiceCompletionDate',
    replacement: '',
    type: 'date',
    align: TableAlign.START,
    dateFormat: 'FORMAT.DATE'
  },
  {
    value: 'INVOICE.ACCOUNTING_COMPLETION_DATE',
    key: 'accountingCompletionDate',
    replacement: '',
    type: 'date',
    align: TableAlign.START,
    dateFormat: 'FORMAT.DATE',
    hideIfEmpty: true
  },
  {
    value: 'INVOICE_GENERALS.DEADLINE',
    key: 'invoicePaymentDeadline',
    replacement: '',
    boldValue: true,
    type: 'date',
    align: TableAlign.START,
    dateFormat: 'FORMAT.DATE'
  },
  {
    value: 'INVOICE.ATTR.DATE_OF_65M',
    key: 'invoiceDateOf65M',
    replacement: '',
    type: 'date',
    align: TableAlign.START,
    dateFormat: 'FORMAT.DATE',
    hideIfEmpty: true
  },
  {
    value: 'INVOICE_GENERALS.NET_VALUE',
    key: 'invoiceNetValue',
    replacement: '',
    type: 'currency',
    align: TableAlign.START,
    currencyKey: 'currency',
    defaultCurrency
  },
  {
    value: 'INVOICE_GENERALS.VAT_VALUE',
    key: 'invoiceVatValue',
    replacement: '',
    type: 'currency',
    align: TableAlign.START,
    currencyKey: 'currency',
    defaultCurrency,
    boldValue: true
  },
  {
    value: 'INVOICE.BRUT_VALUE',
    key: 'invoiceBruttoValue',
    replacement: '',
    type: 'currency',
    align: TableAlign.START,
    currencyKey: 'currency',
    defaultCurrency,
    boldValue: true
  },
  ...addDefault? defaultCurrencyDetails: [],
  {
    value: 'INVOICE.REMAINED_VALUE',
    key: 'invoicePaidValue',
    hideIfEmpty: true,
    type: 'currency',
    currencyKey: 'currency',
    defaultCurrency,
    align: TableAlign.START,
    boldValue: true
  },
  {
    value: 'INVOICE.SAVED_IN_TRANSACTION',
    key: 'invoiceSavedInTransaction',
    hideIfEmpty: true,
    type: 'currency',
    currencyKey: 'currency',
    defaultCurrency,
    align: TableAlign.START,
    boldValue: true
  },
  {
    value: 'INVOICE_GENERALS.EXCHANGE_RATE',
    key: 'exchangeRate',
    hideIfEmpty: true,
    type: 'currency',
    currencyKey: 'defaultCurrency',
    align: TableAlign.START
  },
  {
    value: 'INVOICE.DEFFER_DATE',
    key: 'defferedVatDate',
    hideIfEmpty: true,
    type: 'date',
    align: TableAlign.START,
    dateFormat: 'FORMAT.DATE'
  },
  {
    value: 'INVOICE.VENDOR_LEDGER_NUMBER',
    key: 'vendorLedgerNumber',
    hideIfEmpty: true,
    type: 'text',
    align: TableAlign.START
  },
  {
    value: 'INVOICE.DEFFER_DATE_RATE',
    key: 'defferedVatExchangeRate',
    hideIfEmpty: true,
    type: 'currency',
    currencyKey: 'defaultCurrency',
    align: TableAlign.START
  },
  {
    value: 'INVOICE_GENERALS.PAYMENT_MODE',
    key: 'invoicePaymentType',
    align: TableAlign.START,
    replacement: '',
    thirdColumn: (invoice) =>
      !invoice?.invoicePaymentTypeStatus && invoice?.invoicePaymentTypeStatus != null ? ({
        type: 'icon',
        icon: Icon.INFO,
        iconStyle: 'size-25 fill-accent',
        text: 'INVOICE.BANK_ACCOUNT_INVALID'
      }) : null
  },
  {
    value: 'INVOICE_GENERALS.REFERENCE_NOTICE',
    key: 'invoiceReferenceNotice',
    align: TableAlign.START,
    hideIfEmpty: true
  },
  {
    value: 'INVOICE.NOTE',
    key: 'invoiceNote',
    hideIfEmpty: true,
    align: TableAlign.START,
    replacement: '-'
  },
  ...useDivision ? [{
    value: 'INVOICE.DIVISION',
    key: 'divisionName',
    hideIfEmpty: true,
    align: TableAlign.START
  }] : [],
  {
    value: 'INVOICE.STORNO_INVOICE_NUMBER',
    type: 'simple-text-link',
    key: 'stornoCrediting',
    hideIfEmpty: true,
    align: TableAlign.START
  },
  {
    value: 'INVOICE.STORNO_CREDITED',
    type: 'simple-text-link',
    key: 'stornoCredited',
    hideIfEmpty: true,
    align: TableAlign.START
  },
  {
    value: 'INVOICE.OTHER',
    key: 'other',
    replacement: '',
    align: TableAlign.START
  },
  {
    value: 'INVOICE_GENERALS.FILED_WITH_OCR',
    key: 'filedWithOcrLabel',
    replacement: '-',
    align: TableAlign.START
  }
];

export const dialogInvoiceTable: Array<ColumnTableItem> = [
  {
    value: 'INPUTS.VENDOR_NAME',
    type: 'text',
    key: 'partnerName'
  },
  {
    value: 'INVOICE_GENERALS.INVOICE_NUMBER',
    type: 'text',
    key: 'number'
  },
  {
    value: 'INVOICE_GENERALS.COMPLETION_DATE',
    type: 'date',
    hideIfEmpty: true,
    align: TableAlign.CENTER,
    key: 'completionDate',
    dateFormat: 'FORMAT.DATE'
  },
  {
    value: 'INVOICE_GENERALS.DEADLINE',
    type: 'date',
    key: 'deadline',
    align: TableAlign.CENTER,
    dateFormat: 'FORMAT.DATE'
  },
  {
    value: 'INVOICE_GENERALS.VALUE',
    type: 'currency',
    align: TableAlign.END,
    key: 'grossValue',
    defaultCurrency
  }
];

const defaultCurrencyDetails: Array<ColumnTableItem> = [
  {
    value: 'INVOICE.NET_VALUE_DEFAUlT',
    key: 'invoiceNetValueDefault',
    replacement: '',
    type: 'currency',
    align: TableAlign.START,
    currencyKey: 'defaultCurrency',
    defaultCurrency
  },
  {
    value: 'INVOICE.VAT_VALUE_DEFAUlT',
    key: 'invoiceVatValueDefault',
    replacement: '',
    type: 'currency',
    align: TableAlign.START,
    currencyKey: 'defaultCurrency',
    defaultCurrency,
    boldValue: true
  },
  {
    value: 'INVOICE.BRUT_VALUE_DEFAUlT',
    key: 'invoiceBruttoValueDefault',
    replacement: '',
    type: 'currency',
    align: TableAlign.START,
    currencyKey: 'defaultCurrency',
    defaultCurrency,
    boldValue: true
  }
];
