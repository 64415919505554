import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthSandbox } from '../sandboxes';
import { map } from 'rxjs/operators';
import { RoutesEnum } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(
    private readonly authSandbox: AuthSandbox,
    private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const unauthorizedMode = route.data.unauthorizedMode;
    return this.authSandbox.storeToken$.pipe(
      map((isAuthenticated) => {
        if (unauthorizedMode) {
          if (!!isAuthenticated) {
            this.router.navigate([RoutesEnum.HOME]);
          }
          return !isAuthenticated;
        } else if (!isAuthenticated) {
          this.router.navigate([RoutesEnum.LOGIN]);
        }
        return !!isAuthenticated;
      })
    );
  }
}
