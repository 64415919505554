import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'slm-template-presenter',
  template: `
    <div [innerHtml]="template"></div>
  `,
  encapsulation: ViewEncapsulation.ShadowDom
})
export class TemplatePresenterComponent {
 @Input() template = '';
}
