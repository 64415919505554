import {Injectable} from '@angular/core';
import {AuthService, GlobalErrorHandler, NavigationService} from '../services';
import {Store} from '@ngrx/store';
import {BehaviorSubject, fromEvent, Observable, of, Subject, throwError} from 'rxjs';
import {catchError, filter, map, switchMap, take, takeUntil} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Authentication, AuthorizationTokens} from '../entities';
import {RoutesEnum} from '../enums';
import {globalActions, globalSelectors} from '../+states';
import {convertToFormData} from '../functions';
import {getUserRights} from '../functions';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthSandbox {
  public static GLOBAL_KEY = 'global';
  private jwtToken: string = null;
  private savedRefreshToken: string = null;
  private destroy$ = new Subject();
  private helper = new JwtHelperService();
  private isRefreshingToken = false;
  public token$ = new BehaviorSubject<string>(null);
  private newToken = '';
  private _lastLogin: { url: string; userId: number; partnerId: number } = null;

  constructor(
    private readonly authService: AuthService,
    private readonly store: Store,
    private readonly globalErrorHandler: GlobalErrorHandler,
    private readonly router: NavigationService
  ) {
  }

  public init() {
    this.storeToken$.pipe(takeUntil(this.destroy$)).subscribe((jwtToken) => {
      if (!!jwtToken) {
        this.refreshing = false;
      }
      this.token$.next(jwtToken);
      this.jwtToken = jwtToken;
      this.savedRefreshToken = this.getLocalStorageRefreshToken();
    });
  }

  public userStatusChange$: Observable<string> = fromEvent(window, 'storage').pipe(
    map(() => {
      try {
        return JSON.parse(localStorage.getItem(AuthSandbox.GLOBAL_KEY) || '{}')?.authToken;
      } catch (e) {
        this.globalErrorHandler.reportError(e, `UserStatusChange json Parse Error! JSON: ${localStorage.getItem(AuthSandbox.GLOBAL_KEY) || '{}'}`);
        return null;
      }
    }),
    map((token) => {
      if (token !== this.jwtToken) {
        if (!token && !!this.jwtToken) {
          this.logout(true);
          return 'sign_out';
        } else if (!!token && !this.jwtToken) {
          this.setToken(token, this.getLocalStorageRefreshToken());
          return 'sign_in';
        } else if (token !== this.jwtToken) {
          const newTokenData = this.helper.decodeToken(token);
          const existingTokenData = this.helper.decodeToken(this.jwtToken);
          if (newTokenData && existingTokenData &&
            newTokenData.partner_id !== existingTokenData.partner_id) {
            if (this.newToken !== token) {
              this.newToken = token;
              return 'company_change';
            }
          } else {
            const refreshToken = this.getLocalStorageRefreshToken();
            this.setToken(token, refreshToken);
            return 'token_refresh';
          }
        }
      }
      return null;
    }),
    filter((event) => !!event)
  );

  public verifyLastLogin(): boolean {
    if (!this._lastLogin || !this.jwtToken) {
      return;
    }
    const decodedJwt = this.helper.decodeToken(this.jwtToken);
    const actualUserId = +decodedJwt?.user_id;
    if (this._lastLogin.userId === actualUserId) {

      this.router.navigateByUrl(this._lastLogin.url);
      this._lastLogin = null;
      return true;
    }

    this._lastLogin = null;
    return false;
  }

  private getLocalStorageRefreshToken = (): string => JSON.parse(localStorage.getItem(AuthSandbox.GLOBAL_KEY))?.refreshToken;

  public goToNewToken(): void {
    if (!!this.newToken) {
      const token = this.newToken;
      this.newToken = '';
      this.setToken(token, this.getLocalStorageRefreshToken());
    }
  }

  public setCurrentToken(): void {
    if (!!this.jwtToken) {
      this.newToken = '';
      this.setToken(this.jwtToken, this.savedRefreshToken || this.getLocalStorageRefreshToken());
    }
  }

  private setToken = (token: string, refreshToken: string = '') => {
    this.token$.next(token);
    this.jwtToken = token;
    this.savedRefreshToken = refreshToken || this.getLocalStorageRefreshToken();
    this.store.dispatch(refreshToken ? globalActions.setAuthorizationData({token, refreshToken}) : globalActions.setToken({token}));
  };

  public isAuthenticated = () =>
    this.store.select(globalSelectors.isUserAuthenticated());

  public getJwtToken = () => this.jwtToken;

  public isTokenExpired(token: string) {
    const expired = token ? this.helper.isTokenExpired(token) : true;
    if (expired) {
      this.refreshToken();
    }
    return expired;
  }

  public storeToken$ = this.store.select(globalSelectors.getAuthorizationToken());

  public destroy = () => {
    this.destroy$.next(null);
    this.destroy$.complete();
  };

  public set refreshing(refreshing: boolean) {
    this.isRefreshingToken = refreshing;
    if (this.isRefreshingToken) {
      this.token$.next(null);
    }
  }

  public get refreshing() {
    return this.isRefreshingToken;
  }

  public login = (authentication: Authentication): Observable<boolean> => {
    const authData = {
      ...authentication,
      ...!!this._lastLogin?.partnerId ?
        {partnerId: this._lastLogin.partnerId} :
        null};

    return this.authService.login(convertToFormData(authData)).pipe(
      map((data) => {
        this.store.dispatch(
          globalActions.setAuthentication({
            authorization: {
              ...data,
              user: getUserRights(data.user)
            }
          })
        );
        this.jwtToken = data.authToken;
        return this.verifyLastLogin();
      })
    );
  };

  public logout(saveUrl: boolean = false, jwtToken: string = null, navigationUrl: string = null) {
    if (saveUrl && (jwtToken || this.jwtToken)) {
      const decodedJwt = this.helper.decodeToken(jwtToken ?? this.jwtToken);
      const url = navigationUrl ?? this.router.url;
      const userId = +decodedJwt.user_id;
      const partnerId = +decodedJwt.partner_id;

      if (url && userId) {
        this._lastLogin = {url, userId, partnerId};
      }
    } else {
      this._lastLogin = null;
    }
    this.token$.next(null);
    this.jwtToken = null;
    this.savedRefreshToken = null;
    this.store.dispatch(globalActions.clearGlobalState());
    this.router.navigate([RoutesEnum.LOGIN]);
  }

  public readonly registration = (registration) =>
    this.authService.registration(registration);

  public readonly activate = (token) =>
    this.authService.activate(token);

  public readonly resetPassword = (email: string) =>
    this.authService.resetPassword(email);

  public readonly setPassword = (password: string, token: string) =>
    this.authService.setPassword(password, token);

  public refreshToken() {
    const jwtToken = this.jwtToken;
    const navigationUrl = this.router.url;
    this.refreshing = true;
    this.store.select(globalSelectors.getRefreshToken()).pipe(
      take(1),
      switchMap(refreshToken => refreshToken ?
        this.authService.refreshToken(refreshToken, jwtToken)
          .pipe(catchError(() => throwError({error: 'LBL_INPUT.ERROR.EXPIRED_LOGIN', status: HttpStatusCode.Unauthorized}))) :
        throwError({error: 'LBL_INPUT.ERROR.EXPIRED_LOGIN', status: HttpStatusCode.Unauthorized})
      ),
      catchError((err: HttpErrorResponse) => {
        if (err.error && err.status === HttpStatusCode.Unauthorized) {
          this.logout(true, jwtToken, navigationUrl);
        }
        return of(null);
      }),
      filter(value => !!value)
    ).subscribe((authentication: AuthorizationTokens) => {
      this.setToken(authentication.authToken, authentication.refreshToken);
      this.token$.next(authentication.authToken);
      this.isRefreshingToken = false;
      return true;
    });
  }
}
