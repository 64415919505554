<mat-form-field
  class="flex-grow"
  [class.text-input--required]="isRequiredField  && (control.valid || !control.dirty)"
  [class.text-input--error]="
    !control?.valid && control?.touched && control?.dirty
  "
>
  <mat-label *ngIf="label || (!isRequiredField && !hideOptionalLabel)"
  >{{ label | translate
    }} <span *ngIf="secondaryLabel">{{ secondaryLabel | translate }}</span>
    <span *ngIf="!isRequiredField && !hideOptionalLabel" translate="LBL_INPUT.LABEL.NOT_REQUIRED"></span>
  </mat-label>
  <slm-svg-icon matPrefix *ngIf="prefixIcon" [icon]="prefixIcon"></slm-svg-icon>
  <input
    matInput
    type="text"
    [required]="isRequiredField"
    [attr.data-test-id]="testId"
    [placeholder]="placeholder | translate"
    [formControl]="control"
    #inputAutoComplete
    [matAutocomplete]="auto"
    (keyup.enter)="enterEnabled ? onClick('enter') : null"
    (focus)="showDropDown()"
  />

  <slm-svg-icon
    *ngIf="!loading && suffixIcon"
    matSuffix
    matRipple
    class="fill-grey"
    [matTooltip]="suffixIconLabel | translate"
    [matRippleDisabled]="!buttonIcon"
    (click)="buttonIcon ? onClick('suffix') : null"
    [icon]="suffixIcon"
  ></slm-svg-icon>
  <div *ngIf="!loading && !suffixIcon && clearButton && control?.value && control?.enabled"
       matSuffix
       class="radius-infinite pos-relative top-4"
       matRipple
       (click)="clear()">
    <slm-svg-icon class="fill-accent" icon="close"></slm-svg-icon>
  </div>
  <slm-loader *ngIf="loading" diameter="24" class="block top-4" matSuffix></slm-loader>
  <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
  <mat-hint *ngIf="customWarningMsg" class="color-warning">{{customWarningMsg | translate}}</mat-hint>
  <mat-error *ngIf="(control?.dirty || control?.touched) && control?.invalid">{{
    getError() | translate: errorBody
    }}</mat-error>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayValue()">
    <mat-option *ngFor="let option of options$ | async; let i = index" [value]="option" class="h-min-3r h-fit-content-i pt-1-i pb-1-i"
                [attr.data-test-id]="testId + i">
      <div class="txt-medium">{{ option.label | translate }}</div>
      <span *ngIf="option.subLabel" class="mt-1 block txt-normal-wrap">{{ option.subLabel | translate }}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
