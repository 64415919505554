import { Subscription } from 'rxjs';

export interface InvoiceDocument {
  name: string;
  file?: File;
  encodedFile?: string;
  ids?: Array<number>;
  progress?: number;
  subscription?: Subscription;
  status?: 'uploading' | 'done' | 'error' | 'canceled' | 'timeout';
  hideOpen?: boolean;
  hideClose?: boolean;
  data?: any;
}
