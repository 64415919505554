import {SelectOption} from "../entities";

export const needAccountingNumberForExternalSystems =
  ['COMBOSOFT'];

export const externalSystemsToSend =
  ['IMA','NOVITAX','RELAX','RLB', 'SMARTBOOKS', 'KULCS-SOFT'];

export enum AllExternalSystems {
  NONE = '',
  COMBOSOFT = 'COMBOSOFT',
  IMA = 'IMA',
  NOVITAX = 'NOVITAX',
  RELAX = 'RELAX',
  RLB = 'RLB',
  SMARTBOOKS = 'SMARTBOOKS',
  KULCS_SOFT = 'KULCS-SOFT'
}

export enum ExternalSystemIds {
  COMBOSOFT = 'COMBOSOFT',
  IMA = 8,
  NOVITAX = 7,
  RELAX = 6,
  RLB = 5,
  SMARTBOOKS = 9,
  KULCS_SOFT = 11
}

export const externalSystems: Array<SelectOption> = [
  {
    label: 'ACCOUNTING_SETTINGS.OTHERS.COMPANY_NO_EXIST',
    value: 0
  },
  {
    label: 'IMA',
    value: ExternalSystemIds.IMA
  },
  /*{
    label: 'Novitax',
    value: ExternalSystemIds.NOVITAX
  },*/
  {
    label: 'Relax',
    value: ExternalSystemIds.RELAX
  },
  {
    label: 'RLB',
    value: ExternalSystemIds.RLB
  },
  {
    label: 'SMARTBOOKS',
    value: ExternalSystemIds.SMARTBOOKS
  },
  {
    label: 'KULCS-SOFT',
    value: ExternalSystemIds.KULCS_SOFT
  }
];

export const externalSystemFormats = [

  {
    label: 'IMA',
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: 'xlsx'
  },
  /*{
    label: 'NOVITAX',
    type: 'text/csv',
    extension: 'csv'
  },*/
  {
    label: 'RELAX',
    type: 'text/csv',
    extension: 'csv'
  },
  {
    label: 'RLB',
    type: 'text/csv',
    extension: 'csv'
  },
  {
    label: 'SMARTBOOKS',
    type: 'xml',
    extension: 'xml'
  },
  {
    label: 'KULCS-SOFT',
    type: 'application/zip, application/octet-stream',
    extension: 'zip'
  }
];
