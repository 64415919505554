import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FireEvent, TableAlign, TableTextButton} from '../../../../../../global';

@Component({
  selector: 'slm-table-text-button',
  templateUrl: './table-text-button.component.html'
})
export class TableTextButtonComponent {

  @Input() public data: TableTextButton = null;

  @Input() align: TableAlign = TableAlign.CENTER;
  @Input() tooltip: string = null;
  @Input() textColor: string = null;

  @Output()
  public fireEvent = new EventEmitter<FireEvent>();
}
