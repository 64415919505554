import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ButtonConfig, HeaderSelect, InputConfig, RightsConfig, RightsEnum} from '../../../global';
import {Observable, Subject} from 'rxjs';
import {debounceTime, filter, map, shareReplay, takeUntil} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'slm-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss']
})
export class PageContainerComponent implements OnInit, OnDestroy {
  @Output() public readonly fireEvent = new EventEmitter<string>();
  @Output() public readonly selectChanged = new EventEmitter<any>();

  @Input() public title = '';
  @Input() public primaryButtonConfig: ButtonConfig = null;
  @Input() public secondaryButtonConfig: ButtonConfig = null;
  @Input() public thirdRowButtonConfig: ButtonConfig = null;
  @Input() public fourthRowButtonConfig: ButtonConfig = null;
  @Input() public type: 'button' | 'select' | 'stepper' | 'counter' | string =
    'button';
  @Input() public stepperPages: Array<string> = [];
  @Input() public selectRights: Array<RightsEnum> | RightsConfig = null;
  @Input() public counter: {
    count: number;
    index: number;
    label?: string;
  } = null;
  @Input() public selectedStepperPage = 0;
  @Input() public disableSelects = false;
  @Input() public layoutWidth: 'normal' | 'small' | 'large' = 'normal';
  @Input() public headerSelect: HeaderSelect = null;

  @Input('selectValues')
  public set setValues(values: any) {
    this.selectValues = values;
    if (
      this.selectForm &&
      !!Object.keys(this.selectForm.value).length &&
      values
    ) {
      this.selectForm.setValue(values);
    }
  }

  @Input('selects')
  public set setSelects(inputs: Array<InputConfig>) {
    if (this.selectForm && this.selectConfigs.length) {
      this.selectForm.removeControl(this.selectConfigs[0].name);
      if (this.selectConfigs.length === 2 && !!this.selectConfigs[1]?.name) {
        this.selectForm.removeControl(this.selectConfigs[1].name);
      }
    }
    if (!this.selectForm) {
      this.selectForm = this.fb.group({});
    }
    this.selectConfigs = [];
    if ((inputs || []).length > 0 && !!inputs[0]) {
      this.selectForm.addControl(
        inputs[0].name,
        this.fb.control({
          value: this.selectValues?.[inputs[0].name],
          disabled: this.disableSelects
        })
      );
      this.selectConfigs.push(inputs[0]);
    }
    if ((inputs || []).length > 1 && !!inputs[1]) {
      this.selectForm.addControl(
        inputs[1].name,
        this.fb.control({
          value: this.selectValues?.[inputs[1].name],
          disabled: this.disableSelects
        })
      );
      this.selectConfigs.push(inputs[1]);
    }
  }

  public selectValues: any = null;
  public selectConfigs: Array<InputConfig> = [];

  public selectForm: UntypedFormGroup;

  private readonly destroy$ = new Subject();
  public rights$: Observable<string>;
  public rightsClasses$: Observable<string>;

  constructor(private readonly fb: UntypedFormBuilder, private readonly route: ActivatedRoute) {
  }

  ngOnInit() {
    if (!this.selectForm) {
      this.selectForm = this.fb.group({});
    }
    this.rights$ = this.route.data.pipe(
      filter(() => environment.showRights),
      map(data =>
        !!data.rights && Array.isArray(data.rights) && data.rights.length > 0 ? data.rights.join(', ') : ''
      ),
      shareReplay({
        refCount: true,
        bufferSize: 1
      }));

    this.rightsClasses$ = this.rights$.pipe(
      map(value => !!value ? 'border-solid-i border-3 border-color-warning-i' : '')
    );

    this.selectForm.valueChanges
      .pipe(
        debounceTime(50),
        filter((value) => !!value && this.type === 'select'),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => this.selectChanged.next(value));
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
