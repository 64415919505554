import {Injectable} from '@angular/core';
import {HttpClient, HttpStatusCode} from '@angular/common/http';
import {ErrorHandlingService} from './error-handling.service';
import {Observable} from 'rxjs';
import {AuthorizationObject, AuthorizationTokens} from '../entities';
import {catchError} from 'rxjs/operators';
import {NotificationService} from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private readonly http: HttpClient,
    private readonly notificationService: NotificationService,
    private readonly errorHandler: ErrorHandlingService
  ) {
  }

  public login(
    authentication: FormData
  ): Observable< AuthorizationObject> {
    return this.http
      .post('/auth/login', authentication)
      .pipe(
        catchError((error) =>
          this.errorHandler.handleErrorByMessages(error, [
            {code: HttpStatusCode.Unauthorized, message: 'LBL_ERROR.INVALID_AUTH_DATA'}
          ])
        )
      );
  }

  public refreshToken(refreshToken: string, authToken): Observable<AuthorizationTokens> {
    return this.http
      .post('/auth/token', {refreshToken, authToken})
      .pipe(
        catchError(error => this.errorHandler.handleError(error))
      );
  }

  public activate = (
    token: string
  ): Observable<any> => this.http
    .get(`/auth/activation/${token}`)
    .pipe(catchError((error) => this.errorHandler.handleError(error)));

  public registration(registration): Observable<any> {
    return this.http
      .post('/auth/registration', registration)
      .pipe(catchError((error) => this.errorHandler.handleError(error)));
  }

  public resetPassword(email: string): Observable<any> {
    return this.http
      .post('/auth/reset-password', {email})
      .pipe(catchError((error) => this.errorHandler.handleError(error)));
  }
  public setPassword(password: string, token: string): Observable<any> {
    return this.http
      .put('/auth/reset-password', {password, token})
      .pipe(catchError((error) => this.errorHandler.handleError(error)));
  }
}
