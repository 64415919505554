import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {NavigationService, RoutesEnum} from '../../../global';
import {ActivatedRoute} from '@angular/router';
import {HttpStatusCode} from '@angular/common/http';

@Component({
  selector: 'error',
  template: `
    <article class="error-page">
      <svg-icon
        class="error-page__picture"
        [src]="svgSrc"
        [applyClass]="true"
      ></svg-icon>
      <h1
        *ngIf="!!errorCode"
        class="error-page__code w-100 pr-3 pl-3 txt-center"
      >
        {{ errorCode }}
      </h1>
      <h2 class="error-page__text  w-100 pr-3 pl-3 txt-center">
        {{ errorText | translate }}
      </h2>
      <slm-button
        class="error-page__btn"
        [routerLink]="returnRoute"
        text="LBL_BTN.HOME"
      ></slm-button>
    </article>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorContainer implements OnInit {
  public svgSrc = '/assets/pictures/illustrations/not-found.svg';
  public errorCode = HttpStatusCode.NotFound;
  public errorText = 'LBL_ERROR.PAGE_NOT_FOUND';
  public returnRoute = RoutesEnum.HOME;

  constructor(
    private route: ActivatedRoute,
    private router: NavigationService
  ) {}

  ngOnInit() {
    const currentRoute = (this.router.url || '/').slice(1);
    if (currentRoute === RoutesEnum.NO_ACCESS) {
      this.svgSrc = '/assets/pictures/illustrations/no-access.svg';
      this.errorCode = HttpStatusCode.Unauthorized;
      this.errorText = 'LBL_INPUT.ERROR.PAGE_NO_ACCESS';
    } else {
      const isExist = Object.values(RoutesEnum).find(
        (route) =>
          (!route && !currentRoute) ||
          (!!route && currentRoute.startsWith(route))
      );
      if (isExist) {
        this.svgSrc = '/assets/pictures/illustrations/under-construction.svg';
        this.errorCode = HttpStatusCode.InternalServerError;
        this.errorText = 'LBL_ERROR.UNDER_CONSTRUCTION';
      }
    }
  }
}
