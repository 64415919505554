import {Base} from './general.entity';

export type ProcessingTimeTypes = 'T0' | 'T1' | 'T2';

export interface BankAccount {
  id?: number;
  accountNumber?: string;
  accountName?: string;
  default?: boolean;
  bankName?: string;
  iban?: string;
  type?: string;
  swift?: string;
  countryCode?: string;
  other?: string;
  defaultProcessingTime?: ProcessingTimeTypes;
}

export interface Bank extends Base {
  formats?: Array<{id: string, name: string}>;
  swift?: string;
  countryCode?: string;
  a8?: boolean;
  custom?: boolean;
  useProcessingTime?: boolean;
  giro?: string;
}
