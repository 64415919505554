<slm-dialog-container
  [title]="data.title"
  [buttons]="buttons"
  class="w-max-100p block"
  (fireEvent)="buttonEvent($event.event)"
>
  <slm-simple-table
    class="reject-dialog-table-container block w-100 mb-3"
    [config]="tableConfig"
    [currency]="data?.invoice?.currency"
    [items]="[data.invoice]"
  ></slm-simple-table>
  <ng-container *ngIf="payments$ | async as payments; else loading">
    <slm-simple-table
      *ngIf="payments.length"
      class="block w-100 mb-2 mt-1"
      extraAttributeName="note"
      [config]="paymentTableConfig"
      [currency]="data?.invoice?.currency"
      [items]="payments"
    ></slm-simple-table>
    <slm-payout-template
      [title]="data.title"
      addDefaultElement="true"
      [configData]="paymentConfig$ | async"
      (formArray)="setPaymentForm($event)"
      #payoutTemplate
    ></slm-payout-template>
  </ng-container>
</slm-dialog-container>

<ng-template #loading>
  <slm-loader></slm-loader>
</ng-template>
