import {Injectable} from '@angular/core';
import {FileManagerService} from '../services/file-manager.service';
import {tap} from 'rxjs/operators';
import {downloadExcel, downloadImage, downloadOther, downloadPdf, FileType, FileTypeEnum} from '../index';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileManagerSandbox {
  public openDialog$ = new BehaviorSubject<'file' | 'folder'>(null);

  constructor(
    private fileManagerService: FileManagerService
  ) {}

  public readonly getFolderContent = (folder: string, search: string, filters: any) => this.fileManagerService.getFolderContent(folder, search, filters);
  public readonly createFolder = (data: any) => this.fileManagerService.createFolder(data);
  public readonly deleteFolder = (ids: string) => this.fileManagerService.deleteFolder(ids);
  public readonly modifyFolder = (id: string, data: any) => this.fileManagerService.modifyFolder(id, data);
  public readonly changeFolder = (ids: Array<string>, toFolder: string) => this.fileManagerService.changeFolder(ids, toFolder);

  public readonly uploadFile = (file: File, toFolder: string) => this.fileManagerService.uploadFile(file, toFolder);
  public readonly deleteFiles = (ids: Array<string>) => this.fileManagerService.deleteFiles(ids);
  public readonly modifyFile = (id: string, data: any) => this.fileManagerService.modifyFile(id, data);
  public readonly getFile = (id: string) => this.fileManagerService.getFile(id);

  public readonly downloadFile = (id: string, fileType: FileType, fileName: string) => {
    const fullFileName = `${fileName}.${fileType}`;
    return this.fileManagerService.downloadFile(id).pipe(tap(blob => {
      switch (fileType){
        case FileTypeEnum.DOC: case FileTypeEnum.DOCX: case FileTypeEnum.TXT:
        case FileTypeEnum.PPT: case FileTypeEnum.PPTX:
        case FileTypeEnum.GIF: case FileTypeEnum.BMP:
          downloadOther(blob, fullFileName);
          break;
        case FileTypeEnum.FOLDER:
          downloadOther(blob, fileName);
          break;
        case FileTypeEnum.XLS: case FileTypeEnum.XLSX:
          downloadExcel(blob, fullFileName);
          break;
        case FileTypeEnum.PDF:
          downloadPdf(blob, fileName)
          break;
        case FileTypeEnum.JPG: case FileTypeEnum.JPEG: case FileTypeEnum.PNG:
          downloadImage(blob, fullFileName, fileType);
          break;
      }
    }));
  };

  public buttonClicked(type: 'file' | 'folder') {
    this.openDialog$.next(type);
  }
}
