import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Icon, IconColors, Icons } from '../../../global';

@Component({
  selector: 'slm-document-item',
  template: `
    <div *ngIf="file" [class]="'document-item document-item--' + status">
      <slm-svg-icon
        class="document-item__icon"
        [icon]="typeIcon"
        [color]="iconColor"
      ></slm-svg-icon>
      <div class="document-item__content">
        <h5 class="document-item__content__name">{{ name || file?.name }}</h5>
        <ng-container [ngSwitch]="status">
          <mat-progress-bar
            *ngSwitchCase="'uploading'"
            class="document-item__content__progress"
            mode="determinate"
            color="accent"
            [value]="progress"
          ></mat-progress-bar>
          <span
            *ngSwitchCase="'done'"
            class="document-item__content__message"
            >{{
              'LBL_UPLOAD_FILL.UPLOAD_DIALOG.SUCCESS'
                | translate: { size: file?.size || 0 | filesize }
            }}</span
          >
          <span
            *ngSwitchCase="'canceled'"
            class="document-item__content__message"
            >{{ 'LBL_UPLOAD_FILL.UPLOAD_DIALOG.CANCELED' | translate }}</span
          >
          <span
            *ngSwitchCase="'error'"
            class="document-item__content__message"
            >{{ 'LBL_UPLOAD_FILL.UPLOAD_DIALOG.ERROR' | translate }}</span
          >
          <span
            *ngSwitchCase="'timeout'"
            class="document-item__content__message"
          >{{ 'LBL_UPLOAD_FILL.UPLOAD_DIALOG.RETRY_UPLOAD' | translate }}</span
          >
        </ng-container>
      </div>
      <slm-button-icon
        *ngIf="!hideClose"
        class="document-item__button fill-accent"
        [elevation]="true"
        [icon]="buttonIcon"
        [matTooltip]="tooltipLabel | translate"
        (click)="buttonClicked()"
      ></slm-button-icon>
      <slm-button-icon *ngIf="status === 'done' && !hideOpen"
                       [icon]="icon.OPEN_IN_NEW"
                       class="size-25 fill-accent ml-2"
                       elevation="true"
                       (click)="buttonClicked('open')"
                       [matTooltip]="'LBL_BTN.OPEN' | translate"></slm-button-icon>
    </div>
  `,
  styleUrls: ['./document-item.component.scss']
})
export class DocumentItemComponent {
  public readonly icon = Icon;
  @Output() public fireEvent = new EventEmitter<string>();

  @Input() progress = 0;
  @Input() file: File = null;
  @Input() name = '';
  @Input() hideOpen = false;
  @Input() hideClose = false;
  @Input('status')
  public set setStatus(status: string) {
    this.status = status;
    this.setStyle();
    switch (status) {
      case 'canceled':
        this.buttonIcon = Icon.REFRESH;
        this.tooltipLabel = 'LBL_UPLOAD_FILL.UPLOAD_DIALOG.REFRESH_DOC';
        this.iconColor = IconColors.GREY;
        break;
      case 'uploading':
        this.buttonIcon = Icon.CLOSE;
        this.tooltipLabel = 'LBL_UPLOAD_FILL.UPLOAD_DIALOG.CANCEL';
        break;
      case 'error':
        this.tooltipLabel = 'LBL_UPLOAD_FILL.UPLOAD_DIALOG.REFRESH_DOC';
        this.buttonIcon = Icon.REFRESH;
        break;
      case 'done':
        this.tooltipLabel = 'LBL_UPLOAD_FILL.UPLOAD_DIALOG.DELETE_DOC';
        this.buttonIcon = Icon.DELETE;
        break;
      case 'timeout':
        this.tooltipLabel = 'LBL_UPLOAD_FILL.UPLOAD_DIALOG.RESUME_DOC';
        this.buttonIcon = Icon.UPLOAD_FILE;
        break;
    }
  }

  public typeIcon: Icons = Icon.DESCRIPTION;
  public buttonIcon: Icons = Icon.CLOSE;
  public iconColor = '#0000FF';
  public tooltipLabel = '';
  public status = '';

  public setStyle() {
    if (this.file) {
      const fileType = this.file.type.split('/')[1];
      switch (fileType) {
        case 'pdf':
          this.typeIcon = Icon.INSERT_DRIVE_FILE;
          this.iconColor = IconColors.RED;
          break;
        case 'png':
          this.typeIcon = Icon.PHOTO;
          this.iconColor = IconColors.ORANGE;
          break;
        case 'jpg':
          this.typeIcon = Icon.PHOTO;
          this.iconColor = IconColors.BLUE;
          break;
        case 'jpeg':
          this.typeIcon = Icon.PHOTO;
          this.iconColor = IconColors.BLUE;
          break;
      }
    }
  }

  buttonClicked(action: string = null) {
    if (action === 'open') {
      this.fireEvent.emit('open');
      return;
    }
    switch (this.status) {
      case 'canceled':
        this.fireEvent.emit('refresh');
        break;
      case 'uploading':
        this.fireEvent.emit('cancel');
        break;
      case 'error': case 'timeout':
        this.fireEvent.emit('refresh');
        break;
      case 'done':
        this.fireEvent.emit('remove');
    }
  }
}
