import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icons } from '../../../global';

@Component({
  selector: 'slm-svg-icon',
  styleUrls: ['./svg-icon.component.scss'],
  template: `
    <svg-icon
      *ngIf="icon"
      [src]="'/assets/icons/' + icon + '.svg'"
      class="slm-svg-icon"
      [style]="{ width: size, height: size }"
      [applyClass]="true"
      [svgStyle]="{ fill: color || null, width: size, height: size }"
    ></svg-icon>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgIconComponent {
  @Input() icon: Icons | string = null;
  @Input() color = '';
  @Input() size = '24px';
}
