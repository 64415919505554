<ng-container *ngIf="autocomplete; else onlyInput">
  <mat-form-field
    class="flex-grow"
    [class.text-input--error]="
      !control?.valid && control?.touched && control?.dirty
    "
    [class.text-input--required]="isRequiredField  && (control.valid || !control.dirty)"
  >
    <mat-label *ngIf="label || (!isRequiredField && !hideOptionalLabel)"
      >{{ label | translate
      }} <span *ngIf="secondaryLabel">{{ secondaryLabel | translate }}</span>
      <span *ngIf="!isRequiredField && !hideOptionalLabel" translate="LBL_INPUT.LABEL.NOT_REQUIRED"></span>
    </mat-label>
    <h5 *ngIf="!!prefixLabel && !prefixIcon" class="prefix-label" matPrefix>
      {{ prefixLabel | translate }}
    </h5>
    <slm-svg-icon
      matPrefix
      *ngIf="prefixIcon"
      [icon]="prefixIcon"
    ></slm-svg-icon>
    <input
      matInput
      minLength="7"
      maxlength="50"
      [required]="isRequiredField"
      [attr.data-test-id]="testId"
      [placeholder]="placeholder | translate"
      [formControl]="control"
      [matAutocomplete]="auto"
      (keyup.enter)="enterEnabled ? onClick('enter') : null"
      bankAccountFormatDirective
      disableValue=" - "
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayValue">
      <mat-option *ngFor="let option of options" [value]="option">
        <div class="medium-text">{{ option.label | translate }}</div>
        <span *ngIf="option.subLabel" class="mt-1 block txt-normal-wrap">{{ option.subLabel | translate }}</span>
      </mat-option>
    </mat-autocomplete>
    <h5 *ngIf="!!suffixLabel && !suffixIcon" matSuffix>
      {{ suffixLabel | translate }}
    </h5>
    <slm-svg-icon
      *ngIf="suffixIcon"
      matSuffix
      matRipple
      class="fill-grey"
      [matTooltip]="suffixLabel | translate"
      [matRippleDisabled]="!buttonIcon"
      (click)="buttonIcon ? onClick('suffix') : null"
      [icon]="suffixIcon"
    ></slm-svg-icon>
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
    <mat-error
      *ngIf="(control?.dirty || control?.touched) && control?.invalid"
      >{{ getError() | translate: errorBody }}</mat-error
    >
  </mat-form-field>
</ng-container>

<ng-template #onlyInput>
  <mat-form-field
    class="flex-grow"
    [class.text-input--error]="
      !control?.valid && control?.touched && control?.dirty
    "
  >
    <mat-label *ngIf="label"
      >{{ label | translate
      }}<span *ngIf="secondaryLabel"> {{ secondaryLabel | translate }}</span>
    </mat-label>
    <h5 *ngIf="!!prefixLabel && !prefixIcon" class="prefix-label" matPrefix>
      {{ prefixLabel | translate }}
    </h5>
    <slm-svg-icon
      matPrefix
      *ngIf="prefixIcon"
      [icon]="prefixIcon"
    ></slm-svg-icon>
    <input
      matInput
      [attr.data-test-id]="testId"
      minLength="7"
      maxlength="40"
      [required]="isRequiredField"
      [placeholder]="placeholder | translate"
      [formControl]="control"
      (keyup.enter)="enterEnabled ? onClick('enter') : null"
      bankAccountFormatDirective
    />
    <h5 *ngIf="!!suffixLabel && !suffixIcon" matSuffix>
      {{ suffixLabel | translate }}
    </h5>
    <slm-svg-icon
      *ngIf="suffixIcon"
      matSuffix
      matRipple
      class="fill-grey"
      [matTooltip]="suffixLabel | translate"
      [matRippleDisabled]="!buttonIcon"
      (click)="buttonIcon ? onClick('suffix') : null"
      [icon]="suffixIcon"
    ></slm-svg-icon>
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
    <mat-error
      *ngIf="(control?.dirty || control?.touched) && control?.invalid"
      >{{ getError() | translate: errorBody }}</mat-error
    >
  </mat-form-field>
</ng-template>
