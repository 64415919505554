import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Currency, RightsEnum} from '../../../global';

@Component({
  selector: 'slm-payment-overview',
  templateUrl: './payment-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentOverviewComponent {
  public readonly rights = RightsEnum;
  @Input() balance: any = null;
  @Input() labels: any = null;
  @Input() currency: Currency = null;
}
