import {
  PartnerActiveStatus,
  LegalClassEnum,
  PartnerTypeEnum,
  InvoicePaymentType,
  PartnerInvoiceTypeEnum,
  TaxationFormEnum, NotificationEnum, PartnerActiveStatusEnum
} from '../enums';
import { Country } from './country.entity';
import {Bank, BankAccount} from './bank.entity';
import {AccountingMirror, DefaultLedgerNumberValue} from './accounting-settings.entity';
import {Base} from './general.entity';
import {Currency} from './currency.entity';

export interface PartnerLite {
  id: number | string;
  name: string;
  type?: 'company' | any;
  negativeInfo?: boolean;
  verificationDate?: string;
  euNegativeInfo?: boolean;
  euVerificationDate?: string;
  euVatNumber?: string;
}

export interface Partner extends Base {
  address: string;
  addressHouseNumber: string;
  addressStreet: string;
  city: string;
  taxType: number;
  contact: PartnerContactInfo;
  country: Country;
  countryId?: number;
  externalSystemIdentifier: string;
  invoice: PartnerInvoicingInfo;
  email: string;
  emailLanguage: string;
  legalClass: LegalClassEnum;
  negativeInfo: boolean;
  verificationDate: string;
  euNegativeInfo: boolean;
  euVerificationDate: string;
  publicTaxIdNumber: string;
  status: PartnerActiveStatus;
  taxAdministrationNumber: string;
  taxNumber: string;
  type: PartnerTypeEnum;
  zipCode: string;
  groupIdNumber?: string;
  vendorLedgerNumber: DefaultLedgerNumberValue;
  customerLedgerNumber: DefaultLedgerNumberValue;
  chartOfAccount?: Partial<AccountingMirror>;
  bankAccounts: Array<PartnerBankAccount>;
  groupVatNumber?: number;
  thirdPartyVat?: string;
  defaultCurrency?: Currency;
}

export interface PartnerBankAccount {
  id: number;
  bank: Bank;
  accountName: string;
  accountNumber: string;
  default: boolean;
  iban: string;
  other?: string;
  status?: PartnerActiveStatusEnum.ACTIVE | PartnerActiveStatusEnum.INACTIVE
}

export interface PartnerSave {
  id?: number;
  status: PartnerActiveStatus | string;
  name: string;
  legalClass: LegalClassEnum | string;
  zipCode: string;
  city: string;
  countryId?: number;
  addressHouseNumber: string;
  addressStreet: string;
  taxAdministrationNumber: string;
  publicTaxIdNumber: string;
  taxType: number;
  externalSystemIdentifier: string;
  contact: PartnerContactInfo;
  invoice: PartnerInvoicingInfo;
  bankAccounts: Array<BankAccount>;
  deletedBankAccounts: Array<number>;
  groupVatNumber?: number;
  thirdPartyVat?: string;
  customerLedgerNumberId: number;
  vendorLedgerNumberId: number;
  defaultCurrency?: number;
}

/**
 * @summary An interface describing the data that represent a Partner object's contact sub object.
 */
export interface PartnerContactInfo {
  name: string;
  role: string;
  email: string;
  phone: number | string;
  comments?: string;
}

/**
 * @summary An interface describing the data that represent a Partner object's invoice sub object.
 */
export interface PartnerInvoicingInfo {
  invoiceType?: PartnerInvoiceTypeEnum | string;
  email: string;
  noVatWithinTheCommunity?: boolean;
  distanceSelling?: boolean;
  paymentType?: InvoicePaymentType | string;
  invoiceNote?: string;
  paymentTime: number | string;
  emailLanguage: string;
}

/**
 * @summary An interface describing the data that represent a Partner object's accountingInfo sub object.
 */
export interface PartnerAccountingInfo {
  taxationForm?: TaxationFormEnum;
  valueAddedTax?: boolean;
  costLedgerNumber?: string;
  salesLedgerNumber?: string;
}

export interface PartnerSearchResult {
  id: number;
  name: string;
  taxNumber: string;
}

export interface PartnerValidationMessage {
  message: string;
  type?: NotificationEnum;
  params?: any;
}

export interface PartnerVatCheck {
  verificationDate: string;
  negativeInfo: boolean;
  euVerificationDate: string;
  euNegativeInfo: boolean;
}
