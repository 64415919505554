<div *ngIf="data" class="flex flex-column w-100">
  <div class="flex w-100 flex-end">
    <h5 [matTooltip]="tooltip | translate"
        [class]="'w-100 m-0 block pt-1 table__column--no-wrap txt-' + (align || 'center') + '-i'"
        [style.color]="textColor">{{data.label | translate: data.data}}</h5>
    <slm-button-icon *ngIf="!data?.disable"
                     [icon]="data?.icon"
                     size="20px"
                     [style.fill]="data.iconColor"
                     class="size-15 fill-accent mv-auto ml-2 block"
                     [matTooltip]="data.iconLabel | translate"
                     (click)="fireEvent.emit({event: 'buttonClick'})"></slm-button-icon>
  </div>
</div>
