<div
  *ngIf="sections.length > 0"
  class="
  breadcrumb
">
  <ng-container
    *ngFor="let section of sections; last as last; trackBy: trackById;"
  >
    <ng-container
      *ngIf="!last"
    >
      <a
        [routerLink]="'/'+baseUrl+'/'+section.id"
        class="section-name"
        (click)="breadcrumbClicked.emit(section.id)"
      >{{ section.name | translate }}</a> <span class="section-divider">|</span>
    </ng-container>
    <ng-container
      *ngIf="last"
    >
      <span class="section-name">{{ section.name | translate }}</span>
    </ng-container>

  </ng-container>
</div>
