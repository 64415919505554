import {Component, Input} from '@angular/core';

@Component({
  selector: 'slm-pdf-viewer',
  templateUrl: './pdf-viewer.component.html'
})
export class PdfViewerComponent {
  @Input() src: string;

  public showNoImage: boolean;

  error(){
    this.showNoImage = true;
  }
}
