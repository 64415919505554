/**
 * @summary An enum that specifies the available values for the Partner object's legalClass property.
 */
import {SelectOption} from '../entities';


export enum LegalClassEnum {
  COMPANY = 'company',
  PRIVATE_PERSON = 'privatePerson',
  FOREIGN_COMPANY = 'foreignCompany',
  THIRD_COUNTRY = 'thirdCountry'
}

export const legalClassOptions: Array<SelectOption> = [
  {
    value: LegalClassEnum.COMPANY,
    label: 'PARTNER_TYPES.COMPANY_LONG'
  },
  {
    value: LegalClassEnum.FOREIGN_COMPANY,
    label: 'PARTNER_TYPES.FOREIGNCOMPANY'
  },
  {
    value: LegalClassEnum.PRIVATE_PERSON,
    label: 'PARTNER_TYPES.PRIVATEPERSON'
  },
  {
    value: LegalClassEnum.THIRD_COUNTRY,
    label: 'PARTNER_TYPES.THIRDCOUNTRY'
  }
];

export type DefaultLedgerNumberTypes = 'vendor' | 'customer' | 'cash' | 'accrual';

export enum StatusClassEnum {
  REGISTRATION = 1,
  ACTIVE = 2,
  IN_DEBT = 3
}

export enum VatFrequency {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly'
}

export enum FileUploadModes {
  AS_SEPARATE_PAGES = 'asSeparatePages',
  AS_FILE = 'asFile'
}

export const partnerEmailLanguageOptions: Array<SelectOption> = [
  {
    label: 'LBL_LANGUAGE.NOT_SET',
    value: ''
  },
  {
    label: 'LBL_LANGUAGE.HU',
    value: 'hu'
  },
  {
    label: 'LBL_LANGUAGE.EN',
    value: 'en'
  }
];
