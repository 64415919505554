import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  TableOrder,
  ErrorHandlingService,
  capitalizeFirstLetter, PaginatorNew
} from '../../global';
import {CompanyUser, CompanyUserResponse, CompanyUserUpdate, UserStatistics} from '../entities/users.entity';

@Injectable({
  providedIn: 'root'
})
export class CompanyUserService {
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandler: ErrorHandlingService
  ) {}

  public getUsers(
    page: PaginatorNew,
    order: TableOrder,
    search: string = null,
    status: string,
    shortInfo: boolean
  ): Observable<CompanyUserResponse> {
    return this.http
      .get('/user/list', {
        params: {
          size: `${page?.size || 10}`,
          page: `${page?.page || 1}`,
          ...(order
            ? {
                [`order${capitalizeFirstLetter(order.attribute)}`]: order.order
              }
            : {}),
          ...(search ? { filterSearch: encodeURIComponent(search) } : {}),
          status,
          shortInfo
        }
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public updateUserPassword(id: number, newPassword: string): Observable<any> {
    return this.http
      .put(`/users/password/${id}`, { newPassword })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public updateUser(user: CompanyUserUpdate): Observable<any> {
    return this.http
      .put('/users', user)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public updateUserStatus(ids: Array<number>, status: string): Observable<any> {
    return this.http
      .put('/companies/users/statuses', { ids, status })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public createUser(user: CompanyUserUpdate): Observable<any> {
    return this.http
      .post('/users', user)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getUser(id: number): Observable<CompanyUser> {
    return this.http
      .get(`/users/${id}`)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getUserStatistics(): Observable<UserStatistics>{
    return this.http
      .get(`/users/statistics`)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }
}
