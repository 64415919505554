import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  FinanceCashFlowBalanceReport,
  FinanceReportsProcessing,
  FinanceReportsVatBalance,
  ListResponseNew,
  FinanceInvoicesReportsCashFlowItem,
  CashFlowInvoiceType,
  FinanceCashFlowInvoiceOverview, FinanceReportsCashFlow
} from '../entities';
import {catchError} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ErrorHandlingService} from './error-handling.service';
import {InvoiceTypeEnum, YearQuarter} from '../enums';
import {utils} from '../functions';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandler: ErrorHandlingService
  ) {
  }

  public getVatBalance(queryParams: { year: number; month?: number; quarter?: YearQuarter; navVat?: boolean }): Observable<FinanceReportsVatBalance> {
    const params = queryParams?.month !== undefined ? {
      year: queryParams.year,
      month: queryParams.month + 1,
      navVat: queryParams?.navVat
    } : queryParams;
    return this.http
      .get('/finances/reports/vatBalance', {params})
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getCashFlowBalance(): Observable<FinanceCashFlowBalanceReport> {
    return this.http
      .get('/finances/cash-flow/balance')
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getInvoicesStatus(
    from: Date,
    to: Date,
    type: InvoiceTypeEnum.INCOMING | InvoiceTypeEnum.OUTGOING
  ): Observable<FinanceReportsProcessing> {
    return this.http
      .get('/finances/reports/processing', {
        params: {from: utils.dateToServerDate(from), to: utils.dateToServerDate(to), type}
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getCashFlow(
    period: string,
    verified: boolean
  ): Observable<FinanceReportsCashFlow> {
    return this.http
      .get('/finances/reports/cashflow', {
        params: {period, verified: `${verified}`}
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getInvoicesCashFlow(
    interval: 'daily' | 'weekly' | 'monthly',
    verified: boolean,
    type: CashFlowInvoiceType,
    orderBy: 'creationDate' | 'completeDate' | 'paymentDeadline',
    from: Date,
    to: Date
  ): Observable<ListResponseNew<FinanceInvoicesReportsCashFlowItem>> {
    from.setHours(2);
    return this.http
      .get('/finances/cashflow', {
        params: {
          interval,
          type,
          orderBy,
          verified: `${verified}`,
          from: utils.dateToServerDate(from),
          to: utils.dateToServerDate(to)
        }
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getInvoiceBalances(
    type: CashFlowInvoiceType,
    amount: 'net' | 'gross'
  ): Observable<FinanceCashFlowInvoiceOverview> {
    return this.http
      .get('/finances/cashflow/invoice-balance', {params: {type, amount}})
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }
}
