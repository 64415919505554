<mat-form-field class="flex-grow"
                [class.text-input--required]="isRequiredField  && (control.valid || !control.dirty)">
  <mat-label *ngIf="label || (!isRequiredField && !hideOptionalLabel)"
    >{{ label | translate
    }} <span *ngIf="secondaryLabel">{{ secondaryLabel | translate }}</span>
    <span *ngIf="!isRequiredField && !hideOptionalLabel" translate="LBL_INPUT.LABEL.NOT_REQUIRED"></span>
  </mat-label>
  <mat-chip-grid #chipList>
    <mat-chip-row
      *ngFor="let item of chips$ | async"
      removable
      color="primary"
      (removed)="removeItem(item)"
    >
      {{ item }}
      <slm-svg-icon icon="close" matChipRemove size="20px"></slm-svg-icon>
    </mat-chip-row>
    <input
      matInput
      [attr.data-test-id]="testId"
      [type]="type"
      [required]="isRequiredField"
      [placeholder]="placeholder | translate"
      [matChipInputAddOnBlur]="true"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="chipLimiters"
      (matChipInputTokenEnd)="addItem($event)"
    />
  </mat-chip-grid>
  <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
  <mat-error *ngIf="errorMessage$ | async as message">{{
    message | translate: errorBody
  }}</mat-error>
</mat-form-field>
