import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bankAccount'
})
export class BankAccountPipe implements PipeTransform {
  transform(value: any, replace: string = ''): any {
    if (!value) {
      return replace;
    }
    const accountNumber = `${value}`.toUpperCase().replace(/[^\dA-Z]/g, '');
    if (!accountNumber) {
      return replace;
    }
    if (accountNumber.length > 4) {
      return accountNumber.replace(/[\dA-Z]{4}(?=.)/g, '$& ');
    }
    return accountNumber;
  }
}
