<div class="w-100">
  <ng-container
    *ngFor="let company of companyArray.controls; let first = first; let index = index">
    <h5 *ngIf="!first"
        translate="AUTHENTICATION.REGISTRATION.EXTRA_COMPANY_TITLE"
        [translateParams]="{number: index}"
        class="txt-start-i txt-medium border-bottom"></h5>
    <div class="grid-responsive grid grid-col-2-50">
      <slm-text-input
        label="AUTHENTICATION.REGISTRATION.COMPANY_NAME"
        [errors]="errors"
        [control]="company.get('companyName')"
      ></slm-text-input>

      <slm-autocomplete-input
        *ngIf="!disableTaxNumberAutocomplete"
        label="INVOICE.TAX_NUMBER"
        [errors]="errors"
        searchLengthRequirement="3"
        [control]="company.get('taxNumber')"
        [options]="taxNumberOptions"
      ></slm-autocomplete-input>
      <slm-text-input
        *ngIf="disableTaxNumberAutocomplete"
        label="INVOICE.TAX_NUMBER"
        [errors]="errors"
        [control]="company.get('taxNumber')"
      ></slm-text-input>

      <slm-text-input
        label="LBL_PARTNERS.COMPANY_REGISTRATION_NUMBER"
        [errors]="errors"
        [control]="company.get('registrationNumber')"></slm-text-input>

      <slm-text-input
        label="LBL_PARTNERS.EDIT.CITY"
        [errors]="errors"
        [control]="company.get('city')"
      ></slm-text-input>

      <slm-text-input
        label="LBL_PARTNERS.EDIT.ZIP"
        [errors]="errors"
        [control]="company.get('zipCode')"
      ></slm-text-input>

      <slm-text-input
        label="LBL_PARTNERS.EDIT.STREET"
        [errors]="errors"
        [control]="company.get('street')"
      ></slm-text-input>

      <slm-text-input
        label="LBL_PARTNERS.EDIT.ADDRESS"
        [errors]="errors"
        [control]="company.get('address')"
      ></slm-text-input>
      <div class="flex flex-end w-100 mt-2 mb-4">
        <slm-button text="LBL_BTN.DELETE"
                    *ngIf="companyArray.length > 1"
                    [icon]="icons.DELETE"
                    buttonStyle="transparent-blue"
                    (click)="removeCompany(index)"></slm-button>
      </div>
    </div>
  </ng-container>
  <div *ngIf="canAddMoreCompany && !single" class="flex flex-end w-100 mt-2 mb-4">
    <slm-button text="AUTHENTICATION.REGISTRATION.EXTRA_COMPANY"
                buttonStyle="secondary"
                [icon]="icons.ADD_CIRCLE"
                (click)="addCompany()"></slm-button>
  </div>

  <ng-container *ngIf="!hideAuth">

    <h5 class="txt-start-i block pb-1 mb-2 txt-medium border-bottom"
        translate="AUTHENTICATION.AUTHORIZATION_DATA"></h5>

    <div class="grid-responsive grid grid-col-2-50">

      <slm-text-input
        label="LBL_PROFILE.NAME"
        [errors]="errors"
        [control]="registrationForm.get('fullName')"
      ></slm-text-input>

      <slm-text-input
        label="AUTHENTICATION.PHONE"
        type="phone"
        [errors]="errors"
        [control]="registrationForm.get('phone')"
      ></slm-text-input>

      <slm-text-input
        label="AUTHENTICATION.EMAIL"
        type="email"
        [errors]="errors"
        [control]="registrationForm.get('email')"
      ></slm-text-input>

      <slm-toggle
        [control]="registrationForm.get('acceptedUserTerms')"
        label="AUTHENTICATION.TERMS_USAGE"
        isInnerHtml="true"
        class="block mb-6"
      ></slm-toggle>

    </div>

    <h5 class="txt-start-i block pb-1 mb-2 txt-medium border-bottom"
        translate="AUTHENTICATION.REGISTRATION.LOGIN_DATA"></h5>

    <div class="grid-responsive grid grid-col-2-50">

      <div>

        <slm-text-input
          label="LBL_INPUT.LABEL.PASSWORD"
          [suffixIcon]="inputConfig.password.icon"
          [type]="inputConfig.password.type"
          enterEnabled="true"
          (fireEvent)="passwordTypeChanged('password')"
          [errors]="errors"
          [control]="registrationForm.get('password')"
        ></slm-text-input>

        <slm-text-input
          label="LBL_PASSWORD.PASSWORD_CONFIRMATION"
          [suffixIcon]="inputConfig.passwordConfirmation.icon"
          [type]="inputConfig.passwordConfirmation.type"
          enterEnabled="true"
          (fireEvent)="passwordTypeChanged('passwordConfirmation')"
          [errors]="errors"
          [control]="registrationForm.get('confirmPassword')"
        ></slm-text-input>

      </div>

      <slm-password-strength-presenter
        [control]="registrationForm.get('password')"
      ></slm-password-strength-presenter>

    </div>
    <slm-message
      *ngIf="passwordNotMatch$ | async"
      type="error"
      class="block w-max-2680px mb-3 mt-2"
      message="LBL_PASSWORD.PASSWORD_NOT_MATCH"
    ></slm-message>
  </ng-container>

  <ng-container *ngIf="!hideAuth">
    <h5 class="txt-start-i block pb-1 mb-2 txt-medium border-bottom"
        translate="AUTHENTICATION.REGISTRATION.SUBSCRIPTION"></h5>

    <div class="grid-responsive grid grid-col-2-50">
      <slm-grouped-select-input
        label="AUTHENTICATION.REGISTRATION.PACKAGE"
        [control]="registrationForm.get('generalPackage')"
        [errors]="errors"
        [options]="subscriptions"
      ></slm-grouped-select-input>
      <slm-text-input
        label="AUTHENTICATION.REGISTRATION.EXTRA_USER"
        type="number"
        min="0"
        [control]="registrationForm.get('userCount')"
        [errors]="errors"
        [hint]="userCountHint$ | async"
      ></slm-text-input>
      <slm-text-input
        label="ELECTRONIC_INVOICE_EMAIL"
        type="email"
        [control]="registrationForm.get('invoiceEmail')"
        [errors]="errors"
      ></slm-text-input>
    </div>
    <div class="grid-responsive grid grid-col-2-50">
      <slm-select-input
        [label]="'AUTHENTICATION.REGISTRATION.WHERE_TO_SEE'"
        [options]="whereToSeeOptions"
        [control]="this.registrationForm.get('whereToSee')"
        testId="whereToSeeSelect"
      ></slm-select-input>
      <slm-text-input
        *ngIf="showOtherDesc$ | async"
        [label]="'AUTHENTICATION.REGISTRATION.WHERE_TO_SEE_OPTIONS.OTHER'"
        [control]="this.registrationForm.get('otherDescription')"
        maxLength="64"
        testId="otherDescription"
      ></slm-text-input>
    </div>
    <div
      *ngIf="showPackageWarning$ | async"
      class="grid-responsive grid">
      <slm-message
        type="warning"
        [message]="'AUTHENTICATION.REGISTRATION.BANK_NOT_INCLUDED'"
      ></slm-message>
    </div>

    <div class="w-100 border-top border mt-3 mb-3 pt-3 pb-3 flex flex-end">

      <h4 class="w-auto-i txt-end-t pr-1 mt-1-i txt-start-i">
        {{'AUTHENTICATION.REGISTRATION.MONTHLY_RATE' | translate}}:
      </h4>

      <h3 class="txt-end-i w-auto-i" *ngIf="(finalPrice$ | async) as finalPrice">
        {{((finalPrice.price) || 0)| currencyTransform: finalPrice.currency || 'HUF'}}
        <ng-container *ngIf="finalPrice.price">+ {{'VAT' | translate}}</ng-container>
      </h3>

    </div>
  </ng-container>

  <slm-toggle
    [control]="registrationForm.get('acceptedTerms')"
    label="AUTHENTICATION.REGISTRATION.TERMS"
    isInnerHtml="true"
    class="block mb-2"
  ></slm-toggle>

  <slm-text-input *ngIf="!hideAuth && registrationForm.get('acceptedTerms').value"
                  label="AUTHENTICATION.TERMS_ACCEPTED_BY"
                  class="w-max-350px"
                  [control]="registrationForm.get('acceptTermsBy')"
                  [errors]="errors"
  ></slm-text-input>

  <slm-toggle
    [control]="registrationForm.get('acceptedPlans')"
    label="AUTHENTICATION.REGISTRATION.PLANS"
    isInnerHtml="true"
    class="block mb-2"
  ></slm-toggle>

  <slm-toggle
    [control]="registrationForm.get('acceptedPolicy')"
    label="AUTHENTICATION.REGISTRATION.POLICY"
    isInnerHtml="true"
    class="block mb-6"
  ></slm-toggle>

</div>
