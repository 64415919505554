<article class="card card--no-padding">
  <div *ngIf="title" class="card__header">
    <h4 class="card__header__title">{{ title | translate }}</h4>
    <div *ngIf="buttonConfig" class="card__header__buttons">
      <slm-button
        [config]="buttonConfig"
        (click)="
          fireEvent.emit({ event: buttonConfig.action || 'buttonClick' })
        "
      ></slm-button>
    </div>
  </div>
  <div class="card__content pl-3 pr-3">
    <ng-content></ng-content>
  </div>
</article>
