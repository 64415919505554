import { Component, Input } from '@angular/core';
import {Icons, SelectOption, utils} from '../../../../global';
import { InputAbstract } from '../base/input.abstract';
import {SelectAutocompleteInputComponent} from '../select-autocomplete-input/select-autocomplete-input.component';

@Component({
  selector: 'slm-bank-account-input',
  templateUrl: './bank-account-input.component.html',
  styles: [`
    :host {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
    }
  `]
})
export class BankAccountInputComponent extends InputAbstract {
  @Input() public enterEnabled = false;
  @Input() public buttonIcon = true;
  @Input() public prefixIcon: Icons = null;
  @Input() public prefixLabel = '';
  @Input() public suffixIcon: Icons = null;
  @Input() public suffixLabel = '';
  @Input() public options: Array<SelectOption> = [];
  @Input() public autocomplete = false;

  public clear(): void {
    if (this.control) {
      this.control.setValue('');
    }
  }

  displayValue(selected: SelectOption | string): any {
    let label: string;
    if (!selected) {
      return '';
    }
    if (utils.isObject(selected)) {
      if ((selected as SelectOption)?.label) {
        label = SelectAutocompleteInputComponent.getOptionLabel(selected as SelectOption);
      } else {
        label = this.control?.value ?? '';
      }
    } else {
      const match = (this.options ?? []).find((option) => option.value === option);
      if (match) {
        label = SelectAutocompleteInputComponent.getOptionLabel(match);
      } else {
        label = match?.value ?? selected;
      }
    }
    return label?.length > 100 ? `${label.substring(0, 97)}...` : label;
  }
}
