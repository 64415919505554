import {UntypedFormArray} from '@angular/forms';
import {InvoicePaymentTypeEnum} from '../enums';
import {utils} from './converter.functions';

export class InvoicePaymentFunctions {

  static getPaymentData(formArray: UntypedFormArray){
    return (formArray?.value || []).map((value) => ({
      type: value.type,
      ...(value.type === InvoicePaymentTypeEnum.CASH
        ? {amount: value.form?.value, paymentDate: utils.dateToServerDate(value.form?.date)}
        : value.type === InvoicePaymentTypeEnum.PROFORMA
          ? {proformaId: value.form?.prepayment.id}
          : value.type === InvoicePaymentTypeEnum.MONEY
            ? {amount: value.form?.value, paymentDate: utils.dateToServerDate(value.form?.date)}
            : value.type === InvoicePaymentTypeEnum.COMPENSATION
              ? {amount: value.form?.value, paymentDate: utils.dateToServerDate(value.form?.date), note: value.form?.note}
              : value.type === InvoicePaymentTypeEnum.DISCOUNT
                ? {amount: value.form?.value, paymentDate: utils.dateToServerDate(value.form?.date), percent: value.form?.percent}
                : value.type === InvoicePaymentTypeEnum.OTHER
                  ? {amount: value.form?.value, paymentDate: utils.dateToServerDate(value.form?.date), note: value.form?.note}
                  : value.type === InvoicePaymentTypeEnum.BANK_CARD
                    ? {amount: value.form?.value, paymentDate: utils.dateToServerDate(value.form?.date)}
                  : null)
    }));
  }
}
