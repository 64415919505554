import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'slm-table-selection-button',
  template: `
    <div [matTooltip]="tooltip | translate" class="w-100 block">
      <slm-button
        height="normal"
        class="w-max-140 block m-auto"
        [icon]="selected ? 'check_circle' : null"
        [text]="selected ? 'LBL_BTN.SELECTED' : 'LBL_BTN.SELECT'"
        testId="selectInvoice"
        [buttonStyle]="selected ? 'primary' : 'secondary'"
        (click)="selectionChanged.emit()"
      ></slm-button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableSelectionButtonComponent {
  @Input() selected: boolean = null;
  @Output() selectionChanged = new EventEmitter();
  @Input() tooltip = '';
}
