import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
  CustomValidators,
  InputError,
  SelectOption,
  inputErrorTypes,
  Icon, Currency, GroupedSelectOptions
} from '../../../global';
import {BehaviorSubject, combineLatest, concat, Observable, of} from 'rxjs';
import {debounceTime, map, shareReplay, tap} from 'rxjs/operators';
import {whereToSeeOptions} from '../../../../app/containers/registration/registration.config';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'slm-registration-form',
  templateUrl: './registration.form.html'
})
export class RegistrationForm implements OnInit {

  public readonly errors: Array<InputError> = [
    inputErrorTypes.required,
    inputErrorTypes.taxNumber,
    inputErrorTypes.minLength,
    inputErrorTypes.maxLength,
    inputErrorTypes.strongPassword,
    inputErrorTypes.email,
    inputErrorTypes.phone,
    inputErrorTypes.min,
    inputErrorTypes.max,
    inputErrorTypes.companyRegistrationNumber
  ];
  public readonly icons = Icon;
  public readonly whereToSeeOptions = whereToSeeOptions;
  public canAddMoreCompany = true;
  public registrationForm: UntypedFormGroup;
  public companyArray: UntypedFormArray = null;

  public passwordNotMatch$: Observable<boolean>;
  public finalPrice$: Observable<{price: number; currency: Currency}>;
  public showPackageWarning$ = new BehaviorSubject<boolean>(false);
  public showOtherDesc$: Observable<boolean>;
  public userCountHint$ = new BehaviorSubject<string>(null);

  private maxCompanyCount: number;

  @Output() public readonly form = new EventEmitter<UntypedFormGroup>();
  @Input() public readonly taxNumberOptions: Array<SelectOption> = [];
  @Input() hideAuth = false;
  @Input() disableTaxNumberAutocomplete = false;
  @Input() subscriptions: Array<GroupedSelectOptions> = [];
  @Input() single = false;

  public inputConfig = {
    password: {
      type: 'password',
      icon: Icon.VISIBILITY
    },
    passwordConfirmation: {
      type: 'password',
      icon: Icon.VISIBILITY
    }
  };

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.registrationForm = this.fb.group({
      companies: this.fb.array([this.addCompanyForm()]),
      acceptedTerms: [false, [Validators.requiredTrue]],
      acceptedPlans: [false, [Validators.requiredTrue]],
      acceptedPolicy: [false, [Validators.requiredTrue]],
      ...(this.hideAuth
        ? {}
        : {
          email: ['', [Validators.required, Validators.email]],
          phone: ['', [Validators.required, CustomValidators.phone]],
          fullName: ['', [Validators.required]],
          password: [
            '',
            [
              Validators.required,
              CustomValidators.strongPassword,
              Validators.minLength(8)
            ]
          ],
          confirmPassword: [
            '',
            [
              Validators.required,
              CustomValidators.strongPassword,
              Validators.minLength(8)
            ]
          ],
          acceptedUserTerms: [false, [Validators.requiredTrue]],
          generalPackage: ['', [Validators.required]],
          userCount: [{value: 0, disabled: true}, [Validators.min(0)]],
          invoiceEmail: ['', [Validators.required, Validators.email]],
          acceptTermsBy: ['', [Validators.required]],
          whereToSee: ['', [Validators.required]],
          otherDescription: ['']
        })
    });
    this.companyArray = this.registrationForm.get('companies') as UntypedFormArray;
    this.form.emit(this.registrationForm);
    const passwordControl = this.registrationForm.get('password');
    if (!this.hideAuth) {
      this.passwordNotMatch$ = combineLatest([
        this.registrationForm.get('confirmPassword').valueChanges,
        passwordControl.valueChanges
      ]).pipe(
        map((values) => !!values[0] && !!values[1] && values[0] !== values[1])
      );

      this.showOtherDesc$ = this.registrationForm
        .get('whereToSee').valueChanges.pipe(
          map(value => !!value.includes('other')),
          shareReplay({bufferSize: 1, refCount: true})
      );
    }

    const generalPackageControl = this.registrationForm.get('generalPackage');
    const userCountControl = this.registrationForm.get('userCount');

    /**
     * Combining the package and user count control event values.
     */

    if (generalPackageControl && userCountControl) {
      this.finalPrice$ = combineLatest([
        concat(of(null), generalPackageControl.valueChanges),
        concat(of(0), userCountControl.valueChanges)
      ]).pipe(
        debounceTime(250),
        tap(([subscription, _]) => {
          this.maxCompanyCount = subscription?.maxPartnerInGroup;
          this.checkToAddMoreCompany();

          if(subscription?.maxFreeUser){
            const label = this.translateService.instant('AUTHENTICATION.REGISTRATION.DEFAULT_USER',
              {freeUserNumber: subscription?.maxFreeUser});
            this.userCountHint$.next(label);
          }else{
            this.userCountHint$.next(null);
          }

          if(subscription?.maxUser){
            userCountControl.clearValidators();
            userCountControl.addValidators([Validators.min(0), Validators.max(subscription?.maxUser)])
            userCountControl.updateValueAndValidity({emitEvent: false});
            subscription?.maxUser === 1 ? userCountControl.disable({emitEvent: false}) : userCountControl.enable({emitEvent: false});
          }
        }),
        map(
          ([subscription, userCount]: [any, any]) => ({
              price: (+subscription?.baseFee ?? 0) +
                (+subscription?.userFee ?? 0) * (+userCount ?? 0),
              currency: subscription?.currency
            })
        ),
        shareReplay({
          refCount: true,
          bufferSize: 1
        })
      );
    }
  }

  public passwordTypeChanged(type: string): void {
    const isPassword = this.inputConfig[type]?.type === 'password';
    this.inputConfig[type] = {
      type: isPassword ? 'text' : 'password',
      icon: isPassword ? Icon.VISIBILITY_OFF : Icon.VISIBILITY
    };
  }

  private addCompanyForm(): UntypedFormGroup {
    return this.fb.group({
      registrationNumber: ['', [Validators.required, CustomValidators.companyRegistrationNumber]],
      companyName: ['', [Validators.required]],
      taxNumber: ['', [Validators.required, CustomValidators.taxNumber]],
      city: ['', [Validators.required]],
      zipCode: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(16)
        ]
      ],
      street: ['', [Validators.required]],
      address: ['', [Validators.required]]
    });
  }

  public addCompany() {
    const companyFormArray = this.registrationForm.get('companies') as UntypedFormArray;
    if (companyFormArray.length >= this.maxCompanyCount || this.single) {
      return;
    }
    companyFormArray.push(this.addCompanyForm());
    this.checkToAddMoreCompany();
  }

  public removeCompany(index: number) {
    if (this.single) {
      return;
    }
    const companyFormArray = this.registrationForm.get('companies') as UntypedFormArray;
    if (companyFormArray.length === 1) {
      return;
    }
    companyFormArray.removeAt(index);
    this.checkToAddMoreCompany();
  }

  private checkToAddMoreCompany(){
    const companyFormArray = this.registrationForm.get('companies') as UntypedFormArray;
    this.canAddMoreCompany = companyFormArray.length < this.maxCompanyCount;
  }
}
