export enum TableColumnType {
  TEXT = 'text',
  TEXT_BUTTON = 'text_button',
  COUNTER_TEXT = 'counter_text',
  COUNTER_ICON = 'counter_icon',
  DATE = 'date',
  DATE_INTERVAL = 'date-interval',
  CURRENCY = 'currency',
  ICON_TEXT = 'icon-text',
  MENU = 'menu',
  CHECKBOX = 'checkbox',
  STATUS_TEXT = 'status_text',
  USER = 'user',
  SELECTION_BUTTON = 'selection_btn',
  CUSTOM_BUTTON = 'custom_btn',
  STEPPER = 'stepper',
  EDITABLE = 'editable',
  PAYMENT_DETAILED = 'payment_detailed',
  ICON_BUTTON = 'icon_button'
}

export enum TableAlign {
  START = 'start',
  END = 'end',
  CENTER = 'center'
}

export interface DragDropListElement {
  name: string;
  id: string;
}

export const InvoiceColumnTypes: Array<DragDropListElement> = [
  {
    name: 'LBL_PARTNERS.PARTNER_NAME',
    id: 'partnerName'
  },
  {
    name: 'SETTINGS.LIST_VIEW_COLUMN_TYPE_NAMES.NOTE_COUNTER',
    id: 'messageCounter'
  },
  {
    name: 'INVOICE_GENERALS.INVOICE_NUMBER',
    id: 'invoiceNumber'
  },
  {
    name: 'INVOICE_GENERALS.ACCOUNTING_STATUS',
    id: 'accountingStatus'
  },
  {
    name: 'INVOICE_GENERALS.FILLING_STATUS',
    id: 'fillingStatus'
  },
  {
    name: 'INVOICE_GENERALS.PAYMENT_STATUS',
    id: 'paymentStatus'
  },
  {
    name: 'INVOICE_GENERALS.COMPLETION_DATE',
    id: 'completionDate'
  },
  {
    name: 'INVOICE_GENERALS.DEADLINE',
    id: 'paymentDeadline'
  },
  {
    name: 'INVOICE_GENERALS.DATE_OF_FILLING',
    id: 'fillingDate'
  },
  {
    name: 'INVOICE_GENERALS.VALUE',
    id: 'grossValue'
  },
  {
    name: 'INVOICE_GENERALS.DOC_TYPE',
    id: 'documentType'
  },
  {
    name: 'INVOICE_GENERALS.REGISTRATION_NUMBER',
    id: 'registrationNumber'
  },
  {
    name: 'SETTINGS.LIST_VIEW_COLUMN_TYPE_NAMES.TEMPLATE_NAME',
    id: 'templateName'
  },
  {
    name: 'INVOICE_GENERALS.CREATION_DATE',
    id: 'creationDate'
  },
  {
    name: 'INVOICE_GENERALS.RECORDING_DATE',
    id: 'recordingDate'
  },
  {
    name: 'INVOICE_GENERALS.CURRENCY',
    id: 'currency'
  },
  {
    name: 'INVOICE_GENERALS.EXCHANGE_RATE',
    id: 'exchangeRate'
  },
  {
    name: 'INVOICE_GENERALS.NET_VALUE',
    id: 'netValue'
  },
  {
    name: 'INVOICE_GENERALS.VAT_VALUE',
    id: 'vatValue'
  },
  {
    name: 'INVOICE_GENERALS.PAYMENT_MODE',
    id: 'paymentType'
  },
  {
    name: 'INVOICE_GENERALS.REFERENCE_NOTICE',
    id: 'referenceNotice'
  },
  {
    name: 'SETTINGS.LIST_VIEW_COLUMN_TYPE_NAMES.LAST_MESSAGE',
    id: 'lastMessage'
  },
  {
    name: 'SETTINGS.LIST_VIEW_COLUMN_TYPE_NAMES.PAYMENTS_DETAILED',
    id: 'paymentsDetailed'
  },
  {
    name: 'SETTINGS.LIST_VIEW_COLUMN_TYPE_NAMES.PAYMENTS',
    id: 'payments'
  },
  {
    name: 'INVOICE_GENERALS.FILED_WITH_OCR',
    id: 'ocr'
  },
  {
    name: 'SETTINGS.LIST_VIEW_COLUMN_TYPE_NAMES.NAV_STATUS',
    id: 'navStatus'
  },
  {
    name: 'INVOICE.ATTR.DATE_OF_65M',
    id: '65mDate'
  },
  {
    name: 'INVOICE_GENERALS.PAID_VALUE',
    id: 'paidValue'
  },
  {
    name: 'INVOICE.PAGE.ATTACHMENTS',
    id: 'attachmentNumber'
  },
  {
    name: 'INVOICE.DIVISION',
    id: 'divisionName'
  },
  {
    name: 'INVOICE.DEFFER_DATE',
    id: 'defferedVatDate'
  }
];
