import {Component, Input} from '@angular/core';
import {AbstractControl, UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'slm-toggle',
  template: `
    <mat-slide-toggle color="primary" class="slm-toggle"
                      [formControl]="control"
                      [attr.data-test-id]="testId"
                      [class.slm-toggle--invalid]="(control?.dirty || control?.touched) && control?.invalid"
                      [class]="uniqueClass">
      <ng-container *ngIf="!isInnerHtml; else innerHTML">{{
        label | translate
        }}</ng-container>
    </mat-slide-toggle>
    <ng-template #innerHTML>
      <div [innerHTML]="label | translate"></div>
    </ng-template>
  `
})
export class ToggleComponent {
  @Input() public label = '';
  @Input() public testId = '';
  @Input() public isInnerHtml = false;
  @Input() public control: UntypedFormControl | AbstractControl;
  @Input() public uniqueClass = '';
}
