import {filter, switchMap, take} from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthSandbox } from '../../modules/global';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authSandbox: AuthSandbox;
  private translateService: TranslateService;

  constructor(private readonly injector: Injector) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isNotApiCall =
      req.url.match('^(((https|http)://)|(/assets/)).*$') !== null;

    const isPublicApi = req.url.includes('/public/');

    const isRefresh = req.url.includes('/auth/token') && req.method === 'POST';
    if (isNotApiCall || isPublicApi || isRefresh) {
      return next.handle(req);
    }

    this.authSandbox = this.injector.get(AuthSandbox);
    this.translateService = this.injector.get(TranslateService);
    const token: string = this.authSandbox.getJwtToken();

    if (!token) {
      return next.handle(req);
    }

    if (this.authSandbox.refreshing || this.authSandbox.isTokenExpired(token)) {
      return this.authSandbox.token$.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((jwtToken) => next.handle(TokenInterceptor.setAuthorizationHeader(req, jwtToken, this.translateService.currentLang)))
      );
    }

    return next.handle(TokenInterceptor.setAuthorizationHeader(req, token, this.translateService.currentLang));
  }

  public static setAuthorizationHeader(
    request: HttpRequest<any>,
    token: string,
    language: string = 'hu'
  ): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        // Authorization: `Bearer ${token}`
        Authorization: token,
        Language: language
      }
    });
  }
}
