import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators, Icons, InputError} from '../../../../global';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

@Component({
  selector: 'slm-date-range-input',
  templateUrl: './date-range-input.component.html',
  styles: [`
    :host {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
    }
  `]
})
export class DateRangeInputComponent implements OnInit {
  @Input() public label = '';
  @Input() public secondaryLabel = '';
  @Input() public placeholderStart = '';
  @Input() public placeholderEnd = '';
  @Input() public hint = '';
  @Input() public min: Date = null;
  @Input() public max: Date = null;
  @Input() public groupControl: AbstractControl | UntypedFormGroup | any;
  @Input() public controlStart = 'start';
  @Input() public controlEnd = 'end';
  @Input() public prefixIcon: Icons = null;
  @Input() public suffixIconLabel = '';
  @Input() public errorsStart: Array<InputError> = [];
  @Input() public errorsEnd: Array<InputError> = [];
  @Input() public firstTestId = '';
  @Input() public secondTestId = '';
  @Input() public coloredValue = false;
  @Input() public hideOptionalLabel = false;
  public isRequiredField = false;
  public errors$: Observable<string>;

  ngOnInit() {
    this.initRequiredFieldCheck();
    this.errors$ = this.groupControl.statusChanges.pipe(
      debounceTime(250),
      map((value: string) => 'INVALID' === value && this.groupControl.touched ? this.getError(): null),
      distinctUntilChanged()
    );
  }

  private initRequiredFieldCheck() {
    this.isRequiredField = DateRangeInputComponent.checkRequiredValidator(this.groupControl) ||
      DateRangeInputComponent.checkRequiredValidator(this.groupControl.get(this.controlStart)) ||
      DateRangeInputComponent.checkRequiredValidator(this.groupControl.get(this.controlEnd));
  }

  private static checkRequiredValidator(control: AbstractControl) {
    return control.hasValidator(Validators.required) || control.hasValidator(CustomValidators.trimRequired);
  }

  public getError() {
    this.initRequiredFieldCheck();
    const startErrorKeys = Object.keys(
      this.groupControl?.get(this.controlStart).errors || {}
    );
    if (startErrorKeys.length) {
      const errorType = startErrorKeys[0];
      if(errorType === 'matStartDateInvalid' &&
        (new Date(this.groupControl?.get(this.controlStart).value) < new Date(this.groupControl?.get(this.controlEnd).value))){
        this.groupControl?.get(this.controlStart).updateValueAndValidity();
      }

      const error = (this.errorsStart || []).find(
        (error: InputError) => error.name === errorType
      );
      return error?.message;
    }
    const endErrorKeys = Object.keys(
      this.groupControl?.get(this.controlEnd).errors || {}
    );
    if (endErrorKeys.length) {
      const errorType = endErrorKeys[0];
      const error = (this.errorsEnd || []).find(
        (error: InputError) => error.name === errorType
      );
      return error?.message;
    }

    const groupErrorKeys = Object.keys(
      this.groupControl.errors || {}
    );
    if (groupErrorKeys.length) {
      const errorType = groupErrorKeys[0];

      const error = (this.errorsStart || []).find(
        (error: InputError) => error.name === errorType
      );
      return error?.message;
    }
    return null;
  }
}
