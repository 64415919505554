import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'slm-checkbox',
  template: `
    <mat-checkbox
      class="slm-checkbox"
      color="primary"
      [attr.data-test-id]="testId"
      [class.slm-checkbox--invalid]="
        (control?.dirty || control?.touched) && control?.invalid
      "
      [formControl]="control"
      ><ng-container *ngIf="!isInnerHtml; else innerHTML">{{
        label | translate
      }}</ng-container></mat-checkbox
    >
    <ng-template #innerHTML>
      <div [innerHTML]="label | translate"></div>
    </ng-template>
  `
})
export class CheckboxComponent {
  @Input() public label = '';
  @Input() public testId = '';
  @Input() public isInnerHtml = false;
  @Input() public control: UntypedFormControl | AbstractControl | any;
}
