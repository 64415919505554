import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {globalReducer, GlobalEffects} from './+states';
import {EffectsModule} from '@ngrx/effects';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature({name: globalReducer.name, reducer: globalReducer.reducer}),
    EffectsModule.forFeature([GlobalEffects])
  ],
  declarations: [],
  exports: []
})
export class GlobalModule {}
