import {Injectable} from '@angular/core';
import {DialogService} from './dialog.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {InvoiceTableItem, randomNumber} from '../../global';
import {ComponentType} from '@angular/cdk/overlay';
import {
  QuestionDialogComponent,
  InvoicePaymentDialogComponent
} from '../dialog';
import {NavRefreshDialog, NavRefreshDialogConfig} from '../dialog/nav-refresh/nav-refresh.dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogPresenterService extends DialogService {
  private dialogRef: MatDialogRef<any>;
  private _dialogId = 0;
  public readonly QUESTION_DIALOG_ID = 1;
  public readonly MESSAGE_DIALOG_ID = 2;

  constructor(private readonly matDialog: MatDialog) {
    super(matDialog);
  }

  public openQuestionDialog(
    title: string,
    label: string,
    labelParams: any,
    approveButton: string = null,
    declineButton: string = null,
    disableClose: boolean = false,
    isHtml: boolean = false
  ): Observable<boolean> {
    this.close();
    this._dialogId = this.QUESTION_DIALOG_ID;
    this.dialogRef = this.open(QuestionDialogComponent, {
      data: {title, label, labelParams, approveButton, declineButton, isHtml},
      disableClose
    });
    return this.dialogRef.afterClosed().pipe(
      tap(() => (this._dialogId = 0)),
      map((value) => value === 'approve')
    );
  }

  public openMessageDialog(
    title: string,
    label: string,
    labelParams: any,
    approveButton: string = null,
    disableClose: boolean = false,
    isHtml: boolean = false
  ): Observable<boolean> {
    this.close();
    this._dialogId = this.MESSAGE_DIALOG_ID;
    this.dialogRef = this.open(QuestionDialogComponent, {
      data: {
        title,
        label,
        labelParams,
        approveButton,
        declineButton: null,
        onlyApprove: true,
        isHtml
      },
      disableClose
    });
    return this.dialogRef.afterClosed().pipe(
      tap(() => (this._dialogId = 0)),
      map((value) => value === 'approve')
    );
  }

  public openDialog(
    dialogComponent: ComponentType<any>,
    dialogData: any = null,
    dialogConfig: {
      dialogRefName?: number;
      disableClose?: boolean;
      returnClose?: boolean;
      disableDefaultClass?: boolean | string;
    } = null
  ): Observable<any> {
    const {dialogRefName, disableClose, returnClose, disableDefaultClass} = dialogConfig ?? {};
    this.close();
    this._dialogId = dialogRefName ?? randomNumber();
    this.dialogRef = this.open(
      dialogComponent,
      {
        data: dialogData,
        disableClose: disableClose ?? false
      },
      disableDefaultClass || false
    );
    return this.dialogRef.afterClosed().pipe(
      tap(() => (this._dialogId = 0)),
      filter((value) => (returnClose || false) || !!value)
    );
  }

  public paymentDialog(data: {
    invoice: InvoiceTableItem;
    payments: Observable<any>;
    title: string;
    save: ((paymentData) => Observable<any>)
  }): Observable<any> {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this._dialogId = 2;
    this.dialogRef = this.open(InvoicePaymentDialogComponent, {
      data: {
        invoice: {
          ...data.invoice,
          deadline: data.invoice?.paymentDeadline,
          number: data.invoice?.invoiceNumber,
          currency: data.invoice?.currency,
          partnerName: data.invoice?.partner?.name
        },
        payments$: data.payments,
        title: data.title,
        save: data.save
      }
    });
    return this.dialogRef.afterClosed().pipe(
      tap(() => (this._dialogId = 0)),
      filter((value) => !!value)
    );
  }

  public navRefreshDialog(data: NavRefreshDialogConfig): Observable<any> {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this._dialogId = 3;
    this.dialogRef = this.open(NavRefreshDialog, {data});
    return this.dialogRef.afterClosed().pipe(
      tap(() => (this._dialogId = 0)),
      filter((value) => !!value)
    );
  }

  get dialogId(): number {
    return this._dialogId;
  }

  public close(): void {
    if (!!this.dialogRef) {
      this._dialogId = 0;
      super.close();
    }
  }

  public getEmitService() {
    return this.dialogRef.componentInstance.emitService;
  }
}
