import {Directive, OnDestroy, OnInit} from '@angular/core';
import {
  ListResponse,
  ListResponseNew,
  PageResponse,
  PaginatorNew,
  SelectionButton,
  TableColumn,
  TableFilter,
  TableOrder,
  NavigationService,
  isSameObject, defaultPage, ListAmountItem, utils
} from '../../../global';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import {SortDirection} from '@angular/material/sort';
import {ActivatedRoute} from '@angular/router';

@Directive()
export abstract class PageRouterTableContainer implements OnInit, OnDestroy {
  private _filters: any = null;
  private _filterIsOpen = false;
  private _search = '';
  private _multiSearch: Array<string>;
  private _order: TableOrder = null;
  private _page: PaginatorNew = defaultPage;
  private _selection: Array<any> = [];
  public filterConfig: Array<TableFilter> = null;
  public selectionAttributes: Array<string> = ['id'];
  public tableConfig: Array<TableColumn> = null;
  public selectionButtons: Array<SelectionButton> = null;
  public amounts$ = new BehaviorSubject<Array<ListAmountItem>>(null);
  public sumAmount$ = new BehaviorSubject<ListAmountItem>(null);
  public showAmounts = true;

  public data$: Observable<Array<any>>;
  private readonly refresh$ = new BehaviorSubject<boolean>(false);
  protected readonly destroy$ = new Subject();

  protected readonly _isLoading$ = new BehaviorSubject<boolean>(true);
  private readonly _filters$ = new BehaviorSubject<any>(null);
  private readonly _filterIsOpen$ = new BehaviorSubject<boolean>(false);
  private readonly _search$ = new BehaviorSubject<string>(null);
  private readonly _multiSearch$ = new BehaviorSubject<Array<string>>(null);
  private readonly _order$ = new BehaviorSubject<TableOrder>(null);
  private readonly _page$ = new BehaviorSubject<PaginatorNew>(this._page);
  private readonly _selection$ = new BehaviorSubject<Array<any>>(null);

  public readonly isLoading$ = this._isLoading$.asObservable();
  public readonly filters$ = this._filters$
    .asObservable()
    .pipe(debounceTime(250));
  public readonly search$ = this._search$.asObservable();
  public readonly multiSearch$ = this._multiSearch$.asObservable();
  public readonly filterIsOpen$ = this._filterIsOpen$.asObservable();
  public readonly order$ = this._order$.asObservable();
  public readonly page$ = this._page$.asObservable();
  public readonly selection$ = this._selection$.asObservable();
  public minAttributeCount = 3;

  protected constructor(
    private readonly navigation: NavigationService,
    private readonly route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    combineLatest([this.route.queryParams, this.defaultParameters()])
      .pipe(
        debounceTime(250),
        filter(([params, storeQueryParams], index) => {
          const attributes = Object.keys(params) || [];
          if (attributes.length <= this.minAttributeCount && (!!storeQueryParams && index === 0)) {
            if (storeQueryParams.search) {
              this.setSearch(storeQueryParams.search);
            }

            let multiSearch: Array<string> = [];
            if(storeQueryParams.multiSearch){
              multiSearch = (storeQueryParams.multiSearch || '').split(';').filter(item => !!item);
              this.setMultiSearch(multiSearch);
              delete storeQueryParams.multiSearch;
            }
            this.refreshQueryParams(storeQueryParams, multiSearch);
            return false;
          }
          let isChanged = false;
          const search = params['search'] || '';
          if (search !== this.search) {
            this.setSearch(search);
            isChanged = true;
          }

          const multiSearch = !params['multiSearch'] ? [] : params['multiSearch'].split(';');

          if(!!multiSearch){
            this.setMultiSearch(multiSearch);
            isChanged = true;
          }
          const orderBy = params['orderBy'];
          const orderDirection = params['orderDirection'];
          const newOrder =
            !orderBy ||
            !orderDirection ||
            !['asc', 'desc'].includes(orderDirection)
              ? null
              : {
                order: orderDirection as SortDirection,
                attribute: orderBy
              };
          if (!isSameObject(newOrder, this.order)) {
            this.setOrder(newOrder);
            isChanged = true;
          }
          const page = +params['page'] || (this.page?.page ?? 1);
          const size = +params['size'] || (this.page?.size ?? 10);
          const newPage = {
            page,
            size,
            fullLength: +this.page.fullLength || 0
          };
          if (!isSameObject(newPage, this.page)) {
            if (newPage.size !== this.page?.size || newPage.page !== this.page?.page) {
              isChanged = true;
            }
            this.setPage(newPage);
          }

          const newFilters = attributes
            .filter(
              (attribute) =>
                ![
                  'page',
                  'size',
                  'search',
                  'multiSearch',
                  'orderBy',
                  'orderDirection'
                ].includes(attribute)
            )
            .reduce(
              (object, attribute) => ({
                ...object,
                ...(!!params[attribute]
                  ? {
                  [attribute]: (!Array.isArray(params[attribute]) ?
                      utils.isNumber(params[attribute])? +params[attribute]: params[attribute] :
                      params[attribute].map(value => utils.isNumber(value)? +value: value))
                }
                  : {})
              }),
              {}
            );

          if (!isSameObject(newFilters, this.filters)) {
            this.setFilters(newFilters);
            isChanged = true;
          }
          return isChanged;
        }),
        tap(() => this.refreshCurrentData()),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.refresh());

    this.data$ = this.refresh$.pipe(
      filter(refresh => refresh),
      tap(() => this._isLoading$.next(true)),
      tap(() => this.amounts$.next(null)),
      debounceTime(250),
      switchMap(() => this.loadData(
        [...this.multiSearch, this.search].join(';'),
        this.page,
        this.order,
        utils.convertArraysInObjectToSting(this.filters))
      ),
      filter((value) => !!value),
      map(
        (
          data: PageResponse<any> | ListResponse<any> | ListResponseNew<any>
        ) => {
          const keys = (Object.keys(data || {}) || []).filter(
            (key) => key !== 'paginator'
          );
          const paginator: any = data?.paginator;
          if (paginator) {
            const receivedPaginator: PaginatorNew = {
              page: +(paginator?.page || paginator?.actualPage || 1),
              size: +(paginator?.pageSize || paginator?.size || 10),
              fullLength: +(paginator?.fullLength || 0)
            };
            const currentPaginator = {
              ...this.page,
              fullLength: +(paginator?.fullLength || 0)
            };
            this.setPage(receivedPaginator);
            if (!isSameObject(receivedPaginator, currentPaginator)) {
              this.refreshQueryParams();
            }
          }
          const items = !!keys.length ? data[keys[0]] : [];
          if (!!data['amounts'] && (items?.length ?? 0) > 0) {
            this.amounts$.next(data['amounts']);
            if (data['allAmount']) {
              this.sumAmount$.next(data['allAmount']);
            }
          }
          if (!data['items'] && !data['documents'] && !!data.paginator && this._isLoading$.getValue()) {
            this.refreshCurrentData();
            this._isLoading$.next(false);
            return [];
          }
          this._isLoading$.next(false);
          return items;
        }
      ),
      switchMap(items => this.modifyData(items))
    );
  }

  public modifyData(items: Array<any>): Observable<Array<any>> {
    return of(items);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  protected refresh(): void {
    this.refresh$.next(true);
  }

  public get filterIsOpen(): boolean {
    return this._filterIsOpen;
  }

  public openFilter() {
    this._filterIsOpen = !this._filterIsOpen;
    this._filterIsOpen$.next(this._filterIsOpen);
  }

  public get filters(): any {
    return this._filters || {};
  }

  public set filters(value: any) {
    if (!utils.isSameObject(value, this.filters)) {
      this.setPage({...this._page, page: 1});
      this.refreshQueryParams(value);
    }
  }

  protected setFilters(value) {
    this._filters = value || {};
    this._filters$.next(value);
  }

  public get search(): string {
    return this._search || '';
  }

  public set search(value: string) {
    if (utils.isString(value) && this.search !== value) {
      this.setPage({...this._page, page: 1});
      this.refreshQueryParams({search: value || '', ...this.filters});
    }
  }

  public setSearch(value: string) {
    this._search = value || null;
    this._search$.next(this._search);
  }

  public get multiSearch(): Array<string> {
    return this._multiSearch || [];
  }

  public set multiSearch(value: any) {
    if (!utils.isSameArray(this.multiSearch, value)) {
      this.setPage({...this._page, page: 1});
      this.refreshQueryParams({search: '', ...this.filters}, value);
    }
  }

  public setMultiSearch(value: Array<string>) {
    if (!utils.isSameArray(this.multiSearch, value)) {
      this._multiSearch = value || null;
      this._multiSearch$.next(this._multiSearch);
    }
  }

  public deletedMultiSearch(value: any){
    this.setPage({...this._page, page: 1});
    this.refreshQueryParams(this.filters, value);
  }

  public get order(): TableOrder {
    return this._order;
  }

  public set order(value: TableOrder) {
    const object =
      !!value && !!value.attribute && !!value.order
        ? {orderBy: value.attribute, orderDirection: value.order}
        : {
          orderBy: null,
          orderDirection: null
        };
    this.refreshQueryParams({...object, ...this.filters});
  }

  public setOrder(value: TableOrder) {
    this._order = !!value && !!value.attribute && !!value.order ? value : null;
    this._order$.next(this._order);
  }

  public get page(): PaginatorNew {
    return this._page;
  }

  public set page(value: PaginatorNew) {
    this.refreshQueryParams({
      page: value.page,
      size: value.size,
      ...this.filters
    });
  }

  protected setPage(page: PaginatorNew) {
    if (!utils.isSameObject(page, this.page)) {
      this._page = page;
      this._page$.next(page);
    }
  }

  public get isSelected(): boolean {
    return !!(this._selection || []).length;
  }

  public get selection(): Array<any> {
    return this._selection || [];
  }

  public set selection(value: Array<any>) {
    this._selection$.next(value);
    this._selection = value || [];
  }

  private refreshQueryParams(filters: any = null, multiSearch?: Array<string>): void {
    const page = this.page;
    const order = this.order;

    const multiSearchParams = !!multiSearch ? multiSearch : this.multiSearch;

    this.navigation.refreshQueryParams({
      search: this.search || null,
      multiSearch: multiSearchParams ? multiSearchParams.join(';') : null,
      orderBy: order?.attribute || null,
      orderDirection: order?.order || null,
      page: page.page || null,
      size: page.size || null,
      ...(!!filters ? filters : this.filters)
    });
  }

  protected resetFilters(): void {
    this._filters$.next((this._filters = {}));
    this._search$.next((this._search = ''));
    this._page$.next(
      (this._page = {page: 1, size: this._page?.size || 10, fullLength: 0})
    );
    this._selection = [];
    this.order = null;
  }

  public refreshData(
    search: string,
    page: PaginatorNew,
    order: TableOrder,
    filters: any
  ): boolean {
    return true;
  }

  public refreshCurrentData(): void {
    this.refreshData([...this.multiSearch, this.search].join(';'),
      this.page,
      this.order,
      utils.convertArraysInObjectToSting(this.filters));
  }

  protected defaultParameters(): Observable<any> {
    return of(null);
  }

  abstract loadData(
    search: string,
    page: PaginatorNew,
    order: TableOrder,
    filters: any
  ): Observable<any>;
}
