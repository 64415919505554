<slm-dialog-container
  [title]="data?.title"
  [buttons]="data?.buttons || buttons"
  class="w-max-100p block"
  (fireEvent)="buttonEvent($event.event)"
>
  <h5 class="txt-start-i block">
    {{ data?.message | translate: { count: (data?.items || []).length } }}
  </h5>
  <slm-simple-table
    cdk-scrollable
    class="dialog-height-small block w-100 mb-3 overflow-y-auto h-max-inherit"
    [config]="data?.tableConfig"
    [items]="data?.items"
  ></slm-simple-table>
  <slm-message
    type="warning"
    [message]="data?.warning || 'LBL_MESSAGES.DELETE_WARNING'"
    class="w-100 block"
  ></slm-message>
</slm-dialog-container>
