export interface NotificationMenu {
  data: any;
  checked: boolean;
  text: string;
  type: 'system' | 'other';
  date: string;
  id: number;
}

export interface SystemNotification {
  id: number;
  text: string;
  creationDate: string;
  effectiveFrom: string;
  effectiveTo: string;
  isRead: boolean;
  type: 'text' | 'html';
}
