<slm-dialog-container
  [title]="'INVOICE.NAV.REGISTER_NAV_REFRESH'"
  [buttons]="buttons"
  class="w-max-small-dialog block"
  (fireEvent)="buttonEvent($event.event)"
>
  <slm-message *ngIf="data.showMessage"
               message="INVOICE.NAV.DATA_AFTER_SYNC_MESSAGE"
               class="block mb-2"
               type="info"></slm-message>

  <form *ngIf="form" [formGroup]="form">
    <slm-select-input label="INVOICE.NAV.DATE_TYPE"
                      [control]="form.get('dateType')"
                      [errors]="error"
                      testId="dateTypeInput"
                      [options]="dateTypeSelectOptions"></slm-select-input>

    <slm-date-range-input label="INVOICE.NAV.SYNC_RANGE"
                          [errorsStart]="error"
                          [errorsEnd]="error"
                          [max]="maxDate"
                          firstTestId="syncRangeStart"
                          secondTestId="syncRangeEnd"
                          [groupControl]="form.get('date')"></slm-date-range-input>
  </form>
</slm-dialog-container>
