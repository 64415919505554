import {Injectable} from '@angular/core';
import {GeneralService} from '../services';
import {CurrencyEnum} from '../enums';
import {map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {globalActions, globalSelectors} from '../+states';
import {Observable} from 'rxjs';
import {SelectOption} from '../entities';

@Injectable({
  providedIn: 'root'
})
export class GeneralSandbox {
  constructor(private readonly generalService: GeneralService,
              private readonly store: Store) {
  }

  readonly getCountries = (page: number, size: number, search: string) =>
    this.generalService.getCountries(page, size, search);

  readonly getSelectFormattedCountries =
    (page: number = null, size: number = null, search: string = ''): Observable<Array<SelectOption>> =>
    this.generalService.getCountries(page, size, search).pipe(
      map((list) => (list ?? []).map((country) => ({
          label: country.name,
          value: +country.id,
          data: country.code
        }))
      ));

  readonly getInvoiceItemUnits = () =>
    this.generalService.getInvoiceItemUnits().pipe(
      map(response => (response || []).map((suffix) => ({
          value: suffix.value || suffix.label,
          label: suffix.label
        }))
      ));

  readonly vatFrequency$ = this.store.select(globalSelectors.vatFrequency());

  readonly getExchangeRate = (currency: CurrencyEnum, date: Date, base = false) =>
    this.generalService.getExchangeRate(currency, date, base);

  readonly getRoles = () => this.generalService.getRoles();

  readonly getProformas = (date, partnerId, type) =>
    this.generalService.getProformas(date, partnerId, type);

  readonly getSubscriptions = (all?: boolean) => this.generalService.getSubscriptions(all);

  readonly getHelp = (language: string, page: string) =>
    this.generalService.getGeneralInformation(language, (page ?? '').split('?')[0]);

  readonly getBanks = (includePartnerBanks: boolean = false) => this.generalService.getBankAccounts(includePartnerBanks);

  readonly sendReport = (subject: string, description: string, files: Array<File>) =>
    this.generalService.sendReport(subject, description, files);

  readonly navMenuLocations$ = this.store.select(globalSelectors.getNavLocations());

  readonly autoInvoiceRegistrationNumber$ = this.store.select(globalSelectors.autoRegistrationNumber());

  readonly defaultAccrual$ = this.store.select(globalSelectors.defaultAccrual());

  readonly sendError = (message) => this.generalService.sendError(message);

  readonly useOnTransactionStatus$ = this.store.select(globalSelectors.useOnTransactionStatus());

  public readonly clearUseOnTransactionStatus = () => this.store.dispatch(globalActions.clearUseOnTransactionStatus());

  readonly externalBillingSystem$ = this.store.select(globalSelectors.externalBillingSystem());

  readonly externalBillingSystemError$ = this.store.select(globalSelectors.externalBillingSystemError());

  readonly fileUploadMode$ = this.store.select(globalSelectors.fileUploadMode());

  readonly externalSystemId$ = this.store.select(globalSelectors.externalSystemId());

  readonly getStorageUsage$ = this.store.select(globalSelectors.getStorageUsage());

  readonly useDivision$ = this.store.select(globalSelectors.useDivision());

  readonly useBank$ = this.store.select(globalSelectors.useBank());
}
