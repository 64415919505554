import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Currency, defaultCurrency, Icons} from '../../../../global';
import { InputAbstract } from '../base/input.abstract';

@Component({
  selector: 'slm-amount-input',
  templateUrl: './amount-input.component.html',
  styles: [`
    :host {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
    }
  `]
})
export class AmountInputComponent extends InputAbstract {
  @Input() public autoFocus = false;
  @Input() public buttonIcon = true;
  @Input() public prefixLabel = '';
  @Input() public allowNegativeNumber = true;
  @Input() public prefixIcon: Icons | string = null;
  @Input() public enterEnabled = false;
  public decimals = 'separator.0';
  private hasDecimal = false;

  @Input('decimals') public set setDecimals(decimals: number) {
    this.hasDecimal = true;
    this.decimals = `separator.${decimals ?? 0}`;
  }

  @Input('currency') public set setCurrency(currency: Currency) {
    if (!this.hasDecimal) {
      this.decimals = `separator.${currency?.precision ?? defaultCurrency.precision}`;
    }
    this.suffixLabel = currency?.prefix ?? '';
  }

  @Input() public suffixIcon: Icons = null;
  @Input() public suffixLabel = '';
  @Input() public suffixIconLabel = '';
  @Output() public commaPressed = new EventEmitter<boolean>();

  public clear(): void {
    if (this.control) {
      this.control.setValue('');
    }
    this.onClick('clear');
  }

  public setComma(event) {
    event.target.value = `${event.target.value}.`;
    this.commaPressed.emit(true);
  }
}
