import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {TableCounterIcon} from '../../../../../../global';

@Component({
  selector: 'slm-table-counter-icon',
  templateUrl: './table-counter-icon.component.html',
  styles: [
    `
           :host {
             display: block;
           }
         `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCounterIconComponent {
  @Input() data: TableCounterIcon = null;
  @Input() tooltip = '';
}
