import {Icon, IconColors, Icons, InputTypeEnum, RightsEnum, TableAlign, TableColumnType} from '../enums';
import {ButtonConfig, MenuItem, RightsConfig} from './general.entity';
import { SortDirection } from '@angular/material/sort';
import {Currency} from './currency.entity';
import {ValidatorFn} from '@angular/forms';

export interface TableColumn {
  // Name of the attribute in the returned object
  attributeName: string;
  label: string;
  columnType: TableColumnType;
  menuItems?: Array<MenuItem>;
  dateFormat?: string;
  align?: TableAlign;
  tooltip?: string  | ((any) => string);
  valueTooltip?: boolean;
  sortDisabled?: boolean;
  transpile?: (any) => any;
  notClickable?: boolean | ((any) => boolean);
  maxWidth?: string;
  orderName?: string;
  rights?: Array<RightsEnum> | RightsConfig | string;
  buttonConfig?: ButtonConfig;
  sortStart?: SortDirection | any;
  color?: string | ((any) => string);
  settingId?: string;
  viewSettingOptions?: Array<MenuItem>;
  icon?: string;
  hideIfDisabled?: boolean;
  checkedAndDisabled?: ((any) => boolean);
}

export interface TableIconText {
  color: string;
  icon: Icons;
  label: string;
  notification?: any;
}

export interface TableSelectionButton {
  value: any;
  selection: any;
}

export interface TableUser {
  image: string;
  firstName: string;
  lastName: string;
}

export interface TableTextStatus {
  text: string;
  enableTextTooltip?: boolean;
  enableTooltip?: string;
  color?: IconColors | 'string';
  statusNumber?: number;
  statusIcon?: Icons;
  statusTooltip?: string;
  statusAlign: 'start' | 'end';
}

export interface TableText {
  label: string;
  data?: any;
}

export interface TableTextButton extends TableText{
  icon: Icon | string;
  iconColor: IconColors | string;
  disable: boolean;
  iconLabel: string;
}

export interface TableEditableText {
  data: string;
  disableEditable?: boolean | ((any) => boolean);
  subLabel?: string | ((any) => { label: string; color: string; params: any });
  validator?: Array<ValidatorFn>;
  currency?: Currency;
  inputType: InputTypeEnum | 'long-text';
}

export interface TableCounterText extends TableText {
  counter: number;
  color: string;
}

export interface TableDate {
  date: Date;
  until?: Date;
}

export interface TableOrder {
  attribute: string;
  order: SortDirection;
}

export interface TableCounterIcon extends TableText {
  counter: number;
  icon: Icon | string;
  iconColor: IconColors | string;
}
