<ng-container *ngIf="!!menuItem">
  <a
    matRipple
    [attr.data-test-id]="menuItem.testId"
    *ngIf="!menuItem?.submenu; else submenu"
    class="side-nav-item side-nav-item--link flex flex-start flex-items-center"
    [class.side-nav-item--active]="
      openedMenu === menuItem.name || selectedRoute === menuItem.route
    "
    [routerLink]="menuItem.route"
    [replaceUrl]="true"
    [matTooltip]="(desktopClosed ? menuItem.name : null) | translate"
    matTooltipPosition="right"
  >
    <slm-svg-icon
      *ngIf="menuItem.icon"
      [icon]="menuItem.icon"
      class="side-nav-item__icon"
    ></slm-svg-icon>
    <h5 *ngIf="menuItem.name" class="side-nav-item__name">
      {{ menuItem.name | translate }}
    </h5>
  </a>
  <ng-container
    *ngIf="!!menuItem?.data?.progressBar"
  >
    <div
      class="progressbar-container"
      [class.progressbar-container--active]="
        openedMenu === menuItem.name || selectedRoute === menuItem.route
      "
    >
      <mat-progress-bar
        [value]="(menuItem.data.progressBar.used / menuItem.data.progressBar.total) * 100 "
        [color]="(menuItem.data.progressBar.used / menuItem.data.progressBar.total) * 100 < 90 ? 'accent' : 'warn'"
        mode="determinate"
      ></mat-progress-bar>
      <h5
        *ngIf="!desktopClosed"
        class="progressbar-container__description">
        {{'FILE_MANAGER.MENU_USED_DESCRIPTION' |
        translate: {
          total: menuItem.data.progressBar.total,
          used: menuItem.data?.progressBar.used
        }
        }}
      </h5>
    </div>
  </ng-container>
</ng-container>

<ng-template #submenu>
  <button
    matRipple
    class="side-nav-item side-nav-item--dropdown  flex flex-start flex-items-center"
    [attr.data-test-id]="menuItem.testId"
    [class.side-nav-item--active]="
      openedMenu === menuItem.name || selectedRoute === menuItem.route
    "
    [matTooltip]="(desktopClosed ? menuItem.name : null) | translate"
    matTooltipPosition="right"
    (click)="openMenu()"
  >
    <slm-svg-icon
      *ngIf="menuItem.icon"
      [icon]="menuItem.icon"
      class="side-nav-item__icon"
    ></slm-svg-icon>
    <h5 *ngIf="menuItem.name" class="side-nav-item__name side-nav-item__name--dropdown">
      {{ menuItem.name | translate }}
    </h5>
    <slm-svg-icon
      *ngIf="menuItem.submenu"
      icon="arrow_down"
      class="side-nav-item__button"
    ></slm-svg-icon>
  </button>
  <div @dropdownFade *ngIf="menuItem.name === openedMenu">
    <ng-container
      *ngFor="let submenu of menuItem.submenu; trackBy: trackByLabel"
    >
      <a
        *slmAccess="submenu.rights"
        matRipple
        [attr.data-test-id]="submenu.testId"
        class="side-nav-item side-nav-item--submenu side-nav-item--dropdown flex flex-end flex-items-center"
        [class.side-nav-item--submenu--closed]="desktopClosed"
        [routerLink]="submenu.route"
        [preserveFragment]="true"
        [class.side-nav-item--active]="selectedRoute === submenu.route"
        (click)="clickedRoute(submenu.route)"
        [matTooltip]="(desktopClosed ? submenu.name : null) | translate"
        matTooltipPosition="right"
      >
        <slm-svg-icon
          *ngIf="submenu.icon && desktopClosed"
          [icon]="submenu.icon"
          size="20px"
          class="side-nav-item__icon"
        ></slm-svg-icon>
        <h5
          *ngIf="submenu.name && !desktopClosed"
          class="side-nav-item__name"
        >
          {{ submenu.name | translate }}
        </h5>
        <slm-svg-icon
          *ngIf="submenu.showUpgradeIcon"
          [icon]="upgradeIcon"
          size="20px"
          class="side-nav-item__upgrade-icon"
          color="#b1a800"
          [matTooltip]="'RESTRICTED_USE' | translate"
        ></slm-svg-icon>
      </a>
    </ng-container>
  </div>
</ng-template>
