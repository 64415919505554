import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  ButtonConfig,
  InvoicePayments,
  InvoiceTableItem,
  ListResponseNew,
  buttonsStaticConfig,
  inputErrors,
  simpleTablePayout, RoutesEnum, PaymentFormConfig, getPaymentLabel, InvoicePaymentFunctions
} from '../../../global';
import {Observable} from 'rxjs';
import {dialogInvoiceTable} from '../../../invoices/static/invoice-data-sheet-config';
import {UntypedFormArray} from '@angular/forms';
import {map, shareReplay} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {PayoutTemplateContainer} from '../../containers';

export interface InvoicePaymentDialogConfig {
  invoice: InvoiceTableItem;
  title: string;
  payments$: Observable<ListResponseNew<InvoicePayments>>;
  save: ((paymentData) => Observable<any>);
}

@Component({
  selector: 'slm-invoice-payment-dialog',
  templateUrl: './invoice-payment-dialog.component.html'
})
export class InvoicePaymentDialogComponent implements OnInit {
  public readonly errors = inputErrors;
  public readonly tableConfig = [...dialogInvoiceTable];
  public readonly paymentTableConfig = [
    simpleTablePayout[0],
    simpleTablePayout[1],
    simpleTablePayout[2],
    simpleTablePayout[3]
  ];
  public readonly buttons: Array<ButtonConfig> = [
    buttonsStaticConfig.cancel,
    buttonsStaticConfig.saveData
  ];
  public formArray: UntypedFormArray;
  public payments$: Observable<Array<any>>;
  public paymentConfig$: Observable<PaymentFormConfig>;

  @ViewChild('payoutTemplate', {static: false})
  payoutTemplateContainer: PayoutTemplateContainer;

  constructor(
    private readonly dialogRef: MatDialogRef<InvoicePaymentDialogComponent>,
    private readonly translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: InvoicePaymentDialogConfig
  ) {
  }

  ngOnInit() {
    const isOutgoing = this.data.title.includes('OUTGOINGS');
    const link = !isOutgoing ? RoutesEnum.INCOMING_INVOICE_DATA_SHEET : RoutesEnum.OUTGOINGS_INVOICE_DATA_SHEET;
    this.payments$ = this.data.payments$.pipe(
      map((response) => response?.items || []),
      map(items => items.map(item => ({
        ...item,
        type: getPaymentLabel(item, item.type === 'cashDiscount' ?
          this.translateService.instant('INVOICE.PAYMENT_TYPE.DISCOUNT') + ' (' + item.percent + '%)'
          : link)
      }))),
      shareReplay({bufferSize: 1, refCount: true})
    );
    this.paymentConfig$ = this.payments$.pipe(
      map((items) => ({
        paidValue: items.reduce((amount, payment) => +payment.grossValue + amount, 0),
        partnerId: +this.data?.invoice?.partner?.id,
        date: this.data?.invoice?.paymentDeadline,
        documentId: +this.data?.invoice?.id,
        grossValue: +this.data?.invoice?.grossValue || 0,
        currency: this.data.invoice.currency,
        type: isOutgoing? 'outgoing': 'incoming',
        paymentType: this.data.invoice?.paymentType,
        savedInTransaction: this.data.invoice?.savedInTransaction
      }))
    );
    this.tableConfig.splice(2, 1);
  }

  public setPaymentForm(array: UntypedFormArray): void {
    this.formArray = array;
  }

  public buttonEvent(event: string) {
    if (event === 'cancel') {
      this.dialogRef.close();
      return;
    }
    if (!this.formArray) {
      return;
    }

    if (this.formArray.invalid && this.payoutTemplateContainer) {
      this.payoutTemplateContainer?.markAllAsTouched();
      return;
    }

    const paymentData = InvoicePaymentFunctions.getPaymentData(this.formArray);
    if(!paymentData){
      return;
    }

    this.dialogRef.disableClose = true;
    this.buttons[1].isLoading = true;
    this.data.save(paymentData).pipe().subscribe({
      error: () => {
        this.dialogRef.disableClose = false;
        this.buttons[1].isLoading = false;
      },
      next: () => {
        this.dialogRef.disableClose = false;
        this.buttons[1].isLoading = false;
        this.dialogRef.close(true);
      }
    });
  }
}
