import {Component, Input} from '@angular/core';
import {Icons} from '../../../../global';
import {InputAbstract} from '../base/input.abstract';

@Component({
  selector: 'slm-date-input',
  templateUrl: './date-input.component.html',
  styles: [`
    :host {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
    }
  `]
})
export class DateInputComponent extends InputAbstract {
  @Input() public min: Date;
  @Input() public max: Date;
  @Input() public prefixIcon: Icons = null;
  @Input() public suffixIconLabel = '';
}
