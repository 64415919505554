import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlingService } from './error-handling.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InternalMessage } from '../entities';

export type MessageType = 'documents' | 'file-manager/file';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandler: ErrorHandlingService
  ) {}

  public sendMessage(
    id: string | number,
    message: string,
    messageType: MessageType = 'documents'
  ): Observable<InternalMessage> {
    return this.http
      .post(`/${messageType}/${id}/messages`, { message })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public updateMessage(
    id: string | number,
    message: string,
    messageType: MessageType = 'documents'
  ): Observable<InternalMessage> {
    return this.http
      .put(`/${messageType}/messages`, { message, id })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public deleteMessage(
    id: number | string,
    messageType: MessageType = 'documents'
  ): Observable<InternalMessage> {
    return this.http
      .delete(`/${messageType}/messages/${id}`)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }
}
