import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TableUser, TableAlign } from '../../../../../../global';

@Component({
  selector: 'slm-table-user',
  template: `
    <div
      *ngIf="data; else invalid"
      [matTooltip]="tooltip | translate"
      class="w-min-content flex-items-center flex flex-items-center flex-{{align || 'center'}}"
    >
      <slm-monogram
        *ngIf="data"
        [user]="data"
        class="mr-1"
        [size]="2"
      ></slm-monogram>
      <h5 class="txt-start-i m-0 pl-2 pr-2 w-max-content w-max-200 txt-no-wrap">
        {{ data | name }}
      </h5>
    </div>
    <ng-template #invalid
      ><span class="w-100 block txt-center-i">--</span></ng-template
    >
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableUserComponent {
  @Input() data: TableUser = null;
  @Input() tooltip: string = null;
  @Input() align: TableAlign = TableAlign.CENTER;
}
