import {ButtonConfig, Icon, RightsEnum, RoutesEnum, utils} from '../../../modules/global';

const newInvoiceBtn: ButtonConfig = {
  label: 'INVOICE.OUTGOINGS.CREATE_INVOICE',
  icon: Icon.DESCRIPTION,
  buttonStyle: 'light',
  noWrap: true,
  url: RoutesEnum.OUTGOINGS_INVOICE_CREATE,
  rights: [RightsEnum.BILLING_READ]
};

const documentUploadBtn: ButtonConfig = {
  label: 'LBL_UPLOAD_FILL.LIST.NEW',
  icon: Icon.ADD_CIRCLE,
  buttonStyle: 'light',
  rights: [RightsEnum.DOCUMENT_UPLOADING_READ],
  noWrap: true,
  testId: 'newOutgoing',
  action: 'new_upload'
};

const currentDate = new Date();

const routeDates = {
  completionDateFrom: utils.dateToServerDate(new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
    0,
    0,
    0,
    0
  )),
  completionDateTo: utils.dateToServerDate(new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
    0,
    0,
    0,
    0
  ))
};

export const dashboardConfig = {
  primaryButton: documentUploadBtn,
  secondaryButton: newInvoiceBtn,
  routeDates
};
