<pdf-viewer
  *ngIf="!showNoImage"
  [src]="src"
  [original-size]="false"
  [fit-to-page]="true"
  [external-link-target]="'blank'"
  [autoresize]="true"
  style="height: 500px"
  (error)="error()"
></pdf-viewer>

<slm-no-image
  *ngIf="showNoImage"
></slm-no-image>
