<div class="pos-relative mt-3">
  <span
    *ngIf="label"
    class="txt-medium font-size-label top-0 z-index-2 pr-1 pl-1 ml-1_5 mt--1 pos-absolute bg-color-white color-gray-label"
    >{{ label | translate }}</span
  >
  <div class="border radius-big overflow-hidden">
    <div id="toolbar">
      <!-- Add buttons as you would before -->

      <mat-select
        *ngIf="!!options?.length"
        class="ql-custom border pl-1_5 pt-1 pb-1 radius border-color-accent border-2 color-accent"
        [formControl]="selectControl"
        [placeholder]="'EDITOR.VARIABLE_PARAMS' | translate"
      >
        <mat-option *ngFor="let option of options" [value]="option.value">{{
          option.key | translate
        }}</mat-option>
      </mat-select>

      <span class="ql-formats">
        <select class="ql-header">
          <!-- Note a missing, thus falsy value, is used to reset to default -->
          <option selected></option>
          <option value="1"></option>
          <option value="2"></option>
          <option value="3"></option>
          <option value="4"></option>
          <option value="5"></option>
          <option value="6"></option>
        </select>
        <button
          class="ql-bold"
          matRipple
          [matTooltip]="'EDITOR.BOLD' | translate"
        ></button>
        <button
          class="ql-italic"
          matRipple
          [matTooltip]="'EDITOR.ITALIC' | translate"
        ></button>
        <button
          class="ql-underline"
          matRipple
          [matTooltip]="'EDITOR.UNDERLINE' | translate"
        ></button>
        <button
          class="ql-strike"
          matRipple
          [matTooltip]="'EDITOR.STRIKE' | translate"
        ></button>
        <button
          class="ql-link"
          matRipple
          [matTooltip]="'EDITOR.LINK' | translate"
        ></button>
      </span>
      <span class="ql-formats">
        <button
          class="ql-list"
          value="ordered"
          matRipple
          [matTooltip]="'EDITOR.LIST_ORDERED' | translate"
        ></button>
        <button
          class="ql-list"
          value="bullet"
          matRipple
          [matTooltip]="'EDITOR.LIST_BULLET' | translate"
        ></button>
        <span
          class="ql-custom-button"
          [matTooltip]="'EDITOR.ALIGN' | translate"
        >
          <select class="ql-align">
            <option selected=""></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>
          </select>
        </span>
        <button
          class="ql-indent"
          value="-1"
          matRipple
          [matTooltip]="'EDITOR.INDENT_LEFT' | translate"
        ></button>
        <button
          class="ql-indent"
          value="+1"
          matRipple
          [matTooltip]="'EDITOR.INDENT_RIGHT' | translate"
        ></button>
      </span>
    </div>
    <quill-editor
      [modules]="modules"
      [placeholder]="placeholder | translate"
      [style.height]="height"
      [class.border-color-error]="control?.invalid"
      [class.bg-color-error-input]="control?.invalid"
      [class.border-2]="control?.invalid"
      [maxLength]="maxLength"
      [required]="true"
      [formControl]="control"
      (onSelectionChanged)="editorChanged($event)"
      format="html"
      [attr.data-test-id]="testId"
      class="block pos-relative w-100"
      #quillEditor
    ></quill-editor>
  </div>
  <span
    class="pt-1_5 block pl-1_5 txt-medium color-error font-size-small"
    *ngIf="control && (control.dirty || control.touched) && control.invalid"
    >{{ getError() | translate }}</span
  >
</div>
