import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CompanySandbox} from '../../../modules/global';
import {Observable} from 'rxjs';
import {filter, map, shareReplay} from 'rxjs/operators';

@Component({
  selector: 'slm-component-pending',
  templateUrl: './company-pending.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyPendingContainer implements OnInit {
  public statuses$: Observable<Array<string>>;
  public currentStep = 0;
  public description: string;
  public companyName$: Observable<string>;

  constructor(private readonly companySandbox: CompanySandbox) {
  }

  ngOnInit() {
    this.statuses$ = this.companySandbox.getCompanyActivation().pipe(
      filter((companies) => !!companies?.items?.length),
      map((companies) => companies?.items ?? [] ?? []),
      map((steps) => {
        this.currentStep = steps.findIndex((step) => !step.checked);
        this.description = steps[this.currentStep]?.description || '';
        return steps.map((step) => step.text);
      }),
      shareReplay({
        bufferSize: 1,
        refCount: true
      })
    );

    this.companyName$ = this.companySandbox.activeCompany$.pipe(
      map(company => company?.partnerName ? company.partnerName : '')
    );
  }
}
