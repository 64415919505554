<div class="image-list__container">
  <div class="image-list__no-image">
    <slm-svg-icon
      class="image-list__no-image__image fill-grey"
      icon="image"
      size="96px"
    ></slm-svg-icon>
    <h4 class="image-list__no-image__title">
      {{ placeholderTitle | translate }}
    </h4>
    <h5 class="image-list__no-image__description">
      {{ placeholderDescription | translate }}
    </h5>
  </div>
</div>
