/**
 * Converts the enumeration to an array
 *
 * @param {string} enumeration
 * @return {Array<string |number | object | function>}
 */
import {NotificationEnum} from '../enums';
import {ServerResponseMessage} from '../entities';
import {utils} from './converter.functions';
import {JwtHelperService} from '@auth0/angular-jwt';
import {AuthSandbox} from '../sandboxes';
import {externalSystemFormats} from "../static/externalSoftwares.static";

export const enumToArray = (enumeration): Array<any> =>
  Object.keys(enumeration).map((key) => enumeration[key]);

const downloadBlob = (title: string, blob: Blob, type: 'pdf' | 'png' | 'jpg' | 'jpeg' | 'txt' | 'other') => {
  const downloadURL = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadURL;
  if (type !== 'other') {
    link.download = `${title || ('SLM_' + new Date().getTime())}.${
      type
    }`;
  } else {
    link.download = title;
  }
  link.target = '_blank';
  link.dispatchEvent(new MouseEvent('click'));
 // link.click();
};

export const downloadPdfAsBlob = (blob: Blob, title) =>
  downloadBlob(title, blob, 'pdf');

export const downloadPdf = (blob, title) =>
  downloadBlob(title, new Blob([blob], {type: 'application/pdf'}), 'pdf');

export const downloadOther = (blob, title, externalSystem = '') =>
  downloadBlob(title, new Blob([blob]), 'other');

export const downloadImage = (image, title, type = 'png') =>
  downloadBlob(title, new Blob([image], {type: `image/${type}`}), type === 'png' ? 'png' : 'other');

export const downloadBase64Txt = (base64, title) =>
  downloadBlob(title, utils.base64toBlob(base64), 'txt');

export const downloadBase64File = (base64, title) =>
  downloadBlob(title, utils.base64toBlob(base64), 'other');

export const downloadExcel = (exelData, title, externalSystem = '') => {
  let blob: Blob;
  let extension = 'xlsx';
  if(externalSystem !== ''){
    const externalSystemExportDatas = externalSystemFormats.find(elem =>
      elem.label === externalSystem
    );
    blob = new Blob([exelData], {
      type: externalSystemExportDatas.type
    });
    extension = externalSystemExportDatas.extension;
  }else{
    blob = new Blob([exelData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
  }
  const downloadURL = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadURL;
  link.download = `${title}.` + extension;
  link.click();
};

const printBlob = (blob: Blob) => {
  const blobUrl = URL.createObjectURL(blob);
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = blobUrl;
  document.body.appendChild(iframe);
  iframe.contentWindow.print();
};

export const printPdf = (response) =>
  printBlob(new Blob([response], {type: 'application/pdf'}));

export const printImage = (image) =>
  printBlob(new Blob([image], {type: 'image/png'}));

export const replaceUrlParameter = (
  route: string = '',
  params: any
): string => {
  if (!params || !route) {
    return route;
  }
  const keys = route.match(/:[a-zA-Z]+/g);
  let newRoute: string = route || '';
  (keys || []).forEach((key: string) => {
    const attribute = key.substr(1, key.length);
    if (params[attribute] !== undefined && params[attribute] !== null) {
      newRoute = newRoute.replace(
        key,
        Array.isArray(params[attribute])
          ? params[attribute]
          : /^[0-9]+$/.test(`${params[attribute]}`)
          ? +params[attribute]
          : params[attribute]
      );
    }
  });
  return newRoute;
};

export const randomStringGenerator = (length: number = 8): string => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;\'[]=-)(*&^%$#@!~`';
  let text = '';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};
export const randomNumber = (length: number = 6): number => {
  const possible = '1234567890';
  let text = '';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return +text;
};

export const verifyServerResponse = (
  object: any,
  isError: boolean = false
): { message: string; type: NotificationEnum; duration: number } | null => {
  if (object == null || typeof object !== 'object' || Object.keys(object).length > 4) {
    return null;
  }
  const keyLength = Object.keys(object ?? {}).length;
  if (keyLength === 1 && !!object.message) {
    return {
      message: object.message,
      type: isError ? NotificationEnum.ERROR : NotificationEnum.SUCCESS,
      duration: 5000
    };
  } else if (keyLength >= 2 && !!object.message) {
    const response = object as ServerResponseMessage;
    let type: NotificationEnum;
    if (!!object.type) {
      switch (response.type) {
        case 'error':
          type = NotificationEnum.ERROR;
          break;
        case 'warning':
          type = NotificationEnum.WARNING;
          break;
        case 'info':
          type = NotificationEnum.INFO;
          break;
        default:
          type = NotificationEnum.SUCCESS;
      }
    } else {
      type = isError ? NotificationEnum.ERROR : NotificationEnum.SUCCESS;
    }
    const duration = object.duration !== null ? (+object.duration > 0 ? +object.duration * 1000 : object.duration === -1 ? -1: 0) : 5000;
    return {message: response.message, type, duration};
  }
  return null;
};

export const createId = (length: number = 16): string => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const len = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * len));
  }
  return result;
};

export const getDefaultLanguage = (defaultLanguage = 'hu') => JSON.parse(localStorage.getItem(AuthSandbox.GLOBAL_KEY) || '{}')?.language || defaultLanguage;

export const isValidJwt = (jwt: string) => {
  const helper = new JwtHelperService();
  return !/^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/g.test(jwt) || helper.isTokenExpired(jwt);
};
