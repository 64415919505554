<ng-container *ngIf="partnerLastVerificationDate$ | async as info">
  <slm-message
    *ngIf="info.message"
    [message]="info.message | translate: info.params"
    [type]="info.type"
    [isInnerHtml]="!!info.params"
    class="w-100 block mb-4"></slm-message>
  <slm-message
    *ngIf="info.euMessage"
    [message]="info.euMessage | translate: info.euParams"
    [type]="info.euType"
    [isInnerHtml]="true"
    class="w-100 block mb-4"></slm-message>
</ng-container>

