import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  AttachedInvoices,
  ButtonConfig,
  buttonsStaticConfig,
  File,
  FileDetailed,
  FileTypeEnum,
  Icon,
  inputErrorTypes,
  InternalMessage,
  InvoiceTypeEnum,
  MessageSandbox,
  NavigationService,
  NotificationService,
  RoutesEnum,
  User,
  UserSandbox
} from '../../../global';
import {FileManagerSandbox} from '../../../global/sandboxes/file-manager.sandbox';
import {first, tap} from 'rxjs/operators';
import {attachedInvoicesTableConfig} from './file-edit-dialog.component.config';

@Component({
  selector: 'slm-file-edit-dialog',
  templateUrl: './file-edit-dialog.component.html'
})
export class FileEditDialogComponent implements OnInit{
  public readonly buttons = [
    {...buttonsStaticConfig.close}
  ];
  public readonly downloadButtonConfig: ButtonConfig = {
    ...buttonsStaticConfig.download,
    buttonStyle: 'secondary',
    height: 'large',
    icon: Icon.DOWNLOAD
  };
  public readonly errors = [inputErrorTypes.required];

  public form: FormGroup<{
    name: FormControl<string>;
  }>;
  public internalMessages: Array<InternalMessage>;
  public user$: Observable<User>;
  public user: User;
  public filePreviewData: string;
  public readonly fileTypeEnum = FileTypeEnum;
  public readonly attachedInvoicesTableConfig = [...attachedInvoicesTableConfig];
  public attachedInvoices: Array<AttachedInvoices> = [];
  public loading$ = new BehaviorSubject(true);

  public pagerTitles = [
    {
      title: 'LBL_BASE_DATA'
    },
    {
      title: 'FILE_MANAGER.PREVIEW'
    },
    {
      title: 'INVOICE.PAGE.MESSAGES'
    }];

  constructor(
    private readonly fb: FormBuilder,
    public readonly dialogRef: MatDialogRef<FileEditDialogComponent>,
    public readonly fileManagerSandbox: FileManagerSandbox,
    public readonly userSandbox: UserSandbox,
    public readonly messageSandbox: MessageSandbox,
    public readonly notification: NotificationService,
    public readonly navigation: NavigationService,
    @Inject(MAT_DIALOG_DATA) public data: {
      file?: File;
      readonlyUser: boolean;
      save: (any) => Observable<any>;
      download: () => Observable<any>;
    }) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: [{value: this.data.file?.name || '', disabled: this.data.readonlyUser}, [Validators.required]]
    });

    if(!this.data.readonlyUser){
      this.buttons.push({...buttonsStaticConfig.save});
    }

    this.fileManagerSandbox.getFile(this.data.file?.id).pipe(
      first(),
      tap(data => {
        this.internalMessages = data?.internalMessages || [];
        this.filePreviewData = this.makePreviewData(data);
        this.attachedInvoices = data?.attachedInvoices || [];
        this.loading$.next(false);
      })
    ).subscribe();

    this.user$ = this.userSandbox.user$.pipe(
      tap(user => this.user = user)
    );
  }

  buttonClicked(event: string) {
    if (event === 'close') {
      this.dialogRef.close();
      return;
    }

    if (event === 'download') {
      this.data.download();
      return;
    }

    if(this.form.invalid){
      this.form.markAllAsTouched();
      return;
    }

    this.buttons[1].isLoading = true;
    this.dialogRef.disableClose = true;

    const dataToSave = {...this.form.value, name: `${this.form.value.name}.${this.data.file.fileType}`};

    this.data.save(dataToSave).subscribe({
      next: (response) => {
        this.buttons[1].isLoading = false;
        this.dialogRef.disableClose = false;
        this.dialogRef.close({...this.form.value, ...response});
      },
      error: () => {
        this.buttons[1].isLoading = false;
        this.dialogRef.disableClose = false;
      }
    });
  }

  sendMessage(event: string): void {
    this.messageSandbox.sendMessage(this.data.file?.id, event, 'file-manager/file').subscribe((message) => {
      this.internalMessages = [message, ...(this.internalMessages || [])];
    });
  }

  modifyMessage(event: {id: number | string, message: string}): void {
    this.messageSandbox.updateMessage(event?.id, event?.message, 'file-manager/file').subscribe(() => {
      const index = this.findInInternalMessages(event.id);
      if (index >= 0) {
        const messages = [...(this.internalMessages || [])];
        messages.splice(index, 1, {
          ...messages[index],
          message: event.message
        });
        this.internalMessages = messages;
        this.notification.notify('LBL_MESSAGES.UPDATE_SUCCESS');
      }
    });
  }

  removeMessage(id: string): void {
    this.messageSandbox.deleteMessage(id, 'file-manager/file').subscribe(() => {
      const index = this.findInInternalMessages(id);
      if (index >= 0) {
        const messages = [...(this.internalMessages || [])];
        messages.splice(index, 1);
        this.internalMessages = messages;

        this.notification.notify('LBL_MESSAGES.DELETE_SUCCESS');
      }
    });
  }

  private findInInternalMessages(id: number | string): number {
    return (this.internalMessages || []).findIndex(
      (message) => message.id === id
    );
  }

  private makePreviewData(data: FileDetailed): string {
    let mimeType: string;
    switch(data.fileType){
      case FileTypeEnum.PNG:
        mimeType = 'image/png';
        break;
      case FileTypeEnum.JPEG: case FileTypeEnum.JPG:
        mimeType = 'image/jpeg';
        break;
      case FileTypeEnum.GIF:
        mimeType = 'image/gif';
        break;
      case FileTypeEnum.BMP:
        mimeType = 'image/bmp';
        break;
      case FileTypeEnum.PDF:
        mimeType = 'application/pdf';
        break;
      case FileTypeEnum.DOC:
        mimeType = 'application/msword';
        break;
      case FileTypeEnum.DOCX:
        mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;
      case FileTypeEnum.XLS:
        mimeType = 'application/vnd.ms-excel';
        break;
      case FileTypeEnum.XLSX:
        mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      case FileTypeEnum.TXT:
        mimeType = 'text/plain';
        break;
      case FileTypeEnum.PPT:
        mimeType = 'application/vnd.ms-powerpoint';
        break;
      case FileTypeEnum.PPTX:
        mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        break;
      default:
        return null;
    }

    if(mimeType){
      if(data?.previewData){
        return `data:${mimeType};base64,${data.previewData}`;
      }else{
        return '';
      }
    }else{
      return null;
    }
  }

  attachedInvoicesTableClicked(data: AttachedInvoices) {
    this.navigation.openInNewTab([data.type === InvoiceTypeEnum.INCOMING ? RoutesEnum.INCOMING_INVOICE_DATA_SHEET :
      RoutesEnum.OUTGOINGS_INVOICE_DATA_SHEET], {
      id: data.id
    });
  }
}
