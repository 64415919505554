import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {BehaviorSubject, Observable, Subject, combineLatest} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {debounceTime, map, shareReplay, takeUntil, tap} from 'rxjs/operators';
import {
  TableFilter,
  dropdownFadeAnimation,
  isSameArray,
  isSameObject, utils, invoiceTableFilterConfig
} from '../../../../global';
import {DateTransformPipe} from '../../../pipes';
import {RoutesEnum} from "../../../../global";
import {TranslateService} from '@ngx-translate/core';
import {Router} from "@angular/router";

@Component({
  selector: 'slm-table-filter',
  templateUrl: './table-filter.component.html',
  animations: [dropdownFadeAnimation],
  providers: [DateTransformPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableFilterComponent implements OnInit, OnDestroy {
  public config: Array<TableFilter> = [];
  public form: UntypedFormGroup = this.fb.group({});
  public selectedValues$: Observable<Array<{ key: string; tooltip: string; label: string; isList: boolean; disableDelete: boolean }>>;
  public readonly refreshSelectedValues$ = new BehaviorSubject<boolean>(true);
  public multiSearchValues$ = new BehaviorSubject<Array<string>>([]);
  private prevFilterObject: any = {};
  private debounceWaiting = false;
  public values: any = null;
  public showClearButton = false;
  private hasDate = false;
  private destroy$ = new Subject();

  @Input() public isOpen = false;
  @Input() public isFloating = false;

  @Input('values')
  public set setValues(values: any) {
    if (this.debounceWaiting) {
      return;
    }
    if (this.form) {
      if (
        (!Object.keys(values || {}).length &&
          !!Object.keys(this.form.value).length) ||
        (!isSameObject(this.form.value, {...this.form.value, ...values}) &&
          !!this.form.value)
      ) {
        this.values = values || {};
        if (!!this.values) {
          this.setValue(this.values);
        }
        this.cd.detectChanges();
      }
    }
  }

  @Input() columnCount = 5;
  @Input('multipleSearchText')
  public set setMultipleSearchText(search: Array<string>) {
    this.multiSearchValues$.next(search);
  }

  @Input('config')
  public set setConfig(config: Array<TableFilter>) {
    if(this.router.url.indexOf('documentType=invoice') !== -1
      && (this.router.url.indexOf(RoutesEnum.INVOICES_INCOMES_TABLE) !== -1
        || this.router.url.indexOf(RoutesEnum.INVOICES_OUTGOINGS_TABLE) !== -1)
      && !!config && !config.find(o => o.config.name === 'documentType')) {
        config.push(invoiceTableFilterConfig().filter(filterObject => filterObject.config.name === 'documentType')[0]);
    }

    if (!isSameArray(this.config, config) && !!config) {
      this.hasDate = false;
      if (config && config.length) {
        Object.keys(this.form.getRawValue()).forEach((name) =>
          this.form.removeControl(name)
        );
        config.forEach((config) => {
          if (config.type === 'date-range' || config.type === 'date') {
            this.hasDate = true;
          }
          if (['date-range', 'number-range'].includes(config.type)) {
            this.form.addControl(
              config?.config?.name,
              this.fb.group({
                start: [''],
                end: ['']
              })
            );
          } else {
            this.form.addControl(config?.config?.name, this.fb.control(''));
          }
        });
        this.config = [...config];
        if (!!this.values) {
          this.setValue(this.values);
        }
        this.cd.detectChanges();
      }
    }
  }

  private setValue(value: any) {
    if (!Object.values(value || {}).length) {
      this.form.reset();
      return;
    }
    const formValue = {};
    const filters = (this.config || []).map((config) => ({
      attribute: config.config.name,
      type: config.type
    }));
    const keys = Object.keys(value || {});
    keys.forEach((key) => {
      const isExist = filters.find((filter) => filter.attribute === key);

      if (isExist) {
        if (['date-range', 'number-range'].includes(isExist.type)) {
          if (!formValue[key]) {
            formValue[key] = {start: value[key], end: ''};
          } else {
            formValue[key].start = value[key];
          }
        } else {
          if(['multi-select'].includes(isExist.type)){
            formValue[key] = !utils.isObject(value[key]) ? value[key].toString().split(',') : value[key];
          }else{
            formValue[key] = value[key];
          }
        }
      } else if (key.endsWith('From') || key.endsWith('To')) {
        const attribute = key.replace(/(From)|(To)/gi, '');
        const isExist = filters.find(
          (filter) => filter.attribute === attribute && this.form?.get(attribute)
        );
        if (isExist && isExist.type === 'date-range') {
          const isStart = key.endsWith('From');
          if (!formValue[attribute]) {
            formValue[attribute] = isStart
              ? {start: new Date(value[key]), end: ''}
              : {start: '', end: new Date(value[key])};
          } else if (isStart) {
            formValue[attribute].start = new Date(value[key]);
          } else {
            formValue[attribute].end = new Date(value[key]);
          }
        } else if (isExist && isExist.type === 'number-range') {
          const isStart = key.endsWith('From');
          if (!formValue[attribute]) {
            formValue[attribute] = isStart
              ? {start: +value[key], end: ''}
              : {start: '', end: +value[key]};
          } else if (isStart) {
            formValue[attribute].start = +value[key];
          } else {
            formValue[attribute].end = +value[key];
          }
        }
      }
    });
    this.form.setValue({
      ...this.form.value,
      ...formValue
    });
  }

  @Output() filter = new EventEmitter<any>();
  @Output() multiSearch = new EventEmitter<any>();

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly cd: ChangeDetectorRef,
    private readonly datePipe: DateTransformPipe,
    private readonly translateService: TranslateService,
    private readonly router: Router
  ) {
  }

  ngOnInit() {
    this.selectedValues$ =
      combineLatest([
        this.refreshSelectedValues$,
        this.form.valueChanges,
        this.multiSearchValues$.asObservable()
      ])
        .pipe(
          tap(() => this.debounceWaiting = true),
          debounceTime(1000),
          map(([_, values, multiSearch]) => {
            const format = this.hasDate
              ? this.translateService.instant('FORMAT.DATE')
              : '';

            this.debounceWaiting = false;
            const filterObject = {};
            let count = 0;
            const notEmptyValues = Object.keys({...values, multiSearch})
              .filter(
                (key) =>
                  (!!multiSearch && key === 'multiSearch' && multiSearch.length > 0) ||
                  (!!values[key] &&
                  (((!Array.isArray(values[key]) && typeof values[key] !== 'object') || (Array.isArray(values[key]) || values[key].length)) ||
                    (!!values[key]?.start || values[key]?.start === 0) ||
                    (!!values[key]?.end || values[key]?.end === 0))))
              .map((key) => {
                if(key === 'multiSearch'){
                  return {
                    key: 'multiSearch',
                    label: multiSearch.filter(searchText => !!searchText),
                    tooltip: 'INVOICE.TEXT_FILTER',
                    isList: true,
                    disableDelete: false
                  };
                }

                const config = this.config.find(
                  (value) => value?.config?.name === key
                );
                const isList = config.type === 'multi-select';
                let label: string | any;
                if (isList) {
                  const items = !utils.isObject(values[key]) ? [values[key]] : values[key];
                  label = items.map(value => typeof config.chipName === 'function'
                    ? config.chipName(value)
                    :  utils.isString(config.chipName)
                      ? config.chipName
                      : value);
                  count += label.length;
                } else {
                  label = typeof config.chipName === 'function'
                    ? config.chipName(values[key])
                    : utils.isString(config.chipName)
                      ? config.chipName
                      : values[key];
                  ++count;
                }
                const value = values[key];
                let tooltip = config?.config?.label;
                if (config.type === 'date-range') {
                  if (!!label.start) {
                    filterObject[`${key}From`] = utils.dateToServerDate(new Date(
                      label.start)
                    );
                  }
                  if (!!label.end) {
                    filterObject[`${key}To`] = utils.dateToServerDate(new Date(label.end));
                  }
                  if (!!label.start && !!label.end) {
                    label = `${this.datePipe.transform(
                      label.start,
                      format
                    )}-${this.datePipe.transform(label.end, format)}`;
                  } else if (!!label.start && !label.end) {
                    label = this.datePipe.transform(label.start, format);
                  } else if (!label.start && !!label.end) {
                    label = this.datePipe.transform(label.end, format);
                  }
                } else if (config.type === 'number-range') {
                  if (!!label.start || label.start === 0) {
                    filterObject[`${key}From`] = utils.removeWhiteSpacesExceptMinus(`${label.start ?? ''}`);
                  }
                  if ((!!label.end || label.end === 0)) {
                    filterObject[`${key}To`] = utils.removeWhiteSpacesExceptMinus(`${label.end ?? ''}`);
                  }
                  if ((!!label.start || label.start === 0) && (!!label.end || label.end === 0)) {
                    label = `${utils.convertValueToAmount(label.start)} - ${utils.convertValueToAmount(label.end)}`;
                  } else if ((!!label.start || label.start === 0) && !label.end) {
                    label = utils.convertValueToAmount(label.start);
                    tooltip = `${config?.config?.label}_FROM`;
                  } else if (!label.start && (!!label.end || label.end === 0)) {
                    label = utils.convertValueToAmount(label.end);
                    tooltip = `${config?.config?.label}_TO`;
                  }
                } else if (config.type === 'date') {
                  label = this.datePipe.transform(label);
                  filterObject[key] = value;
                } else {
                  filterObject[key] = value;
                }
                this.showClearButton = count > 1;
                return {
                  key,
                  label,
                  isList,
                  disableDelete: config.disableChipRemove,
                  tooltip
                };
              });
            if (notEmptyValues.length) {
              if (!isSameObject(this.prevFilterObject, filterObject)) {
                this.prevFilterObject = filterObject;
                this.filter.next(filterObject);
              }
            } else if (Object.keys(this.prevFilterObject ?? {}).length !== 0) {
              this.prevFilterObject = {};
              this.filter.next({});
            }
            return notEmptyValues.length ? notEmptyValues : null;
          }),
          shareReplay({bufferSize: 1, refCount: true})
        );
    this.selectedValues$
      .pipe(debounceTime(250),
        takeUntil(this.destroy$))
      .subscribe(() => this.cd.detectChanges());
    this.form.markAsTouched();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public resetFilter(key: string) {
    this.form.get(key)?.reset('');
  }

  public removeSelectValue(key: string, index: number) {
    if(key === 'multiSearch'){
      const value = this.multiSearchValues$.getValue();
      value.splice(index, 1);
      this.multiSearchValues$.next(value);
      this.multiSearch.next(value);
    }else{
      const control = this.form.get(key);
      if (control?.value) {
        if (utils.isString(control.value)) {
          control.reset('');
        } else if (control.value?.length && control.value.length > index) {
          const value = [...control.value];
          value.splice(index, 1);
          control.setValue(value);
        }
      }
    }
  }

  public resetFilters() {
    this.form.reset();
  }

  public refreshSelected() {
    this.refreshSelectedValues$.next(true);
  }
}
