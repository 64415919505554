import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {concat, Observable, of} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {InputAbstract} from '../base/input.abstract';
import {Icons, InputError, SELECT_SEARCH_FUNCTION, SELECT_VALUE_MATCHER, SelectOption, utils} from '../../../../global';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';

@Component({
  selector: 'slm-select-autocomplete-input',
  templateUrl: './select-autocomplete-input.component.html',
  styles: [`
    :host {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
    }
  `]
})
export class SelectAutocompleteInputComponent
  extends InputAbstract
  implements OnInit {
  @Input() autoFocus = false;
  @Input() buttonIcon = true;
  @Input() enterEnabled = false;
  @Input() prefixIcon: Icons = null;
  @Input() suffixIcon: Icons = null;
  @Input() suffixIconLabel = '';
  @Input() maxDisplayLength = 36;
  @Input() clearButton = false;
  @Input() hideSubLabel = false;
  @Input() notFoundOptionLabel: string = null;

  @Input() errors: Array<InputError> = [];
  @Input() searchFunction: SELECT_SEARCH_FUNCTION = (search, option) =>
    (option?.label || '').toLowerCase().includes((search || '').toLowerCase());
  @Input() secondaryValueMatcher: SELECT_VALUE_MATCHER;

  @Input('options')
  public set setOptions(options: Array<SelectOption>) {
    this.options = options;
    this.loading = !options;
    const value = this.control?.value;
    if (this.secondaryValueMatcher && this.options && value) {
      let primaryMatch = null;
      let secondaryMatch = null;

      this.options.find(
        (option) => {
          const valueId = +(value?.value?.id || value?.id || value.value || value);
          if (+option.value?.id === valueId || +option.value?.id === +valueId || +option.value === +valueId) {
            primaryMatch = option;
            return;
          }
          if (!secondaryMatch && this.secondaryValueMatcher(value, option)) {
            secondaryMatch = option;
          }
        });
      if (primaryMatch || secondaryMatch) {
        this.control.setValue(primaryMatch || secondaryMatch);
      } /*else {
        TODO: need to delete later
        this.control.setValue(null);
      }*/
    } else if (
      !!options?.length &&
      !!value &&
      typeof value !== 'object'
    ) {
        const match = this.options.find(
          (option) => +option.value?.id === +value || +option.value?.id === +value || +option.value === +value
        );
        if (!!match) {
          this.control.setValue(match);
        } else {
          this.control.setValue(null);
        }
    }
    this.control.setValue(this.control.value);
  }

  private options: Array<SelectOption> = [];
  public options$: Observable<Array<SelectOption>>;
  public loading = true;

  @ViewChild('inputAutoComplete', {read: MatAutocompleteTrigger}) inputAutoComplete: MatAutocompleteTrigger;

  ngOnInit() {
    super.ngOnInit();
    this.options$ = concat(of(''), this.control.valueChanges).pipe(
      debounceTime(350),
      map((value: string) =>
        !value || typeof value !== 'string'
          ? this.options || []
          : (this.options || []).filter((option) => this.searchFunction(value as string, option))
      ),
      map(result => [...result,  ...(!!this.notFoundOptionLabel ? [{label: this.notFoundOptionLabel, value: -1}] : []) ])
    );
  }

  displayValue = () => (selected: SelectOption | string): string => {
    let label: string;
    if (!selected) {
      return '';
    }
    if (utils.isObject(selected)) {
      const selectedOption = selected as SelectOption;
      if (selectedOption?.label || selectedOption?.subLabel) {
        label = SelectAutocompleteInputComponent.getOptionLabel(selectedOption, this.hideSubLabel);
      } else {
        label = this.control?.value ?? '';
      }
    } else {
      const match = (this.options ?? []).find((option) => option.value === option);
      if (match) {
        label = SelectAutocompleteInputComponent.getOptionLabel(match, this.hideSubLabel);
      } else {
        label = match?.value ?? selected;
      }
    }
    return label?.length > +this.maxDisplayLength ? `${label.substring(0, +this.maxDisplayLength - 3)}...` : label;
  };

  public static getOptionLabel = ((option: SelectOption, hideSubLabel: boolean = false): string =>
    !hideSubLabel && option?.subLabel && option?.label ? `${option.label} - ${option.subLabel}` : option?.label || option?.subLabel);

  showDropDown() {
    if (this.inputAutoComplete && this.inputAutoComplete?.autocomplete?.closed) {
      this.inputAutoComplete.openPanel();
    }
  }

  clear() {
    this.control.reset();
  }

  trackItem(item) {
    return item.value;
  };
}
