import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationExtras, ParamMap, Params, Router} from '@angular/router';
import {Location} from '@angular/common';
import {RoutesEnum} from '../enums';
import {replaceUrlParameter} from '../functions';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private previousUrl = '';

  constructor(
    private readonly location: Location,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
  }

  public navigate(
    url: Array<string>,
    urlParameters?: any,
    extras?: NavigationExtras
  ): void {
    const newUrl = this.replaceUrl(url, urlParameters);
    const joinedUrl = newUrl.join('/');
    if (this.previousUrl === joinedUrl && joinedUrl) {
      this.goBack();
      return;
    }
    this.previousUrl = this.router.url;
    this.router.navigate(this.replaceUrl(url, urlParameters), extras).then();
  }

  public navigateByUrl(
    url: string,
    extras?: NavigationExtras
  ): void {
    this.previousUrl = this.router.url;
    this.router.navigateByUrl(url, extras).then();
  }

  private replaceUrl = (url: Array<string>, urlParameters?: any): Array<string> =>
    !urlParameters ? url : [replaceUrlParameter(
      (url || []).join('/'),
      urlParameters
    )];

  public get prevUrl() {
    return this.previousUrl;
  }

  public queryParams(): Observable<Params> {
    return this.route.queryParams;
  }

  public queryParamsMap(): Observable<Params> {
    return this.route.queryParamMap;
  }

  public snapshotQueryParams(): Params {
    return this.route.snapshot.queryParams;
  }

  public params(): Observable<ParamMap> {
    return this.route.paramMap;
  }

  public refreshQueryParams(
    queryParams: any,
    paramHandling: null | 'merge' | 'preserve' = null
  ): void {
    this.router.navigate([this.url.split('?')[0]], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: paramHandling
    }).then();
  }

  get url() {
    return this.router.url;
  }

  get events() {
    return this.router.events;
  }

  public goBack(prevUrlIfExist: boolean = false): void {
    if (prevUrlIfExist && this.previousUrl) {
      if (this.previousUrl.includes('?')) {
        this.navigateByUrl(this.previousUrl);
      } else {
        this.navigate([this.previousUrl]);
      }
      return;
    }
    const state: any = this.location.getState();
    if (!!state?.navigationId && state.navigationId === 1) {
      const url = this.url;
      const parts = (url || '').split('/');
      if (!!parts.length) {
        this.navigate(parts.slice(0, parts.length - 1));
      } else {
        this.navigate([RoutesEnum.HOME]);
      }
    } else {
      this.location.back();
    }
  }

  public redirect(url: string): void {
    if (!!url) {
      window.location.href = url;
    }
  }

  public openInNewTab(url: Array<string>, urlParameters?: any): void {
    const replacedUrl = this.replaceUrl(url, urlParameters).join('/');
    window.open(replacedUrl.startsWith('/') ? replacedUrl : `/${replacedUrl}`, '_blank');
  }
}
