import {Injectable} from '@angular/core';
import {Paginator, PaginatorNew, TableOrder} from '../entities';
import {InvoiceTypeEnum, DefaultLedgerNumberTypes, InvoiceType} from '../enums';
import {GlobalAccountingService} from '../services';
import {Store} from '@ngrx/store';
import {globalActions, globalSelectors} from '../+states';
import {filter, map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class GlobalAccountingSandbox {
  constructor(
    private readonly globalAccountingService: GlobalAccountingService,
    private readonly store: Store
  ) {
  }

  public readonly defaultVendorLedgerNumber = (type: DefaultLedgerNumberTypes) => this.store.select(globalSelectors.defaultDefaultLedgerNumber(type)).pipe(
    filter(value => {
      if (!value) {
        this.refreshDefaultVendorLedgerNumber(type);
        return false;
      }
      return true;
    })
  );

  public readonly refreshDefaultVendorLedgerNumber = (type: DefaultLedgerNumberTypes) =>
    this.store.dispatch(globalActions.loadDefaultLedgerNumber({ledgerNumberType: type}));

  public readonly clearDefaultVendorLedgerNumber = (type: DefaultLedgerNumberTypes) =>
    this.store.dispatch(globalActions.clearDefaultLedgerNumber({ledgerNumberType: type}));

  public readonly clearAllDefaultVendorLedgerNumber = () =>
    this.store.dispatch(globalActions.clearAllDefaultLedgerNumber());

  public readonly getAccountingTemplates = (
    page: PaginatorNew = null,
    order: TableOrder = null,
    search: string = null,
    periodId: number = null,
    date: string = null,
    type: InvoiceTypeEnum = null,
    partnerId: number = null
  ) =>
    this.globalAccountingService.getAccountingTemplates(
      page,
      order,
      search,
      periodId,
      date,
      type,
      partnerId
    );

  public readonly getLedgerNumbers = (
    page: Paginator,
    order: TableOrder,
    search: string = null,
    periodId: number = null,
    date: Date = null
  ) =>
    this.globalAccountingService.getLedgerNumbers(
      page,
      order,
      search,
      periodId,
      date
    );

  public readonly getDefaultLedgerNumber = (type: DefaultLedgerNumberTypes) =>
    this.globalAccountingService.getDefaultInvoice(type).pipe(map(ledgerNumber => ({ledgerNumber, ledgerNumberType: type})));

  public readonly getAccountingVatCodes = (
    page: Paginator,
    order: TableOrder = null,
    search: string = null,
    active: boolean = true,
    inactive: boolean = true
  ) =>
    this.globalAccountingService.getAccountingVatCodes(
      page,
      order,
      search,
      active,
      inactive
    );

  public readonly getDeductibleVatCodes = (
    page: PaginatorNew,
    order: TableOrder = null
  ) => this.globalAccountingService.getDeductibleVatCodes(page, order);

  public readonly checkRuleForDateAndCompany = (date: Date | string, companyId: number, type: InvoiceType) => this.globalAccountingService.checkRuleForDateAndCompany(date, companyId, type);
}
