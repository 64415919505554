import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'ngSwitchMultiCase'
})
export class NgSwitchMultiCasePipe implements PipeTransform {
  transform(value: string, ...options: string[]): string {
    return options.includes(value) ? value : undefined;
  }
}
