<slm-external-page-container>
  <h4 translate>LBL_COMPANY.ACTIVATION</h4>

  <slm-loader *ngIf="!invalid && !activated; else notLoading"></slm-loader>

</slm-external-page-container>

<ng-template #notLoading>

  <ng-container *ngIf="activated; else invalidToken">
    <slm-message message="LBL_COMPANY.ACTIVATION_SUCCESS" type="success"></slm-message>
  </ng-container>

  <slm-button text="GO_TO_HOME_SCREEN" class="block mt-3 mb-3" routerLink="/"></slm-button>
</ng-template>

<ng-template #invalidToken>
  <slm-message message="LBL_COMPANY.INVALID_ID" type="error"></slm-message>
</ng-template>
