<slm-dialog-container
  [title]="'LBL_BTN.DATA_SHEET'"
  [buttons]="buttons"
  overflow="hidden"
  (fireEvent)="buttonClicked($event.event)"
  class="block w-max-dialog-wide w-750px"
>
  <slm-pager
    [titles]="pagerTitles"
    [pages]="[dataSheet, images, innerMessages]"
    [disableElevation]="true"
  ></slm-pager>
</slm-dialog-container>

<ng-template #dataSheet
>
  <div class="mt-4 mb-2 grid-col-2-65-35 w-min-350px w-max-500px m-auto">
    <slm-text-input
      [label]="'FILE_MANAGER.FILE_NAME'"
      [control]="form.get('name')"
      [errors]="errors"
      testId="dialog_folder_name"
    ></slm-text-input>
    <slm-button
      [config]="downloadButtonConfig"
      class="mt-1"
      (click)="buttonClicked(downloadButtonConfig.action)"
    ></slm-button>
  </div>
  <div class="mt-4 mb-2 grid w-min-350px w-max-500px m-auto">
    <h5 class="txt-start-i block pb-1 mb-2 txt-medium border-bottom">{{'FILE_MANAGER.ATTACHED_INVOICES' | translate}}</h5>
    <ng-container *ngIf="!(loading$ | async) else loader">
      <slm-simple-table
        *ngIf="attachedInvoices.length > 0 else noAttachedInvoice"
        [config]="attachedInvoicesTableConfig"
        [items]="attachedInvoices"
        (clicked)="attachedInvoicesTableClicked($event)"
      ></slm-simple-table>
    </ng-container>

  </div>
</ng-template>


<ng-template #images
  class="w-100"
>
  <ng-container [ngSwitch]="data.file.fileType">
    <slm-image-list
      *ngSwitchCase="data.file.fileType | ngSwitchMultiCase : fileTypeEnum.PNG : fileTypeEnum.JPG : fileTypeEnum.JPEG : fileTypeEnum.GIF : fileTypeEnum.BMP"
      [showHeaderButtons]="false"
      [hideAllButton]="true"
      [imageFromUrl]="false"
      [imagePreviewData]="filePreviewData"
      title=""
    ></slm-image-list>

    <slm-pdf-viewer
      *ngSwitchCase="fileTypeEnum.PDF"
      [src]="filePreviewData"
    ></slm-pdf-viewer>

    <slm-no-image
      *ngSwitchDefault
      [placeholderTitle]="'FILE_MANAGER.PLACEHOLDER_TITLE'"
      [placeholderDescription]=""
    ></slm-no-image>
  </ng-container>
</ng-template>

<ng-template #innerMessages>
  <div
    class="mt-4 mb-6 w-min-350px w-max-500px m-auto"
  >
    <slm-internal-messages
      [messages]="internalMessages"
      [currentUser]="user$ | async"
      typeLabelSet="FILE_MANAGER.INTERNAL_MESSAGES_ADMIN"
      adminType="file-manager"
      (update)="modifyMessage($event)"
      (delete)="removeMessage($event)"
      (messageEntered)="sendMessage($event)"
      showToggle="true"
    ></slm-internal-messages>
  </div>
</ng-template>

<ng-template #loader>
  <slm-loader></slm-loader>
</ng-template>

<ng-template #noAttachedInvoice>
  <slm-message
    type="info"
    [message]="'FILE_MANAGER.NO_ATTACHED_INVOICE'"
  ></slm-message>
</ng-template>
