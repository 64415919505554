import {
  AbstractControl, UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {utils} from './converter.functions';

export const customRegexp = {
  username: /^[A-ZÀ-Ü][a-zà-ü]{1,50}[.]?([- ][A-ZÀ-Ü][a-zà-ü]{1,50}){1,10}$/,
  phone: /^[+]?(([(][0-9]{1,4}[)])|([0-9]{1,4}))([-\s./]?[0-9]{1,4}){1,4}$/,
  email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
  zipCode: /^[1-9][0-9]{3}$/,
  taxNumber: /^[1-9][0-9]{7}[-][0-9][-][0-9]{2}$/,
  companyRegistrationNumber: /^((0[1-9])|(1[0-9])|(20))[-]((0[1-9])|(1[0-9])|(2[0-3]))[-][0-9]{6}$/,
  /* eslint-disable max-len */
  euTaxNumber: /^((((ATU)|(DK)|(FI)|(HU)|(LU)|(MT)|(SI))\d{8})|(((EE)|(DE)|(EL)|(PT)|(GB))\d{9})|(((BE)|(BG))\d{9,10})|(RO\d{2,10})|(((PL)|(SK))\d{10})|(((HR)|(IT)|(LV))\d{11})|(CY\d{8}[A-Z])|(CZ\d{8,10})|(LT\d{9,12})|(SE\d{12})|(\d{9}MVA)|(CHE\d{3}(.)?\d{3}(.)?\d{3}((MWST)|(TVA)|(IVA)))|(ES(\d|[A-Z])\d{7}(\d|[A-Z]))|(NL([\dA-Z]){12})|(IE\d((\d{6,7}[A-Z])|([A-Z]\d{5,6}[A-Z])|(\d{5,6}[A-Z]{2})))|(FR([\dA-HJ-NP-Z]{2})\d{9})|(XI(((GD)|(HA))\d{3})|(\d{9})|(\d{12})))$/,
  digit: /^(-)?[0-9]+([.][0-9]+)?$/,
  onlyNumbers: /^((\d+\s?)+)$/,
  amount: /^(-)?[0-9]+([.][0-9]{1,10})?$/,
  bankIdentifier: /^(([0-9]{4}\s?){3}[0-9]{4})$|^(([0-9]{4}\s?){5}[0-9]{4})$/,
  /* eslint-disable max-len */
  swift: /^([a-zA-Z]){4}(AF|AX|AL|DZ|AS|AD|AO|AI|AQ|AG|AR|AM|AW|AU|AZ|BS|BH|BD|BB|BY|BE|BZ|BJ|BM|BT|BO|BA|BW|BV|BR|IO|BN|BG|BF|BI|KH|CM|CA|CV|KY|CF|TD|CL|CN|CX|CC|CO|KM|CG|CD|CK|CR|CI|HR|CU|CY|CZ|DK|DJ|DM|DO|EC|EG|SV|GQ|ER|EE|ET|FK|FO|FJ|FI|FR|GF|PF|TF|GA|GM|GE|DE|GH|GI|GR|GL|GD|GP|GU|GT|GG|GN|GW|GY|HT|HM|VA|HN|HK|HU|IS|IN|ID|IR|IQ|IE|IM|IL|IT|JM|JP|JE|JO|KZ|KE|KI|KP|KR|KW|KG|LA|LV|LB|LS|LR|LY|LI|LT|LU|MO|MK|MG|MW|MY|MV|ML|MT|MH|MQ|MR|MU|YT|MX|FM|MD|MC|MN|ME|MS|MZ|MM|MA|NR|NP|NL|AN|NC|NZ|NI|NE|NG|NU|NF|MP|NO|OM|PK|PW|PS|PA|PG|PY|PE|PH|PN|PL|PT|PR|QA|RE|RO|RU|RW|SH|KN|LC|PM|VC|WS|SM|ST|SA|SN|RS|SC|SL|SG|SK|SI|SB|SO|ZA|GS|ES|LK|SD|SR|SJ|SZ|SE|CH|SY|TW|TJ|TZ|TH|TL|TG|TK|TO|TT|TN|TR|TM|TC|TV|UG|UA|AE|GB|US|UM|UY|UZ|VU|VE|VN|VG|VI|WF|EH|YE|ZM|ZW)([0-9A-Z]){2}([0-9a-zA-Z]{3})?$/
};

export class CustomValidators {
  static username = (control: AbstractControl): ValidationErrors | null =>
    !!control.value && !customRegexp.username.test((control.value || '')?.trim())
      ? {username: {value: control.value}}
      : null;

  static onlyNumbers = (control: AbstractControl): ValidationErrors | null =>
    !!control.value && !customRegexp.onlyNumbers.test((control.value || '')?.trim())
      ? {onlyNumbers: {value: control.value}}
      : null;

  static validEmailArray = (
    control: AbstractControl
  ): ValidationErrors | null => {
    if (!control?.value) {
      return null;
    }
    const value = control.value;
    if (!Array.isArray(value)) {
      return null;
    }
    const hasInvalid = (value as Array<string>).find(
      (email) => !customRegexp.email.test(email)
    );
    return hasInvalid ? {email: {value: hasInvalid}} : null;
  };

  static autoCompleteObject = (
    control: AbstractControl
  ): ValidationErrors | null => {
    if (!control?.value) {
      return null;
    }
    const value = control.value || {};
    const keys = Object.keys(value);
    return keys.includes('label') && keys.includes('value')
      ? null
      : {autocomplete: {value: control.value}};
  };

  static strongPassword(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;
    if (value === value.toUpperCase()) {
      return {strongPassword: {value}};
    }
    if (value === value.toLowerCase()) {
      return {strongPassword: {value}};
    }
    if (!/[0-9]/.test(value)) {
      return {strongPassword: {value}};
    }

    return null;
  }

  static email = (control: AbstractControl): ValidationErrors | null =>
    !!control.value && Validators.email(control)
      ? {email: {value: control.value}}
      : null;

  static zipCode = (control: AbstractControl): ValidationErrors | null =>
    !!control.value && !customRegexp.zipCode.test(control.value)
      ? {zip: {value: control.value}}
      : null;

  static taxNumber = (control: AbstractControl): ValidationErrors | null =>
    !!control.value &&
    !customRegexp.taxNumber.test(control.value?.value || control.value)
      ? {taxNumber: {value: control.value}}
      : null;

  static companyRegistrationNumber = (control: AbstractControl): ValidationErrors | null =>
    !!control.value &&
    !customRegexp.companyRegistrationNumber.test(control.value?.value || control.value)
      ? {companyRegistrationNumber: {value: control.value}}
      : null;

  static euTaxNumber = (control: AbstractControl): ValidationErrors | null =>
    !!control.value && !customRegexp.euTaxNumber.test(control.value)
      ? {euTaxNumber: {value: control.value}}
      : null;

  static digit = (control: AbstractControl): ValidationErrors | null =>
    !!control.value && !customRegexp.digit.test(control.value)
      ? {digit: {value: control.value}}
      : null;

  static amount = (control: AbstractControl): ValidationErrors | null =>
    !!control.value && (isNaN(control.value) || !customRegexp.amount.test(`${control.value ?? ''}`.replace(/\s/g, '')))
      ? {amount: {value: control.value}}
      : null;

  static greaterThan = (value: number) => (control: AbstractControl): ValidationErrors | null =>
    ![null, undefined, ''].includes(control.value) && !isNaN(control.value) && +control.value <= value
      ? {min: {min: value, actual: control.value}}
      : null;

  static trimRequired = (control: AbstractControl): ValidationErrors | null =>
    !control.value || !(`${control.value}`.trim())
      ? {required: true}
      : null;

  static chipRequired = (control: AbstractControl): ValidationErrors | null =>
    !control.value || control.value.length === 0
      ? {required: true}
      : null;

  static notZero = (control: AbstractControl): ValidationErrors | null =>
    control.value !== '' && control.value === 0 ? {required: {value: control.value}} : null;

  static digitObject = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (utils.isObject(value)) {
      return !customRegexp.digit.test(value.value)
        ? {digit: {value}}
        : null;
    }
    return !customRegexp.digit.test(value) ? {digit: {value}} : null;
  };

  static orValidator = (
    validators: Array<ValidatorFn>,
    revert: boolean = false
  ): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
    if (!!control.value && !!validators?.length) {
      if (!revert) {
        const exist: ValidatorFn = validators.find(
          (validator) => validator(control) !== null
        );
        if (exist != null) {
          return exist(control);
        }
      } else {
        const exist: ValidatorFn = validators.find(
          (validator) => validator(control) === null
        );
        if (exist != null) {
          return null;
        }
        return validators.find((validator) => validator(control) !== null)(
          control
        );
      }
    }
    return null;
  };

  static phone = (control: AbstractControl): ValidationErrors | null =>
    !!control.value && !customRegexp.phone.test(control.value)
      ? {phone: {value: control.value}}
      : null;

  static swift = (control: AbstractControl): ValidationErrors | null =>
    !!control.value && !customRegexp.swift.test(control.value)
      ? {swift: {value: control.value}}
      : null;
  static bankAccount = (control: AbstractControl): ValidationErrors | null =>
    !!control.value &&
    !customRegexp.bankIdentifier.test(utils.removeWhiteSpaces(control.value))
      ? {bankIdentifier: {value: control.value}}
      : null;

  static raiffaisenAccountIdentifier = (control: AbstractControl): ValidationErrors | null =>
    utils.removeWhiteSpaces(control.value).length !== 13 && utils.removeWhiteSpaces(control.value).length !== 0
      ? {raiffaisenAccountIdentifier: {actualLength: control.value?.length}}
      : null;

  static bankAccountMinLength = (control: AbstractControl): ValidationErrors | null =>
    !!control.value &&
    utils.removeWhiteSpaces(control.value).length < 13
      ? {minlength: {value: control.value, requiredLength: 13, actualLength: control.value?.length ?? 0}}
      : null;

  static bankAccountMaxLength = (control: AbstractControl): ValidationErrors | null =>
    !!control.value &&
    utils.removeWhiteSpaces(control.value).length > 30
      ? {maxlength: {value: control.value, requiredLength: 30, actualLength: control.value?.length ?? 0}}
      : null;

  static isDateAfter = (date: Date): ValidatorFn => (
    control: AbstractControl
  ): ValidationErrors | null =>
    !!control.value &&
    new Date(control.value).getTime() < new Date(date).getTime()
      ? {isDateAfter: {date}}
      : null;

  static notSameDate(startName: string, endName: string): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
      const start = formGroup.get(startName);
      const end = formGroup.get(endName);

      if(start.value && end.value && utils.isSameDate(new Date(start.value), new Date(end.value))){
        const error = {sameDate: true};
        end.setErrors(error);
        return error;
      }
      return null;
    };
  };

  static absMax = (value: number) => (control: AbstractControl): ValidationErrors | null =>
    ![null, undefined, ''].includes(control.value) && !isNaN(control.value) && Math.abs(+control.value) > Math.abs(value)
      ? {max: {max: value}}
      : null;
}
