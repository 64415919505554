<mat-form-field
  class="flex-grow"
  [class.text-input--required]="isRequiredField  && (control.valid || !control.dirty)"
  [class.mat-form-field-invalid]="control && control.invalid && control.touched"
  (focusout)="setControlTouched()"
>
  <mat-label *ngIf="label || (!isRequiredField && !hideOptionalLabel)">
    {{ label | translate}}
    <span *ngIf="isRequiredField" class="mat-placeholder-required mat-form-field-required-marker ng-star-inserted"> *</span>
    <span *ngIf="secondaryLabel">{{ secondaryLabel | translate }}</span>
    <span *ngIf="!isRequiredField && !hideOptionalLabel" translate="LBL_INPUT.LABEL.NOT_REQUIRED"></span>
  </mat-label>

  <mat-chip-grid
    #chipGrid>
    <mat-chip-row *ngFor="let item of chips$ | async"
                  removable
                  color="primary"
                  (removed)="removeItem(item)">
      {{ item.name }}
      <slm-svg-icon icon="close" matChipRemove size="20px"></slm-svg-icon>
    </mat-chip-row>
  </mat-chip-grid>

  <input
    matInput
    #selectInput
    [formControl]="inputControl"
    [matChipInputFor]="chipGrid"
    [matAutocomplete]="auto"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    [required]="isRequiredField"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="selected($event)"
  >
    <mat-option *ngFor="let option of options; let index = index"
                [value]="option"
                [attr.data-test-id]="testId + index"
                class="h-min-3r h-fit-content-i pt-1-i pb-1-i"
                (click)="disableLoading()"
    >
      <div class="txt-medium">{{ option.label | translate }}</div>
    </mat-option>
  </mat-autocomplete>
  <mat-error
    *ngIf="control && control.invalid && control.touched"
  >{{ getError() | translate: errorBody }}</mat-error>
</mat-form-field>
