<slm-dialog-container
  [title]="'HELP.NAME'"
  [buttons]="buttons"
  class="w-max-dialog-wide block"
  (fireEvent)="buttonEvent()"
>
  <article *ngIf="content$ | async as content; else loading" [innerHTML]="content"></article>
</slm-dialog-container>

<ng-template #loading>
  <slm-loader></slm-loader>
</ng-template>
