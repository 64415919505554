import {SELECT_SEARCH_FUNCTION, SELECT_VALUE_MATCHER, SelectOption} from '../entities';

export const ledgerNumberSearchFunction: SELECT_SEARCH_FUNCTION = (searchString: string, selectOption: SelectOption) => {
  const globalSearch = (searchString ?? '').includes('*');

  const search = (globalSearch ?
    searchString.replace(/\*/g, '') : searchString ?? '').toLowerCase();

  const isNumeric = !isNaN(Number(search));
  const searchLabel = ((isNumeric ? selectOption?.label : selectOption?.subLabel) ?? '').toLowerCase();
  return globalSearch ? searchLabel.includes(search) : searchLabel.startsWith(search);
};

export const selectOptionTextSearch: SELECT_SEARCH_FUNCTION = (searchString: string, selectOption: SelectOption) => {
  const globalSearch = (searchString ?? '').includes('*');

  const search = (globalSearch ?
    searchString.replace(/\*/g, '') : searchString ?? '').toLowerCase();

  const searchLabel = (selectOption?.label ?? '').toLowerCase();
  const searchSubLabel = (selectOption?.subLabel ?? '').toLowerCase();

  if(globalSearch){
    return searchLabel.includes(search) || searchSubLabel.includes(search);
  }

  return searchLabel.startsWith(search) || searchSubLabel.startsWith(search);
};

export const labelSecondaryMatcher: SELECT_VALUE_MATCHER =  (search, option): boolean => {
  if (!search || typeof search !== 'object') {
    return false;
  }
  return search.label === option.label;
};
