import {Injectable} from '@angular/core';
import {HttpClient, HttpContext} from '@angular/common/http';
import {ErrorHandlingService} from './error-handling.service';
import {ExternalInvoice, ImagesDataBase, InvoiceDetailed, InvoicePayments, InvoiceTableItem, ListResponseNew, PageResponse, TableOrder} from '../entities';
import {Observable} from 'rxjs';
import {capitalizeFirstLetter} from '../functions';
import {catchError} from 'rxjs/operators';
import {InvoiceTypeEnum, RoutesEnum} from '../enums';
import {AllExternalSystems} from "../static/externalSoftwares.static";
import {CLEAR_CACHE, IS_CACHE_ENABLED} from '../../../app/interceptors/cache.interceptor';

@Injectable({
  providedIn: 'root'
})
export class GlobalInvoiceService {
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandler: ErrorHandlingService
  ) {
  }

  public getInvoices(
    invoiceType: InvoiceTypeEnum,
    pageNumber: number = 1,
    itemsPerPage: number = 10,
    order: TableOrder = null,
    filters: any = null,
    searchText: string = '',
    isCollecting: boolean = false
  ): Observable<PageResponse<InvoiceTableItem>> {
    const orderValue =
      order && order.order
        ? {[`order${capitalizeFirstLetter(order.attribute)}`]: order.order}
        : null;
    return this.http
      .get(`/documents/${invoiceType}-list`, {
        params: {
          pageNumber,
          itemsPerPage,
          ...(!!searchText ? {searchText: encodeURIComponent(searchText)} : {}),
          ...orderValue,
          ...filters,
          ...(isCollecting && invoiceType === InvoiceTypeEnum.OUTGOING
            ? {isCollecting}
            : {})
        }
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public exportInvoiceList(invoiceType: InvoiceTypeEnum, config: {
    externalSystem?: string;
    filters?: any;
    searchText?: string;
    exportFormat?: 'excel' | 'pdf';
    selectedIds?: Array<number>;
    extraFields?: string;
    merge?: string;
  }): Observable<PageResponse<InvoiceTableItem>> {
    const ids = config?.selectedIds?.length ? config?.selectedIds.join(',') : null;

    let path;
    if ([InvoiceTypeEnum.NAV_INCOMING, InvoiceTypeEnum.NAV_OUTGOING].includes(invoiceType)) {
      path = `/${RoutesEnum.INVOICES}/${invoiceType.replace('-', '/')}/export`;
    } else {
      if(config?.externalSystem !== ''){
        path = `/${RoutesEnum.DOCUMENTS}/${invoiceType}-list/export-for-external`;
      }else{
        path = `/${RoutesEnum.DOCUMENTS}/${invoiceType}-list/exports`;
      }
    }

    const responseType : any = config.externalSystem === AllExternalSystems.SMARTBOOKS ? 'json' : 'blob';
    const { extraFields, merge, exportFormat = 'excel', searchText, filters } = config || {};
    return this.http.get(path, {
      params: {
        ...extraFields ? { extraFields } : {},
        ...merge ? { merge } : {},
        exportFormat,
        ...(searchText && !ids ? {searchText: encodeURIComponent(searchText)} : {}),
        ...!ids ? filters : {},
        ...!!ids ? {ids} : {}
      },
      responseType
    }).pipe(catchError((err) => this.errorHandler.handleError(err)));  }

  public downloadPdf(id: string | number): Observable<any> {
    return this.http
      .get(`/pdf/${id}`, {responseType: 'blob'})
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public downloadDocumentPdf(id: string | number): Observable<any> {
    return this.http
      .get(`/images/${id}/download`, {responseType: 'blob'})
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public saveInvoicePayments(
    id: number,
    deposits: Array<any>
  ): Observable<any> {
    return this.http
      .put(`/invoices/${id}/deposits`, {items: deposits})
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public deleteInvoicePayments(id: number): Observable<any> {
    return this.http
      .delete(`/invoices/deposits/${id}`)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getInvoicePayments(
    id: number
  ): Observable<ListResponseNew<InvoicePayments>> {
    return this.http
      .get(`/invoices/${id}/deposits`)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getInvoice(id: string | number): Observable<InvoiceDetailed> {
    return this.http
      .get(`/documents/${id}`)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public downloadImage(imageId: string | number, responseType: any = 'arraybuffer', clearCache: boolean, cacheEnabled = true): Observable<any> {
    return this.http
      .get(`/images/${imageId}`, {
        responseType,
        context: new HttpContext()
          .set(IS_CACHE_ENABLED, cacheEnabled)
          .set(CLEAR_CACHE, clearCache)
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public rotateImage(imageId: string | number, direction: 'left' | 'right'): Observable<any> {
    return this.http
      .get(`/images/${imageId}/rotate`, {params: {direction}})
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public publicInvoice(jwt: string): Observable<ExternalInvoice> {
    return this.http
      .get(`/public/documents/${jwt}`)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public downloadPublicInvoice(jwt: string): Observable<ExternalInvoice> {
    return this.http
      .get(`/public/documents/${jwt}/pdf`, {responseType: 'blob'})
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getImagesData(imageId: string | number): Observable<ImagesDataBase> {
    return this.http
      .get(`/images-data/${imageId}`, {
        context: new HttpContext().set(IS_CACHE_ENABLED, true)
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }
}
