import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ButtonConfig, ColumnTableItem, TableAlign } from '../../../../global';

@Component({
  selector: 'slm-table-card',
  template: `
    <div
      class="card card--no-padding {{customClass}}"
      [class.card--no-shadow]="disableElevation"
      [style.minHeight]="minHeight || 'auto'"
    >
      <div class="card__header">
        <h4 class="card__header__title">{{ title | translate }}</h4>
        <div *ngIf="button" class="card__header__buttons">
          <slm-button
            *slmAccess="button.rights"
            [config]="button"
            (click)="buttClicked.emit()"
          ></slm-button>
        </div>
      </div>
      <div class="w-100 block p-2">
        <slm-vertical-table
          [data]="data"
          [attributes]="attributes"
          (thirdColumnClicked)="thirdColumnClicked.emit($event)"
        ></slm-vertical-table>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCardComponent {
  @Input() title = '';
  @Input() data: any = null;
  @Input() button: ButtonConfig = null;
  @Input() textAlign: TableAlign | 'start' | 'end' | 'center' =
    TableAlign.START;
  @Input() minHeight: string = null;
  @Input() thirdColumnEnabled = false;
  @Input() public disableElevation = false;
  @Input() attributes: Array<ColumnTableItem> = [];
  @Input() customClass = 'h-100';

  @Output() buttClicked = new EventEmitter();
  @Output() thirdColumnClicked = new EventEmitter();
}
