import {TranslateLoader, TranslateService} from '@ngx-translate/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  IModuleTranslationOptions,
  ModuleTranslateLoader
} from '@larscom/ngx-translate-module-loader';
import {filter, pairwise, switchMap, tap} from 'rxjs/operators';

export class TranslationModuleLoader implements TranslateLoader {
  constructor(private http: HttpBackend, private module: string) {
  }

  getTranslation(lang: string): Observable<any> {
    return new HttpClient(this.http).get(
      `/assets/i18n/translations/${this.module}/${this.module}_${lang}.json`
    );
  }
}

export const moduleHttpLoaderFactory = (httpBackend: HttpBackend) => {
  const baseTranslateUrl = '/assets/i18n/translations/common';
  const pathTemplate = '{baseTranslateUrl}/{moduleName}/{moduleName}_{language}';
  const client = new HttpClient(httpBackend);
  const options: IModuleTranslationOptions = {
    modules: [
      // no moduleName/namespace
      {baseTranslateUrl, pathTemplate: '{baseTranslateUrl}/shared_{language}'},
      // namespace: AUTHENTICATION, registration and login labels
      {moduleName: 'authentication', baseTranslateUrl, pathTemplate},
      // namespace: MESSAGE, app notification messages and questions
      {moduleName: 'message', baseTranslateUrl, pathTemplate},
      // namespace: INVOICE, invoice labels
      {moduleName: 'invoice', baseTranslateUrl, pathTemplate},
      // namespace: Inputs
      {moduleName: 'inputs', baseTranslateUrl, pathTemplate},
      {moduleName: 'bug_report', baseTranslateUrl, pathTemplate},
      {moduleName: 'file_manager', baseTranslateUrl, pathTemplate}
    ]
  };
  return new ModuleTranslateLoader(client, options);
};

export const moduleTranslateLoader = (httpBackend: HttpBackend, moduleName: string) => new TranslationModuleLoader(httpBackend, moduleName);

export const initModuleTranslation = (translateService: TranslateService) => {
  const language = translateService.store.currentLang
    || JSON.parse(localStorage.getItem('global') || '{}').language;

  translateService.currentLang = '';
  translateService.use(language).subscribe();
  translateService.store.onLangChange.pipe(
    pairwise(),
    filter(([prev, current]) => prev.lang !== current.lang),
    tap(() => translateService.currentLang = ''),
    switchMap(([_, lang]) => translateService.use(lang.lang))).subscribe();
};
