import { ButtonConfig } from '../entities';
import {Icon} from '../enums';

export const buttonsStaticConfig: {
  cancel: ButtonConfig;
  back: ButtonConfig;
  saveData: ButtonConfig;
  attachDocument: ButtonConfig;
  save: ButtonConfig;
  send: ButtonConfig;
  update: ButtonConfig;
  delete: ButtonConfig;
  updateData: ButtonConfig;
  quickVerify: ButtonConfig;
  yes: ButtonConfig;
  close: ButtonConfig;
  no: ButtonConfig;
  import: ButtonConfig;
  download: ButtonConfig;
  export: ButtonConfig;
  saveTransactionFile: ButtonConfig;
  check: ButtonConfig;
} = {
  cancel: {
    label: 'LBL_BTN.CANCEL',
    action: 'cancel',
    buttonStyle: 'secondary',
    align: 'left',
    height: 'normal',
    testId: 'cancel'
  },
  close: {
    label: 'LBL_BTN.CLOSE',
    action: 'close',
    buttonStyle: 'secondary',
    align: 'left',
    testId: 'closeButton',
    height: 'normal'
  },
  back: {
    label: 'LBL_BTN.BACK',
    action: 'back',
    testId: 'backButton',
    buttonStyle: 'secondary',
    align: 'left',
    height: 'normal'
  },
  saveData: {
    label: 'LBL_BTN.SAVE_DATA',
    action: 'save',
    testId: 'saveButton',
    buttonStyle: 'primary',
    align: 'left',
    type: 'submit',
    height: 'normal'
  },
  attachDocument: {
    label: 'LBL_BTN.ATTACH_DOCUMENT',
    action: 'attach',
    testId: 'attachButton',
    buttonStyle: 'primary',
    align: 'left',
    type: 'submit',
    height: 'normal'
  },
  save: {
    label: 'LBL_BTN.SAVE',
    action: 'save',
    buttonStyle: 'primary',
    align: 'left',
    type: 'submit',
    height: 'normal',
    testId: 'saveButton'
  },
  send: {
    label: 'LBL_BTN.SEND',
    action: 'send',
    buttonStyle: 'primary',
    align: 'left',
    type: 'submit',
    height: 'normal'
  },
  updateData: {
    label: 'LBL_BTN.DATA_UPDATE',
    action: 'update',
    type: 'submit',
    buttonStyle: 'primary',
    align: 'left',
    height: 'normal'
  },
  update: {
    label: 'LBL_BTN.UPDATE',
    action: 'update',
    type: 'submit',
    buttonStyle: 'primary',
    align: 'left',
    height: 'normal'
  },
  delete: {
    label: 'LBL_BTN.DELETE',
    action: 'delete',
    type: 'submit',
    buttonStyle: 'primary',
    align: 'left',
    height: 'normal'
  },
  quickVerify: {
    label: 'LBL_BTN.QUICK_VERIFY',
    action: 'quick_verify',
    buttonStyle: 'primary',
    align: 'left',
    height: 'normal'
  },
  yes: {
    label: 'LBL_YES',
    action: 'approve',
    buttonStyle: 'primary',
    testId: 'yesButton',
    align: 'left',
    type: 'submit',
    height: 'normal'
  },
  no: {
    label: 'LBL_NO',
    action: 'decline',
    testId: 'noButton',
    buttonStyle: 'secondary',
    align: 'left',
    height: 'normal'
  },
  download: {
    label: 'LBL_BTN.DOWNLOAD',
    action: 'download',
    testId: 'downloadButton',
    buttonStyle: 'primary',
    align: 'right',
    height: 'normal'
  },
  import: {
    label: 'IMPORT',
    height: 'normal',
    buttonStyle: 'secondary',
    icon: Icon.UPLOAD_FILE,
    align: 'right',
    action: 'import',
    testId: 'importButton'
  },
  export: {
    label: 'LBL_BTN.EXPORT',
    height: 'normal',
    buttonStyle: 'secondary',
    icon: Icon.EXPORT,
    align: 'right',
    action: 'export',
    testId: 'exportButton'
  },
  saveTransactionFile: {
    label: 'LBL_BTN.SAVE_TRANSACTION_FILE',
    height: 'normal',
    buttonStyle: 'primary',
    icon: Icon.SAVE,
    align: 'right',
    action: 'saveTransactionFile',
    testId: 'saveTransactionFileButton'
  },
  check: {
    label: 'LBL_BTN.DATA_SYNC',
    height: 'normal',
    buttonStyle: 'secondary',
    icon: Icon.CHECK_CIRCLE,
    align: 'right',
    action: 'check',
    testId: 'checkButton'
  },
};
