import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {
  AuthSandbox, ButtonConfig, buttonsStaticConfig,
  inputErrorTypes,
  NavigationService,
  NotificationEnum,
  NotificationService, RoutesEnum
} from '../../../modules/global';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'slm-reset-password',
  template: `
    <slm-external-page-container>
      <h4 translate="AUTHENTICATION.RESET.TITLE"></h4>
      <h5 translate="AUTHENTICATION.RESET.DESCRIPTION"></h5>
      <slm-text-input
        type="email"
        class="w-100 mt-3"
        label="LBL_INPUT.LABEL.EMAIL"
        [control]="emailControl"
        [errors]="errors"
      ></slm-text-input>
      <slm-button
        class="w-100 mt-2"
        (click)="resetPassword()"
        [loading]="btnLoading"
        type="submit"
        testId="sendEmail"
        text="AUTHENTICATION.RESET.SEND_EMAIL"
        [disabled]="emailControl.invalid"
      ></slm-button>
      <slm-button
        class="w-100 mt-2"
        [config]="goBackButton"
        testId="backButton"
      ></slm-button>
    </slm-external-page-container>
  `
})
export class ResetPasswordContainer implements OnInit {
  public readonly emailControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  public readonly errors = [inputErrorTypes.email, inputErrorTypes.required];
  public btnLoading = false;
  public readonly goBackButton: ButtonConfig = {
    ...buttonsStaticConfig.back,
    url: `/${RoutesEnum.LOGIN}`
  };

  constructor(private readonly authSandbox: AuthSandbox,
              private readonly route: ActivatedRoute,
              private readonly navigation: NavigationService,
              private readonly notification: NotificationService) {
  }

  ngOnInit() {
    if (this.route.snapshot.queryParams.email) {
      this.emailControl.setValue(this.route.snapshot.queryParams.email);
    }
  }

  public resetPassword() {
    if (this.emailControl.invalid) {
      this.emailControl.markAllAsTouched();
      return;
    }
    if (this.btnLoading) {
      return;
    }
    this.btnLoading = true;
    this.authSandbox.resetPassword(this.emailControl.value).subscribe(
      () => {
        this.btnLoading = false;
        this.notification.notify('AUTHENTICATION.RESET.EMAIL_SENT', NotificationEnum.SUCCESS, 10000);
        this.navigation.navigate([RoutesEnum.LOGIN]);
      },
      () => {
        this.btnLoading = false;
      }
    );
  }
}
