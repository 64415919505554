import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TableText, TableAlign } from '../../../../../../global';

@Component({
  selector: 'slm-table-text',
  template: `
    <h5
      *ngIf="data; else invalid"
      [matTooltip]="tooltip | translate"
      [class]="
        'w-auto mb-0 m-1 txt-no-wrap txt-ellipsis block txt-' +
        (align || 'center') +
        '-i'
      "
    >
      {{ data.label | translate: data.data }}
    </h5>
    <ng-template #invalid
      ><span class="w-100 block txt-center-i">--</span></ng-template
    >
  `,
  styles: [
    `
           :host {
             display: block;
           }
         `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableTextComponent {
  @Input() data: TableText = null;
  @Input() align: TableAlign = TableAlign.CENTER;
  @Input() tooltip: string = null;
}
