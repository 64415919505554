<div
  class="paginator flex-items-center flex-{{pages && pages.length ? 'space-btwn' : 'end'}}"
>
  <div
    *ngIf="pages && pages.length"
    class="paginator__list"
    [class.paginator__list--disabled]="disabled"
  >
    <div
      class="paginator__list__bg"
      [style.margin-left.rem]="marginRightOfBg()"
    ></div>
    <slm-button-icon
      data-test-id="paginator_prev_btn"
      class="paginator__list__btn block fill-white bg-color-accent"
      [class.paginator__list__btn--disabled]="previousBtnDisabled"
      (click)="onClickPageBackward()"
      [elevation]="!previousBtnDisabled"
      [disable]="previousBtnDisabled"
      [matTooltip]="
        previousBtnDisabled ? null : ('LBL_TABLE.PAGE.PREVIOUS' | translate)
      "
      icon="navigate_previous"
    ></slm-button-icon>

    <ng-container *ngFor="let item of pages">
      <slm-button-icon
        *ngIf="item !== -1"
        (click)="onActualPageChange(item)"
        class="paginator__list__btn block color-accent"
        [class.bg-color-accent-transparent]="item === page.page"
        [text]="item + ''"
        [attr.data-test-id]="'paginator_{{item}}_btn'"
        [elevation]="true"
        [matTooltip]="
          item === page.page
            ? ('LBL_TABLE.PAGE.CURRENT' | translate)
            : ('LBL_TABLE.PAGE.GO_TO' | translate: { pageNumber: item })
        "
      ></slm-button-icon>
      <h3 *ngIf="item === -1" class="paginator__list__more">...</h3>
    </ng-container>

    <slm-button-icon
      data-test-id="paginator_next_btn"
      class="paginator__list__btn block fill-white bg-color-accent"
      [class.paginator__list__btn--disabled]="nextBtnDisabled"
      [disable]="nextBtnDisabled"
      (click)="onClickPageForward()"
      [elevation]="!nextBtnDisabled"
      [matTooltip]="
        nextBtnDisabled ? null : ('LBL_TABLE.PAGE.NEXT' | translate)
      "
      icon="navigate_next"
    ></slm-button-icon>
  </div>

  <slm-select-input
    [control]="sizeControl"
    data-test-id="paginator_size_input"
    class="paginator__size w-max-100"
    label="LBL_TABLE.PAGE.SIZE"
    [options]="pageSizeOptions"
  ></slm-select-input>
</div>
