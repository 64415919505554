<div *slmAccess="rightsToMakePayout">
  <h5 class="txt-start-i mb-1 mt-3-i">{{title | translate}}</h5>
  <ng-container *ngIf="(savedInTransaction$ | async) as savedInTransaction">
    <slm-message
      type="info"
      message="{{'INVOICE.SAVED_IN_TRANSACTION_LONG' | translate: {
          value: savedInTransaction
        } }}"
    ></slm-message>
  </ng-container>
  <div
    *ngFor="let template of arrayItems; let index = index;"
    class="radius p-2 mt-3 bg-color-accent-transparent-lighter"
  >
    <form [formGroup]="template">
      <div class="grid-col-2-40-60 grid-responsive"
           *ngIf="template.get('type').value !== 'cashDiscount'; else cashDiscountBlock">
        <slm-select-input
          [control]="template.get('type')"
          [options]="typeOptions"
          label="INVOICE.TYPE"
          [errors]="errors"
          testId="paymentType"
        ></slm-select-input>
        <div *ngIf="showDateAndValueFields$[index] | async" class="grid-col-2-50">
          <slm-date-input
            [control]="template.get('form').get('date')"
            [errors]="errors"
            testId="paymentDate"
            label="INVOICE.DATE"
          ></slm-date-input>
          <slm-amount-input
            label="INVOICE.AMOUNT"
            [errors]="errors"
            decimals="2"
            [currency]="currency$ | async"
            testId="paymentAmount"
            [control]="template.get('form').get('value')"
          ></slm-amount-input>
        </div>
        <div>
          <slm-select-input
            *ngIf="template.get('type').value === 'proforma'"
            label="INVOICE_TYPE.REQUEST"
            [errors]="errors"
            [control]="template.get('form').get('prepayment')"
            [options]="prepayment$ | async"
          ></slm-select-input>
        </div>
      </div>
      <ng-template #cashDiscountBlock>
        <div class="grid-col-2-40-60 grid-responsive">
          <slm-select-input
            [control]="template.get('type')"
            [options]="typeOptions"
            label="INVOICE.TYPE"
            [errors]="errors"
            testId="paymentType"
          ></slm-select-input>
          <slm-date-input class="grid-c"
                          [control]="template.get('form').get('date')"
                          [errors]="errors"
                          testId="paymentDate"
                          label="INVOICE.DATE"
          ></slm-date-input>
        </div>
        <div class="grid-col-2-50 grid-responsive">
          <slm-amount-input
            label="INVOICE.DISCOUNT_PERCENT"
            [errors]="errors"
            testId="discountPercent"
            [control]="template.get('form').get('percent')"
            (focusin)="onFocus(true)"
            (focusout)="onFocus(false)"
            decimals="2"
            suffixLabel="%"
          ></slm-amount-input>
          <slm-amount-input
            label="INVOICE.AMOUNT"
            [errors]="errors"
            [currency]="currency$ | async"
            testId="paymentAmount"
            [control]="template.get('form').get('value')"
          ></slm-amount-input>
        </div>
      </ng-template>
      <slm-message
        *ngIf="(config$ | async) === null && template.get('type').value === 'proforma'"
        message="INVOICE.PAYOUT_PROFORMA_ERROR"
        type="warning"
      ></slm-message>
      <slm-message
        *ngIf="template.get('type').value === 'proforma' && (noProformas$ | async)"
        message="INVOICE.PAYOUT_NO_PROFORMA"
        type="warning"
      ></slm-message>
      <slm-text-area *ngIf="showPaymentNoteField$[index] | async"
                     label="INVOICE.PAYMENT_NOTE"
                     placeholder="LBL_COMPANY.NOTE_EXAMPLE"
                     maxRow="8"
                     [errors]="errors"
                     minRow="4"
                     maxLength="1024"
                     testId="paymentNote"
                     [control]="template.get('form').get('note')"
      ></slm-text-area>
    </form>
    <div *ngIf="!addDefaultElement || array.length > 1" class="flex flex-end">
      <slm-button
        buttonStyle="transparent-blue"
        class="mt-3 mb-3 block float-right"
        icon="delete"
        text="LBL_BTN.DELETE"
        testId="deletePayout"
        (click)="deleteAccountingItem(index)"
      ></slm-button>
    </div>
  </div>
  <slm-button
    text="INVOICE.PAYOUT_ADD"
    buttonStyle="secondary"
    class="mt-3 mb-3 block float-right"
    icon="add_circle"
    (click)="newAccountingItem()"
    testId="addPayout"
  ></slm-button>
</div>
