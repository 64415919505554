import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
  ButtonConfig,
  buttonsStaticConfig,
  FireEvent,
  GeneralSandbox,
  Icon,
  inputErrorTypes,
  NavigationService,
  NotificationEnum,
  NotificationService, NotificationTimes, RoutesEnum
} from '../../../modules/global';

@Component({
  selector: 'slm-report',
  templateUrl: './bug-report.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BugReportContainer implements OnInit {

  /**
   * translation parameters
   */
  public readonly links  = {
   faqUrl: `/${RoutesEnum.FAQ}`,
   videoUrl: `/${RoutesEnum.VIDEOS}`
  };

  /**
   * Icon enumeration
   */
  public readonly icons = Icon;

  /**
   * @type {Array<InputError>}
   * List of input error texts
   */
  public readonly errors = [
    inputErrorTypes.required,
    inputErrorTypes.minLength
  ];

  /**
   * @type {Array<ButtonConfig>}
   * Button list configuration, but some modification. These buttons are aligned to right in the </slm-bottom-bar>.
   */
  public readonly buttons: Array<ButtonConfig> = [
    {
      ...buttonsStaticConfig.cancel,
      testId: 'cancelButton',
      align: 'right'
    },
    {
      ...buttonsStaticConfig.send,
      label: 'BUG_REPORT.SEND',
      testId: 'sendButton',
      align: 'right'
    }
  ];

  public form: UntypedFormGroup;

  /**
   * @type {Array<File>}
   * Users can attach some images (png/jpg/jpeg) to the bug report. This list will contain the file (image) list.
   */
  public readonly files: Array<File> = [];

  /**
   * @param fb {FormBuilder} - FormBuilder for the reactive forms
   * @param cd {ChangeDetectorRef} - If you check the component config (@Component) you will find a changeDetection attribute.
   * Medium article about the the ChangeDetection strategies: [Amazingly Simplified Angular Change Detection Explanation]
   * {@link https://medium.com/swlh/amazingly-simplified-angular-change-detection-explanation-feef61b85573}
   * @param generalSandbox {GeneralSandbox} - Facade layer service between the http service layer and the component layer.
   * The GeneralSandbox contains some general endpoint calls and other stuffs.
   * @param navigation {NavigationService} - SLM navigation service. Contains some extra functionality, like goBack function.
   * @param notification {NotificationService} - Notification service. With the notify method you can display the messages.
   */
  constructor(private readonly fb: UntypedFormBuilder,
              private readonly cd: ChangeDetectorRef,
              private readonly generalSandbox: GeneralSandbox,
              private readonly navigation: NavigationService,
              private readonly notification: NotificationService) {}

  ngOnInit() {
    /**
     * Initialization of the reactive form with the subject of the report and a description of the report
     */
    this.form = this.fb.group({
      subject: ['', [Validators.required]],
      description: ['', [Validators.required, Validators.minLength(30)]]
    });

    document.addEventListener('paste', (event) => {});
  }

  /**
   *
   * @param files {File} - add selected or drag and droped files
   */
  public addFile(files: Array<File>) {
    this.files.push(...files);
    this.cd.detectChanges();
  }

  /**
   *
   * @param index {number} - remove selected file from the files array
   */
  public removeFile(index: number) {
    if (index >= 0 && index < this.files.length) {
      this.files.splice(index, 1);
      this.cd.detectChanges();
    }
  }

  public buttonClicked(event: FireEvent) {
    if (event.event === 'send') {
      this.saveReport();
    } else {
      this.navigation.goBack();
    }
  }

  /**
   *
   * Submit values
   */
  public saveReport() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.cd.detectChanges();
      return;
    }

    this.buttons[1].isLoading = true;
    this.cd.detectChanges();
    const {subject, description} = this.form.value;
    this.generalSandbox.sendReport(subject, description, this.files).subscribe(() => {
      this.notification.notify('BUG_REPORT.REPORTED', NotificationEnum.SUCCESS, NotificationTimes.INFINITE);
      this.files.length = 0;
      this.buttons[1].isLoading = false;
      this.form.reset();
    }, () => {
      this.buttons[1].isLoading = false;
      this.cd.detectChanges();
    });
  }

  public onPaste(event: ClipboardEvent): void {
    const fileList = event.clipboardData.files;
    if(fileList){
      const files = [];
      for (let index = 0; index < (fileList || []).length; ++index) {
        files.push(fileList.item(index));
      }

      this.addFile(files);
    }
  }

  public trackByIndex = (index, _) => index;
}
