import {InputConfig, SelectOption} from '../entities';
import {FileUploadModes, InvoicePaymentTypeEnum} from '../enums';
import {accountantStatus, fillingStatus, paymentStatuses} from './statuses.static';
import {utils} from '../functions';

export const paymentTypeOptions: Array<SelectOption> = [
  {
    value: InvoicePaymentTypeEnum.BANK_TRANSFER,
    label: 'INVOICE.PAYMENT.BANK-TRANSFER'
  },
  {
    value: InvoicePaymentTypeEnum.CREDIT_CARD,
    label: 'INVOICE.PAYMENT.CREDIT-CARD'
  },
  {
    value: InvoicePaymentTypeEnum.CASH,
    label: 'INVOICE.PAYMENT_TYPE.MONEY'
  },
  {
    value: InvoicePaymentTypeEnum.CHECK,
    label: 'INVOICE.PAYMENT.CHECK'
  }
];
export const paymentTypeOptionsLite: Array<SelectOption> = [
  {
    value: InvoicePaymentTypeEnum.BANK_TRANSFER,
    label: 'INVOICE.PAYMENT.BANK-TRANSFER'
  },
  {
    value: InvoicePaymentTypeEnum.CASH,
    label: 'INVOICE.PAYMENT_TYPE.MONEY'
  }
];

export const languageOptions: Array<SelectOption> = [
  {
    label: 'LBL_LANGUAGE.HU',
    value: 'hu'
  },
  {
    label: 'LBL_LANGUAGE.EN',
    value: 'en'
  },
  {
    label: 'LBL_LANGUAGE.DE',
    value: 'de'
  }
];

export const documentTypes: Array<SelectOption> = [
  {
    label: 'INVOICE.INVOICE',
    value: 'invoice'
  },
  {
    label: 'INVOICE.PROFORMA',
    value: 'proforma'
  }
];

export const invoiceTypeOptions: Array<SelectOption> = [
  {
    value: 'electronic',
    label: 'INVOICE_TYPE.ELECTRONIC'
  },
  {
    value: 'paper',
    label: 'INVOICE_TYPE.PAPER'
  },
  {
    value: 'prepaymentRequest',
    label: 'INVOICE_TYPE.REQUEST'
  }
];

export const fillingStatusSelect: InputConfig = {
  name: 'fillingStatus',
  testId: 'fillingStatusSelect',
  info: 'Random info button',
  inputType: 'select',
  label: 'INVOICE_GENERALS.FILLING_STATUS',
  options: fillingStatus
};

export const accountingStatusSelect: InputConfig = {
  name: 'accountingStatus',
  testId: 'accountStatusSelect',
  info: 'Random info button',
  inputType: 'select',
  label: 'INVOICE_GENERALS.ACCOUNTING_STATUS',
  options: accountantStatus
};

export const paymentStatusSelect = (isIncoming: boolean = false, onTransactionStatus: boolean = false): InputConfig => ({
  name: 'paymentStatus',
  info: 'Payment status select',
  inputType: 'select',
  label: 'INVOICE_GENERALS.PAYMENT_STATUS',
  testId: 'paymentStatus',
  options: paymentStatuses(isIncoming).filter(
    (status) =>
      utils.isString(status.value) &&
      ['payable', 'wait-approve', 'paid', 'overpaid', isIncoming && onTransactionStatus ? 'sent-to-bank' : null].includes(status.value)
  )
});

export const partnerTaxTypes: Array<SelectOption> = [
  {
    value: 1,
    label: 'LBL_PARTNERS.TAX_TYPES.CASHFLOW'
  },
  {
    value: 2,
    label: 'LBL_PARTNERS.TAX_TYPES.KATA'
  }
];

export const accrualOptions: Array<SelectOption> = [
  {
    value: 'daily',
    label: 'INVOICE.ACCRUAL_TYPE.DAILY'
  },
  {
    value: 'monthly',
    label: 'INVOICE.ACCRUAL_TYPE.MONTHLY'
  },
  {
    value: 'yearly',
    label: 'INVOICE.ACCRUAL_TYPE.YEARLY'
  }
];

export const fileUploadModeOptions: Array<SelectOption> = [
  {
    label: 'UPLOAD_MODE_OPTIONS.AS_PAGES',
    value: FileUploadModes.AS_SEPARATE_PAGES
  },
  {
    label: 'UPLOAD_MODE_OPTIONS.AS_FILE',
    value: FileUploadModes.AS_FILE
  }
];

export const processingTimeOptions: Array<SelectOption> = [
  {
    label: 'PROCESSING_TIME_TYPES.T0',
    value: 'T0'
  },
  {
    label: 'PROCESSING_TIME_TYPES.T1',
    value: 'T1'
  },
  {
    label: 'PROCESSING_TIME_TYPES.T2',
    value: 'T2'
  }
];
