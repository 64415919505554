import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonConfig, FireEvent, KeyValue } from '../../../global';

@Component({
  selector: 'slm-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss']
})
export class BottomBarComponent {
  public rightButtons: Array<ButtonConfig> = [];
  public leftButtons: Array<ButtonConfig> = [];

  @Input('buttons')
  public set setButtons(buttons: Array<ButtonConfig>) {
    this.rightButtons = (buttons || []).filter(
      (button) => !button.align || button.align === 'right'
    );
    this.leftButtons = (buttons || []).filter(
      (button) => button.align === 'left'
    );
  }

  @Input() public flexible = false;
  @Input() public disableButtons: Array<KeyValue> = [];
  @Output() public fireEvent = new EventEmitter<FireEvent>();

  public isDisabled(action: string) {
    return !!(this.disableButtons || []).find(
      (value) => value.key === action && !!value.value
    );
  }
}
