import {Routes} from '@angular/router';
import {
  AuthGuard,
  HasAccessGuard,
  HasAccessOrGuard,
  RightsEnum,
  RoutesEnum
} from '../modules/global';
import {LoginContainer} from './containers/login/login.container';
import {HomeContainer} from './containers/home/home.container';
import {DashboardComponent} from './containers/dashboard/dashboard.component';
import {ErrorContainer} from '../modules/shared';
import {RegistrationContainer} from './containers/registration/registration.container';
import {CompanyPendingContainer} from './containers/company-pending/company-pending.container';
import {ActivationContainer} from './containers/activation/activation.container';
import {ResetPasswordContainer} from './containers/reset-password/reset-password.container';
import {NewPasswordContainer} from './containers/new-password/new-password.container';
import {ExternalInvoiceContainer} from './containers/external-invoice/external-invoice.container';
import {BugReportContainer} from './containers/bug-report/bug-report.container';
import {CompanyActivationContainer} from './containers/company-activation/company-activation.container';
import {CompanyDebtContainer} from './containers/company-debt/company-debt.container';
import {Aggreg8PackageContainer} from '../modules/shared/containers/aggreg8-package/aggreg8-package.container';

export const appRoutes: Routes = [
  {
    path: RoutesEnum.EXTERNAL_INVOICE,
    component: ExternalInvoiceContainer
  },
  {
    path: RoutesEnum.ACCOUNT_ACTIVATION,
    component: ActivationContainer
  },
  {
    path: RoutesEnum.COMPANY_ACTIVATION,
    component: CompanyActivationContainer
  },
  {
    path: RoutesEnum.LOGIN,
    component: LoginContainer,
    canActivate: [AuthGuard],
    data: {unauthorizedMode: true}
  },
  {
    path: RoutesEnum.RESET_NEW_PASSWORD,
    component: NewPasswordContainer,
    canActivate: [AuthGuard],
    data: {unauthorizedMode: true}
  },
  {
    path: RoutesEnum.RESET_PASSWORD,
    component: ResetPasswordContainer,
    canActivate: [AuthGuard],
    data: {unauthorizedMode: true}
  },
  {
    path: RoutesEnum.REGISTRATION,
    component: RegistrationContainer,
    canActivate: [AuthGuard],
    data: {unauthorizedMode: true}
  },
  {
    path: RoutesEnum.HOME,
    component: HomeContainer,
    canActivate: [AuthGuard],
    children: [
      {path: RoutesEnum.HOME, component: DashboardComponent},
      {path: RoutesEnum.BUG_REPORT, component: BugReportContainer},
      {
        path: RoutesEnum.INVOICES,
        loadChildren: () => import('../modules/invoices/invoice.module').then(module => module.InvoiceModule)
      },
      {
        path: RoutesEnum.BANKING,
        loadChildren: () => import('../modules/banking/banking.module').then(module => module.BankingModule),
        canActivate: [HasAccessGuard],
        data: {rights: [RightsEnum.BANKING_OPERATIONS_READ]}
      },
      {
        path: RoutesEnum.FINANCES_REPORTS,
        loadChildren: () => import('../modules/finance-reports/finance-reports.module')
          .then(module => module.FinanceReportsModule),
        canActivate: [HasAccessGuard],
        data: {rights: [RightsEnum.DOCUMENT_FINANCIAL_SETTINGS_READ]}
      },
      {
        path: RoutesEnum.FINANCES_CASH_FLOW,
        loadChildren: () => import('../modules/finance-cashflow/finance-cashflow.module')
          .then(module => module.FinanceCashflowModule),
        canActivate: [HasAccessGuard],
        data: {rights: [RightsEnum.DOCUMENT_FINANCIAL_SETTINGS_READ]}
      },
      {
        path: RoutesEnum.FINANCES_OUTSTANDING,
        loadChildren: () => import('../modules/finance-outstanding/finance-outstanding.module')
          .then(module => module.FinanceOutstandingModule),
        canActivate: [HasAccessGuard],
        data: {rights: [RightsEnum.DOCUMENT_FINANCIAL_SETTINGS_READ]}
      },
      {
        path: RoutesEnum.SETTINGS_ACCOUNTING,
        loadChildren: () => import('../modules/accounting-settings/accounting-settings.module')
          .then(module => module.AccountingSettingsModule),
        canActivate: [HasAccessOrGuard],
        data: {
          rights: [
            RightsEnum.VAT_CODES_READ,
            RightsEnum.ACCOUNTING_PERIODS_READ,
            RightsEnum.CHART_OF_ACCOUNTS_READ,
            RightsEnum.TITLES_READ,
            RightsEnum.COST_CENTERS_READ,
            RightsEnum.PROGRAM_SETTINGS_READ,
            RightsEnum.AA_RULES_READ
          ]
        }
      },
      {
        path: RoutesEnum.SETTINGS_USERS,
        loadChildren: () => import('../modules/users/users.module')
          .then(module => module.UsersModule),
        canActivate: [HasAccessOrGuard]
      },
      {
        path: RoutesEnum.SETTINGS_PARTNERS,
        loadChildren: () => import('../modules/partner-settings/partner-settings.module')
          .then(module => module.PartnerSettingsModule),
        canActivate: [HasAccessGuard],
        data: {rights: [RightsEnum.CUSTOMERS_READ]}
      },
      {
        path: RoutesEnum.SETTINGS,
        loadChildren: () => import('../modules/settings/settings.module').then(module => module.SettingsModule)
      },
      {
        path: RoutesEnum.FAQ,
        loadChildren: () =>
          import('../modules/faq/faq.module').then(
            module => module.FaqModule
          )
      },
      {
        path: RoutesEnum.VIDEOS,
        loadChildren: () =>
          import('../modules/videos/videos.module').then(
            module => module.VideosModule
          )
      },
      {
        path: RoutesEnum.NO_ACCESS,
        component: ErrorContainer
      },
      {
        path: RoutesEnum.COMPANY_PROCESSING,
        component: CompanyPendingContainer
      },
      {
        path: RoutesEnum.COMPANY_DEBT,
        component: CompanyDebtContainer
      },
      {
        path: RoutesEnum.FILE_MANAGER,
        loadChildren: () => import('../modules/file-manager/file-manager.module').then(module => module.FileManagerModule),
        canActivate: [HasAccessOrGuard]
      },
      {
        path: RoutesEnum.AGGREG8_PACKAGE,
        component: Aggreg8PackageContainer
      },
      {
        path: '**',
        component: ErrorContainer
      }
    ]
  },
  {
    path: '**',
    component: ErrorContainer
  }
];
