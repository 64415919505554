import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { BankAccountPipe } from '../pipes';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  skip,
  takeUntil
} from 'rxjs/operators';

@Directive({
  selector: '[bankAccountFormatDirective]',
  providers: [BankAccountPipe]
})
export class BankAccountFormatDirective implements OnInit, OnDestroy {
  private el: HTMLInputElement;
  private changed$ = new BehaviorSubject<string>(null);
  private destroy$ = new Subject();

  @Input() disableValue = '';

  constructor(
    private elementRef: ElementRef,
    private accountPipe: BankAccountPipe
  ) {}

  ngOnInit() {
    this.el = this.elementRef.nativeElement;
    this.el.value = this.accountPipe.transform(this.el.value);
    this.changed$
      .pipe(
        skip(1),
        filter(
          (value) =>
            !this.disableValue || !(value || '').includes(this.disableValue)
        ),
        map((value) => value.replace(/\s/g, '')),
        distinctUntilChanged((prev, next) => {
          if (prev.length > next.length) {
            return next.length % 4 === 1;
          }
          return (
            prev.length === next.length ||
            (prev.length - next.length === 1 && next.length % 4 !== 1)
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        const cursorPos = this.el.selectionStart;
        const transformedValue = this.accountPipe.transform(value);
        this.el.value = transformedValue;
        if (cursorPos >= 0 && transformedValue.length > cursorPos) {
          const newCursorPos = cursorPos + (transformedValue.length  - cursorPos === 1 ? 1 : 0);
          this.el.setSelectionRange(newCursorPos, newCursorPos);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
  }

  @HostListener('focus', ['$event.target.value'])
  focus(value) {
    this.changed$.next(value);
  }

  @HostListener('keyup', ['$event.target.value'])
  change(value) {
    this.changed$.next(value);
  }

  @HostListener('blur', ['$event.target.value'])
  blur(value) {
    this.changed$.next(value);
  }
}
