<div
  cdk-scrollable
  class="table overflow-y-auto h-max-inherit"
>
  <ng-container *ngTemplateOutlet="table"></ng-container>
</div>

<ng-template #table>
  <table
    @stagger
    mat-table
    matSort
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    [matSortDisableClear]="disableClearSort"
    [matSortActive]="sort?.attribute"
    [matSortDisabled]="loading"
    [matSortDirection]="sort?.order"
    [dataSource]="loading ? null : dataSource"
    (matSortChange)="sortChanged($event)"
  >
    <ng-container *ngFor="let column of config">
      <ng-container [matColumnDef]="column.attributeName">
        <!-- Checkbox Column -->
        <ng-container *ngIf="columnTypes.CHECKBOX === column.columnType">
          <th mat-header-cell *matHeaderCellDef class="table__column--checkbox">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
              [disabled]="masterCheckboxDisabled"
              data-test-id="masterTableCheckbox"
              color="primary"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" class="table__column--checkbox">
            <mat-checkbox
              *ngIf="(!row.checkboxDisabled ||
                (!!row.checkboxDisabled && !column.hideIfDisabled)) ||
                (!!column.hideIfDisabled && !!row.checkboxCheckedAndDisabled)"
              (click)="$event.stopPropagation()"
              (change)="selectionChanged($event, row)"
              [checked]="selection.isSelected(row) || !!row.checkboxCheckedAndDisabled"
              [aria-label]="checkboxLabel(row)"
              [disabled]="row.checkboxDisabled || !!row.checkboxCheckedAndDisabled"
              data-test-id="tableCheckbox"
              color="primary"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header=""
          [id]="column.attributeName"
          [start]="column.sortStart"
          [disabled]="column.sortDisabled"
          class="no-last-padding"
        >
          {{ column.label | translate }}
        </th>

        <td
          mat-cell
          *matCellDef="let element"
          [ngSwitch]="column.columnType"
          [class.table__row__cell--clickable]="!column.notClickable"
          (click)="!column.notClickable ? openItem(element) : null"
        >
          <!-- Date Column -->
          <slm-table-date
            *ngSwitchCase="columnTypes.DATE"
            [data]="element[column.attributeName]"
            [align]="column.align"
            [class.m-auto]="column.align === 'center'"
            [format]="column.dateFormat"
            [tooltip]="element['tooltipLabel-' + column.attributeName]"
            [style.color]="element.textColor"
          ></slm-table-date>

          <!-- Text Column -->
          <slm-table-text
            *ngSwitchCase="columnTypes.TEXT"
            [style.maxWidth]="column.maxWidth || '200px'"
            [data]="element[column.attributeName]"
            [align]="column.align"
            [class.m-auto]="column.align === 'center'"
            [tooltip]="element['tooltipLabel-' + column.attributeName]"
            [style.color]="element.textColor"
          ></slm-table-text>

          <!-- Text With Icon Column -->
          <slm-table-icon-text
            *ngSwitchCase="columnTypes.ICON_TEXT"
            [style.maxWidth]="column.maxWidth || '200px'"
            [data]="element[column.attributeName]"
            [align]="column.align"
            [class.m-auto]="column.align === 'center'"
            [tooltip]="element['tooltipLabel-' + column.attributeName]"
          ></slm-table-icon-text>

          <!-- User Column -->
          <slm-table-user
            *ngSwitchCase="columnTypes.USER"
            [data]="element[column.attributeName]"
            [align]="column.align"
            [class.m-auto]="column.align === 'center'"
            [tooltip]="element['tooltipLabel-' + column.attributeName]"
          ></slm-table-user>

          <!-- Menu Column -->
          <slm-table-menu
            *ngSwitchCase="columnTypes.MENU"
            [menu]="column.menuItems"
            [data]="element.originalTableData || element"
            (fireEvent)="menuEvent($event, element)"
          ></slm-table-menu>
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnNames; sticky: stickyHeader"></tr>
      <tr
        cdkDrag
        mat-row
        *matRowDef="let row; columns: columnNames"
        [cdkDragData]="row"
        class="table__row"
        [class.none]="selection.isSelected(row) && multiDrag"
        (dblclick)="doubleClick(row)"
        (cdkDragStarted)="dragStarted($event)"
        [cdkDragDisabled]="row.originalTableData?.fileType === 'back_folder'"
      >
        <div
          *cdkDragPreview
          class="grid-col-2-20-80 grid-responsive w-200px w-max-200 flex-items-center"
        >
          <ng-container
            *ngIf="!multiDrag else multiDragContainer"
          >
            <div>
              <slm-svg-icon
                [color]="row?.fileType.color"
                [icon]="row?.fileType.icon"
              ></slm-svg-icon>
            </div>
            <div
             class="txt-no-wrap txt-ellipsis"
            >
              {{row.originalTableData.name}}
            </div>
          </ng-container>
          <ng-template
            #multiDragContainer
          >
            <div>
              <slm-svg-icon
                [color]="colors.LIGHT_BLUE"
                [icon]="icons.FILE_COPY"
              ></slm-svg-icon>
            </div>
            <div
              class="txt-no-wrap txt-ellipsis"
            >
              {{'FILE_MANAGER.MORE_ITEM' | translate}}
            </div>
          </ng-template>
        </div>
        <div *cdkDragPlaceholder></div>

      </tr>
  </table>
</ng-template>

<slm-loader
  *ngIf="loading"
  class="m-auto mt-6 mb-6 block"
></slm-loader>
<div
  *ngIf="!loading && !(dataSource?.data || []).length"
  class="table__placeholder"
>
  <svg-icon
    class="table__placeholder__svg"
    src="/assets/pictures/illustrations/empty.svg"
    [applyClass]="true"
  ></svg-icon>
  <h3 class="table__placeholder__text">{{ placeholder | translate }}</h3>
</div>
