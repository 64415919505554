import {
  ButtonConfig,
  bytesToBeauty,
  FileManagerMode,
  FileManagerTableItems,
  FileTypeEnum,
  Icon,
  IconColors,
  SelectionButton,
  stringDateToTableDate,
  stringToTableText,
  TableAlign,
  TableColumn,
  TableColumnType,
  TableFilter,
  TableIconText
} from '../../../global';

export const newFolderButton: ButtonConfig = {
  type: 'button',
  icon: Icon.ADD_CIRCLE,
  action: 'add_folder',
  label: 'FILE_MANAGER.ADD_NEW_FOLDER',
  buttonStyle: 'light'
}

export const newFileButton: ButtonConfig = {
  type: 'button',
  icon: Icon.ADD_CIRCLE,
  action: 'add_file',
  label: 'LBL_UPLOAD_FILL.FILLING.UPLOAD',
  buttonStyle: 'light'
}

export const tableConfig = (mode: FileManagerMode, filterOutIds: Array<string>) : Array<TableColumn> => [
  {
    label: '',
    attributeName: 'selected',
    columnType: TableColumnType.CHECKBOX,
    notClickable: (item) => (item?.fileType === FileTypeEnum.BACK_FOLDER) ||
      (mode === 'windowed' && item?.fileType === FileTypeEnum.FOLDER) ||
      !!filterOutIds.find(filter => filter === item.id),
    hideIfDisabled: true,
    checkedAndDisabled: (item) => !!filterOutIds.find(filter => filter === item.id),
    align: TableAlign.CENTER
  },
  {
    label: '',
    attributeName: 'fileType',
    align: TableAlign.START,
    columnType: TableColumnType.ICON_TEXT,
    sortDisabled: true,
    transpile: (type: string): TableIconText => ({icon: getConfigIcon(type), label: '', color: IconColors.BLUE}),
    maxWidth: '30px',
  },
  {
    attributeName: 'name',
    label: 'FILE_MANAGER.NAME',
    align: TableAlign.START,
    columnType: TableColumnType.TEXT,
    sortDisabled: true,
    valueTooltip: true,
    transpile: stringToTableText
  },
  {
    attributeName: 'size',
    label: 'FILE_MANAGER.FILE_SIZE',
    align: TableAlign.CENTER,
    columnType: TableColumnType.TEXT,
    sortDisabled: true,
    transpile: bytesToBeauty
  },
  {
    attributeName: 'uploadDate',
    label: 'LBL_UPLOAD_FILL.LIST.UPLOAD_DATE',
    align: TableAlign.CENTER,
    columnType: TableColumnType.DATE,
    dateFormat: 'FORMAT.DATE_FULL',
    sortDisabled: true,
    valueTooltip: true,
    transpile: stringDateToTableDate
  },
  {
    align: TableAlign.START,
    columnType: TableColumnType.TEXT,
    label: 'LBL_UPLOAD_FILL.LIST.UPLOADER',
    attributeName: 'uploader',
    sortDisabled: true,
    tooltip: (item) => item?.uploader?.name,
    transpile: (uploader) => stringToTableText(uploader?.name)
  },
  {
    align: TableAlign.END,
    columnType: TableColumnType.MENU,
    attributeName: 'menu',
    label: null,
    sortDisabled: true,
    notClickable: true,
    menuItems: [
      {
        icon: Icon.CREATE,
        action: 'folderEdit',
        name: 'LBL_BTN.EDIT',
        rights: {},
        isEnabled: (item: FileManagerTableItems) => item?.fileType !== 'back_folder' && item?.type === 'folder' && item?.isEnabledToEdit,
        testId: 'folderEditButton'
      },
      {
        icon: Icon.DOWNLOAD,
        action: 'fileDownload',
        name: 'LBL_BTN.DOWNLOAD',
        rights: {},
        isEnabled: (item: FileManagerTableItems) => item?.type === 'file',
        testId: 'fileDownloadButton'
      },
      {
        icon: Icon.ATTACH_FILE,
        action: 'fileEdit',
        name: 'LBL_BTN.DATA_SHEET',
        rights: {},
        isEnabled: (item: FileManagerTableItems) => item?.type === 'file',
        testId: 'fileEditButton'
      },
      {
        icon: Icon.DELETE,
        action: 'fileDelete',
        name: 'LBL_BTN.DELETE',
        rights: {},
        isEnabled: (item: FileManagerTableItems) => item?.fileType !== 'back_folder' && item?.isEnabledToEdit,
        testId: 'fileDeleteButton'
      }
    ]
  }
];

const getConfigIcon = (type: string): Icon => {
  switch(type){
    case FileTypeEnum.FOLDER:
      return Icon.FOLDER;
    case FileTypeEnum.BACK_FOLDER:
      return Icon.ARROW_LEFT;
    case FileTypeEnum.DOC: case FileTypeEnum.DOCX: case FileTypeEnum.TXT:
      return Icon.FILE_COPY;
    case FileTypeEnum.PDF:
      return Icon.PICTURE_AS_PDF;
    case FileTypeEnum.XLSX: case FileTypeEnum.XLS:
      return Icon.TABLE;
    case FileTypeEnum.JPG: case FileTypeEnum.PNG: case FileTypeEnum.GIF: case FileTypeEnum.JPEG: case FileTypeEnum.BMP:
      return Icon.IMAGE;
    case FileTypeEnum.PPT: case FileTypeEnum.PPTX:
      return Icon.SHOW_CHART;
    default:
      return Icon.UNKNOWN_DOCUMENT;
  }
}

export const tableFilters: Array<TableFilter> = [
  {
    type: 'date-range',
    config: {
      name: 'uploadDate',
      label: 'LBL_UPLOAD_FILL.LIST.UPLOAD_DATE',
      testId: 'filterUploadDate'
    }
  },
  {
    type: 'select',
    chipName: (value) => tableFilters[1].config.options.find(item => +item.value === +value)?.label,
    config: {
      name: 'user',
      label: 'LBL_UPLOAD_FILL.LIST.UPLOADER',
      inputType:  'select',
      options: [],
      testId: 'filterUser'
    }
  }
];

export const tableSelectionButtons: Array<SelectionButton> = [
  {
    label: 'LBL_BTN.DELETE',
    action: 'deleteSelected',
    testId: 'deleteSelected'
  },
  {
    label: 'LBL_BTN.DOWNLOAD',
    action: 'downloadSelected',
    testId: 'downloadSelected'
  }
];
