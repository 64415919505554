import { CompanyItem, Division } from './company.entity';
import {RightsEnum, SupportedLanguages} from '../enums';

export interface User {
  id: number;
  partnerId: number;
  partner_access: string;
  picture?: string;
  firstName: string;
  lastName: string;
  email: string;
  language: SupportedLanguages;
  phone: string;
  activeCompanyId: number;
  companies: Array<CompanyItem>;
  rights: Array<RightsEnum>;
  lastNavUpdate: string;
  divisions: Array<Division>;
}

export interface UserCompanyChange {
  token: string;
  expiration?: number;
}
