import { Component, Input } from '@angular/core';
import { InputTypeEnum, Icons } from '../../../../global';
import { InputAbstract } from '../base/input.abstract';

@Component({
  selector: 'slm-text-input',
  templateUrl: './text-input.component.html',
  styles: [`
    :host {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
    }
  `]
})
export class TextInputComponent extends InputAbstract {
  @Input() public autoFocus = false;
  @Input() public type: InputTypeEnum | any = 'text';
  @Input() public buttonIcon = true;
  @Input() public enterEnabled = false;
  @Input() public prefixLabel = '';
  @Input() public prefixIcon: Icons | string = null;
  @Input() public decimals: string = null;
  @Input() public min: string = null;
  @Input() public max: string = null;
  @Input() public suffixIcon: Icons = null;
  @Input() public maxLength: number = null;
  @Input() public minLength: number = null;
  @Input() public suffixLabel = '';
  @Input() public suffixIconLabel = '';
  @Input() public hasClearButton = false;
  @Input() public upperCase = false;

  public clear(): void {
    if (this.control) {
      this.control.setValue('');
    }
    this.onClick('clear');
  }
}
