import {ColumnTableItem} from '../../../modules/global';

export const partnerTableConfig: Array<ColumnTableItem> = [
  {
    type: 'text',
    key: 'name',
    boldValue: true,
    value: 'INVOICE.ISSUER'
  },
  {
    type: 'text',
    boldValue: true,
    key: 'taxNumber',
    hideIfEmpty: true,
    value: 'INVOICE.TAX_NUMBER'
  },
  {
    type: 'text',
    key: 'address',
    boldValue: true,
    hideIfEmpty: true,
    value: 'LBL_COMPANY.ADDRESS'
  },
  {
    type: 'text',
    key: 'phone',
    hideIfEmpty: true,
    value: 'LBL_PROFILE.PHONE'
  },
  {
    type: 'text',
    key: 'email',
    hideIfEmpty: true,
    value: 'LBL_INPUT.LABEL.EMAIL'
  }
];

export const invoiceTableConfig: Array<ColumnTableItem> = [
  {
    type: 'text',
    key: 'invoiceNumber',
    boldValue: true,
    value: 'INVOICE_GENERALS.INVOICE_NUMBER'
  },
  {
    type: 'text',
    key: 'deadline',
    boldValue: true,
    value: 'INVOICE_GENERALS.DEADLINE'
  },
  {
    type: 'text',
    boldValue: true,
    key: 'paymentMode',
    hideIfEmpty: true,
    value: 'INVOICE_GENERALS.PAYMENT_MODE'
  }
];

export const paymentTableConfig: Array<ColumnTableItem> = [
  {
    type: 'text',
    boldValue: true,
    key: 'accountNumber',
    hideIfEmpty: true,
    value: 'INVOICE.ACCOUNT_NUMBER'
  },
  {
    type: 'text',
    key: 'swift',
    boldValue: true,
    hideIfEmpty: true,
    value: 'BANK_ACCOUNTS.SWIFT_CODE'
  },
  {
    type: 'text',
    key: 'iban',
    hideIfEmpty: true,
    value: 'BANK_ACCOUNTS.IBAN'
  }
];
