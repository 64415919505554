<slm-dialog-container
  [title]="data.title"
  [buttons]="buttons"
  class="w-max-dialog block"
  (fireEvent)="buttonEvent($event.event)"
>
  <slm-message *ngIf="notification$ | async as notification"
               [message]="notification.message | translate: {type: data.typeLabel | translate}"
               [type]="notification.type"
               [isInnerHtml]="notification.isInnerHtml"></slm-message>
  <div class="w-100 flex flex-end mt-2 mb-2">
    <slm-button text="EXAMPLE_FILE"
                buttonStyle="primary"
                [icon]="icons.DOWNLOAD"
                [loading]="exampleFileIsLoading"
                (click)="downloadTestFile()"></slm-button>
  </div>
  <slm-drag-and-drop
    [config]="{ width: '100%' }"
    iconSize="17%"
    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    supportedFiles="documents"
    class="pt-2 block h-min-100px"
    (fileError)="unauthorizedFileFormat($event)"
    (file)="selectedFile($event)"></slm-drag-and-drop>
</slm-dialog-container>
