import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {
  AuthSandbox,
  RoutesEnum,
  Icon,
  NavigationService,
  inputErrorTypes
} from '../../../modules/global';
import {catchError, debounceTime, map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {rootStoreActions} from '../../+state';

@Component({
  selector: 'slm-login',
  templateUrl: './login.container.html'
})
export class LoginContainer implements OnInit {
  public readonly routes = RoutesEnum;
  public readonly isLoading$ = new BehaviorSubject<boolean>(false);
  public emailQueryParams$: Observable<any>;

  public readonly inputErrors = [
    inputErrorTypes.required,
    inputErrorTypes.email
  ];
  public loginForm: UntypedFormGroup;
  protected errors: any;
  public passwordInputType: 'password' | 'text' = 'password';
  public passwordInputIcon = Icon.VISIBILITY;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly store: Store,
    private readonly router: NavigationService,
    private readonly authSandbox: AuthSandbox
  ) {}

  ngOnInit() {
    // Form generation
    this.store.dispatch(rootStoreActions.logoutAction());
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
    const emailControl = this.loginForm.get('email');
    this.emailQueryParams$ = emailControl.valueChanges.pipe(
      debounceTime(500),
      map(value => emailControl.valid ? ({email: value}) : null)
    );
  }

  public login(): void {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.invalid && this.isLoading$.getValue()) {
      return;
    }
    this.isLoading$.next(true);
    this.authSandbox
      .login(this.loginForm.value)
      .pipe(
        catchError((err) => {
          this.isLoading$.next(false);
          return throwError(err);
        })
      )
      .subscribe((navigated) => {
        this.isLoading$.next(false);
        if (!navigated) {
          this.router.navigate([RoutesEnum.HOME]);
        }
      });
  }

  public goToRegistration(): void {
    this.router.navigate([RoutesEnum.REGISTRATION]);
  }

  public passwordTypeChanged(): void {
    if ('password' === this.passwordInputType) {
      this.passwordInputIcon = Icon.VISIBILITY_OFF;
      this.passwordInputType = 'text';
    } else {
      this.passwordInputIcon = Icon.VISIBILITY;
      this.passwordInputType = 'password';
    }
  }
}
