import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {ButtonConfig, FireEvent, Icon, KeyValue} from '../../../global';
import {Observable} from 'rxjs';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {DataInvoiceImage} from '../../../invoices/entities/invoice-images.entity';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'slm-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss']
})
export class ImageListComponent {
  public readonly icons = Icon;
  private imageIsRotating = [];
  @Input() public imageFromUrl = true;
  @Input() public imageUrls: Array<DataInvoiceImage> = [];
  @Input() public imagePreviewData: string;
  @Input() public title = 'LBL_IMAGES.CONTAINER_TITLE';
  @Input() public placeholderTitle = 'LBL_IMAGES.PLACEHOLDER_TITLE';
  @Input() public placeholderDescription = 'LBL_IMAGES.PLACEHOLDER_DESCRIPTION';
  @Input() public showHeaderButtons = true;
  @Input() public isEditMode = false;
  @Input() public ocrBtnEnabled = false;
  @Input() public buttons: Array<ButtonConfig> = [];
  @Input() public runningOcr: Array<number> = [];
  @Input() public view: 'card' | 'list' = 'card';
  @Input() public hideAllButton = false;
  @Input() public rotateFunction: (id, rotation) => Observable<any>;

  @Output() public readonly fireEvent = new EventEmitter<FireEvent>();
  @Output() public readonly rotatedImage = new EventEmitter<number>();

  constructor(
    readonly sanitizer: DomSanitizer,
    private clipboard: Clipboard
  ) {
  }

  public isOcrRunning(id: number) {
    return (this.runningOcr || []).includes(+id);
  }

  public trackByValue = (item: KeyValue | any) => item.value;

  public rotate(id: number, isLeft: boolean) {
    if (this.rotateFunction && !this.imageIsRotating.includes(+id)) {
      this.imageIsRotating.push(+id);
      const index = this.imageUrls.findIndex((data => +data.value === +id));
      this.rotateFunction(+id, isLeft).subscribe(
        blob => {
          this.imageUrls[index].data = new Observable<SafeHtml>((observer) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); // convert blob to base64
            reader.onloadend = () => {
              observer.next(
                this.sanitizer.bypassSecurityTrustUrl(`${reader.result}`)
              ); // emit the base64 string result
              observer.complete();
            };
            this.rotatedImage.emit(id);
          });
          this.imageIsRotating.splice(this.imageIsRotating.indexOf(id), 1);
        },
        () => this.imageIsRotating.splice(this.imageIsRotating.indexOf(id), 1)
      );
    }
  }

  public isImageRotating = (id): boolean => this.imageIsRotating.includes(+id);

  copyFileNameToClipboard(fileName: string) {
    this.clipboard.copy(fileName);
  }
}
