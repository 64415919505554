import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {PagerTitle} from '../../../global';

@Component({
  selector: 'slm-tabs',
  template: `
    <nav mat-tab-nav-bar #slmMatTabs [tabPanel]="tabPanel">
      <ng-container *ngFor="let tab of tabs; index as index">
        <ng-container *slmAccess="tab.rights">
          <a
            mat-tab-link
            [attr.data-test-id]="tab.testId"
            (click)="goTo.emit(tab.route)"
            (keyup.enter)="goTo.emit(tab.route)"
            [active]="(tab.route === selected) || (index === 0 && !selected)"
          >
            {{ tab.title | translate }}
          </a>
        </ng-container>
      </ng-container>
    </nav>
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>

  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent {
  @Input() tabs: Array<PagerTitle> = [];
  @Input() selected = '';
  @Output() goTo = new EventEmitter<string>();
}
