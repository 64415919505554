import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private readonly DIALOG_PANEL_CLASS = 'slm-dialog';

  private modalInstance: MatDialogRef<any> | null = null;

  constructor(private readonly dialog: MatDialog) {}

  public open(
    popupComponent: any,
    params?: { [key: string]: any },
    disableDefaultClass: boolean | string = false
  ): MatDialogRef<any> {
    return this.modalInstance &&
      this.modalInstance.componentInstance instanceof popupComponent
      ? this.modalInstance
      : this.openNewModal(popupComponent, params, disableDefaultClass);
  }

  public close(reason?: string) {
    if (!this.modalInstance) {
      return;
    }

    this.modalInstance.close(reason);
    this.modalInstance = null;
  }

  private openNewModal(
    content: any,
    params?: { [key: string]: any },
    disableDefaultClass: boolean | string = false
  ): MatDialogRef<any> {
    const disableClass = typeof disableDefaultClass === 'boolean' && disableDefaultClass;
    const classes = disableClass ? [] : [(typeof disableDefaultClass === 'string' ? disableDefaultClass : null) || this.DIALOG_PANEL_CLASS];
    if (params && params.classes && Array.isArray(params.classes)) {
      classes.push(...params.classes);
    }
    const config = { panelClass: classes, ...params };
    const modalRef = (this.modalInstance = this.dialog.open(content, config));
    modalRef.afterClosed().subscribe(
      () => (this.modalInstance = null),
      () => (this.modalInstance = null)
    );

    return modalRef;
  }
}
