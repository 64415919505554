import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {debounceTime, filter, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {
  Currency,
  downloadPdf,
  ExternalInvoice,
  GlobalInvoiceSandbox,
  InvoicePaymentTypeEnum, isValidJwt
} from '../../../modules/global';
import {TranslateService} from '@ngx-translate/core';
import {BankAccountPipe} from '../../../modules/shared';
import {invoiceTableConfig, partnerTableConfig, paymentTableConfig} from './external-invoice.config';

@Component({
  selector: 'slm-external-invoice',
  templateUrl: './external-invoice.container.html',
  providers: [BankAccountPipe]
})
export class ExternalInvoiceContainer implements OnInit {

  public readonly partnerTable = partnerTableConfig;
  public readonly invoiceTable = invoiceTableConfig;
  public readonly paymentTable = paymentTableConfig;

  private data$: Observable<ExternalInvoice>;
  public partnerData$: Observable<any>;
  public invoiceData$: Observable<any>;
  public paymentData$: Observable<any>;
  public status$: Observable<'expired' | 'valid'>;
  public amount$: Observable<{ amount: number; currency: Currency; deadline: Date; unpaidValue: number; }>;
  public buttonIsLoading = false;
  public buttonLabel$: Observable<string>;
  private fileName = '';

  constructor(private readonly route: ActivatedRoute,
              private readonly translation: TranslateService,
              private readonly bankAccountPipe: BankAccountPipe,
              private readonly globalInvoiceSandbox: GlobalInvoiceSandbox) {
  }

  ngOnInit() {
    this.data$ = this.route.params.pipe(
      filter(params => !!params.jwt),
      switchMap(params => isValidJwt(params.jwt) ? of(null) : this.globalInvoiceSandbox.publicInvoice(params.jwt)),
      shareReplay({refCount: true, bufferSize: 3})
    );

    this.status$ = this.data$.pipe(map((value): 'expired' | 'valid' => !value ? 'expired' : 'valid'));

    this.partnerData$ = this.data$.pipe(
      debounceTime(250),
      filter(value => !!value),
      map(data => data.partner)
    );

    this.invoiceData$ = this.data$.pipe(
      debounceTime(250),
      filter(value => !!value),
      tap(value => this.fileName = `${value.partner.name}-${value.document.invoiceNumber}`.replace(/\s/g, '_')),
      map(data => ({
        invoiceNumber: data.document.invoiceNumber,
        paymentMode: !!data.document.paymentType
          ? this.translation.instant(
          `INVOICE.PAYMENT.${(
            data.document.paymentType || ''
          ).toUpperCase()}`
          ) +
          (data.document.paymentType ===
          InvoicePaymentTypeEnum.BANK_TRANSFER &&
          data.bank
            ? ` - ${data.bank.name}`
            : '')
          : null,
        deadline: data.document.deadline
      }))
    );

    this.amount$ = this.data$.pipe(
      debounceTime(250),
      filter(value => !!value),
      map(value => ({
          deadline: new Date(value.downloadDeadline),
          amount: value.document.grossValue,
          currency: value.currency,
          unpaidValue: value.document?.unpaidValue || null
        })
      )
    );

    this.paymentData$ = this.data$.pipe(
      debounceTime(250),
      filter(value => !!value && value.document.paymentType === InvoicePaymentTypeEnum.BANK_TRANSFER),
      map(value => ({
        accountNumber: this.bankAccountPipe.transform(value.document.bankAccount),
        swift: value?.bank?.swift,
        iban: this.bankAccountPipe.transform(value.document.iban)
      }))
    );

    this.buttonLabel$ = this.data$.pipe(
      debounceTime(250),
      filter(value => !!value),
      map(value => value.document?.documentType === 'invoice'? 'PUBLIC.DOWNLOAD_INVOICE': 'PUBLIC.DOWNLOAD')
    );
  }

  public downloadInvoice() {
    if (this.buttonIsLoading) {
      return;
    }
    this.buttonIsLoading = true;
    this.globalInvoiceSandbox.downloadPublicInvoice(this.route.snapshot.params.jwt).subscribe(
      blob => {
        this.buttonIsLoading = false;
        downloadPdf(blob, `${this.fileName ?? 'SLM_INVOICE'}-${new Date().getTime()}`);
      },
      () => this.buttonIsLoading = false
    );
  }
}
