import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {globalActions, globalSelectors} from '../+states';
import {distinctUntilChanged, filter, map, tap} from 'rxjs/operators';
import {UserService} from '../services';
import {InvoiceType, RightsEnum} from '../enums';
import {PaginatorNew, TableOrder} from "../entities";

@Injectable({
  providedIn: 'root'
})
export class UserSandbox {
  public readonly user$ = this.store.select(globalSelectors.getUser());
  public readonly rights$ = this.store.select(globalSelectors.getUserRights());
  public readonly userId$ = this.store.select(globalSelectors.getUserId());

  constructor(
    private readonly store: Store,
    private readonly userService: UserService
  ) {
  }

  public readonly reloadUser = () => this.store.dispatch(globalActions.reloadUser());

  public readonly changeLanguage = (language: string) =>
    this.userService.changeLanguage(language)
      .pipe(tap(() => this.store.dispatch(globalActions.setLanguage({language}))));

  public readonly hasAccess = (
    requiredRightList: Array<string> = [],
    filterNull: boolean = false
  ) =>
    this.rights$.pipe(
      filter((rights) => !filterNull || !!rights),
      map(
        (userRights) =>
          !userRights?.length ||
          !requiredRightList?.length ||
          userRights.filter((right) => requiredRightList.includes(right))
            .length === requiredRightList.length
      ),
      distinctUntilChanged()
    );

  public readonly hasAccessExcept = (
    requiredRightList: Array<string> = [],
    filterNull: boolean = false
  ) =>
    this.rights$.pipe(
      filter((rights: Array<RightsEnum>) => !filterNull || !!rights),
      map(
        (userRights) =>
          !userRights?.length ||
          !requiredRightList?.length ||
          !userRights.filter((right) => requiredRightList.includes(right))
            .length
      ),
      distinctUntilChanged()
    );

  public readonly hasAccessOr = (
    requiredRightList: Array<string> = [],
    filterNull: boolean = false
  ) =>
    this.rights$.pipe(
      filter((rights) => !filterNull || !!rights),
      map(
        (userRights): boolean | number =>
          !userRights ||
          !requiredRightList?.length ||
          !userRights?.length ||
          userRights.filter((right) => requiredRightList.includes(right)).length
      ),
      distinctUntilChanged()
    );

  public readonly updatePassword = (
    currentPassword: string,
    newPassword: string
  ) => this.userService.updatePassword(currentPassword, newPassword);

  public readonly updateUser = (user: any) => this.userService.updateUser(user);

  public readonly getViewDetails = (target: InvoiceType) =>
    this.store.select(globalSelectors.getViewDetails(target)).pipe(
      filter(details => {
        if (!details) {
          this.store.dispatch(globalActions.getInvoiceListSettings({target}));
        }
        return !!details;
      })
    );

  public readonly setViewDetails = (target: InvoiceType, id: number, settings: Array<string>) =>
    this.userService.setViewDetails(target, id).pipe(
      tap(() => {
        this.store.dispatch(globalActions.setInvoiceListSettings({target, settings}));
      })
    );

  public readonly getDivisions = (page: PaginatorNew, order: TableOrder, search) => this.userService.getDivisions(page, order, search);

  public readonly getDivisionUsers = (divisionId: number) => this.userService.getDivisionUsers(divisionId);

  public readonly modifyDivision = (divisionId: number, divisionName: string) => this.userService.modifyDivision(divisionId, divisionName);

  public readonly createNewDivision = (divisionName: string) => this.userService.createNewDivision(divisionName);

  public readonly deleteDivision = (divisionIds: Array<string>) => this.userService.deleteDivision(divisionIds);
}
