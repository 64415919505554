<div *ngIf="data && data.counter; else invalid" class="w-fit-content flex flex-items-center flex-start">
  <slm-svg-icon
    [color]="data.iconColor"
    [icon]="data.icon"
    [matBadge]="data.counter"
    [matTooltip]="tooltip || null"
    matBadgeColor="accent"
  ></slm-svg-icon>
</div>

<ng-template #invalid>
  <span class="w-100 block txt-center-i">--</span>
</ng-template>
