import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  Icons,
  ButtonHeightEnum,
  ButtonStyleEnum,
  ButtonTypeEnum,
  ButtonConfig
} from '../../../../global';

@Component({
  selector: 'slm-button',
  template: `
    <button
      *ngIf="!url"
      matRipple
      [attr.data-test-id]="testId"
      [class]="
        'slm-btn slm-btn--' +
        buttonStyle +
        ' slm-btn--height--' +
        (height || 'normal')
      "
      [class.slm-btn--icon]="!!icon"
      [class.slm-btn--no-wrap]="noWrap"
      [class.slm-btn--left-padding]="loading"
      [disabled]="disabled"
      [matTooltip]="tooltip"
    >
      <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>
    <a
      *ngIf="!!url && !externalLink"
      matRipple
      [attr.data-test-id]="testId"
      [target]="target"
      [routerLink]="url"
      [class]="
        'slm-btn slm-btn--' +
        buttonStyle +
        ' slm-btn--height--' +
        (height || 'normal')
      "
      [class.slm-btn--icon]="!!icon"
      [class.slm-btn--no-wrap]="noWrap"
      [class.slm-btn--left-padding]="loading"
    >
      <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </a>
    <a
      *ngIf="!!url && externalLink"
      matRipple
      [attr.data-test-id]="testId"
      [target]="target"
      [class]="
        'slm-btn slm-btn--' +
        buttonStyle +
        ' slm-btn--height--' +
        (height || 'normal')
      "
      [class.slm-btn--icon]="!!icon"
      [class.slm-btn--no-wrap]="noWrap"
      [class.slm-btn--left-padding]="loading"
      [href]="url"
    >
      <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </a>
    <ng-template #buttonContent>
      <slm-svg-icon
        *ngIf="icon && !loading"
        [icon]="icon"
        size="100%"
      ></slm-svg-icon>
      <slm-loader
        *ngIf="loading"
        diameter="22"
        class="slm-btn__loading"
      ></slm-loader>
      {{ text | translate }}
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() public type: ButtonTypeEnum = 'button';
  @Input() public buttonStyle: ButtonStyleEnum = 'primary';
  @Input() public text = '';
  @Input() public icon: Icons | string = null;
  @Input() public loading = false;
  @Input() public disabled = false;
  @Input() public noWrap = false;
  @Input() public height: ButtonHeightEnum = 'normal';
  @Input() public url = '';
  @Input() public externalLink = false;
  @Input() public target = '';
  @Input() public testId =  '';
  @Input() public tooltip =  null;

  @Input('config')
  public set setButtonConfig(config: ButtonConfig) {
    if (config) {
      this.buttonStyle = config.buttonStyle || 'primary';
      this.text = config.label || '';
      this.icon = config.icon || null;
      this.noWrap = config.noWrap;
      this.loading = config.isLoading === null ? this.loading : config.isLoading;
      this.height = config.height || 'normal';
      this.url = config.url;
      this.type = config.type || 'button';
      this.target = config.target;
      this.testId = config.testId || this.testId || '';
      this.externalLink = config.externalLink;
      this.disabled = config?.disabled;
      this.tooltip = config?.tooltip;
    }
  }
}
