import {Currency} from './currency.entity';
import {
  AccountingStatus,
  FillingStatus,
  IncomingPaymentStatus,
  InvoiceFormat,
  InvoicePaymentType, InvoiceTypeEnum,
  OutgoingPaymentStatus, PartnerActiveStatusEnum
} from '../enums';
import {AccountingTemplate} from './accounting-template.entity';
import {Customer} from './customer.entity';
import {InternalMessage} from './message.entity';
import {PartnerLite} from './partner.entity';
import {SelectOption} from './input.entity';
import {Base} from './general.entity';
import {File} from './file-manager.entity';

export interface Invoice {
  bruttoValue: string | number;
  netValue: string | number;
  vatValue: string | number;
  paidValue: string | number;
  bankAccountDetails?: InvoicePartnerBankAccount;
  bankAccount?: string;
  bankAccountId?: number;
  vendorLedgerNumber?: SelectOption;
  completeDate: string;
  referenceNotice: string;
  creationDate: string;
  accountingCompletionDate?: string;
  dateOf65M?: string;
  currency: Currency;
  format: InvoiceFormat;
  prepaymentData?: BaseInvoiceData;
  id: string | number;
  isPaid: boolean;
  note: string;
  navValid?: boolean;
  number: string;
  language?: string;
  paymentDeadline: string;
  paymentType: InvoicePaymentType;
  registrationNumber: string;
  type: InvoiceTypeEnum.INCOMING | InvoiceTypeEnum.OUTGOING;
  exchangeRate?: string | number;
  defferedVat?: boolean;
  defferedVatDate?: string;
  defferedVatExchangeRate?: string | number;
  payments?: Array<InvoicePayments>;
  title?: Base;
  titleName?: string;
  other?: string;
  createdInvoice?: boolean;
  storno?: BaseInvoiceData;
  stornoStatus?: StornoStatusType;
  savedInTransaction?: number;
  filedWithOcr?: boolean;
  userDivision?: Base;
}

export interface InvoiceTableItem {
  id: string | number;
  partner: PartnerLite;
  invoiceNumber?: string;
  paymentDeadline?: string;
  completionDate?: string;
  isOutgoing?: boolean;
  accountingStatus?: AccountingStatus;
  fillingStatus?: FillingStatus;
  paymentStatus?: IncomingPaymentStatus | OutgoingPaymentStatus;
  currency?: Currency;
  grossValue?: string | number;
  stornoEnabled?: boolean;
  paidValue?: number;
  leftover?: number;
  messageCount?: number;
  uploadSuccess?: boolean;
  slmBilling?: boolean;
  format?: InvoiceFormat;
  createdInvoice?: boolean;
  netValue?: string | number;
  vatValue?: string | number;
  exchangeRate?: string | number;
  registrationNumber?: string;
  templateName?: string;
  creationDate?: string;
  recordingDate?: string;
  referenceNotice?: string;
  documentType?: DocumentType;
  paymentType?: InvoicePaymentType;
  lastMessage?: string;
  lastPayment?: number;
  lastPaymentDetails?: InvoicePayments;
  savedInTransaction?: number;
  filedWithOcr?: boolean;
  divisionName?: string;
}

export interface OutgoingInvoiceTableItem extends InvoiceTableItem {
  slmBilling: boolean;
}

export interface InvoicePayments {
  id?: number;
  date: string;
  grossValue: string | number;
  fullName: string;
  currency?: Currency;
  type?: 'cash' | 'prepayment' | 'money' | 'compensation' | 'cashDiscount' | 'other' | 'proforma' | 'bank-card';
  prepayment?: BaseInvoiceData;
  note?: string;
  percent?: number;
}

export interface InvoiceDetailed {
  accountingStatus: AccountingStatus;
  fillingStatus: FillingStatus;
  accountingTemplate: Array<AccountingTemplate>;
  customer: Customer;
  dataImages: Array<ImagesData>;
  id: string;
  internalMessages: Array<InternalMessage>;
  invoice: Invoice;
  partner: PartnerLite;
  paymentStatus: IncomingPaymentStatus;
  stornoEnabled: boolean;
  uploadSuccess: boolean;
  slmBilling?: boolean;
  createdInvoice?: boolean;
  format?: InvoiceFormat;
  attachments?: Array<File>
}

export interface ExternalInvoice {
  partner: {
    name: string;
    taxNumber: string;
    address: string;
    phone: string;
    email: string;
  };
  document: {
    invoiceNumber: string;
    grossValue: number;
    paymentType: InvoicePaymentType;
    bankAccount: string;
    iban: string;
    documentType: DocumentType;
    deadline: string;
    unpaidValue: number;
  };
  bank: {
    name: string;
    swift: string;
  };
  currency: Currency;
  downloadDeadline: string;
}

export interface BaseInvoiceData {
  id: number;
  documentNumber: string;
}

export interface ImagesDataBase {
  fileName: string;
}

export interface ImagesData extends ImagesDataBase {
  id: number;
}

export interface InvoicePartnerBankAccount {
  name: string;
  id: number;
  accountNumber:  string;
  status: PartnerActiveStatusEnum.ACTIVE | PartnerActiveStatusEnum.INACTIVE;
}

export type DocumentType = 'invoice' | 'prepayment';
export type StornoStatusType = 'credited' | 'crediting' | null;
