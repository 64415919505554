import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {
  ButtonConfig,
  SelectOption,
  User,
  CustomValidators,
  NotificationService,
  NotificationEnum,
  buttonsStaticConfig,
  inputErrorTypes, Company, DragAndDropError, UserSandbox
} from '../../../modules/global';
import {map} from 'rxjs/operators';

export interface ProfileDialogConfig {
  user: User;
  companies$: Observable<Array<Company>>;
  save: (any) => Observable<any>;
}

@Component({
  selector: 'slm-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss']
})
export class ProfileDialogComponent implements OnInit, OnDestroy {
  public readonly buttons: Array<ButtonConfig> = [
    buttonsStaticConfig.cancel,
    {...buttonsStaticConfig.saveData}
  ];
  public readonly errors = [
    inputErrorTypes.required,
    inputErrorTypes.phone
  ];

  private readonly destroy$ = new Subject();
  public companySelect$: Observable<Array<SelectOption>>;

  public profileForm: UntypedFormGroup;

  constructor(
    public readonly dialogRef: MatDialogRef<ProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProfileDialogConfig,
    private readonly fb: UntypedFormBuilder,
    private readonly notificationService: NotificationService,
    private userSandbox: UserSandbox
  ) {
  }

  ngOnInit() {
    this.companySelect$ = this.data.companies$.pipe(
      map(companies => companies
        .filter(company => company.defaultGroup)
        .map(company => ({
          label: company.partnerName,
          value: +company.partnerId
        } as SelectOption)))
    );
    this.profileForm = this.fb.group({
      userName: [
        `${this.data.user?.firstName || ''} ${this.data.user?.lastName || ''}`,
        [Validators.required]
      ],
      phone: [this.data.user?.phone || '', [CustomValidators.phone]],
      partnerId: [+this.data.user?.partnerId || '', [Validators.required]]
    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public buttonEvent(event: string) {
    if (event === 'save') {
      if (this.profileForm.invalid || this.buttons[1].isLoading) {
        this.profileForm.markAllAsTouched();
        return;
      }
      this.buttons[1].isLoading = true;
      this.dialogRef.disableClose = true;
      this.data.save(this.profileForm.value).subscribe(
        () => {
          this.dialogRef.close();
          this.userSandbox.reloadUser();
        },
        () => {
          this.buttons[1].isLoading = false;
          this.dialogRef.disableClose = false;
        }
      );
    } else {
      this.dialogRef.close();
    }
  }

  public uploadError(error: DragAndDropError): void {
    this.notificationService.notify(error.message, NotificationEnum.ERROR);
  }

  public fileSelected(file): void {
  }
}
