<div *ngIf="layoutStyle === 'card'; else normalStyle"
     class="w-max-container m-auto grid-col-2-50 grid-responsive"
>
  <div class="card card--no-padding">
    <div class="card__header">
      <h4 class="card__header__title" translate="LBL_PARTNERS.EDIT_SUBTITLE"></h4>
    </div>
    <div class="card__content">
      <div
        *ngIf="!isUpdate || id; else loading"
        cdk-scrollable
        class="block pb-12 overflow-y-auto h-max-inherit"
      >
        <ng-container *ngTemplateOutlet="dataFormContainer"></ng-container>
        <ng-container *ngTemplateOutlet="accountingData"></ng-container>

        <slm-partner-bank-account (formArray)="setBankAccountFormArray($event)"
                                  [accounts]="bankAccounts$ | async"
                                  (deleted)="deleteBankAccount($event)"></slm-partner-bank-account>
      </div>
    </div>
  </div>
  <div class="card card--no-padding">
    <div class="card__header">
      <h4 class="card__header__title">
        {{'LBL_PARTNERS.CONTACT_DATA_TITLE' | translate}}
        <span>({{'LBL_FILL_NOT_REQUIRED' | translate}})</span>
      </h4>
    </div>
    <div
      *ngIf="!isUpdate || id; else loading"
      cdk-scrollable
      class="card__content block pb-12 overflow-y-auto h-max-inherit"
    >
      <ng-container *ngTemplateOutlet="contactData"></ng-container>
    </div>
  </div>
</div>

<ng-template #normalStyle>
  <div class="w-max-container m-auto grid-responsive">
    <div>
      <ng-container
        *ngIf="!isUpdate || id; else loading"
      >
        <ng-container *ngTemplateOutlet="dataFormContainer"></ng-container>
      </ng-container>
    </div>
    <div>
      <h5 class="txt-start-i pb-1 pt-3 mb-2 ml-3-i txt-medium border-bottom">
        {{'LBL_PARTNERS.CONTACT_DATA_TITLE' | translate}}
        <span>({{'LBL_FILL_NOT_REQUIRED' | translate}})</span>
      </h5>
      <ng-container
        *ngIf="!isUpdate || id; else loading"
      >
        <ng-container *ngTemplateOutlet="contactData"></ng-container>

        <slm-partner-bank-account (formArray)="setBankAccountFormArray($event)"
                                  [accounts]="bankAccounts$ | async"
                                  (deleted)="deleteBankAccount($event)"></slm-partner-bank-account>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #autoComplete>
  <slm-autocomplete-input
    label="LBL_PARTNERS.PARTNER_NAME"
    [errors]="inputErrors"
    [showLoader]="showLoader$ | async"
    searchLengthRequirement="3"
    [control]="partnerForm.get('partnerName')"
    [options]="partnersByName$ | async"
    testId="partner-name"
  ></slm-autocomplete-input>

  <slm-select-input
    label="LBL_PARTNERS.LEGAL_CLASS"
    [errors]="inputErrors"
    [control]="partnerForm.get('legalClass')"
    [options]="legalClassSelectValues"
    testId="partner-legal-class"
  ></slm-select-input>

  <slm-autocomplete-input
    label="INVOICE.TAX_NUMBER"
    placeholder="LBL_PARTNERS.EDIT.EXAMPLES.TAX_NUMBER"
    [errors]="inputErrors"
    searchLengthRequirement="3"
    [control]="partnerForm.get('vatNumber')"
    [options]="partnersByNumber$ | async"
    (leave)="selectRightCountry()"
    testId="partner-vat-number"
  ></slm-autocomplete-input>
</ng-template>

<ng-template #dataFormContainer>
  <form [formGroup]="partnerForm" class="p-3">
    <h5 class="txt-start-i pb-1 mt-2 mb-2 txt-medium border-bottom">
      {{'LBL_BASE_DATA' | translate}}
    </h5>
    <div class="grid-col-2-50 grid-responsive mb-2">
      <ng-container *ngIf="isUpdate; else autoComplete">
        <slm-text-input
          label="LBL_PARTNERS.PARTNER_NAME"
          [errors]="inputErrors"
          [control]="partnerForm.get('partnerName')"
          testId="partner-partner-name"
        ></slm-text-input>

        <slm-select-input
          label="LBL_PARTNERS.LEGAL_CLASS"
          [errors]="inputErrors"
          [control]="partnerForm.get('legalClass')"
          [options]="legalClassSelectValues"
          testId="partner-legal-class"
        ></slm-select-input>

        <slm-text-input
          label="INVOICE.TAX_NUMBER"
          placeholder="LBL_PARTNERS.EDIT.EXAMPLES.TAX_NUMBER"
          [errors]="inputErrors"
          [control]="partnerForm.get('vatNumber')"
          (leave)="selectRightCountry()"
          testId="partner-vat-number"
        ></slm-text-input>
      </ng-container>
      <slm-text-input
        label="LBL_PARTNERS.DATA.EU_TAX_NUMBER"
        placeholder="LBL_PARTNERS.EDIT.EXAMPLES.EU_TAX_NUMBER"
        [errors]="inputErrors"
        (keydown.space)="$event.preventDefault()"
        [control]="partnerForm.get('euTaxNumber')"
        (leave)="selectRightCountry()"
        testId="partner-eu-vat"
      ></slm-text-input>
      <slm-text-input
        label="LBL_PARTNERS.DATA.GROUP_VAT_NUMBER"
        placeholder="LBL_PARTNERS.EDIT.EXAMPLES.GROUP_VAT_NUMBER"
        [control]="partnerForm.get('groupVatNumber')"
        [errors]="inputErrors"
        testId="partner-group-vat"
      ></slm-text-input>
      <slm-text-input
        *ngIf="showThirdTax$ | async"
        label="LBL_PARTNERS.DATA.THIRD_PARTY_VAT_NUMBER"
        placeholder="LBL_PARTNERS.EDIT.EXAMPLES.THIRD_PARTY_VAT_NUMBER"
        [control]="partnerForm.get('thirdPartyVat')"
        [errors]="inputErrors"
        testId="partner-third-party-vat"
      ></slm-text-input>
      <slm-toggle
        [control]="partnerForm.get('taxType')"
        class="w-min-250px block"
        label="LBL_PARTNERS.TAX_TYPE_CASHFLOW"
        testId="partner-tax-type"
      ></slm-toggle>
    </div>
    <ng-container *ngIf="isUpdate">
      <ng-container *ngIf="partnerStatus$ | async as status">
        <h5 class="txt-start-i pb-1 mt-2 mb-2 txt-medium border-bottom"
            translate="LBL_PARTNERS.OPERATIONAL_STATUS"></h5>
        <div class="flex flex-column flex-items-end">
          <slm-partner-negative-info [status]="status" class="w-100"></slm-partner-negative-info>
          <slm-button buttonStyle="secondary-blue"
                      (click)="billzoneRefresh()"
                      [loading]="partnerBillzoneRefresh"
                      testId="partner-status-refresh"
                      text="LBL_PARTNERS.STATUS_REFRESH" [icon]="icons.REFRESH"
                      class="w-fit-content mb-4 block"></slm-button>
        </div>
      </ng-container>
      <h5
        class="txt-start-i pb-1 mt-2 mb-2 txt-medium border-bottom" translate="LBL_PARTNERS.ADDRESS"></h5>
    </ng-container>
    <slm-message type="info"
                 class="mb-2 block"
                 message="LBL_PARTNERS.TRANSACTION_NOT_WORKING_WITHOUT_COUNTRY"></slm-message>
    <div class="grid-col-2-50 grid-responsive">

      <slm-select-autocomplete-input
        label="LBL_PARTNERS.EDIT.COUNTRY"
        [errors]="inputErrors"
        [control]="partnerForm.get('country')"
        testId="partner-country"
        clearButton="true"
        [options]="countries$ | async"
      ></slm-select-autocomplete-input>
      <slm-text-input
        label="LBL_PARTNERS.EDIT.ZIP"
        [errors]="inputErrors"
        type="text"
        [control]="partnerForm.get('zipCode')"
        testId="partner-zip"
      ></slm-text-input>
      <slm-text-input
        label="LBL_PARTNERS.EDIT.CITY"
        [errors]="inputErrors"
        [control]="partnerForm.get('city')"
        testId="partner-city"
      ></slm-text-input>
      <slm-text-input
        label="LBL_PARTNERS.EDIT.STREET"
        [errors]="inputErrors"
        [control]="partnerForm.get('street')"
        testId="partner-street"
      ></slm-text-input>
      <slm-text-input
        label="LBL_PARTNERS.EDIT.ADDRESS"
        [errors]="inputErrors"
        [control]="partnerForm.get('address')"
        testId="partner-address"
      ></slm-text-input>
    </div>
    <div class="grid-col-2-50 grid-responsive">
      <slm-text-input *ngIf="hasCombo$ | async"
        label="LBL_PARTNERS.EXTERNAL_ID"
        placeholder="LBL_PARTNERS.EXAMPLE_EXTERNAL"
        [control]="partnerForm.get('externalSystemIdentifier')"
        testId="partner-external-system-id"
      ></slm-text-input>
    </div>

    <h5 class="txt-start-i pb-1 mt-2 mb-2 txt-medium border-bottom" translate="LBL_MENU.SETTING.INVOICE"></h5>

    <slm-chip-input
      label="LBL_PARTNERS.DATA.NOTIFICATION_EMAILS"
      [errors]="inputErrors"
      placeholder="LBL_PARTNERS.EDIT.EXAMPLES.EMAIL_INVOICE"
      hint="LBL_PARTNERS.EDIT.HINT.EMAIL_INVOICE"
      type="email"
      [validators]="emailValidator"
      [control]="partnerForm.get('partnerEmails')"
      testId="partner-partner-emails"
    ></slm-chip-input>
    <div class="grid-col-2-50 grid-responsive">
      <slm-select-input
        label="LBL_PARTNERS.DATA.NOTIFICATION_EMAIL_LANGUAGE"
        [errors]="inputErrors"
        [control]="partnerForm.get('partnerEmailLanguage')"
        [options]="partnerEmailLanguageOptions"
      ></slm-select-input>
      <slm-select-input
        label="LBL_PARTNERS.PAYMENT_MODE"
        [errors]="inputErrors"
        [control]="partnerForm.get('paymentType')"
        [options]="paymentTypeOptions"
        testId="partner-payment-type"
      ></slm-select-input>
    </div>
    <div class="grid-col-2-50 grid-responsive">
      <slm-text-input
        label="LBL_PARTNERS.PAYMENT_TERMS"
        [errors]="inputErrors"
        type="text"
        suffixLabel="LBL_DAYS"
        [control]="partnerForm.get('paymentTime')"
        testId="partner-payment-time"
      ></slm-text-input>
    </div>
    <slm-text-area
      label="LBL_PARTNERS.DEFAULT_INVOICE_NOTE"
      [control]="partnerForm.get('invoiceNote')"
      testId="partner-invoice-note"
      class="w-100 block"
    ></slm-text-area>
  </form>
</ng-template>

<ng-template #accountingData>
  <form [formGroup]="partnerForm" class="p-3">
    <h5 class="txt-start-i pb-1 mt-2 mb-2 txt-medium border-bottom">
      {{'LBL_MENU.SETTING.ACCOUNT_SETTING' | translate}}
    </h5>
    <div class="grid-col-2-50 grid-responsive">
      <slm-select-autocomplete-input
        label="INVOICE.VENDOR_LEDGER_NUMBER"
        [control]="partnerForm.get('vendorLedgerNumber')"
        [options]="vendorLedgerNumber$ | async"
        clearButton="true"
        testId="vendorLedgerNumberInput"
        [errors]="inputErrors"
        maxDisplayLength="100"
      ></slm-select-autocomplete-input>
      <slm-select-autocomplete-input
        label="INVOICE.CUSTOMER_LEDGER_NUMBER"
        [control]="partnerForm.get('customerLedgerNumber')"
        [options]="customerLedgerNumber$ | async"
        clearButton="true"
        testId="customerLedgerNumberInput"
        [errors]="inputErrors"
        maxDisplayLength="100"
      ></slm-select-autocomplete-input>
      <slm-select-input
        label="PARTNERS.DEFAULT_CURRENCY"
        [control]="partnerForm.get('defaultCurrency')"
        [errors]="inputErrors"
        [options]="currencyOptions$ | async"
      ></slm-select-input>
    </div>
  </form>
</ng-template>

<ng-template #contactData>
  <ng-container
    *ngIf="!isUpdate || id; else loading"
  >
    <div class="p-3">
      <div class="grid-col-2-50 grid-responsive">
        <slm-text-input
          label="LBL_PARTNERS.CONTRACTOR_NAME"
          [errors]="inputErrors"
          testId="contractorName"
          [control]="partnerForm.get('name')"
        ></slm-text-input>
        <slm-text-input
          label="LBL_PARTNERS.ROLE"
          testId="nameOfRole"
          [errors]="inputErrors"
          [control]="partnerForm.get('role')"
        ></slm-text-input>
        <slm-text-input
          type="email"
          label="LBL_INPUT.LABEL.EMAIL"
          testId="partnerEmail"
          placeholder="LBL_PARTNERS.EXAMPLE_EMAIL"
          [errors]="inputErrors"
          [control]="partnerForm.get('email')"
        ></slm-text-input>
        <slm-text-input
          type="tel"
          label="LBL_PROFILE.PHONE"
          testId="phoneNumber"
          placeholder="LBL_PARTNERS.EXAMPLE_PHONE"
          [errors]="inputErrors"
          [control]="partnerForm.get('phone')"
        ></slm-text-input>
      </div>
      <slm-text-area
        label="LBL_PARTNERS.NOTE"
        [control]="partnerForm.get('comments')"
        [errors]="inputErrors"
        class="w-100 block"
        testId="partner-comments"
      ></slm-text-area>
    </div>
  </ng-container>
</ng-template>

<ng-template #loading>
  <slm-loader></slm-loader>
</ng-template>
