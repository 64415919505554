import {Injectable} from '@angular/core';
import {HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {CacheService} from '../../modules/global/services/cache.service';

export const IS_CACHE_ENABLED = new HttpContextToken<boolean>(() => false);
export const CLEAR_CACHE = new HttpContextToken<boolean>(() => false);

@Injectable()
export class CacheInterceptor implements HttpInterceptor{
  constructor(
    private readonly cacheService: CacheService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const cacheEnabled = req.context.get(IS_CACHE_ENABLED);

    if(req.method !== 'GET' ||
      !cacheEnabled
    ){
      return next.handle(req);
    }

    const clearCache = req.context.get(CLEAR_CACHE);
    if(clearCache){
      this.cacheService.deleteFromCache(req.urlWithParams);
    }

    const cachedResponse: HttpResponse<any> = this.cacheService.getFromCache(req.urlWithParams);
    if(cachedResponse) {
      return of(cachedResponse.clone())
    } else {
      return next.handle(req).pipe(
        tap(stateEvent => {
          if(stateEvent instanceof HttpResponse) {
            this.cacheService.addToCache(req.urlWithParams, stateEvent.clone())
          }
        })
      );
    }
  }
}
