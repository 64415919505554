<slm-dialog-container
  [title]="'LBL_PASSWORD.PASSWORD_UPDATE'"
  [buttons]="buttons"
  class="w-max-dialog w-min-350px block"
  (fireEvent)="buttonEvent($event.event)"
>
  <form [formGroup]="passwordForm">

    <slm-text-input
      label="LBL_PASSWORD.CURRENT_PASSWORD"
      [suffixIcon]="inputConfig.currentPassword.icon"
      [type]="inputConfig.currentPassword.type"
      enterEnabled="true"
      (fireEvent)="passwordTypeChanged('currentPassword')"
      [control]="passwordForm.get('currentPassword')"
      [errors]="errors"
    ></slm-text-input>

    <slm-text-input
      label="LBL_PASSWORD.NEW_PASSWORD"
      [suffixIcon]="inputConfig.newPassword.icon"
      [type]="inputConfig.newPassword.type"
      enterEnabled="true"
      (fireEvent)="passwordTypeChanged('newPassword')"
      [control]="passwordForm.get('newPassword')"
      [errors]="errors"
    ></slm-text-input>

    <slm-text-input
      label="LBL_PASSWORD.NEW_PASSWORD_CONFIRMATION"
      [suffixIcon]="inputConfig.newPasswordConfirmation.icon"
      [type]="inputConfig.newPasswordConfirmation.type"
      enterEnabled="true"
      (fireEvent)="passwordTypeChanged('newPasswordConfirmation')"
      [control]="passwordForm.get('newPasswordConfirmation')"
      [errors]="errors"
    ></slm-text-input>

    <slm-message
      *ngIf="passwordMatch$ | async"
      type="error"
      class="block w-max-2680px mb-2"
      message="LBL_PASSWORD.PASSWORD_MATCH"
    ></slm-message>

    <slm-message
      *ngIf="passwordNotMatch$ | async"
      type="error"
      class="block w-max-2680px mb-2"
      message="LBL_PASSWORD.PASSWORD_NOT_MATCH"
    ></slm-message>

    <slm-password-strength-presenter
      [control]="passwordForm.get('newPassword')"
    ></slm-password-strength-presenter>

  </form>
</slm-dialog-container>
