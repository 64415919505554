import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'slm-loader',
  template: `
    <mat-spinner
      [diameter]="diameter"
      [class]="class"
    ></mat-spinner>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {
  @Input() diameter = 50;
  @Input() class = 'block m-auto mt-4 mb-4';
}
