import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TableIconText, TableAlign } from '../../../../../../global';

@Component({
  selector: 'slm-table-icon-text',
  template: `
    <div
      *ngIf="data; else invalid"
      [matTooltip]="tooltip | translate"
      class="w-100 block flex flex-{{align || 'center'}} flex-items-center"
    >
      <slm-svg-icon
        *ngIf="data.icon"
        [color]="data.color"
        [icon]="data.icon"
        class="mr-1"
      ></slm-svg-icon>
      <h5 *ngIf="data.label" class="m-0 txt-start-i w-auto-i">
        {{ data.label | translate }}
      </h5>

      <div *ngIf="data?.notification?.icon"
           class="ml-1 size-1 radius-infinite color-white flex flex-center flex-items-center"
           [matTooltip]="data.notification.label | translate"
           [style.backgroundColor]="data.notification.color">
        <slm-svg-icon *ngIf="data?.notification?.icon" [icon]="data?.notification?.icon"
                      class="fill-white block size-16 w-16pxi h-16pxi"
                      size="16px"></slm-svg-icon>
      </div>


    </div>
    <ng-template #invalid
      ><span class="w-100 block txt-center-i">--</span></ng-template
    >
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableIconTextComponent {
  @Input() data: TableIconText = null;
  @Input() tooltip: string = null;
  @Input() align: TableAlign = TableAlign.CENTER;
}
