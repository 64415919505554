import {Component, Inject} from '@angular/core';
import {ButtonConfig, buttonsStaticConfig, DragAndDropError, Icon, NotificationEnum, NotificationService} from '../../../global';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable, tap} from 'rxjs';

export interface DataImportDialogConfig {
  save: ((file) => Observable<any>);
  typeLabel: string;
  title: string;
  exampleDownload: () => Observable<any>;
}

@Component({
  selector: 'slm-data-import',
  templateUrl: './data-import.dialog.html'
})
export class DataImportDialog {
  public readonly icons = Icon;
  public readonly buttons: Array<ButtonConfig> = [
    buttonsStaticConfig.cancel,
    {
      ...buttonsStaticConfig.import,
      buttonStyle: 'primary',
      icon: null
    }
  ];
  public exampleFileIsLoading = false;
  private file: File = null;
  public readonly notification$ = new BehaviorSubject<{ message: string; isInnerHtml: boolean; type: NotificationEnum }>({
    message: 'MESSAGE.IMPORT_MESSAGE',
    type: NotificationEnum.WARNING,
    isInnerHtml: false
  });

  constructor(
    private readonly dialogRef: MatDialogRef<DataImportDialog>,
    private readonly notification: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: DataImportDialogConfig
  ) {
  }

  public buttonEvent(event: string) {
    if (event === buttonsStaticConfig.cancel.action) {
      this.dialogRef.close();
      return;
    }
    if (this.buttons[1].isLoading) {
      return;
    }
    if (!this.file) {
      this.notification.notify('MESSAGE.FILE_SELECTION_REQUIRED', NotificationEnum.ERROR);
      return;
    }
    this.dialogRef.disableClose = true;
    this.buttons[1].isLoading = true;
    this.data.save(this.file)
      .pipe(tap(() => {
        this.notification.notify('MESSAGE.UPLOAD_SUCCESS');
        this.dialogRef.close(true);
      }))
      .subscribe({
        error: (error) => {
          (error?.error?.message || error?.message) && this.notification$.next({
            type: NotificationEnum.ERROR,
            message: error?.error?.message || error?.message,
            isInnerHtml: true
          });
          this.buttons[1].isLoading = this.dialogRef.disableClose = false;
        },
        complete: () => this.buttons[1].isLoading = this.dialogRef.disableClose = false
      });
  }

  public downloadTestFile() {
    if (this.exampleFileIsLoading) {
      return;
    }
    this.exampleFileIsLoading = true;
    this.data.exampleDownload().subscribe({
      next: () => this.notification.notify('MESSAGE.DOWNLOAD_SUCCESS'),
      error: () => this.exampleFileIsLoading = false,
      complete: () => this.exampleFileIsLoading = false
    });
  }

  public selectedFile(file) {
    this.file = file;
  }

  public unauthorizedFileFormat(event: DragAndDropError) {
    this.notification.notify(event.error === 'type' ? 'MESSAGE.ONLY_EXCEL_SUPPORTED' : event.message, NotificationEnum.ERROR);
  }
}
