import {Currency} from './currency.entity';

export interface Paginator {
  actualPage: number;
  fullLength?: number;
  pageSize: number;
}

export interface PaginatorNew {
  page: number;
  fullLength?: number;
  size: number;
}

export interface PageResponse<T> {
  documents: Array<T>;
  paginator?: Paginator;
}

export interface ListResponse<T> {
  items: Array<T>;
  paginator?: Paginator;
}

export interface ListResponseNew<T> {
  items: Array<T>;
  paginator?: PaginatorNew;
}

export interface ListAmountItem {
  currency: Currency;
  amount: number;
}

export interface AmountListResponseNew<T> extends ListResponseNew<T> {
  amounts?: Array<ListAmountItem>;
  allAmount?: Array<ListAmountItem>;
}
