import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl, UntypedFormControl, Validators} from '@angular/forms';
import {GeneralSandbox, inputErrors, SelectOption, UserSandbox} from '../../../global';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {filter, map, switchMap, tap} from 'rxjs/operators';

@Component({
    selector: 'slm-user-division-select',
    templateUrl: './user-division-select.component.html',
})
export class UserDivisionSelectComponent implements OnInit{
  protected readonly errors = inputErrors;

  public userDivisionOptions$: Observable<Array<SelectOption>>;
  public clearButtonEnabled$ = new BehaviorSubject<boolean>(false);

  @Input() control: UntypedFormControl | FormControl | AbstractControl;
  @Input() useDefaultIfMany: boolean;

  constructor(
    private readonly generalSandbox: GeneralSandbox,
    private readonly userSandbox: UserSandbox
  ) {
  }

  ngOnInit() {


    this.userDivisionOptions$ = combineLatest([
      this.generalSandbox.useDivision$,
      this.userSandbox.user$,
    ]).pipe(
      filter(([useDivision, _]) => !!useDivision),
      switchMap(([_, userData]) => {
        if (userData?.divisions.length > 0) {
          this.control.addValidators([Validators.required]);
          return of({divisions: userData.divisions, paginator: null});
        } else {
          this.control.clearValidators();
          this.clearButtonEnabled$.next(true);
          return this.userSandbox.getDivisions(
            {page: 1, size: 1000},
            {attribute: 'name', order: 'asc'},
            '');
        }
      }),
      map(divisionsResponse => {
        return (divisionsResponse.divisions || []).map(division => {
          return {
            value: division.id,
            label: division.name
          };
        });
      }),
      tap(options => {
        !!options ? this.control.enable() : this.control.disable();
        if((options || []).length === 1 || (this.useDefaultIfMany && (options || []).length > 0)){
          this.control.setValue(options[0].value);
        }
      })
    );
  }
}
