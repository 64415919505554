import {Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ButtonConfig,
  buttonsStaticConfig, KeyValue,
  SystemNotification
} from '../../../modules/global';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'slm-notification-alert-dialog',
  templateUrl: './notification-alert-dialog.component.html'
})
export class NotificationAlertDialogComponent implements OnInit, OnDestroy {
  public buttons: Array<ButtonConfig> = [];
  private acceptButton: ButtonConfig = { ...buttonsStaticConfig.yes, label: 'LBL_BTN.ACCEPT_ALL' };

  public disabledButtons$ = new BehaviorSubject<Array<KeyValue>>(null);
  private buttonInit$ = new BehaviorSubject<boolean>(true);

  public readonly fireEvent = new EventEmitter<string>();
  private readonly destroy$ = new Subject();
  public notificationItems: Array<SystemNotification>;
  public notificationItemIds: Array<number>;

  @ViewChild('textContainer', {static: false}) textContainer: ElementRef;

  @Output() private emitService = new EventEmitter();

  constructor(
    public readonly dialogRef: MatDialogRef<NotificationAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { items: Array<SystemNotification>; showAll?: false }
  ) {}

  ngOnInit() {
    this.notificationItems = [...this.data.items];
    this.notificationItemIds =  this.notificationItems.map(item => item.id);

    this.buttons = this.data.showAll ? [this.acceptButton] : [buttonsStaticConfig.no, this.acceptButton];

    this.fireEvent.pipe(
      tap(() => {
        this.buttons = !this.notificationItems[0].isRead ? [this.acceptButton] : [];
        this.buttonInit$.next(true);
      }),
      takeUntil(this.destroy$)
    ).subscribe();

    this.buttonInit$.pipe(
      debounceTime(100),
      tap(() => {
        if(this.textContainer?.nativeElement?.scrollHeight > this.textContainer?.nativeElement?.clientHeight) {
          this.disabledButtons$.next([{key: 'approve', value: true}]);
          this.textContainer.nativeElement.scrollTop = 0;
        }else{
          this.disabledButtons$.next([{key: 'approve', value: false}]);
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public buttonEvent(event: string, id: Array<number>) {
    if(this.data.showAll){
      this.emitService.emit({event, id});
      this.dialogRef.close('allApproved');
    }else{
      this.dialogRef.close(event);
    }
  }

  public onScroll(event: any) {
    if (((event.target.scrollTop + event.target.clientHeight) * 1.05) >= event.target.scrollHeight) {
      this.disabledButtons$.next(null);
    }
  }

  public trackById = (_, item: SystemNotification) => item?.id;
}
