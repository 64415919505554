import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {
  CompanySandbox,
  CustomValidators,
  GeneralSandbox, GlobalAccountingSandbox, Icon, inputErrors,
  LegalClassEnum, legalClassOptions,
  NotificationEnum,
  NotificationService,
  Partner, PartnerBankAccount, partnerEmailLanguageOptions, partnerTaxTypes, PartnerVatCheck, paymentTypeOptionsLite,
  SelectOption,
  utils
} from '../../../global';
import {BehaviorSubject, concat, Observable, of, Subject, timer} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {PartnerSandbox} from '../../../partner-settings/sandboxes/partner.sandbox';
import {PartnerBankAccountForm} from '../partner-bank-accounts/partner-bank-account.form';

@Component({
  selector: 'slm-partner-form',
  templateUrl: './partner-form.container.html',
  styleUrls: ['./partner-form.container.scss']
})
export class PartnerFormContainer implements OnInit, OnDestroy {
  @ViewChild(PartnerBankAccountForm, {static: false}) partnerBankAccountForm: PartnerBankAccountForm;

  public readonly inputErrors = inputErrors;
  public readonly taxTypeSelectValues = partnerTaxTypes;
  public readonly legalClassSelectValues = legalClassOptions;
  public readonly emailValidator = [Validators.email];
  public readonly paymentTypeOptions = paymentTypeOptionsLite;
  public readonly partnerEmailLanguageOptions = partnerEmailLanguageOptions;
  public readonly icons = Icon;
  private readonly defaultEmailLanguage = '';

  @Output()
  public readonly form = new EventEmitter<UntypedFormGroup>();

  @Output()
  public readonly deletedBankAccounts = new EventEmitter<Array<number>>();

  public partnerForm: UntypedFormGroup;

  public partnersByNumber$: Observable<Array<SelectOption>>;
  public partnersByName$: Observable<Array<SelectOption>>;
  public partnerStatus$: Observable<PartnerVatCheck>;
  public showTaxType$: Observable<boolean>;
  public showThirdTax$: Observable<boolean>;
  public countries$: Observable<Array<SelectOption>>;
  public showLoader$ = new BehaviorSubject<boolean>(true);
  public vendorLedgerNumber$: Observable<Array<SelectOption>>;
  public customerLedgerNumber$: Observable<Array<SelectOption>>;
  public currencyOptions$: Observable<Array<SelectOption>>;
  public hasCombo$: Observable<boolean>;

  public readonly selectedPartner$ = new BehaviorSubject<Partner>(null);
  public readonly bankAccounts$ = new BehaviorSubject<Array<PartnerBankAccount>>(null);

  private isAutocompleteSet = {
    name: false,
    taxNumber: false
  };
  public partnerBillzoneRefresh = false;
  private deletedAccount: Array<number> = [];
  private countryList: Array<SelectOption>;
  private readonly destroy$ = new Subject();

  @Input()
  public isUpdate = false;
  public id: number = null;

  @Input()
  public layoutStyle: 'card' | 'normal' = 'normal';

  @Input('partner')
  public set setPartner(partner: Partner) {
    if(partner?.id){
      this.id = partner?.id;
      this.isAutocompleteSet.name = true;
      this.isAutocompleteSet.taxNumber = true;
      this.setPartnerValue(partner);
      this.selectedPartner$.next(partner);
    }
  }

  constructor(private readonly fb: UntypedFormBuilder,
              private readonly notifications: NotificationService,
              private readonly generalSandbox: GeneralSandbox,
              private readonly partnerSandbox: PartnerSandbox,
              private readonly companySandbox: CompanySandbox,
              private readonly globalAccountingSandbox: GlobalAccountingSandbox) {
  }

  ngOnInit() {
    this.formInit();
    this.partnerForm.get('euTaxNumber').setAsyncValidators((control: AbstractControl): Observable<ValidationErrors> => {
      if (!control?.value?.trim()) {
        return of(null);
      }
      return timer(250).pipe(
        switchMap(() => this.companySandbox.validateEuTaxNumber(control.value)),
        catchError(() => {
          this.notifications.notify('LBL_INPUT.ERROR.UNVERIFIED_EU_TAX_NUMBER', NotificationEnum.WARNING);
          return of(null);
        }));
    });

    this.partnersByNumber$ = this.initSearchObservable(this.partnerForm.get('vatNumber'), 'taxNumber');
    this.partnersByName$ = this.initSearchObservable(this.partnerForm.get('partnerName'), 'name');
    this.showTaxType$ = concat(of(null), this.partnerForm.get('legalClass').valueChanges).pipe(
      map(value => !value || (value !== LegalClassEnum.PRIVATE_PERSON && value !== LegalClassEnum.THIRD_COUNTRY)),
      distinctUntilChanged(),
      tap(show => {
        if (show) {
          this.partnerForm.get('taxType').enable();
        } else {
          this.partnerForm.get('taxType').disable();
        }
      })
    );

    this.hasCombo$ = this.companySandbox.hasExternalSystem$;

    this.showThirdTax$ = concat(of(null), this.partnerForm.get('legalClass').valueChanges).pipe(
      map(value => !value || (value === LegalClassEnum.THIRD_COUNTRY)),
      tap(show => {
        if (show) {
          this.partnerForm.get('thirdPartyVat').enable();
        } else {
          this.partnerForm.get('thirdPartyVat').disable();
        }

        if(this.partnerForm.get('legalClass').value === LegalClassEnum.THIRD_COUNTRY) {
          this.showLoader$.next(false);
        }else{
          this.showLoader$.next(true);
        }
      })
    );

    this.partnerStatus$ = this.selectedPartner$.pipe(
      filter(selectedPartner => !!selectedPartner),
      map(selectedPartner => ({
        negativeInfo: selectedPartner.negativeInfo,
        verificationDate: selectedPartner?.verificationDate,
        euNegativeInfo: selectedPartner?.euNegativeInfo,
        euVerificationDate: selectedPartner?.euVerificationDate,
      }))
    );
    this.countries$ = this.generalSandbox.getSelectFormattedCountries().pipe(
      tap(countries => this.countryList = countries),
      map(countries => countries.map(country => utils.omit(country, ['code']))));

    this.vendorLedgerNumber$ = this.globalAccountingSandbox.defaultVendorLedgerNumber('vendor').pipe(
      map(ledgerNumbers =>
        ([...ledgerNumbers.primaryLedgerNumbers || [], ...ledgerNumbers.foreignLedgerNumbers || []]).map(
          (item): SelectOption => ({
            label: item.ledgerNumber,
            subLabel: item.name,
            value: item.id,
            data: item
          })
        ))
    );

    this.customerLedgerNumber$ = this.globalAccountingSandbox.defaultVendorLedgerNumber('customer').pipe(
      map(ledgerNumbers =>
        ([...ledgerNumbers.primaryLedgerNumbers || [], ...ledgerNumbers.foreignLedgerNumbers || []]).map(
          (item): SelectOption => ({
            label: item.ledgerNumber,
            subLabel: item.name,
            value: item.id,
            data: item
          })
        ))
    );

    this.currencyOptions$ = this.companySandbox.getCompanyCurrencies().pipe(
      tap(() => this.partnerForm?.get('defaultCurrency').setValue(this.partnerForm.get('defaultCurrency').value)),
      map((currencies) =>
        (currencies || []).map((currency) => ({
          label: currency.prefix,
          value: +currency.id
        }))
      )
    );
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private initSearchObservable(control: AbstractControl, setAttributeName: 'name' | 'taxNumber'): Observable<Array<SelectOption>> {
    return control.valueChanges.pipe(
      debounceTime(250),
      tap((value: SelectOption | string) => {
        if (!!value && utils.isObject(value)) {
          const selected = value as SelectOption;
          this.selectedPartner$.next(selected.data);
          this.isAutocompleteSet.name = true;
          this.isAutocompleteSet.taxNumber = true;
          this.setPartnerValue(selected.data);
        }
      }),
      filter((value) => !!value && typeof value === 'string' && value.length >= 3 && this.partnerForm.get('legalClass').value !== LegalClassEnum.THIRD_COUNTRY),
      switchMap((value: string) =>
        this.isAutocompleteSet[setAttributeName] ? of(null) : this.companySandbox.searchPartnerBy({size: 5, page: 1}, value)
      ),
      map((response: any) => {
          const partners = response?.items || response?.companies || [];
          if (this.isAutocompleteSet[setAttributeName] && !partners.length) {
            this.isAutocompleteSet[setAttributeName] = false;
            return [];
          }
          return partners.map(
            (item): SelectOption => ({
              label: item.name,
              subLabel: item.taxAdministrationNumber ?? item.taxNumber ?? item.publicTaxIdNumber ?? item.thirdPartyVat,
              value: item.id,
              data: item
            })
          );
        }
      )
    );
  }

  private formInit() {
    if (this.partnerForm) {
      return;
    }
    this.partnerForm = this.fb.group({
      //Contact data
      name: ['', [CustomValidators.username, Validators.maxLength(128)]],
      email: ['', [CustomValidators.email, Validators.maxLength(64)]],
      role: ['', [Validators.maxLength(32)]],
      phone: ['', [CustomValidators.phone, Validators.maxLength(32)]],
      comments: ['', [Validators.maxLength(1024)]],
      /// Partner Data
      partnerName: ['', [Validators.required, Validators.maxLength(256)]],
      legalClass: [''],
      country: ['', [CustomValidators.autoCompleteObject]],
      zipCode: ['', [Validators.minLength(2), Validators.maxLength(16)]],
      city: [''],
      street: [''],
      address: [''],
      taxType: [''],
      vatNumber: ['', [CustomValidators.taxNumber]],
      euTaxNumber: ['', {
        validators: [CustomValidators.euTaxNumber, Validators.maxLength(16)],
        updateOn: 'blur'
      }],
      externalSystemIdentifier: [{value: '', disabled: true}],
      paymentTime: ['0', [CustomValidators.digit, Validators.max(255)]],
      partnerEmails: [],
      partnerEmailLanguage: [this.defaultEmailLanguage],
      paymentType: [''],
      invoiceNote: [''],
      groupVatNumber: ['', [Validators.maxLength(120)]],
      thirdPartyVat: ['', [Validators.maxLength(32)]],
      vendorLedgerNumber: ['', [CustomValidators.autoCompleteObject]],
      customerLedgerNumber: ['', [CustomValidators.autoCompleteObject]],
      defaultCurrency: ['']
    });
    this.form.emit(this.partnerForm);

    this.partnerForm.get('partnerEmails').valueChanges.pipe(
      tap((value) => {
        const emailLanguage = this.partnerForm.get('partnerEmailLanguage');
        if(value?.length > 0){
          emailLanguage.setValidators([Validators.required]);
        }else{
          emailLanguage.removeValidators([Validators.required]);
        }
        emailLanguage.updateValueAndValidity();
      }),
      takeUntil(this.destroy$)
    ).subscribe();

  }

  setBankAccountFormArray(formArray: UntypedFormArray) {
    this.partnerForm.addControl('bankAccounts', formArray);
  }

  private setPartnerValue(partner: Partner): void {
    if (!partner) {
      return;
    }
    if (!this.partnerForm) {
      this.formInit();
    }
    const country = partner.country && utils.isObject(partner.country) ? {label: partner.country.name, value: partner.country.id} :
      (utils.isString(partner.country)  && partner.countryId ? {label: partner.country, value: partner.countryId} : '');

    const vendorLedgerNumber = partner?.vendorLedgerNumber ? {
      label: partner?.vendorLedgerNumber?.ledgerNumber,
      subLabel: partner?.vendorLedgerNumber?.name,
      value: partner?.vendorLedgerNumber?.id,
      data: partner?.vendorLedgerNumber
    }: null;

    const customerLedgerNumber = partner?.customerLedgerNumber && !!partner.customerLedgerNumber ? {
      label: partner?.customerLedgerNumber?.ledgerNumber,
      subLabel: partner?.customerLedgerNumber?.name,
      value: partner?.customerLedgerNumber?.id,
      data: partner?.customerLedgerNumber
    }: null;

    const partnerEmails = partner.invoice?.email ? partner.invoice?.email.split(';') : [];

    this.partnerForm.patchValue({
      partnerName: partner.name || '',
      legalClass: partner.legalClass || '',
      country,
      zipCode: partner.zipCode || '',
      city: partner.city || '',
      taxType: partner.taxType ?? '',
      street: partner.addressStreet || '',
      address: partner.addressHouseNumber || '',
      vatNumber: partner.taxAdministrationNumber || '',
      euTaxNumber: partner.publicTaxIdNumber || '',
      externalSystemIdentifier: partner.externalSystemIdentifier || '',
      paymentTime: partner.invoice?.paymentTime || '',
      partnerEmails: partnerEmails,
      partnerEmailLanguage: partner.invoice?.emailLanguage || this.defaultEmailLanguage,
      paymentType: partner.invoice?.paymentType || '',
      invoiceNote: partner.invoice?.invoiceNote || '',
      groupVatNumber: partner.groupVatNumber ?? '',
      thirdPartyVat: partner.thirdPartyVat ?? '',
      name: partner?.contact?.name ?? '',
      email: partner?.contact?.email ?? '',
      role: partner?.contact?.role ?? '',
      phone: partner?.contact?.phone ?? '',
      comments: partner?.contact?.comments ?? '',
      vendorLedgerNumber,
      customerLedgerNumber,
      defaultCurrency: partner?.defaultCurrency?.id ?? ''
    });

    if(this.partnerBankAccountForm){
      this.partnerBankAccountForm.clearAllBankAccounts();
    }

    if(partnerEmails.length > 0){
      this.partnerForm.get('partnerEmailLanguage').setValidators([Validators.required]);
    }

    this.bankAccounts$.next(partner?.bankAccounts);
  }

  public billzoneRefresh() {
    if (this.partnerBillzoneRefresh) {
      return;
    }
    this.partnerBillzoneRefresh = true;
    this.companySandbox.refreshPartner(this.id).subscribe({
      next: () => this.partnerSandbox.selectPartner(this.id),
      error: () => this.partnerBillzoneRefresh = false,
      complete: () => this.partnerBillzoneRefresh = false
    });
  }

  public deleteBankAccount(id: number) {
    this.deletedAccount.push(id);
    this.deletedBankAccounts.emit(this.deletedAccount);
  }

  private searchCountryByShort(shortCode: string) {
    return (this.countryList ?? []).find(country => country.data === shortCode);
  }

  selectRightCountry() {
    const countryControl =  this.partnerForm.get('country');

    if(!countryControl.value){
      const vatControl = this.partnerForm.get('vatNumber');
      const euTaxControl = this.partnerForm.get('euTaxNumber');

      if(vatControl.value){
        const hungaryCountry = this.searchCountryByShort('HU');
        if(hungaryCountry){
          countryControl.setValue(hungaryCountry);
        }
      } else if(euTaxControl?.value && euTaxControl.value.length >= 2){
        const country = this.searchCountryByShort(euTaxControl.value.toUpperCase().slice(0, 2));
        if(country){
          countryControl.setValue(country);
        }
      }
    }
  }
}
