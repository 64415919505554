import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {globalActions, globalSelectors} from '../+states';
import { GeneralService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class NotificationSandbox {
  constructor(
    private readonly store: Store,
    private readonly generalService: GeneralService
  ) {}

  notificationCount$ = this.store.select(globalSelectors.getNotificationCount());

  notifications$ = this.store.select(globalSelectors.getNotifications());

  systemNotification$ = this.store.select(
    globalSelectors.getSystemNotifications()
  );

  public refreshGeneral(): void {
    this.store.dispatch(globalActions.refreshGeneral());
  }

  public loadNextNotificationPage(page: number) {
    this.store.dispatch(globalActions.loadNotifications({page}));
  }

  public readonly acceptNotifications = (ids: Array<number>) =>
    this.generalService.acceptNotifications(ids);
}
