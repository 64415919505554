import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  MenuItem,
  SelectOption,
  NavigationService,
  MenuStates, RoutesEnum
} from '../../../global';
import {UntypedFormControl} from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { NavigationEnd } from '@angular/router';

@Component({
  selector: 'slm-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {

  public readonly routes = RoutesEnum;
  //Menu items
  @Input() public menuItems: Array<MenuItem> = [];

  // Available user companies
  @Input() public companies: Array<SelectOption> = [];

  private companyId: number = null;

  // Active company id
  @Input('companyId')
  public set setCompanyId(companyId: number) {
    this.companyControl.setValue(companyId);
    this.companyId = companyId;
  }

  @Input() public status: MenuStates = 'full';

  @Output() public readonly changeCompany = new EventEmitter<string>();
  @Output() public readonly toggle = new EventEmitter<boolean>();
  @Output() public readonly openedRoute = new EventEmitter<string>();

  // Control of the select in the side menu
  public readonly companyControl = new UntypedFormControl('');

  private readonly destroy$ = new Subject();

  // Name of the opened dropdown in the menu.
  public openedMenu: string = null;

  // Simplified url of the current page. With this parameter the menu can activate the menu item in the menu
  public activeRoute = '';
  private queryParamCheckedRegexp = /\?(&?[a-zA-Z0-9]*=(.*)?){1,}$/i;

  constructor(private readonly router: NavigationService) {}

  ngOnInit() {
    this.routeInit();
    this.controlInit();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private routeInit(): void {
    this.setActiveRoute(this.router.url.replace(this.queryParamCheckedRegexp, ''));

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((event: NavigationEnd) => {
        const simplifiedUrl = event.url.replace(this.queryParamCheckedRegexp,''); // removing queryParams;
        this.setActiveRoute(simplifiedUrl);
      });
  }

  private setActiveRoute(currentRoute: string) {
    if (currentRoute) {
      if (currentRoute === '/' || currentRoute === '') {
        this.activeRoute = '';
      } else {
        const routes = this.menuItems
          .map((item) => item.route as string)
          .filter(
            (route: string) =>
              !!route && route !== '/' && currentRoute.startsWith(route)
          );
        if (!!routes.length) {
          this.activeRoute = routes[0];
          return;
        } else {
          this.menuItems
            .filter((item) => item.submenu)
            .forEach((item) => {
              const exist = item.submenu.find((submenu) =>
                currentRoute.startsWith(submenu.route)
              );
              const equal = item.submenu.find((submenu) => currentRoute === submenu.route);
              if (exist || equal) {
                this.openedMenu = item.name;
                this.activeRoute = equal?.route ?? exist?.route;
                if (equal) {
                  return;
                }
              }
            });
        }
      }
    }
  }

  private controlInit(): void {
    this.companyControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter((value) => value !== this.companyId && !!this.companyId),
        takeUntil(this.destroy$)
      )
      .subscribe((companyId) => {
        this.companyId = companyId;
        this.changeCompany.emit(companyId);
      });
  }

  public menuClicked(): void {
    this.toggle.emit();
  }

  public openMenu(name: string): void {
    this.openedMenu = name;
  }

  public navigateTo(route: string): void {
    this.activeRoute = route;
    this.openedRoute.emit(route);
  }

  public trackByLabel = (item: MenuItem | any) => item.name;
}
