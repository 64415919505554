<mat-label *ngIf="label" class="mb-1 block color-secondary">{{ label | translate }}</mat-label>
<div
  class="pos-relative w-100 p-5 bg-color-accent-transparent-lighter border border-dashes border-color-accent border-2 radius-big"
  slmDragAndDrop
  (fileDropped)="onFileDropped($event)"
>
  <input
    class="pos-absolute top-0 left-0 right-0 bottom-0 w-100 opacity-transparent cursor-pointer"
    [accept]="accept"
    multiple
    type="file"
    #fileDropRef
    id="fileDropRef"
    (change)="fileBrowseHandler($event)"
  />
  <div class="flex flex-center flex-wrap">
    <h4 class="color-accent w-fit-content-i text-medium mb-0" translate>DND.CHOOSE_FILE</h4>
    <h4 class="color-accent-transparent w-fit-content-i mb-0 pl-1 pr-1" translate>LBL_OR</h4>
    <h4 class="color-accent w-fit-content-i mb-0" translate>DND.DRAG_HERE</h4>
  </div>
</div>
