import {SelectOption} from '../../../global';

export const rightOptions: Array<SelectOption> = [
  {
    value: 'read',
    label: 'FILE_MANAGER.USER_RIGHTS_READ'
  },
  {
    value: 'write',
    label: 'FILE_MANAGER.USER_RIGHTS_WRITE'
  }
];
