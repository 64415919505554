import {
  ButtonHeightEnum,
  ButtonStyleEnum, ButtonTypeEnum, FileUploadModes, Icon, IconColors,
  Icons, InvoicePaymentType, InvoiceType,
  NotificationEnum,
  RightsEnum,
  TableAlign, VatFrequency
} from '../enums';
import {InputConfig} from './form.enity';
import {Currency} from './currency.entity';
import {SelectOption} from './input.entity';
import {AllExternalSystems} from '../static/externalSoftwares.static';
import {FormControl} from '@angular/forms';

export interface Notification {
  message: string;
  type: NotificationEnum;
  duration: number;
  messageParams?: any;
}

export interface Base {
  id: number;
  name: string;
}

export interface Code extends Base {
  code: string;
}

export interface MenuItem {
  icon: Icons;
  name: string;
  route?: string | ((any) => string);
  action?: string;
  testId?: string;
  submenu?: Array<SubMenuItem>;
  isEnabled?: (any) => boolean;
  rights?: RightsConfig | Array<string | RightsEnum> | string;
  data?: any;
}

export interface SubMenuItem {
  name: string;
  route: string;
  icon?: Icons;
  testId?: string;
  rights?: RightsConfig | Array<string | RightsEnum> | string;
  showUpgradeIcon?: boolean;
}

export interface KeyValue {
  key: string | number | boolean;
  value: string | boolean | number;
}

export interface DataKeyValue extends KeyValue {
  data?: any;
}

export interface SelectionButton {
  label: string | number | boolean;
  action: string | number;
  rights?: Array<RightsEnum> | RightsConfig;
  testId?: string;
}

export interface FireEvent {
  event: string;
  data?: any;
}

export interface ButtonConfig {
  icon?: Icons;
  label: string;
  buttonStyle?: ButtonStyleEnum;
  isLoading?: boolean;
  align?: 'left' | 'right';
  action?: string;
  testId?: string;
  height?: ButtonHeightEnum;
  noWrap?: boolean;
  rights?: Array<RightsEnum> | RightsConfig;
  url?: string;
  target?: string;
  externalLink?: boolean;
  type?: ButtonTypeEnum;
  disabled?: boolean;
  tooltip?: string;
}
export interface ThirdColumn {
  type: 'link' | 'button' | 'icon';
  text: string;
  action?: string;
  route?: string;
  icon?: Icon | string;
  iconStyle?: string;
  testId?: string;
  rights?: Array<string | RightsEnum> | RightsConfig;
  urlAttribute?: string;
}

export interface TranspiledColumnTableItem extends ColumnTableItem {
  color: string;
}

export interface ColumnTableItem {
  width?: string;
  key: string;
  value: string;
  type?: 'text' | 'date' | 'translate' | 'currency' | 'round-button' | 'text-link' | 'icon' | 'simple-text-link';
  valueColor?: IconColors | string | ((any) => string);
  replacement?: string;
  noWrapKey?: boolean;
  noWrapValue?: boolean;
  boldValue?: boolean;
  align?: TableAlign;
  dateFormat?: string;
  thirdColumn?: ThirdColumn | ((any) => ThirdColumn | null);
  thirdColumnConfig?: ThirdColumn | null;
  hideIfEmpty?: boolean;
  currencyKey?: string;
  defaultCurrency?: Currency;
  buttonIcon?: Icons;
  buttonLabel?: string;
  rights?: Array<string | RightsEnum> | RightsConfig;
  testId?: string;
  bold?: boolean | ((any) => boolean);
  infoIconData?: InfoIconData;
  smallIconColumn?: boolean;
}

export interface TableFilter {
  type: 'multi-select' | 'select' | 'text' | 'date-range' | 'number-range' | 'checkbox' | 'date';
  chipName?: string | ((any) => any);
  disableChipRemove?: boolean;
  config: InputConfig;
  rights?: Array<string | RightsEnum> | RightsConfig;
  settingsId?: Array<string>;
}

export interface PagerTitle {
  title: string;
  rights?: RightsConfig | Array<RightsEnum | string> | string;
  route?: string;
  testId?: string;
  search?: string;
}

export interface General {
  notificationCount: number;
  autoRegNumber: boolean;
  syncLocation: 'all' | 'incoming';
  vatFrequency: VatFrequency;
  defaultAccrual: boolean;
  useOnTransactionStatus?: boolean;
  useExternalBilling?: boolean;
  fileUploadMode?: FileUploadModes;
  externalBillingSystem?: BillingSystem;
  externalBillingSystemError?: string;
  externalSystemId: AllExternalSystems;
  storage?: {
    enabled: boolean;
    total: number;
    used: number;
  };
  useDivision?: boolean;
  bank?: boolean;
}

export interface PaymentFormConfig {
  date: Date | string;
  partnerId: number;
  type?: InvoiceType;
  documentId?: number | null;
  currency?: Currency;
  paidValue?: number;
  grossValue?: number;
  paymentType?: InvoicePaymentType;
  savedInTransaction?: number;
}

export interface RightsConfig {
  rights?: string | Array<RightsEnum | string>;
  orRights?: string | Array<RightsEnum | string>;
  exceptRights?: string | Array<RightsEnum | string>;
  reverseRights?: boolean;
}

export type SELECT_SEARCH_FUNCTION = (search: string, option: SelectOption) => boolean;
export type SELECT_VALUE_MATCHER = (search: string | SelectOption, option: SelectOption) => boolean;

export interface DragAndDropError {
  message: string;
  error: 'size' | 'type';
}

export interface InfoIconData {
  icon: Icons;
  color: IconColors;
  tooltip: string;
}

export type BillingSystem = 'none' | 'billzone' | 'szamlazz';

export enum BillingSystemEnum {
  NONE = 'none',
  BILLZONE = 'billzone',
  SZAMLAZZ = 'szamlazz'
}

export interface HeaderSelect {
  control: FormControl,
  options: Array<SelectOption>
}
