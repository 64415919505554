import {Component, Input} from '@angular/core';
import {ListAmountItem} from '../../../../global';

@Component({
  selector: 'slm-table-amount',
  templateUrl: './table-amount.component.html'
})
export class TableAmountComponent {
  @Input()
  public amounts: Array<ListAmountItem>;

  @Input()
  public sumAmount: ListAmountItem = null;

  @Input()
  public sumAmountLabel = 'ALL_AMOUNT_DEF_CURRENCY';

  @Input()
  public message: string;
}
