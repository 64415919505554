import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'slm-simple-drag-and-drop',
  templateUrl: './simple-drag-and-drop.component.html'
})
export class SimpleDragAndDropComponent {

  /**
   * @type {string} - Label text above the drag and drop container
   */
  @Input() public label = '';

  /**
   * @type {string} - supported files
   * @example image/jpeg, image/png, image/jpg
   */
  @Input() public accept = '';

  /**
   * @type {EventEmitter} - Returns to the parent component the selected file(s)
   */
  @Output() public files = new EventEmitter<any>();

  /**
   * @type {ElementRef} - Reference to the <input/> tag. (#fileDropRef reference on the input tag in the template file).
   */
  @ViewChild('fileDropRef', {static: false}) fileDropEl: ElementRef;

  /**
   *
   * @param event {Event} - change function on the <input[type=file]/> input.
   * Traditional file selection (When the user clicks on the button)
   */
  public fileBrowseHandler(event) {
    this.submitData(event.target.files);
  }

  /**
   *
   * @param files {FileList} - drag and drop function call.
   * When the user uses drag and drop functionality
   */
  public onFileDropped(files: FileList) {
    this.submitData(files);
  }

  /**
   *
   * @param selectedFiles {FileList} - file sending to the parent component
   */
  private submitData(selectedFiles: FileList) {
    const files = [];
    for (let index = 0; index < (selectedFiles || []).length; ++index) {
      files.push(selectedFiles.item(index));
    }
    this.files.emit(files);
    this.clear();
  }

  /**
   *
   * Remove the input value (selected file)
   */
  private clear(): void {
    this.fileDropEl.nativeElement.value = '';
  }
}
