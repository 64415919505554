<mat-form-field
  [class.text-input--required]="isRequiredField  && (groupControl.valid || !groupControl.dirty)"
  class="flex-grow"
  [class.text-input--error]="
    groupControl && groupControl.invalid && groupControl.touched
  "
>
  <mat-label *ngIf="label || (!isRequiredField && !hideOptionalLabel)"
    >{{ label | translate
    }} <span *ngIf="secondaryLabel">{{ secondaryLabel | translate }}</span>
    <span *ngIf="!isRequiredField && !hideOptionalLabel" translate="LBL_INPUT.LABEL.NOT_REQUIRED"></span>
  </mat-label>
  <slm-svg-icon matPrefix *ngIf="prefixIcon" [icon]="prefixIcon"></slm-svg-icon>
  <mat-date-range-input
    [formGroup]="groupControl"
    [rangePicker]="rangePicker"
    [min]="min"
    [max]="max"
  >
    <input
      matStartDate
      [required]="isRequiredField"
      [attr.data-test-id]="firstTestId"
      [class.color-accent-i]="coloredValue"
      [formControlName]="controlStart"
      [placeholder]="placeholderStart | translate"

    />
    <input
      matEndDate
      [required]="isRequiredField"
      [attr.data-test-id]="secondTestId"
      [class.color-accent-i]="coloredValue"
      [formControlName]="controlEnd"
      [placeholder]="placeholderEnd | translate"
    />
  </mat-date-range-input>
  <mat-datepicker-toggle
    matSuffix
    [for]="rangePicker"
    [matTooltip]="suffixIconLabel | translate"
  >
    <slm-svg-icon
      matDatepickerToggleIcon
      class="fill-grey svg-icon"
      icon="today"
    ></slm-svg-icon>
  </mat-datepicker-toggle>
  <mat-date-range-picker #rangePicker></mat-date-range-picker>

  <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
  <mat-error
    *ngIf="errors$ | async as error
    "
    >{{ error | translate }}</mat-error
  >
</mat-form-field>
