import {animate, state, style, transition, trigger} from '@angular/animations';

export const showHideDropdownAnimation = trigger('showHideDropdown', [

    state('show', style({ opacity: 1, height: '*' })),
    state('hide', style({ opacity: 0, height: 0 })),

    transition('hide => show', [
      animate('300ms cubic-bezier(.35, 0, .25, 1)')
    ]),
    transition('show => hide', [
      animate('300ms cubic-bezier(.35, 0, .25, 1)')
    ]),

    transition(':enter', [
      style({
        height: 0,
        opacity: 0
      }),
      animate('300ms cubic-bezier(.35, 0, .25, 1)')
    ])
]);
