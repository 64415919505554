export interface InternalMessage {
  userId: number;
  id: number | string;
  message: string;
  date: string;
  firstName: string;
  lastName: string;
  isAdmin?: boolean;
  type?: string;
}

export interface ServerResponseMessage {
  message: string;
  type?: 'error' | 'warning' | 'success' | 'info';
}
