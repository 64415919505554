import { InputError } from '../entities';

export const inputErrorTypes = {
  required: { name: 'required', message: 'LBL_INPUT.ERROR.REQUIRED' },
  digit: { name: 'digit', message: 'LBL_INPUT.ERROR.DIGIT' },
  onlyNumbers: { name: 'onlyNumbers', message: 'LBL_INPUT.ERROR.DIGIT' },
  amount: { name: 'amount', message: 'LBL_INPUT.ERROR.AMOUNT' },
  isDateAfter: { name: 'isDateAfter', message: 'LBL_INPUT.ERROR.ISDATEAFTER' },
  zip: { name: 'zip', message: 'LBL_INPUT.ERROR.ZIP' },
  taxNumber: { name: 'taxNumber', message: 'LBL_INPUT.ERROR.TAX_NUMBER' },
  euTaxNumber: {
    name: 'euTaxNumber',
    message: 'LBL_INPUT.ERROR.EU_TAX_NUMBER'
  },
  invoiceNumber: { name: 'invoiceNumber', message: 'LBL_INPUT.ERROR.EXISTING_INVOICE'},
  email: { name: 'email', message: 'LBL_INPUT.ERROR.EMAIL' },
  phone: { name: 'phone', message: 'LBL_INPUT.ERROR.PHONE' },
  username: { name: 'username', message: 'LBL_INPUT.ERROR.USERNAME' },
  min: { name: 'min', message: 'LBL_INPUT.ERROR.MIN_VALUE' },
  companyRegistrationNumber: { name: 'companyRegistrationNumber', message: 'LBL_INPUT.ERROR.COMPANY_REGISTRATION_NUMBER' },
  max: { name: 'max', message: 'LBL_INPUT.ERROR.MAX_VALUE' },
  swift: { name: 'swift', message: 'LBL_INPUT.ERROR.SWIFT' },
  bankIdentifier: {
    name: 'bankIdentifier',
    message: 'LBL_INPUT.ERROR.BANK_IDENTIFIER'
  },
  autocomplete: {
    name: 'autocomplete',
    message: 'LBL_INPUT.ERROR.AUTOCOMPLETE'
  },
  minLength: { name: 'minlength', message: 'LBL_INPUT.ERROR.MINLENGTH' },
  maxLength: { name: 'maxlength', message: 'LBL_INPUT.ERROR.MAXLENGTH' },
  strongPassword: {
    name: 'strongPassword',
    message: 'LBL_INPUT.ERROR.STRONGPASSWORD'
  },
  iban: {name: 'iban', message: 'LBL_INPUT.ERROR.IBAN'},
  sameDate: {name: 'sameDate', message: 'LBL_INPUT.ERROR.SAME_DATE'},
  matDatepickerParse: {name: 'matDatepickerParse', message: 'LBL_INPUT.ERROR.INVALID_VALUE'},
  raiffaisenAccountIdentifier: {name: 'raiffaisenAccountIdentifier', message: 'LBL_INPUT.ERROR.RAIFFEISEN_13_LENGTH'}
};

export const inputErrors: Array<InputError> = Object.values(inputErrorTypes);
