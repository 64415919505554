export enum RoutesEnum {
  HOME = '',
  LOGIN = 'login',
  ACCOUNT_ACTIVATION = 'login/activation/:token',
  COMPANY_ACTIVATION = 'company/verification/:token',
  REGISTRATION = 'registration',
  RESET_PASSWORD = 'auth/reset-password',
  RESET_NEW_PASSWORD = 'auth/reset-password/:token',
  EXTERNAL_INVOICE = 'public/invoice/:jwt',
  ID = ':id',
  ID_EDIT = ':id/edit',
  EDIT = 'edit',
  CREATE = 'create',
  CREATE_ID = 'create/:id',
  CREATE_PREVIEW = 'create/preview',
  CREATE_DISABLED = 'create/disabled',
  VIDEOS = 'videos',
  IMPORT = 'import',
  NEW = 'new',
  COPY = 'copy',
  CHECK = 'check',
  CHECK_DISABLED = 'check/disabled',

  //Documents
  DOCUMENTS = 'documents',
  DOCUMENTS_FILLING = 'documents/filling/:id',
  DOCUMENTS_FILLING_FORM = 'documents/filling/:id/form/:selectedId',
  DOCUMENTS_ATTACH = 'documents/attach/:id',
  DOCUMENTS_ATTACH_INVOICE = 'documents/attach/:id/invoice/:selectedId',
  FILLING = 'filling',
  FILLING_ID = 'filling/:id',
  FILLING_FORM = 'filling/:id/form/:selectedId',
  ATTACH = 'attach',
  ATTACH_ID = 'attach/:id',
  ATTACH_INVOICE = 'attach/:id/invoice/:selectedId',

  //Invoices

  INVOICES = 'invoices',
  INVOICES_INCOMES_TABLE = 'invoices/incomes',
  INVOICES_INCOMES_NAV_TABLE = 'invoices/incomes/nav',
  INCOMING_INVOICE_DATA_SHEET = 'invoices/incomes/:id',
  INVOICES_OUTGOINGS_TABLE = 'invoices/outgoings',
  INVOICES_OUTGOINGS_NAV_TABLE = 'invoices/outgoings/nav',
  INCOMES = 'incomes',
  OUTGOINGS = 'outgoings',
  QUICK_VERIFY = 'verify/:ids',
  NAV = 'nav',
  NAV_OPEN = 'nav/:id',
  NAV_EDIT = 'nav/:id/edit',
  QUICK_VERIFY_SELECTED = 'verify/:ids/:id',
  CHECKING = 'checking',
  CHECKING_ID = 'checking/:type/:id',
  OUTGOINGS_INVOICE_DATA_SHEET = 'invoices/outgoings/:id',
  NAV_INCOMING_INVOICE_DATA_SHEET = 'invoices/incomes/nav/:id',
  NAV_OUTGOING_INVOICE_DATA_SHEET = 'invoices/outgoings/nav/:id',
  INVOICES_UPLOADS = 'invoices/documents',
  OUTGOINGS_INVOICE_CREATE = 'invoices/outgoings/create',
  OUTGOINGS_INVOICE_CREATE_PREVIEW = 'invoices/outgoings/create/preview',
  OUTGOINGS_INVOICE_CREATE_DISABLED = 'invoices/outgoings/create/disabled',
  OUTGOINGS_IMPORT_TABS = 'invoices/outgoings/import',
  OUTGOINGS_IMPORT_HISTORY = 'history',
  OUTGOINGS_IMPORT_QUEUE = 'queue',
  OUTGOINGS_IMPORT_ATTACH = 'attach',

  // Bank sub pages
  BANKING = 'banking',
  BANKING_ACCOUNTS = 'banking/accounts',
  ACCOUNTS = 'accounts',
  TRANSACTIONS = 'transactions',
  INVOICE = 'invoice',
  ITEM = 'item',
  TRANSACTION_ITEM_VIEW = 'transactions/item/:id',
  TRANSACTION_INVOICE_VIEW = 'transactions/invoice/:id',
  NEW_TRANSACTION = 'transactions/item/new',
  TRANSACTION_ITEM_EDIT = 'transactions/item/:id/edit',
  TRANSACTION_INVOICE_EDIT = 'transactions/invoice/:id/edit',
  TRANSACTION_INVOICE_NEW = 'transactions/invoice/:id/new',
  ACCOUNTS_SELECTED = 'accounts/:id',
  ACCOUNTS_SELECTED_HISTORY = 'history',
  NEW_TRANSACTION_SELECTED = 'transactions/item/new/:id',
  NEW_TRANSACTION_COPY = 'transactions/item/new/copy/:id',
  GROUP_ITEM_TRANSACTION = 'transactions/group/item/:ids',
  GROUP_INVOICE_TRANSACTION = 'transactions/group/invoice/:ids',
  GROUP_TRANSACTION = 'transactions/group/:type/:ids',
  AGGREGATION = 'aggregation',
  AGGREGATION_RULES = 'aggregation-rules',
  UNDER_TRANSFER = 'under-transfer',
  UNDER_TRANSFER_DETAILS = 'banking/under-transfer/:id',
  UNDER_TRANSFER_DETAILS_SHORT = 'under-transfer/:id',
  BANKING_TRANSACTION_INVOICE = 'banking/transactions/invoice',

  // Finances sub pages
  FINANCES = 'finances',

  // Finances Reports
  FINANCES_REPORTS = 'finances/reports',
  CASH_FLOW = 'cashflow',
  VAT_BALANCE = 'vat',
  REPORTS = 'reports',
  PROCESSING = 'processing/:type',
  ACCOUNTANT = 'accountant',
  FINANCES_EMAILS = 'finances/emails',
  FINANCES_MONEY_COLLECTION = 'finances/money',
  FINANCES_DATA = 'finances/data',

  // Finance Cash-Flow
  FINANCES_CASH_FLOW = 'finances/cashflow',

  //Finances Outstanding
  FINANCES_OUTSTANDING = 'finances/outstanding',
  OUTSTANDING = 'outstanding',
  COLLECTING = 'collecting',
  AUTOMATIC_EMAIL = 'collecting/email/:type/:language',
  EMAIL = 'email',
  SENT_EMAILS = 'sent',
  SENT_EMAIL = 'sent/:id',

  // Setting sub pages
  SETTINGS = 'settings',

  //Settings COMPANY
  SETTINGS_COMPANY = 'settings/company',
  SETTINGS_COMPANY_MAIN = 'company',

  // Settings USERS
  SETTINGS_USERS = 'settings/users',
  SETTINGS_USERS_MAIN = 'users',
  SETTINGS_USERS_USERS = 'users',
  SETTINGS_USERS_DIVISIONS = 'divisions',

  //Setting PARTNERS
  SETTINGS_PARTNERS = 'settings/partners',
  SETTINGS_PARTNERS_MAIN = 'partners',
  SETTINGS_PARTNERS_CHECK_DISABLED = 'settings/partners/check/disabled',
  SETTINGS_PARTNERS_DATA = 'partners/:id',
  SETTINGS_PARTNERS_DATA_EDIT = 'partners/:id/edit',
  SETTINGS_PARTNERS_BANKS = 'settings/partners/banks',
  BANKS = 'banks',

  // Settings bank accounts
  SETTINGS_BANK_ACCOUNTS_ARCHIVED = 'settings/bankaccounts/archived',
  SETTINGS_BANK_ACCOUNTS_ACTIVE = 'settings/bankaccounts/active',
  BANK_ACCOUNTS_ACTIVE = 'bankaccounts/active',
  SETTINGS_BANK_ACCOUNTS_MAIN = 'bankaccounts/:type',
  BANK_ACCOUNTS = 'bankaccounts',
  BANK_SETUP = 'bank',
  ACTIVE = 'active',
  ARCHIVED = 'archived',

  SETTINGS_INVOICE = 'settings/invoice',

  SETTINGS_FILLING = 'settings/filling',
  SETTINGS_ACCOUNTING = 'settings/accounting',
  SETTINGS_ACCOUNTING_DEFAULT = 'default',
  SETTINGS_ACCOUNTING_PERIOD = 'periods',
  SETTINGS_ACCOUNTING_PERIOD_PAGE = '/settings/accounting/periods',
  SETTINGS_ACCOUNTING_VAT_CODES = 'vat-codes',
  SETTINGS_ACCOUNTING_VAT_CODES_PAGE = '/settings/accounting/vat-codes',
  SETTINGS_ACCOUNTING_INVOICE_MIRRORS = 'invoice-mirrors',
  SETTINGS_ACCOUNTING_INVOICE_MIRRORS_PAGE = '/settings/accounting/invoice-mirrors',
  SETTINGS_ACCOUNTING_COST_LOCATIONS = 'cost-locations',
  SETTINGS_ACCOUNTING_COST_LOCATIONS_PAGE = '/settings/accounting/cost-locations',
  SETTINGS_ACCOUNTING_SCHEMAS = 'schemas',
  SETTINGS_ACCOUNTING_SCHEMAS_PAGE = '/settings/accounting/schemas',
  SETTINGS_ACCOUNTING_RULES = 'rules',
  SETTINGS_ACCOUNTING_RULES_PAGE = '/settings/accounting/rules',
  SETTINGS_ACCOUNTING_OTHER = 'other',
  SETTINGS_OTHER_PAGE = '/settings/accounting/other',

  // Settings list view
  SETTINGS_LIST_VIEW_DETAILS = 'settings/list-view-details',
  SETTINGS_LIST_VIEW_DETAILS_MAIN = 'list-view-details',
  SETTINGS_LIST_VIEW_DETAILS_CREATE = 'list-view-details/create',
  SETTINGS_LIST_VIEW_DETAILS_EDIT = 'list-view-details/:id/edit',
  SETTINGS_LIST_VIEW_DETAILS_COPY = 'list-view-details/:id/copy',

  COMPANY_PROCESSING = 'processing',
  COMPANY_DEBT = 'debt',
  NO_ACCESS = 'unauthorized',
  AGGREG8_PACKAGE = 'restricted-banking',

  //FAQ
  FAQ = 'faq',
  FAQ_GROUPS = 'groups',
  FAQ_SELECTED_GROUPS = '/faq/groups',

  //BUG REPORT
  BUG_REPORT = 'report',

  //File manager
  FILE_MANAGER = 'file-manager',
  FILE_MANAGER_ID = 'file-manager/:id'
}
