<table *ngIf="!!data; else loading" class="vertical-table">
  <ng-container *ngFor="let attribute of attributes">
    <ng-container *slmAccess="attribute.rights">
      <tr *ngIf="!attribute.hideIfEmpty || keyIsExist(attribute.key)">
        <td
          class="vertical-table__title"
          [class.vertical-table__title--no-wrap]="attribute.noWrapKey"
          [style.width]="attribute?.width ? attribute.width : ''"
          [style.min-width]="attribute?.width ? 'none' : ''"
          [style.max-width]="attribute?.width ? 'none' : ''"
        >
          <div class="block flex flex-items-center flex-start">
            {{ attribute.value | translate }}

            <slm-svg-icon
              *ngIf="attribute?.infoIconData?.icon"
              [icon]="attribute.infoIconData.icon"
              [color]="attribute.infoIconData?.color"
              [matTooltip]="attribute.infoIconData.tooltip | translate"
              class="vertical-table__icon d-inline-flex ml-1"
            ></slm-svg-icon>

          </div>

        </td>
        <td
          [class]="
              'vertical-table__data txt-' + (attribute.align || 'start') + '-i'
            "
          [class.vertical-table__data--no-wrap]="attribute.noWrapValue"
          [class.vertical-table__data--bold]="attribute.boldValue"
          [style.color]="attribute.color"
          [ngSwitch]="attribute.type"
          [style.font-weight]="attribute?.bold ? 900 : ''"
        >
          <ng-container *ngSwitchCase="'date'">{{
            (data[attribute.key] | dateTransform: attribute.dateFormat) ||
            attribute.replacement
            }}

          </ng-container>
          <ng-container *ngSwitchCase="'translate'">{{
            (data[attribute.key] | translate) || attribute.replacement
            }}</ng-container>
          <ng-container *ngSwitchCase="'currency'">{{
            (data[attribute.key]
              | currencyTransform
              : data[attribute.currencyKey] || attribute.defaultCurrency) ||
            attribute.replacement
            }}</ng-container>
          <ng-container *ngSwitchCase="'text-link'">{{
            (data[attribute.key]
              | currencyTransform
              : data[attribute.currencyKey] || attribute.defaultCurrency) ||
            attribute.replacement
            }}</ng-container>
          <div *ngSwitchCase="'text-link'"
               class="flex">
            <div *ngIf="data[attribute.key].label" [translate]="data[attribute.key].label"></div>
            -
            <a *ngIf="data[attribute.key].link"
               target="_blank"
               [href]="data[attribute.key].link" [translate]="data[attribute.key].linkText"></a>
          </div>

          <div *ngSwitchCase="'simple-text-link'"
               class="flex">
            <a *ngIf="data[attribute.key].link"
               target="_blank"
               [href]="data[attribute.key].link" [translate]="data[attribute.key].linkText"></a>
          </div>
          <div *ngSwitchCase="'icon'"
               class="flex flex-items-center">
            <ng-container *ngIf="data[attribute.key]">
              <slm-svg-icon *ngIf="data[attribute.key].icon"
                            [style.fill]="data[attribute.key].color"
                            [icon]="data[attribute.key].icon"
                            [matTooltip]="data[attribute.key].label | translate"
                            class="block m-auto"></slm-svg-icon>
              <div *ngIf="!data[attribute.key].icon && data[attribute.key].label"
                   class="block m-auto txt-center">{{data[attribute.key].label}}</div>
            </ng-container>
            <ng-container *ngIf="!data[attribute.key]">
              <slm-loader diameter="15" class="block m-auto"></slm-loader>
            </ng-container>
          </div>
          <ng-container *ngSwitchDefault>{{
            data[attribute.key] || attribute.replacement
            }}</ng-container>
        </td>
        <ng-container *ngIf="attribute.thirdColumnConfig">
          <ng-container
            *slmAccess="attribute.thirdColumnConfig.rights"
          >
            <td [ngSwitch]="attribute.thirdColumnConfig.type" class="vertical-table__link">
              <slm-button-icon *ngSwitchCase="'button'"
                               [class]="attribute.thirdColumnConfig.iconStyle"
                               [icon]="attribute.thirdColumnConfig.icon"
                               [matTooltip]="attribute.thirdColumnConfig.text | translate"
                               elevationDefault="true"
                               [loading]="loadingAttributes.includes(attribute.key)"
                               (click)="thirdColumnClicked.emit(attribute.thirdColumnConfig.action)"
                               [testId]="attribute.thirdColumnConfig.testId"></slm-button-icon>
              <a *ngSwitchCase="'link'"
                 (click)="attribute.thirdColumnConfig.action ? thirdColumnClicked.emit(attribute.thirdColumnConfig.action) : null"
                 [routerLink]="(attribute.thirdColumnConfig.route || data[attribute.thirdColumnConfig.urlAttribute]) ?
                 ((attribute.thirdColumnConfig.route || data[attribute.thirdColumnConfig.urlAttribute]) | routeParam: data) : null"
                 [attr.data-test-id]="attribute.thirdColumnConfig.testId || 'tableLink'">{{attribute.thirdColumnConfig.text | translate}}
              </a>
              <slm-svg-icon *ngSwitchCase="'icon'"
                [class]="attribute.thirdColumnConfig.iconStyle"
                [icon]="attribute.thirdColumnConfig.icon"
                [matTooltip]="attribute.thirdColumnConfig.text | translate"></slm-svg-icon>
            </td>
          </ng-container>
        </ng-container>
      </tr>
    </ng-container>
  </ng-container>
</table>
<ng-template #loading>
  <slm-loader></slm-loader>
</ng-template>
