import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const dropdownFadeAnimation = trigger('dropdownFade', [
  // the "in" style determines the "resting" state of the element when it is visible.
  state('in', style({ opacity: 1, height: '*' })),

  // fade in when created. this could also be written as transition('void => *')
  transition(':enter', [
    style({
      height: 0,
      opacity: 0
    }),
    animate('300ms cubic-bezier(.35, 0, .25, 1)')
  ]),

  // fade out when destroyed. this could also be written as transition('void => *')
  transition(
    ':leave',
    animate(
      '300ms cubic-bezier(.35, 0, .25, 1)',
      style({
        height: 0,
        opacity: 0
      })
    )
  )
]);
