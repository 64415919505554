import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import {ModuleWithProviders, NgModule, Provider} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule
} from '@angular/material/form-field';
import {TextInputComponent} from './components/inputs/text-input/text-input.component';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatNativeDateModule,
  MatRippleModule
} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {ButtonComponent} from './components/buttons/button/button.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SvgIconComponent} from './components/svg-icon/svg-icon.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {SideNavComponent} from './components/side-nav/side-nav.component';
import {SideNavItemComponent} from './components/side-nav-item/side-nav-item.component';
import {ButtonIconComponent} from './components/buttons/button-icon/button-icon.component';
import {SelectInputComponent} from './components/inputs/select-input/select-input.component';
import {MatSelectModule} from '@angular/material/select';
import {
  QuestionDialogComponent,
  InvoicePaymentDialogComponent,
  DocumentUploadDialogComponent,
  DeleteDialogComponent,
  HelpDialogComponent
} from './dialog';
import {
  FileSizePipe,
  DateTransformPipe,
  RouteParamPipe,
  PictureAsyncPipe,
  PicturePipe,
  CurrencyTransformPipe,
  NamePipe,
  BankAccountPipe,
  MultiplicationPipe,
  AdminInternalMessagePipe,
  InvoiceInternalMessagePipe
} from './pipes';
import {ErrorContainer, PartnerBankAccountForm, PartnerFormContainer, PayoutTemplateContainer, RegistrationForm} from './containers';
import {MonogramComponent} from './components/monogram/monogram.component';
import {PageContainerComponent} from './components/page-container/page-container.component';
import {TableContainerComponent} from './components/tables/table-container/table-container.component';
import {PaginatorComponent} from './components/tables/paginator/paginator.component';
import {TableIconTextComponent} from './components/tables/table/elements/table-icon-text/table-icon-text.component';
import {TableUserComponent} from './components/tables/table/elements/table-user/table-user.component';
import {TableTextComponent} from './components/tables/table/elements/table-text/table-text.component';
import {TableDateComponent} from './components/tables/table/elements/table-date/table-date.component';
import {TableMenuComponent} from './components/tables/table/elements/table-menu/table-menu.component';
import {TableComponent} from './components/tables/table/table.component';
import {MatSortModule} from '@angular/material/sort';
import {ImageListComponent} from './components/image-list/image-list.component';
import {BottomBarComponent} from './components/bottom-bar/bottom-bar.component';
import {PagerComponent} from './components/tables/pager/pager.component';
import {TextAreaComponent} from './components/inputs/text-area/text-area.component';
import {MatDialogModule} from '@angular/material/dialog';
import {DialogContainerComponent} from './components/dialog-container/dialog-container.component';
import {DragAndDropComponent} from './components/drag-and-drop/drag-and-drop.component';
import {TabsComponent} from './components/tabs/tabs.component';
import {TableCardComponent} from './components/tables/table-card/table-card.component';
import {VerticalTableComponent} from './components/tables/vertical-table/vertical-table.component';
import {AutocompleteInputComponent} from './components/inputs/text-autocomplete-input/autocomplete-input.component';
import {CheckboxComponent} from './components/inputs/checkbox/checkbox.component';
import {SimpleTableComponent} from './components/simple-table/simple-table.component';
import {MessageComponent} from './components/message/message.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DateRangeInputComponent} from './components/inputs/date-range-input/date-range-input.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {StepperComponent} from './components/stepper/stepper.component';
import {SelectorComponent} from './components/selector/selector.component';
import {InternalMessagesComponent} from './components/internal-messages/internal-messages.component';
import {DateInputComponent} from './components/inputs/date-input/date-input.component';
import {
  MAT_CHIPS_DEFAULT_OPTIONS,
  MatChipsModule
} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {ChipInputComponent} from './components/inputs/chip-input/chip-input.component';
import {TableSelectionButtonComponent} from './components/tables/table/elements/selection-button-text/table-selection-button.component';
import {CounterComponent} from './components/counter/counter.component';
import {
  DragAndDropDirective,
  BankAccountFormatDirective,
  UppercaseFormatDirective,
  AccessDirective
} from './directives';
import {TableFilterComponent} from './components/tables/table-filter/table-filter.component';
import {BaseChartDirective, provideCharts, withDefaultRegisterables} from 'ng2-charts';
import {ChartComponent} from './components/chart/chart.component';
import localeEn from '@angular/common/locales/en';
import localeHu from '@angular/common/locales/hu';
import localeHuExtra from '@angular/common/locales/extra/hu';
import {
  customDateFormat,
  DatePickerAdapter
} from './adapters/date-picker.adapter';
import {DocumentItemComponent} from './components/document-item/document-item.component';
import {VatBalanceComponent} from './components/vat-balance/vat-balance.component';
import {CashFlowBalanceOverviewComponent} from './components/cash-flow-balance/cash-flow-balance-overview.component';
import {InvoiceOverviewComponent} from './components/invoice-ovierview/invoice-overview.component';
import {CashFlowChartComponent} from './components/cash-flow-chart/cash-flow-chart.component';
import {CashFlowInvoicesOverviewComponent} from './components/cash-flow-invoices-overview/cash-flow-invoices-overview.component';
import {PaymentOverviewComponent} from './components/payment-overview/payment-overview.component';
import {TableStepperComponent} from './components/tables/table/elements/table-stepper/table-stepper.component';

import {EditorComponent} from './components/editor/editor.component';
import {EditorPresenterComponent} from './components/editor-presenter/editor-presenter.component';
import {SelectAutocompleteInputComponent} from './components/inputs/select-autocomplete-input/select-autocomplete-input.component';
import {TitledCardComponent} from './components/titled-card/titled-card.component';
import {LoaderComponent} from './components/loader/loader.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatBadgeModule} from '@angular/material/badge';
import {BankAccountInputComponent} from './components/inputs/bank-account-input/bank-account-input.component';
import {AmountInputComponent} from './components/inputs/amount-input/amount-input.component';
import {MatButtonModule} from '@angular/material/button';
import {TableCounterTextComponent} from './components/tables/table/elements/table-counter-text/table-counter-text.component';
import {ImageZoomComponent} from './components/image-zoom/image-zoom.component';
import {LastUpdateComponent} from './components/last-update/last-update.component';
import {TableTextStatusComponent} from './components/tables/table/elements/table-text-status/table-text-status.component';
import {PasswordStrengthPresenterComponent} from './components/password-strength-presenter/password-strength-presenter.component';
import {SimpleDragAndDropComponent} from './components/simple-drag-and-drop/simple-drag-and-drop.component';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {HttpClientModule} from '@angular/common/http';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ToggleComponent} from './components/inputs/toggle/toggle.component';
import {TableAmountComponent} from './components/tables/table-amounts/table-amount.component';
import {PartnerNegativeInfoComponent} from './components/partner-negative-info/partner-negative-info.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {TemplatePresenterComponent} from './components/template-presenter/template-presenter.component';
import {TableEditableComponent} from './components/tables/table/elements/table-editable/table-editable.component';
import {DataImportDialog} from './dialog/data-import/data-import.dialog';
import {NavRefreshDialog} from './dialog/nav-refresh/nav-refresh.dialog';
import {ValueStepperComponent} from './components/inputs/value-stepper/value-stepper.component';
import {TableTextButtonComponent} from './components/tables/table/elements/table-text-button/table-text-button.component';
import {CloseScrollStrategy, Overlay} from '@angular/cdk/overlay';
import {ChipAutocompleteComponent} from './components/inputs/chip-autocomplete/chip-autocomplete.component';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {DragDropTableComponent} from './components/drag-drop-tables/drag-drop-table/drag-drop-table.component';
import {CdkDrag, CdkDragPlaceholder, CdkDragPreview, CdkDropList, CdkDropListGroup} from '@angular/cdk/drag-drop';
import {FileManagerContainer} from './containers/file-manager/file-manager.container';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {NgSwitchMultiCasePipe} from './pipes/ngSwitchMultiCase.pipe';
import {PdfViewerComponent} from './components/pdf-viewer/pdf-viewer.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {NoImageComponent} from './components/no-image/no-image.component';
import {TableCounterIconComponent} from './components/tables/table/elements/table-counter-icon/table-counter-icon.component';
import {UserDivisionSelectComponent} from './components/user-division-select/user-division-select.component';
import {QuillModule} from 'ngx-quill';
import {GroupedSelectInputComponent} from './components/inputs/grouped-select-input/grouped-select-input.component';
import {Aggreg8PackageContainer} from './containers/aggreg8-package/aggreg8-package.container';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeHu, 'hu', localeHuExtra);

export const scrollCloseOptions = (overlay: Overlay): () => CloseScrollStrategy => () => overlay.scrollStrategies.close();

const otherModules = [
  AngularSvgIconModule.forRoot(),
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  BaseChartDirective,
  HttpClientModule,
  QuillModule.forRoot(),
  LoadingBarModule,
  LoadingBarHttpClientModule,
  TranslateModule,
  NgxMaskDirective,
  NgxMaskPipe
];

const materialModules = [
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatDatepickerModule,
  MatRippleModule,
  MatFormFieldModule,
  MatExpansionModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatSortModule,
  MatSidenavModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatStepperModule,
  ScrollingModule,
  CdkDropList,
  CdkDrag,
  CdkDropListGroup,
];

const pipes = [
  BankAccountPipe,
  CurrencyTransformPipe,
  FileSizePipe,
  NamePipe,
  PicturePipe,
  RouteParamPipe,
  DateTransformPipe,
  PictureAsyncPipe,
  MultiplicationPipe,
  AdminInternalMessagePipe,
  InvoiceInternalMessagePipe,
  NgSwitchMultiCasePipe
];

const components = [
  AmountInputComponent,
  AutocompleteInputComponent,
  BankAccountInputComponent,
  BottomBarComponent,
  ButtonComponent,
  ButtonIconComponent,
  CashFlowBalanceOverviewComponent,
  CashFlowChartComponent,
  CashFlowInvoicesOverviewComponent,
  ChartComponent,
  CheckboxComponent,
  ChipInputComponent,
  CounterComponent,
  DateInputComponent,
  DateRangeInputComponent,
  DialogContainerComponent,
  DocumentItemComponent,
  DragAndDropComponent,
  EditorComponent,
  EditorPresenterComponent,
  ImageListComponent,
  ImageZoomComponent,
  InvoiceOverviewComponent,
  InternalMessagesComponent,
  LoaderComponent,
  MessageComponent,
  MonogramComponent,
  SelectAutocompleteInputComponent,
  SelectInputComponent,
  SelectorComponent,
  SideNavComponent,
  SideNavItemComponent,
  SimpleDragAndDropComponent,
  SimpleTableComponent,
  StepperComponent,
  SvgIconComponent,
  TableContainerComponent,
  TextInputComponent,
  PageContainerComponent,
  PagerComponent,
  PaginatorComponent,
  PasswordStrengthPresenterComponent,
  PartnerNegativeInfoComponent,
  PaymentOverviewComponent,
  TableAmountComponent,
  TableCardComponent,
  TableComponent,
  TableCounterTextComponent,
  TableCounterIconComponent,
  TableDateComponent,
  TableFilterComponent,
  TableIconTextComponent,
  TableMenuComponent,
  TableUserComponent,
  TableSelectionButtonComponent,
  TableTextComponent,
  TableTextButtonComponent,
  TableTextStatusComponent,
  TableEditableComponent,
  TableStepperComponent,
  TabsComponent,
  TextAreaComponent,
  TitledCardComponent,
  ToggleComponent,
  ValueStepperComponent,
  VatBalanceComponent,
  VerticalTableComponent,
  LastUpdateComponent,
  TemplatePresenterComponent,
  ChipAutocompleteComponent,
  DragDropTableComponent,
  BreadcrumbComponent,
  PdfViewerComponent,
  NoImageComponent,
  UserDivisionSelectComponent,
  NoImageComponent,
  NoImageComponent,
  GroupedSelectInputComponent
];

const forms = [RegistrationForm, PartnerFormContainer, PartnerBankAccountForm];

const dialogs = [
  DataImportDialog,
  DeleteDialogComponent,
  DocumentUploadDialogComponent,
  HelpDialogComponent,
  InvoicePaymentDialogComponent,
  NavRefreshDialog,
  QuestionDialogComponent
];

const containers = [ErrorContainer, PayoutTemplateContainer, FileManagerContainer, Aggreg8PackageContainer];

const directives: Array<any> = [
  BankAccountFormatDirective,
  DragAndDropDirective,
  AccessDirective,
  UppercaseFormatDirective
];

const providers: Array<Provider> = [
  DatePipe,
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      appearance: 'outline'
    } as MatFormFieldDefaultOptions
  },
  {
    provide: MAT_CHIPS_DEFAULT_OPTIONS,
    useValue: {
      separatorKeyCodes: [ENTER, COMMA]
    }
  },
  {provide: DateAdapter, useClass: DatePickerAdapter},
  {provide: MAT_DATE_FORMATS, useValue: customDateFormat},
  {provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY, useFactory: scrollCloseOptions, deps: [Overlay] },
  provideCharts(withDefaultRegisterables()),
  provideNgxMask()
];

@NgModule({
  imports: [
    CommonModule,
    ...materialModules,
    ...otherModules,
    CdkDragPreview,
    CdkDragPlaceholder,
    PdfViewerModule
  ],
  providers: [...providers],
  declarations: [
    ...pipes,
    ...components,
    ...dialogs,
    ...directives,
    ...containers,
    ...forms
  ],
  exports: [
    ...pipes,
    ...components,
    ...dialogs,
    ...directives,
    ...containers,
    ...materialModules,
    ...otherModules,
    ...forms
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
