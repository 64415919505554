import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';

const DEF_CACHE_LENGTH = 20;
const TTL = 1_800_000;

interface CacheEntry {
  response: HttpResponse<any>;
  expiresOn: number;
}

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache: Map<string, CacheEntry> = new Map();

  public addToCache(url: string, res: HttpResponse<any>) {
    if (this.cache.size >= DEF_CACHE_LENGTH){
      this.deleteFirstElementFromCache();
    }
    this.cache.set(url, {
      response: res,
      expiresOn: new Date().getTime() + TTL});
  }

  public getFromCache(url: string): HttpResponse<any> {
    const cached = this.cache.get(url);
    const hasExpired = new Date().getTime() >= cached?.expiresOn;
    if (hasExpired){
      this.cache.delete(url);
      return null;
    }

    return this.cache.get(url)?.response;
  }

  public deleteFromCache(url: string) {
    this.cache.delete(url);
  }

  private deleteFirstElementFromCache(){
    this.cache.delete(this.cache.keys().next().value);
  }
}
