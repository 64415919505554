<div
  class="grid grid-col-3-33-1 p-2 grid-responsive grid-responsive-no-gap"
  *ngIf="balance; else loading"
>
  <div>
    <h5 class="w-100 txt-start-i" translate>{{ labels?.expired }}</h5>
    <h2 class="color-accent w-100 block txt-start-i">
      {{ balance.expiredAmount | currencyTransform: currency }}
    </h2>
    <div *slmAccess="rights.DOCUMENT_LISTING_READ">
<!--      <a
        *ngIf="!!balance.expiredAmountCount"
        [routerLink]="labels?.route"
        [queryParams]="labels?.expiredCountParams"
        >{{
          labels?.expiredCount
            | translate: { count: balance.expiredAmountCount }
        }}</a>-->
      <div *ngIf="!!balance.expiredAmountCount"  class="txt-medium">{{
        labels?.expiredCount
          | translate: { count: balance.expiredAmountCount }
        }}</div>
    </div>
    <div *ngIf="!balance.expiredAmountCount" translate>
      {{ labels?.expiredCount + "_0" }}
    </div>
  </div>
  <div>
    <h5 class="w-100 txt-start-i" translate>{{ labels?.notExpired }}</h5>
    <h2 class="w-100 block txt-start-i">
      {{ balance.notExpiredAmount | currencyTransform: currency }}
    </h2>
    <div *slmAccess="rights.DOCUMENT_LISTING_READ">
<!--      <a
        *ngIf="!!balance.notExpiredAmountCount"
        [routerLink]="labels?.route"
        [queryParams]="labels?.notExpiredCountParams"
        >{{
          labels?.notExpiredCount
            | translate: { count: balance.notExpiredAmountCount }
        }}</a>-->
      <div *ngIf="!!balance.notExpiredAmountCount"  class="txt-medium">{{
        labels?.notExpiredCount
          | translate: { count: balance.notExpiredAmountCount }
        }}</div>
    </div>
    <div *ngIf="!balance.notExpiredAmountCount" translate>
      {{ labels?.notExpiredCount + "_0" }}
    </div>
  </div>

  <div>
    <h5 class="w-100 txt-start-i" translate>{{ labels?.sum }}</h5>
    <h2 class="w-100 block txt-start-i">
      {{ balance.sumAmount | currencyTransform: currency }}
    </h2>
    <div *slmAccess="rights.DOCUMENT_LISTING_READ">
    <!--<a *ngIf="!!balance.sumAmountCount" [routerLink]="labels?.route" [queryParams]="labels?.notExpiredParams">{{
        labels?.sumCount | translate: { count: balance.sumAmountCount }
      }}</a>-->
      <div *ngIf="!!balance.sumAmountCount" class="txt-medium">{{
        labels?.sumCount | translate: { count: balance.sumAmountCount }
        }}</div>
    </div>
    <div *ngIf="!balance.sumAmountCount" translate>
      {{ labels?.sumCount + "_0" }}
    </div>
  </div>
</div>

<ng-template #loading>
  <slm-loader class="block m-auto mt-6 mb-5"></slm-loader>
</ng-template>
