import {partnerActions} from './partner.actions';
import { Partner } from '../../global';
import {StoreReducer} from '../../global/entities/store';
import {Action, createReducer, on} from '@ngrx/store';

export interface PartnerState {
  selectedPartner: Partner;
  selectedPartnerId: number;
}

export const initialState: PartnerState = {
  selectedPartner: null,
  selectedPartnerId: null
};

export const partnerReducerFunction = (
  state = initialState,
  action: Action
): PartnerState => {
  const reducer = createReducer(
    initialState,
    on(partnerActions.selectPartner, (state, action: any) => ({
      ...state, selectedPartnerId: action.id
    })),
    on(partnerActions.loadPartner, (state, action: any) => ({
      ...state, selectedPartner: action.partner
    })),
    on(partnerActions.clearPartner, (_, __) => ({
      ...initialState
    }))
    );
  return reducer(state, action);
};

export const partnerReducer: StoreReducer<PartnerState> = {
  name: 'partner',
  reducer: partnerReducerFunction
};
