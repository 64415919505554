import { Component, Input } from '@angular/core';
import { Icons } from '../../../../global';
import { InputAbstract } from '../base/input.abstract';

@Component({
  selector: 'slm-text-area',
  templateUrl: './text-area.component.html',
  styles: [`
    :host {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
    }
  `]
})
export class TextAreaComponent extends InputAbstract {
  @Input() buttonIcon = true;
  @Input() enterEnabled = false;
  @Input() prefixIcon: Icons = null;
  @Input() suffixIcon: Icons = null;
  @Input() suffixIconLabel = '';
  @Input() maxRow = 5;
  @Input() minRow = 2;
  @Input() maxLength = 0;

  public clear(): void {
    if (this.control) {
      this.control.setValue('');
    }
  }
}
