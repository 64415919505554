import {PartnerContactInfo, PartnerInvoicingInfo, PartnerSave, PartnerValidationMessage, TableTextStatus} from '../entities';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {utils} from './converter.functions';
import {Icon, IconColors, LegalClassEnum, NotificationEnum} from '../enums';
import {getErrorLabel} from './form-error-handler';

export class PartnerFunctions {

  static getPartnerObject(form: UntypedFormGroup, params:
    {
      isUpdate?: boolean;
      status?: string;
      id?: number;
      deletedBankAccounts?: Array<number>;
    }): PartnerSave {
    const partnerForm = form.value;
    const invoice: PartnerInvoicingInfo = {
      email: (partnerForm.partnerEmails || []).join(';'),
      emailLanguage: partnerForm.partnerEmailLanguage,
      paymentType: partnerForm.paymentType,
      invoiceNote: partnerForm.invoiceNote,
      paymentTime: partnerForm.paymentTime
    };
    const contact: PartnerContactInfo = {
      name: partnerForm.name,
      role: partnerForm.role,
      email: partnerForm.email,
      phone: partnerForm.phone,
      comments: partnerForm.comments
    };
    return {
      ...(params?.isUpdate ? {id: params?.id} : {}),
      status: params?.status,
      name: partnerForm.partnerName || '',
      legalClass: partnerForm.legalClass,
      zipCode: partnerForm.zipCode,
      city: partnerForm.city,
      countryId: partnerForm.country?.value,
      addressHouseNumber: partnerForm.address,
      addressStreet: partnerForm.street,
      taxAdministrationNumber: partnerForm.vatNumber || '',
      publicTaxIdNumber: partnerForm.euTaxNumber,
      groupVatNumber: partnerForm.groupVatNumber || '',
      thirdPartyVat: partnerForm.thirdPartyVat || '',
      taxType: partnerForm.taxType,
      externalSystemIdentifier:
        partnerForm.externalSystemIdentifier ||
        form.get('externalSystemIdentifier').value,

      //invoice data
      invoice,
      // contact data
      contact,
      deletedBankAccounts: params?.deletedBankAccounts || [],
      bankAccounts: (form.get('bankAccounts') as UntypedFormArray).getRawValue().map((account) => ({
        id: account.id,
        bankId: account.bankAccountId?.value === -1 ? null : account.bankAccountId?.value,
        accountName: account.accountName,
        iban: account.iban ? `${(account?.countryCode || account.bankAccountId?.data?.countryCode || '')
          .toUpperCase()}${utils.removeWhiteSpaces(account.iban)}` : '',
        other: utils.removeWhiteSpaces(account?.other || ''),
        accountNumber: utils.removeWhiteSpaces(account.accountNumber),
        default: account.default,
        ...account.bankAccountId?.value === -1 ? {
          swift: account.swift?.value ?? account.swift,
          countryCode: account.countryCode,
          bankName: account.bankName
        } : {}
      })),
      vendorLedgerNumberId: partnerForm.vendorLedgerNumber?.data?.id || null,
      customerLedgerNumberId: partnerForm.customerLedgerNumber?.data?.id || null,
      defaultCurrency: partnerForm?.defaultCurrency || null
    };
  }

  static validateData(partnerForm: UntypedFormGroup): PartnerValidationMessage {
    let vatIsSet = true;
    if (!partnerForm.get('vatNumber').value &&
      !partnerForm.get('euTaxNumber').value &&
      !partnerForm.get('thirdPartyVat').value &&
      (partnerForm.get('legalClass').value !== LegalClassEnum.PRIVATE_PERSON
      && partnerForm.get('legalClass').value !== LegalClassEnum.THIRD_COUNTRY)) {
      vatIsSet = false;
    }

    if (
      partnerForm.invalid ||
      !vatIsSet
    )  {
      let label = getErrorLabel(partnerForm);
      if (label?.controlName === 'bankAccounts') {
        const invalidBankAccountForm = (partnerForm.get('bankAccounts') as UntypedFormArray).controls.find(form => form.invalid);
        label = getErrorLabel(invalidBankAccountForm as UntypedFormGroup);
      }
      let notification;
      let interpolateParams: object;
      if (!vatIsSet) {
        notification = 'MESSAGE.NO_VAT_CODES';
      } else {
        if(!label || !label.label){
          notification = 'MESSAGE.WARNING_INVALID_FORM';
        }else{
          notification = 'MESSAGE.WARNING_INVALID_FORM_VALUE';
          interpolateParams = { input: label.label };
        }
      }

      partnerForm.markAllAsTouched();
      partnerForm.updateValueAndValidity();

      return {
        message: notification,
        type: NotificationEnum.WARNING,
        params: interpolateParams
      };
    } else {
      const partnerBanks = partnerForm.get('bankAccounts') as UntypedFormArray;
      partnerBanks.markAllAsTouched();
      const invalidBanks = (partnerBanks).getRawValue().filter((account) => !account.accountNumber && !account.iban && !account?.other);
      if (invalidBanks.length) {
        return {
          message: 'LBL_PARTNERS.REQUIRED_BANK_ACCOUNT_NUMBER',
          type: NotificationEnum.ERROR
        };
      }

      const invalidOther = (partnerBanks).getRawValue().filter((account) => account.accountNumber && account?.other);
      if (invalidOther.length) {
        return {
          message: 'LBL_PARTNERS.BANK_ACCOUNT_OTHER_ERROR',
          type: NotificationEnum.ERROR
        };
      }

      return null;
    }
  }

  static getPartnerNegativeStateIcon(partner: any): TableTextStatus{
    const tooltip = partner?.negativeInfo && partner?.euNegativeInfo ? 'LBL_PARTNERS.NEGATIVE_INFO_AND_NEGATIVE_EU_VAT' :
      partner?.negativeInfo ? 'LBL_PARTNERS.NEGATIVE_INFO' :
        partner?.euNegativeInfo ? 'LBL_PARTNERS.NEGATIVE_EU_VAT_NUMBER_SIMPLE' :
          null;

    return ({
      color: IconColors.ORANGE,
      statusIcon: partner?.negativeInfo || partner?.euNegativeInfo ? Icon.PRIORITY_HIGH : null,
      enableTextTooltip: true,
      statusAlign: 'end',
      statusTooltip: tooltip,
      text: partner?.name ?? '-'
    });
  }
}
