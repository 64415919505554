import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RoutesEnum} from '../../../global';

export type BreadcrumbSection = {
    id: number | string;
    name: string;
}

@Component({
  selector: 'slm-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() sections: Array<BreadcrumbSection>;
  @Input() baseUrl:  RoutesEnum;

  @Output() breadcrumbClicked = new EventEmitter<string>();

  public trackById = (_, item: BreadcrumbSection) => item.id;
}
