import { Component, Input } from '@angular/core';

@Component({
  selector: 'slm-editor-presenter',
  template: `
    <quill-view-html
      [content]="content"
      class="overflow-hidden block pos-relative"
      [ngClass]="{ 'border radius-big': !hideBorder }"
    ></quill-view-html>
  `
})
export class EditorPresenterComponent {
  @Input() public content = '';
  @Input() public hideBorder = false;
}
