<div
  cdk-scrollable
  class="table overflow-y-auto h-max-inherit"
  *ngIf="!stickyHeader; else stickyTable"
>
  <ng-container *ngTemplateOutlet="table"></ng-container>
</div>

<ng-template #stickyTable>
  <div class="table overflow-y-auto h-max-inherit">
    <ng-container *ngTemplateOutlet="table"></ng-container>
  </div>
</ng-template>

<ng-template #table>
  <table
    @stagger
    mat-table
    matSort
    [matSortDisableClear]="disableClearSort"
    [matSortActive]="sort?.attribute"
    [matSortDisabled]="loading"
    [matSortDirection]="sort?.order"
    [dataSource]="loading ? null : dataSource"
    (matSortChange)="sortChanged($event)"
  >
    <ng-container *ngFor="let column of config">
      <ng-container [matColumnDef]="column.attributeName">
        <!-- Checkbox Column -->
        <ng-container *ngIf="columnTypes.CHECKBOX === column.columnType">
          <th mat-header-cell *matHeaderCellDef class="table__column--checkbox">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
              [disabled]="masterCheckboxDisabled"
              data-test-id="masterTableCheckbox"
              color="primary"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" class="table__column--checkbox">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="selectionChanged($event, row)"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
              [disabled]="row.checkboxDisabled"
              data-test-id="tableCheckbox"
              color="primary"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header=""
          [id]="column.attributeName"
          [start]="column.sortStart"
          [disabled]="column.sortDisabled"
          class="no-last-padding"
        >
          <ng-container
          *ngIf="columnTypes.MENU === column.columnType && column.viewSettingOptions"
          >
            <slm-table-menu
              [menu]="column.viewSettingOptions"
              maxHeight="h-max-250"
              (fireEvent)="tableEvent($event, 'viewSetting', '', $event)"
              icon="settings"
            ></slm-table-menu>
          </ng-container>
          {{ column.label | translate }}
        </th>

        <td
          mat-cell
          *matCellDef="let element"
          [ngSwitch]="column.columnType"
          [class.table__row__cell--clickable]="!column.notClickable"
          (click)="!column.notClickable ? openItem(element) : null"
          (mousedown)="isItemMiddleClicked($event, element)"
        >
          <!-- Date Column -->
          <slm-table-date
            *ngSwitchCase="columnTypes.DATE"
            [data]="element[column.attributeName]"
            [align]="column.align"
            [class.m-auto]="column.align === 'center'"
            [format]="column.dateFormat"
            [tooltip]="element['tooltipLabel-' + column.attributeName]"
            [style.color]="element.textColor"
          ></slm-table-date>

          <!-- Date Interval Column -->
          <slm-table-date
            *ngSwitchCase="columnTypes.DATE_INTERVAL"
            [data]="element[column.attributeName]"
            [align]="column.align"
            [class.m-auto]="column.align === 'center'"
            [format]="column.dateFormat"
            [tooltip]="element['tooltipLabel-' + column.attributeName]"
          ></slm-table-date>

          <!-- Text Column -->
          <slm-table-text
            *ngSwitchCase="columnTypes.TEXT"
            [style.max-width]="column.maxWidth || '200px'"
            [data]="element[column.attributeName]"
            [align]="column.align"
            [class.m-auto]="column.align === 'center'"
            [tooltip]="element['tooltipLabel-' + column.attributeName]"
            [style.color]="element.textColor"
          ></slm-table-text>

          <!-- Text With Icon Column -->
          <slm-table-icon-text
            *ngSwitchCase="columnTypes.ICON_TEXT"
            [style.max-width]="column.maxWidth || '200px'"
            [data]="element[column.attributeName]"
            [align]="column.align"
            [class.m-auto]="column.align === 'center'"
            [tooltip]="element['tooltipLabel-' + column.attributeName]"
          ></slm-table-icon-text>

          <!-- User Column -->
          <slm-table-user
            *ngSwitchCase="columnTypes.USER"
            [data]="element[column.attributeName]"
            [align]="column.align"
            [class.m-auto]="column.align === 'center'"
            [tooltip]="element['tooltipLabel-' + column.attributeName]"
          ></slm-table-user>

          <!-- Text Button Column -->
          <slm-table-text-button
            *ngSwitchCase="columnTypes.TEXT_BUTTON"
            [data]="element[column.attributeName]"
            [align]="column.align"
            [textColor]="element.textColor"
            [class.m-auto]="column.align === 'center'"
            [tooltip]="element['tooltipLabel-' + column.attributeName]"
            (fireEvent)="tableEvent($event, column.attributeName, null, element?.originalTableData ?? element)"
          ></slm-table-text-button>


          <!-- Menu Column -->
          <slm-table-menu
            *ngSwitchCase="columnTypes.MENU"
            [menu]="column.menuItems"
            [data]="element.originalTableData || element"
            (fireEvent)="menuEvent($event, element)"
          ></slm-table-menu>

          <!-- Stepper Column -->
          <slm-table-stepper
            *ngSwitchCase="columnTypes.STEPPER"
            class="block"
            [style.max-width]="column.maxWidth || '200px'"
            [class.m-auto]="column.align === 'center'"
            [data]="element[column.attributeName]"
          ></slm-table-stepper>

          <!-- Custom Button Column -->
          <slm-button
            *ngSwitchCase="columnTypes.CUSTOM_BUTTON"
            class="block"
            [config]="column?.buttonConfig"
            [style.max-width]="column.maxWidth || '200px'"
            (click)="
              tableEvent({
                event: column?.buttonConfig?.action || 'customButton',
                data: element
              }, column.attributeName)
            "
            [class.m-auto]="column.align === 'center'"
          ></slm-button>

          <!-- Currency Column -->
          <h5
            *ngSwitchCase="columnTypes.CURRENCY"
            [matTooltip]="element['tooltipLabel-' + column.attributeName] | translate"
            [class]="'w-100 m-0 block table__column--no-wrap txt-' + (column.align || 'center') + '-i'"
            [style.color]="element.textColor"
          >
            {{
            element[column.attributeName] | currencyTransform: element.currency}}
          </h5>

          <ng-container
            *ngSwitchCase="columnTypes.PAYMENT_DETAILED"
          >
            <h5
              *ngIf="element[column.attributeName].label"
              matTooltip="
                 {{element[column.attributeName].grossValue?
                  (element[column.attributeName].grossValue | currencyTransform: element.currency)
                  : element[column.attributeName].label | translate}}
                (
                  {{element[column.attributeName].date}} -
                  {{element[column.attributeName].fullName}} -
                  {{element[column.attributeName].label | translate}}
                )"
              [class]="'w-100 m-0 block table__column--no-wrap txt-' + (column.align || 'center') + '-i'"
              [style.color]="element.textColor"
              [style.max-width]="column.maxWidth || '200px'"
            >

              {{element[column.attributeName].grossValue?
                (element[column.attributeName].grossValue | currencyTransform: element.currency)
                : element[column.attributeName].label | translate}}
              (
                {{element[column.attributeName].date}} -
                {{element[column.attributeName].fullName}} -
                {{element[column.attributeName].label | translate}}
              )
            </h5>
          </ng-container>

          <!-- Editable Text Column -->
          <slm-table-editable
            *ngSwitchCase="columnTypes.EDITABLE"
            [data]="element[column.attributeName]"
            [class.m-auto]="column.align === 'center'"
            [tooltip]="element['tooltipLabel-' + column.attributeName]"
            [textColor]="element.textColor"
            [disable]="element.disable"
            [style.text-decoration]="element.decoration"
            class="block"
            [align]="column.align"
            [style.max-width]="column.maxWidth || '120px'"
            (fireEvent)="tableEvent($event, column.attributeName, element.id)"
          ></slm-table-editable>

          <!-- Counter text Column -->
          <slm-table-counter-text
            *ngSwitchCase="columnTypes.COUNTER_TEXT"
            [data]="element[column.attributeName]"
            [class.m-auto]="column.align === 'center'"
            [tooltip]="element['tooltipLabel-' + column.attributeName]"
            [style.max-width]="column.maxWidth || '120px'"
          ></slm-table-counter-text>

          <!-- Selection button Column -->
          <slm-table-selection-button
            *ngSwitchCase="columnTypes.SELECTION_BUTTON"
            [tooltip]="element['tooltipLabel-' + column.attributeName]"
            [selected]="element[column.attributeName] === selectedItem"
            (selectionChanged)="selected.next(element)"
            data-test-id="selectInvoice"
          ></slm-table-selection-button>

          <!-- Selection button Column -->
          <slm-table-text-status
            *ngSwitchCase="columnTypes.STATUS_TEXT"
            [style.max-width]="column.maxWidth || '200px'"
            [data]="element[column.attributeName]"
          ></slm-table-text-status>

          <!-- Icon button Column -->
          <slm-button-icon
            *ngSwitchCase="columnTypes.ICON_BUTTON"
            [icon]="element.disable ? '' : column?.icon"
            (click)="menuEvent('buttonClicked', element)"
            [matTooltip]="column.tooltip | translate"
            class="fill-accent m-1_5 right-0"
          ></slm-button-icon>

          <!-- Counter icon Column -->
          <slm-table-counter-icon
            *ngSwitchCase="columnTypes.COUNTER_ICON"
            [data]="element[column.attributeName]"
            [class.m-auto]="column.align === 'center'"
            [tooltip]="element['tooltipLabel-' + column.attributeName]"
            [style.max-width]="column.maxWidth || '30px'"
          ></slm-table-counter-icon>
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnNames; sticky: stickyHeader"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columnNames"
      class="table__row"
      [class.table__row--selected]="selection.isSelected(row)"
    ></tr>
  </table>
</ng-template>

<slm-loader
  *ngIf="loading"
  class="m-auto mt-6 mb-6 block"
></slm-loader>
<div
  *ngIf="!loading && !(dataSource?.data || []).length"
  class="table__placeholder"
>
  <svg-icon
    class="table__placeholder__svg"
    src="/assets/pictures/illustrations/empty.svg"
    [applyClass]="true"
  ></svg-icon>
  <h3 class="table__placeholder__text">{{ placeholder | translate }}</h3>
</div>
