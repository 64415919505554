import {Component, Inject, OnInit} from '@angular/core';
import {
  ButtonConfig,
  buttonsStaticConfig,
  inputErrorTypes,
  NotificationService,
  SelectOption,
  utils
} from '../../../global';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

export interface NavRefreshDialogConfig {
  refresh: ((dateType, dateFrom, dateTo) => Observable<any>);
  lastRefreshDate: Date;
  showMessage?: boolean;
}

enum RefreshDateTypes {
  DEADLINE = 'deadline',
  SENDING = 'sending'
}

@Component({
  selector: 'slm-nav-refresh',
  templateUrl: './nav-refresh.dialog.html'
})
export class NavRefreshDialog implements OnInit {
  public readonly error = [inputErrorTypes.required, { name: 'wrongRange', message: 'INVOICE.NAV.RANGE_ERROR' }];
  public readonly buttons: Array<ButtonConfig> = [
    buttonsStaticConfig.close,
    {
      label: 'LBL_BTN.REFRESH',
      action: 'refresh',
      buttonStyle: 'primary',
      icon: null,
      testId: 'refreshButton'
    }
  ];

  public readonly dateTypeSelectOptions: Array<SelectOption> = [
    {
      label: 'INVOICE.NAV.REFRESH_TYPES.DEADLINE',
      value: RefreshDateTypes.DEADLINE
    },
    {
      label: 'INVOICE.NAV.REFRESH_TYPES.SENDING',
      value: RefreshDateTypes.SENDING
    }
  ];

  public form: UntypedFormGroup;
  public readonly maxDate = new Date();

  constructor(
    private readonly dialogRef: MatDialogRef<NavRefreshDialog>,
    private readonly notification: NotificationService,
    private readonly fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: NavRefreshDialogConfig
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      dateType: [RefreshDateTypes.SENDING, [Validators.required]],
      date: this.fb.group({
        start: this.fb.control(this.data.lastRefreshDate || '', [Validators.required]),
        end: this.fb.control('')
      }, {
        validators: [((group) => {
          const startControl = group?.get('start');
          const endControl = group?.get('end');

          if (!startControl?.value) {
            endControl.setErrors(null);
            return null;
          }
          if (!endControl?.value) {
            endControl.setErrors(null);
            return null;
          }
          if (utils.dayDifferenceBtwnDates(new Date(startControl.value), new Date(endControl.value)) > 35) {
            endControl.setErrors({wrongRange: endControl.value});
            return null;
          }

          if (endControl.hasError('wrongRange')) {
            endControl.setErrors(null);
          }
          return null;
        })]
      })
    });
  }

  public buttonEvent(event: string) {
    if (event === buttonsStaticConfig.close.action) {
      this.dialogRef.close();
      return;
    }
    if (this.buttons[1].isLoading || this.form.invalid) {
      return;
    }
    this.dialogRef.disableClose = true;
    this.buttons[1].isLoading = true;
    const formValues = this.form.value;
    this.data.refresh(formValues.dateType, formValues.date.start, formValues.date.end).subscribe(() => {
      this.notification.notify('MESSAGE.UPLOAD_SUCCESS');
      this.dialogRef.disableClose = false;
      this.buttons[1].isLoading = false;
      this.dialogRef.close(true);
    }, () => {
      this.dialogRef.disableClose = false;
      this.buttons[1].isLoading = false;
    });
  }
}
