import {createFeatureSelector, createSelector} from '@ngrx/store';
import {globalReducer, GlobalState} from './global.reducer';
import {DefaultLedgerNumberTypes, InvoiceType} from '../enums';

const globalState = createFeatureSelector<GlobalState>(globalReducer.name);

const getUser = () => createSelector(globalState, (state) => state.user);

const getUserRights = () => createSelector(
  globalState,
  (state) => state?.user?.rights || []
);

const isUserAuthenticated = () => createSelector(
  globalState,
  (state) => state.isAuthenticated
);

const getUserId = () => createSelector(globalState, (state) =>
  state.user ? state.user.id : null
);

const getVatCodes = () => createSelector(globalState, (state) =>
  state.vatCodes
);

const getLastNavUpdate = () => createSelector(globalState, (state) =>
  state.user?.lastNavUpdate
);

const getCompanyId = () => createSelector(globalState, (state) =>
  state.user ? state.user.activeCompanyId : null
);

const getCompanies = () => createSelector(
  globalState,
  (state) => state?.user?.companies ?? []
);

const getCompany = () => createSelector(globalState, (state) => state.company);

const getAuthorizationToken = () => createSelector(
  globalState,
  (state) => state.authToken
);

const getRefreshToken = () => createSelector(
  globalState,
  (state) => state.refreshToken
);

const getLanguage = () => createSelector(globalState, (state) => state.language);

const getCompanyCurrency = () => createSelector(
  globalState,
  (state) => state.currency
);

const hasExternalSystem = () => createSelector(
  globalState,
  (state) => !!state.company?.externalSystem
);

const getCompanyCurrencies = () => createSelector(
  globalState,
  (state) => state.companyCurrencies
);

const getNotificationCount = () => createSelector(
  globalState,
  (state) => state.general?.notificationCount ?? 0
);

const getSystemNotifications = () => createSelector(
  globalState,
  (state) => state.notification?.systemNotifications ?? null
);

const getNotifications = () => createSelector(
  globalState,
  (state) => state.notification?.notifications ?? null
);

const getNavLocations = () => createSelector(
  globalState,
  (state) => state.general?.syncLocation ?? 'all'
);

const autoRegistrationNumber = () => createSelector(
  globalState,
  (state) => !!state.general?.autoRegNumber
);

const vatFrequency = () => createSelector(
  globalState,
  (state) => state.general?.vatFrequency
);

const defaultDefaultLedgerNumber = (type: DefaultLedgerNumberTypes) => createSelector(
  globalState,
  (state) => {
    if (state.defaultLedgerNumbers) {
      return state?.defaultLedgerNumbers[type];
    }
    return null;
  }
);

const defaultAccrual = () => createSelector(
    globalState,
    (state) => state.general?.defaultAccrual ?? false
  );

const useOnTransactionStatus = () => createSelector(
  globalState,
  (state) => state.general?.useOnTransactionStatus
);

const externalBillingSystem = () => createSelector(
  globalState,
  (state) => state.general?.externalBillingSystem
);

const externalBillingSystemError = () => createSelector(
  globalState,
  (state) => state.general?.externalBillingSystemError
);

const fileUploadMode = () => createSelector(
  globalState,
  (state) => state.general?.fileUploadMode
);

const getViewDetails = (target: InvoiceType) => createSelector(
  globalState,
  (state) => state.viewDetails?.[target]
);

const externalSystemId = () => createSelector(
  globalState,
  (state) => state.general?.externalSystemId
)

const getStorageUsage = () => createSelector(
  globalState,
  (state) => state.general?.storage
);

const useDivision = () => createSelector(
  globalState,
  (state) => state.general?.useDivision
);

const useBank = () => createSelector(
  globalState,
  (state) => state.general?.bank
);

export const globalSelectors = {
  getUser,
  getUserId,
  isUserAuthenticated,
  getAuthorizationToken,
  getLanguage,
  getCompanyId,
  getCompany,
  getCompanyCurrency,
  getRefreshToken,
  getUserRights,
  hasExternalSystem,
  getCompanyCurrencies,
  getCompanies,
  getNotificationCount,
  getSystemNotifications,
  getNotifications,
  getVatCodes,
  getLastNavUpdate,
  getNavLocations,
  autoRegistrationNumber,
  defaultDefaultLedgerNumber,
  vatFrequency,
  defaultAccrual,
  useOnTransactionStatus,
  externalBillingSystem,
  externalBillingSystemError,
  fileUploadMode,
  getViewDetails,
  externalSystemId,
  getStorageUsage,
  useDivision,
  useBank
};
