import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
  AuthSandbox, CustomValidators, Icon,
  inputErrorTypes,
  NavigationService,
  NotificationEnum,
  NotificationService, RoutesEnum
} from '../../../modules/global';
import {ActivatedRoute} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';

@Component({
  selector: 'slm-new-password',
  template: `
    <slm-external-page-container>

      <h4 translate>LBL_PASSWORD.NEW_PASSWORD</h4>

      <ng-container *ngIf="isValidToken$ | async as status; else loading">

        <ng-container [ngSwitch]="status">

          <ng-container *ngSwitchCase="'expired'">
            <ng-container *ngTemplateOutlet="invalidToken"></ng-container>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="passwordForm"></ng-container>
          </ng-container>

        </ng-container>

      </ng-container>

    </slm-external-page-container>

    <ng-template #loading>
      <slm-loader></slm-loader>
    </ng-template>

    <ng-template #invalidToken>

      <slm-message message="AUTHENTICATION.NEW_PASSWORD.EXPIRED" type="error" class="mt-5 mb-3"></slm-message>

      <slm-button
        class="w-100 mt-4 block"
        buttonStyle="secondary"
        url="/{{routes.RESET_PASSWORD}}"
        text="AUTHENTICATION.NEW_PASSWORD.SET_EMAIL"
      ></slm-button>

    </ng-template>

    <ng-template #passwordForm>

      <form [formGroup]="form" (ngSubmit)="setPassword()" class="w-100">
        <slm-text-input
          label="LBL_PASSWORD.NEW_PASSWORD"
          [suffixIcon]="inputConfig.password.icon"
          [type]="inputConfig.password.type"
          enterEnabled="true"
          (fireEvent)="passwordTypeChanged('password')"
          [control]="form.get('password')"
          [errors]="errors"
          [testId]="'password'"
        ></slm-text-input>

        <slm-text-input
          label="LBL_PASSWORD.NEW_PASSWORD_CONFIRMATION"
          [suffixIcon]="inputConfig.verification.icon"
          [type]="inputConfig.verification.type"
          enterEnabled="true"
          (fireEvent)="passwordTypeChanged('verification')"
          [control]="form.get('verification')"
          [errors]="errors"
          [testId]="'retry_password'"
        ></slm-text-input>

        <slm-message
          *ngIf="passwordNotMatch$ | async"
          type="error"
          class="block w-max-2680px mb-2"
          message="LBL_PASSWORD.PASSWORD_NOT_MATCH"
        ></slm-message>

        <slm-password-strength-presenter
          [control]="form.get('password')"
        ></slm-password-strength-presenter>

        <slm-button
          class="w-100 mt-4"
          type="submit"
          [loading]="btnLoading"
          text="AUTHENTICATION.NEW_PASSWORD.SET_PASSWORD"
          testId="setNewPassword"
          [disabled]="form.invalid || !isValidPassword"
        ></slm-button>

      </form>

    </ng-template>
  `
})
export class NewPasswordContainer implements OnInit {

  public readonly routes = RoutesEnum;
  public readonly errors = [inputErrorTypes.strongPassword, inputErrorTypes.minLength, inputErrorTypes.required];
  private readonly helper = new JwtHelperService();

  public passwordNotMatch$: Observable<boolean>;
  public isValidToken$: Observable<'valid' | 'expired'>;

  public isValidPassword = false;
  public btnLoading = false;
  public form: UntypedFormGroup;

  public inputConfig = {
    verification: {
      type: 'password',
      icon: Icon.VISIBILITY
    },
    password: {
      type: 'password',
      icon: Icon.VISIBILITY
    }
  };

  constructor(private readonly authSandbox: AuthSandbox,
              private readonly activatedRoute: ActivatedRoute,
              private readonly fb: UntypedFormBuilder,
              private readonly navigation: NavigationService,
              private readonly notification: NotificationService) {
  }

  ngOnInit() {

    this.isValidToken$ = this.activatedRoute.params.pipe(map(params => {
      try {
        if (!params.token ||
          !/^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/g.test(params.token) ||
          this.helper.isTokenExpired(params.token)) {
          return 'expired';
        }

        return 'valid';
      }catch (_) {
        return 'expired';
      }

    }));

    this.form = this.fb.group({
      password: ['', [Validators.required, CustomValidators.strongPassword, Validators.minLength(8)]],
      verification: ['', [Validators.required, CustomValidators.strongPassword, Validators.minLength(8)]]
    });
    this.passwordNotMatch$ = combineLatest([
      this.form.get('password').valueChanges,
      this.form.get('verification').valueChanges
    ]).pipe(
      map((values) => {
        this.isValidPassword = !!values[0] && !!values[1] && values[0] === values[1];
        return !!values[0] && !!values[1] && values[0] !== values[1];
      })
    );
  }

  public passwordTypeChanged(type: string): void {
    const config = this.inputConfig[type];
    if (config) {
      if ('password' === config.type) {
        this.inputConfig[type] = {
          type: 'text',
          icon: Icon.VISIBILITY_OFF
        };
      } else {
        this.inputConfig[type] = {
          type: 'password',
          icon: Icon.VISIBILITY
        };
      }
    }
  }

  public setPassword() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    if (this.btnLoading && !this.isValidPassword) {
      return;
    }
    this.btnLoading = true;
    const token = this.activatedRoute.snapshot.params.token;
    this.authSandbox.setPassword(this.form.value.password, token).subscribe(
      () => {
        this.btnLoading = false;
        this.notification.notify('AUTHENTICATION.NEW_PASSWORD.PASSWORD_SET', NotificationEnum.SUCCESS, 10000);
        this.navigation.navigate([RoutesEnum.LOGIN]);
      },
      () => {
        this.btnLoading = false;
      }
    );
  }
}
