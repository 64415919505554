import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {first, switchMap, takeUntil} from 'rxjs/operators';
import {
  AuthSandbox,
  CompanySandbox, IconColors,
  NavigationService,
  NotificationEnum,
  NotificationService,
  RoutesEnum,
  UserSandbox
} from '../../../modules/global';
import {DialogPresenterService} from '../../../modules/shared';

@Component({
  selector: 'slm-main',
  templateUrl: './main.container.html',
  styleUrls: ['./main.container.scss']
})
export class MainContainer implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  public readonly colors = IconColors;

  constructor(
    private readonly autoSandbox: AuthSandbox,
    private readonly userSandbox: UserSandbox,
    private readonly notification: NotificationService,
    private readonly companySandbox: CompanySandbox,
    private readonly dialogPresenter: DialogPresenterService,
    private readonly navigation: NavigationService
  ) {
  }

  ngOnInit() {
    this.autoSandbox.userStatusChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        switch (event) {
          case 'sign_in':
            if (!this.navigation.url
                .startsWith(`/${RoutesEnum.EXTERNAL_INVOICE
                  .substr(0, RoutesEnum.EXTERNAL_INVOICE.length - 5)}`) &&
              !this.navigation.url
                .startsWith(`/${RoutesEnum.COMPANY_ACTIVATION
                  .substr(0, RoutesEnum.COMPANY_ACTIVATION.length - 6)}`) &&
              !this.navigation.url.startsWith(`/${RoutesEnum.ACCOUNT_ACTIVATION
                .substr(0, RoutesEnum.ACCOUNT_ACTIVATION.length - 6)}`)) {
              this.navigation.navigate([RoutesEnum.HOME]);
              this.notification.notify(
                'MESSAGE.SIGNED_IN',
                NotificationEnum.INFO,
                15_000
              );
            }
            break;
          case 'sign_out':
            this.dialogPresenter.close();
            this.notification.notify(
              'MESSAGE.SIGNED_OUT',
              NotificationEnum.INFO,
              15_000
            );
            break;
          case 'company_change':
            this.openCompanyChangedDialog();
            break;
          case 'token_refresh': {
            if (
              this.dialogPresenter.dialogId ===
              this.dialogPresenter.QUESTION_DIALOG_ID
            ) {
              this.dialogPresenter.close();
            }
            break;
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private goToNewCompany(): void {
    this.companySandbox.clearVatCodes();
    this.companySandbox.clearCompanyCurrencies();
    this.companySandbox.clearCurrency();
    this.autoSandbox.goToNewToken();
    this.userSandbox.reloadUser();
  }

  private remainOnCurrentCompany(): void {
    this.autoSandbox.setCurrentToken();
  }

  private openCompanyChangedDialog(): void {
    if (
      this.dialogPresenter.dialogId !== this.dialogPresenter.QUESTION_DIALOG_ID
    ) {
      this.companySandbox.activeCompany$
        .pipe(
          first(),
          switchMap((company) =>
            this.dialogPresenter.openQuestionDialog(
              'COMPANY_CHANGED.TITLE',
              'MESSAGE.COMPANY_CHANGE',
              {name: company?.partnerName},
              null,
              null,
              true
            )
          )
        )
        .subscribe((approved) => {
          if (approved) {
            this.goToNewCompany();
          } else {
            this.remainOnCurrentCompany();
          }
        });
    }
  }
}
