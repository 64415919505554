import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { ErrorHandlingService } from './error-handling.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { convertToFormData } from '../functions';
import {FileUploadModes} from '../enums';
@Injectable({
  providedIn: 'root'
})
export class DocumentUploadService {
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandler: ErrorHandlingService
  ) {}

  public upload(
    file: File,
    uploadMode: FileUploadModes,
    name?: string,
    userDivisionId?: number
  ): Observable<{ type: 'error' | 'progress' | 'response'; result: any }> {
    return this.http
      .post(
        '/upload-and-filling/file-upload',
        convertToFormData({
          document: file,
          name,
          fileUploadMode: uploadMode,
          ...!!userDivisionId ? { userDivisionId } : null
        }),
        {
          observe: 'events',
          reportProgress: true
        }
      )
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { type: 'progress', result: progress };

            case HttpEventType.Response:
              return { type: 'response', result: event.body };
            default:
              return {
                type: 'error',
                result: `Unhandled event: ${event.type}`
              };
          }
        }),
        catchError((err) => this.errorHandler.handleError(err))
      );
  }

  public delete(ids: Array<number>): Observable<any> {
    return this.http
      .delete(`/documents/images/${(ids || []).join(',')}`)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }
}
