import { Component, Inject } from '@angular/core';
import { ButtonConfig, DeleteDialogConfig } from '../../../global';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { deleteDialogButtons } from './delete-dialog.config';

@Component({
  selector: 'slm-delete-dialog',
  templateUrl: './delete-dialog.component.html'
})
export class DeleteDialogComponent {
  public readonly buttons: Array<ButtonConfig> = deleteDialogButtons;
  constructor(
    public readonly dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteDialogConfig
  ) {}

  public buttonEvent(event: string) {
    this.dialogRef.close(event);
  }
}
