<div class="card card--no-padding">
  <div class="card__header">
    <h4 class="card__header__title" translate>
      NOTIFICATION.SYSTEM_NOTIFICATIONS
    </h4>
  </div>
  <div class="card__content w-100 d-block pt-2">
    <div class="pl-2 pr-2">
      <div
        class="p-3 flex flex-space-btwn"
        *ngFor="let notification of notifications || []; trackBy: trackById"
      >
        <ng-container [ngSwitch]="notification.type">
          <ng-container *ngSwitchCase="'text'">
            <h5 class="txt-start-i">{{ notification.text }}</h5>
          </ng-container>
          <ng-container *ngSwitchCase="'html'">
            <slm-template-presenter [template]="notification.text"></slm-template-presenter>
          </ng-container>
        </ng-container>
        <slm-button
        text="LBL_BTN.ACCEPT"
        [loading]="(loadings || []).includes(notification.id)"
        [buttonStyle]="notifications?.length > 1 ? 'secondary' : 'primary'"
        (click)="accept(notification.id)"
        ></slm-button>
      </div>
    </div>
    <div
      *ngIf="notifications?.length > 1"
      class="flex flex-end mb-1 mt-2 pt-2 pb-2 pl-2 pr-5 border border-top border-color-light-gray"
    >
      <slm-button
        text="LBL_BTN.ACCEPT_ALL"
        [loading]="loadings?.length === notifications?.length"
        buttonStyle="primary"
        (click)="acceptAll()"
      ></slm-button>
    </div>
  </div>
</div>
