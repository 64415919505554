import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Injectable()
export class LoggerInterceptor implements HttpInterceptor {
  constructor(private router: ActivatedRoute) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.url.startsWith('/assets/')
      || !this.router.snapshot.queryParams?.debugToken
    ) {
      return next.handle(req);
    }

    return next.handle(req.clone({
      params: req.params.set('debugToken', this.router.snapshot.queryParams?.debugToken)
    }));
  }
}
