import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SupportedLanguages} from '../../../modules/global';

@Component({
  selector: 'slm-language-selector',
  templateUrl: './language-selector.component.html'
})
export class LanguageSelectorComponent implements OnInit {

  public readonly languages = this.translate.getLangs().map((language) => ({
    label: `LBL_LANGUAGE.${language.toUpperCase()}`,
    value: language as SupportedLanguages
  }));

  @Input() public language: SupportedLanguages = 'hu';
  @Output() public selected = new EventEmitter<SupportedLanguages>();

  constructor(private readonly translate: TranslateService) {}

  public ngOnInit() {
    this.language = this.translate.currentLang as SupportedLanguages;
  }

  public selectLanguage(language: SupportedLanguages) {
    if (language !== this.language) {
      this.language = language;
      this.selected.emit(language);
      this.translate.use(language);
    }
  }
}
