import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpResponse, HttpStatusCode
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {
  NotificationService,
  NotificationEnum,
  AuthSandbox,
  verifyServerResponse, serverMessages
} from '../../modules/global';
import {TokenInterceptor} from './token.interceptor';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly injector: Injector,
    private readonly notificationService: NotificationService,
    private authSandbox: AuthSandbox
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((response: HttpResponse<any>) => {
        if (!(response?.url || '').includes('/assets/')) {
          this.verifyResponse(response.body, false);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.InternalServerError) {
          this.notificationService.notify(
            'LBL_ERROR.GENERAL',
            NotificationEnum.ERROR
          );
        } else if (error.status === HttpStatusCode.Unauthorized && (error.error?.message ?? error.error) === serverMessages.expiredAuthToken) {
          const authSandbox = this.injector.get(AuthSandbox);
          if (!authSandbox.refreshing) {
            authSandbox.refreshToken();
          }
          return authSandbox.token$.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap((jwtToken) => next.handle(TokenInterceptor.setAuthorizationHeader(request, jwtToken)))
          );
        } else if (error.status === HttpStatusCode.Unauthorized
          && (error.error?.message ?? error.error) === serverMessages.expiredRefreshToken) {
          this.authSandbox.logout(true);
          this.notificationService.notify(
            'LBL_INPUT.ERROR.EXPIRED_LOGIN',
            NotificationEnum.ERROR,
            7000
          );
        }
        let errorBody = {};
        if (error.error instanceof Blob) {
          const reader: FileReader = new FileReader();
          reader.onloadend = () => {
            const result: string = reader.result as string;
            errorBody = result.includes('{') ? JSON.parse(result) : result;
            this.verifyResponse(errorBody, error?.status !== HttpStatusCode.Ok);
          };
          reader.readAsText(error.error);
        } else if(error?.status !== HttpStatusCode.PayloadTooLarge) {
          errorBody = error.error;
          this.verifyResponse(errorBody, error?.status !== HttpStatusCode.Ok);
        }

        return throwError(error);
      })
    );
  }

  private verifyResponse(object, isError): void {
    const hasResponse = verifyServerResponse(object, isError);
    if (!!hasResponse) {
      this.notificationService.notify(hasResponse.message, hasResponse.type, hasResponse.duration);
    }
  }
}
