export enum RightsEnum {
  DOCUMENT_UPLOADING_READ = 'DOCUMENT_UPLOADING_READ',
  DOCUMENT_UPLOADING_WRITE = 'DOCUMENT_UPLOADING_WRITE',
  DOCUMENT_UPLOADING_ADMIN = 'DOCUMENT_UPLOADING_ADMIN',
  DOCUMENT_RECORDING_READ = 'DOCUMENT_RECORDING_READ',
  DOCUMENT_RECORDING_WRITE = 'DOCUMENT_RECORDING_WRITE',
  DOCUMENT_RECORDING_APPROVAL_READ = 'DOCUMENT_RECORDING_APPROVAL_READ',
  DOCUMENT_RECORDING_APPROVAL_WRITE = 'DOCUMENT_RECORDING_APPROVAL_WRITE',
  BANKING_OPERATIONS_READ = 'BANKING_OPERATIONS_READ',
  BANKING_OPERATIONS_WRITE = 'BANKING_OPERATIONS_WRITE',
  DOCUMENT_LISTING_READ = 'DOCUMENT_LISTING_READ',
  DOCUMENT_LISTING_WRITE = 'DOCUMENT_LISTING_WRITE',
  DOCUMENT_ACCOUNT_ASSIGNMENT_READ = 'DOCUMENT_ACCOUNT_ASSIGNMENT_READ',
  DOCUMENT_ACCOUNT_ASSIGNMENT_WRITE = 'DOCUMENT_ACCOUNT_ASSIGNMENT_WRITE',
  DOCUMENT_FINANCIAL_SETTINGS_READ = 'DOCUMENT_FINANCIAL_SETTINGS_READ',
  DOCUMENT_FINANCIAL_SETTINGS_WRITE = 'DOCUMENT_FINANCIAL_SETTINGS_WRITE',
  DOCUMENT_FINANCIAL_APPROVAL_READ = 'DOCUMENT_FINANCIAL_APPROVAL_READ',
  DOCUMENT_FINANCIAL_APPROVAL_WRITE = 'DOCUMENT_FINANCIAL_APPROVAL_WRITE',
  OWN_COMPANY_READ = 'OWN_COMPANY_READ',
  OWN_COMPANY_WRITE = 'OWN_COMPANY_WRITE',
  BANK_ACCOUNTS_READ = 'BANK_ACCOUNTS_READ',
  BANK_ACCOUNTS_WRITE = 'BANK_ACCOUNTS_WRITE',
  VAT_CODES_READ = 'VAT_CODES_READ',
  VAT_CODES_WRITE = 'VAT_CODES_WRITE',
  ACCOUNTING_PERIODS_READ = 'ACCOUNTING_PERIODS_READ',
  ACCOUNTING_PERIODS_WRITE = 'ACCOUNTING_PERIODS_WRITE',
  CHART_OF_ACCOUNTS_READ = 'CHART_OF_ACCOUNTS_READ',
  CHART_OF_ACCOUNTS_WRITE = 'CHART_OF_ACCOUNTS_WRITE',
  TITLES_READ = 'TITLES_READ',
  TITLES_WRITE = 'TITLES_WRITE',
  COST_CENTERS_READ = 'COST_CENTERS_READ',
  COST_CENTERS_WRITE = 'COST_CENTERS_WRITE',
  PROGRAM_SETTINGS_READ = 'PROGRAM_SETTINGS_READ',
  PROGRAM_SETTINGS_WRITE = 'PROGRAM_SETTINGS_WRITE',
  AA_RULES_READ = 'AA_RULES_READ',
  AA_RULES_WRITE = 'AA_RULES_WRITE',
  CUSTOMERS_READ = 'CUSTOMERS_READ',
  CUSTOMERS_WRITE = 'CUSTOMERS_WRITE',
  USERS_READ = 'USERS_READ',
  USERS_WRITE = 'USERS_WRITE',
  BILLING_READ = 'BILLING_READ',
  BILLING_WRITE = 'BILLING_WRITE',
  PSD2_AISP_READ = 'PSD2_AISP_READ',
  PSD2_AISP_WRITE = 'PSD2_AISP_WRITE',
  FILLING_ACCOUNTS_EDIT_READ = 'FILLING_ACCOUNTS_EDIT_READ',
  FILLING_ACCOUNTS_EDIT_WRITE = 'FILLING_ACCOUNTS_EDIT_WRITE',
  OTHER_SYSTEM_SETTINGS_READ = 'OTHER_SYSTEM_SETTINGS_READ',
  OTHER_SYSTEM_SETTINGS_WRITE = 'OTHER_SYSTEM_SETTINGS_WRITE'
}
