import {Component, Input} from '@angular/core';

@Component({
  selector: 'slm-no-image',
  templateUrl: './no-image.component.html',
  styleUrls: ['no-image.component.scss']
})
export class NoImageComponent {
  @Input() placeholderTitle: string;
  @Input() placeholderDescription: string;
}
