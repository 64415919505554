import { SelectOption } from '../../../../global';

export const tableSizes: Array<SelectOption> = [
  {
    value: 5,
    label: 'LBL_TABLE.PAGE.FIVE'
  },
  {
    value: 10,
    label: 'LBL_TABLE.PAGE.TEN'
  },
  {
    value: 25,
    label: 'LBL_TABLE.PAGE.TWENTY_FIVE'
  },
  {
    value: 50,
    label: 'LBL_TABLE.PAGE.FIFTY'
  },
  {
    value: 100,
    label: 'LBL_TABLE.PAGE.HUNDRED'
  }
];
