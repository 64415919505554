import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';

const inputLabels = {
  invoiceType: 'INPUTS.INVOICE_TYPE',
  language: 'INVOICE.LANGUAGE',
  creationDate: 'INVOICE_GENERALS.CREATION_DATE',
  paymentDate: 'INVOICE_GENERALS.COMPLETION_DATE',
  deadline: 'INVOICE_GENERALS.DEADLINE',
  currency: 'LBL_COMPANY.CURRENCY',
  exchangeRate: 'INVOICE_GENERALS.EXCHANGE_RATE',
  paymentType: 'INVOICE_GENERALS.PAYMENT_MODE',
  bankAccount: 'INVOICE.ACCOUNT_NUMBER',
  vatNumber: 'INVOICE.TAX_NUMBER',
  euTaxNumber: 'LBL_PARTNERS.DATA.EU_TAX_NUMBER',
  legalClass: 'LBL_PARTNERS.LEGAL_CLASS',
  country: 'LBL_PARTNERS.EDIT.COUNTRY',
  zipCode: 'LBL_PARTNERS.EDIT.ZIP',
  city: 'LBL_PARTNERS.EDIT.CITY',
  street: 'INVOICE.STREET',
  address: 'INVOICE.ADDRESS',
  itemname: 'INVOICE.NAMING',
  itemamount: 'INVOICE.COUNT',
  itemunit: 'LBL_COMPANY.UNIT',
  itemnetValue: 'INVOICE.NET_PRICE',
  itemvatCode: 'INVOICE.VAT_CODE',
  itemdeductibleVatCodeId: 'INVOICE.DEDUCTIBLE_VAT_VALUE',
  itemdeductibleLedgerNumber: 'INVOICE.NON_DEDUCTIBLE_LEDGER_NUMBER',
  itemaccountingCostLedgerNumber: 'INVOICE.LEDGER_NUMBER',
  itemaccounting1: 'ACCOUNTING_MIRROR.1_COST_LOCATION',
  itemaccounting2: 'ACCOUNTING_MIRROR.2_COST_LOCATION',
  itemaccounting3: 'ACCOUNTING_MIRROR.3_COST_LOCATION',
  itemaccounting4: 'ACCOUNTING_MIRROR.4_COST_LOCATION',
  itemvendorLedgerNumber: 'INVOICE.VENDOR_LEDGER_NUMBER',
  itemcustomsTariff: 'LBL_COMPANY.VTSZ',
  itembrutValue: 'INVOICE.BRUT_VALUE',
  invoiceNumber: 'INVOICE_GENERALS.INVOICE_NUMBER',
  registryNumber: 'INVOICE_GENERALS.REGISTRATION_NUMBER',
  defferedVatDate: 'INVOICE.DEFFER_DATE',
  defferedVat: 'INVOICE.DEFFER_DATE_ENABLE',
  defferedVatExchangeRate: 'INVOICE.DEFFER_DATE_RATE',
  paymentTime: 'PARTNERS.PAYMENT_TERMS',
  vendorLedgerNumber: 'INVOICE.VENDOR_LEDGER_NUMBER',
  partnerName: 'LBL_PARTNERS.PARTNER_NAME',
  partnerEmail: 'LBL_PARTNERS.DATA.NOTIFICATION_EMAILS',
  accountNumber: 'INVOICE.ACCOUNT_NUMBER',
  bankingsourceAccount: 'BANKING.SOURCE_ACCOUNT',
  bankingtransactionType: 'BANKING.TRANSFER_TYPE',
  bankingpartner: 'LBL_PARTNERS.PARTNER_NAME',
  bankingpartnerAccount: 'INVOICE.ACCOUNT_NUMBER',
  bankingamount: 'BANKING.TRANSACTION_AMOUNT',
  bankingdate: 'BANKING.TRANSACTION_DATE',
  bankingreference: 'BANKING.TRANSACTION_REFERENCE',
  bankingbearer: 'BANKING.COST_BEARER',
  bankingprocessingTime: 'PROCESSING_TIME',
  swift: 'INVOICE.SWIFT',
  countryCode: 'LBL_PARTNERS.COUNTRY_CODE',
  bankName: 'BANK_SHORT_NAME',
  bankAccountId: 'BANK_ACCOUNTS.BANK_NAME',
  iban: 'BANK_ACCOUNTS.IBAN',
  accountingCompletionDate: 'INVOICE.ACCOUNTING_COMPLETION_DATE',
  itemaccrualDate: 'INVOICE.ACCRUAL_DATE',
  itemaccrualType: 'INVOICE.ACCRUAL_TYPE_TITLE',
  itemaccrualSum: 'INVOICE.ACCRUAL_AMOUNT',
  newTemplateName: 'INVOICE.NEW_TEMPLATE_NAME',
  dateOf65M: 'INVOICE.ATTR.DATE_OF_65M',
  itembackwardAccrualLedgerNumber: 'INVOICE.ACCRUAL_FROM_LEDGER',
  itemforwardAccrualLedgerNumber: 'INVOICE.ACCRUAL_TO_LEDGER',
  stornoInvoiceNumber: 'INVOICE.STORNO_INVOICE_NUMBER'
};

export const getErrorLabel = (
  form: UntypedFormGroup | UntypedFormArray,
  formName: 'item' | 'payment' | 'banking' | 'template' = null
): { controlName: string; label: string } => {
  if (!form) {
    return null;
  }
  if (form.valid) {
    return null;
  }
  if (form instanceof UntypedFormGroup) {
    return getInvalidInput(form, formName);
  } else if (form instanceof UntypedFormArray) {
    const invalidForm = (form as UntypedFormArray).controls.find(
      (form) => form?.invalid
    );
    if (!invalidForm) {
      return null;
    } else {
      return getInvalidInput(invalidForm, formName);
    }
  }
  return null;
};

const getInvalidInput = (form: UntypedFormGroup | AbstractControl, formName) => {
  const controlNames = Object.keys(form.value);
  const invalidControlNames = controlNames.filter(
    (name) => form.get(name).invalid
  );
  if (
    controlNames.length > 2 &&
    controlNames.length / 2 <= invalidControlNames.length
  ) {
    return null;
  }
  return {
    controlName: invalidControlNames[0],
    label: inputLabels[`${formName || ''}${invalidControlNames[0]}`]
  };
};
