import { Icon } from '../enums';

export interface InputError {
  message: string;
  name: string;
}

export interface SelectOption {
  value: any;
  label: string;
  icon?: Icon;
  color?: string;
  id?: string | number;
  subLabel?: string;
  default?: boolean;
  data?: any;
  disabled?: boolean;
  title?: string;
}

export interface GroupedSelectOptions {
  groupLabel: string;
  options: Array<SelectOption>;
}
