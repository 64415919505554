import {PartnerActiveStatusEnum} from '../enums';
import {DefaultLedgerNumberValue} from './accounting-settings.entity';
import {Currency} from './currency.entity';

export interface PartnerItem {
  address: string;
  externalSystemIdentifier: string;
  taxAdministrationNumber?: string;
  publicTaxIdNumber?: string;
  thirdPartyVat?: string;
  id: number;
  name: string | any;
  negativeInfo?: boolean;
  verificationDate?: string;
  euNegativeInfo?: boolean;
  euVerificationDate?: string;
  status: PartnerActiveStatusEnum;
  invoiceNote?: string;
  vendorLedgerNumber?: DefaultLedgerNumberValue;
  customerLedgerNumber?: DefaultLedgerNumberValue;
  defaultCurrency?: Currency;
}

