import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {BehaviorSubject, combineLatest, concat, Observable, of, Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import { InternalMessage, User, Icon } from '../../../global';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'slm-internal-messages',
  templateUrl: './internal-messages.component.html',
  styles: [
    `
           :host {
             width: 100%;
           }

           .light-background {
             background-color: #f3f3f3;
           }
         `
  ]
})
export class InternalMessagesComponent implements OnInit, OnDestroy {
  public readonly icons = Icon;

  public readonly messageControl = new UntypedFormControl('');
  public readonly updateControl = new UntypedFormControl('');
  public readonly toggleControl = new UntypedFormControl('');

  private readonly destroy$ = new Subject();
  private refreshMessage$ = new BehaviorSubject(null);

  public selectedIndex = -1;
  public messages: Array<InternalMessage> = [];
  public messages$: Observable<Array<InternalMessage>>;

  @Input('messages')
  public set setMessages(messages: Array<InternalMessage>) {
    this.messages = messages;
    this.refreshMessage$.next(messages);
  };
  @Input() public currentUser: User = null;
  @Input() public invoiceMode = false;
  @Input() public disablePadding = false;
  @Input() public disableButton = false;
  @Input() public showToggle = false;
  @Input() public typeLabelSet = 'FILE_MANAGER.INTERNAL_MESSAGES_ADMIN';
  @Input() public adminType: 'invoice' | 'file-manager' = 'invoice'
  @Output() public readonly messageEntered = new EventEmitter<string>();
  @Output() public readonly update = new EventEmitter<{
    id: number | string;
    message: string;
  }>();
  @Output() public readonly delete = new EventEmitter<number | string>();

  constructor(
    private translateService: TranslateService
  ) {}

  public sendMessage(): void {
    if (!!this.messageControl.value) {
      this.messageEntered.emit(this.messageControl.value);
      if (!this.invoiceMode) {
        this.messageControl.setValue('');
      }
    }
  }

  ngOnInit() {
    if (this.disableButton) {
      this.messageControl.valueChanges
        .pipe(
          filter(() => this.disableButton),
          takeUntil(this.destroy$)
        )
        .subscribe((value) => this.messageEntered.emit(value));
    }

    this.messages$ = combineLatest([
      concat(
        of(false),
        this.toggleControl.valueChanges
      ),
      this.refreshMessage$,
      of(this.messages)
    ]).pipe(
      map(([toggle, messages, _]) => (messages || []).filter(message => toggle || (!toggle && !message?.isAdmin))),
      map(messages =>
        messages.map(value => {
          const msgTypeValue = !!value.type ? `${this.typeLabelSet}.${value.type.toUpperCase()}` : null;
            return ({
              ...value,
              type: value.isAdmin && !['Jóváhagyva', 'Visszautasítva'].includes(value.type)
                ? this.translateService.instant(msgTypeValue) !== msgTypeValue ? msgTypeValue : value.type
                : value.type
            });
          }
        ))
    );
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public deleteMessage(message: InternalMessage): void {
    this.delete.emit(message.id);
  }

  public showUpdate(index: number): void {
    this.updateControl.setValue(this.messages[index].message);
    this.selectedIndex = index;
  }

  public cancelUpdate(): void {
    this.selectedIndex = -1;
    this.updateControl.setValue('');
  }

  public updateMessage(): void {
    if (this.updateControl.value) {
      this.update.emit({
        id: this.messages[this.selectedIndex].id,
        message: this.updateControl.value
      });
      this.cancelUpdate();
    }
  }

  public trackById = (_, item: InternalMessage) => item.id;
}
