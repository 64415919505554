<slm-external-page-container>
  <h4 translate>AUTHENTICATION.USER_ACTIVATION</h4>

  <slm-toggle
    [control]="acceptControl"
    label="AUTHENTICATION.TERMS_USAGE"
    isInnerHtml="true"
    class="block mb-6"
  ></slm-toggle>

  <slm-button
    text="AUTHENTICATION.ACTIVATION"
    (click)="activateUser()"
    type="submit"
    [loading]="loading"
  ></slm-button>

</slm-external-page-container>
