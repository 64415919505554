<mat-form-field
  [class.text-input--required]="isRequiredField && (control.valid || !control.dirty)"
  class="flex-grow"
  [class.text-input--error]="
    control && !control.valid && control.touched && control.dirty
  "
>
  <mat-label *ngIf="label || (!isRequiredField && !hideOptionalLabel)"
    >{{ label | translate }}
    <span>{{ secondaryLabel | translate }}</span>
    <span *ngIf="!isRequiredField && !hideOptionalLabel" translate="LBL_INPUT.LABEL.NOT_REQUIRED"></span>
  </mat-label
  >
  <slm-svg-icon matPrefix *ngIf="prefixIcon" [icon]="prefixIcon"></slm-svg-icon>
  <textarea
    matInput
    [required]="isRequiredField"
    [attr.data-test-id]="testId"
    [maxLength]="maxLength || 10000"
    [style.maxHeight.rem]="maxRow"
    [style.minHeight.rem]="minRow"
    [placeholder]="placeholder | translate"
    [formControl]="control"
    (focusout)="focusOut()"
    (keyup.enter)="enterEnabled ? onClick('enter') : null"
  ></textarea>
  <slm-svg-icon
    *ngIf="suffixIcon"
    matSuffix
    matRipple
    class="fill-grey"
    [matTooltip]="suffixIconLabel | translate"
    [matRippleDisabled]="!buttonIcon"
    (click)="buttonIcon ? onClick('suffix') : null"
    [icon]="suffixIcon"
  ></slm-svg-icon>
  <mat-hint *ngIf="hint" align="start">{{ hint | translate }}</mat-hint>
  <mat-hint *ngIf="maxLength" align="end"
    >{{ control?.value?.length || 0 }} / {{ maxLength }}</mat-hint
  >
  <mat-error
    *ngIf="control && (control.dirty || control.touched) && control.invalid"
    >{{ getError() | translate: errorBody }}</mat-error
  >
</mat-form-field>
