import {ColumnTableItem, MenuItem, TableColumn, TableCounterIcon, TableCounterText, TableFilter, TableIconText, TableText, TableTextStatus} from '../entities';
import {accountingStatusSelect, documentTypes, fillingStatusSelect, paymentStatusSelect, paymentTypeOptions} from './options.static';
import {Icon, IconColors, IncomingPaymentStatus, InvoicePaymentTypeEnum, OutgoingPaymentStatus, RightsEnum, TableAlign, TableColumnType} from '../enums';
import {
  accountingStatusToTableIconText,
  convertToTableIconText,
  fillingStatusToTableIconText, PartnerFunctions,
  paymentStatusToTableIconText,
  stringDateToTableDate,
  stringToTableText
} from '../functions';
import {defaultCurrency} from './general.static';
import {accountantStatus} from './statuses.static';

/**
 * Returns the default filter config of the invoice tables with the specified parameters.
 *
 * @param filterOut -(not required) Attribute names that we do not want to display in the filter
 * @param isIncoming - (not required) filter type by invoice type
 * @param onTransactionStatusEnabled
 */
export const invoiceTableFilterConfig = (
  filterOut: Array<string> = [],
  isIncoming: boolean = false,
  onTransactionStatusEnabled: boolean = false
): Array<TableFilter> => {
  const filters: Array<TableFilter> = [
    {
      type: 'select',
      chipName: (value) => `LBL_TABLE_ATTR.${(value || '').toUpperCase()}`,
      config: accountingStatusSelect,
      settingsId: ['accountingStatus']
    },
    {
      type: 'select',
      chipName: (value) =>
        value === 'accounted'
          ? 'LBL_TABLE_ATTR.FILED'
          : `LBL_TABLE_ATTR.${(value || '').toUpperCase()}`,
      config: fillingStatusSelect,
      settingsId: ['fillingStatus']
    },
    {
      type: 'multi-select',
      chipName: (value) => paymentStatusSelect(isIncoming, onTransactionStatusEnabled).options.find(item =>
        item.value === value)?.label,
      config: paymentStatusSelect(isIncoming, onTransactionStatusEnabled),
      settingsId: ['paymentStatus']
    },
    {
      type: 'select',
      config: {
        testId: 'currencyFilter',
        name: 'currency',
        inputType: 'select',
        label: 'INVOICE_GENERALS.CURRENCY',
        options: []
      },
      settingsId: ['currency', 'netValue', 'vatValue', 'grossValue']
    },
    {
      type: 'select',
      chipName: (value) => `INVOICE.${(value || '').toUpperCase()}`,
      config: {
        testId: 'documentTypeFilter',
        name: 'documentType',
        inputType: 'select',
        label: 'INVOICE_GENERALS.DOC_TYPE',
        options: documentTypes
      },
      settingsId: ['documentType']
    },
    {
      type: 'select',
      chipName: (value) => {
        switch (value) {
          case InvoicePaymentTypeEnum.CASH:
            return 'INVOICE.PAYMENT_TYPE.MONEY';
          case InvoicePaymentTypeEnum.PROFORMA:
            return 'INVOICE_TYPE.REQUEST';
          case InvoicePaymentTypeEnum.CREDIT_CARD:
            return 'INVOICE.PAYMENT.CREDIT-CARD';
          case InvoicePaymentTypeEnum.BANK_TRANSFER:
            return 'INVOICE.PAYMENT.BANK-TRANSFER';
          case InvoicePaymentTypeEnum.CHECK:
            return 'INVOICE.PAYMENT.CHECK';
        }
        return '';
      },
      config: {
        testId: 'paymentTypeOptions',
        name: 'paymentType',
        inputType: 'select',
        label: 'INVOICE_GENERALS.PAYMENT_MODE',
        options: paymentTypeOptions
      },
      settingsId: ['paymentType']
    },
    {
      type: 'date-range',
      config: {
        name: 'completionDate',
        label: 'INVOICE_GENERALS.COMPLETION_DATE',
        testId: 'filterCompletionDate'
      },
      settingsId: []
    },
    {
      type: 'date-range',
      config: {
        name: '65mDate',
        label: 'INVOICE.ATTR.DATE_OF_65M',
        testId: 'filter65mDate'
      },
      settingsId: ['65mDate']
    },
    {
      type: 'date-range',
      config: {
        testId: 'paymentDeadlineFilter',
        name: 'paymentDeadline',
        label: 'INVOICE_GENERALS.DEADLINE'
      },
      settingsId: ['paymentDeadline']
    },
    {
      type: 'date-range',
      config: {
        testId: 'fillingDateFilter',
        name: 'fillingDate',
        label: 'INVOICE_GENERALS.DATE_OF_FILLING'
      },
      settingsId: ['fillingDate']
    },
    {
      type: 'select',
      chipName: (value) => `INVOICE.${value === 'true' ? '' : 'UN_'}SUCCESS_UPLOAD`,
      rights: [RightsEnum.DOCUMENT_ACCOUNT_ASSIGNMENT_WRITE],
      config: {
        name: 'uploadSuccess',
        label: 'INVOICE.UPLOAD_STATUS',
        options: [
          {label: 'INVOICE.SUCCESS_UPLOAD', value: 'true'},
          {label: 'INVOICE.UN_SUCCESS_UPLOAD', value: 'false'}
        ]
      },
      settingsId: []
    },
    {
      type: 'number-range',
      config: {
        testId: 'grossValueFilter',
        name: 'grossValue',
        label: 'INVOICE_GENERALS.VALUE'
      },
      settingsId: ['grossValue']
    },
    {
      type: 'number-range',
      config: {
        testId: 'netValueFilter',
        name: 'netValue',
        label: 'INVOICE_GENERALS.NET_VALUE'
      },
      settingsId: ['netValue']
    },
    {
      type: 'number-range',
      config: {
        testId: 'vatValueFilter',
        name: 'vatValue',
        label: 'INVOICE_GENERALS.VAT_VALUE'
      },
      settingsId: ['vatValue']
    },
    {
      type: 'number-range',
      config: {
        testId: 'exchangeRateFilter',
        name: 'exchangeRate',
        label: 'INVOICE_GENERALS.EXCHANGE_RATE'
      },
      settingsId: ['exchangeRate']
    },
    {
      type: 'date-range',
      config: {
        testId: 'creationDateFilter',
        name: 'creationDate',
        label: 'INVOICE_GENERALS.CREATION_DATE'
      },
      settingsId: ['creationDate']
    },
    {
      type: 'date-range',
      config: {
        testId: 'recordingDateFilter',
        name: 'recordingDate',
        label: 'INVOICE_GENERALS.RECORDING_DATE'
      },
      settingsId: ['recordingDate']
    },
    {
      type: 'date-range',
      config: {
        testId: 'paymentDateFilter',
        name: 'paymentDate',
        label: 'INVOICE.PAYMENT_DATE'
      },
      settingsId: ['paymentsDetailed', 'payments']
    },
    {
      type: 'date-range',
      config: {
        testId: 'defferedVatDateFilter',
        name: 'defferedVatDate',
        label: 'INVOICE.DEFFER_DATE'
      },
      settingsId: ['defferedVatDate']
    },
    {
      type: 'select',
      chipName: (value) => `INVOICE.PAYMENT_DATE_MODE_TYPES.${value === 'allWithinInterval'? 'ALL': 'ANY'}`,
      config: {
        name: 'paymentDateFilterMode',
        label: 'INVOICE.PAYMENT_DATE_MODE',
        testId: 'paymentDateFilterMode',
        options: [
          {label: 'INVOICE.PAYMENT_DATE_MODE_TYPES.ALL', value: 'allWithinInterval'},
          {label: 'INVOICE.PAYMENT_DATE_MODE_TYPES.ANY', value: 'anyWithinInterval'}
        ]
      },
      settingsId: ['paymentsDetailed', 'payments']
    },
    {
      type: 'checkbox',
      chipName: () => `INVOICE.HAS_COMMENT`,
      config: {
        name: 'invoiceHasComment',
        label: 'INVOICE.HAS_COMMENT',
        testId: 'invoiceHasComment',
      },
      settingsId: ['messageCounter']
    },
    {
      type: 'select',
      chipName: (value) =>  value === 'true' ? 'INVOICE_GENERALS.FILED_WITH_OCR' : 'INVOICE_GENERALS.NOT_FILED_WITH_OCR',
      config: {
        name: 'filedWithOcr',
        label: 'INVOICE_GENERALS.FILED_WITH_OCR',
        testId: 'paymentDateFilterMode',
        options: [
          {label: 'LBL_YES', value: 'true'},
          {label: 'LBL_NO', value: 'false'}
        ]
      },
      settingsId: ['ocr']
    },
    {
      type: 'select',
      chipName: (value) => `INVOICE.NAV_STATUS.${value.toUpperCase()}`,
      config: {
        name: 'navStatus',
        label: 'INVOICE_GENERALS.NAV_STATUS',
        testId: 'navStatusFilter',
        options: [
          {label: 'INVOICE.NAV_STATUS.PROCESSING', value: 'PROCESSING'},
          {label: 'INVOICE.NAV_STATUS.DONE', value: 'DONE'},
          {label: 'INVOICE.NAV_STATUS.ABORTED', value: 'ABORTED'}
        ]
      },
      settingsId: ['navStatus'],
    },
    {
      type: 'checkbox',
      chipName: () => `INVOICE.HAS_ATTACHMENT`,
      config: {
        name: 'hasAttachment',
        label: 'INVOICE.HAS_ATTACHMENT',
        testId: 'hasAttachment',
      },
      settingsId: ['attachmentNumber']
    },
  ];

  return filters.filter(
    (value: TableFilter) => !filterOut.includes(value.config.name)
  );
};

export const withoutAttachmentFilter: TableFilter = {
  type: 'checkbox',
  chipName: 'INVOICE.MISSING_FILES',
  config: {
    name: 'withoutAttachment',
    label: 'INVOICE.MISSING_FILES',
    testId: 'withoutAttachmentFilter'
  },
  settingsId: []
};

/**
 * Returns the config of the invoice table with the given parameters
 *
 * @param partnerNameLabel - (not required) Label of the partner attribute
 * @param menuItems - An array with the submenu items
 * @param filterOut -(not required) Attribute names that we do not want to display in the table
 * @param isIncoming -(not required) invoice type
 * @param accountingStatusWithNotification -(not required) show notification icon
 * @param columnConfig -(not required) column config
 * @param viewSettingOptions -(not required) available config list
 */
export const invoiceTableConfig = (
  partnerNameLabel: string = 'LBL_PARTNERS.PARTNER_NAME',
  menuItems: Array<MenuItem>,
  filterOut: Array<string> = [],
  isIncoming: boolean = false,
  accountingStatusWithNotification: boolean = false,
  columnConfig: Array<string> = defaultColumnConfig,
  viewSettingOptions?: Array<MenuItem>
): Array<TableColumn> => {
  const firstColumn: TableColumn = {
    columnType: TableColumnType.CHECKBOX,
    label: '',
    attributeName: 'selected'
  };
  const lastColumn: TableColumn = {
    align: TableAlign.END,
    columnType: TableColumnType.MENU,
    attributeName: 'menu',
    label: null,
    sortDisabled: true,
    menuItems: menuItems || [],
    notClickable: true,
    viewSettingOptions
  };

  const items: Array<TableColumn> = [
    {
      align: TableAlign.START,
      columnType: TableColumnType.STATUS_TEXT,
      label: partnerNameLabel,
      attributeName: 'partner',
      transpile: (partner: any): TableTextStatus => PartnerFunctions.getPartnerNegativeStateIcon(partner),
      maxWidth: '180px',
      settingId: 'partnerName'
    },
    {
      align: TableAlign.START,
      columnType: TableColumnType.COUNTER_TEXT,
      label: 'INVOICE_SHORT_NOTE',
      attributeName: 'messageCounter',
      maxWidth: '30px',
      settingId: 'messageCounter',
      transpile: (messageCounter: number): TableCounterText => ({
        counter: messageCounter,
        color: IconColors.BLUE,
        label: ''
      })
    },
    {
      align: TableAlign.START,
      columnType: TableColumnType.TEXT,
      label: 'INVOICE_GENERALS.INVOICE_NUMBER',
      attributeName: 'invoiceNumber',
      transpile: stringToTableText,
      maxWidth: '160px',
      settingId: 'invoiceNumber',
      valueTooltip: true
    },
    accountingStatusWithNotification? accountingStatusNotification:
      accountingStatusSimple,
    {
      align: TableAlign.START,
      columnType: TableColumnType.ICON_TEXT,
      label: 'INVOICE_GENERALS.FILLING_STATUS',
      attributeName: 'fillingStatus',
      settingId: 'fillingStatus',
      transpile: fillingStatusToTableIconText
    },
    {
      align: TableAlign.START,
      columnType: TableColumnType.ICON_TEXT,
      label: 'INVOICE_GENERALS.PAYMENT_STATUS',
      attributeName: 'paymentStatus',
      settingId: 'paymentStatus',
      transpile: (status: IncomingPaymentStatus | OutgoingPaymentStatus) => paymentStatusToTableIconText(status, isIncoming)
    },
    {
      align: TableAlign.CENTER,
      columnType: TableColumnType.DATE,
      label: 'INVOICE_GENERALS.COMPLETION_DATE',
      attributeName: 'completionDate',
      dateFormat: 'FORMAT.DATE',
      settingId: 'completionDate',
      transpile: stringDateToTableDate,
      valueTooltip: true
    },
    {
      align: TableAlign.CENTER,
      columnType: TableColumnType.DATE,
      label: 'INVOICE_GENERALS.DEADLINE',
      attributeName: 'paymentDeadline',
      dateFormat: 'FORMAT.DATE',
      settingId: 'paymentDeadline',
      transpile: stringDateToTableDate,
      valueTooltip: true
    },
    {
      align: TableAlign.END,
      columnType: TableColumnType.CURRENCY,
      label: 'INVOICE.REMAINS',
      attributeName: 'leftover',
      settingId: 'leftover'
    },
    {
      align: TableAlign.END,
      columnType: TableColumnType.CURRENCY,
      label: 'INVOICE_GENERALS.VALUE',
      attributeName: 'grossValue',
      settingId: 'grossValue'
    },
    {
      align: TableAlign.END,
      columnType: TableColumnType.CURRENCY,
      label: 'INVOICE_GENERALS.NET_VALUE',
      attributeName: 'netValue',
      settingId: 'netValue'
    },
    {
      align: TableAlign.END,
      columnType: TableColumnType.CURRENCY,
      label: 'INVOICE_GENERALS.VAT_VALUE',
      attributeName: 'vatValue',
      settingId: 'vatValue'
    },
    {
      align: TableAlign.CENTER,
      columnType: TableColumnType.DATE,
      label: 'INVOICE_GENERALS.CREATION_DATE',
      attributeName: 'creationDate',
      dateFormat: 'FORMAT.DATE',
      settingId: 'creationDate',
      transpile: stringDateToTableDate,
      valueTooltip: true
    },
    {
      align: TableAlign.CENTER,
      columnType: TableColumnType.DATE,
      label: 'INVOICE_GENERALS.RECORDING_DATE',
      attributeName: 'recordingDate',
      dateFormat: 'FORMAT.DATE',
      settingId: 'recordingDate',
      transpile: stringDateToTableDate,
      valueTooltip: true
    },
    {
      align: TableAlign.CENTER,
      columnType: TableColumnType.DATE,
      label: 'INVOICE_GENERALS.DATE_OF_FILLING',
      attributeName: 'fillingDate',
      dateFormat: 'FORMAT.DATE',
      settingId: 'fillingDate',
      transpile: stringDateToTableDate,
      valueTooltip: true
    },
    {
      align: TableAlign.START,
      columnType: TableColumnType.TEXT,
      label: 'ACCOUNTING_TEMPLATE_NAME',
      attributeName: 'templateName',
      settingId: 'templateName',
      maxWidth: '160px',
      valueTooltip: true,
      transpile: stringToTableText
    },
    {
      align: TableAlign.START,
      columnType: TableColumnType.TEXT,
      label: 'INVOICE_GENERALS.REGISTRATION_NUMBER',
      attributeName: 'registrationNumber',
      transpile: stringToTableText,
      maxWidth: '160px',
      valueTooltip: true,
      settingId: 'registrationNumber',
      tooltip: (value: any) => value?.registrationNumber
    },
    {
      align: TableAlign.START,
      columnType: TableColumnType.TEXT,
      label: 'INVOICE_GENERALS.CURRENCY',
      attributeName: 'currencyShort',
      orderName: 'currency',
      maxWidth: '50px',
      transpile: stringToTableText,
      settingId: 'currency'
    },
    {
      align: TableAlign.END,
      columnType: TableColumnType.TEXT,
      label: 'INVOICE_GENERALS.EXCHANGE_RATE',
      attributeName: 'exchangeRate',
      maxWidth: '80px',
      settingId: 'exchangeRate',
      transpile: (exchangeRate: any): TableText  => ({
        label: exchangeRate > 1? exchangeRate: '-'
      })
    },
    {
      align: TableAlign.START,
      columnType: TableColumnType.TEXT,
      label: 'INVOICE_GENERALS.REFERENCE_NOTICE',
      attributeName: 'referenceNotice',
      settingId: 'referenceNotice',
      valueTooltip: true,
      maxWidth: '160px',
      transpile: stringToTableText
    },
    {
      align: TableAlign.START,
      columnType: TableColumnType.TEXT,
      label: 'INVOICE.LAST_MESSAGE',
      attributeName: 'lastMessage',
      settingId: 'lastMessage',
      valueTooltip: true,
      sortDisabled: true,
      maxWidth: '160px',
      transpile: stringToTableText
    },
    {
      align: TableAlign.END,
      columnType: TableColumnType.CURRENCY,
      label: isIncoming? 'INVOICE.LAST_PAYMENT_OUT': 'INVOICE.LAST_PAYMENT_IN',
      attributeName: 'lastPayment',
      settingId: 'payments',
      transpile: (payment: number) => payment? payment: ''
    },
    {
      align: TableAlign.START,
      columnType: TableColumnType.PAYMENT_DETAILED,
      label: isIncoming? 'INVOICE.LAST_PAYMENT_OUT_DETAILED': 'INVOICE.LAST_PAYMENT_IN_DETAILED',
      attributeName: 'lastPaymentDetails',
      orderName: 'lastPaymentDetails',
      settingId: 'paymentsDetailed',
      valueTooltip: true,
      maxWidth: '100%'
    },
    {
      align: TableAlign.START,
      columnType: TableColumnType.TEXT,
      label: 'INVOICE.TYPE',
      attributeName: 'documentType',
      settingId: 'documentType',
      transpile: (type: string): TableText => ({
        label: type? `INVOICE.${type.toUpperCase()}`: '-'
      })
    },
    {
      align: TableAlign.START,
      columnType: TableColumnType.TEXT,
      label: 'INVOICE_GENERALS.PAYMENT_MODE',
      attributeName: 'paymentType',
      settingId: 'paymentType',
      transpile: (type: string): TableText => ({
        label: type? `INVOICE.PAYMENT.${type.toUpperCase()}`: '-'
      })
    },
    {
      align: TableAlign.CENTER,
      columnType: TableColumnType.ICON_TEXT,
      label: 'LBL_UPLOAD_FILL.DOCUMENT_OCR',
      attributeName: 'filedWithOcr',
      maxWidth: '30px',
      settingId: 'ocr',
      transpile: (ocr: boolean): TableIconText => ({
        label: '',
        icon: Icon.DOCUMENT_SEARCH,
        color: ocr ? IconColors.GREEN : IconColors.RED
      })
    },
    {
      align: TableAlign.CENTER,
      columnType: TableColumnType.ICON_TEXT,
      label: 'INVOICE_GENERALS.NAV_STATUS',
      attributeName: 'navStatus',
      transpile: (status: string): TableIconText => ({
        color: status === null
                ? IconColors.GREY
                : (['DONE'].includes(status)
                  ? IconColors.GREEN
                  : (['PROCESSING','RECEIVED','SAVED'].includes(status)
                    ? IconColors.ORANGE : IconColors.RED)),
        icon: status === null
                ? Icon.REMOVE_CIRCLE
                : (['DONE'].includes(status)
                  ? Icon.CHECK_CIRCLE
                  : (['PROCESSING','RECEIVED','SAVED'].includes(status)
                    ? Icon.INFO : Icon.ERROR)),
        label: ''
      }),
      maxWidth: '160px',
      settingId: 'navStatus',
      tooltip: (status: any): string => {
       return status.navStatus === null ? 'INVOICE.NAV_STATUS.NO_DATA' : `INVOICE.NAV_STATUS.${status.navStatus}`
      }
    },
    {
      align: TableAlign.CENTER,
      columnType: TableColumnType.DATE,
      label: 'INVOICE.ATTR.DATE_OF_65M',
      attributeName: '65mDate',
      dateFormat: 'FORMAT.DATE',
      settingId: '65mDate',
      transpile: stringDateToTableDate,
      valueTooltip: true
    },
    {
      align: TableAlign.END,
      columnType: TableColumnType.CURRENCY,
      label: 'INVOICE_GENERALS.PAID_VALUE',
      attributeName: 'paidValue',
      settingId: 'paidValue',
      transpile: (payment: number) => payment? payment: ''
    },
    {
      align: TableAlign.START,
      columnType: TableColumnType.COUNTER_ICON,
      label: 'INVOICE.PAGE.ATTACHMENTS',
      attributeName: 'attachmentNumber',
      maxWidth: '30px',
      settingId: 'attachmentNumber',
      sortDisabled: true,
      transpile: (attachmentCounter: number): TableCounterIcon => ({
        counter: attachmentCounter,
        iconColor: IconColors.BLUE,
        icon:Icon.ATTACH_FILE,
        label: ''
      })
    },
    {
      align: TableAlign.CENTER,
      columnType: TableColumnType.DATE,
      label: 'INVOICE.DEFFER_DATE',
      attributeName: 'defferedVatDate',
      dateFormat: 'FORMAT.DATE',
      settingId: 'defferedVatDate',
      transpile: stringDateToTableDate,
      valueTooltip: true
    },
    {
      align: TableAlign.START,
      columnType: TableColumnType.TEXT,
      label: 'INVOICE.DIVISION',
      attributeName: 'divisionName',
      settingId: 'divisionName',
      valueTooltip: true,
      maxWidth: '160px',
      transpile: stringToTableText
    }
  ];

  const filtered = !filterOut || !filterOut.length
    ? items
    : items.filter((item) => !filterOut.includes(item.attributeName));

  const columns: Array<TableColumn> = !filterOut.includes('selected') ? [{...firstColumn}] : [];
  columnConfig.filter(column => {
    filtered.forEach(filter => {
      if(column === filter?.settingId){
        columns.push(filter);
      }
    });
  });

  columns.push(lastColumn);
  return columns;
};

/**
 * Payment vertical table config (invoice detail, quick-verify, invoice transaction, payment dialog)
 */
export const simpleTablePayout: Array<ColumnTableItem> = [
  {
    value: 'INVOICE.USER',
    key: 'fullName',
    type: 'text'
  },
  {
    value: 'INVOICE.DATE',
    type: 'date',
    key: 'date',
    align: TableAlign.CENTER,
    dateFormat: 'FORMAT.DATE'
  },
  {
    value: 'INVOICE.TYPE',
    type: 'text-link',
    align: TableAlign.CENTER,
    key: 'type'
  },
  {
    value: 'INVOICE.AMOUNT',
    type: 'currency',
    align: TableAlign.END,
    currencyKey: 'currency',
    key: 'grossValue',
    defaultCurrency
  },
  {
    value: '',
    type: 'round-button',
    key: 'loading',
    testId: 'deletePayment',
    align: TableAlign.CENTER,
    buttonIcon: Icon.DELETE,
    buttonLabel: 'LBL_BTN.DELETE',
    rights: [RightsEnum.DOCUMENT_FINANCIAL_SETTINGS_WRITE]
  }
];

const accountingStatusSimple: TableColumn = {
  align: TableAlign.START,
  columnType: TableColumnType.ICON_TEXT,
  label: 'INVOICE_GENERALS.ACCOUNTING_STATUS',
  attributeName: 'accountingStatus',
  settingId: 'accountingStatus',
  transpile: accountingStatusToTableIconText
};

const accountingStatusNotification: TableColumn = {
  align: TableAlign.START,
  columnType: TableColumnType.ICON_TEXT,
  label: 'INVOICE_GENERALS.ACCOUNTING_STATUS',
  attributeName: 'accountingStatusNotification',
  orderName: 'accountingStatus',
  settingId: 'accountingStatus',
  transpile: (accountingStatusNotification): TableIconText => ({
    ...convertToTableIconText(
      accountantStatus.find((value) => value.value === (accountingStatusNotification?.accountingStatus || accountingStatusNotification))
    ),
    notification: accountingStatusNotification?.notification? {
      label: 'INVOICE.UPLOAD_STATUS_MESSAGE_NO_RIGHTS',
      icon: Icon.PRIORITY_HIGH,
      color: IconColors.ORANGE
    }: {}
  })
};

export const defaultColumnConfig: Array<string> = ['partnerName', 'messageCounter',
  'invoiceNumber', 'accountingStatus', 'fillingStatus', 'paymentStatus',
  'completionDate', 'paymentDeadline', 'leftover', 'grossValue'];

export const defaultFilteredOutFilterOptions: Array<string> = [
  'netValue',
  'vatValue',
  'exchangeRate',
  'recordingDate',
  'uploadSuccess'
];

export const attachmentTableConfig = (showDeleteButton = true): Array<ColumnTableItem> => {
  const deleteButton: ColumnTableItem = showDeleteButton ? {
    value: '',
    type: 'round-button',
    key: 'delete',
    testId: 'deleteAttachment',
    align: TableAlign.CENTER,
    buttonIcon: Icon.DELETE,
    buttonLabel: 'LBL_BTN.DELETE',
    smallIconColumn: true
  } : null;

  return [
    {
      value: 'FILE_MANAGER.NAME',
      key: 'name',
      type: 'text'
    },
    {
      value: 'LBL_UPLOAD_FILL.LIST.UPLOAD_DATE',
      key: 'uploadDate',
      type: 'date',
      dateFormat: 'FORMAT.DATE_FULL',
      align: TableAlign.CENTER
    },
    {
      value: '',
      type: 'round-button',
      key: 'download',
      testId: 'downloadAttachment',
      align: TableAlign.CENTER,
      buttonIcon: Icon.DOWNLOAD,
      buttonLabel: 'LBL_BTN.DOWNLOAD',
      smallIconColumn: true
    },
    ...deleteButton ? [deleteButton] : []
  ];
};
