import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {dashboardConfig} from './dashboard.config';
import {debounceTime, filter, first, map, shareReplay, switchMap, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, concat, Observable, of, Subject, timer} from 'rxjs';
import {
  CompanySandbox,
  Currency,
  defaultCurrency,
  DocumentUploadSandbox,
  fadeInUp600ms,
  FinanceBalanceOverviewReport,
  FinanceCashFlowBalanceReport,
  FinanceInvoicesReportsCashFlowItem,
  FinanceReportsCashFlow,
  FinanceReportsProcessing,
  FinanceReportsVatBalance,
  FinanceSandbox,
  GeneralSandbox,
  InvoiceTypeEnum,
  NavigationService,
  NotificationSandbox,
  RightsEnum,
  RoutesEnum,
  utils,
  VatFrequency
} from '../../../modules/global';
import {DialogPresenterService, DocumentUploadDialogComponent} from '../../../modules/shared';
import {DomSanitizer} from '@angular/platform-browser';
import {NotificationAlertDialogComponent} from '../../components/notification-alert-dialog/notification-alert-dialog.component';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'slm-dashboard',
  templateUrl: './dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInUp600ms]
})
export class DashboardComponent implements OnInit, OnDestroy {
  public readonly routes = RoutesEnum;
  public readonly config = dashboardConfig;
  public vatBalance$: Observable<FinanceReportsVatBalance>;
  public balance$: Observable<FinanceCashFlowBalanceReport>;
  public income$: Observable<FinanceBalanceOverviewReport>;
  public cashFlowData$: Observable<FinanceReportsCashFlow>;
  public incomingOverview$: Observable<FinanceReportsProcessing>;
  public outgoingOverview$: Observable<FinanceReportsProcessing>;
  public outgoing$: Observable<FinanceBalanceOverviewReport>;
  public incomingCashFlowData$: Observable<Array<FinanceInvoicesReportsCashFlowItem>>;
  public outgoingCashFlowData$: Observable<Array<FinanceInvoicesReportsCashFlowItem>>;
  public showSentToBank$: Observable<boolean> = this.generalSandbox.useOnTransactionStatus$;

  public currency: Currency = defaultCurrency;
  public processParams = {};
  public readonly loading$ = new BehaviorSubject<boolean>(true);
  public readonly rights = RightsEnum;
  private readonly destroy$ = new Subject();
  private readonly refresh$ = new BehaviorSubject<boolean>(false);
  public readonly navVatToggleControl = new UntypedFormControl(false);

  constructor(
    private readonly navigation: NavigationService,
    private readonly financeSandbox: FinanceSandbox,
    private readonly companySandbox: CompanySandbox,
    private readonly notificationSandbox: NotificationSandbox,
    private readonly documentUploadSandbox: DocumentUploadSandbox,
    private readonly dialogService: DialogPresenterService,
    private readonly sanitizer: DomSanitizer,
    private readonly generalSandbox: GeneralSandbox
  ) {
  }

  ngOnInit() {
    const fromDate = new Date();
    fromDate.setDate(1);
    fromDate.setHours(0, 0, 0, 0);
    const toDate = new Date();
    toDate.setMonth(toDate.getMonth() + 1, 0);
    toDate.setHours(23, 59, 59, 0);
    this.processParams = {
      completionDateFrom: utils.dateToServerDate(fromDate),
      completionDateTo: utils.dateToServerDate(toDate)
    };
    this.companySandbox.companyChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loading$.next(true);
        this.refresh$.next(true);
      });
    this.refresh$
      .pipe(debounceTime(150), takeUntil(this.destroy$))
      .subscribe(() => this.loading$.next(false));

    const currentDate = new Date();

    this.notificationSandbox.systemNotification$.pipe(
      filter(value => (value || []).length > 0),
      map((response) => response.map(
        (notification) => ({
          ...notification,
          text: notification.type === 'text' ? notification.text : this.sanitizer.bypassSecurityTrustHtml(notification.text)
      }))),
      tap(notifications =>
        this.dialogService.openDialog(NotificationAlertDialogComponent,
          {items: notifications, showAll: true},
          {disableClose: true}).pipe(
          takeUntil(this.destroy$)
        ).subscribe()
      ),
      switchMap(() => this.dialogService.getEmitService()),
      debounceTime(200),
      filter((value: any) => value.event === 'approve' && value.id),
      switchMap((value) => this.notificationSandbox.acceptNotifications(value.id)),
      tap(() => this.notificationSandbox.refreshGeneral()),
      takeUntil(this.destroy$)
    ).subscribe();

    this.cashFlowData$ = this.refresh$.pipe(
      switchMap(() => timer(150)),
      switchMap(() => this.financeSandbox.getCashFlow('currentWeek', false)),
      tap(() => this.loading$.next(false))
    );

    const pastWeek = utils.currentDateMinusDays(7);

    this.incomingCashFlowData$ = this.refresh$.pipe(
      switchMap(() => timer(300)),
      switchMap(() =>
        this.financeSandbox.getInvoicesCashFlow(
          'daily',
          false,
          'incomes',
          'completeDate',
          pastWeek,
          currentDate
        )
      ),
      tap(() => this.loading$.next(false)),
      map((response) => response.items)
    );

    this.outgoingCashFlowData$ = this.refresh$.pipe(
      switchMap(() => timer(600)),
      switchMap(() =>
        this.financeSandbox.getInvoicesCashFlow(
          'daily',
          false,
          'outgoings',
          'completeDate',
          pastWeek,
          currentDate
        )
      ),
      tap(() => this.loading$.next(false)),
      map((response) => response.items)
    );

    this.vatBalance$ = combineLatest([
      concat(of(this.navVatToggleControl.value), this.navVatToggleControl.valueChanges),
      this.refresh$
    ]).pipe(
      switchMap(() => timer(900)),
      switchMap(() => this.generalSandbox.vatFrequency$),
      filter(frequency => !!frequency),
      switchMap((frequency) =>
        this.financeSandbox.getVatBalance({
          navVat: this.navVatToggleControl.value,
          year: currentDate.getFullYear(),
          ...(frequency === VatFrequency.YEARLY ? {}
            : frequency === VatFrequency.MONTHLY ? {month: currentDate.getMonth()}
              : {quarter: utils.yearQuarter(currentDate)})
        })
      ),
      tap(() => this.loading$.next(false))
    );

    this.balance$ = this.refresh$.pipe(
      switchMap(() => timer(1200)),
      switchMap(() => this.financeSandbox.getCashFlowBalance()),
      tap(() => this.loading$.next(false)),
      shareReplay({bufferSize: 1, refCount: true})
    );

    this.income$ = combineLatest([this.balance$]).pipe(
      map(([balance]) => balance.incomes)
    );

    this.outgoing$ = combineLatest([this.balance$]).pipe(
      map(([balance]) => balance.outgoings)
    );

    this.companySandbox.companyCurrency$
      .pipe(first())
      .subscribe((currency) => (this.currency = currency || defaultCurrency));

    const prevMonth = new Date();
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    this.incomingOverview$ = this.refresh$.pipe(
      switchMap(() => timer(1500)),
      switchMap(() => this.financeSandbox.getInvoicesStatus(fromDate, toDate, InvoiceTypeEnum.INCOMING)),
      tap(() => this.loading$.next(false))
    );

    this.outgoingOverview$ = this.refresh$.pipe(
      switchMap(() => timer(1800)),
      switchMap(() => this.financeSandbox.getInvoicesStatus(fromDate, toDate, InvoiceTypeEnum.OUTGOING)),
      tap(() => this.loading$.next(false))
    );
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public pageButtonEvent(event: string) {
    if (event === 'new_upload') {
      this.dialogService
        .openDialog(DocumentUploadDialogComponent, {
          fileUploading: this.documentUploadSandbox.upload,
          documentDelete: this.documentUploadSandbox.delete
        })
        .pipe(filter((value) => !!value))
        .subscribe(() =>
          this.navigation.navigate([RoutesEnum.INVOICES_UPLOADS])
        );
    }
  }
}
