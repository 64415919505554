import {Directive, Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {UserSandbox} from '../sandboxes';
import {NotificationService} from '../services';
import {NotificationEnum, RightsEnum, RoutesEnum} from '../enums';

@Directive()
export abstract class AbstractAccessGuard  {
  constructor(private userSandbox: UserSandbox,
              private notification: NotificationService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const rights = route.data['rights'];
    const exceptRights = route.data['exceptRights'];
    const redirectUrl = route.data['redirect'];
    const disableNotification = route.data['disableNotification'];
    return this.canActivateObservable(rights || [], exceptRights || []).pipe(
      tap((canActivate) => {
        if (!canActivate) {
          if (!disableNotification) {
            this.notification.notify(
              'LBL_INPUT.ERROR.PAGE_NO_ACCESS',
              NotificationEnum.ERROR
            );
          }
          this.router.navigate([redirectUrl || `/${RoutesEnum.NO_ACCESS}`]);
        }
      }),
      map(hasAccess => !!hasAccess)
    );
  }

  private canActivateObservable(rights: Array<RightsEnum>, exceptRights: Array<RightsEnum>): Observable<boolean> {
    if (exceptRights?.length) {
      return combineLatest([
        this.hasAccess(rights),
        this.hasAccessExcept(exceptRights)
      ]).pipe(map(([canActivate, except]) => canActivate && except));
    }
    return this.hasAccess(rights || []);
  }

  private hasAccessExcept(rights: Array<RightsEnum>): Observable<boolean> {
    return this.userSandbox.hasAccessExcept(rights);
  }

  abstract hasAccess(rights: Array<RightsEnum>): Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class HasAccessGuard extends AbstractAccessGuard {
  constructor(
    private readonly userSandboxObj: UserSandbox,
    private readonly routerObj: Router,
    private readonly notificationServiceObj: NotificationService
  ) {
    super(userSandboxObj, notificationServiceObj, routerObj);
  }

  hasAccess(rights: Array<RightsEnum>): Observable<boolean> {
    return this.userSandboxObj.hasAccess(rights || []);
  }
}

@Injectable({
  providedIn: 'root'
})
export class HasAccessOrGuard extends AbstractAccessGuard {
  constructor(
    private readonly userSandboxObj: UserSandbox,
    private readonly routerObj: Router,
    private readonly notificationServiceobj: NotificationService
  ) {
    super(userSandboxObj, notificationServiceobj, routerObj);
  }

  hasAccess(rights: Array<RightsEnum>): Observable<boolean> {
    return this.userSandboxObj.hasAccessOr(rights || []).pipe(
      map(hasAccess => !!hasAccess)
    );
  }
}
