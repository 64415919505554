import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorHandlingService} from './error-handling.service';

@Injectable({
  providedIn: 'root'
})
export class BankingService {

  constructor(private readonly http: HttpClient,
              private readonly errorHandler: ErrorHandlingService) {}

  refreshAccountConsent(id: number,
                        originUrl: 'settings_bankaccounts' | 'banking_accounts'
  ): Observable<any | {redirectUrl: string}> {
    return this.http
      .get(`/bankaccounts/${id}/refresh-consent/${originUrl}`)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }
}
