import { User } from './user.entity';

export interface AuthorizationObject extends AuthorizationTokens {
  user: User;
}

export interface AuthorizationTokens {
  authToken: string;
  refreshToken: string;
}
