import {Injectable} from '@angular/core';
import {GlobalInvoiceService} from '../services';
import {downloadImage, downloadPdf, printImage, printPdf} from '../functions';
import {InvoiceTypeEnum} from '../enums';
import {ImagesData, TableOrder} from '../entities';
import {Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GlobalInvoiceSandbox {
  private isImageDownloading = false;
  private isPdfDownloading = false;

  constructor(
    private readonly invoiceService: GlobalInvoiceService) {
  }

  public rotateImage = (id: number, isLeft: boolean) =>
    this.invoiceService.rotateImage(id, isLeft ? 'left' : 'right').pipe(
      switchMap(() => this.downloadImageData(id, 'blob', true))
    );

  public downloadImageData = (id: string | number, responseType = 'arraybuffer', clearCache = false, cacheEnabled = true) =>
    this.invoiceService.downloadImage(id, responseType, clearCache, cacheEnabled);

  public getImagesData = (id: number): Observable<ImagesData> =>
    this.invoiceService.getImagesData(id).pipe(
      map(value => ({...value, id}))
    );

  public downloadImage(
    id: number | string,
    title: string | number = 'InvoiceImage'
  ) {
    if (!this.isImageDownloading) {
      this.isImageDownloading = true;
      this.downloadImageDocument(id, `SLM_${title}_${new Date().getTime()}`).subscribe({
        next: () => this.isImageDownloading = false,
        error: () => this.isImageDownloading = false
      });
    }
  }

  public readonly downloadImageDocument = (
    id: number | string,
    title: string | number = 'InvoiceImage'
  ): Observable<boolean> => this.downloadImageData(id, 'arraybuffer', true, false).pipe(
    tap((image) => downloadImage(image, title)));

  public readonly downloadPdfDocument = (id: string | number, title: string = 'InvoicePdf') =>
    this.downloadPdfData(id).pipe(tap((blob) => downloadPdf(blob, title)));

  public printImage(id: number | string) {
    if (!this.isImageDownloading) {
      this.isImageDownloading = true;
      this.downloadImageData(id, 'arraybuffer', true, false).subscribe({
        next: (image) => {
          printImage(image);
          this.isImageDownloading = false;
        },
        error: () => this.isImageDownloading = false
      });
    }
  }

  public downloadPdf(id: number | string, title: string = `SLM_${id}_${new Date().getTime()}`): void {
    if (!this.isPdfDownloading) {
      this.isPdfDownloading = true;
      this.downloadPdfDocument(id, title).subscribe({
        next: () => this.isPdfDownloading = false,
        error: () => this.isPdfDownloading = false
      });
    }
  }

  public downloadDocumentAsPdf(documentId: number, documentName: string): void {
    if (!this.isPdfDownloading) {
      this.isPdfDownloading = true;
      this.invoiceService.downloadDocumentPdf(documentId).subscribe({
        next: (blob) => {
          downloadPdf(blob, documentName.substring(0, documentName.lastIndexOf('.')));
          this.isPdfDownloading = false;
        },
        error: () => (this.isPdfDownloading = false)
      });
    }
  }

  public printPdf(id: number | string): void {
    if (!this.isPdfDownloading) {
      this.isPdfDownloading = true;
      this.downloadPdfData(id).subscribe({
        next: (blob) => {
          printPdf(blob);
          this.isPdfDownloading = false;
        },
        error: () => this.isPdfDownloading = false
      });
    }
  }

  public readonly saveInvoicePayments = (id: number, deposits: Array<any>) =>
    this.invoiceService.saveInvoicePayments(id, deposits);

  public readonly getInvoicePayments = (id: number) =>
    this.invoiceService.getInvoicePayments(id);

  public readonly deleteInvoicePayments = (id: number) =>
    this.invoiceService.deleteInvoicePayments(id);

  public readonly getInvoice = (id: number) =>
    this.invoiceService.getInvoice(id);

  public readonly getInvoices = (
    invoiceType: InvoiceTypeEnum,
    pageNumber: number = 1,
    itemsPerPage: number = 10,
    order: TableOrder = null,
    filters: any = null,
    searchText: string = '',
    isCollecting: boolean = false
  ) =>
    this.invoiceService.getInvoices(
      invoiceType,
      pageNumber,
      itemsPerPage,
      order,
      filters,
      searchText,
      isCollecting
    );

  public readonly exportInvoiceList = (
    invoiceType: InvoiceTypeEnum,
    config: {
      externalSystem?: string;
      exportFormat?: 'excel' | 'pdf';
      filters?: any;
      searchText?: string;
      selectedIds?: Array<number>;
      extraFields?: string;
      merge?: string;
    }) => this.invoiceService.exportInvoiceList(invoiceType, config);

  public readonly publicInvoice = jwtToken => this.invoiceService.publicInvoice(jwtToken);

  public readonly downloadPublicInvoice = jwtToken => this.invoiceService.downloadPublicInvoice(jwtToken);

  private readonly downloadPdfData = (id: string | number) =>
    this.invoiceService.downloadPdf(id);
}
