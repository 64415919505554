import { Injectable } from '@angular/core';
import {NotificationEnum, NotificationTimes} from '../enums';
import { ReplaySubject } from 'rxjs';
import { Notification } from '../entities';
import {serverMessages} from '../static';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _notification$ = new ReplaySubject<Notification>(1);

  public notification$ = this._notification$.asObservable();

  notify(
    message: string,
    type: NotificationEnum = NotificationEnum.SUCCESS,
    duration: number = NotificationTimes.DEFAULT,
    messageParams?: any
  ) {
    if (message && !Object.values(serverMessages).includes(message)) {
      this._notification$.next({ message, type, duration, messageParams});
    }
  }
}
