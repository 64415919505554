<ng-container *ngIf="!isLoading else loading">
  <ng-container *ngIf="storageEnabled$ | async else storageDisabled">
    <slm-page-container
      *ngIf="mode === 'page'"
      [title]="'LBL_MENU.FILE_MANAGER'"
      [layoutWidth]="'large'"
      [primaryButtonConfig]="newFileButton"
      [secondaryButtonConfig]="newFolderButton"
      (fireEvent)="buttonClicked($event)"
    >
      <ng-container *ngTemplateOutlet="fileManagerTemplate"></ng-container>
    </slm-page-container>

    <ng-container *ngIf="mode === 'windowed'"><ng-container *ngTemplateOutlet="fileManagerTemplate"></ng-container></ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <slm-loader></slm-loader>
</ng-template>

<ng-template #storageDisabled>
  <slm-page-container
    [title]="'LBL_MENU.FILE_MANAGER'"
  >
    <slm-titled-card class="w-max-container m-auto block mt-6">
      <svg-icon
        class="w-max-100p w-500px h-max-300 mt-5 m-auto block"
        src="/assets/pictures/illustrations/no-access.svg"
        applyClass="true"
      ></svg-icon>
      <div class="w-max-100p w-600px m-auto block mt-6 mb-6">
        <h5 class="txt-center-i">
          {{ 'FILE_MANAGER.FILE_MANAGER_DISABLED_DESCRIPTION' | translate }}
        </h5>
      </div>
    </slm-titled-card>
  </slm-page-container>
</ng-template>

<ng-template #fileManagerTemplate>
  <ng-container *ngIf="items$ | async as folders else loading">
    <slm-table-container
      @fadeInUp
      [restrictedWidth]="false"
      [title]="tableTitle"
      [searchText]="search$ | async"
      [selectionButtons]="selectionButtons"
      [showSelectionButtons]="!!isSelected && mode === 'page'"
      class="block mb-5 m-auto"
      hidePagination="true"
      searchEnabled="true"
      filterEnabled="true"
      filterButtonText="LBL_TABLE.FILTER"
      [disableAppearance]="mode === 'windowed'"
      (searchChanged)="setSearch($event)"
      (filterClicked)="openFilter()"
      (selectionButton)="selectionButtonClicked($event)"
    >
      <slm-breadcrumb
        [sections]="breadcrumbSections"
        [baseUrl]="baseUrl"
        (breadcrumbClicked)="breadcrumbClicked()"
      ></slm-breadcrumb>
      <slm-table-filter
        [config]="filterConfig"
        [isOpen]="filterIsOpen$ | async"
        [values]="filters$ | async"
        (filter)="filters = $event"
      ></slm-table-filter>
      <slm-drag-drop-table
        #dragDropTable
        [config]="tableConfig"
        [data]="folders"
        [loading]="false"
        [selectionAttributes]="selectionAttributes"
        (selected)="selection = $event"
        (elementDropped)="folderDropped($event, $event.selectedItems)"
        (menuClicked)="menuItemClicked($event)"
        (doubleClicked)="folderOpened($event)"
        (itemClicked)="openItem($event)"
      >
      </slm-drag-drop-table>
    </slm-table-container>
  </ng-container>
</ng-template>
