<div class="header w-100 flex flex-end flex-items-center">
  <slm-button-icon
    class="header__home"
    icon="menu"
    (click)="fireEvent.emit({ event: 'menu' })"
  ></slm-button-icon>
  <div class="flex">
    <div
      *ngIf="remainingDemoDays !== null"
      class="flex flex-aligned flex-items-center h-100 mt-1 mr-2 cursor-default"
      [matTooltip]="progressSpinnerTooltip | translate: {'days': remainingDemoDays} "
    >
      <mat-progress-spinner
        class=""
        [color]="progressSpinnerColor"
        mode="determinate"
        [value]="remainingDemoDaysValue"
        diameter="35"
      ></mat-progress-spinner>
      <div style="" class="pos-relative right-50">
        {{ remainingDemoDays }}
      </div>
    </div>
    <slm-button-icon
      [icon]="icons.INFO"
      id="header_notification-btn"
      class="fill-red size-25 mr-4 info-animation"
      elevation="true"
      [matTooltip]="'HELP.NAME' | translate"
      (click)="fireEvent.emit({ event: 'help_open' })"
    ></slm-button-icon>
    <slm-button-icon
      [icon]="
        notificationCount ? icons.NOTIFICATIONS_ACTIVE : icons.NOTIFICATIONS
      "
      class="fill-accent size-25 mr-4"
      elevation="true"
      [matTooltip]="'NOTIFICATION.NAME' | translate"
      [matMenuTriggerFor]="notificationMenu"
      (click)="fireEvent.emit({ event: 'notification_open' })"
      [badgeCount]="notificationCount"
    ></slm-button-icon>
    <slm-language-selector class="mt-1 mr-2"
                           [language]="user?.language"
                           (selected)="fireEvent.emit({event: 'language', data: $event})"></slm-language-selector>
    <button
      class="header__user flex flex-start flex-items-center"
      matRipple
      [matMenuTriggerFor]="profileMenu"
    >
      <slm-monogram [user]="user"></slm-monogram>
      <h4 class="header__user__name pl-2 txt-no-wrap txt-ellipsis">
        {{ user | name }}
      </h4>
    </button>
  </div>
</div>

<mat-menu #profileMenu="matMenu" class="user-menu">
  <div
    *ngIf="user"
    class="pl-2 pt-1 pr-2 pb-2 border-bottom flex flex-start flex-items-center">
    <slm-monogram
      class="user-menu__header__picture"
      [user]="user"
      [size]="3"
    ></slm-monogram>
    <div class="pl-2">
      <h4 class="w-100 w-max-160 block txt-ellipsis txt-no-wrap mb-1">
        {{ user | name }}
      </h4>
      <span class="w-100 w-max-160 block txt-ellipsis txt-no-wrap">{{
        user.email
      }}</span>
    </div>
  </div>
  <ng-container *ngIf="user">
    <div
      *ngFor="let menu of userMenu; trackBy: trackMenuByAction"
      mat-menu-item
      class="flex-i flex-start flex-items-center"
      (click)="fireEvent.emit({ event: menu.action })"
      (keyup.enter)="fireEvent.emit({ event: menu.action })"
    >
      <slm-svg-icon
        *ngIf="menu.icon"
        [icon]="menu.icon"
        size="25px"
        class="fill-accent d-inline-block"
      ></slm-svg-icon>
      <h5 class="pl-2 mb-0 txt-medium d-inline-block">{{ menu.name | translate }}</h5>
    </div>
    <span class="border-bottom w-100 d-block"></span>
  </ng-container>
  <slm-button
    (click)="fireEvent.emit({ event: 'sign_out' })"
    buttonStyle="secondary"
    class="user-menu__button mt-3 mb-1 ml-2 mr-2 w-auto"
    text="LBL_BTN.LOGOUT"
  ></slm-button>
</mat-menu>
<mat-menu #notificationMenu="matMenu">
  <h5
    *ngIf="
      !!notifications && notifications.length === 0;
      else notificationContainer
    "
    class="p-3 mb-0"
    translate="MESSAGE.NO_NOTIFICATIONS"
  ></h5>
</mat-menu>

<ng-template #notificationContainer>
  <div
    class="notification-menu"
    [class.notification-menu--no_content]="!notifications?.length"
  >
    <div
      cdk-scrollable
      class="block overflow-y-auto">
      <div
        *ngFor="let item of notifications; trackBy: trackNotificationById"
        class="p-2 w-auto w-min-250px flex d-flex flex-column cursor-pointer"
        [class.bg-color-accent-transparent-lighter]="!item.checked"
        (click)="
          fireEvent.emit({ event: 'notification_click', data: item.data })
        "
      >
        <div class="d-flex flex flex-space-btwn">
          <h4 class="txt-start-i txt-no-wrap mb-0"
              translate="NOTIFICATION.{{ item.type.toUpperCase() }}">
          </h4>
          <span>{{ item.date | dateTransform: "FORMAT.DATE_FULL" }}</span>
        </div>
        <h5 class="notification-menu__text">{{ item.text }}</h5>
      </div>
      <slm-button
        *ngIf="!notificationLoading && hasMoreNotification"
        class="block m-auto d-block mt-2 mb-2 w-fit-content"
        buttonStyle="transparent-blue"
        height="small"
        text="NOTIFICATION.LOAD_MORE"
        (click)="fireEvent.emit({ event: 'notification_next' })"
      ></slm-button>
      <slm-loader
        *ngIf="notificationLoading"
        diameter="30"
        class="d-block m-auto mt-3 mb-3"
      ></slm-loader>
    </div>
  </div>
</ng-template>
