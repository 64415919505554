<div *ngIf="data" class="flex flex-column w-100">
  <div class="flex w-100 flex-end">
    <h5 *ngIf="!inputIsVisible; else inputContainer"
        [matTooltip]="tooltip | translate"
        [class]="'w-100 m-0 block pt-1 table__column--no-wrap txt-' + (align || 'center') + '-i'"
        [style.color]="textColor"
    >
      <ng-container>
        <ng-container
          *ngIf="data.currency; else normalValue">{{control.value | currencyTransform: data.currency}}</ng-container>
        <ng-template #normalValue>{{control.value}}</ng-template>
      </ng-container>
    </h5>
    <slm-button-icon *ngIf="!data.disableEditable" [icon]="icons.CREATE"
                     size="20px"
                     class="size-15 fill-accent mv-auto ml-2 block"
                     [matTooltip]="'LBL_BTN.UPDATE' | translate"
                     (click)="changeInputVisibility()"></slm-button-icon>
  </div>
  <span *ngIf="subLabel$ | async as subLabel"
        [style.color]="subLabel.color"
        class="txt-end">{{subLabel.label | translate: subLabel.params}}</span>
</div>

<ng-template #inputContainer>
  <slm-amount-input *ngIf="data.currency; else normalInput" [control]="control"
                    class="w-max-140 h-3 mb-1 block"
                    smallInput="true"
                    (fireEvent)="inputEvent($event)"
                    enterEnabled="true"
                    (leave)="changeInputVisibility()"
                    [currency]="data.currency"></slm-amount-input>
  <ng-template #normalInput>
    <slm-text-area *ngIf="data.inputType === 'long-text'"
                   [control]="control"
                   class="h-5 mb-1 block"
                   smallInput="true"
                   minRow="3"
                   (fireEvent)="inputEvent($event)"
                   enterEnabled="true"
                   (leave)="changeInputVisibility()"></slm-text-area>
    <slm-text-input *ngIf="data.inputType !== 'long-text'"
                    [control]="control"
                    [type]="data.inputType"
                    class="h-3 mb-1 block"
                    smallInput="true"
                    (fireEvent)="inputEvent($event)"
                    enterEnabled="true"
                    (leave)="changeInputVisibility()"></slm-text-input>
  </ng-template>
</ng-template>
