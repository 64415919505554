import {Base} from './general.entity';
import {DefaultLedgerNumberValue} from './accounting-settings.entity';

export interface AccountingTemplate {
  id?: string | number;
  ti_id?: string | number;
  accountingComment?: string;
  accountingCostCenter?: string;
  accountingCostCenterId: number;
  accountingCostLedgerNumber: LedgerNumber;
  deductibleVatCode?: Base;
  accountingGrossValueSummary?: string | number;
  accountingName?: string;
  accountingNetSummaryPrice?: string | number;
  accountingNetUnitPrice?: string | number;
  accountingQuantity?: string | number;
  accountingQuantitySuffix?: string;
  accountingVATCode?: string;
  accountingVATCodeId?: number;
  accountingVATSummary?: string | number;
  accountingTariffNumber?: string;
  accountingVATDeductibleRate?: number;
  deductibleLedgerNumber: DefaultLedgerNumberValue;
  customsTariff?: CustomsTariffsNumber;
  vendorLedgerNumber?: DefaultLedgerNumberValue;
  accountingVTSZ2?: string;
  accounting1?: Accounting;
  accounting2?: Accounting;
  accounting3?: Accounting;
  accounting4?: Accounting;
  note?: string;
  overwriteComment?: boolean;
  accrual?: boolean;
  accrualBackward?: AccrualDate;
  accrualForward?: AccrualDate;
  accrualType?: 'daily' | 'monthly' | 'yearly';
  accrualSum?: number;
}

export interface CustomsTariffsNumber {
  id: number;
  tariffNumber: string;
  description: string;
}

interface Accounting {
  id: string | number;
  name: string;
}

export interface LedgerNumber extends DefaultLedgerNumberValue{
  accounting1: Accounting;
  accounting2: Accounting;
  accounting3: Accounting;
  accounting4: Accounting;
}

export interface AccrualDate {
  date: string;
  ledgerNumber: DefaultLedgerNumberValue;
}
