import { ButtonConfig, ColumnTableItem } from './index';

export class DeleteDialogConfig {
  title: string;
  message: string;
  tableConfig: Array<ColumnTableItem>;
  items: Array<any>;
  warning?: string;
  buttons?: Array<ButtonConfig>;
  itemTranspile?: any;
}
