import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { SelectOption, PaginatorNew, mobileWidth } from '../../../../global';
import { UntypedFormControl } from '@angular/forms';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'slm-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnDestroy {
  // * Inputs & Outputs ---------------------------------------------
  //#region Inputs & Outputs
  /**
   * Available page sizes like [5,10,20,etc...]
   */
  @Input() public pageSizeOptions: Array<SelectOption> = [];

  /**
   * number of all items
   */

  /**
   * Paginator object which contains: actualPage, pageSize, fullLength
   */
  @Input('page')
  public set setPage(page: PaginatorNew) {
    const newPage = page || { size: 10, page: 1, fullLength: 0 };
    if (
      +newPage.fullLength !== +this.page.fullLength ||
      +newPage.size !== +this.page.size ||
      +newPage.page !== +this.page.page
    ) {
      this.page = newPage;
      this.allPageSize = Math.ceil(+this.page.fullLength / +this.page.size);
      this.sizeControl.setValue(+this.page.size);
      this.fillPagesArray();
    }
  }

  public page: PaginatorNew = { size: 10, page: 1, fullLength: 0 };

  /**
   * Disable the paginator
   */
  @Input('disabled')
  public set setDisabled(disabled: boolean) {
    this.disabled = disabled;
    if (disabled) {
      this.sizeControl.disable();
    } else {
      this.sizeControl.enable();
    }
  }

  @Output() readonly pageChange = new EventEmitter<PaginatorNew>();

  //#endregion

  // * Data Members -------------------------------------------------
  //#region Data Members

  public readonly sizeControl = new UntypedFormControl(+this.page.size);
  public disabled = false;
  public pages: Array<number> = [];
  protected allPageSize: number;
  public nextBtnDisabled = false;
  public previousBtnDisabled = false;

  private destroy$ = new Subject();
  //#endregion

  // * LifeCycle Hooks ----------------------------------------------
  //#region LifeCycle Hooks
  ngOnInit() {
    if (this.page) {
      this.sizeControl.setValue(+this.page.size);
    }
    this.sizeControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter((value) => !!value && +this.page.size !== value),
        takeUntil(this.destroy$)
      )
      .subscribe((pageSize) => {
        this.pageChange.emit({
          size: +pageSize,
          page: 1,
          fullLength: this.page.fullLength
        });
        this.allPageSize = Math.ceil(+this.page.fullLength / +pageSize);
        this.fillPagesArray();
      });
    this.allPageSize = Math.ceil(+this.page.fullLength / +this.page.size);
    this.fillPagesArray();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  //#endregion

  // * Operations ---------------------------------------------------
  //#region Operations
  /**
   * Change the actual page
   *
   * @param selectedPage
   */
  public onActualPageChange(selectedPage: number): void {
    if (
      selectedPage !== +this.page.page &&
      !this.disabled &&
      selectedPage !== -1 &&
      selectedPage <= +this.allPageSize
    ) {
      this.pageChange.emit({ ...this.page, page: selectedPage });
    }
  }

  /**
   * Next page
   */
  public onClickPageForward(): void {
    if (!this.disabled && +this.page.page < +this.allPageSize) {
      this.pageChange.emit({ ...this.page, page: +this.page.page + 1 });
    }
  }

  /**
   * Previous page
   */
  public onClickPageBackward(): void {
    if (!this.disabled && +this.page.page !== 1) {
      this.pageChange.emit({ ...this.page, page: +this.page.page - 1 });
    }
  }

  /**
   * Fill pages array algorithm shorter
   */
  public fillPagesArray() {
    if (this.allPageSize <= 0) {
      this.pages = [];
    } else if (+this.allPageSize <= 7) {
      this.pages = [1, 2, 3, 4, 5, 6, 7].splice(0, +this.allPageSize);
    } else {
      const current = +this.page.page;
      const pageCount = +this.allPageSize;
      if (current < 3) {
        this.pages = [1, 2, 3, -1, pageCount - 2, pageCount - 1, pageCount];
      } else if (current === 3) {
        this.pages = [1, 2, 3, 4, -1, pageCount - 1, pageCount];
      } else if (current > pageCount - 2) {
        this.pages = [1, 2, 3, -1, pageCount - 2, pageCount - 1, pageCount];
      } else if (current === pageCount - 2) {
        this.pages = [
          1,
          2,
          -1,
          pageCount - 3,
          pageCount - 2,
          pageCount - 1,
          pageCount
        ];
      } else {
        this.pages = [1, -1, current - 1, current, current + 1, -1, pageCount];
      }
    }
    this.buttonVerification();
  }

  /**
   * Next and Previous page button validation
   */
  private buttonVerification(): void {
    this.nextBtnDisabled =
      +this.page.page >= +this.allPageSize || !this.pages.length;
    this.previousBtnDisabled = +this.page.page <= 1 || !this.pages.length;
  }

  public marginRightOfBg() {
    const index = this.pages.indexOf(+this.page.page);
    return (
      (index >= 0 ? index : 0) *
      ((mobileWidth >= window.innerWidth ? 2 : 2.5) + 0.25)
    );
  }

  //#endregion
}
