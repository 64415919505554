import { Injectable } from '@angular/core';
import { DocumentUploadService } from '../services';
import {FileUploadModes} from '../enums';

@Injectable({
  providedIn: 'root'
})
export class DocumentUploadSandbox {
  constructor(private readonly uploadService: DocumentUploadService) {}

  public delete = (ids: Array<number>) => this.uploadService.delete(ids);

  public upload = (file: File, uploadMode: FileUploadModes, fileName?: string, userDivisionId?: number) =>
    this.uploadService.upload(file, uploadMode, fileName, userDivisionId);
}
