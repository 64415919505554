import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Sort} from '@angular/material/sort';
import {FireEvent, Icon, IconColors, ItemType, stagger60ms, TableColumn, TableColumnType, TableOrder, utils} from '../../../../global';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'slm-drag-drop-table',
  templateUrl: './drag-drop-table.component.html',
  styleUrls: ['./drag-drop-table.component.scss'],
  animations: [stagger60ms]
})
export class DragDropTableComponent {

  @Input('config')
  public set setConfig(config: Array<TableColumn>) {
    this.config = (config || []).map(cnf => ({
      ...cnf,
      sortStart: !!cnf.sortStart ? cnf.sortStart : cnf.columnType === TableColumnType.DATE ? 'desc' : 'asc'
    }));
    this.columnNames = this.config.map((conf) => conf.attributeName);
    if (!!this.data) {
      this.setData = this.data;
    }
  }

  @Input() public disableClearSort = false;
  @Input() public loading = true;
  @Input() public stickyHeader = false;
  @Input() public selectionAttributes: Array<string> = ['id'];

  @Input('sort')
  set setSort(sort: TableOrder) {
    if (!sort) {
      this.sort = null;
      return;
    }
    const sortBy = this.config.find(column => column.orderName === sort.attribute)?.attributeName;
    this.sort = {
      attribute: sortBy ?? sort.attribute,
      order: sort.order
    };
  }

  @Input() public placeholder = 'LBL_TABLE.NO_DATA';
  @Input() public dragAndDropTable: boolean;
  @Input('data')
  public set setData(data: Array<any>) {
    this.data = data;
    if (this.config[0]?.columnType === TableColumnType.CHECKBOX && !!this.config[0]?.notClickable) {
      const disableFunction = this.config[0]?.notClickable as ((any) => boolean);
      const checkAndDisable = this.config[0]?.checkedAndDisabled as ((any) => boolean);

      this.dataSource.data = this.transpileData((this.data || []).map(row => ({
        ...row,
        checkboxDisabled: disableFunction(row),
        checkboxCheckedAndDisabled: checkAndDisable(row)
      })));
    } else {
      this.dataSource.data = this.transpileData();
    }
  }

  private data: Array<any> = null;
  public sort: TableOrder;
  public readonly selection = new SelectionModel<any>(true, []);

  public masterCheckboxDisabled = false;
  public multiDrag = false;
  public icons = Icon;
  public colors = IconColors;

  private transpileData(data: Array<any> = null) {
    const needToTranspile = (this.config || []).filter(config => !!config.transpile || !!config.color || config.tooltip);

    if (!needToTranspile.length || !this.data) {
      return this.data;
    }

    return (data || this.data || []).map(data => ({
      ...data,
      ...needToTranspile.reduce((prev, current) => ({
          ...prev,
          ...current.transpile ? {[current.attributeName]: current.transpile(data[current.attributeName])} : {},
          ...current.color ? {textColor: utils.isString(current.color) ? current.color : (current as any).color(data)} : {},
          ...current.tooltip || current.valueTooltip ? {
            [`tooltipLabel-${current.attributeName}`]: current.tooltip ?
              (utils.isString(current.tooltip) ? current.tooltip : (current as any).tooltip(data)) : data[current.attributeName]
          } : {},
          originalTableData: data
        }), {})
    }));
  }

  @Output() menuClicked = new EventEmitter<FireEvent>();
  @Output() otherEvent = new EventEmitter<FireEvent>();
  @Output() itemClicked = new EventEmitter<any>();
  @Output() orderChanged = new EventEmitter<TableOrder>();
  @Output() elementDropped = new EventEmitter<any>();
  @Output() doubleClicked = new EventEmitter<any>();
  @Output() selected = new EventEmitter<any>();
  @Output() selectedAll = new EventEmitter<boolean>();

  public readonly dataSource = new MatTableDataSource<any>([]);
  public columnTypes = TableColumnType;
  public config: Array<TableColumn> = [];
  public columnNames: Array<string> = [];

  public menuEvent(event: string, item: any): void {
    this.menuClicked.emit({event, data: item.originalTableData ?? item});
  }

  public doubleClick(item: any): void {
    this.doubleClicked.emit(item.originalTableData ?? item);
  }

  public openItem(item: any): void {
    this.itemClicked.emit(item.originalTableData ?? item);
  }

  public sortChanged(sort: Sort): void {
    const orderBy = (this.config || []).find(
      (config) => config.attributeName === sort.active
    );
    this.orderChanged.emit({
      order: this.disableClearSort && !sort.direction ? 'desc' : sort.direction,
      attribute: orderBy?.orderName || sort.active
    });
  }

  public getTooltip = (attribute: string, data: any) => data.originalTableData ? data.originalTableData[attribute] : data[attribute];

  public tableEvent(event: any, attribute: string, identifier: any = null, row: any = null): void {
    this.otherEvent.emit({
      event: attribute, data: {
        ...event,
        ...identifier ? {identifier} : {},
        ...row ? {row} : {}
      }
    });
  }

  drop(event: any) {
    this.multiDrag = false;
    const selectedItems: Array<{id: string, type: ItemType}> =
      (this.selection.selected || []).map(item => ({
        id: item.id,
        type: item.type
      }));
    this.elementDropped.emit({...event, selectedItems: selectedItems});
  }

  dragStarted(event: any) {
    const draggedElement = event.source.data;
    const selectedItems: Array<string> = (this.selection.selected || []).map(item => item.id);

    this.multiDrag = selectedItems.length > 1 && selectedItems.includes(draggedElement.id);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle() {
    if (this.isAllSelected()) {
      this.clearSelection();
    } else {
      if (this.config[0].columnType === TableColumnType.CHECKBOX && !!this.config[0].notClickable) {
        this.getSelectableItems().forEach((row) => this.selection.select(row));
        if (this.selection.selected.length === 0) {
          this.clearSelection();
        }
      } else {
        this.dataSource.data.forEach((row) => this.selection.select(row));
      }
    }
    this.emitSelection();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected() {
    const numSelected = this.selection?.selected?.length || 0;
    let numRows;
    if (this.config[0].columnType === TableColumnType.CHECKBOX && !!this.config[0].notClickable) {
      numRows = this.getSelectableItems().length;
    } else {
      numRows = this.dataSource?.data?.length || 0;
    }
    return numSelected === numRows;
  }

  private getSelectableItems() {
    return (this.dataSource.data || []).filter(row => !row.checkboxDisabled);
  }

  /** The label for the checkbox on the passed row */
  public checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  public selectionChanged(event, row): void {
    if (event) {
      this.selection.toggle(row);
    }
    this.emitSelection();
  }

  private emitSelection(): void {
    this.selectedAll.emit(this.isAllSelected());
    this.selected.emit(
      (this.selection.selected || []).map((selection) =>
        this.selectionAttributes && this.selectionAttributes.length
          ? this.getSelectedAttributes(selection)
          : selection
      )
    );
  }

  public clearSelection() {
    this.selection.clear();
  }

  private getSelectedAttributes(selection: any) {
    return this.selectionAttributes.reduce((obj, attribute) => ({
      ...obj,
      [attribute]: selection.originalTableData ? selection.originalTableData[attribute] : selection[attribute]
    }), {});
  }
}
