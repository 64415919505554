import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ButtonConfig, FireEvent } from '../../../global';

@Component({
  selector: 'slm-titled-card',
  templateUrl: './titled-card.component.html'
})
export class TitledCardComponent {
  @Input() title = '';
  @Input() buttonConfig: ButtonConfig = null;
  @Output() fireEvent = new EventEmitter<FireEvent>();
}
