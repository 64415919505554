<section class="dialog">
  <div *ngIf="title" class="dialog__header">
    <h4 class="dialog__header__title">{{ title | translate }}</h4>
  </div>
  <div class="dialog__content"
    [class.overflow-auto]="overflow === 'auto'"
    [class.overflow-hidden]="overflow === 'hidden'"
  >
    <div
      class="dialog__content__container"
      [class.dialog__content__container--disable-padding]="
        disableContainerPadding
      "
    >
      <ng-content></ng-content>
    </div>
  </div>
  <div *ngIf="buttons?.length" class="dialog__footer">
    <slm-button
      *ngFor="let button of buttons"
      [config]="button"
      [loading]="button.isLoading"
      class="dialog__footer__btn"
      [disabled]="isDisabled(button.action)"
      (click)="fireEvent.next({ event: button.action })"
    ></slm-button>
  </div>
</section>
