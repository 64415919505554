import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
  ButtonConfig,
  CompanySandbox,
  fadeInUp400ms,
  FileManagerMode,
  FileManagerRightTypeEnum,
  FileManagerTableItems,
  FileTypeEnum,
  FireEvent,
  Folder,
  GeneralSandbox,
  Icon,
  IconColors,
  ItemType,
  mainFolderId,
  NavigationService,
  NotificationSandbox,
  NotificationService,
  PaginatorNew,
  RoutesEnum,
  SelectionButton,
  SelectOption,
  TableColumn,
  TableOrder,
  UserSandbox
} from '../../../global';
import {BehaviorSubject, combineLatest, concat, forkJoin, Observable, of, Subject, switchMap} from 'rxjs';
import {FileManagerSandbox} from '../../../global/sandboxes/file-manager.sandbox';
import {filter, first, map, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {DialogPresenterService, DocumentUploadDialogComponent, PageRouterTableContainer} from '../../index';
import {FolderEditDialogComponent} from '../../../file-manager/dialogs/folder-edit-dialog/folder-edit-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {newFileButton, newFolderButton, tableConfig, tableFilters, tableSelectionButtons} from './file-manager.container.config';
import {BreadcrumbSection} from '../../components/breadcrumb/breadcrumb.component';
import {FileEditDialogComponent} from '../../../file-manager/dialogs/file-edit-dialog/file-edit-dialog.component';
import {CompanyUserSandbox} from '../../../users/sandboxes/company-user-sandbox.service';
import {DragDropTableComponent} from '../../components/drag-drop-tables/drag-drop-table/drag-drop-table.component';

@Component({
  selector: 'slm-file-manager',
  templateUrl: './file-manager.container.html',
  styleUrls: ['./file-manager.container.scss'],
  animations: [fadeInUp400ms]
})
export class FileManagerContainer extends PageRouterTableContainer implements OnInit, OnDestroy {
  public folders$ = new BehaviorSubject(null);
  public files$ = new BehaviorSubject(null);
  public search$ = new BehaviorSubject(null);
  public openedFolder$ = new BehaviorSubject<string>('');
  public items$: Observable<Array<FileManagerTableItems>>;
  public filters$: Observable<any>;
  public destroy$ = new Subject();
  public storageEnabled$: Observable<boolean>;

  public tableConfig: Array<TableColumn>;
  public breadcrumbSections: Array<BreadcrumbSection> = [];
  public tableTitle: string;
  public filterConfig = tableFilters;
  public userOptionsList: Array<SelectOption> = [];
  public isLoading = true;
  public selectionButtons: Array<SelectionButton>;
  public newFolderButton: ButtonConfig;
  public newFileButton: ButtonConfig;
  public readonlyUser: boolean;

  public readonly icons = Icon;
  public readonly iconColors = IconColors;
  public readonly baseUrl = RoutesEnum.FILE_MANAGER;
  public readonly selectionAttributes = ['id', 'name', 'fileType', 'uploadDate'];

  private currentFolderId = mainFolderId;
  private currentFolder: Folder;
  private items: Array<FileManagerTableItems>;

  @ViewChild('dragDropTable', {static: false}) tableContainer: DragDropTableComponent;

  @Input()
  public mode: FileManagerMode = 'page';

  @Input()
  public filterIds: Array<string> = [];

  @Output()
  public selectedAttachments = new EventEmitter<any>();

  constructor(
    private readonly fileManagerSandbox: FileManagerSandbox,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationService: NavigationService,
    private readonly dialog: DialogPresenterService,
    private readonly companySandbox: CompanySandbox,
    private readonly transation: TranslateService,
    private readonly notification: NotificationService,
    private readonly companyUserSandbox: CompanyUserSandbox,
    private readonly notificationSandbox: NotificationSandbox,
    private readonly generalSandbox: GeneralSandbox,
    private readonly userSandbox: UserSandbox
  ) {
    super(navigationService, activatedRoute);
  }

  ngOnInit() {
    super.ngOnInit();

    this.tableConfig = tableConfig(this.mode, this.filterIds);
    if(this.mode === 'page'){
      this.selectionButtons = tableSelectionButtons;
    }

    this.fileManagerSandbox.openDialog$.pipe(
      tap(open => {
        switch(open){
          case 'folder':
            this.newFolder();
            break;
          case 'file':
            this.newFile();
            break;
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe();

    this.companySandbox.companyChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.router.navigate([RoutesEnum.HOME]);
      });

    this.storageEnabled$ = this.generalSandbox.getStorageUsage$.pipe(
      map(usage => usage?.enabled),
      filter(usage => usage !== undefined),
      tap(() => this.isLoading = false)
    );

    combineLatest([
      this.mode === 'page' ? this.activatedRoute.params : concat(of(mainFolderId), this.openedFolder$),
      this.search$,
      this.filters$,
      this.storageEnabled$,
      this.userSandbox.user$
    ]).pipe(
      filter(([_, __, ___, storageEnabled, ____]) => !!storageEnabled),
      switchMap(([routeParams, search, filters, _, user]) => {
        let folder: string;
        if(this.mode === 'page'){
          const routeIdParam = (routeParams as Params)?.id;
          folder = routeIdParam ? routeIdParam : mainFolderId;
        }else{
          folder = (routeParams as string) ? (routeParams as string) : mainFolderId;
        }

        return forkJoin([
          of(folder),
          of({...filters, search}),
          this.fileManagerSandbox.getFolderContent(folder, search, filters),
          of(user)
        ]);
      }),
      tap(([folder, _, content, user]) => {
        this.currentFolderId = folder;
        this.currentFolder = content?.currentFolder;
        const folderRights = this.currentFolder.rights;
        const userRight = folderRights.find(right => right.id === user.id);

        if(!!userRight && userRight.right === FileManagerRightTypeEnum.READ){
          this.newFileButton = null;
          this.newFolderButton = null;
          this.readonlyUser = true;
        }else{
          this.readonlyUser = false;
          this.newFolderButton = {...newFolderButton};
          this.newFileButton = {...newFileButton};
        }

        this.tableTitle = 'FILE_MANAGER.OWN_DRIVE';
        this.breadcrumbSections = [];
        const folders = [...content?.folders];
        const parents = [...content?.parents];
        if (folder !== mainFolderId) {
          const lastParent =  parents.pop();
          this.tableTitle = content?.currentFolder?.name;
          folders.unshift({id: lastParent?.id, name: lastParent?.name});

          this.breadcrumbSections = (content?.parents || []).concat(content?.currentFolder || []).map(folder => ({
            name: folder.name === mainFolderId ? 'FILE_MANAGER.OWN_DRIVE' : folder.name,
            id: folder.name === mainFolderId ? mainFolderId : folder.id
          }));
        }
        this.folders$.next({files: content?.files, folders: folders});
        this.files$.next(content?.files);
      }),
      takeUntil(this.destroy$)
    ).subscribe();

    this.items$ = combineLatest([
      this.folders$,
      this.userSandbox.user$
    ]).pipe(
      filter(([items, _]) => !!items),
      map(([items, user]) => {
        return [
          ...items.folders.map((folder, index) =>
            ({
              ...folder,
              type: 'folder',
              fileType: this.currentFolderId !== mainFolderId && index === 0 ? 'back_folder' : 'folder',
              name: folder.name === mainFolderId && index === 0 ? 'FILE_MANAGER.OWN_DRIVE' : folder.name,
              id: folder.name === mainFolderId && index === 0 ? mainFolderId : folder.id,
              isEnabledToEdit: folder?.uploader?.id === user?.id
            })),
          ...items.files.map(file => ({...file, type: 'file'}))
        ];
      }),
      tap(value => this.items = value)
    );

    this.companyUserSandbox.getUsers(
      {
        page: 1,
        size: 100
      },
      null,
      '',
      true,
      true
    ).pipe(
      first(),
      tap(users => {
        this.userOptionsList = (users?.users || []).map(user => ({
            value: user.id,
            label: user.firstName,
            data: user
          }));

        this.filterConfig[1].config.options = this.userOptionsList;
      })
    ).subscribe();

    this.selection$.pipe(
      tap(value => this.selectedAttachments.emit(value)),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  loadData(search: string, page: PaginatorNew, order: TableOrder, filters: any): Observable<any> {
    return null;
  }

  public refreshData(search: string, page: PaginatorNew, order: TableOrder, filters: any): boolean {
    return true;
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  folderOpened(folder: FileManagerTableItems) {
    if(folder.type === 'folder'){
      this.resetFilters();
      this.tableContainer?.clearSelection();

      this.folders$.next(null);
      this.files$.next(null);
      this.openedFolder$.next(folder?.id);

      if(this.mode === 'page'){
        this.navigationService.navigate([RoutesEnum.FILE_MANAGER_ID], {id: folder?.id});
      }
    }
  }

  folderDropped(event: CdkDragDrop<string[]>, selectedItems?: Array<{id: string, type: ItemType}>) {
    const elem = event.item.data.originalTableData;
    const currentIndex = event.currentIndex;
    const previousIndex = event.previousIndex;
    const targetFolder = this.items[currentIndex];
    const type = targetFolder.type;
    const itemsToChange = [
      {id: elem.id, type: elem.type},
      ...(selectedItems || []).filter(item => item.id !== elem.id)];

    this.selection = [];
    this.tableContainer.clearSelection();

    if(type === 'folder' && (currentIndex != previousIndex)){
      const labelType = `${elem.name}
        ${this.transation.instant(elem.type === 'folder' ? 'FILE_MANAGER.MOVE_FOLDER_FOLDER_MOVE_TYPE' : 'FILE_MANAGER.MOVE_FOLDER_FILE_MOVE_TYPE')}`;

      this.dialog.openQuestionDialog(
        this.transation.instant('FILE_MANAGER.MOVE_FOLDER_QUESTION', {type: labelType}),
        'FILE_MANAGER.MOVE_FOLDER_QUESTION_DESCRIPTION',
        {
          type: labelType,
          folder_name: targetFolder.id === mainFolderId ? this.transation.instant('FILE_MANAGER.OWN_DRIVE') : targetFolder.name,
          and_more: itemsToChange.length > 1 ? this.transation.instant('FILE_MANAGER.AND_MORE', {number: (itemsToChange.length - 1)}) : ''
        }).pipe(
        filter(res => !!res),
        switchMap(() => this.fileManagerSandbox.changeFolder(
          itemsToChange.map(item => item.id),
          targetFolder.id)),
        tap(() => {
          itemsToChange.map(item => {
            this.deleteElement(item.id, item.type);
          });
        }),
        takeUntil(this.destroy$)
      ).subscribe();
    }
  }

  newFolder(){
    this.dialog.openDialog(FolderEditDialogComponent, {
      userOptionsList: this.userOptionsList,
      currentFolderRights: this.currentFolder?.rights || [],
      save: saveData => this.fileManagerSandbox.createFolder({...saveData, toFolder: this.currentFolderId})
    }).subscribe((value) => {
      this.resetFilters();
      this.tableContainer?.clearSelection();

      const content = this.folders$.getValue();
      this.folders$.next({...content, folders: [...content.folders, value]});
    });
  }

  newFile(){
    this.dialog.openDialog(DocumentUploadDialogComponent, {
      fileUploading: saveData => this.fileManagerSandbox.uploadFile(saveData, this.currentFolderId),
      documentDelete: this.fileManagerSandbox.deleteFiles,
      hideUploadOptions: true,
      title: 'LBL_UPLOAD_FILL.FILLING.UPLOAD',
      hideAllOpenIcon: true,
      acceptedFileMimeTypes:  [
        'application/pdf', 'text/plain',
        'image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp',
        'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      ].join(',')
    }).subscribe(response => {
      this.resetFilters();
      this.tableContainer?.clearSelection();
      this.notificationSandbox.refreshGeneral();

      const content = this.folders$.getValue();
      const newFiles = response.map(file => {
        return ({
          id: file.data.id,
          name: file.data.name,
          fileType: file?.data?.fileType,
          size: file?.data?.size,
          uploadDate: file?.data?.uploadDate,
          uploader: file?.data?.uploader,
        });
      });

      this.folders$.next({...content, files: [...content.files, ...newFiles.map(file => file)]});
    });
  }

  buttonClicked(event: string) {
    switch (event) {
      case 'add_folder':
        this.newFolder();
        break;
      case 'add_file':
        this.newFile();
        break;
      default:
        break;
    }
  }

  menuItemClicked(event: FireEvent) {
    switch (event.event) {
      case 'folderEdit':
        this.editFolder(event.data);
        break;
      case 'fileEdit':
        this.editFile(event.data);
        break;
      case 'fileDownload':
        this.downloadFile(event.data);
        break;
      case 'fileDelete':
        this.deleteItem(event.data);
        break;
    }
  }

  private editFolder(data){
    this.dialog.openDialog(FolderEditDialogComponent, {
      folder: data,
      userOptionsList: this.userOptionsList,
      currentFolderRights: this.currentFolder?.rights || [],
      save: saveData => this.fileManagerSandbox.modifyFolder(data?.id, saveData)
    }).subscribe((value) => {
      const newData = {...data, name: value.name, rights: value?.rights || []};

      if(data.type === 'folder'){
        const content = this.folders$.getValue();
        const folders = content.folders;
        const originalFolderIndex = folders.findIndex(folder => folder.id === data.id);

        if(originalFolderIndex > -1){
          folders[originalFolderIndex] = newData;
          this.folders$.next({...content, folders: folders});
        }
      }
    });
  }

  private editFile(data){
    this.dialog.openDialog(FileEditDialogComponent, {
      file: data,
      readonlyUser: this.readonlyUser,
      save: saveData => this.fileManagerSandbox.modifyFile(data?.id, saveData),
      download: () => this.downloadFile(data)
    }, {
      disableDefaultClass: true
    }).subscribe((value)  => {
      const newData = {...data, name: value.name};
      const content = this.folders$.getValue();
      const files = content.files;
      const originalFileIndex = files.findIndex(file => file.id === data.id);
      if(originalFileIndex > -1){
        files[originalFileIndex] = newData;
        this.folders$.next({...content, files: files});
      }
    });
  }

  private downloadFile(data){
    this.fileManagerSandbox.downloadFile(data?.id, data?.fileType, data?.name).subscribe({
      next: () => this.notification.notify('MESSAGE.DOWNLOAD_SUCCESS')
    });
  }

  public setSearch(search: string){
    this.search$.next(search);
  }

  private deleteItem(item: FileManagerTableItems = null){
    let title: string;
    let id: string;
    if(!!item){
      title = item?.fileType === FileTypeEnum.FOLDER ?
        'FILE_MANAGER.DELETE_FOLDER_QUESTION_DESCRIPTION' :
        'FILE_MANAGER.DELETE_FILE_QUESTION_DESCRIPTION';

      id = item?.id;
    }else{
      title = 'FILE_MANAGER.DELETE_MORE_QUESTION_DESCRIPTION';
      id = super.selection.map(item => item.id).join(',');
    }

    this.dialog.openQuestionDialog('LBL_BTN.DELETE', title, {name: item?.name || ''}).pipe(
      filter(res => !!res),
      switchMap(() => this.fileManagerSandbox.deleteFolder(id)),
      takeUntil(this.destroy$)
    ).subscribe({
      next: () => {
        if(!!item){
          this.deleteElement(item.id, item.fileType === FileTypeEnum.FOLDER ? 'folder' : 'file');
        }else{
          super.selection.forEach(item => this.deleteElement(item.id, item.fileType === FileTypeEnum.FOLDER ? 'folder' : 'file'));
        }
        super.selection = [];
        this.tableContainer.clearSelection();
      }
    });
  }

  public selectionButtonClicked(event) {
    switch(event){
      case 'deleteSelected':
        this.deleteItem();
        break;
      case 'downloadSelected':
        const selectedItems = super.selection.map((item: any) => item.id);
        const downloadSpecs = selectedItems.length === 1 && super.selection[0].fileType !== FileTypeEnum.FOLDER ?
          {fileType: super.selection[0].fileType, name: super.selection[0].name} :
          {fileType: FileTypeEnum.FOLDER, name: `SLM_FILE_MANAGER_${new Date().getTime()}.zip`}
        this.downloadFile({id: selectedItems.join(','), fileType: downloadSpecs.fileType, name: downloadSpecs.name});
        break;
    }
  }

  private deleteElement(id: string, type: ItemType){
    const content = this.folders$.getValue();

    if(type === 'folder') {
      const folders = content.folders;
      content.folders.forEach((item, index) => {
        if (item.id === id) {
          folders.splice(index, 1);
          this.folders$.next({...content, folders: folders});
        }
      });
    }else if(type === 'file'){
      const files = content.files;
      content.files.forEach((item, index) => {
        if (item.id === id) {
          files.splice(index, 1);
          this.folders$.next({...content, files: files});
        }
      });
    }
  }

  breadcrumbClicked() {
    super.selection = [];
    this.tableContainer.clearSelection();
  }

  openItem(event: FileManagerTableItems){
    if(![FileTypeEnum.BACK_FOLDER, FileTypeEnum.FOLDER].includes(event.fileType)){
      this.editFile(event);
    }
  }
}
