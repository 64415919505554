<div *ngIf="amounts" class="pt-1 pb-1 w-100 block">
  <div *ngIf="amounts.length; else messageBox" class="w-100 flex flex-column pr-3">
    <div *ngIf="sumAmount" class="flex flex-end">
      <h5 class="pt-1 pb-1 pr-2 mb-0 color-black txt-medium">{{sumAmountLabel | translate}}: </h5>
      <h5
        class="mb-0 pt-1 pb-1 color-secondary txt-medium txt-end">{{sumAmount.amount | currencyTransform: sumAmount.currency}}</h5>
    </div>
    <div *ngIf="amounts.length; else messageBox" class="flex flex-end">
      <h5 class="pl-3 plr-3 pt-1 pb-1 mb-0 color-black txt-medium">{{'INVOICE_GENERALS.CURRENCY'| translate}}:</h5>
      <div class="flex flex-column flex-end txt-end-i pl-3">
        <h5 *ngFor="let amount of amounts"
            class="mb-0 pt-1 pb-1 color-secondary txt-medium txt-end">{{amount.amount | currencyTransform: amount.currency}}</h5>
      </div>
    </div>
  </div>
</div>

<ng-template #messageBox>
  <slm-message *ngIf="!amounts.length" class="w-auto m-auto" type="info" [message]="message"></slm-message>
</ng-template>
