import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Icon} from '../../../global';

@Component({
  selector: 'slm-stepper',
  template: `
    <ng-container *ngIf="!approvedStyle; else approved">
      <div
        class="stepper"
        *ngIf="steps"
        [class.stepper--no-bg]="disableAppearance"
      >
        <div
          *ngFor="
            let step of steps;
            let index = index;
            let last = last;
            let first = first
          "
          class="stepper__step"
          [class.stepper__step--selected]="index === currentStep"
        >
          <div class="stepper__step__counter">{{ index + 1 }}</div>
          <h4
            class="stepper__step__name"
            [class.stepper__step__name--no-wrap]="noWrap"
          >
            {{ step | translate }}
          </h4>
          <slm-svg-icon
            class="stepper__step__arrow"
            *ngIf="steps.length > 1 && !last"
            icon="arrow_right"
            size="2.25rem"
          ></slm-svg-icon>
        </div>
      </div>
    </ng-container>
    <ng-template #approved>
      <div *ngIf="steps" class="flex flex-center flex-wrap">
        <div
          *ngFor="
            let step of steps;
            let index = index;
            let last = last;
            let first = first
          "
          class="flex flex-column w-min-140 w-max-200"
          [class.stpr-approved__step--checked]="index <= currentStep"
          [class.stpr-approved__step--selected]="index === currentStep"
        >
          <div class="flex">
            <div class="w-100 block border h-1px  mt-4"
                 [class.opacity-transparent]="first"
                 [class.border-color-success]="currentStep >= index"
                 [class.border-color-light-gray]="currentStep < index"
            ></div>
            <div class="" *ngIf="currentStep <= index; else checkIcon">
              <div *ngIf="currentStep === index"
                   class="border border-2 border-color-success p-1 border-dashes radius-infinite">
                <div
                  class="bg-color-success color-white radius-infinite size-2 flex flex-items-center flex-center">{{index + 1}}</div>
              </div>

              <div *ngIf="currentStep < index"
                   class="bg-color-grey-light color-white radius-infinite size-25 flex flex-items-center flex-center">{{index + 1}}</div>

            </div>
            <div class="w-100 block border h-1px mt-4"
                 [class.border-color-success]="currentStep > index"
                 [class.border-color-light-gray]="currentStep <= index"
                 [class.opacity-transparent]="last"></div>
          </div>
          <h4
            class="pl-2 pr-2 pt-2"
            [class.txt-no-wrap]="noWrap"
            [class.color-black]="currentStep === index"
            [class.color-secondary]="currentStep > index"
            [class.color-secondary-disabled]="currentStep < index"
          >
            {{ step | translate }}
          </h4>
        </div>
      </div>
    </ng-template>

    <ng-template #checkIcon>
      <div class="p-2 radius-infinite bg-color-success">
        <slm-svg-icon [icon]="icons.DONE" size="16px" class="fill-white"></slm-svg-icon>
      </div>
    </ng-template>
  `,
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepperComponent {
  public icons = Icon;
  @Input() steps: Array<string> = [];
  @Input() currentStep = -1;
  @Input() disableAppearance = false;
  @Input() noWrap = false;
  @Input() approvedStyle = false;
}
