<slm-page-container>
  <slm-titled-card class="w-max-container m-auto block mt-6">
    <svg-icon
      class="w-max-100p w-500px h-max-300 mt-5 m-auto block"
      src="/assets/pictures/illustrations/upgrade.svg"
      applyClass="true"
    ></svg-icon>
    <div class="w-max-100p w-600px m-auto block mt-4 mb-4">
      <h4 class="txt-start-i pb-1 pt-3 txt-bold">{{ 'UPGRADABLE.GREET' | translate: {name: companyName$ | async} }}</h4>
      <h5 class="txt-start-i">{{ 'UPGRADABLE.DESCRIPTION' | translate }}</h5>
      <h5 class="txt-start-i">{{ 'UPGRADABLE.ENDING' | translate }}</h5>
      <h5 class="txt-start-i pb-4 txt-bold">
        ICT Európa System Zrt.
      </h5>
    </div>
  </slm-titled-card>
</slm-page-container>
