import { Pipe, PipeTransform } from '@angular/core';
import { replaceUrlParameter } from '../../global';

@Pipe({
  name: 'routeParam'
})
export class RouteParamPipe implements PipeTransform {
  transform(route: string = '', params: any): any {
    return replaceUrlParameter(route, params);
  }
}
