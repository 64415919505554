import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Currency, defaultCurrency, FinanceReportsVatBalance, RightsEnum, RoutesEnum } from '../../../global';

@Component({
  selector: 'slm-vat-balance',
  template: `
    <div
      class="card card--no-padding card--no-shadow"
      [class.card--no-elevation]="disableElevation"
    >
      <div *ngIf="!hideHeader" class="card__header p-3">
        <h4 class="card__header__title" translate>VAT_BALANCES</h4>
      </div>
      <div
        class="card__content p-3 pb-5 grid grid-col-3-33-1 grid-responsive grid-responsive-no-gap"
        *ngIf="vatBalance as balances; else loading"
      >
        <div>
          <h5 class="w-100 txt-start-i" translate>VAT_BALANCE</h5>
          <h2 class="color-accent w-100 block txt-start-i">
            {{ balances.vatBalanceAmount | currencyTransform: currency }}
          </h2>
          <a class="flex"
             *ngIf="!hideExport"
             (click)="fireEvent.emit('vat_export')"
             data-test-id="vatListExport"
          >{{'EXPORT_VAT_LIST' | translate}}
            <slm-loader class="ml-2 block" *ngIf="showLoader" diameter="15"></slm-loader>
          </a
          >
        </div>
        <div>
          <h5 class="w-100 txt-start-i" translate>ACCOUNTED_BALANCE</h5>
          <h2 class="w-100 block txt-start-i">
            {{
            balances.invoicedVatAmount | currencyTransform: currency
            }}
          </h2>
          <a
            *slmAccess="rights.DOCUMENT_LISTING_READ"
            [routerLink]="'/' + routes.INVOICES_OUTGOINGS_TABLE"
            [queryParams]="routeParams"
            target="_blank"
            data-test-id="connectedOutgoingInvoces"
          >{{'CONNECTED_OUTGOING_INVOICES' | translate}}</a
          >
        </div>

        <div>
          <h5 class="w-100 txt-start-i" translate>DEDUCTIBLE_BALANCE</h5>
          <h2 class="w-100 block txt-start-i">
            {{
            balances.deductibleVatAmount | currencyTransform: currency
            }}
          </h2>
          <a
            *slmAccess="rights.DOCUMENT_LISTING_READ"
            [routerLink]="'/' + routes.INVOICES_INCOMES_TABLE"
            [queryParams]="routeParams"
            target="_blank"
            data-test-id="connectedIncomingInvoices"
          >{{'CONNECTED_INCOMING_INVOICES' | translate}}</a
          >
        </div>
      </div>
    </div>
    <ng-template #loading>
      <slm-loader class="block m-auto mt-5 mb-5"></slm-loader>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VatBalanceComponent {
  public readonly routes = RoutesEnum;
  public readonly rights = RightsEnum;
  @Input() public vatBalance: FinanceReportsVatBalance = null;
  @Input() public routeParams: any = null;
  @Input() public currency: Currency = defaultCurrency;
  @Input() public hideExport = false;
  @Input() public hideHeader = false;
  @Input() public disableElevation = false;
  @Input() public showLoader = false;
  @Output() public fireEvent = new EventEmitter<any>();
}
