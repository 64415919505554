import { Directive, ElementRef, OnDestroy, OnInit, HostListener, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { filter, skip, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[uppercaseFormat]'
})

export class UppercaseFormatDirective implements OnInit, OnDestroy{
    private element: HTMLInputElement;
    private changed$ = new BehaviorSubject<string>(null);
    private destroy$ = new Subject();

    @Input() enableUppercase = false;

    constructor(
        private elementRef: ElementRef
    ) {}

    ngOnInit() {
        this.element = this.elementRef.nativeElement;
        this.changed$
            .pipe(
                skip(1),
                filter(() => this.enableUppercase),
                takeUntil(this.destroy$)
                )
            .subscribe((value) => {
                const transformedValue = value.toUpperCase();
                if(transformedValue !== value) {
                    this.element.value = transformedValue;
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    @HostListener('keyup', ['$event.target.value']) onKeyUp(value) {
        this.changed$.next(value);
    }
}
