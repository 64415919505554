import { MenuItem, Icon } from '../../../modules/global';

export const headerUserMenu: Array<MenuItem> = [
  {
    name: 'LBL_HEADER.PERSONAL_DATA',
    action: 'profile_setting',
    icon: Icon.PROFILE
  },
  // {
  //   name: 'LBL_HEADER.NOTIFICATION_SETTINGS',
  //   action: 'notification_settings',
  //   icon: Icon.NOTIFICATION_SETTINGS
  // },
  {
    name: 'LBL_HEADER.PASSWORD_UPDATE',
    action: 'password_settings',
    icon: Icon.PASSWORD_SETTINGS
  }
];
