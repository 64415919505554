<slm-page-container
  [title]="'BUG_REPORT.TITLE_LONG'"
  (paste)="onPaste($event)"
>

  <article class="w-max-container grid m-auto grid-responsive">
    <slm-titled-card [title]="'BUG_REPORT.TITLE'" class="flex">
      <p class="txt-start-i txt-size-regular txt-medium pt-5 block" translate="BUG_REPORT.HELP_ADVICE_START"></p>
      <div class="grid grid-col-2-50 m-auto grid-responsive pt-2 pb-2">
        <div class="mr-3">
          <h4 class="txt-start-i txt-size-regular txt-medium pt-1 border-bottom border-color-accent-i"
              translate="BUG_REPORT.HELP_ADVICE_INFO_TITLE"></h4>
          <p class="txt-start-i txt-size-regular text-justify"
             [innerHTML]="'BUG_REPORT.HELP_ADVICE_INFO_DESCRIPTION' | translate: links"></p>
        </div>
        <div>
          <h4 class="txt-start-i txt-size-regular txt-medium pt-1 border-bottom border-color-accent-i"
              translate="BUG_REPORT.HELP_ADVICE_VIDEO_TITLE"></h4>
          <p class="txt-start-i txt-size-regular text-justify"
             [innerHTML]="'BUG_REPORT.HELP_ADVICE_VIDEO_DESCRIPTION' | translate: links"></p>
        </div>
      </div>
      <p class="txt-start-i txt-size-regular pt-1 pb-2" translate="BUG_REPORT.HELP_ADVICE_END_TEXT"></p>
    </slm-titled-card>
  </article>

  <article class="w-max-container grid grid-col-2-50 m-auto grid-responsive pt-5">
    <slm-titled-card [title]="'INVOICE.SET_DATA'">
      <slm-text-input
        label="BUG_REPORT.SUBJECT"
        [errors]="errors"
        testId="reportSubject"
        class="mt-3"
        [control]="form.get('subject')"></slm-text-input>

      <slm-text-area
        label="BUG_REPORT.DESCRIPTION"
        [errors]="errors"
        minRow="7"
        testId="reportDescription"
        [control]="form.get('description')"
      ></slm-text-area>

      <slm-simple-drag-and-drop
        label="BUG_REPORT.FILES"
        accept="image/jpeg,image/png,image/jpg"
        class="mb-2 block pb-2"
        (files)="addFile($event)"
      ></slm-simple-drag-and-drop>

      <div *ngIf="files.length" class="mb-2 block">

        <label class="mb-3 block color-secondary" translate="BUG_REPORT.SELECTED_FILES"></label>

        <div *ngFor="let file of files; let index = index; trackBy: trackByIndex"
             class="pl-2 pr-2 pt-1 pb-1 mt-1 mb-1 flex-items-center flex flex-space-btwn bg-color-accent-transparent-lighter">

          <h5 class="mb-0 txt-start-i txt-medium txt-ellipsis">{{file.name}}</h5>
          <slm-button-icon
            class="size-2 fill-accent"
            [icon]="icons.DELETE"
            (click)="removeFile(index)"></slm-button-icon>

        </div>
      </div>
    </slm-titled-card>

    <slm-titled-card [title]="'BUG_REPORT.HELP_TITLE'" class="flex">
      <p class="txt-start-i txt-size-regular pt-5 block" translate="BUG_REPORT.HELP_TEXT"></p>
      <ul>
        <li class="txt-start-i pb-2 pt-1 txt-size-regular" translate="BUG_REPORT.HELP_LIST_1"></li>
        <li class="txt-start-i pb-2 pt-1 txt-size-regular" translate="BUG_REPORT.HELP_LIST_2"></li>
        <li class="txt-start-i pb-2 pt-1 txt-size-regular" translate="BUG_REPORT.HELP_LIST_3"></li>
        <li class="txt-start-i pb-2 pt-1 txt-size-regular" translate="BUG_REPORT.HELP_LIST_4"></li>
        <li class="txt-start-i pb-2 pt-1 txt-size-regular" translate="BUG_REPORT.HELP_LIST_5"></li>
        <li class="txt-start-i pb-2 pt-1 txt-size-regular" translate="BUG_REPORT.HELP_LIST_6"></li>
      </ul>
      <p class="txt-start-i txt-size-regular txt-medium" translate="BUG_REPORT.HELP_TEXT_ENDING"></p>
    </slm-titled-card>
  </article>
  <slm-bottom-bar [buttons]="buttons" class="w-max-container m-auto block mt-2"
                  (fireEvent)="buttonClicked($event)"></slm-bottom-bar>
</slm-page-container>
