<div
  class="radius-infinite size-34px overflow-hidden cursor-pointer border border-2-i border-color-accent box-shadow-hover"
  [matMenuTriggerFor]="languageSelectorMenu"
  [matTooltip]="'LBL_PROFILE.LANGUAGE' | translate"
>
  <svg-icon src="/assets/pictures/flags/flag_{{language}}.svg"
            matRipple
            applyClass="true"></svg-icon>
</div>

<mat-menu #languageSelectorMenu="matMenu">
  <div *ngFor="let language of languages"
       class="flex pt-1 pb-1 pl-2 pr-2 cursor-pointer"
       matRipple
       (click)="selectLanguage(language.value)">
    <div
      class="radius-infinite size-30px overflow-hidden"
    >
    <svg-icon src="/assets/pictures/flags/flag_{{language.value}}.svg"
              matRipple
              applyClass="true"></svg-icon>
    </div>
    <h4 [translate]="language.label" class="pl-2 pt-1 block mb-0"></h4>
  </div>
</mat-menu>
