import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {ColumnTableItem, Currency} from '../../../global';

@Component({
  selector: 'slm-simple-table',
  templateUrl: './simple-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleTableComponent {
  @Input() public config: Array<ColumnTableItem> = [];
  @Input() public items: Array<any> = [];
  @Input() public currency: Currency = null;
  @Output() public clicked = new EventEmitter<any>();
  @Input() public extraAttributeName: string;

  buttonClicked(item: any, action: any) {
    this.clicked.emit({...item, action});
  }
}
