import {Currency, PaginatorNew} from '../entities';

export const mobileWidth = 835;
export const pageContainerMaxWidth = 1200;

export const defaultCurrency: Currency = {
  prefix: 'HUF',
  id: 0,
  precision: 2
};

export const defaultPage: PaginatorNew = {
  page: 1,
  size: 10,
  fullLength: 0
};

export const consentExpireWarningDays = 432_000_000; // 5 days ahead warning
