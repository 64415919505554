<slm-dialog-container
  [title]="'LBL_PROFILE.TITLE'"
  [buttons]="buttons"
  (fireEvent)="buttonEvent($event.event)"
>
  <div class="profile">
    <div *ngIf="!!profileForm" class="profile__right">
      <form [formGroup]="profileForm">
        <slm-text-input
          [control]="profileForm.get('userName')"
          label="LBL_PROFILE.NAME"
          [errors]="errors"
        ></slm-text-input>

        <div class="profile__right__email">
          <mat-label class="profile__right__email__label">{{
            "LBL_INPUT.LABEL.EMAIL" | translate
          }}</mat-label>
          <div>{{ data.user.email }}</div>
        </div>

        <slm-text-input
          [control]="profileForm.get('phone')"
          label="LBL_PROFILE.PHONE"
          [errors]="errors  "
        ></slm-text-input>

        <slm-select-input
          [control]="profileForm.get('partnerId')"
          label="LBL_PROFILE.DEFAULT"
          [errors]="errors"
          [options]="companySelect$ | async"
        ></slm-select-input>
      </form>
    </div>
  </div>
</slm-dialog-container>
