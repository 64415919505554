import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TableDate, TableAlign } from '../../../../../../global';

@Component({
  selector: 'slm-table-date',
  template: `
    <h5
      *ngIf="data; else invalid"
      [matTooltip]="tooltip | translate"
      [class]="'w-100 m-0 block txt-' + (align || 'center') + '-i'"
    >
      {{ data.date | dateTransform: format || 'short'
      }}{{
        data.until
          ? ' - ' + (data.until | dateTransform: format || 'short')
          : ''
      }}
    </h5>
    <ng-template #invalid
      ><span class="w-100 block txt-center-i">--</span></ng-template
    >
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableDateComponent {
  @Input() data: TableDate = null;
  @Input() format: string = null;
  @Input() tooltip: string = null;
  @Input() align: TableAlign = TableAlign.CENTER;
}
