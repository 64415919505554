import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { PagerTitle } from '../../../../global';

@Component({
  selector: 'slm-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagerComponent {
  @Input() public titles: Array<PagerTitle>;
  @Input() public pages: Array<any> = [];
  @Input() public maxHeight = 21.5;
  @Input() public disableMaxHeight = false;
  @Input() public disableElevation = false;
  @Input() public align: 'start' | 'center' | 'end' = 'center';
  @Input('selected')
  set setSelected(selectedPage: string){
    if(selectedPage){
      this.selectedIndex = this.titles.findIndex(value => value.search === selectedPage);
    }
  }

  @Output() public readonly pageChanged = new EventEmitter<number>();

  public selectedIndex: number = null;
}
