import {Component, OnInit} from '@angular/core';
import {
  GeneralSandbox,
  AuthSandbox,
  NavigationService,
  utils,
  RoutesEnum, GroupedSelectOptions
} from '../../../modules/global';
import {BehaviorSubject, Observable} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {debounceTime, map, tap} from 'rxjs/operators';
import {CurrencyTransformPipe} from '../../../modules/shared';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {GeneralPackage} from '../../../modules/global/entities/package.entity';

@Component({
  selector: 'slm-registration-container',
  templateUrl: './registration.container.html',
  providers: [CurrencyTransformPipe]
})
export class RegistrationContainer implements OnInit {
  public readonly isLoading$ = new BehaviorSubject<boolean>(false);
  public readonly recaptchaSiteKey: string = environment.recaptchaSiteKey;

  public readonly routes = RoutesEnum;

  public registrationForm: UntypedFormGroup;

  public readonly recaptchaControl = new UntypedFormControl('', [Validators.required]);
  public subscriptionOptions$: Observable<Array<GroupedSelectOptions>>;
  public showSuccessScreen = false;
  public email = '';
  public defaultPackage: string;
  public useDefaultPackage: GeneralPackage;

  constructor(
    private readonly navigation: NavigationService,
    private readonly currencyPipe: CurrencyTransformPipe,
    private readonly generalSandbox: GeneralSandbox,
    private readonly authSandbox: AuthSandbox,
    private readonly translate: TranslateService,
    private readonly route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    /**
     * Loading and building subscription package list
     * Example [{label: 'BASIC - 49,99 EUR + VAT'}, ...]
     */
    this.defaultPackage = (this.route.snapshot.queryParams['package'] || '').toUpperCase();
    this.subscriptionOptions$ = this.generalSandbox.getSubscriptions().pipe(
      debounceTime(1000),
      tap(packages => {
        this.useDefaultPackage = (packages?.generalPackages || []).find((item) => item.name === this.defaultPackage);
        this.setDefaultPackage();
      }),
      map(value => {
        const filterObject = utils.groupByKey((value?.generalPackages ?? []), 'groupName');
        return Object.keys((filterObject || [])).map((key) => {
          return ({
            groupLabel: key,
            options: (filterObject[key] || []).map((item) => {
              return ({
                label: `${item.name.toUpperCase()} - ${this.currencyPipe.transform(
                  item.baseFee,
                  item?.currency || 'HUF'
                )} + ${this.translate.instant('VAT')}`,
                value: item,
                id: item.name.toUpperCase()
              });
            })
          });
        });
      })
    );
  }

  public setDefaultPackage(){
    if(!!this.useDefaultPackage  && this.registrationForm){
      const generalPackageControl = this.registrationForm.get('generalPackage');
      generalPackageControl.setValue(this.useDefaultPackage);
    }
  }

  public setForm(form: UntypedFormGroup): void {
    this.registrationForm = form;
    const generalPackageControl = this.registrationForm.get('generalPackage');
    const userCountControl = this.registrationForm.get('userCount');
    generalPackageControl.reset();

    this.setDefaultPackage();
    userCountControl.setValue(0);
  }

  public submitForm(): void {
    if (!this.registrationForm || this.isLoading$.getValue()) {
      return;
    }
    if (this.registrationForm.invalid) {
      this.registrationForm.markAllAsTouched();
      return;
    }
    if (this.recaptchaControl.invalid) {
      this.recaptchaControl.markAllAsTouched();
      return;
    }
    const registrationData = utils.omit(this.registrationForm.getRawValue(), [
      'confirmPassword', this.registrationForm.value['whereToSee'] !== 'other'? 'otherDescription' : ''
    ]);

    if(registrationData.companies.length > registrationData.generalPackage.maxPartnerInGroup){
      this.registrationForm.markAllAsTouched();
      return;
    }

    this.isLoading$.next(true);
    const token = this.recaptchaControl.value;
    const registrationObject = {
      token,
      ...registrationData,
      generalPackage: registrationData.generalPackage.id
    };
    this.registerUser(registrationObject);
  }

  private registerUser(registrationData: any) {
    this.authSandbox.registration(registrationData)
      .subscribe({
        next: () => {
          this.email = registrationData.email;
          this.isLoading$.next(false);
          this.showSuccessScreen = true;
        },
        error: () => {
          this.isLoading$.next(false);
        },
        complete: () => {
          this.email = registrationData.email;
          this.isLoading$.next(false);
          this.showSuccessScreen = true;
        }
      });
  }

  public goToLogin(): void {
    this.navigation.navigate([RoutesEnum.LOGIN]);
  }
}
