import {
  Directive,
  Input,
  OnDestroy,
  OnInit, Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {combineLatest, of, Subject} from 'rxjs';
import {RightsConfig, RightsEnum, UserSandbox} from '../../global';
import {environment} from '../../../environments/environment';

/**
 * Base object of the access directives
 */
@Directive()
export abstract class HasAccessBase implements OnInit, OnDestroy {
  destroy$ = new Subject();
  hasAccessReverse = false;
  hasAccessExcept: string | Array<string>;

  /**
   *
   * @param rendererObj - Angular powerful renderer
   * @param viewContainerRefObj - the DOM container
   * @param templateRefObj - template inside the dom
   * @param userSandboxObj - User Sandbox
   * @protected
   */
  protected constructor(
    public readonly rendererObj: Renderer2,
    public readonly viewContainerRefObj: ViewContainerRef,
    public readonly templateRefObj: TemplateRef<any>,
    public readonly userSandboxObj: UserSandbox
  ) {
  }

  public abstract ngOnInit();

  /**
   * Conversion function: string -> array or array -> array.
   *
   * @param rights - rights
   * @returns Array of rights or null
   */
  protected getAccess = (rights: Array<string> | string) => rights ? typeof rights === 'string'
    ? [rights] : rights : null;

  /**
   *
   * @param hasAccess - need to show the container
   * @param rights - right list for the title
   * @param prefix - array of title prefixes when the border is enabled
   * @param exceptAccess - need to show the except text
   */
  public verifyVisibility(hasAccess, rights: Array<string>, exceptAccess: boolean, prefix: string = ''): void {
    //first reset otherwise it may leads to duplicated html tags
    this.viewContainerRefObj.clear();
    const arrayIsNotEmpty = !!this.hasAccessExcept?.length;
    const hasRights = !!rights?.length || arrayIsNotEmpty;
    if (!this.hasAccessReverse) {
      this.changeViewVisibility(hasAccess && exceptAccess,
        !hasRights ? [] : [rights , ...(exceptAccess && arrayIsNotEmpty? [this.getAccess(this.hasAccessExcept)] : [])],
        [prefix, ...exceptAccess && arrayIsNotEmpty? ['Except rights'] : []]
      );
    } else {
      this.changeViewVisibility(!hasAccess && !exceptAccess,
        !hasRights ? [] : [
          rights,
          ...(exceptAccess && arrayIsNotEmpty? [this.getAccess(this.hasAccessExcept)] : [])],
        [prefix, ...exceptAccess && arrayIsNotEmpty ? ['Except rights'] : []]);
    }
  }

  public hasExceptAccess() {
    const rights = this.getAccess(this.hasAccessExcept);
    if (!rights?.length) {
      return of(true);
    }
    return this.userSandboxObj.hasAccessExcept(rights);
  }

  /**
   * This method will change the visibility (show/hide) of the DOM element
   *
   * @param show - visibility flag
   * @param rights - array of rights
   * @param titlePrefixes - array of prefixes text before the rights list
   * @private
   */
  private changeViewVisibility(show: boolean, rights: Array<Array<string>>, titlePrefixes: Array<string>) {
    if (show) {
      const view = this.viewContainerRefObj.createEmbeddedView(this.templateRefObj);
      if (environment.showRights && view && rights && rights.length > 0) {
        view.rootNodes.forEach(element => this.addStyleToElement(element, titlePrefixes, rights));
      }
    } else {
      this.viewContainerRefObj.clear();
    }
  }

  /**
   * This method will change the style of the element: red border, title
   *
   * @param element - DOM Element
   * @param titlePrefixes - array of prefix texts before the rights list
   * @param rights - array of Array of rights
   * @private
   */
  private addStyleToElement(element, titlePrefixes: Array<string>, rights: Array<Array<string>>) {
    if (element && element.tagName) {
      element.title = titlePrefixes.map((titlePrefix, index) =>
        (titlePrefix ? `${titlePrefix}: ${(rights[index] ?? []).join(', ')}` : (rights[index] ?? []).join(', ')) + ' \n');
      if (!element.style.display) {
        this.rendererObj.addClass(element, 'block');
      }
      if (!element.style.position) {
        this.rendererObj.addClass(element, 'pos-relative');
      }
      this.rendererObj.addClass(element, 'border-solid-i');
      this.rendererObj.addClass(element, 'border-3');
      this.rendererObj.addClass(element, 'border-color-error-i');
    }
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}

@Directive({
  selector: '[slmAccess]'
})
export class AccessDirective extends HasAccessBase implements OnInit {
  private rights: Array<RightsEnum | string> | string;
  private orRights: Array<RightsEnum | string> | string;

  @Input('slmAccess')
  public set setSlmAccess(config: RightsConfig | Array<RightsEnum | string> | string) {
    if (!!config && typeof config === 'string') {
      this.rights = [config];
      this.hasAccessReverse = null;
      this.hasAccessExcept = null;
      this.orRights = null;
    } else if (!!config && Array.isArray(config)) {
      this.rights = config as Array<string | RightsEnum>;
      this.hasAccessReverse = null;
      this.hasAccessExcept = null;
      this.orRights = null;
    } else {
      const rightConfig = config as RightsConfig;
      this.rights = rightConfig?.rights;
      this.hasAccessReverse = rightConfig?.reverseRights;
      this.hasAccessExcept = rightConfig?.exceptRights;
      this.orRights = rightConfig?.orRights;
    }
  }

  constructor(
    private readonly renderer: Renderer2,
    private readonly userSandbox: UserSandbox,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>
  ) {
    super(renderer, viewContainerRef, templateRef, userSandbox);
  }

  ngOnInit() {
    const isOrRights = !this.rights?.length && !!this.orRights?.length;
    const access = this.getAccess(isOrRights ? this.orRights : this.rights);
    if (!!access && !!access.length) {
      combineLatest([(isOrRights ? this.userSandbox.hasAccessOr : this.userSandbox.hasAccess)(access), this.hasExceptAccess()])
        .subscribe(
          ([hasAccess, exceptAccess]) =>
            this.verifyVisibility(hasAccess, access, exceptAccess, isOrRights ? 'Min 1 rights required' : '')
        );
    } else {
      this.verifyVisibility(true, access, true, isOrRights ? 'Min 1 rights required' : '');
    }
  }
}
