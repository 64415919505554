import { Icons, InputTypeEnum } from '../enums';
import { SelectOption } from './input.entity';

export interface InputConfig {
  inputType?: InputTypeEnum | 'select';
  name: string;
  label: string;
  placeholder?: string;
  options?: Array<SelectOption>;
  prefixIcon?: Icons;
  suffixIcon?: Icons;
  clickableSuffix?: boolean;
  info?: string;
  hint?: string;
  min?: Date;
  max?: Date;
  minValue?: number;
  maxValue?: number;
  suffixLabel?: string;
  testId?: string;
}
