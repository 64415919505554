import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {TableCounterText} from '../../../../../../global';

@Component({
  selector: 'slm-table-counter-text',
  template: `
    <div *ngIf="data && data.counter; else invalid" class="w-fit-content flex flex-items-center flex-start">
      <h5
        class="radius-infinite size-2-i m-0 color-white flex flex-center flex-items-center"
        [style.backgroundColor]="data.color"
        [matTooltip]="tooltip || null"
      >
        {{ data.counter }}
      </h5>
      <h5 *ngIf="data.label" class="w-auto-i mb-0 m-1 txt-no-wrap pl-2 txt-ellipsis block ml-2 txt-start-i">
        {{ data.label | translate: data.data }}
      </h5>
    </div>
    <ng-template #invalid
    ><span class="w-100 block txt-center-i">--</span></ng-template
    >
  `,
  styles: [
    `
           :host {
             display: block;
           }
         `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCounterTextComponent {
  @Input() data: TableCounterText = null;
  @Input() tooltip = '';
}
