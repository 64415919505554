<article class="tc"
         [class.tc--disable-appearance]="disableAppearance"
         [class.tc-w-container]="restrictedWidth">
  <div
    class="tc__header"
    [class.tc__header--selection]="showSelectionButtons && selectionButtons"
  >
    <h4
      *ngIf="title"
      class="tc__header__title"
      [class.tc__header__title--no-border]="!filterEnabled && !searchEnabled"
    >
      {{ title | translate }}
    </h4>
    <div
      *ngIf="
        (customButton || searchEnabled || filterEnabled) &&
        !showSelectionButtons
      "
      class="tc__header__actions"
    >
      <slm-button
        *ngIf="filterEnabled && filterButtonSize === 'normal'"
        (click)="filterClicked.emit()"
        [icon]="filterIcon"
        class="tc__header__actions__filter--desktop"
        buttonStyle="transparent-blue"
        testId="table_filter_btn"
        [text]="filterButtonText"
      ></slm-button>
      <slm-button-icon
        *ngIf="filterEnabled && filterButtonSize === 'normal'"
        testId="table_filter_btn"
        (click)="filterClicked.emit()"
        [icon]="filterIcon"
        [elevation]="true"
        class="tc__header__actions__filter--mobile size-25 fill-accent"
        [matTooltip]="filterButtonText | translate"
      ></slm-button-icon>
      <slm-button-icon
        *ngIf="filterEnabled && filterButtonSize === 'small'"
        testId="table_filter_btn"
        (click)="filterClicked.emit()"
        [icon]="filterIcon"
        [elevation]="true"
        class="tc__header__actions__filter size-25 fill-accent"
        [matTooltip]="filterButtonText | translate"
      ></slm-button-icon>
      <ng-container *ngIf="customButton">
        <div *slmAccess="customButton.rights">
          <slm-button
            (click)="customClicked.emit(customButton.action || null)"
            [config]="customButton"
            [loading]="customButton.isLoading"
            class="tc__header__actions__filter--desktop"
            noWrap="true"
          ></slm-button>
          <slm-button-icon
            (click)="customClicked.emit(customButton.action || null)"
            [icon]="customButton.icon"
            [loading]="customButton.isLoading"
            [elevation]="true"
            class="tc__header__actions__filter--mobile size-25 fill-accent"
            [matTooltip]="customButton.label | translate"
          ></slm-button-icon>
        </div>
      </ng-container>
      <slm-text-input
        *ngIf="searchEnabled"
        [label]="searchLabel"
        prefixIcon="search"
        enterEnabled="true"
        class="tc__header__actions__search"
        [control]="searchControl"
        (fireEvent)="startSearch($event)"
        testId="searchTable"
        type="search"
        hideOptionalLabel="true"
      ></slm-text-input>
    </div>
    <div
      cdk-scrollable
      *ngIf="showSelectionButtons && selectionButtons"
      class="tc__header__selection overflow-y-auto"
    >
      <div class="tc__header__actions--list">
        <ng-container *ngFor="let button of selectionButtons">
          <slm-button
            *slmAccess="button.rights"
            class="mr-1 w-min-120 block w-max-230"
            buttonStyle="secondary"
            [noWrap]="true"
            [testId]="button.testId"
            height="small"
            [attr.data-test-id]="button.testId"
            [text]="button.label"
            (click)="selectionButton.emit(button.action)"
          ></slm-button>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
  <div class="table-container__footer" *ngIf="!hidePagination">
    <slm-paginator
      [page]="page"
      [pageSizeOptions]="pageSizes"
      (pageChange)="pageChanged($event)"
    ></slm-paginator>
  </div>
</article>
