import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {GlobalInvoiceSandbox} from '../../global';

@Pipe({name: 'picture'})
export class PicturePipe implements PipeTransform {
  public static PICTURE_USER = 'default';
  public static PICTURE_UNSAFE_USER = 'base';

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(src: any, type: string = 'base'): SafeHtml | Observable<SafeHtml> {
    let validSrc = false;
    if (src && src !== 'null' && src !== 'undefined') {
      validSrc = true;
    }
    if (validSrc) {
      if (type === PicturePipe.PICTURE_UNSAFE_USER) {
        return src;
      }
      return this.sanitizer.bypassSecurityTrustUrl(`${src}`);
    } else {
      return './assets/images/profile-img.png';
    }
  }
}

@Pipe({name: 'pictureAsync'})
export class PictureAsyncPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer, public readonly invoiceSandbox: GlobalInvoiceSandbox) {
  }

  transform(src: any): Observable<SafeHtml> {
    if (!/^-?\d+$/.test(`${src}`)) {
      return of(src);
    }
    return this.invoiceSandbox.downloadImageData(src, 'blob').pipe(
    //   .get(`/images/${+src}`, {responseType: 'blob'}).pipe(
      switchMap((blob) =>
        // return new observable which emits a base64 string when blob is converted to base64
        new Observable<SafeHtml>((observer) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob); // convert blob to base64
          reader.onloadend = () => {
            observer.next(
              this.sanitizer.bypassSecurityTrustUrl(`${reader.result}`)
            ); // emit the base64 string result
            observer.complete();
          };
        })
      )
    );
  }
}
