import {StatusClassEnum} from '../enums';
import {PaginatorNew} from "./paginator.entity";

export interface CompanyItem {
  id: string;
  name: string;
  status?: StatusClassEnum;
  reqList: Array<{ text: string; checked: boolean }>;
}

export interface CompanyActivation {
  items: Array<{ text: string; checked: boolean; description: string }>;
}

export interface Company {
  partnerCode?: string;
  partnerId: number;
  partnerName: string;
  pgId?: string;
  taxAdministrationNumber?: string;
  billing?: CompanyAddress;
  externalSystem?: string;
  address?: CompanyAddress;
  defaultGroup?: boolean;
  partnerSettings?: CompanySubscriptionSettings;
  useDivision?: boolean;
  remainingDemoDays?: number;
}

export interface CompanyAddress {
  address: string;
  city: string;
  countryId: number;
  countryCode: string;
  number: string;
}

export interface CompanySubscriptionSettings {
  storage: {
    enabled:  boolean;
    packageId: number;
    usage?: number;
  },
  partnerId?: number;
  useDivision?: boolean;
}

export interface Division {
  id: number;
  name: string;
  users?: Array<{id: number; name: string}>
}

export interface DivisionResponse {
  divisions: Array<Division>;
  paginator: PaginatorNew;
}
