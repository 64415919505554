<mat-sidenav-container class="home">
  <!--  SIDE NAVIGATION -->
  <mat-sidenav
    #vcSidenav
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [fixedInViewport]="!mobileQuery.matches"
    [disableClose]="!mobileQuery.matches"
    class="home__menu"
  >
    <slm-side-nav
      [status]="menuStates$ | async"
      [menuItems]="menuItems$ | async"
      [companies]="availableCompanies$ | async"
      [companyId]="activeCompanyId$ | async"
      (openedRoute)="menuClicked()"
      (changeCompany)="changeCompany($event)"
      (toggle)="showSideMenu()"
    ></slm-side-nav>
  </mat-sidenav>

  <!--  CONTENT PART -->
  <mat-sidenav-content
    class="home__content"
    [style.margin-left.px]="(menuStates$ | async) === 'full' ? 300 : 80"
  >
    <!--  HEADER -->
    <header
      class="home__content__header"
      [class.home__content__header--shadow]="headerShadow$ | async"
    >
      <slm-header
        [user]="user$ | async"
        (fireEvent)="headerAction($event)"
        [hasMoreNotification]="hasMoreNotification"
        [notificationCount]="notificationCount$ | async"
        [notificationLoading]="notificationLoading"
        [notifications]="notifications$ | async"
        [remainingDemoDays]="remainingDemoDays$ | async"
      ></slm-header>
    </header>

    <!--  PAGE CONTENT CONTAINER -->
    <div
      cdk-scrollable
      class="home__content__container overflow-y-auto"
      (scroll)="containerScroll($event)"
    >
      <div class="home__content__container__content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
