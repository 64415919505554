<slm-page-container>
  <slm-titled-card class="w-max-container m-auto block mt-6">
    <svg-icon
      class="w-max-100p w-500px h-max-300 mt-5 m-auto block"
      src="/assets/pictures/illustrations/pending.svg"
      applyClass="true"
    ></svg-icon>
    <div class="w-max-100p w-600px m-auto block mt-4 mb-4">
      <h4 class="txt-start-i pb-1 pt-3 txt-bold"
          translate [translateParams]="{name: companyName$ | async}">PROCESSING.GREET</h4>
      <h5 class="txt-start-i" translate>
        PROCESSING.THANK
      </h5>
      <h5 class="txt-start-i" translate>
        PROCESSING.QUESTION<a href="mailto:info@slm.hu">info&#64;slm.hu</a>.
      </h5>
      <h5 class="txt-start-i" translate>
        PROCESSING.ENDING
      </h5>
      <h5 class="txt-start-i pb-4 txt-bold">
        ICT Európa System Zrt.
      </h5>
    </div>
  </slm-titled-card>
  <slm-titled-card class="w-max-container m-auto block mt-2 mb-2">
    <slm-stepper
      class="pt-4 block m-auto"
      [currentStep]="currentStep"
      [steps]="statuses$ | async"
      disableAppearance="true"
      approvedStyle="true"
    ></slm-stepper>
  </slm-titled-card>
  <slm-titled-card *ngIf="description" class="w-max-container m-auto block mt-2 mb-2">
    <h4 class="pt-3">{{description}}</h4>
  </slm-titled-card>
</slm-page-container>
