import {Component, Input, OnInit} from '@angular/core';
import {IconColors, Currency} from '../../../global';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {DateTransformPipe, CurrencyTransformPipe} from '../../pipes';

@Component({
  selector: 'slm-cash-flow-invoices-overview',
  template: `
    <div *ngIf="data as items; else loading">
      <ng-container *ngIf="!!items?.length; else noData">
        <slm-chart
          *ngIf="chartData$ | async as chart"
          [chartMaxHeight]="chartMaxHeight"
          [height]="maxHeight"
          [data]="chart.data"
          [colors]="chartColor"
          [options]="chart.options"
          type="bar"
          [labels]="chart.labels"
          [legend]="false"
        ></slm-chart>
        <div class="w-100 block">
          <div class="w-100 border-collapse border-top border-color-light-gray">
            <div class="border-bottom border-color-light-gray flex">
              <div class="txt-medium  pt-1 pb-1 flex flex-space-btwn w-max-90 w-100">
                {{ labels[0] | translate }}
                <span
                  class="w-10px h-10px block ml-1 mt-1"
                  [style.backgroundColor]="iconColors.BLUE"
                ></span>
              </div>
              <div
                *ngFor="let item of items"
                class="txt-end w-max-calc-100_7 w-100 m-auto ml-0 mr-0"
              >
                <div class="block w-max-140 m-auto">
                  {{ item.invoices | currencyTransform: currency }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-template #noData>
      <h4 class="mt-6 mb-6" translate="LBL_MESSAGES.NO_DATA_FOUND"></h4>
    </ng-template>
    <ng-template #loading>
      <slm-loader></slm-loader>
    </ng-template>
  `,
  providers: [DateTransformPipe, CurrencyTransformPipe]
})
export class CashFlowInvoicesOverviewComponent implements OnInit {
  public readonly chartColor: Array<any> = [
    {backgroundColor: IconColors.DARK_BLUE},
    {backgroundColor: IconColors.LIGHT_BLUE}
  ];

  public readonly chartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            weight: 'bold',
            size: 14
          }
        }
      },
      y: {
        ticks: {
          padding: 25,
          callback: (value, _, __) => {
            if (Math.abs(value) > 1_000_000) {
              return `${(value / 1_000_000).toFixed(1)} M`;
            } else if (Math.abs(value) > 1_000) {
              return `${(value / 1_000).toFixed(0)} K`;
            } else {
              return value;
            }
          }
        }
      }
    }
  };

  public readonly iconColors = IconColors;
  public chartData$: Observable<any>;
  public data: any = null;
  public currency: Currency = null;
  private refresh$ = new BehaviorSubject<boolean>(false);
  @Input() labels: Array<string> = [];
  @Input() maxHeight: number = null;
  @Input() chartMaxHeight: number = null;

  @Input('data')
  public set setData(data: any) {
    this.data = data;
    if (data) {
      this.refresh$.next(true);
    }
  }

  @Input('currency')
  public set setCurrency(currency: any) {
    this.currency = currency;
    if (this.currency) {
      this.refresh$.next(true);
    }
  }

  constructor(
    private readonly datePipe: DateTransformPipe,
    private readonly currencyPipe: CurrencyTransformPipe
  ) {
  }

  ngOnInit() {
    this.chartData$ = this.refresh$.pipe(
      filter((value) => !!value && !!this.data && !!this.currency),
      map(() => ({
        labels: this.data.map((item) =>
          this.datePipe.transform(new Date(item.date), 'FORMAT.DATE')
        ),
        options: this.getChartOptions(this.currency),
        data: [
          {
            data: this.data.map((item) => item.invoices),
            barPercentage: 0.8,
            backgroundColor: IconColors.LIGHT_BLUE,
            hoverBackgroundColor: IconColors.LIGHT_BLUE_DARKER
          }
        ]
      }))
    );
  }

  private getChartOptions = (currency: Currency) => ({
    ...this.chartOptions,
    plugins: {
      tooltip: {
        displayColors: false,
        titleAlign: 'center',
        bodyAlign: 'center',
        callbacks: {
          title: (tooltipItem) =>
            this.currencyPipe.transform(tooltipItem[0].raw, currency),
          label: (tooltipItem) => this.labels[Math.abs(tooltipItem.datasetIndex - 1)]
        }
      }
    }
  });
}
