import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter, OnInit, OnDestroy, ChangeDetectorRef
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {debounceTime, filter, takeUntil} from 'rxjs/operators';

export interface ValueStepperConfig {
  valueParser: (any) => string;
  goNext: (any) => any;
  goPrev: (any) => any;
  nextEnabled: (any) => boolean;
  prevEnabled: (any) => boolean;
}

@Component({
  selector: 'slm-value-stepper',
  template: `
    <div class="date-stepper date-stepper--active" *ngIf="config && control">
      <button
        class="date-stepper__button fill-white"
        [class.bg-color-grey-light]="!prevEnabled"
        [class.bg-color-accent]="prevEnabled"
        [disabled]="!prevEnabled"
        matRipple
        data-test-id="date-stepper-prev-btn"
        (click)="goToPrev()"
        [matRippleDisabled]="!prevEnabled"
      >
        <slm-svg-icon icon="arrow_left"></slm-svg-icon>
      </button>
      <div class="date-stepper__content">
        <h4 class="txt-no-wrap w-min-160">{{value}}</h4>
      </div>
      <button
        class="date-stepper__button fill-white"
        data-test-id="date-stepper-next-btn"
        [disabled]="!nextEnabled"
        matRipple
        [class.bg-color-grey-light]="!nextEnabled"
        [class.bg-color-accent]="nextEnabled"
        (click)="goToNext()"
        [matRippleDisabled]="!nextEnabled"
      >
        <slm-svg-icon icon="arrow_right"></slm-svg-icon>
      </button>
    </div>
  `,
  styleUrls: ['./value-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValueStepperComponent implements OnInit, OnDestroy {
  public prevEnabled = true;
  public nextEnabled = true;
  public value = '';
  private skipNextChange = false;
  private readonly destroy$ = new Subject();

  @Input() control = new UntypedFormControl();
  @Input() config: ValueStepperConfig;

  @Output() public readonly fireEvent = new EventEmitter<string>();

  constructor(private readonly cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.control.valueChanges.pipe(
      debounceTime(150),
      filter(() => {
        if (this.skipNextChange) {
          this.skipNextChange = false;
          return false;
        }
        return true;
      }),
      takeUntil(this.destroy$)
    ).subscribe((value) => this.setValue(value));
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public goToNext(): void {
    if (this.nextEnabled && this.config) {
      this.fireEvent.emit('nextValue');
      const nextValue = this.config.goNext(this.control.value);
      this.setValue(nextValue);
    }
  }

  public goToPrev(): void {
    if (this.prevEnabled && this.config) {
      this.fireEvent.emit('previousValue');
      const prevValue = this.config.goPrev(this.control.value);
      this.setValue(prevValue);
    }
  }

  private setValue(value) {

    if (!this.config) {
      return;
    }
    this.value = this.config.valueParser(value);
    this.skipNextChange = true;
    this.control.setValue(value);
    this.nextEnabled = this.config.nextEnabled(value);
    this.prevEnabled = this.config.prevEnabled(value);
    this.cd.detectChanges();
  }
}
