import {ColumnTableItem, Icon, TableAlign} from '../../../global';

export const attachedInvoicesTableConfig: Array<ColumnTableItem> = [
  {
    value: 'LBL_PARTNERS.PARTNER_NAME',
    key: 'partnerName',
    type: 'text'
  },
  {
    value: 'INVOICE_GENERALS.INVOICE_NUMBER',
    key: 'invoiceNumber',
    type: 'text'
  },
  {
    value: '',
    type: 'round-button',
    key: 'openInvoice',
    testId: 'openInvoice',
    align: TableAlign.CENTER,
    buttonIcon: Icon.OPEN_IN_NEW,
    buttonLabel: 'LBL_BTN.OPEN',
    smallIconColumn: true
  }
];
