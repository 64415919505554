import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { SystemNotification } from '../../../modules/global';

@Component({
  selector: 'slm-alerts',
  templateUrl: './alerts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertsComponent {
  @Input() notifications: Array<SystemNotification> = [];
  @Input() loadings: Array<number> = [];
  @Output() accepted = new EventEmitter<Array<number>>();

  accept(id: number) {
    this.accepted.emit([id]);
  }

  acceptAll() {
    this.accepted.emit(this.notifications.map((not) => +not.id));
  }

  public trackById = (_, item: SystemNotification) => item?.id;
}
