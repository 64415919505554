import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ErrorHandlingService} from './error-handling.service';
import {Observable} from 'rxjs';
import {
  Country,
  General,
  ListResponseNew,
  SystemNotification,
  PaginatorNew,
  ProformaInvoice,
  Role,
  Bank
} from '../entities';
import {catchError, map} from 'rxjs/operators';
import {CurrencyEnum, InvoiceType} from '../enums';
import {PackageListResponse} from '../entities/package.entity';
import {convertToFormData, utils} from '../functions';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandler: ErrorHandlingService
  ) {
  }

  getCountries(
    page: number = 1,
    size: number = 10,
    name: string = ''
  ): Observable<Array<Country>> {
    return this.http
      .get('/settings/countries/list', {
        params: {
          pageNumber: `${page}`,
          itemPerPage: `${size}`,
          ...(name ? {filterName: name} : {})
        }
      })
      .pipe(
        map(
          (list: Array<any>): Array<Country> =>
            (list || []).map((country) => ({
              code: country.countryCode,
              id: country.countryId,
              name: country.countryName,
              currency: country.defaultCurrency,
              language: country.defaultLanguage
            }))
        ),
        catchError((err) => this.errorHandler.handleError(err))
      );
  }

  getInvoiceItemUnits(): Observable<Array<{ label: string; value: string }>> {
    return this.http
      .get('/quantity-suffix-list')
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  getExchangeRate(
    currency: CurrencyEnum,
    date: Date,
    base: boolean = false
  ): Observable<{ exchangeRate: number }> {
    return this.http
      .get('/currencies/exchange', {
        params: {currency, date: utils.dateToServerDate(date), base}
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  getRoles(): Observable<ListResponseNew<Role>> {
    return this.http
      .get('/user/schemes')
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  getProformas(
    date: Date,
    partnerId: string,
    type: InvoiceType
  ): Observable<ListResponseNew<ProformaInvoice>> {
    return this.http
      .get<Array<any>>('/documents/prepay-list', {
        params: {
          date: utils.dateToServerDate(date),
          companyId: `${partnerId}`,
          type
        }
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  getSubscriptions(all?: boolean): Observable<PackageListResponse> {
    return this.http
      .get<Array<any>>('/packages', {
        params: {
          ...(!!all ? {fromAllAvailableGroups: all} : {})
        }
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  getSystemInformation(): Observable<General> {
    return this.http
      .get('/general')
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  getNotifications(
    page: PaginatorNew,
    read: boolean
  ): Observable<ListResponseNew<SystemNotification>> {
    return this.http
      .get('/notifications/system', {
        params: {
          ...utils.paginatorToQueryParams(page),
          ...(read ? {read: 'true'} : {})
        }
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  acceptNotifications(ids: Array<number>): Observable<any> {
    return this.http
      .post('/notifications/system', {ids})
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  getGeneralInformation = (language: string, page: string): Observable<string> =>
    this.http.get('/general/info', {responseType: 'text', params: {language, page}})
    .pipe(catchError((err) => this.errorHandler.handleError(err)));

  sendError = (message: string): Observable<string> =>
    this.http.post('/general/error-report',{message})
      .pipe(catchError((err) => this.errorHandler.handleError(err)));

  sendReport(subject: string, description: string, files: Array<File>): Observable<any> {
    const fileList = files.reduce((prev, file, index) => ({
      ...prev,
      [`file${index}`]: file
    }), {});
    return this.http.post('/bug-report', convertToFormData({subject, description, ...fileList}))
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }
  getBankAccounts(custom): Observable<ListResponseNew<Bank>> {
    return this.http
      .get('/bankaccounts/banks',{params: {custom}})
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }
}
