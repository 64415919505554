import {Icon, IconColors} from '../enums';
import {SelectOption} from '../entities';

export const accountantStatus: Array<SelectOption> = [
  {
    color: IconColors.ORANGE,
    icon: Icon.TIME,
    label: 'LBL_TABLE_ATTR.WAIT-ACCOUNTING',
    value: 'wait-accounting'
  },
  {
    color: IconColors.BLUE,
    icon: Icon.VISIBILITY,
    label: 'LBL_TABLE_ATTR.WAIT-CHECKING',
    value: 'wait-checking'
  },
  // {
  //   color: IconColors.RED,
  //   icon: Icon.REFRESH,
  //   label: `LBL_TABLE_ATTR.BE-REPAIRED`,
  //   value: 'be-repaired'
  // },
  {
    color: IconColors.GREEN,
    icon: Icon.DESCRIPTION,
    label: 'LBL_TABLE_ATTR.ACCOUNTED',
    value: 'accounted'
  }
];

export const fillingStatus: Array<SelectOption> = [
  {
    color: IconColors.BLUE,
    icon: Icon.VISIBILITY,
    label: 'LBL_TABLE_ATTR.WAIT-CHECKING',
    value: 'wait-checking'
  },
  {
    color: IconColors.RED,
    icon: Icon.REFRESH,
    label: 'LBL_TABLE_ATTR.BE-REPAIRED',
    value: 'be-repaired'
  },
  {
    color: IconColors.GREEN,
    icon: Icon.DESCRIPTION,
    label: 'LBL_TABLE_ATTR.FILED',
    value: 'accounted'
  }
];

export const paymentStatuses = (isIncoming: boolean = false) => [
  {
    color: IconColors.BLUE,
    icon: Icon.JUDGE,
    label: 'LBL_TABLE_ATTR.JUDGE',
    value: 'judge'
  },
  {
    color: IconColors.ORANGE,
    icon: Icon.TIME,
    label: 'LBL_TABLE_ATTR.WAITINGFORPAYMENT',
    value: 'waitingForPayment'
  },
  {
    color: IconColors.ORANGE,
    icon: Icon.TIME,
    label: 'LBL_TABLE_ATTR.UNDER-PAYOUT',
    value: 'under-payout'
  },
  {
    color: IconColors.BLUE,
    icon: Icon.VISIBILITY,
    label: 'LBL_TABLE_ATTR.WAIT-APPROVE',
    value: 'wait-approve'
  },
  {
    color: IconColors.ORANGE,
    icon: Icon.BANKING,
    label: isIncoming ? 'LBL_TABLE_ATTR.PAYABLE' : 'LBL_TABLE_ATTR.WAIT_FOR_PAYMENT',
    value: 'payable'
  },
  {
    color: IconColors.ORANGE,
    icon: Icon.SYNC_ALT,
    label: 'LBL_TABLE_ATTR.SENT-TO-BANK',
    value: 'sent-to-bank'
  },
  {
    color: IconColors.GREEN,
    icon: Icon.CHECK_CIRCLE,
    label: 'LBL_TABLE_ATTR.PAID',
    value: 'paid'
  },
  {
    color: IconColors.GREY,
    icon: Icon.CANCEL_CIRCLE,
    label: 'LBL_TABLE_ATTR.CANCELED',
    value: 'declined'
  },
  {
    color: IconColors.ORANGE,
    icon: Icon.TIME,
    label: 'LBL_TABLE_ATTR.DURINGCOLLECTION',
    value: 'duringCollection'
  },
  {
    color: IconColors.RED,
    icon: Icon.CANCEL_CIRCLE,
    label: 'LBL_TABLE_ATTR.NOTPAID',
    value: 'notPaid'
  },
  {
    color: IconColors.ORANGE,
    icon: Icon.ERROR,
    label: 'LBL_TABLE_ATTR.OVERPAID',
    value: 'overpaid'
  }
];

export const transactionApprovalStatuses: Array<SelectOption> = [
  {
    color: IconColors.BLUE,
    icon: Icon.VISIBILITY,
    label: 'LBL_TABLE_ATTR.JUDGE',
    value: 'waitApprove'
  },
  {
    color: IconColors.ORANGE,
    icon: Icon.BANKING,
    label: 'LBL_TABLE_ATTR.PAYABLE',
    value: 'payable'
  },
  {
    color: IconColors.GREEN,
    icon: Icon.CHECK_CIRCLE,
    label: 'LBL_TABLE_ATTR.PAID',
    value: 'paid'
  }
];
export const transactionApprovalStatusesAll: Array<SelectOption> = [
  ...transactionApprovalStatuses,
  {
    color: IconColors.GREY,
    icon: Icon.CANCEL_CIRCLE,
    label: 'LBL_TABLE_ATTR.CANCELED',
    value: 'canceled'
  }
];

export const transactionSentToBankStatus: SelectOption = {
  color: IconColors.ORANGE,
  icon: Icon.SYNC_ALT,
  label: 'LBL_TABLE_ATTR.SENT-TO-BANK',
  value: 'sent-to-bank'
};

export const transactionsUnderTransferStatuses: Array<SelectOption> = [
  {
    color: IconColors.GREY,
    icon: Icon.CANCEL_CIRCLE,
    label: 'LBL_TABLE_ATTR.CANCELED',
    value: 'canceled'
  },
  {
    color: IconColors.GREEN,
    icon: Icon.CHECK_CIRCLE,
    label: 'BANKING.SUCCESS_TRANSFER',
    value: 'paid'
  },
  {
    color: IconColors.ORANGE,
    icon: Icon.SYNC_ALT,
    label: 'LBL_TABLE_ATTR.SENT-TO-BANK',
    value: 'sent-to-bank'
  }
];

export const underTransferStatusFilter: Array<SelectOption> = [
  {
    color: IconColors.ORANGE,
    icon: Icon.SYNC_ALT,
    label: 'BANKING.UNDER_TRANSACTIONS_STATUS.SENT',
    value: 'sent'
  },
  {
    color: IconColors.GREEN,
    icon: Icon.DONE,
    label: 'BANKING.UNDER_TRANSACTIONS_STATUS.CONFIRMED',
    value: 'confirmed'
  }
];
