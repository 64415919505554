import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {NotificationEnum} from '../../../global';

@Component({
  selector: 'slm-message',
  template: `
    <div
      [class]="'message-container flex-items-center message-container--' + (type || 'success')"
    >
      <slm-svg-icon
        [icon]="type || 'success'"
        class="message-container__icon"
      ></slm-svg-icon>
      <div>
        <h5 *ngIf="!isInnerHtml; else innerHtml" class="message-container__message">
          {{ (title || message) | translate }}
        </h5>
        <ng-container *ngIf="title && message">
          <div *ngIf="innerHtml" class="message-container__text" [innerHTML]="message | translate"></div>
          <div *ngIf="!innerHtml" class="message-container__text">{{ message | translate }}</div>
        </ng-container>
      </div>
    </div>
    <ng-template #innerHtml>
      <h5 class="message-container__message" [innerHTML]="(title || message) | translate"></h5>
    </ng-template>
  `,
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageComponent {
  @Input() public type:
    | NotificationEnum
    | 'error'
    | 'info'
    | 'warning'
    | 'success' = NotificationEnum.SUCCESS;
  @Input() public message = '';
  @Input() public title = '';
  @Input() public isInnerHtml = false;
}
