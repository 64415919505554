import {
  Icon,
  MenuItem,
  RightsEnum,
  RoutesEnum, SubMenuItem
} from '../../../modules/global';

export const expandableOutgoingMenuItem: MenuItem = {
  icon: Icon.OUTGOING,
  name: 'LBL_MENU.OUTGOING',
  rights: [RightsEnum.DOCUMENT_LISTING_READ],
  submenu: [
    {
      icon: Icon.SECURITY,
      name: 'LBL_MENU.OUTGOING_NAV',
      route: `/${RoutesEnum.INVOICES_OUTGOINGS_NAV_TABLE}`
    },
    {
      icon: Icon.OUTGOING,
      name: 'LBL_MENU.OUTGOING',
      route: `/${RoutesEnum.INVOICES_OUTGOINGS_TABLE}`
    }
  ]
};

export const normalOutgoingMenuItem: MenuItem = {
  icon: Icon.OUTGOING,
  name: 'LBL_MENU.OUTGOING',
  rights: [RightsEnum.DOCUMENT_LISTING_READ],
  route: `/${RoutesEnum.INVOICES_OUTGOINGS_TABLE}`
};

export const normalIncomingMenuItem: MenuItem = {
  icon: Icon.INCOME,
  name: 'LBL_MENU.INCOME',
  rights: [RightsEnum.DOCUMENT_LISTING_READ],
  route: `/${RoutesEnum.INVOICES_INCOMES_TABLE}`
};

export const sideNavItems = (useBank: boolean): Array<MenuItem> => [
  {
    icon: Icon.HOME,
    name: 'LBL_MENU.HOME',
    route: RoutesEnum.HOME
  },
  {
    icon: Icon.UPLOAD,
    name: 'LBL_MENU.UPLOAD_FILLING',
    route: `/${RoutesEnum.INVOICES_UPLOADS}`,
    rights: [RightsEnum.DOCUMENT_UPLOADING_READ]
  },
  {
    ...normalOutgoingMenuItem
  },
  {
    icon: Icon.INCOME,
    name: 'LBL_MENU.INCOME',
    route: null,
    rights: [RightsEnum.DOCUMENT_LISTING_READ],
    submenu: [
      {
        name: 'LBL_MENU.INCOME_NAV',
        route: `/${RoutesEnum.INVOICES_INCOMES_NAV_TABLE}`,
        icon: Icon.SECURITY
      },
      {
        name: 'LBL_MENU.INCOME',
        route: `/${RoutesEnum.INVOICES_INCOMES_TABLE}`,
        icon: Icon.INCOME
      }
    ]
  },
  {
    icon: Icon.BANKING,
    name: 'LBL_MENU.BANKING',
    rights: {orRights: [RightsEnum.BANKING_OPERATIONS_READ, RightsEnum.PSD2_AISP_READ]},
    submenu: [
      {
        name: 'BANK_ACCOUNTS.NAME',
        route: `/${RoutesEnum.BANKING_ACCOUNTS}`,
        rights: [RightsEnum.PSD2_AISP_READ],
        icon: Icon.ACCOUNT,
        showUpgradeIcon: !useBank
      },
      {
        name: 'LBL_MENU.BANK.TRANSFERS',
        route: `/${RoutesEnum.BANKING}/${RoutesEnum.TRANSACTIONS}`,
        rights: [RightsEnum.BANKING_OPERATIONS_READ],
        icon: Icon.SYNC_ALT
      },
      {
        name: 'LBL_MENU.BANK.AGGREGATION',
        route: `/${RoutesEnum.BANKING}/${RoutesEnum.AGGREGATION}`,
        icon: Icon.CHECKLIST,
        showUpgradeIcon: !useBank
      },
      {
        name: 'LBL_MENU.BANK.RULES',
        route: `/${RoutesEnum.BANKING}/${RoutesEnum.AGGREGATION_RULES}`,
        icon: Icon.FACT_CHECK,
        showUpgradeIcon: !useBank
      }
    ]
  },
  {
    icon: Icon.FINANCES,
    name: 'LBL_MENU.FINANCES',
    route: null,
    rights: [RightsEnum.DOCUMENT_FINANCIAL_SETTINGS_READ],
    submenu: [
      {
        name: 'LBL_MENU.FINANCE.REPORTS',
        route: `/${RoutesEnum.FINANCES_REPORTS}`,
        icon: Icon.ANALYTICS
      },
      {
        name: 'LBL_MENU.FINANCE.INCOMES_OUTGOING',
        route: `/${RoutesEnum.FINANCES_CASH_FLOW}`,
        icon: Icon.MONETIZATION_ON
      },
      {
        name: 'LBL_MENU.FINANCE.OUTSTANDING',
        route: `/${RoutesEnum.FINANCES_OUTSTANDING}`,
        icon: Icon.ASSIGMENT_LATE
      }
      // {
      //   name: 'LBL_MENU.FINANCE.EMAILS',
      //   route: `/${RoutesEnum.FINANCES_EMAILS}`,
      //   icon: Icon.EMAIL
      // },
      // {
      //   name: 'LBL_MENU.FINANCE.MONEY_COLLECTION',
      //   route: `/${RoutesEnum.FINANCES_MONEY_COLLECTION}`,
      //   icon: Icon.PAYMENTS
      // },
      // {
      //   name: 'LBL_MENU.FINANCE.DATA',
      //   route: `/${RoutesEnum.FINANCES_DATA}`,
      //   icon: Icon.STORAGE
      // },
    ]
  },
  {
    icon: Icon.SETTINGS,
    name: 'LBL_MENU.SETTINGS',
    route: null,
    rights: {
      orRights: [
        RightsEnum.VAT_CODES_READ,
        RightsEnum.ACCOUNTING_PERIODS_READ,
        RightsEnum.CHART_OF_ACCOUNTS_READ,
        RightsEnum.TITLES_READ,
        RightsEnum.COST_CENTERS_READ,
        RightsEnum.PROGRAM_SETTINGS_READ,
        RightsEnum.AA_RULES_READ,
        RightsEnum.OWN_COMPANY_READ,
        RightsEnum.CUSTOMERS_READ,
        RightsEnum.BANK_ACCOUNTS_READ,
        RightsEnum.OTHER_SYSTEM_SETTINGS_READ
      ]
    },
    submenu: [
      {
        name: 'LBL_MENU.SETTING.COMPANY',
        route: `/${RoutesEnum.SETTINGS_COMPANY}`,
        icon: Icon.ARTICLE,
        rights: [RightsEnum.OWN_COMPANY_READ]
      },
      {
        name: 'LBL_MENU.SETTING.USERS',
        route: `/${RoutesEnum.SETTINGS_USERS}`,
        icon: Icon.PEOPLE,
        rights: [RightsEnum.OWN_COMPANY_READ]
      },
      {
        name: 'LBL_MENU.SETTING.PARTNERS',
        route: `/${RoutesEnum.SETTINGS_PARTNERS}`,
        icon: Icon.PARTNERS,
        rights: [RightsEnum.CUSTOMERS_READ]
      },
      // {
      //   name: 'LBL_MENU.SETTING.INVOICE',
      //   route: `/${RoutesEnum.SETTINGS_INVOICE}`,
      //   icon: Icon.RECEIPT
      // },
      {
        name: 'LBL_MENU.SETTING.PARTNER_BANKS',
        route: `/${RoutesEnum.SETTINGS_PARTNERS_BANKS}`,
        icon: Icon.BANKING,
        rights: [RightsEnum.BANK_ACCOUNTS_READ]
      },
      {
        name: 'LBL_MENU.SETTING.MY_BANK_ACCOUNTS',
        route: `/${RoutesEnum.SETTINGS_BANK_ACCOUNTS_ACTIVE}`,
        rights: [RightsEnum.BANK_ACCOUNTS_READ],
        icon: Icon.ACCOUNT
      },
      // {
      //   name: 'LBL_MENU.SETTING.FILLING_SETTINGS',
      //   route: `/${RoutesEnum.SETTINGS_FILLING}`,
      //   icon: Icon.FACT_CHECK
      // },
      {
        name: 'LBL_MENU.SETTING.ACCOUNT_SETTING',
        route: `/${RoutesEnum.SETTINGS_ACCOUNTING}`,
        icon: Icon.ADMIN_PANEL_SETTINGS,
        rights: {
          orRights: [
            RightsEnum.VAT_CODES_READ,
            RightsEnum.ACCOUNTING_PERIODS_READ,
            RightsEnum.CHART_OF_ACCOUNTS_READ,
            RightsEnum.TITLES_READ,
            RightsEnum.COST_CENTERS_READ,
            RightsEnum.PROGRAM_SETTINGS_READ,
            RightsEnum.AA_RULES_READ,
            RightsEnum.OTHER_SYSTEM_SETTINGS_READ
          ]
        }
      },
      {
        name: 'LBL_MENU.SETTING.LIST_SETTING',
        route: `/${RoutesEnum.SETTINGS_LIST_VIEW_DETAILS}`,
        icon: Icon.ADMIN_PANEL_SETTINGS,
        rights: []
      }
    ]
  },
  {
    icon: Icon.CLOUD,
    name: 'LBL_MENU.FILE_MANAGER',
    route: `/${RoutesEnum.FILE_MANAGER}`,
    rights: []
  }
];

export const useOnTransactionSubmenuItem: SubMenuItem = {
    name: 'LBL_MENU.BANK.UNDER_TRANSFER',
    route: `/${RoutesEnum.BANKING}/${RoutesEnum.UNDER_TRANSFER}`,
    icon: Icon.ARROW_RIGHT
};

