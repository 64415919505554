import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormControl} from '@angular/forms';
import {SelectionButton} from '../../../global';
import {Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'slm-selector',
  template: `
    <div
      [class]="'selector selector--height-' + height"
      *ngIf="values"
      [style.maxWidth.px]="maxWidth"
      [style.minWidth.px]="minWidth"
    >
      <button
        *ngFor="let value of values; let index = index"
        class="selector__value"
        [attr.data-test-id]="value.testId"
        [class.selector__value--selected]="
          index === selectedIndex || value.action === control?.value
        "
        (click)="itemClicked(value, index)"
      >
        {{ value.label | translate }}
      </button>
      <div
        class="selector__background"
        [style.marginLeft.%]="selectedIndex * size"
        [style.width.%]="size"
      ></div>
    </div>
  `,
  styleUrls: ['./selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectorComponent implements OnInit, OnDestroy {
  public selectedIndex = 0;
  public size = 0;
  public values: Array<SelectionButton> = [];
  @Input() public control: AbstractControl | UntypedFormControl;
  @Input() public maxWidth: number = null;
  @Input() public minWidth: number = null;

  @Input() public height: 'normal' | 'small' = 'normal';

  @Input('values')
  public set setValues(values: Array<SelectionButton>) {
    this.values = values;
    this.size = !!this.values?.length
      ? +(100 / (this.values?.length || 1)).toFixed(2)
      : 0;
    this.cd.detectChanges();
  }

  @Output() public selectorClicked = new EventEmitter<any>();

  private readonly destroy$ = new Subject();

  constructor(private readonly cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.control) {
      this.verifyValue(this.control?.value);
      this.control.valueChanges
        .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
        .subscribe((cValue) => this.verifyValue(cValue));
    }
  }

  private verifyValue(cValue: string): void {
    const index = (this.values || []).findIndex(
      (value) => value.action === cValue
    );
    if (index > -1) {
      this.selectedIndex = index;
    } else {
      this.control.setValue(cValue);
    }
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public itemClicked(item: SelectionButton, index: number) {
    this.selectorClicked.emit(item.action);
    this.selectedIndex = index;
    if (this.control) {
      this.control.setValue(item.action);
    }
  }
}
