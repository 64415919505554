import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {FireEvent, Icon, TableAlign, TableEditableText, utils} from '../../../../../../global';
import {debounceTime, filter, map, tap} from 'rxjs/operators';
import {concat, of} from 'rxjs';

@Component({
  selector: 'slm-table-editable',
  templateUrl: './table-editable.component.html'
})
export class TableEditableComponent {
  public readonly icons = Icon;
  public readonly control = new UntypedFormControl('');
  private skipNext = false;
  public readonly subLabel$ =  concat(of(this.control.value), this.control.valueChanges).pipe(
    debounceTime(250),
    map(() => this.control.value),
    filter((value) => {
      if (this.skipNext) {
        this.skipNext = false;
        return false;
      }
      return  this.data.data !== value;
    }),
    tap(value => this.fireEvent.emit({event: 'change', data: value})),
    filter(() => !!this.data?.subLabel),
    map(value => utils.isString(this.data.subLabel) ? this.data.subLabel : (this.data as any).subLabel(value))
  );

  public data: TableEditableText = null;
  public inputIsVisible = false;

  @Input('data')
  public set setData(data: TableEditableText) {
    this.data = data;
    this.skipNext = true;
    this.control.setValue(this.data?.data ?? '');
    if (this.data?.validator) {
      this.control.clearValidators();
      this.control.setValidators(this.data.validator);
    }
  }

  @Input() align: TableAlign = TableAlign.CENTER;
  @Input() tooltip: string = null;
  @Input() textColor: string = null;
  @Input('disable')
  public set setDisabled(disable: boolean) {
    this.data.disableEditable = disable;
  }
  @Output()
  public fireEvent = new EventEmitter<FireEvent>();

  public changeInputVisibility() {
    this.inputIsVisible = !this.inputIsVisible;
    this.fireEvent.emit({event: 'inputVisible', data: this.inputIsVisible});
  }

  public inputEvent(event) {
    if (event === 'enter' && this.inputIsVisible) {
      this.changeInputVisibility();
    }
  }
}
