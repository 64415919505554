<div style="overflow: auto">
  <table *ngIf="!!items; else loading" class="simple-table">
    <tr class="simple-table__header">
      <th *ngFor="let attribute of config">
        {{ attribute.value | translate }}
      </th>
    </tr>
    <ng-container *ngFor="let item of items">
      <tr class="simple-table__row"
          [class.simple-table__no_bottom_border]="extraAttributeName && item[extraAttributeName]">
        <td *ngFor="let attribute of config"
            [class]="
                  'vertical-table__data txt-' +
                  (attribute.align || 'start') +
                  '-i'
                "
            [class.vertical-table__data--no-wrap]="attribute.noWrapValue"
            [class.vertical-table__data--bold]="attribute.boldValue"
            [class.w-2]="attribute.smallIconColumn"
            [ngSwitch]="attribute.type"
        >
          <div *slmAccess="attribute.rights || []">
            <ng-container *ngSwitchCase="'date'">{{
              (item[attribute.key] | dateTransform: attribute.dateFormat) ||
              attribute.replacement
              }}</ng-container>
            <ng-container *ngSwitchCase="'translate'">{{
              (item[attribute.key] | translate) || attribute.replacement
              }}</ng-container>
            <ng-container *ngSwitchCase="'currency'">{{
              (item[attribute.key]
                | currencyTransform
                : currency ||
                item[attribute.currencyKey] ||
                attribute.defaultCurrency) || attribute.replacement
              }}</ng-container>
            <div *ngSwitchCase="'text-link'"
                [class]="'flex flex-column flex-' +(attribute.align || 'center') +
                  ' flex-items-'+(attribute.align || 'center')"
            >
              <div *ngIf="item[attribute.key].label" [translate]="item[attribute.key].label"></div>
              <a *ngIf="item[attribute.key].link"
                 target="_blank"
                 [href]="item[attribute.key].link" [translate]="item[attribute.key].linkText"></a>
            </div>

            <div *ngSwitchCase="'icon'"
                 class="flex flex-items-center">
              <ng-container *ngIf="item[attribute.key]">
                <slm-svg-icon *ngIf="item[attribute.key].icon"
                              [style.fill]="item[attribute.key].color"
                              [icon]="item[attribute.key].icon"
                              [matTooltip]="item[attribute.key].label | translate"
                              class="block m-auto"></slm-svg-icon>
                <div *ngIf="item[attribute.key].icon && item[attribute.key].label"
                     class="block m-auto txt-center">{{item[attribute.key].label | translate}}</div>
              </ng-container>
              <ng-container *ngIf="!item[attribute.key]">
                <slm-loader diameter="15" class="block h-16px m-auto mt-0 mb-0"></slm-loader>
              </ng-container>
            </div>
            <slm-button-icon
              *ngSwitchCase="'round-button'"
              [icon]="attribute.buttonIcon"
              [testId]="attribute.testId"
              class="size-2 m-auto fill-accent"
              [loading]="!!attribute.key ? item[attribute.key] : false"
              loadingDiameter="22"
              [matTooltip]="attribute.buttonLabel | translate"
              elevation="true"
              (click)="buttonClicked(item, attribute.key)"
            ></slm-button-icon>
            <ng-container *ngSwitchDefault>{{
              item[attribute.key] || attribute.replacement
              }}</ng-container>
          </div>
        </td>
      </tr>
      <tr *ngIf="extraAttributeName && item[extraAttributeName]"
          class="vertical-table--bottom-border simple-table__row">
        <td [attr.colspan]="config.length">
          {{item[extraAttributeName]}}
        </td>
      </tr>
    </ng-container>
  </table>
</div>
<ng-template #loading>
  <slm-loader></slm-loader>
</ng-template>
