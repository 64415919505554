import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ButtonConfig, buttonsStaticConfig, RoutesEnum} from '../../../global';
import {Observable} from 'rxjs';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {map} from 'rxjs/operators';

@Component({
  selector: 'slm-help-dialog',
  templateUrl: './help-dialog.component.html'
})
export class HelpDialogComponent implements OnInit {
  public readonly buttons: Array<ButtonConfig> = [
    buttonsStaticConfig.close,
    {
      action: 'navigate',
      url: RoutesEnum.BUG_REPORT,
      buttonStyle: 'primary',
      label: 'BUG_REPORT.TITLE_SHORT',
      testId: 'bug-report-btn'
    }
  ];

  public content$: Observable<SafeHtml>;

  constructor(
    public readonly dialogRef: MatDialogRef<HelpDialogComponent>,
    private sanitized: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: Observable<string>) {
  }

  ngOnInit() {
    this.content$ = this.data.pipe(map(content => this.sanitized.bypassSecurityTrustHtml(content)));
  }

  public buttonEvent() {
    this.dialogRef.close();
  }
}
