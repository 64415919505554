import {Injectable} from '@angular/core';
import {GeneralSandbox} from '../sandboxes';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {RoutesEnum} from '../enums';
import {BillingSystemEnum} from '../entities';

@Injectable({
  providedIn: 'root'
})
export class UseExternalBillingGuard {
  constructor(
    private readonly generalSandbox$: GeneralSandbox,
    private readonly router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return combineLatest([
      this.generalSandbox$.externalBillingSystem$,
      this.generalSandbox$.externalBillingSystemError$
    ]).pipe(
      map(([externalBillingSystem, externalBillingSystemError]) => {
        const useExternalBilling =  externalBillingSystem === undefined ?
          undefined :
            externalBillingSystem === BillingSystemEnum.SZAMLAZZ ||
            (externalBillingSystem === BillingSystemEnum.BILLZONE && !externalBillingSystemError);

        if(!useExternalBilling && useExternalBilling !== undefined){
          this.router.navigate([RoutesEnum.OUTGOINGS_INVOICE_CREATE_DISABLED]);
        }

        return useExternalBilling;
      })
    );
  }
}
