import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, Injector, NgModule} from '@angular/core';

import {appRoutes} from './app.routing';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers, reducers } from './+state';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { RouteInterceptor } from './interceptors/route.interceptor';
import {
  HTTP_INTERCEPTORS, HttpBackend} from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { SharedModule } from '../modules/shared/shared.module';
import { GlobalModule } from '../modules/global/global.module';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {GlobalErrorHandler, moduleHttpLoaderFactory} from '../modules/global';
import { LoginContainer } from './containers/login/login.container';
import { MainContainer } from './containers/main/main.container';
import { HomeContainer } from './containers/home/home.container';
import { NotificationComponent } from './components/notification/notification.component';
import { HeaderComponent } from './components/header/header.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileDialogComponent } from './components/profile-dialog/profile-dialog.component';
import { HttpErrorInterceptor } from './interceptors/error.interceptor';
import { PasswordChangeDialogComponent } from './components/password-change-dialog/password-change-dialog.component';
import { RegistrationContainer } from './containers/registration/registration.container';
import {
  RECAPTCHA_LANGUAGE,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaV3Module
} from 'ng-recaptcha';
import { CompanyPendingContainer } from './containers/company-pending/company-pending.container';
import { AlertsComponent } from './components/alerts/alerts.component';
import { NotificationAlertDialogComponent } from './components/notification-alert-dialog/notification-alert-dialog.component';
import {ActivationContainer} from './containers/activation/activation.container';
import {ExternalPageContainerComponent} from './components/externap-page-container/external-page-container.component';
import {ExternalInvoiceContainer} from './containers/external-invoice/external-invoice.container';
import {ResetPasswordContainer} from './containers/reset-password/reset-password.container';
import {NewPasswordContainer} from './containers/new-password/new-password.container';
import {RouterModule} from '@angular/router';
import {BugReportContainer} from './containers/bug-report/bug-report.container';
import {applicationTranslationInitializerFactory} from './app.config';
import {CompanyActivationContainer} from './containers/company-activation/company-activation.container';
import {LanguageSelectorComponent} from './components/language-selector/language-selector.component';
import {CompanyDebtContainer} from './containers/company-debt/company-debt.container';
import {CacheInterceptor} from './interceptors/cache.interceptor';
import {LoggerInterceptor} from './interceptors/logger.interceptor';

const components = [
  AlertsComponent,
  ExternalPageContainerComponent,
  HeaderComponent,
  LanguageSelectorComponent,
  NotificationComponent
];

const dialogs = [
  NotificationAlertDialogComponent,
  PasswordChangeDialogComponent,
  ProfileDialogComponent
];

const containers = [
  ActivationContainer,
  BugReportContainer,
  DashboardComponent,
  ExternalInvoiceContainer,
  LoginContainer,
  HomeContainer,
  MainContainer,
  NewPasswordContainer,
  RegistrationContainer,
  ResetPasswordContainer,
  CompanyPendingContainer,
  CompanyActivationContainer,
  CompanyDebtContainer
];

@NgModule({
    declarations: [AppComponent, ...containers, ...components, ...dialogs],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule.forRoot(),
        RecaptchaV3Module,
        RecaptchaModule,
        RecaptchaFormsModule,
        GlobalModule,
        RouterModule.forRoot(appRoutes, {}),
        TranslateModule.forRoot({
            extend: true,
            loader: {
                provide: TranslateLoader,
                useFactory: moduleHttpLoaderFactory,
                deps: [HttpBackend]
            }
        }),
        StoreModule.forRoot(reducers, {metaReducers}),
        StoreDevtoolsModule.instrument({
            maxAge: 10, // Retains last 10 states
            logOnly: environment.production // Restrict extension to log-only mode
        , connectInZone: true}),
        EffectsModule.forRoot([])
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: RECAPTCHA_LANGUAGE,
            useValue: 'hu' // use Hungarian language
        },
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: RouteInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: LoggerInterceptor, multi: true},
        {
            provide: APP_INITIALIZER,
            useFactory: applicationTranslationInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
