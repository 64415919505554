import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { ErrorHandlingService } from './error-handling.service';
import { Observable } from 'rxjs';
import {Division, DivisionResponse, TableOrder, User, UserCompanyChange} from '../entities';
import { catchError, map } from 'rxjs/operators';
import {capitalizeFirstLetter, getUserRights} from '../functions';
import {InvoiceType} from '../enums';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private httpDirect: HttpClient;
  constructor(
    private readonly http: HttpClient,
    private readonly httpBackend: HttpBackend,
    private readonly errorHandler: ErrorHandlingService
  ) {
    this.httpDirect = new HttpClient(httpBackend);
  }

  public loadUser(): Observable<User> {
    return this.http.get('/users/me').pipe(
      map((user: User) => getUserRights(user)),
      catchError((error) => this.errorHandler.handleError(error))
    );
  }

  public changeLanguage(language: string): Observable<User> {
    return this.http.put('/users/me/language', {language}).pipe(
      catchError((error) => this.errorHandler.handleError(error))
    );
  }

  public changeCompany(companyId: number): Observable<UserCompanyChange> {
    return this.http
      .put('/user/company', { id: companyId })
      .pipe(catchError((error) => this.errorHandler.handleError(error)));
  }

  public updatePassword(
    currentPassword: string,
    newPassword: string
  ): Observable<UserCompanyChange> {
    return this.http
      .put('/users/me/password', { currentPassword, newPassword })
      .pipe(catchError((error) => this.errorHandler.handleError(error)));
  }

  public updateUser(user: any): Observable<UserCompanyChange> {
    return this.http
      .put('/users/me', user)
      .pipe(catchError((error) => this.errorHandler.handleError(error)));
  }

  getViewDetails(target: InvoiceType): Observable<Array<string>> {
    return this.http
      .get(`/view-settings/${target}`)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  setViewDetails(target: InvoiceType, id: number): Observable<Array<string>> {
    return this.http
      .put(`/view-settings/${target}`, {id})
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getDivisions(page: any,
                      order: TableOrder,
                      search: string = null): Observable<DivisionResponse> {
    return this.http
      .get('/division', {
        params: {
          size: `${page?.size || 10}`,
          page: `${page?.page || 1}`,
          ...(order
            ? {
              [`order${capitalizeFirstLetter(order.attribute)}`]: order.order
            }
            : {}),
          ...(search ? {filterSearch: encodeURIComponent(search)} : {})
        }
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getDivisionUsers(divisionId: number): Observable<Division> {
    return this.http
      .get('/division', {
        params: {
          id: divisionId
        }})
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public createNewDivision(divisionName: string): Observable<any> {
    return this.http
      .post('/division', {
        name: divisionName
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public modifyDivision(divisionId: number, divisionName: string): Observable<any> {
    return this.http
      .put('/division', {
        id: divisionId,
        name: divisionName
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public deleteDivision(divisionIds: Array<string>): Observable<any> {
    return this.http
      .delete('/division/' + divisionIds.toString())
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }
}
