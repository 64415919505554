import {ChangeDetectionStrategy, Component, Input, Output, EventEmitter} from '@angular/core';
import {ColumnTableItem, TranspiledColumnTableItem} from '../../../../global';

@Component({
  selector: 'slm-vertical-table',
  templateUrl: './vertical-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerticalTableComponent {
  data: any = null;
  @Input() loadingAttributes: Array<string> = [];

  @Input('data')
  set setData(data: any) {
    this.data = data;
    this.setAttribute(this.attributes);
  }

  attributes: Array<TranspiledColumnTableItem> = [];

  @Input('attributes')
  set setAttributes(attributes: Array<ColumnTableItem>) {
    this.setAttribute(attributes);
  }

  @Output() thirdColumnClicked = new EventEmitter();

  keyIsExist(key: string) {
    if (!this.data) {
      return false;
    }
    const keyIsExist = key in this.data;
    return keyIsExist && this.data[key] !== null && this.data[key] !== undefined;
  }

  setAttribute(attributes: Array<ColumnTableItem>) {
    this.attributes = attributes?.map(attribute => ({
      ...attribute,
      color: typeof attribute.valueColor === 'function' ? attribute.valueColor(this.data) : attribute.valueColor,
      thirdColumnConfig: typeof attribute.thirdColumn === 'function' ? attribute.thirdColumn(this.data) : attribute.thirdColumn
    }));
  }
}
