<div class="p-3 block mb-2">
  <h5 *ngIf="useStyle" class="txt-start-i pb-1 mb-2 txt-medium border-bottom"
      translate="BANK_ACCOUNTS.NAME"></h5>
  <slm-message
    type="info"
    *ngIf="!bankAccountFormArray?.length"
    message="LBL_PARTNERS.NO_BANK_ACCOUNTS"
  ></slm-message>
  <div
    *ngFor="let form of bankAccountFormArray.controls; let index = index;"
    class="radius mt-3 p-2"
    [class.bg-color-accent-transparent-lighter]="useStyle"
    [class.p2]="useStyle"
    data-test-id="bankAccountBlock"
  >
    <div class="grid grid-col-2-50 grid-responsive">
      <slm-text-input
        label="BANK_ACCOUNT.NAME"
        [control]="form.get('accountName')"
        [errors]="inputErrors"
        testId="partner-accounts-bank-name"
      ></slm-text-input>

      <slm-bank-account-input
        label="INVOICE.HOME_ACCOUNT_NUMBER"
        placeholder="INVOICE.HOME_ACCOUNT_16_24"
        [errors]="inputErrors"
        [control]="form.get('accountNumber')"
        testId="partner-accounts-account-number"
      ></slm-bank-account-input>

      <slm-select-autocomplete-input
        label="BANK_ACCOUNTS.BANK_NAME"
        [control]="form.get('bankAccountId')"
        [options]="banks$ | async"
        hideSubLabel="true"
        clearButton="true"
        [notFoundOptionLabel]="'LBL_PARTNERS.NEW_BANK' | translate"
        [searchFunction]="searchFunction"
        [errors]="inputErrors"
        [customWarningMsg]="bankIdHint$ | async"
        testId="partner-accounts-bank"
      ></slm-select-autocomplete-input>

      <slm-simple-table *ngIf="bankDataList$[index] | async as data" [items]="[data]"
                        [config]="bankDetailTableConfig"></slm-simple-table>
    </div>
    <div *ngIf="showBankFormArray[index] | async"
         class="grid grid-col-2-50 grid-responsive">
      <slm-text-input
        label="BANK_SHORT_NAME"
        [control]="form.get('bankName')"
      ></slm-text-input>

      <slm-text-input
        label="LBL_PARTNERS.COUNTRY_CODE"
        secondaryLabel="{{'LBL_PARTNERS.LENGTH_HINT' | translate: {length: 2} }}"
        upperCase="true"
        maxLength="2"
        [control]="form.get('countryCode')"
        [errors]="inputErrors"
      ></slm-text-input>

      <slm-text-input
        label="INVOICE.SWIFT"
        [errors]="inputErrors"
        upperCase="true"
        maxLength="11"
        [control]="form.get('swift')"
        testId="swift"
      ></slm-text-input>
    </div>
    <div class="grid grid-col-2-65-35 grid-responsive">
      <slm-bank-account-input
        label="BANK_ACCOUNTS.IBAN"
        [prefixLabel]="ibanPrefixes$[index] | async"
        [control]="form.get('iban')"
        [errors]="inputErrors"
        testId="partner-accounts-iban"
      ></slm-bank-account-input>
    </div>
    <div class="grid grid-col-2-65-35 grid-responsive">
      <slm-bank-account-input
        label="BANK_ACCOUNTS.OTHER_BANKACCOUNT"
        [errors]="inputErrors"
        [control]="form.get('other')"
        testId="partner-accounts-other"
      ></slm-bank-account-input>
    </div>
    <slm-toggle [control]="form.get('default')"
                label="DEFAULT"
                testId="partner-accounts-default"></slm-toggle>

    <slm-message type="info"
                 message="LBL_PARTNERS.MISSING_BANK"
                 class="block mt-2 mb-2"
                 isInnerHtml="true"></slm-message>
    <div *ngIf="showButtons" class="flex flex-end">
      <slm-button
        buttonStyle="transparent-blue"
        icon="delete"
        class="w-fit-content block"
        text="LBL_BTN.DELETE"
        testId="partner-accounts-delete"
        (click)="deleteBankAccount(index)"
      ></slm-button>
    </div>
  </div>
  <div *ngIf="showButtons" class="flex flex-end mt-3">
    <slm-button
      class="w-fit-content block"
      buttonStyle="primary"
      text="BANK_ACCOUNT.NEW"
      testId="partner-accounts-new"
      [icon]="icons.ADD_CIRCLE"
      (click)="newBankAccount()"
    ></slm-button>
  </div>
</div>
