/**
 * @summary An enum that specifies the available values for the Partner object's type property.
 */
export enum PartnerTypeEnum {
  Supplier = 'supplier',
  Customer = 'customer',
  Mixed = 'supplier-and-customer',
  Company = 'company'
}

/**
 * @summary utility type specifying the available values for the status property of various entities.
 */
export type PartnerActiveStatus = 'active' | 'inactive';

export enum PartnerActiveStatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

/**
 * @summary An union type describing the two possible types of invoice types a partner can have as preferred invoice type.
 */
export enum PartnerInvoiceTypeEnum {
  ELECTRICAL = 'electrical',
  PAPER = 'paper'
}

/**
 * @summary An enum specifying the values available as the taxation form option.
 */
export enum TaxationFormEnum {
  NORMAL = 'normal',
  EVA = 'eva',
  KATA = 'kata'
}
