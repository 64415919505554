<div class="bottom-bar" [class.bottom-bar--flexible]="flexible">
  <div
    class="bottom-bar__left"
    [class.bottom-bar__left--no-margin]="!rightButtons.length"
  >
    <ng-container *ngFor="let button of leftButtons">
      <slm-button
        *slmAccess="button.rights"
        class="bottom-bar__button"
        [config]="button"
        noWrap="true"
        [disabled]="isDisabled(button.action)"
        (click)="fireEvent.emit({ event: button.action })"
      ></slm-button>
    </ng-container>
  </div>
  <div
    class="bottom-bar__right"
    [class.bottom-bar__right--mobile-padding]="!!leftButtons.length"
  >
    <ng-container *ngFor="let button of rightButtons">
      <slm-button
        *slmAccess="button.rights"
        class="bottom-bar__button"
        [config]="button"
        [loading]="button.isLoading"
        noWrap="true"
        [disabled]="isDisabled(button.action)"
        (click)="fireEvent.emit({ event: button.action })"
      ></slm-button>
    </ng-container>
  </div>
</div>
