import {Component, Input} from '@angular/core';
import {InputAbstract} from '../base/input.abstract';
import {GroupedSelectOptions} from '../../../../global';

@Component({
  selector: 'slm-grouped-select-input',
  templateUrl: './grouped-select-input.component.html',
  styles: [`
    .simple-view {
      height: 3rem;
    }

    :host {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
    }
  `]
})
export class GroupedSelectInputComponent extends InputAbstract {
  public loading = true;

  public groupedOptions: Array<GroupedSelectOptions> = null;

  @Input('options')
  public set setOptions(options: Array<GroupedSelectOptions>) {
    this.groupedOptions = options;
    this.loading = !options;
  }
}
