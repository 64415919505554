import {Component, OnInit} from '@angular/core';
import {AuthSandbox} from '../../../modules/global';
import {first, switchMap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {of} from 'rxjs';

@Component({
  selector: 'slm-company-activation',
  templateUrl: './company-activation.container.html'
})
export class CompanyActivationContainer implements OnInit {

  invalid = false;
  activated = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly authSandbox: AuthSandbox
  ) {
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        first(),
        switchMap(params => !params.get('token') ? of('invalid') : this.authSandbox.activate(params.get('token')))
      )
      .subscribe({
        next: (resp) => resp === 'invalid' ? this.invalid = true : this.activated = true,
        error: () => this.invalid = true
      });
  }
}
