<slm-dialog-container
  [title]="dialogTitle"
  [buttons]="buttons"
  (fireEvent)="buttonClicked($event.event)"
  class="w-500px block"
>
  <div class="mt-2 mb-2">
    <slm-text-input
      [label]="'FILE_MANAGER.FOLDER_NAME'"
      [control]="form.get('name')"
      [errors]="errors"
      testId="dialog_folder_name"
    ></slm-text-input>
    <slm-toggle
      [label]="'FILE_MANAGER.PRIVATE_FOLDER'"
      [control]="form.get('isPrivate')"
      testId="dialog_folder_isPrivate"
    ></slm-toggle>
  </div>
  <ng-container *ngIf="showRights$ | async">
    <div class="mt-2 mb-2">
      <h4>{{ 'FILE_MANAGER.USER_RIGHTS' | translate }}</h4>
      <div
        *ngFor="let template of arrayItems; let index = index;"
        class="radius p-2 mt-3 bg-color-accent-transparent-lighter"
      >
        <form [formGroup]="template">
          <div class="grid-col-3-40-2 grid-responsive">
            <slm-select-input
              label="INVOICE.USER"
              [control]="template.get('user')"
              [options]="userOptions"
              [errors]="errors"
            ></slm-select-input>
            <slm-select-input
              [control]="template.get('right')"
              [options]="rightOptions"
              [errors]="errors"
              label="FILE_MANAGER.USER_RIGHTS"
              testId="right"
            ></slm-select-input>
            <slm-button-icon
              *ngIf="!disableRightEdit"
              [icon]="icons.DELETE"
              (click)="deleteRightRow(index)"
              class="size-3 fill-accent"
            ></slm-button-icon>
          </div>
        </form>
      </div>
      <slm-message
        *ngIf="(arrayItems || []).length === 0"
        message="FILE_MANAGER.NO_RIGHTS"
        type="info"
      ></slm-message>
      <slm-button
        [disabled]="disableRightEdit"
        (click)="addRightRow()"
        text="FILE_MANAGER.NEW_RIGHT"
        buttonStyle="secondary"
        class="mt-3 mb-3 block float-right"
        icon="add_circle"
        testId="addNewRight"
      ></slm-button>
    </div>
  </ng-container>
</slm-dialog-container>
