import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlingService } from './error-handling.service';
import { Observable } from 'rxjs';
import { Company, CompanyActivation, Currency } from '../entities';
import { catchError } from 'rxjs/operators';
import {CompanyNavSettings, PublicCompanyNavSettings} from '../../settings/entities/company-data.entity';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandler: ErrorHandlingService
  ) {}

  public getCompanyCurrency(): Observable<Currency> {
    return this.http
      .get('/company-information/primary-currency')
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getCurrencies(): Observable<{ currencies: Array<Currency> }> {
    return this.http
      .get('/currency/list')
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getCompanyCurrencies(): Observable<Array<Currency>> {
    return this.http
      .get('/companies/currencies')
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public saveCompany(company): Observable<Array<Currency>> {
    return this.http
      .post('/partners/subpartner', company)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getCurrentCompany(): Observable<Company> {
    return this.http
      .get('/partner/get')
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getCompanyActivation(): Observable<CompanyActivation> {
    return this.http
      .get('/partners/activations')
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getSubsidiaries(detailed: boolean): Observable<Array<Company>> {
    return this.http
      .get('/partner/group-list', detailed ? { params: { detailed: detailed } } : {})
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public validateExTaxNumber(taxNumber: string, companyId?: string | number): Observable<{isValid: boolean}> {
    return this.http
      .get(`/companies/eu-vat-number/${taxNumber}` + (companyId ? `/${companyId}` : ''))
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public checkNavData(data: CompanyNavSettings): Observable<any> {
    return this.http
      .post('/companies/nav/check', data)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public checkPublicNavData(data: PublicCompanyNavSettings): Observable<any> {
    return this.http
      .post('/public/nav/check', data)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }
}
