import {Component, Input} from '@angular/core';
import {fadeInUp600ms} from '../../../modules/global';

@Component({
  selector: 'slm-external-page-container',
  template: `
    <article class="auth-bg-image">
      <slm-language-selector class="top-0 right-0 mt-2 mb-2 mr-2 float-right pos-absolute z-infinit"></slm-language-selector>
      <div
        class="pos-absolute block w-100 h-100vh overflow-y-auto flex flex-column flex-items-center flex-{{layoutDirection}}"
      >
        <div class="pt-3 pb-3 flex flex-center">
          <section
            @fadeInUp
            class="card-container card pt-5 pb-4 pos-relative flex flex-column flex-center place-content-center flex-items-center"
            [style.width]="width"
          >
            <img
              class="card-container__logo"
              src="./assets/pictures/logo/SLM_logo_icon.png"
              alt="SLM System logo"
            />
            <img
              class="card-container__text"
              src="./assets/pictures/logo/SLM_logo_text.png"
              alt="SLM System logo"
            />
            <ng-content></ng-content>
          </section>
        </div>
      </div>
    </article>
  `,
  styles: [`
    .card-container {
      max-width: 90vw;
      height: min-content;
      position: inherit;
      margin: auto 3rem;
    }

    .card-container__logo {
      width: 8vh;
      margin: auto;
      display: block;
      height: 8vh;
    }

    .card-container__text {
      display: block;
      margin: 1rem 0;
      height: 3vh;
    }
  `],
  animations: [fadeInUp600ms]
})
export class ExternalPageContainerComponent {
  @Input() width = '320px';
  @Input() layoutDirection = 'center';

}
