import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '../../../global';

@Component({
  selector: 'slm-monogram',
  templateUrl: './monogram.component.html',
  styleUrls: ['./monogram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonogramComponent {
  size = 2;
  fontSize = '1.2em';
  monogram = '--';

  color = null;
  @Input() picture = '';

  @Input('color')
  set setColor(color) {
    this.color = color || null;
  }

  @Input('size')
  set setSize(size: number) {
    this.size = size;
    this.fontSize = `${(size / 1.7).toFixed(2)}em`;
  }

  @Input('user')
  set setData(user: User | any) {
    if (user) {
      this.picture = user.picture || '';
      if (!!user.firstName && !!user.lastName) {
        this.monogram = `${user.firstName[0]}${user.lastName[0]}`.toUpperCase();
      } else if (!!user.firstName || !!user.lastName) {
        this.setUsername = user.firstName || user.lastName;
      } else {
        this.monogram = '--';
      }
    } else {
      this.picture = '';
      this.monogram = '--';
    }
  }

  @Input('name')
  set setUsername(name: string | { firstName: string; lastName: string }) {
    if (name instanceof Object) {
      this.monogram = `${name.firstName[0]}${name.lastName[0]}`.toUpperCase();
    } else {
      const parts = (name || '').split(' ').filter((part) => !!part);
      if (parts.length > 1) {
        this.monogram = `${parts[0][0]}${parts[1][0]}`.toUpperCase();
      } else if (parts.length === 1) {
        this.monogram = `${parts[0][0]}${parts[0][1]}`.toUpperCase();
      } else {
        this.monogram = '--';
      }
    }
  }
}
