<slm-page-container
  [title]="'LBL_MENU.HOME'"
  [primaryButtonConfig]="config.primaryButton"
  [secondaryButtonConfig]="config.secondaryButton"
  (fireEvent)="pageButtonEvent($event)"
>
  <article
    *ngIf="(loading$ | async) === false; else loading"
    class="m-auto w-max-container pb-5"
  >
    <ng-container *slmAccess="rights.DOCUMENT_FINANCIAL_SETTINGS_READ">
      <ng-container *ngTemplateOutlet="cashFlow"></ng-container>
    </ng-container>
    <ng-container *slmAccess="rights.DOCUMENT_FINANCIAL_SETTINGS_READ">
      <ng-container *ngTemplateOutlet="cashFlowInvoiceIncoming"></ng-container>
    </ng-container>
    <ng-container *slmAccess="rights.DOCUMENT_FINANCIAL_SETTINGS_READ">
      <ng-container *ngTemplateOutlet="cashFlowInvoiceOutgoing"></ng-container>
    </ng-container>
    <ng-container *slmAccess="rights.DOCUMENT_FINANCIAL_SETTINGS_READ">
      <ng-container *ngIf="vatBalance$ | async as vatBalance">
        <div class="card block w-100 mt-3 card--no-padding">
          <div class="card__header p-3">
            <h4 class="card__header__title">{{'VAT_BALANCES' | translate}}</h4>
            <slm-toggle
              [label]="'INCLUDE_NAV_DATA'"
              [control]="navVatToggleControl"
              class="block w-min-250px"
            ></slm-toggle>
          </div>
          <slm-vat-balance
            @fadeInUp
            [currency]="currency"
            hideExport="true"
            hideHeader="true"
            [routeParams]="config.routeDates"
            [vatBalance]="vatBalance"
          ></slm-vat-balance>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *slmAccess="rights.DOCUMENT_FINANCIAL_SETTINGS_READ">
      <ng-container *ngTemplateOutlet="cashFlowBalance"></ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="incomingOverview"></ng-container>

    <ng-container *ngTemplateOutlet="outgoingOverview"></ng-container>
  </article>
</slm-page-container>

<ng-template #cashFlowBalance>
  <ng-container *ngIf="balance$ | async">
    <div @fadeInUp class="card card--no-padding mt-3">
      <div class="card__header">
        <h4 class="card__header__title" translate="CURRENT_INCOMES_AND_OUTGOINGS"></h4>
      </div>
      <div class="card__content">
        <slm-cash-flow-balance-overview
          [income]="income$ | async"
          [outgoing]="outgoing$ | async"
          [currency]="currency"
        ></slm-cash-flow-balance-overview>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #incomingOverview>
  <ng-container *ngIf="incomingOverview$ | async as incoming">
    <div @fadeInUp class="card card--no-padding mt-3">
      <div class="card__header">
        <h4 class="card__header__title" translate="INCOMING_PROCESSING_IN_THIS_MONTH"></h4>
      </div>
      <div class="card__content p-3">
        <slm-invoice-overview
          [params]="processParams"
          [link]="'/' + routes.INVOICES_INCOMES_TABLE"
          [data]="incoming"
          [showSentToBank]="showSentToBank$ | async"
          type="incoming"
        ></slm-invoice-overview>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #outgoingOverview>
  <ng-container *ngIf="outgoingOverview$ | async as overview">
    <div @fadeInUp class="card card--no-padding mt-3">
      <div class="card__header">
        <h4 class="card__header__title" translate="OUTGOING_PROCESSING_IN_THIS_MONTH"></h4>
      </div>
      <div class="card__content p-3">
        <slm-invoice-overview
          [params]="processParams"
          [link]="'/' + routes.INVOICES_OUTGOINGS_TABLE"
          [data]="overview"
        ></slm-invoice-overview>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #cashFlow>
  <div
    @fadeInUp
    *ngIf="cashFlowData$ | async as data"
    class="card card--no-padding mt-3"
  >
    <div class="card__header">
      <div>
        <h4 class="card__header__title" translate="CASH_FLOW_BALANCE"></h4>
        <h5 *ngIf="data.A8Integrating"
            class="txt-start-i"
            translate="CASH_FLOW_BALANCE_MESSAGE">
        </h5>
      </div>
    </div>
    <div class="card__content p-3 block">
      <slm-cash-flow-chart
        maxHeight="400"
        [currency]="currency"
        [data]="data.items"
        [A8Integrating]="data.A8Integrating"
        [labels]="[
          'BALANCE' | translate,
          'REVENUE_INCOME' | translate,
          'REVENUE_OUTGOING' | translate
        ]"
      ></slm-cash-flow-chart>
    </div>
  </div>
</ng-template>

<ng-template #cashFlowInvoiceIncoming>
  <div
    @fadeInUp
    *ngIf="incomingCashFlowData$ | async as data"
    class="card card--no-padding mt-3"
  >
    <div class="card__header">
      <h4 class="card__header__title" translate="REVENUES"></h4>
    </div>
    <div class="card__content p-3">
      <slm-cash-flow-invoices-overview
        maxHeight="400"
        [currency]="currency"
        [data]="data"
        [labels]="[
          'INVOICED' | translate,
          'LBL_TABLE_ATTR.ACCEPTED' | translate
        ]"
      ></slm-cash-flow-invoices-overview>
    </div>
  </div>
</ng-template>
<ng-template #cashFlowInvoiceOutgoing>
  <div
    @fadeInUp
    *ngIf="outgoingCashFlowData$ | async as data"
    class="card card--no-padding mt-3"
  >
    <div class="card__header">
      <h4 class="card__header__title" translate="EXPENDITURES"></h4>
    </div>
    <div class="card__content p-3">
      <slm-cash-flow-invoices-overview
        maxHeight="400"
        [currency]="currency"
        [data]="data"
        [labels]="[
          'INVOICED' | translate,
          'PAID_MONEY' | translate
        ]"
      ></slm-cash-flow-invoices-overview>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <slm-loader class="block m-auto mt-5 mb-5"></slm-loader>
</ng-template>
