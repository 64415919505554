import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IconColors, TableAlign, TableTextStatus} from '../../../../../../global';

@Component({
  selector: 'slm-table-text-status',
  template: `
    <div
      *ngIf="data; else invalid"
      class="w-min-content flex-items-center flex"
      [class.flex-center]="align === 'center'"
      [class.flex-start]="align === 'start'"
      [class.flex-end]="align === 'end'"
    >
      <div *ngIf="data.statusAlign === 'start'" class="ml-1 block">
        <ng-container *ngTemplateOutlet="badge"></ng-container>
      </div>
      <h5 class="txt-start-i m-0 pr-1 pl-1 w-max-content w-max-200 txt-no-wrap overflow-hidden txt-ellipsis"
          [matTooltip]="textTooltip | translate">
        {{ data.text | translate }}
      </h5>
      <div *ngIf="data.statusAlign === 'end'" class="mr-1 block">
        <ng-container *ngTemplateOutlet="badge"></ng-container>
      </div>
    </div>
    <ng-template #invalid
    ><span class="w-100 block txt-center-i">--</span></ng-template
    >
    <ng-template #badge>
      <div *ngIf="data.statusIcon || data.statusNumber"
           class="size-1 radius-infinite color-white flex flex-center flex-items-center"
           [matTooltip]="data.statusTooltip  | translate"
           [style.backgroundColor]="data.color || colors.BLUE">
        <ng-container *ngIf="data.statusNumber">{{data.statusNumber}}</ng-container>
        <slm-svg-icon *ngIf="data.statusIcon" [icon]="data.statusIcon"
                      class="fill-white block size-16 w-16pxi h-16pxi"
                      size="16px"></slm-svg-icon>
      </div>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableTextStatusComponent {
  public colors = IconColors;
  public data: TableTextStatus = null;
  public textTooltip: string = null;

  @Input() align: TableAlign = TableAlign.CENTER;

  @Input('data')
  public set setData(data: TableTextStatus) {
    this.data = data;
    this.textTooltip = data.enableTooltip ? data.enableTooltip : data.enableTextTooltip ? data.text : null;
  }
}
