<ng-container [ngSwitch]="align">
  <mat-tab-group
    *ngSwitchCase="'center'"
    class="pager card p-1 block"
    mat-align-tabs="center"
    [class.pager--disable-max-height]="disableMaxHeight"
    [@.disabled]="true"
    [class.card--no-shadow]="disableElevation"
    [selectedIndex]="selectedIndex"
  >
    <ng-container *ngFor="let title of titles; let index = index">
      <mat-tab *slmAccess="title.rights"
               [label]="title.title | translate"
      >
        <ng-template matTabContent>
          <div
            cdk-scrollable
            class="pager__content overflow-y-auto"
            [style.maxHeight]="disableMaxHeight || 'calc(100vh - ' + maxHeight + 'rem)'">
            <ng-container [ngTemplateOutlet]="pages[index]"></ng-container>
          </div>
        </ng-template>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
  <mat-tab-group
    *ngSwitchCase="'start'"
    class="pager card p-1 block"
    mat-align-tabs="start"
    [@.disabled]="true"
    [class.pager--disable-max-height]="disableMaxHeight"
    [class.card--no-shadow]="disableElevation"
  >
    <ng-container *ngFor="let title of titles; let index = index">
      <mat-tab *slmAccess="title.rights"
               [label]="title.title | translate">
        <ng-template matTabContent>
          <div
            cdk-scrollable
            class="pager__content overflow-y-auto"
            [style.maxHeight]="disableMaxHeight || 'calc(100vh - ' + maxHeight + 'rem)'">
            <ng-container [ngTemplateOutlet]="pages[index]"></ng-container>
          </div>
        </ng-template>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
  <mat-tab-group
    *ngSwitchCase="'end'"
    class="pager card p-1 block"
    mat-align-tabs="end"
    [@.disabled]="true"
    [class.pager--disable-max-height]="disableMaxHeight"
    [class.card--no-shadow]="disableElevation"
  >
    <ng-container *ngFor="let title of titles; let index = index">
      <mat-tab *slmAccess="title.rights"
               [label]="title.title | translate">
        <ng-template matTabContent>
          <div
            cdk-scrollable
            class="pager__content overflow-y-auto"
            [style.maxHeight]="disableMaxHeight || 'calc(100vh - ' + maxHeight + 'rem)'">
            <ng-container [ngTemplateOutlet]="pages[index]"></ng-container>
          </div>
        </ng-template>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</ng-container>
