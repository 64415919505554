import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CompanySandbox} from '../../../modules/global';

@Component({
  selector: 'slm-component-debt',
  templateUrl: './company-debt.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyDebtContainer implements OnInit {
  public companyName$: Observable<string>;

  constructor(private readonly companySandbox: CompanySandbox) {
  }

  ngOnInit(): void {
    this.companyName$ = this.companySandbox.activeCompany$.pipe(
      map(company => company?.partnerName ? company.partnerName : '')
    );
  }
}
