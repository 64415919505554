import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FireEvent, Icon, MenuItem, NotificationMenu, User} from '../../../modules/global';
import {headerUserMenu} from './header-menu.config';
import {ThemePalette} from '@angular/material/core';

@Component({
  selector: 'slm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  public readonly icons = Icon;

  public remainingDemoDays: number;
  public remainingDemoDaysValue: number;
  public progressSpinnerColor: ThemePalette = 'primary';
  public userMenu = headerUserMenu;
  public progressSpinnerTooltip = 'REMAINING_DAYS_IN_DEMO';

  @Input() public user: User;
  @Input() public notificationCount = 0;
  @Input() public notifications: Array<NotificationMenu> = [];
  @Input() public notificationLoading = false;
  @Input() public hasMoreNotification = true;
  @Output() public readonly fireEvent = new EventEmitter<FireEvent>();

  @Input('remainingDemoDays')
  public set _remainingDemoDays(value: number) {
    this.remainingDemoDaysValue = value > 0 ? (value / 30) * 100 : 100;
    this.remainingDemoDays = value;
    this.progressSpinnerColor = value > 0 ? 'primary' : 'warn';
    this.progressSpinnerTooltip = value > 0 ? 'REMAINING_DAYS_IN_DEMO' : 'DEMO_EXPIRED';
  }

  public trackNotificationById = (item) => item.id;
  public trackMenuByAction = (_, item: MenuItem) => item.action;
}
