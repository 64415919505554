import {Injectable} from '@angular/core';
import {convertToFormData, ErrorHandlingService} from '../index';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {FileDetailed, Folder, FoldersAndFiles} from '../entities';

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {
  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlingService
  ) {
  }

  public getFolderContent(
    folder: string,
    search: string = '',
    filters: any = null
  ): Observable<FoldersAndFiles> {

    const params = {
      ...(!!search ? {search: encodeURIComponent(search)} : {}),
      ...(filters ? filters : {})
    };

    return this.http.get(`/file-manager/folder/${folder}`, {params})
      .pipe(
        catchError(error => this.errorHandler.handleError(error))
      );
  }

  public createFolder(data: any): Observable<any> {
    return this.http.post<Folder>('/file-manager/folder', data)
      .pipe(catchError(error => this.errorHandler.handleError(error)));
  }

  public deleteFolder(ids: string): Observable<any> {
    return this.http.delete(`/file-manager/${ids}`)
      .pipe(catchError(error => this.errorHandler.handleError(error)));
  }

  public modifyFolder(id: string, data: any): Observable<any> {
    return this.http.put(`/file-manager/folder`, {...data, id})
      .pipe(catchError(error => this.errorHandler.handleError(error)));
  }

  public changeFolder(ids: Array<string>, toFolder: string): Observable<any> {
    return this.http.put(`/file-manager/change-folder`, {ids, toFolder})
      .pipe(catchError(error => this.errorHandler.handleError(error)));
  }

  public uploadFile(file: File, toFolder: string): Observable<{ type: 'error' | 'progress' | 'response'; result: any }> {
    return this.http.post(
      `/file-manager/file`,
      convertToFormData({ file: file, toFolder}),
      {
        observe: 'events',
        reportProgress: true
      }).pipe(
      map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round((100 * event.loaded) / event.total);
            return { type: 'progress', result: progress };

          case HttpEventType.Response:
            return { type: 'response', result: event.body };
          default:
            return {
              type: 'error',
              result: `Unhandled event: ${event.type}`
            };
        }
      }),
      catchError(error => this.errorHandler.handleError(error)
      ));
  }

  public deleteFiles(ids: Array<string>): Observable<any> {
    return this.http
      .delete(`/file-manager/${(ids || []).join(',')}`)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public modifyFile(id: string, data: any): Observable<any> {
    return this.http
      .put('/file-manager/file', {...data, id})
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getFile(id: string): Observable<FileDetailed> {
    return this.http
      .get(`/file-manager/file/${id}`)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public downloadFile(id: string): Observable<any> {
    return this.http
      .get(`/file-manager/download/${id}`, {responseType: 'blob'})
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }
}
