import { SelectOption, TableDate, TableIconText, TableText } from '../entities';
import {
  AccountingStatus,
  FillingStatus,
  IncomingPaymentStatus,
  OutgoingPaymentStatus
} from '../enums';
import {
  accountantStatus,
  fillingStatus,
  paymentStatuses
} from '../static';

export const stringToTableText = (
  value: string,
  data: any = null
): TableText => ({
  label: value,
  data
});

export const convertToTableIconText = (value: SelectOption): TableIconText =>
  !!value
    ? {
        label: value.label,
        icon: value.icon,
        color: value.color
      }
    : null;

export const accountingStatusToTableIconText = (
  status: AccountingStatus | FillingStatus
): TableIconText =>
  convertToTableIconText(
    accountantStatus.find((value) => value.value === status)
  );

export const fillingStatusToTableIconText = (
  status: AccountingStatus | FillingStatus
): TableIconText =>
  convertToTableIconText(fillingStatus.find((value) => value.value === status));

export const paymentStatusToTableIconText = (
  status: IncomingPaymentStatus | OutgoingPaymentStatus,
  isIncoming: boolean = false
): TableIconText =>
  convertToTableIconText(paymentStatuses(isIncoming).find(
      (value) => value.value === status
    )
  );

export const stringDateToTableDate = (date: string): TableDate => ({
  date: date ? new Date(date) : null
});

export const bytesToBeauty = (bytes: number): TableText => ({
    label: bytes ? bytes < 1024 ? `${(bytes).toFixed(2)} KB` : `${(bytes / 1024).toFixed(2)} MB` : ''
});
