import {TranslateService} from '@ngx-translate/core';
import {Injector} from '@angular/core';
import {LOCATION_INITIALIZED} from '@angular/common';
import {getDefaultLanguage} from '../modules/global';
import {lastValueFrom} from 'rxjs';

export const applicationTranslationInitializerFactory = (
  translate: TranslateService,
  injector: Injector
) => async () => {
  await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

  const browserLanguage = translate.getBrowserCultureLang() ?? translate.getBrowserLang();
  const defaultLanguage = getDefaultLanguage(['hu', 'en'].includes(browserLanguage) ? browserLanguage : 'hu');
  translate.addLangs(['en', 'hu']);
  translate.setDefaultLang(defaultLanguage);
  try {
    await lastValueFrom(translate.use(defaultLanguage));
  } catch (err) {
    console.log(err);
  }
};
