import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {InputAbstract} from '../base/input.abstract';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Base, SelectOption} from '../../../../global';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {FormControl, ValidatorFn, Validators} from '@angular/forms';

@Component({
  selector: 'slm-chip-autocomplete',
  templateUrl: './chip-autocomplete.component.html',
  styles: [`
    :host {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
    }
  `]
})
export class ChipAutocompleteComponent extends InputAbstract implements OnInit, OnDestroy {
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  private destroy$ = new Subject();
  public options: Array<SelectOption> = [];
  private readonly _error$ = new BehaviorSubject<string>('');

  public chips: Array<Base> = [];
  public chips$ = new BehaviorSubject<Array<Base>>([]);
  public errorMessage$: Observable<string>;
  public inputControl = new FormControl('', [Validators.required]);

  @Input('autocompleteOptions')
  public set setOptions(options: Array<SelectOption>) {
    this.options = options;
  }
  @Input() public validators: Array<ValidatorFn> = [];
  @Input() public loading: boolean;
  @Output() public searchText = new EventEmitter<FormControl>();

  @ViewChild('selectInput') selectInput: ElementRef<HTMLInputElement>;
  @ViewChild('chipGrid') chipGrid;

  ngOnInit() {
    super.ngOnInit();
    this.searchText.emit(this.inputControl);
    if (this.control.value) {
        this.control.value.map(value => this.addToChipsAndControl(value, false));
    }
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public removeItem(item: Base): void {
    this.chips = this.chips$.getValue();
    const index = this.chips.indexOf(item);

    const controlValues = this.control.value;

    if (index >= 0) {
      this.chips.splice(index, 1);
      this.chips$.next(this.chips);

      controlValues.splice(index, 1);
      this.control.setValue(controlValues);
      this.control.updateValueAndValidity();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value || '';

    if (value) {
      if (!!this.validators?.length) {
        const control = new FormControl(value, this.validators);
        control.markAllAsTouched();
        if (control.invalid) {
          this._error$.next(Object.keys(control.errors)[0]);
          return;
        }
      }
      this.addToChipsAndControl({id: +value?.value, name: value?.label}, true);
    }

    // Clear the input value
    this.selectInput.nativeElement.value = '';
    this.inputControl.setValue(null);
  }

  disableLoading() {
    this.loading = false;
  }

  displayValue = () => (selected: SelectOption | string) => {
    const option = (selected ?? this.inputControl?.value) as SelectOption;
    return option?.subLabel ? `${option.label} - ${option.subLabel}` : option?.label ?? option ?? '';
  };

  private addToChipsAndControl(value: Base, refreshControl: boolean){
    const chipsValue = this.chips$.getValue();

    if(chipsValue.filter(item => item.id === value.id).length === 0){
      const newValue = [{
        id: +value?.id,
        name: value?.name
      }];

      this.chips$.next([...chipsValue, ...newValue]);
      if(refreshControl){
        this.control.setValue([...(this.control.value || []), ...newValue]);
        this.control.updateValueAndValidity();
      }
    }
  }

  public setControlTouched(){
    if(!this.control.touched){
      this.control.markAsTouched();
    }
    if(!this.control.dirty){
      this.control.markAsDirty();
    }
    this.chipGrid.errorState = this.chips$.getValue().length < 1 && this.control.touched;
  }
}
