import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NotificationEnum, PartnerVatCheck} from '../../../global';
import {debounceTime} from 'rxjs/operators';
import {DateTransformPipe} from '../../pipes';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'slm-partner-negative-info',
  templateUrl: './partner-negative-info.component.html',
  providers: [DateTransformPipe]
})
export class PartnerNegativeInfoComponent implements OnInit {
  public _partnerLastVerificationDate$ = new BehaviorSubject<{
    type: NotificationEnum; message: string; params: string;
    euType: NotificationEnum; euMessage: string; euParams: string
  }>(
    null
  );
  public partnerLastVerificationDate$ = this._partnerLastVerificationDate$.asObservable().pipe(debounceTime(150));

  @Input('status')
  private set setPartnerStatus(data: PartnerVatCheck) {
    let verificationData = null;
    let euVerificationData = null;

    const currentDate = new Date();
    if (data) {
      const date = data.verificationDate ? new Date(data.verificationDate) : null;
      const euDate = data.euVerificationDate ? new Date(data.euVerificationDate) : null;

      const formattedDate = this.datePipe.transform(date, 'FORMAT.DATE');
      const formattedEuDate = this.datePipe.transform(euDate, 'FORMAT.DATE');
      const fullFormattedDate = this.datePipe.transform(date, 'FORMAT.DATE_FULL');
      const fullFormattedEuDate = this.datePipe.transform(euDate, 'FORMAT.DATE_FULL');

      if (!date && !euDate) {
        verificationData = {type: NotificationEnum.WARNING, message: 'LBL_PARTNERS.NO_INFO_DESCRIPTION', params: null};
      } else {
        if(date || euDate){
          if (data.negativeInfo) {
            const euVatErrorLabel = data.euNegativeInfo && formattedDate === formattedEuDate ?
              `<br>${this.translateService.instant('LBL_PARTNERS.NEGATIVE_EU_VAT_NUMBER', {dateLabel: ''})}` :
              '';

            verificationData = {type: NotificationEnum.ERROR, message: 'LBL_PARTNERS.NEGATIVE_INFO_DESCRIPTION', params: {date: fullFormattedDate, euVatErrorLabel} };
          }else if (date?.getTime() > currentDate?.getTime() - 604_800_000) { //MAX 1 WEEK OLD
            const euVatErrorLabel = !data.euNegativeInfo && formattedDate === formattedEuDate ?
              `<br>${this.translateService.instant('LBL_PARTNERS.POSITIVE_EU_VAT_NUMBER', {dateLabel: ''})}` :
              '';

            verificationData = {type: NotificationEnum.SUCCESS, message: 'LBL_PARTNERS.POSITIVE_INFO_DESCRIPTION', params: {date: fullFormattedDate, euVatErrorLabel} };
          } else if (date?.getTime() > currentDate?.getTime() - 7_776_000_000) { //MAX 90 DAYS
            verificationData = {type: NotificationEnum.INFO, message: 'LBL_PARTNERS.NEUTRAL_INFO_DESCRIPTION', params: {date: fullFormattedDate}};
          } else if (date?.getTime() <= currentDate?.getTime() - 7_776_000_000) { //MORE THEN 90 DAYS
            verificationData = {type: NotificationEnum.WARNING, message: 'LBL_PARTNERS.EXPIRED_INFO_DESCRIPTION', params: {date: fullFormattedDate}};
          }

          if(euDate) {
            const lastRefreshLabel = this.translateService.instant('LBL_PARTNERS.LAST_INFO_REFRESH_DATE', {date: fullFormattedEuDate});

            if(data.euNegativeInfo && (!data.negativeInfo || formattedDate !== formattedEuDate)){
              euVerificationData = {euType: NotificationEnum.ERROR, euMessage: 'LBL_PARTNERS.NEGATIVE_EU_VAT_NUMBER', euParams: {dateLabel: lastRefreshLabel}};
            }else if(!data.euNegativeInfo && (data.negativeInfo || formattedDate !== formattedEuDate)){
              euVerificationData = {euType: NotificationEnum.SUCCESS, euMessage: 'LBL_PARTNERS.POSITIVE_EU_VAT_NUMBER', euParams: {dateLabel: lastRefreshLabel}};
            }
          }
        }
      }
      this._partnerLastVerificationDate$.next({...verificationData, ...euVerificationData});
    }
  }

  constructor(
    private readonly datePipe: DateTransformPipe,
    private readonly translateService: TranslateService
  ) {
  }

  ngOnInit() {

  }

}
