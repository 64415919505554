import { Injectable } from '@angular/core';
import { PaginatorNew, TableOrder} from '../../global';
import { CompanyUserService } from '../services/company-user.service';
import {CompanyUserUpdate} from '../entities/users.entity';

@Injectable({
  providedIn: 'root'
})
export class CompanyUserSandbox {
  constructor(
    private readonly companyService: CompanyUserService
  ) {}

  getUsers = (
    page: PaginatorNew,
    order: TableOrder,
    search: string = null,
    isActive: boolean = true,
    shortInfo: boolean = false
  ) =>
    this.companyService.getUsers(
      page,
      order,
      search,
      isActive ? 'active' : 'inactive',
      shortInfo
    );

  updateUser = (user: CompanyUserUpdate) =>
    this.companyService.updateUser(user);

  createUser = (user: CompanyUserUpdate) =>
    this.companyService.createUser(user);

  updatePassword = (id: number, password) =>
    this.companyService.updateUserPassword(id, password);

  updateUserStatus = (ids: Array<number>, status: string) =>
    this.companyService.updateUserStatus(ids, status);

  getUser = (id: number) => this.companyService.getUser(id);

  getUserStatistics = () =>
    this.companyService.getUserStatistics();
}
