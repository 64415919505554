<slm-external-page-container>

  <h4 translate="AUTHENTICATION.LOGIN.WELCOME"></h4>

  <form class="w-100" [formGroup]="loginForm" (ngSubmit)="login()">

    <slm-text-input
      type="email"
      label="LBL_INPUT.LABEL.EMAIL"
      [control]="loginForm.get('email')"
      [errors]="inputErrors"
    ></slm-text-input>

    <slm-text-input
      [type]="passwordInputType"
      label="LBL_INPUT.LABEL.PASSWORD"
      class="mt-2"
      [control]="loginForm.get('password')"
      [errors]="inputErrors"
      [suffixIcon]="passwordInputIcon"
      [buttonIcon]="true"
      (fireEvent)="passwordTypeChanged()"
    ></slm-text-input>

    <div class="flex flex-end w-100 mb-2">
      <a routerLink="/{{routes.RESET_PASSWORD}}"
         [queryParams]="emailQueryParams$ | async" class="align"
         translate="AUTHENTICATION.LOGIN.RESET_PASSWORD"></a>
    </div>

    <slm-button
      class="w-100 mt-4 block"
      type="submit"
      testId="loginButton"
      [loading]="isLoading$ | async"
      text="LBL_BTN.LOGIN"
      [disabled]="loginForm.invalid && loginForm.dirty"
    ></slm-button>

  </form>

  <div class="mt-4 mb-2 flex w-100 flex--center">

    <span class="h-1px bg-color-grey-light block w-50 mt-1_5"></span>
    <h5 class="w-auto-i w-min-50" translate="LBL_OR"></h5>
    <span class="h-1px bg-color-grey-light block w-50 mt-1_5"></span>

  </div>

  <slm-button
    class="w-100"
    type="submit"
    testId="registrationButton"
    buttonStyle="transparent-blue"
    text="LBL_BTN.REGISTRATION"
    (click)="goToRegistration()"
  ></slm-button>

</slm-external-page-container>
