/**
 *
 *
 * Converts JSON object to FormData object
 *
 *
 *
 * @param object - JsonObject
 * @return Converted FormData object
 */
import {Paginator, PaginatorNew, TableOrder} from '../entities';
import {YearQuarter} from '../enums';

export const convertToFormData = (object): FormData => {
  const formData = new FormData();
  Object.keys(object || {}).forEach((attribute) => {
    formData.append(attribute, object[attribute]);
  });
  return formData;
};

/**
 *
 *
 * Converts a string to camel case string
 *
 *
 *
 * @param value
 * @return Converted string
 */
export const stringToCamelCase = (value: string) =>
  value
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, '');

/**
 *
 *
 * Converts a string to capitalized string
 *
 *
 *
 * @param value
 * @return Converted string
 */
export const capitalizeFirstLetter = (value: string) =>
  value ? `${value.charAt(0).toUpperCase()}${value.slice(1)}` : '';

/**
 *
 *
 * Compares two objects
 *
 *
 *
 * @param object1
 * @param object2
 * @return
 */
export const isSameObject = (object1: any, object2: any): boolean => {
  const keys1 = Object.keys(object1 || {});
  const keys2 = Object.keys(object2 || {});

  if (keys1.length !== keys2.length) {
    return false;
  }

  keys1.forEach((key1) => {
    if (!keys2.includes(key1)) {
      return false;
    }
  });

  keys2.forEach((key2) => {
    if (!keys1.includes(key2)) {
      return false;
    }
  });

  for (const key of keys1) {
    if (typeof object1[key] !== typeof object2[key]) {
      return false;
    }
    if (object1[key] instanceof Date) {
      if (object1[key].getTime() !== object2[key].getTime()) {
        return false;
      }
    } else if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
};

/**
 *
 *
 * Compares two array
 *
 *
 *
 * @param array1
 * @param array2
 * @param hasDifferentOrder
 * @return
 */
export const isSameArray = (
  array1: Array<any>,
  array2: Array<any>,
  hasDifferentOrder: boolean = false
): boolean => {
  if (!array1 || !array2) {
    return false;
  }
  if (array1.length !== array2.length) {
    return false;
  }
  if (!hasDifferentOrder) {
    const isNotSame = array1.find((item, index) => {
      if (utils.isObject(item)) {
        return !isSameObject(item, array2[index]);
      }
      return item !== array2[index];
    });
    return !isNotSame;
  }
  const newArray2 = [...array2];
  const isNotSame = array1.find((item) => {
    if (utils.isObject(item)) {
      const index = newArray2.findIndex((newItem) =>
        isSameObject(newItem, item)
      );
      if (index === -1) {
        return true;
      }
      newArray2.splice(index, 1);
      return false;
    } else {
      const index = newArray2.findIndex((newItem) => newItem === item);
      if (index === -1) {
        return true;
      }
      newArray2.splice(index, 1);
      return false;
    }
  });
  return !isNotSame;
};

/**
 * Returns the param object without the selected keys
 *
 * @param object - Source object
 * @param keys - Selected attributes
 * @return
 */
const omit = (object, keys: Array<string>): any => {
  if (!object) {
    return object;
  }
  if (!keys || !keys.length) {
    return object;
  }

  return Object.keys(object).reduce((obj, key) => {
    if (keys.includes(key)) {
      return obj;
    }
    return {
      ...obj,
      [key]: object[key]
    };
  }, {});
};

/**
 * Returns the param object with the selected keys
 *
 * @param object - Source object
 * @param keys - Selected attributes
 * @return
 */
const pick = (object, keys: Array<string>): any => {
  if (!object) {
    return object;
  }
  if (!keys || !keys.length) {
    return object;
  }

  return keys.reduce((obj, key) => ({
    ...obj,
    ...(object[key] !== undefined) ? {[key]: object[key]} : {}
  }), {});
};

const isNumber = (number: string | number) =>
  /^\d+([.]\d+)?$/.test(`${number}`);

const isString = (value) => typeof value == 'string';

const isObject = (value) => typeof value == 'object';

const convertValueToAmount = (amount: string | number) => `${amount ?? 0}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

const removeWhiteSpaces = (value: string) =>
  (value ?? '').replace(/[\s\t-]/g, '');

const removeWhiteSpacesExceptMinus = (value: string) =>
  (value ?? '').replace(/[\s\t]/g, '');

const dateToServerDate = (date: Date) => {
  if(date instanceof Date){
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
    const monthDate = date.getDate() <= 9 ? `0${date.getDate()}` : `${date.getDate()}`;
    return `${date.getFullYear()}-${month}-${monthDate}`;
  }else{
    return date;
  }
};

const yearQuarter = (date: Date): YearQuarter => {
  if (!date) {
    return null;
  }
  return (Math.floor(date.getMonth() / 3) + 1) as YearQuarter;
};

const base64toBlob = (base64: string): Blob => {
  const fileData = (base64 || '').includes('base64,') ? base64 : `data:@file/plain;base64,${base64}`;
  // convert base64/URLEncoded data component to raw binary data held in a string
  const byteString = (fileData.split(',')[0].indexOf('base64') >= 0 ? atob : unescape)(fileData.split(',')[1]);

  // separate out the mime component
  const mimeString = fileData.split(',')[0].split(':')[1].split(';')[0];
  // write the bytes of the string to a typed array
  const uint8Array = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; ++i) {
    uint8Array[i] = byteString.charCodeAt(i);
  }
  return new Blob([uint8Array], {type: mimeString});
};

const isSameDate = (date1: Date, date2: Date) =>
  date1.getFullYear() === date2.getFullYear()
  && date1.getMonth() === date2.getMonth()
  && date1.getDate() === date2.getDate();

const dayDifferenceBtwnDates = (date1: Date, date2: Date): number => {
  if (!date1 || !date2) {
    return null;
  }
  const millisDiff = Math.abs(date1.getTime() - date2.getTime());
  return millisDiff / 86_400_000; // 1 day in millis
};

const convertPaginatorToNewPaginator = (paginator: Paginator): PaginatorNew => ({
  fullLength: paginator.fullLength,
  size: paginator.pageSize,
  page: paginator.actualPage
});

const paginatorToQueryParams = (paginator: PaginatorNew) => !paginator ? {} : {
    page: paginator.page || 1,
    size: paginator.size || 10
};

const tableOrderToQueryParams = (order: TableOrder) => {
  {
    if (!order?.attribute || !order?.order) {
      return {};
    }
    return {
      orderBy: order.attribute,
      orderDirection: order.order
    };
  }
};

const everyAttributeIsTrue = (object: any): boolean =>
  Object.values(object ?? {}).reduce((loaded, value) => !!loaded && !!value, true) as boolean;

const everyAttributeIsFalse = (object: any): boolean =>
  Object.values(object ?? {}).reduce((loaded, value) => !!loaded && !value, true) as boolean;

const convertArraysInObjectToSting = (object: any): any =>
  Object.keys(object).reduce((obj, key) =>
    ({
      ...obj,
      ...(!!object[key] || object[key] === 0 ? {
        [key]: Array.isArray(object[key]) ? object[key].join(',') :
          (object[key] || (object[key] === 0 ? 0 : null))
      } : {})
    }), {});

const verifyAndConvertStringDateToIsoDate = (date: string | Date) => {
  if (!date && !Number.isNaN(new Date(date).getTime())) {
    return null;
  }
  if (isString(date)) {
    return new Date((date as string).replace(/\./g, '/')).toISOString();
  }
  return new Date(date).toISOString();
};

const currentDateMinusMonths = (month: number) => {
  const d = new Date();
  d.setMonth(d.getMonth() - month);
  return utils.dateToServerDate(d);
};

const currentDateMinusDays = (day: number) => {
  const d = new Date();
  d.setDate(d.getDate() - day);
  return d;
};

const currentDatePlusDays = (day: number) => {
  const d = new Date();
  d.setDate(d.getDate() + day);
  return d;
};

const groupByKey = (array: any, key: string) => {
  return array.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};


export const utils = {
  omit,
  pick,
  isNumber,
  removeWhiteSpaces,
  removeWhiteSpacesExceptMinus,
  dateToServerDate,
  base64toBlob,
  isSameDate,
  isSameObject,
  isSameArray,
  isString,
  isObject,
  convertPaginatorToNewPaginator,
  everyAttributeIsFalse,
  everyAttributeIsTrue,
  convertValueToAmount,
  convertArraysInObjectToSting,
  paginatorToQueryParams,
  tableOrderToQueryParams,
  dayDifferenceBtwnDates,
  yearQuarter,
  verifyAndConvertStringDateToIsoDate,
  currentDateMinusMonths,
  currentDateMinusDays,
  currentDatePlusDays,
  groupByKey
};
