import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { tableSizes } from './table-size.config';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  takeUntil
} from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  ButtonConfig,
  Paginator,
  PaginatorNew,
  SelectionButton,
  SelectOption,
  isSameObject, Icons, Icon
} from '../../../../global';

@Component({
  selector: 'slm-table-container',
  templateUrl: './table-container.component.html',
  styleUrls: ['./table-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableContainerComponent implements OnInit, OnDestroy {
  public readonly searchControl: AbstractControl = new UntypedFormControl();
  public page: PaginatorNew;
  private readonly destroy$ = new Subject();
  private isNewPaginator = false;
  public searchText: string;
  /**
   * INPUTS
   **/
  @Input() public filterEnabled = false;
  @Input() public filterButtonText = 'LBL_TABLE.FILTER';
  @Input() public searchEnabled = false;
  @Input() public searchLabel = 'LBL_TABLE.SEARCH';
  @Input() public customButton: ButtonConfig = null;
  @Input() public title = '';
  @Input() public disableAppearance = false;
  @Input() public restrictedWidth = true;
  @Input() public hidePagination = false;
  @Input() public filterButtonSize: 'small' | 'normal' = 'normal';
  @Input() public filterIcon: Icons = Icon.FILTER;

  @Input('searchText')
  public set setSearchText(search: string) {
    if (this.searchControl && this.searchControl.value !== search) {
      this.searchControl.setValue(search || '');
    }
  }
  @Input('page') public set setPage(page: any) {
    if (page) {
      const isNewPaginator = !!page?.page && !!page?.size;
      if (isNewPaginator) {
        this.isNewPaginator = true;
        this.page = page;
      } else {
        this.isNewPaginator = false;
        const newPage = {
          fullLength: +page.fullLength,
          page: +page.actualPage,
          size: +page.pageSize
        };
        if (!isSameObject(newPage, this.page)) {
          this.page = newPage;
        }
      }
    } else {
      this.page = {
        page: 1,
        size: 10,
        fullLength: 0
      };
    }
  }
  @Input() public pageSizes: Array<SelectOption> = tableSizes;
  @Input() public selectionButtons: Array<SelectionButton> = [];
  @Input() public showSelectionButtons = false;
  @Input() public multipleSearchInput = false;

  /**
   * OUTPUTS
   **/

  @Output() public readonly filterClicked = new EventEmitter<string>();
  @Output() public readonly customClicked = new EventEmitter<string>();
  @Output() public readonly searchChanged = new EventEmitter<string>();
  @Output() public readonly searchEntered = new EventEmitter<string>();
  @Output() public readonly pageChange = new EventEmitter<
    Paginator | PaginatorNew
  >();
  @Output() public readonly selectionButton = new EventEmitter<string>();

  /**
   * LIFECYCLE METHODS
   **/
  ngOnInit() {
    if (this.searchText !== null && typeof this.searchText !== 'object') {
      this.searchControl.setValue(this.searchText);
    }
    this.searchControl.valueChanges
      .pipe(
        debounceTime(1500),
        distinctUntilChanged(),
        filter((search) => this.searchText !== search),
        takeUntil(this.destroy$)
      )
      .subscribe((search) => {
        this.searchText = this.searchControl.value;
        this.searchChanged.emit(search);
      });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public startSearch(event) {
    const searchValue = this.searchControl.value;
    if (this.searchText !== searchValue) {
      this.searchText = searchValue;
        this.searchChanged.emit(this.searchText);
    }

    if(event === 'enter' && this.multipleSearchInput && !!searchValue){
      this.searchText = searchValue;
      this.searchEntered.emit(this.searchText);
      this.searchControl.reset();
    }
  }
  public pageChanged(event: PaginatorNew): void {
    if (!isSameObject(this.isNewPaginator, event)) {
      if (this.isNewPaginator) {
        this.pageChange.emit(event);
      } else {
        this.pageChange.emit({
          pageSize: +event.size,
          actualPage: +event.page,
          fullLength: +event.fullLength
        });
      }
    }
  }
}
