<nav class="side-nav" [style.width.px]="status === 'full' ? 300 : 80">
  <div class="side-nav__header flex">
    <slm-button-icon
      icon="menu"
      (click)="menuClicked()"
      class="side-nav__header__home"
      testId="showHide"
    ></slm-button-icon>
    <slm-select-input
      *ngIf="status === 'full' && companies"
      class="side-nav__header__select"
      [control]="companyControl"
      [options]="companies"
      lightTheme="true"
      hideOptionalLabel="true"
      label="LBL_MENU.AVAILABLE_COMPANIES"
    ></slm-select-input>
  </div>
  <div
    cdk-scrollable
    class="side-nav__items overflow-y-auto">
    <div class="side-nav__items__container flex flex-column flex-space-btwn">
      <div class="flex flex-column">
        <ng-container *ngFor="let item of menuItems; trackBy: trackByLabel">
          <slm-side-nav-item
            *slmAccess="item.rights"
            [menuItem]="item"
            [desktopClosed]="status === 'half'"
            [openedMenu]="openedMenu"
            [selectedRoute]="activeRoute"
            (toggle)="openMenu($event)"
            (route)="navigateTo($event)"
          ></slm-side-nav-item>
        </ng-container>
      </div>
      <div class="mt-6 mb-2 pos-relative block txt-size-medium txt-medium color-accent txt-center">
        <div class="flex" [class.flex-column]="status === 'half'">
          <a translate="LBL_MENU.FAQ" routerLink="/{{routes.FAQ}}"
             class="block w-min-80 w-fit-content  txt-decoration-none"></a>
          <div *ngIf="status !== 'half'" class="block ml-1 mr-1 color-white medium-text">|</div>
          <a translate="VIDEOS" routerLink="/{{routes.VIDEOS}}"
             class="block w-min-80 w-fit-content txt-decoration-none" [class.mt-2]="status === 'half'"></a>
        </div>

        <a target="_blank" routerLink="/{{routes.BUG_REPORT}}"
           class="block mt-2 w-min-80 w-fit-content txt-decoration-none"
           data-test-id="bug-report-btn"
           [class.ml-3]="status !== 'half'"
           [class.pl-1]="status !== 'half'">{{(status !== 'half' ? 'BUG_REPORT.TITLE' : 'BUG_REPORT.TITLE_SHORT') | translate}}</a>
      </div>
    </div>
  </div>
</nav>
