<mat-form-field
  class="flex-grow flex"
  [class.text-input--required]="isRequiredField && (control.valid || !control.dirty)"
  [class.text-input--error]="
    control && !control.valid && control.touched && control.dirty
  "
  [class.text-input--small]="smallInput"
>
  <mat-label *ngIf="label || (!isRequiredField && !hideOptionalLabel)"
    >{{ label | translate
    }} <span *ngIf="secondaryLabel"> {{ secondaryLabel | translate }} </span>
    <span *ngIf="!isRequiredField && !hideOptionalLabel" translate="LBL_INPUT.LABEL.NOT_REQUIRED"></span>
  </mat-label>
  <h5 *ngIf="!!prefixLabel && !prefixIcon" class="prefix-label" matPrefix>
    {{ prefixLabel | translate }}
  </h5>
  <slm-svg-icon
    matPrefix
    *ngIf="!!prefixIcon"
    [icon]="prefixIcon"
  ></slm-svg-icon>
  <input
    matInput
    uppercaseFormat
    [required]="isRequiredField"
    [attr.data-test-id]="testId"
    [enableUppercase]="upperCase"
    [type]="type"
    [minLength]="minLength"
    [placeholder]="placeholder | translate"
    [maxlength]="maxLength"
    [formControl]="control"
    [step]="decimals"
    [min]="min"
    [class.color-accent-i]="coloredValue"
    [max]="max"
    (focusout)="focusOut()"
    (keyup.enter)="enterEnabled ? onClick('enter') : null"
  />
  <slm-svg-icon
    *ngIf="!!suffixIcon && type !== 'search'"
    matSuffix
    matRipple
    class="fill-grey svg-icon"
    [class.cursor-pointer]="buttonIcon"
    [matTooltip]="suffixIconLabel | translate"
    [matRippleDisabled]="!buttonIcon"
    (click)="buttonIcon ? onClick('suffix') : null"
    [icon]="suffixIcon"
  ></slm-svg-icon>
  <h5 *ngIf="suffixLabel" class="suffix-label" matSuffix>
    {{ suffixLabel | translate }}
  </h5>
  <slm-svg-icon
    *ngIf="(type === 'search' || hasClearButton) && control && control.value"
    matSuffix
    class="fill-accent svg-icon cursor-pointer"
    matRipple
    [matRippleDisabled]="!buttonIcon"
    (click)="clear()"
    icon="close"
  ></slm-svg-icon>
  <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
  <mat-hint *ngIf="maxLength" align="end"
    >{{ control?.value?.length || 0 }} / {{ maxLength }}</mat-hint
  >

  <mat-error
    *ngIf="control && (control.dirty || control.touched) && control.invalid"
    >{{ getError() | translate: errorBody }}</mat-error
  >
</mat-form-field>
