import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  Bank,
  ColumnTableItem,
  CustomValidators,
  GeneralSandbox,
  Icon,
  inputErrorTypes,
  PartnerActiveStatusEnum,
  PartnerBankAccount,
  SelectOption, selectOptionTextSearch,
  TableAlign,
  utils
} from '../../../global';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, combineLatest, concat, Observable, of, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'slm-partner-bank-account',
  templateUrl: './partner-bank-account.form.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerBankAccountForm implements OnInit, OnDestroy {
  public readonly icons = Icon;
  public readonly inputErrors = [
    inputErrorTypes.iban,
    inputErrorTypes.required,
    inputErrorTypes.maxLength,
    inputErrorTypes.minLength,
    inputErrorTypes.digit,
    inputErrorTypes.onlyNumbers,
    inputErrorTypes.bankIdentifier
  ];
  public readonly searchFunction = selectOptionTextSearch;
  public readonly bankDetailTableConfig: Array<ColumnTableItem> = [
    {
      type: 'text',
      key: 'countryCode',
      value: 'LBL_PARTNERS.COUNTRY_CODE',
      align: TableAlign.CENTER
    },
    {
      type: 'text',
      key: 'swift',
      value: 'INVOICE.SWIFT',
      align: TableAlign.CENTER
    }
  ];

  public readonly bankAccountFormArray = this.fb.array([]);
  private accountIndex = 0;

  public readonly banks$ = new BehaviorSubject<Array<SelectOption>>([]);
  private readonly banks: Array<Bank> = [];
  public readonly bankIdHint$ = new BehaviorSubject<string>(null);

  public readonly ibanPrefixes$: Array<Observable<string>> = [];
  public readonly bankDataList$: Array<Observable<Bank>> = [];
  public readonly bankDestroys$: Array<Subject<any>> = [];
  public showBankFormArray: Array<Observable<boolean>> = [];

  @Input('accounts')
  public set setAccount(bankAccounts: Array<PartnerBankAccount>) {
    const existingFormBankAccounts: Array<number> = (this.bankAccountFormArray.getRawValue() || [])
      .map(value => +value.id)
      .filter(value => !!value);
    (bankAccounts || [])
      .filter(account => !existingFormBankAccounts.includes(account.id) && account.status === PartnerActiveStatusEnum.ACTIVE)
      .forEach((account) => this.newBankAccount(account));
  }

  @Input() public showButtons = true;
  @Input() public useStyle = true;

  @Output()
  public formArray = new EventEmitter<UntypedFormArray>();

  @Output()
  public deleted = new EventEmitter<number>();

  constructor(private readonly fb: UntypedFormBuilder,
              private readonly generalSandbox: GeneralSandbox,
              private changeDetection: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.formArray.emit(this.bankAccountFormArray);

    this.generalSandbox
      .getBanks(true)
      .pipe(
        map((banks) => banks?.items || []),
        tap(banks => this.banks.push(...banks))
      )
      .subscribe(banks => {
        const bankOptions = banks.map(bank => ({
          label: bank.name,
          subLabel: `${(bank.countryCode ?? '').toUpperCase()} - ${bank.swift}`,
          value: +bank.id,
          data: bank
        }));
        this.banks$.next(bankOptions);
      });

  }

  public getBankAccountById(id: number) {
    if (!id) {
      return null;
    }
    return this.banks$.getValue().find(bank => bank.value === id) ?? id;
  }

  public newBankAccount(bankAccount: PartnerBankAccount = null): void {
    const form = this.fb.group({
      index: [{value: this.accountIndex++, disabled: true}],
      id: [{value: +bankAccount?.id || null, disabled: true}],
      accountName: [bankAccount?.accountName || '', [Validators.maxLength(1024)]],
      accountNumber: [
        bankAccount?.accountNumber || '',
        [CustomValidators.onlyNumbers, CustomValidators.bankAccount]
      ],
      iban: [bankAccount?.iban?.substr(2) ?? '', [CustomValidators.bankAccountMinLength, CustomValidators.bankAccountMaxLength]],
      bankAccountId: [this.getBankAccountById(bankAccount?.bank?.id),
        [Validators.required, CustomValidators.autoCompleteObject]],
      default: [bankAccount?.default ?? this.setToDefault()],
      swift: [{value: '', disabled: true}, [Validators.required, Validators.minLength(2), Validators.maxLength(11)]],
      bankName: [{value: '', disabled: true}, [Validators.required]],
      countryCode: [{value: '', disabled: true}, [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      other: [bankAccount?.other || '', [Validators.maxLength(128)]]
    });

    const ibanControl = form.get('iban');
    const bankControl = form.get('bankAccountId');
    const accountNumberControl = form.get('accountNumber');

    if (bankAccount) {
      form.markAllAsTouched();
    }
    ibanControl.setAsyncValidators((control: AbstractControl) => {
      const accountNumber = utils.removeWhiteSpaces(form?.get('accountNumber')?.value ?? '');
      if (!accountNumber || !control.value || utils.removeWhiteSpaces(control.value).includes(accountNumber)) {
        return of(null);
      }
      return of({iban: {value: control.value}});
    });

    this.showBankFormArray.push(bankControl.valueChanges.pipe(
      map(bank => bank?.value === -1),
      distinctUntilChanged(),
      tap(show => {
        if (show) {
          form.get('countryCode').enable();
          form.get('bankName').enable();
          form.get('swift').enable();
        } else {
          form.patchValue({
            countryCode: '',
            bankName: '',
            swift: ''
          });
          form.get('countryCode').disable();
          form.get('bankName').disable();
          form.get('swift').disable();
        }
      })));
    const ibanPrefixes = combineLatest([
      concat(of(null), form.get('countryCode').valueChanges),
      concat(of(bankAccount?.bank?.id), bankControl.valueChanges)
    ]).pipe(
      debounceTime(150),
      filter(([countryCode, bank]) => !!countryCode || !!bank?.data?.countryCode),
      map(([countryCode, bank]) => {
        const prefix = (countryCode || bank.data?.countryCode).toUpperCase();
        return !ibanControl.value.includes(prefix) ? prefix : '';
      })
    );
    this.ibanPrefixes$.push(ibanPrefixes);
    const destroy = new Subject();

    concat(of(bankAccount?.iban?.substr(2) ?? ''), ibanControl.valueChanges).pipe(
      debounceTime(300),
      map(value => !!value),
      distinctUntilChanged(),
      takeUntil(destroy)
    ).subscribe(value => {
      bankControl.clearValidators();
      if (value) {
        bankControl.setValidators([Validators.required, CustomValidators.autoCompleteObject]);
      } else {
        bankControl.setValidators([CustomValidators.autoCompleteObject]);
      }
      bankControl.updateValueAndValidity();
    });

    /**
     * FormGroup item identification. Each bank account group on the frontend side has
     * a unique identifier (integer)
     *
     */
    const index = this.accountIndex - 1;

    /**
     * Only one account can be the default. When the currently edited account is set to default, this stream
     * verifies the other accounts and removes the default flag from everywhere.
     */
    form.get('default').valueChanges
      .pipe(
        filter(value => !!value),
        tap(() => this.bankAccountFormArray.controls.forEach((control) => {
          if (control.get('index').value !== index) {
            const defaultControl = control.get('default');
            if (defaultControl.value) {
              defaultControl.setValue(false);
            }
          }
        })),
        takeUntil(destroy)
      )
      .subscribe();

    this.bankDataList$.push(concat(of(bankControl.value), bankControl.valueChanges).pipe(
      debounceTime(150),
      map(selected => selected?.data)));

    this.initBankIdChanger(accountNumberControl, bankControl, 0, 3);
    this.initBankIdChanger(ibanControl, bankControl, 4, 7);

    this.bankDestroys$.push(destroy);
    this.bankAccountFormArray.push(form);
    this.changeDetection.detectChanges();
  }

  public deleteBankAccount(index: number): void {
    const form: UntypedFormGroup = this.bankAccountFormArray.at(index) as UntypedFormGroup;
    if (!!form) {
      const id = form.getRawValue().id;
      if (!!id) {
        this.deleted.emit(id);
      }
      this.bankAccountFormArray.removeAt(index);
      this.ibanPrefixes$.splice(index, 1);
      this.bankDestroys$[index].next(null);
      this.bankDestroys$[index].complete();
      this.bankDestroys$.splice(index, 1);
      this.bankDataList$.splice(index, 1);
      this.showBankFormArray.splice(index, 1);
    }
  }

  private setToDefault = () =>
    !this.bankAccountFormArray.controls.find((controls) => controls.get('default').value);

  public clearAllBankAccounts(){
    this.bankAccountFormArray.clear();
    this.showBankFormArray.length = 0;
    this.ibanPrefixes$.length = 0;
    this.bankDestroys$.forEach(destroy$ => {
      destroy$.next(null);
      destroy$.complete();
      this.bankDestroys$.length = 0;
    });
  }

  private initBankIdChanger(numberControl: AbstractControl, bankIdControl: AbstractControl, fromIndex: number, toIndex: number) {
    const destroy = new Subject();

    numberControl.valueChanges.pipe(
      debounceTime(5),
      map(accountNumber =>  utils.removeWhiteSpaces(accountNumber)),
      filter(accountNumber =>  accountNumber.length >= toIndex),
      map(accountNumber => accountNumber.slice(fromIndex, toIndex)),
      tap(accountNumber => {
        const foundBankId = ((this.banks.filter(bank => bank.giro === accountNumber)[0] || []) as Bank)?.id;
        this.bankIdHint$.next(null);
        if(foundBankId){
          if(!bankIdControl.value?.value){
            bankIdControl.setValue(this.getBankAccountById(foundBankId));
          }else if(foundBankId !== bankIdControl.value?.value){
            this.bankIdHint$.next('MESSAGE.BANK_NOT_EQUALS_ACCOUNT');
          }
        }
      }),
      takeUntil(destroy)
    ).subscribe();

    this.bankDestroys$.push(destroy);
  }

  ngOnDestroy() {
    this.bankDestroys$.forEach(destroy$ => {
      destroy$.next(null);
      destroy$.complete();
    this.bankDestroys$.length = 0;
    });
  }
}
