import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'adminInternalMessage'
})
export class AdminInternalMessagePipe implements PipeTransform{
  constructor(
    private readonly translateService: TranslateService
  ) {}

  transform(type: string, message: string, name: string): any{
    return `${this.translateService.instant(type)} ${message? ` - ${message}`: ''} ${name? `- ${name}` : ''}`;
  }
}

@Pipe({
  name: 'invoiceInternalMessage'
})
export class InvoiceInternalMessagePipe implements PipeTransform{
  transform(message: string, name: string): any{
    return `${message} ${name? `- ${name}` : ''}`;
  }
}
