import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'slm-counter',
  template: `
    <div class="counter"
         *ngIf="counter"
         [class.counter--no-shadow]="!useShadow"
    >
      <div
        *ngIf="+counter.index > 1"
        class="counter--button fill-accent"
        matRipple
        data-test-id="counter-prev-btn"
        (click)="fireEvent.next('previous')"
      >
        <slm-svg-icon icon="arrow_left"></slm-svg-icon>
      </div>
      <div class="counter--content">
        <h4 *ngIf="counter.label">{{ counter.label | translate: counter }}</h4>
        <h4 *ngIf="!counter.label">{{ counter.index }}/{{ counter.count }}</h4>
      </div>
      <div
        *ngIf="+counter.index < counter.count"
        data-test-id="counter-next-btn"
        class="counter--button fill-accent"
        matRipple
        (click)="fireEvent.next('next')"
      >
        <slm-svg-icon icon="arrow_right"></slm-svg-icon>
      </div>
    </div>
  `,
  styleUrls: ['./counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CounterComponent {
  @Input() public counter: {
    count: number;
    index: number;
    label?: string;
  } = null;
  @Input() public useShadow = true;

  @Output() public readonly fireEvent = new EventEmitter<string>();
}
