export type InputTypeEnum =
  | 'text'
  | 'password'
  | 'number'
  | 'email'
  | 'tel'
  | 'url'
  | 'search';

export type ButtonTypeEnum = 'button' | 'submit' | 'reset';

export type ButtonStyleEnum =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'light'
  | 'transparent'
  | 'transparent-blue'
  | 'approve'
  | 'warning-bordered'
  | 'secondary-blue';
export type ButtonHeightEnum = 'normal' | 'small' | 'tiny' | 'large';
