import {SelectOption} from '../../../modules/global';

export const whereToSeeOptions: Array<SelectOption> = [
  {
    value: 'facebook',
    label: 'AUTHENTICATION.REGISTRATION.WHERE_TO_SEE_OPTIONS.FACEBOOK'
  },
  {
    value: 'youtube',
    label: 'AUTHENTICATION.REGISTRATION.WHERE_TO_SEE_OPTIONS.YOUTUBE'
  },
  {
    value: 'google_ad',
    label: 'AUTHENTICATION.REGISTRATION.WHERE_TO_SEE_OPTIONS.GOOGLE_AD'
  },
  {
    value: 'linkedin',
    label: 'AUTHENTICATION.REGISTRATION.WHERE_TO_SEE_OPTIONS.LINKEDIN'
  },
  {
    value: 'conference',
    label: 'AUTHENTICATION.REGISTRATION.WHERE_TO_SEE_OPTIONS.CONFERENCE'
  },
  {
    value: 'voszport',
    label: 'AUTHENTICATION.REGISTRATION.WHERE_TO_SEE_OPTIONS.VOSZPORT'
  },
  {
    value: 'szamlazz',
    label: 'AUTHENTICATION.REGISTRATION.WHERE_TO_SEE_OPTIONS.SZAMLAZZ'
  },
  {
    value: 'billzone',
    label: 'AUTHENTICATION.REGISTRATION.WHERE_TO_SEE_OPTIONS.BILLZONE'
  },
  {
    value: 'other',
    label: 'AUTHENTICATION.REGISTRATION.WHERE_TO_SEE_OPTIONS.OTHER'
  }
];
