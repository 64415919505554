import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../../global';

@Pipe({
  name: 'name'
})
export class NamePipe implements PipeTransform {
  transform(user: User | any): any {
    if (user) {
      if (!user.firstName || !user.lastName) {
        return user.firstName || user.lastName;
      } else {
        return `${user.firstName} ${user.lastName}`;
      }
    }
    return 'Unknown user';
  }
}
