import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Icons } from '../../../../global';

@Component({
  selector: 'slm-button-icon',
  template: `
    <button
      class="slm-btn-icon transition"
      [attr.data-test-id]="testId"
      *ngIf="!loading"
      matRipple
      [disabled]="disable"
      [class.slm-btn-icon--rounded]="rounded"
      [class.slm-btn-icon--elevation]="elevation"
      [class.slm-btn-icon--elevation-default]="elevationDefault"
      [class.slm-btn-icon--text]="text"
      [class.slm-btn-icon--svg]="icon"
      [class.slm-btn-icon--disable]="disable"
    >
      <slm-svg-icon
        *ngIf="icon"
        [size]="size"
        [icon]="icon"
        [matBadge]="badgeCount"
        matBadgeOverlap="true"
        matBadgeColor="warn"
        [ngStyle]="{width: size, height: size}"
        [matBadgeHidden]="!badgeCount"
        matBadgeSize="small"
      ></slm-svg-icon>
      <h3
        *ngIf="text"
        class="m-0"
        [matBadge]="badgeCount"
        matBadgeOverlap="true"
        matBadgeColor="warn"
        [matBadgeHidden]="!badgeCount"
      >
        {{ text | translate }}
      </h3>
    </button>
    <div *ngIf="loading" class="slm-btn-icon slm-btn-icon--svg transition">
      <mat-spinner
        [diameter]="loadingDiameter"
        color="accent"
        [matBadge]="badgeCount"
        matBadgeOverlap="true"
        matBadgeColor="warn"
        [matBadgeHidden]="!badgeCount"
      ></mat-spinner>
    </div>
  `,
  styles: [
    `
           :host {
             display: block;
           }
         `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonIconComponent {
  @HostBinding('style.border-radius') radius = '50%';

  rounded = true;

  @Input() public icon: Icons | string = null;
  @Input() public loading = false;
  @Input() public text = '';
  @Input() public elevation = false;
  @Input() public elevationDefault = false;
  @Input() public disable = false;
  @Input() public loadingDiameter = 22;
  @Input() public badgeCount = null;
  @Input() public testId = '';
  @Input() public size = '24px';

  @Input('rounded')
  public set setRounded(rounded: boolean) {
    this.radius = rounded ? '50%' : '4px';
    this.rounded = rounded;
  }
}
