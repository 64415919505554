import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {distinctUntilChanged, filter, map, skip} from 'rxjs/operators';
import {globalActions, globalSelectors} from '../+states';
import {PartnerService} from '../services/partner.service';
import {PaginatorNew, PartnerSave, TableOrder} from '../entities';
import {CompanyService} from '../services';
import {combineLatest} from 'rxjs';
import {CompanyNavSettings, PublicCompanyNavSettings} from '../../settings/entities/company-data.entity';
import {PartnerActiveStatusEnum} from '../enums';

@Injectable({
  providedIn: 'root'
})
export class CompanySandbox {
  constructor(
    private readonly store: Store,
    private readonly companyService: CompanyService,
    private readonly partnerService: PartnerService
  ) {
  }

  public readonly clearCurrency = () => this.store.dispatch(globalActions.clearCompanyCurrency());

  public readonly activeCompanyId$ = this.store.select(
    globalSelectors.getCompanyId()
  );

  public readonly lastNavUpdate$ = this.store.select(
    globalSelectors.getLastNavUpdate()
  );

  public readonly companies$ = this.store.select(globalSelectors.getCompanies());

  public readonly activeCompany$ = this.store.select(
    globalSelectors.getCompany()
  );

  public readonly companyCurrency$ = combineLatest([
    this.store.select(globalSelectors.getCompanyId()),
    this.store.select(globalSelectors.getCompanyCurrency())
  ]).pipe(
    filter(([companyId, currency]) => {
      if (!currency && !!companyId) {
        this.store.dispatch(globalActions.fetchCompanyCurrency());
      }
      return !!currency;
    }),
    map(([_, currency]) => currency)
  );

  public readonly companyVatCodes$ = this.store.select(globalSelectors.getVatCodes()).pipe(
    filter(vatCodes => {
      if (!vatCodes) {
        this.store.dispatch(globalActions.loadVatCodes());
      }
      return !!vatCodes;
    })
  );

  public readonly hasExternalSystem$ = this.store.select(
    globalSelectors.hasExternalSystem()
  );

  public readonly companyChanged$ = this.activeCompanyId$.pipe(
    filter((id) => !!id),
    distinctUntilChanged(),
    skip(1)
  );

  public readonly changeCompany = (companyId: number) => {
    this.store.dispatch(globalActions.changeCompany({companyId}));
  };

  public readonly getPartners = (
    page: PaginatorNew,
    order: TableOrder,
    search: string = null,
    type: PartnerActiveStatusEnum = PartnerActiveStatusEnum.ACTIVE
  ) => this.partnerService.getPartners(page, order, search, type);

  public readonly getPartner = (id: number) => this.partnerService.getPartner(id);

  public readonly getCurrencies = () => this.companyService.getCurrencies();

  public readonly getCompanyCurrencies = () =>
    combineLatest([
      this.store.select(globalSelectors.getCompanyId()),
      this.store.select(globalSelectors.getCompanyCurrencies())
    ]).pipe(
      filter(([companyId, currencies]) => {
        if (!currencies && !!companyId) {
          this.store.dispatch(globalActions.fetchCompanyCurrencies());
        }
        return !!currencies;
      }),
      map(([_, currencies]) => currencies)
    );

  public readonly clearCompanyCurrencies = () =>
    this.store.dispatch(globalActions.clearCompanyCurrencies());

  public readonly getSubsidiaries = (detailed: boolean) => this.companyService.getSubsidiaries(detailed);

  public readonly searchPartnerBy = (paginator: PaginatorNew, search: string) =>
    this.partnerService.searchPartnerBy(paginator, search);

  public readonly partnerBankAccounts = (paginator: PaginatorNew, partnerId: number) =>
    this.partnerService.partnerBankAccounts(paginator, partnerId);

  public readonly saveCompany = (company) => this.companyService.saveCompany(company);

  public readonly getCompanyActivation = () =>
    this.companyService.getCompanyActivation();

  public readonly clearVatCodes = () => this.store.dispatch(globalActions.clearVatCodes());

  public validateEuTaxNumber = (taxNumber: string) => this.companyService.validateExTaxNumber(taxNumber?.trim())
    .pipe(
      map(response => {
        if (response.isValid) {
          return null;
        }
        return {euTaxNumber: {value: taxNumber}};
      }));

  public checkEuTaxNumber = (taxNumber: string, companyId: string | number) => this.companyService.validateExTaxNumber(taxNumber?.trim(), companyId);

  public readonly refreshPartner = (partnerId: number) => this.partnerService.refreshPartner(partnerId);

  public readonly checkNavData = (data: CompanyNavSettings) =>
    this.companyService.checkNavData(data);

  public readonly publicCheckNavData = (data: PublicCompanyNavSettings) =>
    this.companyService.checkPublicNavData(data);

  public readonly savePartner = (partner: PartnerSave) =>
    this.partnerService.savePartner(partner);
}
