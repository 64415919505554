export type Icons = typeof Icon[keyof typeof Icon];
export enum Icon {
  ATTACH_FILE = 'attach_file',
  ACCOUNT = 'account',
  ADMIN_PANEL_SETTINGS = 'admin_panel_settings',
  ADD_CIRCLE = 'add_circle',
  ANALYTICS = 'analytics',
  ARCHIVE = 'archive',
  ARROW_DOWN = 'arrow_down',
  ARROW_UP = 'arrow_up',
  ARROW_RIGHT = 'arrow_right',
  ARROW_LEFT = 'arrow_left',
  ARTICLE = 'article',
  ASSIGMENT_LATE = 'assignment_late',
  BACKUP = 'backup',
  BANKING = 'banking',
  BLOCK = 'block',
  BUILT = 'built',
  CALL_SPLIT = 'call_split',
  CATEGORY = 'category',
  CANCEL_CIRCLE = 'cancel-circle',
  CHECK_CIRCLE = 'check_circle',
  CHECKLIST = 'checklist',
  CLOSE = 'close',
  CONTENT_COPY = 'content_copy',
  CREATE = 'create',
  CREATE_NEW_FOLDER = 'create_new_folder',
  CLOUD = 'cloud',
  DELETE = 'delete',
  DESCRIPTION = 'description',
  DOCUMENT_SEARCH = 'document_search',
  DO_DISTURB_OFF = 'do_disturb_off',
  DO_DISTURB_ON = 'do_disturb_on',
  DONE = 'done',
  DOWNLOAD = 'download',
  DRAG_AND_DROP = 'drag_and_drop',
  DRAG_AND_DROP_CURSOR = 'drag_and_drop_cursor',
  DRIVE_FILE_RENAME = 'drive_file_rename',
  EMAIL = 'email',
  ERROR = 'error',
  EXCEL_EXPORT = 'excel_export',
  EXPORT = 'export',
  FACT_CHECK = 'fact_check',
  FILE_COPY = 'file_copy',
  FILTER = 'filter',
  FINANCES = 'finances',
  FOLDER = 'folder',
  GRADE = 'grade',
  HOME = 'home',
  IMAGE = 'image',
  IMPORT_EXPORT = 'import-export',
  INCOME = 'income',
  INSERT_DRIVE_FILE = 'insert_drive_file',
  INFO = 'info',
  JUDGE = 'judge',
  LIBRARY_ADD_CHECK = 'library_add_check',
  MENU = 'menu',
  MERGE_TYPE = 'merge_type',
  MONETIZATION_ON = 'monetization_on',
  MORE = 'more',
  NAVIGATE_NEXT = 'navigate_next',
  NAVIGATE_PREVIOUS = 'navigate_previous',
  NOTIFICATIONS = 'notifications',
  NOTIFICATIONS_ACTIVE = 'notifications_active',
  NOTIFICATION_SETTINGS = 'notification-settings',
  OPEN_IN_NEW = 'open_in_new',
  OUTGOING = 'outgoing',
  PAUSE = 'pause',
  PASSWORD_SETTINGS = 'password-settings',
  PAYMENTS = 'payments',
  PEOPLE = 'people',
  PHOTO = 'photo',
  PICTURE = 'picture',
  PICTURE_AS_PDF = 'picture_as_pdf',
  PLAY = 'play',
  PRINT = 'print',
  PRIORITY_HIGH = 'priority_high',
  PROFILE = 'profile',
  RECEIPT = 'receipt',
  REFRESH = 'refresh',
  REMOVE_CIRCLE = 'remove_circle',
  REMOVE_DONE = 'remove_done',
  ROTATE_LEFT = 'rotate_left',
  ROTATE_RIGHT = 'rotate_right',
  SAVE = 'save',
  SAVE_ALT = 'save_alt',
  SEARCH = 'search',
  SECURITY = 'security',
  SETTINGS = 'settings',
  SHOW_CHART = 'show_chart',
  SORT = 'sort',
  SUCCESS = 'success',
  STOP = 'stop',
  STORAGE = 'storage',
  SYNC = 'sync',
  SYNC_ALT = 'sync_alt',
  TABLE = 'table',
  TIME = 'time',
  TODAY = 'today',
  UNARCHIVE = 'unarchive',
  UPLOAD = 'upload',
  UPLOAD_FILE = 'upload_file',
  UPDATE = 'update',
  PARTNERS = 'partners',
  UNKNOWN_DOCUMENT = 'unknown_document',
  VISIBILITY = 'visibility',
  VISIBILITY_OFF = 'visibility-off',
  WARNING = 'warning'
}
