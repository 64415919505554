import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RouteInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      environment.apiUrl &&
      !req.url.startsWith('/assets/') &&
      !req.url.startsWith('https://')
    ) {
      req = req.clone({
        url: environment.apiUrl + req.url
      });
    } else {
      req = req.clone({
        url: req.url
      });
    }

    return next.handle(req);
  }
}
