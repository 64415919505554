import { ButtonConfig } from '../../../global';

export const deleteDialogButtons: Array<ButtonConfig> = [
  {
    label: 'LBL_BTN.CANCEL',
    action: 'cancel',
    buttonStyle: 'secondary',
    testId: 'cancel'
  },
  {
    label: 'LBL_BTN.DELETE',
    action: 'delete',
    buttonStyle: 'error',
    testId: 'delete'
  }
];
