<div *ngIf="date" class="w-100 pos-relative m-auto p-0"
     [style.marginBottom]="marginBottom"
     [class.w-max-container]="!fullWidth">
    <h5 class="m-auto p-0 mb-2 flex flex-end w-100 last-update color-white txt-end" >
      {{'LAST_UPDATE' | translate}}
      <b class="txt-medium">
        {{date | dateTransform: 'FORMAT.DATE_FULL' }}
      </b>
    </h5>
  </div>
