import { Component, Input } from '@angular/core';
import { ChartOptions } from 'chart.js';

@Component({
  selector: 'slm-chart',
  template: `
    <div class="chart" [style.height.px]="height">
      <canvas
        baseChart
        [style.maxHeight.px]="chartMaxHeight"
        [datasets]="data"
        [labels]="labels"
        [options]="options"
        [legend]="legend"
        [type]="type"
        [plugins]="plugins"
      >
      </canvas>
    </div>
  `
})
export class ChartComponent {
  @Input() public data: Array<any> = null;
  @Input() public labels: Array<string | Array<string>> | any = null;
  @Input() public options: ChartOptions | any = null;
  @Input() public legend = false;
  @Input() public colors: Array<any> = null;
  @Input() public type:
    | 'line'
    | 'bar'
    | 'horizontalBar'
    | 'radar'
    | 'doughnut'
    | 'polarArea'
    | 'bubble'
    | 'pie'
    | 'scatter' = 'line';
  @Input() public plugins: any = null;
  @Input() public height: number = null;
  @Input() public chartMaxHeight: number = null;
}
