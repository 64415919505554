import {Company, Currency, General, ListResponseNew, SystemNotification, User, VatCode} from '../entities';
import {globalActions} from './global.actions';
import {Action, createReducer, on} from '@ngrx/store';
import {StoreReducer} from '../entities/store';
import {DefaultLedgerNumber} from '../../accounting-settings/entities/default-invoice-entity';

export interface GlobalState {
  isAuthenticated: boolean;
  user: User;
  authToken: string;
  refreshToken: string;
  language: string;
  currency: Currency;
  company: Company;
  companyCurrencies: Array<Currency>;
  general: General;
  notification: {
    systemNotifications: Array<SystemNotification>;
    notifications: ListResponseNew<SystemNotification>;
  };
  vatCodes: Array<VatCode>;
  defaultLedgerNumbers: {
    vendor: DefaultLedgerNumber;
    customer: DefaultLedgerNumber;
    cash: DefaultLedgerNumber;
    accrual: DefaultLedgerNumber;
  };
  viewDetails: {
    incoming: Array<string>;
    outgoing: Array<string>;
  };
}

export const initialState: GlobalState = {
  isAuthenticated: false,
  user: null,
  authToken: null,
  refreshToken: null,
  language: 'hu',
  currency: null,
  company: null,
  companyCurrencies: null,
  general: {
    notificationCount: 0,
    syncLocation: 'all',
    autoRegNumber: false,
    vatFrequency: null,
    defaultAccrual: false,
    externalSystemId: null,
    storage: {
      enabled: false,
      total: null,
      used: null
    },
    useDivision: false,
    bank: false
  },
  notification: {
    systemNotifications: [],
    notifications: null
  },
  vatCodes: null,
  defaultLedgerNumbers: {
    vendor: null,
    customer: null,
    cash: null,
    accrual: null
  },
  viewDetails: {
    incoming: null,
    outgoing: null
  }
};

const globalReducerFunction = (
  state: GlobalState | undefined = initialState,
  action: Action
): GlobalState => {
  const reducer = createReducer(
    initialState,
    on(globalActions.setLanguage, (state, action) => ({
      ...state,
      language: action.language || state.language
    })),
    on(globalActions.loadUser, (state, action) => ({
      ...state,
      user: {...state.user, ...action.user},
      isAuthenticated: true
    })),
    on(globalActions.clearUserState, (state, _) => ({
      ...state,
      user: initialState.user
    })),
    on(globalActions.loadCompany, (state, action) => ({
      ...state,
      company: action.partner
    })),
    on(globalActions.clearGlobalState, (state, _) => ({
      ...initialState,
      language: state.language
    })),
    on(globalActions.setTokenAndCompanyId, (state, action) => ({
      ...state,
      user: {...state.user, activeCompanyId: action.companyId},
      authToken: action.token
    })),
    on(globalActions.setAuthentication, (state, action) => ({
      ...state,
      authToken: action.authorization.authToken,
      refreshToken: action.authorization.refreshToken,
      user: action.authorization.user,
      isAuthenticated: true
    })),
    on(globalActions.setToken, (state, action) => ({
      ...state,
      authToken: action.token,
      isAuthenticated: true
    })),
    on(globalActions.setAuthorizationData, (state, action) => ({
      ...state,
      authToken: action.token,
      refreshToken: action.refreshToken,
      isAuthenticated: true
    })),
    on(globalActions.loadCompanyCurrency, (state, action) => ({
      ...state,
      currency: action.currency
    })),
    on(globalActions.clearCompanyCurrency, (state, _) => ({
      ...state,
      currency: initialState.currency
    })),
    on(globalActions.loadCompanyCurrencies, (state, action) => ({
      ...state,
      companyCurrencies: action.currencies
    })),
    on(globalActions.clearCompanyCurrencies, (state, _) => ({
      ...state,
      companyCurrencies: initialState.companyCurrencies
    })),
    on(globalActions.setGeneral, (state, action) => ({
      ...state,
      general: action.general
    })),
    on(globalActions.setSystemNotifications, (state, action) => ({
      ...state,
      notification: {
        ...state.notification,
        systemNotifications: action.systemNotifications
      }
    })),
    on(globalActions.setNotifications, (state, action) => ({
      ...state,
      notification: {
        ...state.notification,
        notifications:
          action.response?.paginator?.page === 1
            ? action.response
            : {
              paginator:
                action.response?.paginator ??
                state.notification.notifications.paginator,
              items: [
                ...(state.notification?.notifications?.items ?? []),
                ...action.response.items
              ]
            }
      }
    })),
    on(globalActions.setVatCodes, (state, action) => ({
      ...state,
      vatCodes: action.vatCodes
    })),
    on(globalActions.clearVatCodes, (state, _) => ({
      ...state,
      vatCodes: null
    })),
    on(globalActions.clearDefaultLedgerNumber, (state, action) => ({
      ...state,
      defaultLedgerNumber: {
        ...state.defaultLedgerNumbers,
        [action.ledgerNumberType]: null
      }
    })),
    on(globalActions.setDefaultLedgerNumber, (state, action) => ({
      ...state,
      defaultLedgerNumbers: {
        ...state.defaultLedgerNumbers,
        [action.ledgerNumberType]: action.ledgerNumber
      }
    })),
    on(globalActions.clearAllDefaultLedgerNumber, (state, _) => ({
      ...state,
      defaultLedgerNumbers: initialState.defaultLedgerNumbers
    })),
    on(globalActions.clearUseOnTransactionStatus, (state, _) =>
      ({
        ...state,
        general: {
          ...state.general,
          useOnTransactionStatus: null
        }
      })),
    on(globalActions.setInvoiceListSettings, (state, action) =>
      ({
        ...state,
        viewDetails: {
          ...state.viewDetails,
          [action.target]: action.settings
        }
      }))
  );
  return reducer(state, action);
};

export const globalReducer: StoreReducer<GlobalState> = {
  name: 'global',
  reducer: globalReducerFunction
};
