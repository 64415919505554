<slm-dialog-container
  [title]="'NOTIFICATION.SYSTEM_NOTIFICATIONS'"
  [buttons]="buttons"
  [disabledButtons]="disabledButtons$ | async"
  class="w-max-100p block w-500px"
  (fireEvent)="buttonEvent($event.event, notificationItemIds)"
>
  <div class="flex flex-column">
    <ng-container *ngIf="!data.showAll; else withStepper">
      <div *ngFor="let notification of notificationItems || []; trackBy: trackById" class="pt-2 pb-2">
        <h5 class="txt-start-i">{{ notification.text }}</h5>
      </div>
    </ng-container>

  </div>
</slm-dialog-container>


<ng-template #withStepper>
  <div
    #textContainer
    cdk-scrollable
    class="block mt-5 overflow-y-auto h-max-250"
    (scroll)="onScroll($event)"
  >
    <ng-container *ngFor="let notification of notificationItems; index as index">
      <ng-container [ngSwitch]="notification.type">
        <ng-container *ngSwitchCase="'text'">
          <h5 class="txt-start-i">{{ notification.text }}</h5>
        </ng-container>
        <ng-container *ngSwitchCase="'html'">
          <slm-template-presenter [template]="notification.text"></slm-template-presenter>
        </ng-container>
      </ng-container>
      <hr *ngIf="notificationItems.length > (index + 1)" class="mt-4 mb-4">
    </ng-container>
  </div>
</ng-template>
