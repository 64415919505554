import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {concat, Observable, of, Subject} from 'rxjs';
import {
  buttonsStaticConfig,
  FileManagerRightType,
  FileManagerRightTypeEnum,
  Folder,
  Icon,
  inputErrorTypes,
  SelectOption,
  UserRightsData,
  UserSandbox
} from '../../../global';
import {rightOptions} from './folder-edit-dialog.component.config';
import {filter, first, map, takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'slm-folder-edit-dialog',
  templateUrl: './folder-edit-dialog.component.html'
})
export class FolderEditDialogComponent implements OnInit, OnDestroy {
  public readonly buttons = [buttonsStaticConfig.close, buttonsStaticConfig.save];
  public readonly errors = [inputErrorTypes.required];
  public readonly rightOptions = rightOptions;
  public readonly icons = Icon;

  public dialogTitle: string;
  public form: FormGroup<{
    name: FormControl<string>;
    isPrivate: FormControl<boolean>;
    rights: FormArray;
  }>;
  public arrayItems: FormGroup[];
  public userOptions: Array<SelectOption>;
  public disableRightEdit: boolean;

  public showRights$: Observable<boolean>;

  private destroy$ = new Subject();

  constructor(
    private readonly fb: FormBuilder,
    private readonly userSandbox: UserSandbox,
    public readonly dialogRef: MatDialogRef<FolderEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      folder?: Folder,
      userOptionsList:  Array<SelectOption>,
      currentFolderRights: Array<UserRightsData>,
      save: (any) => Observable<any>;
    }) {
  }

  ngOnInit() {
    this.dialogTitle = this.data.folder ? 'FILE_MANAGER.EDIT_FOLDER' : 'FILE_MANAGER.ADD_NEW_FOLDER';

    this.form = this.fb.group({
      name: [this.data.folder ? this.data.folder?.name : '', [Validators.required]],
      isPrivate: [this.data.folder ? this.data.folder?.isPrivate : false],
      rights: this.fb.array([])
    });

    this.userSandbox.user$.pipe(
      first(),
      tap(user => {
        this.userOptions = this.data.userOptionsList
          .filter(option =>
            this.data.currentFolderRights.length === 0 ?
              option?.data?.id !== user?.id :
            this.data.currentFolderRights.some(right => {
              return right?.id === option?.value;
            })
          );
        this.disableRightEdit = this.data?.folder ? this.data?.folder?.uploader?.id !== user?.id : false;
      })
    ).subscribe();

    of((this.data.folder?.rights || [])).pipe(
      map((data) => {
        return data.map(right => {
          this.disableUserListOption(right.id);
          this.addRightRow(right);
          return (
            {
              ...right,
              right: right.right,
              form: this.fb.control(right.right)
            });
        });
      }),
      takeUntil(this.destroy$)
    ).subscribe();

    this.showRights$ = concat(
      of(this.data?.folder?.isPrivate),
      this.form.get('isPrivate').valueChanges
    ).pipe(
      map(value => !value),
      tap(value => {
        if (!value) {
          this.form.get('rights').disable();
        }else {
          this.form.get('rights').enable();
        }
        this.form.get('rights').updateValueAndValidity();
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  buttonClicked(event: string) {
    if (event === 'close') {
      this.dialogRef.close();
      return;
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const isPrivate = this.form.get('isPrivate').value;

    const dataToSend = {
      ...this.form.getRawValue(),
      rights: !isPrivate ? this.form.get('rights').getRawValue() : []
    };

    this.buttons[1].isLoading = true;
    this.dialogRef.disableClose = true;
    this.data.save(dataToSend).subscribe({
      next: (response) => {
        this.buttons[1].isLoading = false;
        this.dialogRef.disableClose = false;
        const formValue = {
          ...this.form.getRawValue(),
          rights: !isPrivate ? [...this.form.get('rights').getRawValue().map(value => ({right: value.right, id: value.user}))] : []

        };
        this.dialogRef.close({...formValue, ...response});
      },
      error: () => {
        this.buttons[1].isLoading = false;
        this.dialogRef.disableClose = false;
      }
    });
  }

  addRightRow(data?: UserRightsData) {
    const rightRowForm: FormGroup<{
      user: FormControl<number>;
      right: FormControl<FileManagerRightType>;
    }> = this.fb.group({
      user: [{value: data?.id || null, disabled: !!data?.id}, [Validators.required]],
      right: [{value: data?.right || FileManagerRightTypeEnum.READ, disabled: this.disableRightEdit}, [Validators.required]]
    });

    rightRowForm.get('user').valueChanges.pipe(
      filter(value => !!value),
      tap(id => this.disableUserListOption(id)),
      takeUntil(this.destroy$)
    ).subscribe();

    const rightFormArray =this.form.get('rights') as FormArray;
    rightFormArray.push(rightRowForm);
    this.arrayItems = rightFormArray.controls as FormGroup[];
  }

  deleteRightRow(deletedIndex) {
    const rightFormArray =this.form.get('rights') as FormArray;
    const rightRow = rightFormArray.at(deletedIndex).getRawValue();
    this.enableUserListOption(rightRow.user);
    rightFormArray.removeAt(deletedIndex);

    this.arrayItems.splice(deletedIndex, 1);
  }

  private disableUserListOption(id: number) {
    if(id){
      this.userOptions = this.userOptions.map(option => {
        return {...option, disabled: option.value === id || option?.disabled};
      });
    }
  }

  private enableUserListOption(id: number) {
    if(id){
      this.userOptions = this.userOptions.map(option => {
        return {...option, ...(id === option?.value ? { disabled: false} : null)};
      });
    }
  }
}
