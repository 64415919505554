<div class="pr-3 pl-3">
  <mat-chip-listbox
    *ngIf="selectedValues$ | async as values"
    class="pt-3 pb-2 flex flex-start flex-wrap"
  >

    <ng-container *ngFor="let item of values; let i = index">
      <ng-container *ngIf="!item.isList">
        <mat-chip-option
          removable
          color="primary"
          class="mr-1 ml-1 box-shadow-mini-hover"
          [attr.data-test-id]="'filter-' + i"
          [matTooltip]="item.tooltip | translate"
          (removed)="resetFilter(item.key)"
        >
          <span class="color-white txt-medium">{{ item.label | translate }}</span>
          <slm-svg-icon
            *ngIf="!item.disableDelete"
            icon="close"
            matChipRemove
            size="20px"
            class="fill-white"
          ></slm-svg-icon>
        </mat-chip-option>
      </ng-container>
      <ng-container *ngIf="item.isList">
        <mat-chip-option
          *ngFor="let selected of item.label; let index = index"
          removable
          color="primary"
          [attr.data-test-id]="'filter-'+ index"
          class="mr-1 ml-1 box-shadow-mini-hover"
          [matTooltip]="item.tooltip | translate"
          (removed)="removeSelectValue(item.key, index)"
        >
          <span class="color-white txt-medium">{{ selected | translate }}</span>
          <slm-svg-icon
            icon="close"
            matChipRemove
            size="20px"
            class="fill-white"
          ></slm-svg-icon>
        </mat-chip-option>
      </ng-container>
    </ng-container>
    <mat-chip-option *ngIf="showClearButton" (click)="resetFilters()" color="warn"
              data-test-id="clearFilters"
              class="mr-1 ml-1 box-shadow-mini-hover bg-color-white-i border-solid-i border-color-accent-i border-2-i">
      <span class="color-accent txt-medium">{{ 'CLEAR' | translate }}</span>
    </mat-chip-option>
  </mat-chip-listbox>
  <form
    @dropdownFade
    *ngIf="isOpen"
    [formGroup]="form"
    [class.mt-2]="!isFloating"
    [ngClass]="{'border-bottom p-2 pos-absolute right-0': isFloating}"
    class="grid-col-{{columnCount}} grid-responsive-col-2 z-index-10 bg-color-white left-0"
  >
    <ng-container *ngFor="let conf of config">
      <ng-container *slmAccess="conf.rights">
        <ng-container [ngSwitch]="conf.type">
          <slm-select-input
            *ngSwitchCase="'select'"
            [control]="form.get(conf?.config?.name)"
            [label]="conf?.config?.label"
            [options]="conf?.config?.options"
            hideOptionalLabel="true"
            [hint]="conf?.config?.hint"
            coloredValue="true"
            [testId]="conf?.config?.testId"
            [placeholder]="conf?.config?.placeholder"
          ></slm-select-input>

          <slm-select-input
            *ngSwitchCase="'multi-select'"
            multiple="true"
            coloredValue="true"
            hideOptionalLabel="true"
            [control]="form.get(conf?.config?.name)"
            [testId]="conf?.config?.testId"
            [label]="conf?.config?.label"
            [options]="conf?.config?.options"
            [hint]="conf?.config?.hint"
            [placeholder]="conf?.config?.placeholder"
          ></slm-select-input>

          <slm-text-input
            *ngSwitchCase="'text'"
            [label]="conf?.config?.label"
            [type]="conf?.config?.inputType"
            [hint]="conf?.config?.hint"
            hideOptionalLabel="true"
            coloredValue="true"
            [prefixIcon]="conf?.config?.prefixIcon"
            [testId]="conf?.config?.testId"
            [suffixIconLabel]="conf?.config?.suffixLabel"
            [suffixIcon]="conf?.config?.suffixIcon"
            [placeholder]="conf?.config?.placeholder"
            [control]="form.get(conf?.config?.name)"
          ></slm-text-input>

          <slm-date-input
            *ngSwitchCase="'date'"
            [label]="conf?.config?.label"
            [hint]="conf?.config?.hint"
            coloredValue="true"
            hideOptionalLabel="true"
            [suffixIconLabel]="conf?.config?.suffixLabel"
            [min]="conf?.config?.min"
            [testId]="conf?.config?.testId"
            [max]="conf?.config?.max"
            [prefixIcon]="conf?.config?.prefixIcon"
            [placeholder]="conf?.config?.placeholder"
            [control]="form.get(conf?.config?.name)"
          ></slm-date-input>

          <slm-date-range-input
            *ngSwitchCase="'date-range'"
            [label]="conf?.config?.label"
            hideOptionalLabel="true"
            [hint]="conf?.config?.hint"
            coloredValue="true"
            [suffixIconLabel]="conf?.config?.suffixLabel"
            [prefixIcon]="conf?.config?.prefixIcon"
            [groupControl]="form.get(conf?.config?.name)"
            [firstTestId]="conf?.config?.testId + 'First'"
            [secondTestId]="conf?.config?.testId + 'Second'"
            controlEnd="end"
            controlStart="start"
          ></slm-date-range-input>
          <div *ngSwitchCase="'number-range'"
               class="flex flex-center w-100">
            <slm-amount-input [control]="form.get(conf?.config?.name).get('start')"
                              class="w-50"
                              [testId]="conf?.config?.testId"
                              coloredValue="true"
                              hideOptionalLabel="true"
                              [testId]="conf?.config?.testId + 'start'"
                              label="{{conf?.config?.label}}_FROM"></slm-amount-input>
            <div class="p-1 mt-4">-</div>
            <slm-amount-input [control]="form.get(conf?.config?.name).get('end')"
                              coloredValue="true"
                              class="w-50"
                              hideOptionalLabel="true"
                              [testId]="conf?.config?.testId + 'end'"
                              label="{{conf?.config?.label}}_TO"></slm-amount-input>
          </div>
          <slm-toggle
            *ngSwitchCase="'checkbox'"
            uniqueClass="mt-3 txt-align-center"
            [label]="conf?.config?.label"
            [testId]="conf?.config?.testId"
            [control]="form.get(conf?.config?.name)"
          ></slm-toggle>
        </ng-container>
      </ng-container>
    </ng-container>
  </form>
</div>
