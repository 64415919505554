import {Directive, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormControl, Validators} from '@angular/forms';
import {CustomValidators, InputError} from '../../../../global';

@Directive()
export abstract class InputAbstract implements OnInit {
  @Input() public label = '';
  @Input() public secondaryLabel = '';
  @Input() public placeholder = '';
  @Input() public hint = '';
  @Input() public control: UntypedFormControl | AbstractControl | any;
  @Input() public errors: Array<InputError> = [];
  @Input() public testId = '';
  @Input() public smallInput = false;
  @Input() public hideOptionalLabel = false;
  @Input() public coloredValue = false;
  @Input() public customWarningMsg: string;
  @Output() public fireEvent = new EventEmitter<string>();
  @Output() public leave = new EventEmitter<string>();
  public errorBody: any = null;
  public isRequiredField = false;

  ngOnInit() {
    this.initRequiredFieldCheck();
  }

  private initRequiredFieldCheck() {
    this.isRequiredField = (
      (this.control as AbstractControl).hasValidator(Validators.required) ||
      (this.control as AbstractControl).hasValidator(CustomValidators.chipRequired) ||
      (this.control as AbstractControl).hasValidator(CustomValidators.trimRequired)
    );
  }

  public getError(attribute: string = null) {
    this.initRequiredFieldCheck();
    if (!!attribute) {
      const error = (this.errors || []).find(
        (error: InputError) => error?.name === attribute
      );
      return error?.message;
    }
    if (this.control) {
      const errorType = Object.keys(this.control.errors)[0];
      if (!!errorType) {
        this.errorBody = this.control.getError(errorType);
        return this.getError(errorType);
      }
    }
    return null;
  }

  public onClick(eventType = '') {
    this.fireEvent.emit(eventType);
  }

  public focusOut() {
    this.leave.emit();
  }
}
