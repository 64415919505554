import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, interval, Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {
  NotificationEnum,
  NotificationService, NotificationTimes
} from '../../../modules/global';

@Component({
  selector: 'slm-notification',
  styleUrls: ['./notification.component.scss'],
  template: `
    <div class="notification">
      <div
        *ngFor="let notification of (notifications$ | async); let i = index"
        (mouseenter)="hover(i)"
        [class]="'notification__container notification__container--' + notification.status"
      >
        <span class="notification__container__message" [innerHTML]="notification.text | translate"></span>
        <div class="notification__container__button">
          <button mat-button (click)="remove(i)" class="notification__container__button__close" data-test-id="notificationCloseButton">
            <span>OK</span>
          </button>
        </div>
      </div>
    </div>
  `
})
export class NotificationComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  private notificationId = 0;

  notifications$ = new BehaviorSubject<Array<{ text: string; status: string; until: number; index: number; params: any}>>([]);

  constructor(
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService
  ) {
  }

  ngOnInit() {
    interval(500)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.verify(this.notifications$));

    this.notificationService.notification$
      .pipe(
        filter((notification) => !!notification.message),
        map((notification) => ({
          message: notification.message,
          duration: notification.duration || NotificationTimes.DEFAULT,
          type: notification.type || NotificationEnum.SUCCESS,
          messageParams: notification?.messageParams
        })),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        if (data.message) {
          const notifications = this.notifications$.getValue();
          const index = notifications.findIndex(notifications => notifications.text === data.message && data.type === notifications.status);
          if (index >= 0) {
            notifications.splice(index, 1);
          }
          const text = this.changeText(data.message || '', data?.messageParams);
          if (!!text) {
            const notification = {
              text,
              status: data.type,
              index: this.notificationId,
              until: data.duration > 0 ? new Date().getTime() + data.duration : NotificationTimes.INFINITE,
              params: data?.messageParams
            };
            if (!this.isSameNotification(notification)) { // filtering out the notification loop
              notifications.unshift(notification);
              ++this.notificationId;
              this.notifications$.next(notifications);
            }
          }
        }
      });

  }
  private isSameNotification(notification) {
    const notificationCount = this.notifications$.getValue()?.length;
    if (!notificationCount) {
      return false;
    }
    const lastNotification = this.notifications$.getValue()[notificationCount - 1];
     if (notification.text !== lastNotification.text || lastNotification.status !== notification.status) {
       return false;
     }
     return notification.until - lastNotification.until < 1000;
  }
  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  verify(notifications$): void {
    const notifications = notifications$.getValue();
    if (!!notifications.length) {
      const currentMillis = new Date().getTime();
      const expired = notifications
        .map((notification, index) => ({
          until: notification.until,
          index
        }))
        .filter(notification => notification.until > NotificationTimes.INFINITE && notification.until < currentMillis);
      expired.forEach(expired => notifications.splice(expired.index, 1));
      notifications$.next(notifications);
    }
  }

  hover(index) {
    const notifications = this.notifications$.getValue();
    if (notifications[index].until  !== NotificationTimes.INFINITE) {
      notifications[index].until += 1000;
      this.notifications$.next(notifications);
    }
  }

  changeText(message: string, params: any): string {
    return /^[A-Z_]+/.test(message) ? this.translateService.instant(message, params) : message;
  }

  remove(index) {
    const notifications = this.notifications$.getValue();
    notifications.splice(index, 1);
    this.notifications$.next(notifications);
  }

}
