import {
  Component,
  Input, OnChanges,
  OnDestroy,
  OnInit, SimpleChanges
} from '@angular/core';
import {SelectOption, utils} from '../../../../global';
import {debounceTime, distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {InputAbstract} from '../base/input.abstract';

@Component({
  selector: 'slm-select-input',
  templateUrl: './select-input.component.html',
  styles: [`
    .simple-view {
      height: 3rem;
    }
    :host {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
    }
  `]
})
export class SelectInputComponent
  extends InputAbstract
  implements OnInit, OnDestroy, OnChanges {
  @Input() public multiple = false;
  @Input() public enterEnabled = false;
  @Input() public lightTheme = false;
  @Input() public clearButton = false;
  @Input() public useLabelAsTitle = false;
  @Input() public headerSelectStyle = false;

  public simpleView = true;
  private simpleValue = true;
  private skipNext = false;

  @Input('options')
  public set setOptions(options: Array<SelectOption>) {
    this.options = (options || []).map((option) => ({
      ...option,
      title: this.useLabelAsTitle ? option.label : ''
    }));
    this.loading = !options;
    if (options?.length) {
      this.simpleValue = !options[0].icon &&
        !options[0].subLabel && !options[0].id && typeof options[0].value !== 'object';
      this.simpleView = !options[0].icon &&
        !options[0].subLabel;
    }
    if (!this.simpleValue) {
      this.changed();
    }
  }

  public selected: SelectOption | Array<SelectOption> | any= null;

  public options: Array<SelectOption> = null;
  public loading = true;
  private readonly destroy$ = new Subject();

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.control) {
      this.destroy$.next(null);
      this.initValueChanges();
    }
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.control) {
      this.initValueChanges();
      if (!this.simpleValue) {
        this.changed();
      }
    }
  }

  private initValueChanges() {
    this.control.valueChanges
      .pipe(
        debounceTime(50),
        filter(() => {
          if (this.skipNext) {
            this.skipNext = false;
            return false;
          }
          return !this.simpleValue;
        }),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.changed();
      });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public clear(event) {
    event.stopPropagation();
    this.control.reset();
  }

  public changed() {
    const selectedValue = this.control?.value;
    if (!selectedValue) {
      this.selected = null;
      return;
    }

    if (!this.multiple) {
      const selectedId = SelectInputComponent._getIdentifier(selectedValue);
      const previousId = SelectInputComponent._getIdentifier(this.selected);
      if (utils.isObject(selectedValue) && selectedId === previousId) {
        return;
      }
      if (selectedValue.value) {
        this.selected = selectedValue;
      } else if (this.options?.length && selectedId) {
        this.selected = this.getSelected(selectedId);
        if (!!this.selected && (this.selected  as SelectOption).value !== this.control?.value) {
          this.skipNext = true;
          this.control.setValue((this.selected as SelectOption).value);
        }
      } else {
        this.selected = null;
      }
    } else {
      const previousIds = (Array.isArray(this.selected) ? this.selected : [this.selected]).map(selected => SelectInputComponent._getIdentifier(selected));
      const selectedValues = Array.isArray(selectedValue) ?
        selectedValue.map(value => SelectInputComponent._getIdentifier(value)) :
        [SelectInputComponent._getIdentifier(selectedValue)];
      if (selectedValues.length === previousIds.length && !selectedValues.filter(value => !previousIds.includes(value)).length) {
        return;
      }
      if (!selectedValue.length) {
        this.selected = null;
      } else {
        this.selected = selectedValues.map(id => this.getSelected(id));
      }
    }

  }

  private getSelected = (id: number): any => (this.options || []).find(
    (option) => SelectInputComponent._getIdentifier(option) === id);

  private static _getIdentifier(option: SelectOption) {
    return option?.id ?? option?.value?.id ?? option?.value ??
      (!utils.isObject(option as any) ? option : null);
  }
}
