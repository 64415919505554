<mat-form-field
  class="flex-grow"
  *ngIf="control"
  [class.text-input--required]="isRequiredField  && (control.valid || !control.dirty)"
  [class.text-input--error]="
    !control?.valid && control?.touched && control?.dirty
  "
  [class.input--light]="lightTheme"
  [class.header-select]="headerSelectStyle"
>
  <mat-label *ngIf="label || (!isRequiredField && !hideOptionalLabel)">{{ label | translate }}
    <span *ngIf="!isRequiredField && !hideOptionalLabel" translate="LBL_INPUT.LABEL.NOT_REQUIRED"></span></mat-label>

  <div *ngIf="!loading && clearButton && control?.value && control?.enabled"
       matSuffix
       class="radius-infinite pos-relative top-4"
       matRipple
       (click)="clear($event)">
    <slm-svg-icon class="fill-accent" icon="close"></slm-svg-icon>
  </div>
  <slm-loader *ngIf="loading && control?.enabled" diameter="24" class="block top-10" matSuffix></slm-loader>
  <mat-select
    [formControl]="control"
    [multiple]="multiple"
    [required]="isRequiredField"
    [attr.data-test-id]="testId"
    [class.text-input--colored-value]="coloredValue"
    [placeholder]="placeholder | translate"
    (keyup.enter)="enterEnabled ? onClick('enter') : null"
  >
    <mat-select-trigger *ngIf="!simpleView">
      <ng-container *ngIf="!multiple">
        <div *ngIf="selected" class="flex-grow flex flex-start flex-items-center">
          <slm-svg-icon
            *ngIf="selected.icon"
            [icon]="selected.icon"
            class="size-16px mr-1"
            size="16px"
            [color]="selected.color"
          ></slm-svg-icon>
          <h5 class="m-0 txt-start-i" [class.color-accent]="coloredValue">{{ selected.label | translate }}
            <ng-container *ngIf="selected.subLabel"> - {{selected.subLabel}}</ng-container>
          </h5>
        </div>
      </ng-container>
      <div *ngIf="multiple && selected" class="flex">
        <ng-container *ngFor="let item of selected; let last = last">
          <h5 class="m-0 txt-start-i w-auto-i" [class.color-accent]="coloredValue">{{ item?.label | translate }}</h5>
          <ng-container *ngIf="!last">, </ng-container>
        </ng-container>
      </div>
    </mat-select-trigger>
    <ng-container *ngIf="!loading">
      <ng-container *ngIf="!simpleView">
        <mat-option *ngFor="let option of options; let index = index"
                    [value]="option.value"
                    [attr.data-test-id]="testId + index"
                    [disabled]="option.disabled || false"
                    class="h-min-3r h-fit-content-i pt-1-i pb-1-i">
          <div class="flex-grow flex flex-start flex-items-center"  [class.block-i]="option.subLabel">
            <slm-svg-icon
              *ngIf="option.icon"
              [icon]="option.icon"
              [color]="option.color"
              class="mr-2"
            ></slm-svg-icon>
            <div class="medium-text">{{ option.label | translate }}</div>
            <span *ngIf="option.subLabel" class="mt-1 block txt-normal-wrap">{{ option.subLabel | translate }}</span>
          </div>
        </mat-option>
      </ng-container>
      <ng-container *ngIf="simpleView">
        <mat-option *ngFor="let option of options; let index = index"
                    [attr.data-test-id]="testId + index"
                    [value]="option.value"
                    [title]="option.title | translate"
                    [disabled]="option.disabled || false"
                    class="simple-view">
          <!--Need to delete later &zwnj;-->
          <div class="medium-text">{{ option.label | translate }}&zwnj;</div></mat-option>
      </ng-container>
    </ng-container>
  </mat-select>
  <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
  <mat-hint *ngIf="customWarningMsg" class="color-warning">{{customWarningMsg | translate}}</mat-hint>
  <mat-error *ngIf="(control?.dirty || control?.touched) && control?.invalid">{{
    getError() | translate: errorBody
    }}</mat-error>
</mat-form-field>
