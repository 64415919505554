import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  MenuStates,
  RoutesEnum,
  AuthSandbox,
  NotificationMenu,
  SystemNotification,
  NotificationSandbox,
  UserSandbox,
  GeneralSandbox,
  StatusClassEnum, Company
} from '../../../modules/global';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {
  FireEvent,
  MenuItem,
  SelectOption,
  User,
  mobileWidth,
  CompanySandbox,
  NavigationService,
  NotificationService
} from '../../../modules/global';
import {
  debounceTime,
  filter,
  map,
  shareReplay,
  switchMap,
  takeUntil
} from 'rxjs/operators';
import {MediaMatcher} from '@angular/cdk/layout';
import {MatSidenav} from '@angular/material/sidenav';
import {expandableOutgoingMenuItem, normalIncomingMenuItem, normalOutgoingMenuItem, sideNavItems, useOnTransactionSubmenuItem} from './sidenav.config';
import {ProfileDialogComponent, ProfileDialogConfig} from '../../components/profile-dialog/profile-dialog.component';
import {PasswordChangeDialogComponent} from '../../components/password-change-dialog/password-change-dialog.component';
import {Router} from '@angular/router';
import {DialogPresenterService, HelpDialogComponent} from '../../../modules/shared';
import {NotificationAlertDialogComponent} from '../../components/notification-alert-dialog/notification-alert-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'slm-home',
  templateUrl: './home.container.html',
  styleUrls: ['./home.container.scss']
})
export class HomeContainer implements OnInit, OnDestroy {
  public user$: Observable<User>;
  public notificationCount$: Observable<number>;
  public notifications$: Observable<Array<NotificationMenu>>;
  public notificationPage = 0;
  public hasMoreNotification = true;
  public notificationLoading = false;
  private skipNextUserObjLanguageChange = false;

  public menuItems$: Observable<Array<MenuItem>>;
  private showMenuItems$ = new BehaviorSubject<boolean>(true);

  public headerShadow$ = new BehaviorSubject<boolean>(false);

  public activeCompanyId$: Observable<number>;
  public availableCompanies$: Observable<Array<SelectOption>>;
  public remainingDemoDays$: Observable<number>;

  public mobileQuery: MediaQueryList;
  public readonly menuStates$ = new BehaviorSubject<MenuStates>('full');

  private readonly destroy$ = new Subject();
  private _mobileQueryListener: () => void;
  private user: User = null;

  @ViewChild('vcSidenav', {static: true})
  public sidenav: MatSidenav;

  @HostListener('window:keyup.esc', ['$event'])
  public onKeydownHandler(event: KeyboardEvent) {
    event.stopPropagation();
    if (
      this.menuStates$.getValue() === 'full' &&
      window.innerWidth > mobileWidth
    ) {
      this.showSideMenu();
    }
  }

  constructor(
    private readonly userSandbox: UserSandbox,
    private readonly authSandbox: AuthSandbox,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly navigationService: NavigationService,
    private readonly notificationSandbox: NotificationSandbox,
    private readonly media: MediaMatcher,
    private readonly route: Router,
    private readonly notificationService: NotificationService,
    private readonly dialogService: DialogPresenterService,
    private readonly companySandbox: CompanySandbox,
    private readonly generalSandbox: GeneralSandbox,
    private readonly translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.navBarInit();
    this.sandboxInit();
    this.sideMenuInit();
  }

  ngOnDestroy() {
    this.authSandbox.destroy();
    this.destroy$.next(null);
    this.destroy$.complete();

    if (this.mobileQuery?.removeEventListener) {
      this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    }
  }

  private sideMenuInit() {
    this.menuItems$ = combineLatest([
      this.showMenuItems$,
      this.generalSandbox.navMenuLocations$,
      this.generalSandbox.useOnTransactionStatus$,
      this.generalSandbox.getStorageUsage$,
      this.generalSandbox.useDivision$,
      this.generalSandbox.useBank$
    ]).pipe(
      map(([show, navLocations, onTransactionStatus, storage, useDivisions, useBank]) => {
        if (!show) {
          return [];
        }

        const menuItems = [...sideNavItems(useBank)];
        if(useDivisions && (this.user.divisions).length > 0){
          menuItems[2] = {...normalOutgoingMenuItem};
          menuItems[3] = {...normalIncomingMenuItem};
        }else{
          menuItems[2] = {...navLocations === 'all' ? expandableOutgoingMenuItem : normalOutgoingMenuItem };
        }

        if(onTransactionStatus){
          menuItems[4] = {...menuItems[4], submenu: [...sideNavItems(useBank)[4].submenu, useOnTransactionSubmenuItem]};
        }

        if(storage?.enabled){
          menuItems[7] = {...menuItems[7], data: {
            progressBar: {
              used: storage?.used,
              total: storage?.total
            }
          }};
        }else{
          menuItems.splice(7, 1);
        }


        return menuItems;
      })
    );
  }

  public notificationsOpened() {
    if (this.notificationPage === 0) {
      this.loadNextNotificationPage();
    }
  }

  public loadNextNotificationPage() {
    if (this.hasMoreNotification && !this.notificationLoading) {
      this.notificationLoading = true;
      this.notificationSandbox.loadNextNotificationPage(
        ++this.notificationPage
      );
    }
  }

  private sandboxInit(): void {
    // AuthSandbox initialization
    this.authSandbox.init();
    // User reloading. This action will download and store the user object from the backend.
    this.user$ = this.userSandbox.user$.pipe(
      debounceTime(50),
      filter((user) => {
        if (!this.authSandbox.getJwtToken()) {
          return false;
        }
        if (!user) {
          this.userSandbox.reloadUser();
          return false;
        }
        this.notificationSandbox.refreshGeneral();
        this.user = user;
        if (!this.skipNextUserObjLanguageChange &&
          user.language &&
          this.translateService.currentLang !== user.language.toLowerCase()) {
          this.skipNextUserObjLanguageChange = false;
          this.translateService.use(user.language.toLowerCase());
        }
        this.verifyCompany(+user.activeCompanyId);
        return true;
      }),
      shareReplay({bufferSize: 2, refCount: true})
    );

    this.activeCompanyId$ = this.user$.pipe(
      map((user) => user.activeCompanyId)
    );

    this.availableCompanies$ = this.user$.pipe(
      map((user) =>
        (user.companies || []).map((company) => ({
          value: +company.id,
          label: company.name
        }))
      )
    );

    this.notificationCount$ = this.notificationSandbox.notificationCount$.pipe(
      map((count) => +(count ?? 0))
    );

    this.notifications$ = this.notificationSandbox.notifications$.pipe(
      filter((value) => !!value),
      map((response) => {
        this.notificationLoading = false;
        this.hasMoreNotification =
          response.paginator.page <
          Math.floor(response.paginator.fullLength / response.paginator.size);
        this.notificationPage = response.paginator.page;
        return response.items.map(
          (notification): NotificationMenu => {
            let text = notification.text;
            if (notification.type === 'html') {
              const element = document.createElement('div');
              element.innerHTML = text;
              text = element.innerText;
            }
            return ({
              type: 'system',
              data: notification,
              checked: notification.isRead,
              id: notification.id,
              text,
              date: notification.creationDate
            });
          }
        );
      })
    );
    this.notificationSandbox.systemNotification$
      .pipe(
        debounceTime(250),
        filter((value) => !!value?.length),
        map((items) => {
          const currentTime = new Date().getTime();
          return items.filter((item) => {
            const time = new Date(item.effectiveFrom).getTime() - currentTime;
            return time > 0 && time < 86_400_000;
          }); // before 1 day
        }),
        filter((value) => !!value?.length),
        takeUntil(this.destroy$)
      )
      .subscribe((items) => {
        this.dialogService
          .openDialog(
            NotificationAlertDialogComponent,
            {items},
            {disableClose: true}
          )
          .pipe(
            filter((value) => !!value),
            switchMap(() =>
              this.notificationSandbox.acceptNotifications(
                items.map((items) => items.id)
              )
            )
          )
          .subscribe(() => this.notificationSandbox.refreshGeneral());
      });

    this.remainingDemoDays$ = this.companySandbox.activeCompany$.pipe(
      map((company: Company) =>
        !!company?.remainingDemoDays || company?.remainingDemoDays === 0 ? company?.remainingDemoDays : null)
    );
  }

  private navBarInit(): void {
    if (window.innerWidth >= mobileWidth) {
      this.sidenav.toggle(true).then();
    }
    this.mobileQuery = this.media.matchMedia(`(max-width: ${mobileWidth}px)`);
    this._mobileQueryListener = () => {
      if (window.innerWidth > mobileWidth) {
        this.sidenav.toggle(true).then();
      } else {
        this.sidenav.toggle(false).then();
        if (this.menuStates$.getValue() !== 'full') {
          this.menuStates$.next('full');
        }
      }
      this.changeDetectorRef.detectChanges();
    };
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }

  public changeCompany(companyId) {
    this.companySandbox.changeCompany(companyId);
    this.authSandbox.refreshing = true;
    this.userSandbox.reloadUser();
    this.verifyCompany(companyId);
    this.generalSandbox.clearUseOnTransactionStatus();
    this.notificationSandbox.refreshGeneral();
  }

  private verifyCompany(companyId: number) {
    const company = (this.user?.companies || []).find(
      (company) => +company.id === companyId
    )?.status;

    switch (company) {
      case StatusClassEnum.REGISTRATION:
        //Registration in progress
        this.showMenuItems$.next(false);
        this.navigationService.navigate([RoutesEnum.COMPANY_PROCESSING]);
        break;
      case StatusClassEnum.ACTIVE:
        //Registered / active
        if (!this.showMenuItems$.getValue()) {
          this.showMenuItems$.next(true);
        }
        const url = this.route.url.substring(1);
        if (url === RoutesEnum.COMPANY_PROCESSING || url === RoutesEnum.COMPANY_DEBT) {
          this.navigationService.navigate([RoutesEnum.HOME]);
        }
        break;
      case StatusClassEnum.IN_DEBT:
        //Company in debt
        this.showMenuItems$.next(false);
        this.navigationService.navigate([RoutesEnum.COMPANY_DEBT]);
        break;
      default:
        this.showMenuItems$.next(false);
        this.navigationService.navigate([RoutesEnum.NO_ACCESS]);
        break;
    }
  }

  public headerAction(action: FireEvent): void {
    switch (action.event) {
      case 'menu':
        this.showSideMenu();
        break;
      case 'sign_out':
        this.authSandbox.logout();
        break;
      case 'password_settings':
        this.openPasswordDialog();
        break;
      case 'profile_setting':
        this.openProfileDialog();
        break;
      case 'notification_open':
        this.notificationsOpened();
        break;
      case 'notification_next':
        this.loadNextNotificationPage();
        break;
      case 'notification_click':
        this.openNotification(action.data);
        break;
      case 'help_open':
        this.openHelp();
        break;
      case 'language':
        this.saveSelectedLanguage(action.data);
    }
  }

  public saveSelectedLanguage(language: string) {
    this.userSandbox.changeLanguage(language).subscribe(() => this.skipNextUserObjLanguageChange = true);
  }

  public openHelp() {
    this.dialogService
      .openDialog(
        HelpDialogComponent,
        this.generalSandbox.getHelp(this.translateService.currentLang, this.route.url)
      ).subscribe();
  }

  public openNotification(notification: SystemNotification) {
    if (notification.isRead) {
      this.dialogService
        .openMessageDialog(
          'NOTIFICATION.SYSTEM_NOTIFICATIONS',
          notification.text,
          null,
          'LBL_BTN.CLOSE',
          true,
          notification.type === 'html'
        )
        .subscribe();
    } else {
      this.dialogService
        .openQuestionDialog(
          'NOTIFICATION.SYSTEM_NOTIFICATIONS',
          notification.text,
          null,
          'LBL_BTN.ACCEPT',
          'LBL_BTN.CLOSE',
          true,
          notification.type === 'html'
        )
        .pipe(
          filter((value) => !!value),
          switchMap(() =>
            this.notificationSandbox.acceptNotifications([notification.id])
          )
        )
        .subscribe(() => this.notificationSandbox.refreshGeneral());
    }
  }

  public showSideMenu(): void {
    if (window.innerWidth <= mobileWidth) {
      this.sidenav.toggle(!this.sidenav.opened).then();
      if (this.menuStates$.getValue() !== 'full') {
        this.menuStates$.next('full');
      }
    } else {
      this.sidenav.toggle(true).then();
      this.menuStates$.next(
        this.menuStates$.getValue() === 'full' ? 'half' : 'full'
      );
    }
  }

  public menuClicked(): void {
    if (window.innerWidth < mobileWidth && this.sidenav.opened) {
      this.sidenav.toggle(false).then();
    }
  }

  public openProfileDialog(): void {
    if (this.user) {
      this.dialogService
        .openDialog(ProfileDialogComponent, {
          user: this.user,
          save: (data) => this.userSandbox.updateUser({
            fistName: data.userName,
            phone: data.phone,
            partnerId: data.partnerId
          }),
          companies$: this.companySandbox.getSubsidiaries(false)
        } as ProfileDialogConfig)
        .subscribe(() => {
          this.notificationService.notify('LBL_MESSAGES.UPDATE_SUCCESS');
          this.userSandbox.reloadUser();
        });
    }
  }

  public openPasswordDialog(): void {
    if (this.user) {
      this.dialogService
        .openDialog(PasswordChangeDialogComponent)
        .pipe(
          switchMap((data) =>
            this.userSandbox.updatePassword(
              data.currentPassword,
              data.newPassword
            )
          )
        )
        .subscribe(() => {
          this.notificationService.notify('LBL_MESSAGES.UPDATE_SUCCESS');
        });
    }
  }

  public containerScroll(event) {
    this.headerShadow$.next(event.target.scrollTop > window.innerHeight * 0.05);
  }
}
