import {Code} from './general.entity';
import {Paginator} from './paginator.entity';
import {YearQuarter} from '../enums';

export interface VatCode extends Code {
  percent: string;
  value: string;
  unEdifact: string;
  exemption: string;
  deductibleGeneralLedger: string;
  payableGeneralLedger: string;
  default?: boolean;
  cashFlow?: string;
  cashFlowDebt?: string | CashFlowCoa;
  cashFlowNotDebt?: string | CashFlowCoa;
  cashFlowClaim?: string | CashFlowCoa;
  cashFlowNotClaim?: string | CashFlowCoa;
}

export interface VatResponse {
  vatCodes: Array<VatCode>;
  paginator: Paginator;
}

export interface VatBalanceParams {
  year: number;
  month?: number;
  quarter?: YearQuarter;
  navVat?: boolean;
}

export interface CashFlowCoa {
  id: number;
  name?: string;
  ledgerNumber: number;
}
