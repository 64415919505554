<slm-external-page-container width="900px" layoutDirection="start">
  <h4 translate>PUBLIC.QUESTION</h4>
  <h5 translate>PUBLIC.MORE_INFO</h5>
  <slm-button buttonStyle="primary"
              externalLink="true"
              text="PUBLIC.GO_TO_SITE"
              url="https://slm.hu"
              target="_blank"></slm-button>
  <ng-container *ngIf="status$ | async as status; else loading">
    <ng-container [ngSwitch]="status">
      <ng-container *ngSwitchCase="'expired'">
        <ng-container *ngTemplateOutlet="invalidToken"></ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="validToken"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</slm-external-page-container>

<ng-template #loading>
  <slm-loader></slm-loader>
</ng-template>

<ng-template #invalidToken>
  <slm-message message="PUBLIC.EXPIRED" type="error" class="mt-5 mb-3"></slm-message>
</ng-template>

<ng-template #validToken>
  <div class="grid grid-col-2-50 grid-responsive w-100 mt-5">
    <slm-vertical-table [attributes]="partnerTable" [data]="partnerData$ | async"></slm-vertical-table>
    <slm-vertical-table [attributes]="invoiceTable" [data]="invoiceData$ | async"></slm-vertical-table>
  </div>
  <div
    *ngIf="paymentData$ | async as paymentData"
    class=" mt-5">
    <slm-vertical-table [attributes]="paymentTable" [data]="paymentData"></slm-vertical-table>
  </div>
  <div class="mt-5 mb-5" *ngIf="amount$ | async as amount; else loading">
    <ng-container *ngIf="amount.unpaidValue && amount.unpaidValue !== amount.amount">
      <h5 class="mb-1" translate>LBL_COMPANY.BRUT_SUMMERY</h5>
      <h4 class="color-accent">{{amount.amount |  currencyTransform: amount.currency}}</h4>
    </ng-container>
    <h4 translate>PUBLIC.AMOUNT</h4>
    <h3 class="color-accent">{{(amount.unpaidValue || amount.amount) |  currencyTransform: amount.currency}}</h3>
    <h5>{{'PUBLIC.DOWNLOAD_DEADLINE'| translate: {date: amount.deadline | dateTransform: 'FORMAT.DATE_FULL'} }}</h5>
    <slm-button type="submit"
                [text]="buttonLabel$ | async"
                class="mt-1 w-100"
                [loading]="buttonIsLoading"
                (click)="downloadInvoice()"></slm-button>
  </div>
</ng-template>
