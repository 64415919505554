import {ErrorHandler, Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {NotificationService} from './notification.service';
import {NotificationEnum, NotificationTimes} from '../enums';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler extends ErrorHandler {
  public static GLOBAL_ERROR_LABEL = 'APP_GLOBAL_CONSOLE_ERROR';
  public static LAST_SENT_ERROR_LABEL = 'APP_GLOBAL_CONSOLE_LAST_SENT_ERROR';
  public static LOAD_CHUNK_ERROR_LABEL = 'APP_GLOBAL_CONSOLE_LOAD_CHUNK_ERROR';

  private _errorReporter$ = new Subject<{ message: string; error: any }>();
  public errorReporter$ = this._errorReporter$.asObservable().pipe(
    map(error => ({
      error: GlobalErrorHandler.getErrorObject(error.error),
      message: error.message
    }))
  );

  constructor(private readonly notification: NotificationService) {
    super();
  }

  handleError(error: any) {
    if (environment.errorReload) {
      // Check if it's an error from an HTTP response
      if (error instanceof HttpErrorResponse
        || error.toString().startsWith('Error: NG')
        || error.toString().includes('BaseChartDirective')
        || error.toString().includes('XMLHttpRequest')
      ) {
        return;
      }

      if(error.toString().includes('ChunkLoadError')){
        localStorage.setItem(GlobalErrorHandler.LOAD_CHUNK_ERROR_LABEL, 'true');
        window.location.reload();
      }

      const errorObject = GlobalErrorHandler.getErrorObject(error); // get the error object

      if (GlobalErrorHandler.isNewError(errorObject)) {
        localStorage.setItem(GlobalErrorHandler.GLOBAL_ERROR_LABEL, JSON.stringify(errorObject));
        window.location.reload();
      } else if (!GlobalErrorHandler.isReportedErrorMessageDisplayed()) {
        GlobalErrorHandler.disableReportedErrorMessage();
        this.notification.notify('MESSAGE.DETECTED_REPORTED_ERROR', NotificationEnum.WARNING, NotificationTimes.INFINITE);
      }
    }
    super.handleError(error);
  }

  private static isNewError(currentErrorObject): boolean {
    const lastSentError = localStorage.getItem(GlobalErrorHandler.LAST_SENT_ERROR_LABEL);
    if (!lastSentError) {
      return  true;
    }
    try {
      const lastSentErrorObject = JSON.parse(lastSentError);
      return lastSentErrorObject.error !== currentErrorObject.error ||
        new Date().getTime() - (+lastSentErrorObject.time) >= 3_600_000;
    } catch (e) {
      return true;
    }
  }

  private static isReportedErrorMessageDisplayed(): boolean {
    const lastSentError = localStorage.getItem(GlobalErrorHandler.LAST_SENT_ERROR_LABEL);
    if (!lastSentError) {
      return  true;
    }
    try {
      const lastSentErrorObject = JSON.parse(lastSentError);
      return !!(+lastSentErrorObject.displayed);
    } catch (e) {
      return true;
    }
  }

  private static disableReportedErrorMessage(): void {
    const lastSentError = localStorage.getItem(GlobalErrorHandler.LAST_SENT_ERROR_LABEL);
    if (!lastSentError || !lastSentError.includes('displayed":')) {
      return;
    }
    try {
      localStorage.setItem(
        GlobalErrorHandler.LAST_SENT_ERROR_LABEL,
        JSON.stringify({...JSON.parse(lastSentError), displayed: 1})
      );
    } catch (e) {
      return;
    }
  }

  private static getErrorObject(error) {
    return {
      url: window.location.href,
      error: error?.toString(),
      stackTrace: error?.stack,
      browser: GlobalErrorHandler.getBrowserInfo()
    };
  }

  getError(): string {
    return localStorage.getItem(GlobalErrorHandler.GLOBAL_ERROR_LABEL);
  }

  private static getBrowserInfo() {
    const nAgt = navigator.userAgent;
    let browserName = navigator.appName;
    let fullVersion = '' + parseFloat(navigator.appVersion);
    let nameOffset; let verOffset; let ix;

    if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
      browserName = 'Opera';
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf('Version')) !== -1) {
fullVersion = nAgt.substring(verOffset + 8);
}
    } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
      browserName = 'Microsoft Internet Explorer';
      fullVersion = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
      browserName = 'Chrome';
      fullVersion = nAgt.substring(verOffset + 7);
    } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
      browserName = 'Safari';
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf('Version')) !== -1) {
fullVersion = nAgt.substring(verOffset + 8);
}
    } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
      browserName = 'Firefox';
      fullVersion = nAgt.substring(verOffset + 8);
    } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    if ((ix = fullVersion.indexOf(';')) !== -1) {
fullVersion = fullVersion.substring(0, ix);
}
    if ((ix = fullVersion.indexOf(' ')) !== -1) {
fullVersion = fullVersion.substring(0, ix);
}

    let majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    return {
      browserName,
      fullVersion,
      majorVersion,
      appName: navigator.appName,
      userAgent: navigator.userAgent
    };
  }

  reportError(error: any, message: string) {
    this._errorReporter$.next({error, message});
  }

  clearError() {
    const lastError = this.getError();
    if (!!lastError) {
      const lastSentErrorObject = JSON.parse(lastError);
      localStorage.setItem(GlobalErrorHandler.LAST_SENT_ERROR_LABEL,
        JSON.stringify({error: lastSentErrorObject.error, time: new Date().getTime(), displayed: 0}));
    }
    localStorage.removeItem(GlobalErrorHandler.GLOBAL_ERROR_LABEL);
  }

  isChunkError(){
    const chunkError = localStorage.getItem(GlobalErrorHandler.LOAD_CHUNK_ERROR_LABEL);
    if(chunkError){
      localStorage.removeItem(GlobalErrorHandler.LOAD_CHUNK_ERROR_LABEL);
      return true;
    }
    return false;
  }
}
