import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlingService } from '../../global/services';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  BankAccount,
  ListResponseNew,
  PaginatorNew,
  Partner,
  TableOrder,
  PartnerSave, PartnerItem, PartnerVatCheck
} from '../entities';
import {utils} from '../functions';
import {PartnerActiveStatusEnum} from '../enums';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandler: ErrorHandlingService
  ) {}

  public getPartners(
    page: PaginatorNew,
    order: TableOrder,
    search: string = null,
    type: PartnerActiveStatusEnum = null
  ): Observable<ListResponseNew<PartnerItem>> {
    return this.http
      .get('/companies', {
        params: {
          ...utils.paginatorToQueryParams(page),
          ...utils.tableOrderToQueryParams(order),
          type,
          ...(search ? { search: encodeURIComponent(search) } : {})
        }
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public getPartner(id: number | string): Observable<Partner> {
    return this.http
      .get(`/company/data/${id}`)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public searchPartnerBy(
    paginator: PaginatorNew,
    search: string
  ): Observable<ListResponseNew<Partner>> {
    return this.http
      .get('/partners/search', {
        params: {
          search: encodeURIComponent(search),
          ...utils.paginatorToQueryParams(paginator)
        }
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public refreshPartner(
    partnerId: number
  ): Observable<PartnerVatCheck> {
    return this.http
      .get(`/partners/refresh/${partnerId}`)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public partnerBankAccounts(
    paginator: PaginatorNew,
    partnerId: number
  ): Observable<ListResponseNew<BankAccount>> {
    return this.http
      .get(`/partners/${partnerId}/bankaccounts`, {
        params: { page: `${paginator.page}`, size: `${paginator.size}` }
      })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public savePartner(partner: PartnerSave): Observable<{
    companyId: number;
    externalSystemIdentifier: string;
    negativeInfo: boolean;
    verificationDate: string;
  }> {
    return this.http
      .post('/companies', partner)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }
}
