import {Injectable} from '@angular/core';
import {downloadExcel, Partner, PartnerActiveStatusEnum, PartnerSave} from '../../global';
import {PartnerService} from '../services/partner.service';
import {Store} from '@ngrx/store';
import {Observable, tap} from 'rxjs';
import {partnerSelectors} from '../+states/partner-selectors';
import {partnerActions} from '../+states/partner.actions';

@Injectable({
  providedIn: 'root'
})
export class PartnerSandbox {
  constructor(
    private readonly partnerService: PartnerService,
    private readonly store: Store
  ) {}

  public readonly selectedPartner$: Observable<Partner> = this.store.select(
    partnerSelectors.getSelectedPartner()
  );

  public readonly selectedPartnerId$: Observable<number> = this.store.select(
    partnerSelectors.getSelectedId()
  );

  public clear(): void {
    this.store.dispatch(partnerActions.clearPartner());
  }

  public changePartnerStatus = (ids: Array<number>, status: PartnerActiveStatusEnum) =>
    this.partnerService.changePartnerStatus(ids, status);

  public updatePartner = (partner: PartnerSave) =>
    this.partnerService.updatePartner(partner);

  public selectPartner(id: number): void {
    this.store.dispatch(partnerActions.selectPartner({id}));
  }

  public mergePartner = (data) => this.partnerService.mergePartner(data);

  public downloadImportExample = (fileName: string) => this.partnerService.downloadImportExample()
    .pipe(tap(file => downloadExcel(file, fileName)));

  public importPartners = (file: File) => this.partnerService.importPartners(file);

  public export = (type: PartnerActiveStatusEnum = PartnerActiveStatusEnum.ACTIVE, search: string) => this.partnerService.export(type, search);

  public checkPartnersList = () => this.partnerService.checkPartnersList();
}
