<mat-form-field
  class="slm-datepicker flex-grow"
  [class.text-input--required]="isRequiredField  && (control.valid || !control.dirty)"
  [class.text-input--error]="
    this.control && this.control.invalid && this.control.touched
  "
  (focusout)="focusOut()"
>
  <mat-label *ngIf="label || (!isRequiredField && !hideOptionalLabel)"
    >{{ label | translate
    }} <span *ngIf="secondaryLabel">{{ secondaryLabel | translate }}</span>
    <span *ngIf="!isRequiredField && !hideOptionalLabel" translate="LBL_INPUT.LABEL.NOT_REQUIRED"></span>
  </mat-label>
  <slm-svg-icon matPrefix *ngIf="prefixIcon" [icon]="prefixIcon"></slm-svg-icon>
  <input
    matInput
    [required]="isRequiredField"
    [attr.data-test-id]="testId"
    [matDatepicker]="datePicker"
    [formControl]="control"
    [class.color-accent-i]="coloredValue"
    [placeholder]="placeholder | translate"
    [min]="min"
    [max]="max"
  />
  <mat-datepicker-toggle
    matIconSuffix
    [for]="datePicker"
    [matTooltip]="suffixIconLabel | translate"
  >
    <slm-svg-icon
      matDatepickerToggleIcon
      class="fill-grey svg-icon"
      icon="today"
    ></slm-svg-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #datePicker></mat-datepicker>

  <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
  <mat-error
    *ngIf="this.control && this.control.invalid && this.control.touched"
    >{{ getError() | translate: errorBody }}</mat-error
  >
</mat-form-field>
