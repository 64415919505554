import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {InputTypeEnum, Icons, SelectOption, utils} from '../../../../global';
import {InputAbstract} from '../base/input.abstract';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'slm-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styles: [`
    :host {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
    }
  `]
})
export class AutocompleteInputComponent extends InputAbstract implements OnInit, OnDestroy {
  @Input() autoFocus = false;
  @Input() type: InputTypeEnum = 'text';
  @Input() buttonIcon = true;
  @Input() enterEnabled = false;
  @Input() prefixIcon: Icons = null;
  @Input() hideSubLabel = false;
  @Input() suffixIcon: Icons = null;
  @Input() suffixIconLabel = '';
  @Input() clearButton = false;
  @Input() searchLengthRequirement = 2;
  @Input() showLoader = true;
  @Input() matChipInputFor = null;
  @Input() customWarningMsg = '';
  @ViewChild('autocompleteInputField', {static: true})
  inputContainer: ElementRef;

  @Input('options')
  public set setOptions(options: Array<SelectOption>) {
    this.options = options;
    this.loading = false;
    if (this.options?.length) {
      this.inputContainer?.nativeElement?.focus();
    }
  }

  @Input() maxLength: number = null;
  @Input() upperCase = false;

  public options: Array<SelectOption> = null;
  public loading = true;
  private destroy$ = new Subject();

  ngOnInit() {
    super.ngOnInit();
    if (this.control) {
      this.control.valueChanges.pipe(
        filter((value: any) => {
          const isString = utils.isString(value);
          if ((isString && value?.length < +this.searchLengthRequirement) || (utils.isObject(value)) && this.loading) {
            this.loading = false;
          }
          return isString && value?.length >= +this.searchLengthRequirement && !this.loading && this.showLoader;
        }),
        takeUntil(this.destroy$)).subscribe(() => this.loading = true);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public clear(): void {
    if (this.control) {
      this.control.setValue('');
    }
  }

  disableLoading() {
    this.loading = false;
  }

  displayValue = () => (selected: SelectOption | string) => {
    const option = selected ?? this.control?.value;
    return !this.hideSubLabel && option?.subLabel ? `${option.label} - ${option.subLabel}` : option?.label ?? option ?? '';
  };
}
