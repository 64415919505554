<div
  *ngIf="messages || invoiceMode; else loading"
  class="w-100 mt-1"
  [class.p-0]="disablePadding"
>
  <div *ngIf="currentUser" class="flex h-auto w-100 pt-3 pb-3 pr-7 pl-7">
    <slm-monogram
      [user]="currentUser"
      color="rgba(73, 75, 116, 0.25)"
      class="mr-3"
      size="2.5"
    ></slm-monogram>
    <div class="w-100">
      <h5 class="txt-start-i text-medium w-100 color-black">
        {{ currentUser | name }}
      </h5>

      <slm-text-area
        label="INVOICE.LEAVE_MESSAGE"
        [enterEnabled]="true"
        testId="messageArea"
        [control]="messageControl"
        (fireEvent)="sendMessage()"
      ></slm-text-area>

      <slm-toggle
        *ngIf="showToggle"
        [control]="toggleControl"
        label="INVOICE.ALL_MESSAGES"
        class="w-fit-content block float-left"
      ></slm-toggle>

      <slm-button
        *ngIf="!disableButton"
        buttonStyle="primary"
        class="w-fit-content block float-right"
        text="LBL_BTN.SEND_MESSAGE"
        testId="sendMessageButton"
        [disabled]="!messageControl.value"
        (click)="sendMessage()"
      ></slm-button>
    </div>
  </div>
  <div
    *ngFor="let message of messages$ | async ; let index = index; trackBy: trackById;"
    class="flex h-auto w-100 pr-7 pl-7 "
    [ngClass]="!message.isAdmin ? 'light-background pt-3 pb-3 mb-1': 'pt-1 pb-1'"
  >
    <ng-container *ngIf="!message.isAdmin">
      <slm-monogram
        [user]="message"
        [color]="
            +message.userId === +currentUser?.id ? 'rgba(73, 75, 116, 0.25)' : null
          "
        class="mr-3"
        size="2.5"
      ></slm-monogram>
      <div *ngIf="selectedIndex !== index; else update" class="w-100">
        <div class="flex flex-space-btwn">
          <h5 class="txt-start-i text-medium w-100 color-black">
            {{ message | name }}
            <span>{{ message.date | dateTransform: "FORMAT.DATE_FULL" }}</span>
          </h5>
          <div *ngIf="+message.userId === +currentUser?.id" class="flex">
            <slm-button-icon
              icon="create"
              elevation="true"
              class="mr-1 size-2 fill-accent"
              [matTooltip]="'LBL_BTN.EDIT' | translate"
              (click)="showUpdate(index)"
            ></slm-button-icon>
            <slm-button-icon
              icon="delete"
              elevation="true"
              class="size-2 fill-accent"
              [matTooltip]="'LBL_BTN.DELETE' | translate"
              (click)="deleteMessage(message)"
            ></slm-button-icon>
          </div>
        </div>
        <p class="text-thin color-secondary ml-1_5 txt-size-small">
          {{ message.message }}
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="message.isAdmin">
      <h5 class="txt-start-i text-medium w-auto-i color-black pr-3">
        <span>{{ message.date | dateTransform: "FORMAT.DATE_FULL" }}</span>
      </h5>
      <ng-container [ngSwitch]="adminType">
        <p class="text-thin color-secondary ml-1_5 mt-1-i" *ngSwitchCase="'invoice'">
          {{ message.message  | invoiceInternalMessage: message.firstName }}
        </p>
        <p class="text-thin color-secondary ml-1_5 mt-1-i" *ngSwitchCase="'file-manager'">
          {{ message.type | adminInternalMessage: message.message : message.firstName }}
        </p>
      </ng-container>
    </ng-container>
  </div>
</div>
<ng-template #loading>
  <slm-loader></slm-loader>
</ng-template>

<ng-template #update>
  <div class="w-100">
    <h5 class="txt-start-i text-medium w-100 color-black">
      {{ currentUser | name }}
    </h5>

    <slm-text-area
      label="INVOICE.LEAVE_MESSAGE"
      [enterEnabled]="true"
      [control]="updateControl"
      (fireEvent)="updateMessage()"
    ></slm-text-area>
    <div class="flex flex-end w-100">
      <slm-button
        buttonStyle="secondary"
        class="w-fit-content mr-2"
        text="LBL_BTN.CANCEL"
        (click)="cancelUpdate()"
      ></slm-button>

      <slm-button
        *ngIf="!disableButton"
        buttonStyle="primary"
        class="w-fit-content"
        text="LBL_BTN.SEND_MESSAGE"
        [disabled]="!updateControl.value"
        (click)="updateMessage()"
      ></slm-button>
    </div>
  </div>
</ng-template>
