import {createAction, props} from '@ngrx/store';
import {Partner} from '../../global';

export enum PartnerActionTypes {
  // Partner actions
  SelectPartner = '[Partner] select partner ',
  LoadPartner = '[Partner] load partner ',
  ClearPartner = '[Partner] clear partner '
}

/**
 * PARTNER ACTIONS
 */

const selectPartner = createAction(PartnerActionTypes.SelectPartner, props<{id: number}>());

const loadPartner = createAction(PartnerActionTypes.LoadPartner, props<{partner: Partner}>());

const clearPartner = createAction(PartnerActionTypes.ClearPartner);

export const partnerActions = {
  selectPartner,
  loadPartner,
  clearPartner
};
