import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonConfig, buttonsStaticConfig } from '../../../global';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'slm-question-dialog',
  templateUrl: './question-dialog.component.html'
})
export class QuestionDialogComponent implements OnInit {
  public buttons: Array<ButtonConfig> = [
    buttonsStaticConfig.no,
    buttonsStaticConfig.yes
  ];
  public label: SafeHtml | string = '';
  constructor(
    public readonly dialogRef: MatDialogRef<QuestionDialogComponent>,
    public readonly sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      label: string;
      labelParams: any;
      approveButton?: string;
      declineButton?: string;
      onlyApprove?: boolean;
      isHtml?: boolean;
    }
  ) {}

  ngOnInit() {
    this.label = this.data.isHtml ? this.sanitizer.bypassSecurityTrustHtml(this.data.label) : this.data.label;
    if (!!this.data?.approveButton) {
      this.buttons[this.buttons.length - 1].label = this.data.approveButton;
    }
    if (this.data.onlyApprove) {
      this.buttons = [this.buttons[1]];
    } else {
      if (this.buttons.length !== 2) {
        this.buttons = [buttonsStaticConfig.no, this.buttons[0]];
      }
      if (!!this.data?.declineButton) {
        this.buttons[0].label = this.data.declineButton;
      }
    }
  }

  public buttonEvent(event: string) {
    this.dialogRef.close(event);
  }
}
