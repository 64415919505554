/**
 * Accounting statuses
 *
 * @summary An enum specifying the values available as the accounting status of an invoice-router object.
 */
export type AccountingStatus =
  | 'accounted'
  | 'be-repaired'
  | 'wait-checking'
  | 'wait-accounting';

/**
 * Filling statuses
 *
 * @summary An enum specifying the values available as fillingStatus option.
 */
export type FillingStatus = 'accounted' | 'be-repaired' | 'wait-checking';

/**
 * @summary An enum specifying the values available as the payment status of an incoming invoice.
 */
export type IncomingPaymentStatus =
  | 'payable'
  | 'paid'
  | 'judge'
  | 'declined'
  | 'under-payout'
  | 'wait-approve'
  | 'sent-to-bank';

/**
 * @summary An enum specifying the values available as the payment status of an outgoing invoice.
 */
export type OutgoingPaymentStatus =
  | 'duringCollection'
  | 'notPaid'
  | 'paid'
  | 'waitingForPayment';

/**
 * @summary An enum that specifies the available values for the Invoice object's format property.
 */
export type InvoiceFormat = 'electronic' | 'paper' | 'prepaymentRequest';

/**
 * @summary An enum specifying the values available as the payment type of an invoice.
 */
export type InvoicePaymentType =
  | 'bank-transfer'
  | 'credit-card'
  | 'cash'
  | 'check';

export type InvoiceType = 'incoming' | 'outgoing';

export enum InvoicePaymentTypeEnum {
  BANK_TRANSFER = 'bank-transfer',
  CREDIT_CARD = 'credit-card',
  CASH = 'cash',
  CHECK = 'check',
  PROFORMA = 'proforma',
  MONEY = 'money',
  COMPENSATION = 'compensation',
  DISCOUNT = 'cashDiscount',
  OTHER = 'other',
  BANK_CARD = 'bank-card'
}

export enum InvoiceTypeEnum {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
  OUTGOING_PROFORMA = 'outgoing_proforma',
  NAV_INCOMING = 'nav-incoming',
  NAV_OUTGOING = 'nav-outgoing'
}

export enum InvoiceExcelExportType {
  ITEMISED = 'itemised',
  MERGED = 'merged'
}
