import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {convertToFormData, ErrorHandlingService, PartnerActiveStatusEnum, PartnerSave} from '../../global';
import {PartnerListCheckResponse} from '../entities/partner.entity';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  constructor(
    private readonly http: HttpClient,
    private readonly errorHandler: ErrorHandlingService
  ) {}

  public changePartnerStatus(ids: Array<number>, status: PartnerActiveStatusEnum): Observable<any> {
    return this.http
      .put('/companies/status', { ids, status })
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public mergePartner(data): Observable<any> {
    return this.http
      .post('/companies/merge', data)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public updatePartner(partner: PartnerSave): Observable<any> {
    return this.http
      .put('/companies', partner)
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public downloadImportExample(): Observable<any> {
    return this.http.get('/companies/import/example', {responseType: 'blob'})
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  public importPartners(file: File): Observable<any> {
    return this.http.post('/companies/import', convertToFormData({file}))
      .pipe(catchError((err) => this.errorHandler.handleError(err, false)));
  }

  public export(type: PartnerActiveStatusEnum, search: string): Observable<any> {
    return this.http.get('/companies/export', {
      params: {
        type,
        ...(search ? { search: encodeURIComponent(search) } : {})
      },
      responseType: 'blob'
    }).pipe(catchError((err) => this.errorHandler.handleError(err, false)));
  }

  public checkPartnersList(): Observable<PartnerListCheckResponse> {
    return this.http.get('/companies/external-system-sync-check').pipe(
      catchError((err) => this.errorHandler.handleError(err, false)));
  }
}
