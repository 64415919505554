<mat-form-field
  class="flex-grow"
  *ngIf="control"
  [class.text-input--required]="isRequiredField  && (control.valid || !control.dirty)"
  [class.text-input--error]="
    !control?.valid && control?.touched && control?.dirty
  "
>
  <mat-label *ngIf="label || (!isRequiredField && !hideOptionalLabel)">{{ label | translate }}
    <span *ngIf="!isRequiredField && !hideOptionalLabel" translate="LBL_INPUT.LABEL.NOT_REQUIRED"></span></mat-label>

  <slm-loader *ngIf="loading && control?.enabled" diameter="24" class="block top-10" matSuffix></slm-loader>

  <mat-select
    [formControl]="control"
    [required]="isRequiredField"
    [attr.data-test-id]="testId"
    [class.text-input--colored-value]="coloredValue"
    [placeholder]="placeholder | translate"
  >
    <ng-container *ngIf="!loading">

      <mat-optgroup
        *ngFor="let group of groupedOptions"
      >
        <div class="medium-text">{{ group.groupLabel | translate }}&zwnj;</div>
        <mat-option
          *ngFor="let option of group.options; let index = index"
          [attr.data-test-id]="testId + index"
          [value]="option.value"
          [disabled]="option.disabled || false"
          class="simple-view"
        >
          <!--Need to delete later &zwnj;-->
          <div class="medium-text">{{ option.label | translate }}&zwnj;</div>
        </mat-option>
      </mat-optgroup>
    </ng-container>
  </mat-select>
  <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
  <mat-hint *ngIf="customWarningMsg" class="color-warning">{{customWarningMsg | translate}}</mat-hint>
  <mat-error *ngIf="(control?.dirty || control?.touched) && control?.invalid">{{
      getError() | translate: errorBody
    }}</mat-error>
</mat-form-field>
