<mat-label *ngIf="label" class="mb-1 block">{{ label | translate }}</mat-label>
<div
  class="dnd"
  slmDragAndDrop
  (fileDropped)="onFileDropped($event)"
  [style.width]="width"
  [style.paddingTop]="height"
>
  <div
    class="dnd__container"
    [class.dnd__container--horizontal]="!isVertical"
    [style.position]="position">
    <input
      class="dnd__container__input"
      [accept]="accept"
      [attr.data-test-id]="testId"
      [multiple]="type === 'multi-file'"
      type="file"
      #fileDropRef
      id="fileDropRef"
      (change)="fileBrowseHandler($event)"
    />
    <div
      *ngIf="!value || !(type === 'single-file' && supportedFiles === 'images')"
      class="dnd__container__icon"
      [style.width]="iconSize"
      [style.paddingTop]="iconSize"
    >
      <slm-svg-icon
        [icon]="icon"
        size="100%"
        class="fill-accent-transparent dnd__container__icon__svg"
      ></slm-svg-icon>
    </div>
    <ng-container *ngIf="value && type !== 'multi-file'; else noValue">
      <img
        *ngIf="type === 'single-file' && supportedFiles === 'images'"
        class="dnd__container__image"
        [src]="value"
        alt="Image"
        [width]="width"
        [height]="height"
      />
      <h4 class="dnd__container__title">
        {{ "LBL_DND.SELECTED_FILE" | translate: {fileName: value.name} }}
      </h4>
      <div
        class="dnd__container__clear pt-3"
        [style.position]="position">
        <slm-button
          class="dnd__container__clear__btn"
          buttonStyle="error"
          text="LBL_BTN.CLEAR"
          (click)="clear()"
        ></slm-button>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #noValue>
  <div>
    <h5 class="dnd__container__title">{{ text | translate }}</h5>
    <h5 class="dnd__container__title text-medium">
      {{ "LBL_OR" | translate }}
    </h5>
    <label
      class="dnd__container__button slm-btn slm-btn--primary slm-btn--height--normal"
      for="fileDropRef"
    >{{ browseButton | translate }}</label
    >
  </div>
</ng-template>
