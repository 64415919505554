<slm-dialog-container
  [title]="data.title ?? 'LBL_UPLOAD_FILL.UPLOAD_DIALOG.TITLE'"
  [buttons]="dialogButtons"
  (fireEvent)="closeDialog($event.event)"
  class="w-min-350px block"
>
  <ng-container *ngIf="!data.attachToExists">
    <slm-message
      message="{{'LBL_ERROR.MAX_FILE_SIZE' | translate : {maxSize: maxFileSizeMB} }}"
      type="info"
    ></slm-message>
    <ng-container *ngIf="!data.hideUploadOptions">
      <slm-select-input
        label="UPLOAD_MODE"
        class="w-min-250px block mt-3"
        [options]="fileUploadModeOptions"
        [control]="uploadModeControl"
        testId="uploadMode"
      ></slm-select-input>
    </ng-container>
  </ng-container>
  <ng-container
    *ngIf="!data.hideUploadOptions"
  >
    <div
      class="grid-col-2-50 grid-responsive"
      *ngIf="useDivisions$ | async"
    >
      <slm-user-division-select
        [control]="userDivisionControl"
        [useDefaultIfMany]="true"
      ></slm-user-division-select>
    </div>
  </ng-container>
  <slm-document-item
    *ngFor="let document of documents; let i = index"
    [file]="document.file"
    [name]="document.name"
    [hideOpen]="document.hideOpen || data.hideAllOpenIcon"
    [hideClose]="document.hideClose"
    [status]="document.status"
    [progress]="document.progress"
    (fireEvent)="documentEvent(i, $event)"
  ></slm-document-item>
  <slm-drag-and-drop
    [config]="{ width: '100%' }"
    text="LBL_UPLOAD_FILL.UPLOAD_DIALOG.DRAG_DOCUMENT"
    [accept]="data.acceptedFileMimeTypes"
    supportedFiles="images-documents"
    browseButton="LBL_UPLOAD_FILL.UPLOAD_DIALOG.BROWSE"
    type="multi-file"
    [showValue]="false"
    class="pt-2 block"
    [maxFileSizeMB]="maxFileSizeMB"
    (fileError)="unauthorizedFileFormat($event)"
    (file)="selectedFile($event)"
    iconSize="15%"
  ></slm-drag-and-drop>
</slm-dialog-container>
